// eslint-disable-next-line import/no-cycle
import axios from '@/services/http/axios';
import {
	EcommerceProduct,
	EcommerceProductVariantQuantity,
	EcommerceCollection,
	EcommerceProductSeoSettingsData,
} from '@zyro-inc/site-modules/types';

const ECOMMERCE_API = `${import.meta.env.VITE_ECOMMERCE_API_URL}/store`;

export const getSettings = async (storeId: string) => {
	const { data } = await axios.get(`${ECOMMERCE_API}/${storeId}/settings`);

	return data;
};

export const getStoreProducts = async (storeId: string, options: {
	sort?: string,
	offset?: number,
	limit?: number,
	collectionId?: string,
	isHidden?: boolean,
	productId?: string
} = {}) => {
	const {
		sort,
		collectionId,
		isHidden,
		limit,
		offset,
		productId,
	} = options;

	const searchParams = new URLSearchParams(sort);

	if (collectionId) {
		searchParams.set('collection_ids[]', collectionId);
	}

	if (isHidden) {
		searchParams.set('is_hidden', isHidden.toString());
	}

	if (limit) {
		searchParams.set('limit', limit.toString());
	}

	// typeof because offset can be 0
	if (typeof offset === 'number' && limit) {
		searchParams.set('offset', offset.toString());
	}

	if (productId) {
		searchParams.set('ids[]', productId);
	}

	const { data } = await axios.get<{
		products: EcommerceProduct[],
		count: number
	}>(`${ECOMMERCE_API}/${storeId}/products?${searchParams.toString()}`);

	return {
		products: data.products,
		count: data.count,
	};
};

export const getVariantsQuantity = async (storeId: string) => {
	const { data } = await axios.get<{
		variants: EcommerceProductVariantQuantity[]
	}>(`${ECOMMERCE_API}/${storeId}/variants`, {
		params: {
			fields: 'inventory_quantity',
		},
	});

	return data?.variants;
};

export const getCategories = async (storeId: string) => {
	const { data } = await axios.get<{collections: EcommerceCollection[]}>(`${ECOMMERCE_API}/${storeId}/collections`);

	return data.collections;
};

export const getStoreProductsSeo = async (storeId: string) => {
	const response = await fetch(`${ECOMMERCE_API}/${storeId}/products/seo-settings`);
	const data: { products: EcommerceProductSeoSettingsData[] } = await response.json();

	return data;
};
