import { useRoute } from 'vue-router';
import { AI_BUILDER_ROUTE } from '@/constants/routes';
import { computed } from 'vue';

export const useBuilderMode = () => {
	const route = useRoute();

	const isAiBuilderMode = computed(() => route.name === AI_BUILDER_ROUTE);

	return {
		isAiBuilderMode,
	};
};
