<script setup lang="ts">
import {
	computed,
	ref,
	watch,
} from 'vue';

import ZyroTooltip from '@zyro-inc/builder/src/components/global/ZyroTooltip.vue';
import { useNotifications } from '@/use/useNotifications';
import { useI18n } from 'vue-i18n';
import EventLogApi from '@/api/EventLogApi';
import {
	COOKIE_PRODUCT_SURVEY_COUNT,
	COOKIE_PRODUCT_SURVEY_PENDING,
	COOKIE_PRODUCT_SURVEY_SENT,
	GAMIFICATION_TASK_ADD_PRODUCT,
	PRODUCT_SURVEY_ITEM_FINALISE,
	PRODUCT_SURVEY_ITEM_HOW_TO_ADD,
	PRODUCT_SURVEY_ITEM_NO_STORE,
	PRODUCT_SURVEY_ITEM_OTHER,
} from '@/constants/builderConstants';
import {
	getCookie,
	setCookie,
} from '@zyro-inc/site-modules/utils/cookies';
import ZyroCheckbox from '@zyro-inc/builder/src/components/global/ZyroCheckbox.vue';
import Icon from '@zyro-inc/builder/src/components/global/Icon.vue';
import HostingerButton from '@zyro-inc/builder/src/components/global/HostingerButton.vue';
import ZyroFieldTextArea from '@zyro-inc/builder/src/components/global/ZyroFieldTextArea.vue';
import { useStore } from 'vuex';
import { useGamificationStore } from '@/stores/gamificationStore';

const {
	state,
	getters,
} = useStore();
const { notify } = useNotifications();
const { t } = useI18n();
const gamificationStore = useGamificationStore();

const hostnameSplitted = window.location.hostname.split('.');
const domain = hostnameSplitted.slice(Math.max(hostnameSplitted.length - 2, 1)).join('.');
const productSurveyPendingCookie = getCookie(`${COOKIE_PRODUCT_SURVEY_PENDING}-${state.websiteId}`);
const productSurveyCount = getCookie(`${COOKIE_PRODUCT_SURVEY_COUNT}-${state.websiteId}`);
const productSurveySent = getCookie(`${COOKIE_PRODUCT_SURVEY_SENT}-${state.websiteId}`);

const TARGET_HOURS_PASSED = 3;
const MAX_SURVEY_TRIGGER_TIMES = 5;
const COOKIE_EXPIRATION_DAYS = 365;

const comment = ref('');
const isLoading = ref(false);
const items = ref([
	{
		id: PRODUCT_SURVEY_ITEM_FINALISE,
		title: t('builder.nps.productSurveyFinalise'),
		isSelected: false,
	},
	{
		id: PRODUCT_SURVEY_ITEM_HOW_TO_ADD,
		title: t('builder.nps.productSurveyHowToAdd'),
		isSelected: false,
	},
	{
		id: PRODUCT_SURVEY_ITEM_NO_STORE,
		title: t('builder.nps.productSurveyNoStore'),
		isSelected: false,
	},
	{
		id: PRODUCT_SURVEY_ITEM_OTHER,
		title: t('builder.nps.productSurveyOther'),
		isSelected: false,
	},
]);
const isPaymentBannerVisible = computed(() => getters['gui/isSetupPaymentProviderNotificationVisible']);
const isCommentEnabled = computed(() => items.value.find((item) => item.id === PRODUCT_SURVEY_ITEM_OTHER && item.isSelected));
const isSubmitEnabled = computed(() => items.value.some((item) => !!item.isSelected));
const hasEnoughTimePassedAfterSiteCreation = computed(() => {
	const siteCreatedAt = state.createdAt;
	const hoursPassed = (Date.now() - new Date(siteCreatedAt).getTime()) / 1000 / 60 / 60;

	return hoursPassed >= TARGET_HOURS_PASSED;
});
const hasBeenTrigerredMaxTimes = computed(() => {
	const currentCount = getCookie(`${COOKIE_PRODUCT_SURVEY_COUNT}-${state.websiteId}`) || 1;

	return Number(currentCount) >= MAX_SURVEY_TRIGGER_TIMES;
});
const isStoreTypeZyro = computed(() => getters['ecommerce/isStoreTypeZyro']);
const achievements = computed(() => gamificationStore.achievements);

const popupRecomputeTrigger = ref(true);
const isPopupOpen = computed({
	get(): boolean {
		return !isPaymentBannerVisible.value && !productSurveySent
			&& !productSurveyPendingCookie
			&& popupRecomputeTrigger.value
			&& isStoreTypeZyro.value
			&& Array.isArray(achievements.value)
			&& achievements.value.length > 0
			&& !achievements.value.find((achievement) => achievement.id === GAMIFICATION_TASK_ADD_PRODUCT)?.isCompleted
			&& !hasBeenTrigerredMaxTimes.value
			&& hasEnoughTimePassedAfterSiteCreation.value;
	},
	set(value: boolean) {
		popupRecomputeTrigger.value = value;
	},
});

const handleClose = () => {
	const currentCount = getCookie(`${COOKIE_PRODUCT_SURVEY_COUNT}-${state.websiteId}`) || 1;

	setCookie(
		`${COOKIE_PRODUCT_SURVEY_PENDING}-${state.websiteId}`,
		true,
		1,
		{
			cdomain: domain,
		},
	);
	setCookie(
		`${COOKIE_PRODUCT_SURVEY_COUNT}-${state.websiteId}`,
		String(Number(currentCount) + 1),
		COOKIE_EXPIRATION_DAYS,
		{
			cdomain: domain,
		},
	);

	isPopupOpen.value = false;
};

const sendFeedback = async () => {
	const properties = items.value
		.filter((item) => item.isSelected)
		.map((item) => item.id).join(', ');

	const formattedProperties = {
		selections: properties,
		...(properties.includes(PRODUCT_SURVEY_ITEM_OTHER) && {
			comment: comment.value,
		}),
	};

	try {
		isLoading.value = true;

		await EventLogApi.logEvent({
			eventName: 'website_builder.ecomm_product_survey.sent',
			eventProperties: formattedProperties,
		});

		setCookie(
			`${COOKIE_PRODUCT_SURVEY_SENT}-${state.websiteId}`,
			true,
			COOKIE_EXPIRATION_DAYS,
			{
				cdomain: domain,
			},
		);

		notify({
			message: t('builder.nps.thankYou'),
			theme: 'success',
		});

		isPopupOpen.value = false;
	} catch (error) {
		notify({
			message: t('builder.nps.feedbackSendFailed'),
		});

		handleClose();
	} finally {
		isLoading.value = false;
	}
};

watch(() => isPopupOpen.value, (isOpen) => {
	if (isOpen) {
		if (!productSurveyCount) {
			setCookie(
				`${COOKIE_PRODUCT_SURVEY_COUNT}-${state.websiteId}`,
				'1',
				COOKIE_EXPIRATION_DAYS,
				{
					cdomain: domain,
				},
			);
		}

		EventLogApi.logEvent({
			eventName: 'website_builder.ecomm_product_survey.enter',
		});
	}
});

</script>

<template>
	<ZyroTooltip
		v-if="isPopupOpen"
		toggle-event="none"
		content-position="absolute"
		:forced-position="{
			left: '63px',
			bottom: '8px',
			padding: '0px',
			'border-radius': '16px'
		}"
		:triangle="false"
		@on-close="handleClose"
	>
		<div class="feedback">
			<div class="feedback-container feedback-container--fixed-width">
				<div class="feedback-container__title">
					<p class="text-body-2">
						{{ t('builder.nps.productSurveyTitle') }}
					</p>
					<Icon
						v-qa="'survey-close-button'"
						class="close-icon"
						:size="24"
						name="close"
						@click="handleClose"
					/>
				</div>

				<div
					v-for="item in items"
					:key="item.id"
					class="feedback-container__checkbox"
				>
					<ZyroCheckbox
						:id="item.id"
						v-qa="`survey-checkbox-${item.id}`"
						:model-value="item.isSelected"
						color="primary"
						@update:model-value="item.isSelected = !item.isSelected"
					/>
					<p class="text-body-2">
						{{ t(item.title) }}
					</p>
				</div>

				<ZyroFieldTextArea
					v-if="isCommentEnabled"
					id="comment"
					v-model="comment"
					maxlength="300"
					:placeholder="t('builder.nps.productSurveyCommentPlaceholder')"
				/>

				<HostingerButton
					v-qa="'survey-submit-button'"
					:color="isSubmitEnabled ? 'primary' : 'grey'"
					class="feedback-container__submit-button"
					:disabled="!isSubmitEnabled"
					:is-loading="isLoading"
					@click="sendFeedback"
				>
					{{ t('builder.nps.productSurveySubmit') }}
				</HostingerButton>
			</div>
		</div>
	</ZyroTooltip>
</template>

<style lang="scss" scoped>
.feedback-container {
	display: flex;
	flex-direction: column;
	padding: 16px;
	border-radius: 16px;
	box-shadow: 0 0 12px 0 rgb(29 30 32 / 16%);

	.close-icon {
		align-self: baseline;
	}

    &__checkbox {
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;

        :deep(input:checked + label) {
            background-color: $color-primary;

            > svg {
                color: $color-light;
            }
        }

        > div {
            margin-right: 8px;

        }
    }

	&__title {
        color: rgb(var($color-gray-dark));
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            cursor: pointer;
        }

        p {
            line-height: 24px;
            font-weight: 700;
            max-width: 308px;
        }
	}

	:deep(textarea) {
		width: 100%;
		margin-bottom: 16px;
        border-radius: 8px;
        height: 72px;
	}

	&__submit-button {
		padding: 8px 24px;
		line-height: 20px;
		align-self: flex-end;
	}

	&--fixed-width {
		width: 380px;
	}

	&--fixed-height {
		height: 248px;
	}
}
</style>
