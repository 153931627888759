export const extractText = (node) => {
	let text = '';

	// If the node is a text node, extract its content
	if (node.nodeType === Node.TEXT_NODE) {
		text += node.textContent;
	}

	// If the node has child nodes, recursively extract text content from them
	if (node.childNodes) {
		node.childNodes.forEach((childNode) => {
			text += extractText(childNode);
		});
	}

	return text;
};
