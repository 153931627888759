import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { MODAL_NO_BACKUPS_MODAL } from '@/constants/builderConstants';
import { VERSION_HISTORY_ROUTE } from '@/constants/routes';
import { getSiteVersionHistory } from '@/api/SiteVersionApi';
import { SiteVersionItem } from '@/types/siteVersionTypes';

const siteVersionsList = ref<SiteVersionItem[] | null>(null);
const isSiteVersionsLoading = ref<boolean>(false);

const getDateString = (value: SiteVersionItem['lastModified']) => {
	// 2022-11-14T11:51:36.000Z -> 14 Nov, 2022, 11:51:36
	const date = new Date(value);

	return `${date.toLocaleString('default', {
		month: 'short',
	})} ${date.getDate()}, ${date.getFullYear()}, ${date.toLocaleTimeString('en-US', {
		hourCycle: 'h23',
	})}`;
};

export const useSiteVersions = () => {
	const {
		state,
		getters,
		dispatch,
	} = useStore();

	const router = useRouter();

	const websiteId = computed<string>(() => state.websiteId);
	const isSitePublished = computed(() => getters.isSitePublished);

	const loadSiteVersions = async () => {
		try {
			isSiteVersionsLoading.value = true;

			const versionsData = await getSiteVersionHistory(websiteId.value) as SiteVersionItem[] | null;

			if (!versionsData) {
				throw new Error('No site versions found');
			}

			siteVersionsList.value = versionsData.map((item) => ({
				versionId: item.versionId,
				lastModified: getDateString(item.lastModified),
			}));
		} catch (error) {
			console.error(error);
		} finally {
			isSiteVersionsLoading.value = false;
		}
	};

	const openVersionHistory = async () => {
		if (!isSitePublished.value) {
			dispatch('gui/OPEN_MODAL', {
				name: MODAL_NO_BACKUPS_MODAL,
			});

			return;
		}

		await loadSiteVersions();

		await dispatch('saving/saveWebsite', {
			saveWhenImpersonating: false,
		});

		router.push({
			name: VERSION_HISTORY_ROUTE,
		});
	};

	return {
		openVersionHistory,
		siteVersionsList,
		loadSiteVersions,
		isSiteVersionsLoading,
	};
};
