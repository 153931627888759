export const BILLING_PERIODS = {
	QUADRENNIALLY: 'quadrennially',
	ANNUALLY: 'annually',
	BIENNIALLY: 'biennially',
	MONTHLY: 'monthly',
} as const;

export const WEBSITE_STATES = {
	DELETED: 'deleted',
	ACTIVE: 'active',
};

export const HOSTING_PLANS = {
	STARTER: 'hostinger_starter',
};
