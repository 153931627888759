import axios from '@/services/http/axios';
import {
	CreditsEstimate,
	PurchaseCreditsPayload,
} from '@/types/billingTypes';

const BILLING_API = `${import.meta.env.VITE_BACKEND_API_URL}/billing`;

export const getSiteSubscription = async (siteId: string) => {
	const { data } = await axios.get<any>(`${BILLING_API}/subscriptions/site/${siteId}`);

	return data.subscription;
};

export const getSitesSubscriptions = async () => {
	const { data } = await axios.get(`${BILLING_API}/subscriptions`);

	return data;
};

export const connectSiteToSubscription = async ({
	siteId,
	subscriptionId,
}: { siteId: string, subscriptionId: string}) => axios.patch(`${BILLING_API}/subscriptions/connect-site`, {
	siteId,
	subscriptionId,
});

export const activatePlan = (siteId: string) => axios.get(`${BILLING_API}/subscriptions/activate-plan`, {
	params: {
		'site-id': siteId,
	},
});

export const getSites = async () => (await axios.get(`${BILLING_API}/sites`, {
	// 401 status happens for users when they have no sites, is expected and handled in some flows
	whitelistedStatusCodes: [401],
})).data;

export const estimateCredits = () => axios.get<CreditsEstimate>(`${BILLING_API}/estimates/credits`);

// eslint-disable-next-line max-len
export const purchaseCredits = (payload: PurchaseCreditsPayload) => axios.post<{ paymentLink: string, token: string}>(`${BILLING_API}/orders/credits/purchase`, payload);

export const confirmCreditsPurchase = (token: string) => axios.post(`${BILLING_API}/orders/credits/confirm`, {
	token,
});
