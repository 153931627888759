import { ref } from 'vue';
import { timeout } from '@/utils/helpers';

export const useEcommerceTabSettingsPopup = () => {
	const currentTab = ref<string | null>(null);

	const setCurrentTab = (id: string) => {
		currentTab.value = id;
	};

	const scrollToItem = async (id: string) => {
		await timeout(400);

		document.getElementById(id)?.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
			inline: 'start',
		});
	};

	const handleItemClick = (id: string) => {
		if (currentTab.value === id) {
			currentTab.value = null;
		} else {
			currentTab.value = id;
			scrollToItem(id);
		}
	};

	return {
		handleItemClick,
		setCurrentTab,
		currentTab,
	};
};
