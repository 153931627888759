export const mockPosts = {
	'AnA2-P15c': {
		name: 'New page',
		path: '/blogpost1',
		blocks: [
			'AnA2-P15c-header',
			'AnA2-P15c-section',
		],
		type: 'blog',
		isDraft: false,
		coverImageOrigin: 'unsplash',
		coverImagePath: 'photo-1584443348278-4789e86c7373?auto=format&fit=crop&w=3600&h=2160',
		coverImageAlt: 'black blue and yellow textile',
		date: '2024-05-08',
		categories: [],
		minutesToRead: '1',
		meta: {
			title: 'Your blog post',
			description: 'Blog post description.',
			ogImageOrigin: 'unsplash',
			ogImagePath: 'photo-1584443348278-4789e86c7373?auto=format&fit=crop&w=3600&h=2160',
			ogImageAlt: 'black blue and yellow textile',
		},
	},
	xYeRqh3F4: {
		name: 'New page',
		path: '/blogpost2',
		blocks: [
			'xYeRqh3F4-header',
			'xYeRqh3F4-section',
		],
		type: 'blog',
		isDraft: false,
		coverImageOrigin: 'unsplash',
		coverImagePath: 'photo-1563164232-b6ee5dd3e821?auto=format&fit=crop&w=3600&h=2160',
		coverImageAlt: 'a man riding a skateboard down the side of a ramp',
		date: '2024-05-08',
		categories: [],
		minutesToRead: '1',
		meta: {
			title: 'Your blog post',
			description: 'Blog post description.',
			ogImageOrigin: 'unsplash',
			ogImagePath: 'photo-1563164232-b6ee5dd3e821?auto=format&fit=crop&w=3600&h=2160',
			ogImageAlt: 'a man riding a skateboard down the side of a ramp',
		},
	},
	xYeRqh3F5: {
		name: 'New page',
		path: '/blogpost3',
		blocks: [
			'xYeRqh3F5-header',
			'xYeRqh3F5-section',
		],
		type: 'blog',
		isDraft: false,
		coverImageAlt: 'white concrete building during daytime',
		coverImagePath: 'photo-1586522471252-68f4b108ff2a?auto=format&fit=crop&w=3600&h=2160',
		coverImageOrigin: 'unsplash',
		date: '2024-05-08',
		categories: [],
		minutesToRead: '1',
		meta: {
			title: 'Your blog post',
			description: 'Blog post description.',
			ogImagePath: 'photo-1586522471252-68f4b108ff2a?auto=format&fit=crop&w=3600&h=2160',
			ogImageOrigin: 'unsplash',
			ogImageAlt: 'white concrete building during daytime',
		},
	},
	xYeRqh3F7: {
		name: 'New page',
		path: '/blogpost5',
		blocks: [
			'xYeRqh3F7-header',
			'xYeRqh3F7-section',
		],
		type: 'blog',
		isDraft: false,
		coverImageAlt: "worm's-eye view photography of concrete building",
		coverImagePath: 'photo-1483366774565-c783b9f70e2c?auto=format&amp;fit=crop&amp;w=861&amp',
		coverImageOrigin: 'unsplash',
		date: '2024-05-08',
		categories: [],
		minutesToRead: '1',
		meta: {
			title: 'Your blog post',
			description: 'Blog post description.',
			ogImagePath: 'photo-1483366774565-c783b9f70e2c?auto=format&amp;fit=crop&amp;w=861&amp',
			ogImageOrigin: 'unsplash',
			ogImageAlt: "worm's-eye view photography of concrete building",
		},
	},
	xYeRqh3F6: {
		name: 'New page',
		path: '/blogpost4',
		blocks: [
			'xYeRqh3F6-header',
			'xYeRqh3F6-section',
		],
		type: 'blog',
		isDraft: false,
		coverImageAlt: 'photo of white staircase',
		coverImagePath: 'photo-1516739089214-59baa011fdb9?auto=format&fit=crop&w=3600&h=2160',
		coverImageOrigin: 'unsplash',
		date: '2024-05-08',
		categories: [],
		minutesToRead: '1',
		meta: {
			title: 'Your blog post',
			description: 'Blog post description.',
			ogImagePath: 'photo-1516739089214-59baa011fdb9?auto=format&fit=crop&w=3600&h=2160',
			ogImageOrigin: 'unsplash',
			ogImageAlt: 'photo of white staircase',
		},
	},
	xYeRqh3F8: {
		name: 'New page',
		path: '/blogpost6',
		blocks: [
			'xYeRqh3F8-header',
			'xYeRqh3F8-section',
		],
		type: 'blog',
		isDraft: false,
		coverImageAlt: 'white concrete building',
		coverImagePath: 'photo-1578803203370-8b000b589edd?auto=format&fit=crop&w=3600&h=2160',
		coverImageOrigin: 'unsplash',
		date: '2024-05-08',
		categories: [],
		minutesToRead: '1',
		meta: {
			title: 'Your blog post',
			description: 'Blog post description.',
			ogImagePath: 'photo-1578803203370-8b000b589edd?auto=format&fit=crop&w=3600&h=2160',
			ogImageOrigin: 'unsplash',
			ogImageAlt: 'white concrete building',
		},
	},
};
