import {
	Experiment,
	ExperimentClient,
} from '@amplitude/experiment-js-client';
import { getCookie } from '@zyro-inc/site-modules/utils/cookies';
import {
	COOKIE_HOSTINGER_DEVICE_ID,
	COOKIE_QA_NO_EXPERIMENTS,
} from '@/constants/builderConstants';

export const EXPERIMENT_IDS = {
	GAMIFICATION_DOMAINS: 'website-builder-add-domain-connection-step-to-gamification',
	AI_BUILDER_PREVIEW_REVAMP_EXPERIMENT: 'ai-builder-preview-revamp-experiment',
	BUILDER_PAYMENTS_REMINDER: 'builder-payments-reminder',
	AI_BUILDER_PREVIEW_SIDEBAR: 'ai-builder-preview-sidebar',
} as const;

export const FEATURE_FLAG_IDS = {
	ECOMMERCE_DYNAMIC_PRODUCT_PAGES_SEO: 'ecommerce-dynamic-product-pages',
	ECOMMERCE_DYNAMIC_PAGES_FLOW: 'website-builder-ecommerce-dynamic-product-page-flow',
	AI_BUILDER_NEW_IMAGE_SELECTION: 'ai-builder-new-image-selection',
} as const;

export const EXPERIMENT_VARIANTS = {
	[EXPERIMENT_IDS.GAMIFICATION_DOMAINS]: {
		VAR_CONTROL: 'control',
		VAR_1: '1',
	},
	[EXPERIMENT_IDS.AI_BUILDER_PREVIEW_REVAMP_EXPERIMENT]: {
		VAR_CONTROL: '0',
		VAR_1: '1',
	},
	[FEATURE_FLAG_IDS.ECOMMERCE_DYNAMIC_PRODUCT_PAGES_SEO]: {
		VAR_CONTROL: '0',
		VAR_1: '1',
	},
	[FEATURE_FLAG_IDS.ECOMMERCE_DYNAMIC_PAGES_FLOW]: {
		VAR_CONTROL: '0',
		VAR_1: '1',
	},
	[EXPERIMENT_IDS.BUILDER_PAYMENTS_REMINDER]: {
		VAR_CONTROL: '0',
		VAR_1: '1',
	},
	[FEATURE_FLAG_IDS.AI_BUILDER_NEW_IMAGE_SELECTION]: {
		VAR_CONTROL: '0',
		VAR_1: '1',
	},
} as const;

export type ExperimentId = typeof EXPERIMENT_IDS[keyof typeof EXPERIMENT_IDS];

export type FeatureFlagId = typeof FEATURE_FLAG_IDS[keyof typeof FEATURE_FLAG_IDS];

export const experimentsInstance: { amplitudeExperimentInstance: ExperimentClient | null, isGoRobotsUser: boolean } = {
	amplitudeExperimentInstance: null,
	isGoRobotsUser: false,
};

export const initializeExperiments = async ({
	userId,
	isGoRobotsUser,
}: { userId: string, isGoRobotsUser: boolean}) => {
	const noExperimentsCookie = getCookie(COOKIE_QA_NO_EXPERIMENTS);

	if (noExperimentsCookie) {
		return;
	}

	experimentsInstance.amplitudeExperimentInstance = Experiment.initialize(import.meta.env.VITE_AMPLITUDE_EXPERIMENTS_API_KEY);
	experimentsInstance.isGoRobotsUser = isGoRobotsUser;

	await experimentsInstance.amplitudeExperimentInstance.fetch({
		user_id: userId,
		device_id: getCookie(COOKIE_HOSTINGER_DEVICE_ID),
	});
};

type ExperimentGetter = (experimentId: ExperimentId | FeatureFlagId) => string | null | undefined;

export const getExperimentVariant: ExperimentGetter = (experimentId) => {
	if (experimentsInstance.isGoRobotsUser) {
		return null;
	}

	const experimentVariant = experimentsInstance.amplitudeExperimentInstance?.variant(experimentId).value;

	return experimentVariant;
};

export const getIsExperimentActive = (
	experimentId: ExperimentId | FeatureFlagId,
	variants?: string | string[],
	experimentVariantGetter: ExperimentGetter = getExperimentVariant,
): boolean => {
	const experimentVariant = experimentVariantGetter(experimentId);

	const customVariants = typeof variants === 'string' ? [variants] : variants;

	const preparedVariants = customVariants ?? [
		'on',
		'1',
	];

	return preparedVariants.includes(experimentVariant || '');
};
