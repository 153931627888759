export const HOSTINGER_BUTTON_TYPES = {
	CONTAINED: 'contained',
	OUTLINED: 'outlined',
	TEXT: 'text',
	PLAIN: 'plain',
} as const;

export type HostingerButtonType = (typeof HOSTINGER_BUTTON_TYPES)[keyof typeof HOSTINGER_BUTTON_TYPES];

export type HostingerButtonTheme = 'primary' | 'danger' | 'warning' | 'highlight' | 'add-block';

export type QrPageOptionType = {
	name: string;
	slug: string;
	pageId: string;
};

export type QrModalLocationType = 'pages and navigation' | 'online store' | 'blog' | 'publish modal';

type HostingerButtonSizeType = 'sm' | 'md' | 'lg';

interface HostingerButtonLink {
	name?: string,
	path?: string,
	params?: string,
	query?: string,
}

export interface HostingerButtonProps {
	routeLinkTo?: HostingerButtonLink,
	type?: string,
	href?: string,
	buttonType?: HostingerButtonType,
	theme?: HostingerButtonTheme,
	size?: HostingerButtonSizeType,
	title?: string,
	isDisabled?: boolean,
	isLoading?: boolean,
}

export interface CheckboxOption {
	id: string,
	text: string,
	isChecked?: boolean,
}

export interface MultiselectTag {
	value: string;
	name?: string;
}

export const STATUS_THEMES = {
	SUCCESS: 'success',
	WARNING: 'warning',
	DANGER: 'danger',
	SECONDARY: 'secondary',
	INACTIVE: 'inactive',
} as const;

export type StatusThemes = (typeof STATUS_THEMES)[keyof typeof STATUS_THEMES];
