<template>
	<ZyroTooltip
		ref="controlsTooltipRef"
		toggle-event="hover"
		mode="dark"
		:triangle="false"
		:use-portal="false"
		:hide-tooltip="!props.title || isMobileScreen"
		content-position="absolute"
		class="controls-tooltip"
		forced-position=""
	>
		<template #trigger>
			<slot />
		</template>
		<p
			v-if="props.title && !isMobileScreen"
			ref="controlsTooltipContentRef"
			class="text-body-2"
		>
			{{ props.title }}
			<slot name="shortcut" />
		</p>
	</ZyroTooltip>
</template>

<script setup>
import ZyroTooltip from '@/components/global/ZyroTooltip.vue';
import { useElementBounding } from '@vueuse/core';
import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';

const { state } = useStore();

const POSITION_SWAP_TOP_TRESHOLD = 120;
const controlsTooltipContentRef = ref(null);
const controlsTooltipRef = ref(null);
const { top: tooltipContentTop } = useElementBounding(controlsTooltipContentRef);

const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isTooltipVisible = computed(() => {
	if (!controlsTooltipContentRef.value) return false;

	return tooltipContentTop.value > POSITION_SWAP_TOP_TRESHOLD;
});

const tooltipLeftPosition = computed(() => {
	if (!controlsTooltipContentRef.value || !controlsTooltipRef.value) return '0';

	const { width: tooltipWidth } = controlsTooltipRef.value.$el.getBoundingClientRect();

	const {
		left: tooltipContentLeft,
		width: tooltipContentWidth,
	} = controlsTooltipContentRef.value.getBoundingClientRect();

	const isTooltipContentOffScreen = tooltipContentLeft + tooltipContentWidth > window.innerWidth;

	if (isTooltipContentOffScreen) {
		const overflowAmount = (tooltipContentLeft + tooltipContentWidth) - window.innerWidth;
		const SCROLL_BAR_OFFSET = 24;

		return `calc(${-overflowAmount - SCROLL_BAR_OFFSET}px)`;
	}

	return `calc(-50% + ${tooltipWidth / 2}px)`;
});

const tooltipBottomPosition = computed(() => (isTooltipVisible.value ? 'calc(100% + 4px)' : 'calc(-100% + 4px)'));

const props = defineProps({
	title: {
		type: String,
		default: '',
	},
});

</script>

<style lang="scss" scoped>
.controls-tooltip {
	&:not(:last-child, :only-child) {
		@media screen and (max-width: $media-mobile) {
			margin-right: 0;
		}
	}

	:deep(.tooltip__content) {
		transform: translateX(v-bind(tooltipLeftPosition));
		bottom: v-bind(tooltipBottomPosition);
		padding: 3px 6px;
		white-space: nowrap;
	}
}
</style>
