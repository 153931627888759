<template>
	<ZyroPopupCard
		type="editor"
		:title="t('builder.elementEditPopupSearchBarTitle')"
		:tabs="tabs"
		:current-tab="currentTab"
		@update:current-tab="currentTab = $event"
		@close="emit('close')"
	>
		<ElementEditPopupSearchBarTabGeneral
			v-if="currentTab.id === ELEMENT_EDIT_POPUP_TAB_ID_GENERAL"
			@close="emit('close')"
		/>
		<ElementEditPopupSearchBarTabStyle
			v-else-if="currentTab.id === ELEMENT_EDIT_POPUP_TAB_ID_STYLE"
			@close="emit('close')"
		/>
		<EditTabAnimation
			v-else-if="currentTab.id === ELEMENT_EDIT_POPUP_TAB_ID_ANIMATION"
			@close="emit('close')"
		/>
		<NpsRateFeature
			:feature-name="t('builder.npsRateSearchBar')"
			:type="NPS_TYPE_FEATURE_SEARCH_BAR"
		/>
	</ZyroPopupCard>
</template>

<script setup lang="ts">
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';
import ElementEditPopupSearchBarTabGeneral from '@/components/ElementEditPopupSearchBarTabGeneral.vue';
import ElementEditPopupSearchBarTabStyle from '@/components/ElementEditPopupSearchBarTabStyle.vue';
import EditTabAnimation from '@/components/builder-controls/reusable-controls/element/EditTabAnimation.vue';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import {
	NPS_TYPE_FEATURE_SEARCH_BAR,
	ELEMENT_EDIT_POPUP_TAB_ID_GENERAL,
	ELEMENT_EDIT_POPUP_TAB_ID_STYLE,
	ELEMENT_EDIT_POPUP_TAB_ID_ANIMATION,
} from '@/constants/builderConstants';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';

const { t } = useI18n();

const emit = defineEmits(['close']);

const tabs = [
	{
		title: t('common.general'),
		id: ELEMENT_EDIT_POPUP_TAB_ID_GENERAL,
	},
	{
		title: t('common.style'),
		id: ELEMENT_EDIT_POPUP_TAB_ID_STYLE,
	},
	{
		title: t('common.animation'),
		id: ELEMENT_EDIT_POPUP_TAB_ID_ANIMATION,
	},
];
const currentTab = ref(tabs[0]);
</script>
