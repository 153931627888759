<script setup lang="ts">
import ZyroModal from '@/components/global/ZyroModal.vue';

import { computed } from 'vue';
import { MODAL_WORDPRESS_EXPORT_COMPLETE } from '@/constants/builderConstants';
import { CLOSE_MODAL } from '@/store/builder/gui';
import Icon from '@/components/global/Icon.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import HostingerButton from '@/components/global/HostingerButton.vue';

const { t } = useI18n();
const {
	dispatch,
	state,
} = useStore();

const activeModalSettings = computed(() => state.gui.activeModalSettings);
const wordpressSiteUrl = computed<string>(() => activeModalSettings.value.wordpressSiteUrl);
const errorsOccured = computed<string>(() => activeModalSettings.value.errorsOccured);

const message = errorsOccured.value ? t('builder.wordpressExportCompleteModalWarningDescriptionV2') : t('builder.wordpressExportCompleteModalDescription');

const closeModal = () => {
	dispatch(`gui/${CLOSE_MODAL}`, {
		name: MODAL_WORDPRESS_EXPORT_COMPLETE,
	});
};

const openWordPressLink = () => {
	window.open(wordpressSiteUrl.value, '_blank');
};
</script>

<template>
	<Teleport to="body">
		<ZyroModal
			:max-width="'376px'"
			:height="'auto'"
			position="center"
			class="wordpress-export-complete-modal"
			show-close-button
			content-padding="32px"
			@close-modal="closeModal"
		>
			<div class="wordpress-export-complete-modal__content">
				<Icon
					class="wordpress-export-complete-modal__icon"
					name="check_circle"
					color="success"
					is-filled
					dimensions="40px"
				/>
				<h2 class="wordpress-export-complete-modal__title text-heading-2">
					{{ t('builder.wordpressExportCompleteModalTitleV2') }}
				</h2>
				<p class="wordpress-export-complete-modal__description text-body-2">
					{{ message }}
				</p>

				<HostingerButton
					button-type="contained"
					@click="openWordPressLink"
				>
					{{ $t('builder.wordpressExportCompleteModalButton') }}
				</HostingerButton>
			</div>
		</ZyroModal>
	</Teleport>
</template>

<style lang="scss" scoped>
.wordpress-export-complete-modal {
	text-align: center;

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__title {
		margin: 12px 0 4px;
	}

	&__description {
		margin-bottom: 24px;
		color: var(--color-gray)
	}
}
</style>
