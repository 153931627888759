<script setup lang="ts">
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import { isAiImageGeneratorOpen } from '@/use/useAiImageGenerator';
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import {
	computed,
	onUnmounted,
} from 'vue';
import { useStore } from 'vuex';

const { getters } = useStore();

const emit = defineEmits<{
	'toggle-ai-generate': [];
}>();

const isMobileMode = computed(() => getters['gui/isMobileMode']);

onUnmounted(() => {
	isAiImageGeneratorOpen.value = false;
});
</script>

<template>
	<ControlsTooltip class="generate-image-button">
		<HostingerButton
			v-qa="'generate-image-button'"
			button-type="text"
			@click="emit('toggle-ai-generate')"
		>
			<template #icon>
				<Icon name="auto_awesome" />
			</template>
			<span
				v-if="!isMobileMode"
				class="text-body-2"
			>
				{{ $t('builder.aiImageGenerationGenerateImage') }}
			</span>
		</HostingerButton>
	</ControlsTooltip>
</template>

<style scoped lang="scss">
.generate-image-button {
	color: $color-primary;

	& button {
		padding: 6px 12px;
		border-radius: 6px;

		&:hover {
			background-color: $color-primary-light;
		}
	}

	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>
