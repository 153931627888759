<script setup lang="ts">
import {
	ref,
	computed,
	watch,
} from 'vue';
import { useStore } from 'vuex';
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import Popup from '@/components/global/Popup.vue';
import { useSiteStore } from '@/stores/siteStore';
import { useI18n } from 'vue-i18n';
import BuilderHeaderPublishButton from '@/components/builder-view/headers/BuilderHeaderPublishButton.vue';
import { usePrivatePage } from '@/use/usePrivatePage';
import { useHeaderPopups } from '@/use/useHeaderPopups';

const {
	state,
	getters,
} = useStore();

const siteStore = useSiteStore();
const { t } = useI18n();

const {
	openPrivatePage,
	updateIsPrivateModeActive,
} = usePrivatePage();

const {
	setIsPrivateModePopupVisible,
	isPrivateModePopupVisible,
} = useHeaderPopups();

const isStaff = computed(() => state.user.user?.isStaff);

const isMobileMode = computed(() => getters['gui/isMobileMode']);

const privateModeContainerRef = ref<HTMLElement | null>(null);
const isSiteBeingPublished = computed(() => state.gui.isSiteBeingPublished);

watch(isSiteBeingPublished, (newIsSiteBeingPublished) => {
	if (newIsSiteBeingPublished) {
		isPrivateModePopupVisible.value = false;
	}
});
</script>

<template>
	<div
		ref="privateModeContainerRef"
		class="private-mode"
		@mouseenter="!isSiteBeingPublished && !isMobileMode && setIsPrivateModePopupVisible({ value: true, })"
		@mouseleave="!isMobileMode && setIsPrivateModePopupVisible({
			value: false,
			isTimeoutEnabled: !isMobileMode,
		})"
	>
		<HostingerButton
			v-qa="'builder-header-btn-privateMode-popup-toggle'"
			button-type="plain"
			class="private-mode__popup-button"
			:class="{ 'private-mode__popup-button--active': isPrivateModePopupVisible }"
			@click.stop="!isSiteBeingPublished && isMobileMode && setIsPrivateModePopupVisible({ value: !isPrivateModePopupVisible, })"
		>
			<template #icon>
				<Icon :name="siteStore.isPrivateModeActive ? 'vpn_lock' : 'public'" />
			</template>
		</HostingerButton>

		<Popup
			v-if="isPrivateModePopupVisible"
			:target-ref="privateModeContainerRef"
			placement="bottom"
			:offset="0"
			auto-update
			:padding="isMobileMode ? 4 : 24"
			@click-outside="setIsPrivateModePopupVisible({ value: false })"
		>
			<div
				class="private-mode-popup"
				@mouseenter="!isMobileMode && setIsPrivateModePopupVisible({ value: true })"
				@mouseleave="!isMobileMode && setIsPrivateModePopupVisible({ value: false })"
			>
				<div class="private-mode-popup__content">
					<div
						v-if="isStaff"
						class="private-mode-popup__toggle-wrapper private-mode-popup__toggle-wrapper--margin-bottom"
					>
						<ZyroFieldToggle
							id="custom-code-toggle"
							class="private-mode-popup__private-mode-toggle"
							:model-value="siteStore.isCustomCodeDisabled"
							label="Disable custom code"
							@update:model-value="($event) => siteStore.setSiteCustomCodeDisabled($event)"
						>
							<template #icon-left>
								<Icon
									dimensions="20px"
									name="code_off"
								/>
							</template>
						</ZyroFieldToggle>
					</div>

					<div class="private-mode-popup__toggle-wrapper">
						<ZyroFieldToggle
							id="private-mode-toggle"
							v-qa="'builder-header-btn-privateMode-toggle'"
							class="private-mode-popup__private-mode-toggle"
							:model-value="siteStore.isPrivateModeActive"
							:label="t('builder.privateMode.toggleTitle')"
							@update:model-value="($event) => updateIsPrivateModeActive({
								isPrivateModeActive: $event,
								location: 'header_popup'
							})"
						>
							<template #icon-left>
								<Icon
									dimensions="20px"
									:name="siteStore.isPrivateModeActive ? 'vpn_lock' : 'public'"
								/>
							</template>
						</ZyroFieldToggle>
					</div>
					<p class="private-mode-popup__explanation text-body-2">
						{{ t('builder.privateMode.explanation') }}
					</p>

					<a
						v-qa="'builder-header-btn-privateMode-edit-page'"
						class="private-mode-popup__edit-page z-link text-body-2"
						@click="openPrivatePage(), setIsPrivateModePopupVisible({ value: false })"
					>
						{{ t('builder.privateMode.editPage') }}
					</a>

					<div class="private-mode-popup__footer">
						<BuilderHeaderPublishButton :should-show-check-mobile-popup="false" />
					</div>
				</div>
			</div>
		</Popup>
	</div>
</template>

<style lang="scss" scoped>
$default-popup-padding-x: 48px;
$default-mobile-popup-padding-x: 8px;

.private-mode {
    &__popup-button {
        margin: 0 4px;

       &--active {
            background-color: $color-primary-light;
			color: $color-primary;
       }
	}
}

.private-mode-popup {
	padding-top: 8px;

	@media screen and (max-width: $media-mobile) {
		width: calc(100% - $default-popup-padding-x);
	}

	&__content {
		display: flex;
		flex-direction: column;
		width: 400px;
		padding: 16px;
		border: 1px solid $color-gray-light;
		background-color: $color-light;
		border-radius: 16px;
		box-shadow: $box-shadow;

		@media screen and (max-width: $media-mobile) {
			width: calc(100vw - #{$default-mobile-popup-padding-x});
		}
	}

	&__toggle-wrapper {
		display: flex;
		align-items: center;
		gap: 8px;

		&--margin-bottom {
			margin-bottom: 12px;
		}

		&:deep() {
			.zyro-field-toggle {
				padding: 0;
			}
		}
	}

	&__explanation {
		margin: 8px 0;
		color: $color-gray;
	}

	&__edit-page {
		font-weight: 400;
	}

	&__footer {
		border-top: 1px solid $color-gray-border;
		margin-top: 16px;
		padding-top: 16px;
		display: inline-flex;
		justify-content: flex-end;
		gap: 16px;
	}
}

</style>
