/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
import router from '@/router';

import {
	RedirectState,
	REDIRECT_MUTATIONS,
} from '@/types/redirectModuleTypes';

const state: RedirectState = {
	routeName: '',
	prevRouteName: '',
	params: {},
};

const mutations = {
	[REDIRECT_MUTATIONS.SET_REDIRECT](
		state: RedirectState,
		params: { [key: string]: any },
	) {
		state.routeName = String(router.currentRoute.value.name) || '';
		state.params = {
			...state.params,
			...params,
		};
	},
	[REDIRECT_MUTATIONS.UNSET_REDIRECT](state: RedirectState) {
		state.routeName = '';
		state.params = {};
	},
};

export default {
	state,
	mutations,
};
