import {
	AI_IMAGE_GENERATE_COST,
	AI_IMAGE_GENERATE_DEFAULT_COUNT,
	LOCAL_STORAGE_KEY_CREDITS_ORDER,
} from '@/constants/builderConstants';
import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import { confirmCreditsPurchase } from '@/api/BillingApi';
import { THEME_SUCCESS } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { DECREMENT_CREDITS } from '@/store/builder/user';
import EventLogApi from '@/api/EventLogApi';

const CREDITS_ORDER_CHECK_INTERVAL = 2000;
const MAX_CHECK_COUNT = 20;

export const useUserCredits = () => {
	const {
		state,
		dispatch,
		commit,
	} = useStore();

	const userCredits = computed(() => state.user.user.credits);
	const checksCounts = ref(0);

	const isCreditsOrderCheckLoading = ref(false);

	const stopCheckingCreditsOrder = () => {
		localStorage.removeItem(LOCAL_STORAGE_KEY_CREDITS_ORDER);
		isCreditsOrderCheckLoading.value = false;
	};

	const checkCreditsOrder = async () => {
		if (checksCounts.value >= MAX_CHECK_COUNT) {
			stopCheckingCreditsOrder();

			return;
		}

		const orderToken = localStorage.getItem(LOCAL_STORAGE_KEY_CREDITS_ORDER);
		const query = new URLSearchParams(window.location.search);
		const hasCreditsOrderQuery = query.get('creditsPurchased') === '1';

		if (!hasCreditsOrderQuery || !orderToken) {
			return;
		}

		try {
			isCreditsOrderCheckLoading.value = true;

			await confirmCreditsPurchase(orderToken);
			await dispatch('user/getUser', {
				force: true,
			});

			localStorage.removeItem(LOCAL_STORAGE_KEY_CREDITS_ORDER);

			isCreditsOrderCheckLoading.value = false;

			dispatch('notifications/notify', {
				theme: THEME_SUCCESS,
				headingI18nKeyPath: 'builder.imageCreditsToasterHeader',
				messageI18nKeyPath: 'builder.imageCreditsToasterMessage',
			});

			EventLogApi.logEvent({
				eventName: 'website_builder.ai_image_generator.payment_successful',
			});
		} catch (error: any) {
			const responseStatus = error.request.status;

			if (responseStatus !== 402) {
				stopCheckingCreditsOrder();

				return;
			}

			setTimeout(async () => {
				checksCounts.value += 1;
				await checkCreditsOrder();
			}, CREDITS_ORDER_CHECK_INTERVAL);
		}
	};

	const decrementUserCredits = (
		{ generateImageCount = AI_IMAGE_GENERATE_DEFAULT_COUNT } = {},
	) => commit(`user/${DECREMENT_CREDITS}`, Math.round(AI_IMAGE_GENERATE_COST * generateImageCount));

	return {
		userCredits,
		isCreditsOrderCheckLoading,
		checkCreditsOrder,
		decrementUserCredits,
	};
};
