<script setup lang="ts">
import {
	onBeforeUnmount,
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroCheckbox from '@/components/global/ZyroCheckbox.vue';
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import ColorPicker from '@/components/global/color-picker/ColorPicker.vue';
import { useI18n } from 'vue-i18n';
import { SortingOptions } from '@zyro-inc/site-modules/types';
import { ECOMMERCE_SORTING_VALUES } from '@zyro-inc/site-modules/constants/ecommerce';

const {
	getters,
	state,
	dispatch,
} = useStore();
const { t } = useI18n();

const currentBlock = computed(() => getters.currentBlock);
const textColor = computed(() => currentBlock.value.productSorting?.textColor || '#000');

const getIsSortingOptionEnabled = ({ optionId }: { optionId: SortingOptions }) => currentBlock
	.value
	.productSorting
	?.sortingOptions
	?.find(({ id }: { id: string }) => id === optionId)?.isEnabled ?? true;

const isTextColorOpen = ref(false);
const sortingOptions = [
	{
		id: SortingOptions.DEFAULT,
		isEnabled: getIsSortingOptionEnabled({
			optionId: SortingOptions.DEFAULT,
		}),
		value: ECOMMERCE_SORTING_VALUES[SortingOptions.DEFAULT],
	},
	{
		id: SortingOptions.PRICE_LTH,
		isEnabled: getIsSortingOptionEnabled({
			optionId: SortingOptions.PRICE_LTH,
		}),
		value: ECOMMERCE_SORTING_VALUES[SortingOptions.PRICE_LTH],
	},
	{
		id: SortingOptions.PRICE_HTL,
		isEnabled: getIsSortingOptionEnabled({
			optionId: SortingOptions.PRICE_HTL,
		}),
		value: ECOMMERCE_SORTING_VALUES[SortingOptions.PRICE_HTL],
	},
	{
		id: SortingOptions.MOST_RECENT,
		isEnabled: getIsSortingOptionEnabled({
			optionId: SortingOptions.MOST_RECENT,
		}),
		value: ECOMMERCE_SORTING_VALUES[SortingOptions.MOST_RECENT],
	},
];
const sortingOptionsTranslations = {
	[SortingOptions.DEFAULT]: t('builder.productSortingDefault'),
	[SortingOptions.PRICE_LTH]: t('builder.productSortingPriceLTH'),
	[SortingOptions.PRICE_HTL]: t('builder.productSortingPriceHTL'),
	[SortingOptions.MOST_RECENT]: t('builder.mostRecent'),
};

const updateSortingValue = (key: string, value: any) => {
	dispatch('updateBlockData', {
		blockId: state.currentBlockId,
		blockData: {
			productSorting: {
				textColor: currentBlock.value.productSorting?.textColor ?? textColor.value,
				sortingOptions: currentBlock.value.productSorting?.sortingOptions ?? sortingOptions,
				[key]: value,
			},
		},
		merge: true,
	});
};

const updateIsSortingEnabled = (value: boolean) => {
	updateSortingValue('enabled', value);
};

const updateTextColor = (value: string) => {
	updateSortingValue('textColor', value);
};

const updateSortingOptions = (id: string, value: boolean) => {
	const updatedSortingOptions = currentBlock.value.productSorting?.sortingOptions.map((option: { id: string }) => (option.id === id ? {
		...option,
		isEnabled: value,
	} : option));

	updateSortingValue('sortingOptions', updatedSortingOptions);
};

onBeforeUnmount(() => {
	dispatch('undoRedo/createSnapshot');
});
</script>

<template>
	<div class="ecommerce-tab-sorting">
		<ZyroFieldToggle
			id="sortingButton"
			class="ecommerce-tab-sorting__controls"
			:label="$t('builder.enableProductSorting')"
			:model-value="currentBlock.productSorting?.enabled"
			@update:model-value="updateIsSortingEnabled"
		/>
		<template v-if="currentBlock.productSorting?.enabled">
			<div class="ecommerce-tab-sorting__color-wrapper">
				<ZyroLabel>{{ $t('builder.editText.textColor') }}</ZyroLabel>
				<ColorPicker
					:color="textColor"
					:offset="250"
					placement="left"
					:is-open="isTextColorOpen"
					@update-color="updateTextColor"
					@click-outside="isTextColorOpen = false"
					@toggle="isTextColorOpen = !isTextColorOpen"
				/>
			</div>
			<div class="ecommerce-tab-sorting__checkbox-wrapper">
				<ZyroLabel>{{ $t('builder.sortingOptions') }}</ZyroLabel>
				<div
					v-for="sortingOption in sortingOptions"
					:key="sortingOption.id"
					class="ecommerce-tab-sorting__checkbox-wrapper__checkbox"
				>
					<ZyroCheckbox
						:id="sortingOption.id"
						theme="dark"
						:model-value="sortingOption.isEnabled"
						@update:model-value="updateSortingOptions(sortingOption.id, $event)"
					/>
					<p class="text-body-2">
						{{ sortingOptionsTranslations[sortingOption.id] }}
					</p>
				</div>
			</div>
		</template>
	</div>
</template>

<style lang="scss" scoped>
.ecommerce-tab-sorting {
	&__controls {
		padding: 0;
	}

	&__color-wrapper {
		display: flex;
		margin: 24px 0;
	}

	&__checkbox-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;

		&__checkbox {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
}
</style>
