import { useStore } from 'vuex';
import { Toast } from '@/types/utilTypes';

export const useNotifications = () => {
	const {
		dispatch,
		commit,
	} = useStore();

	const notify = (settings: Toast) => dispatch('notifications/notify', settings);

	const removeNotificationById = (id: string) => commit('notifications/removeToastById', id);

	return {
		notify,
		removeNotificationById,
	};
};
