import {
	ref,
	watch,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import { useMousePosition } from '@/use/useMousePosition';
import { useTouchPosition } from '@/use/useTouchPosition';
import { useScrollHandler } from '@/use/useScrollHandler';
import { areTouchEventsSupported } from '@/utils/areTouchEventsSupported';

import {
	CONTROL_LINE_SCROLL_START_THRESHOLD,
	CONTROL_LINE_SCROLL_AMOUNT,
	CONTROL_LINE_SCROLL_INTERVAL_DELAY,
} from '@/constants/builderConstants';

const autoScrollInterval = ref(null);

export const useDrag = ({ trackScroll = true } = {}) => {
	const {
		mouseX,
		mouseY,
	} = useMousePosition();
	const {
		touchX,
		touchY,
	} = useTouchPosition();
	const { scrollYAmount } = useScrollHandler();

	const { state } = useStore();

	const builderPreviewContainerRef = computed(() => state.gui.builderPreviewContainerRef);

	const initialDragXPosition = ref(null);
	const initialDragYPosition = ref(null);
	const initialScrollY = ref(0);
	const isDragging = ref(false);
	const hasMouseMoved = ref(false);

	const dragXPosition = computed(() => (areTouchEventsSupported ? touchX.value : mouseX.value));
	const dragYPosition = computed(() => (areTouchEventsSupported ? touchY.value : mouseY.value));

	const dragMoveEventType = computed(() => (areTouchEventsSupported ? 'touchmove' : 'mousemove'));
	const dragEndEventType = computed(() => (areTouchEventsSupported ? 'touchend' : 'mouseup'));

	const startDragging = ({ onDragEnd = null } = {}) => {
		isDragging.value = true;
		initialDragXPosition.value = dragXPosition.value;
		initialDragYPosition.value = dragYPosition.value;

		initialScrollY.value = scrollYAmount.value;

		document.addEventListener(dragEndEventType.value, () => {
			onDragEnd?.();
			initialDragXPosition.value = null;
			initialDragYPosition.value = null;

			hasMouseMoved.value = false;
			isDragging.value = false;
		}, {
			once: true,
		});
		document.addEventListener(dragMoveEventType.value, () => {
			// Check if dragging action is still active
			// It can be inactive when this fires if the user e.g.: clicked on an element
			if (isDragging.value) {
				hasMouseMoved.value = true;
			}
		}, {
			once: true,
		});
	};

	const scrollDeltaY = computed(() => ((trackScroll && hasMouseMoved.value) ? scrollYAmount.value - initialScrollY.value : 0));
	const dragDeltaXPosition = computed(() => (hasMouseMoved.value ? dragXPosition.value - initialDragXPosition.value : 0));
	const dragDeltaYPosition = computed(() => (hasMouseMoved.value
		? dragYPosition.value - initialDragYPosition.value + scrollDeltaY.value
		: 0
	));

	const shouldScrollDown = computed(() => {
		const bottomThreshold = dragYPosition.value > document.documentElement.clientHeight - CONTROL_LINE_SCROLL_START_THRESHOLD;
		const isCursorBelowHandle = (dragYPosition.value - initialDragYPosition.value) > 0;

		return bottomThreshold && isCursorBelowHandle && isDragging.value;
	});

	const shouldScrollUp = computed(() => {
		const topThreshold = dragYPosition.value < CONTROL_LINE_SCROLL_START_THRESHOLD;
		const isCursorAboveHandle = (dragYPosition.value - initialDragYPosition.value) < 0;

		return topThreshold && isCursorAboveHandle && isDragging.value;
	});

	const scrollPage = (scrollByAmount) => {
		builderPreviewContainerRef.value.scrollBy(0, scrollByAmount);
	};

	watch([
		shouldScrollDown,
		shouldScrollUp,
	], () => {
		if (!trackScroll) {
			return;
		}

		if ((shouldScrollDown.value || shouldScrollUp.value) && !autoScrollInterval.value) {
			autoScrollInterval.value = setInterval(() => {
				scrollPage(shouldScrollDown.value ? CONTROL_LINE_SCROLL_AMOUNT : -CONTROL_LINE_SCROLL_AMOUNT);
			}, CONTROL_LINE_SCROLL_INTERVAL_DELAY);
		} else {
			clearInterval(autoScrollInterval.value);
			autoScrollInterval.value = null;
		}
	});

	return {
		startDragging,
		dragDeltaXPosition,
		dragDeltaYPosition,
		isDragging,
		hasMouseMoved,
	};
};
