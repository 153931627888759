<template>
	<div class="style-select">
		<p
			v-if="label"
			data-testid="select-label"
			class="text-body-2 style-select__label"
		>
			{{ label }}
		</p>
		<ZyroSelect
			v-qa="`style-select-select-${label}`"
			label-key="title"
			:options="styleOptions"
			:model-value="selectedStyle"
			:taggable="allowCustomOptions ? taggable : undefined"
			:create-option="allowCustomOptions ? createOption : undefined"
			:filterable="allowCustomOptions ? filterable : undefined"
			@update:model-value="updateSelectedStyle"
			@search="handleSearch"
		/>
	</div>
</template>

<script>
import ZyroSelect from '@/components/global/ZyroSelect.vue';

import {
	mapActions,
	mapGetters,
} from 'vuex';

import { PROPERTY_FONT_WEIGHT } from '@zyro-inc/site-modules/constants/globalStyles';
import { convertWeightStringToNumber } from '@zyro-inc/site-modules/utils/font';

import { defineComponent } from 'vue';
import { useSiteStore } from '@/stores/siteStore';

export default defineComponent({
	name: 'StyleSelectControl',

	components: {
		ZyroSelect,
	},

	props: {
		customOptionsValidator: {
			type: Function,
			default: () => true,
		},
		allowCustomOptions: {
			type: Boolean,
			default: false,
		},
		element: {
			type: String,
			required: true,
		},
		property: {
			type: String,
			required: true,
		},
		styleOptions: {
			type: Array,
			required: true,
		},
		label: {
			type: String,
			default: '',
		},
	},
	emits: ['update'],

	setup() {
		const siteStore = useSiteStore();

		return {
			siteStore,
		};
	},

	data() {
		return {
			taggable: true,
			filterable: false,
		};
	},

	computed: {
		...mapGetters([
			'siteStyles',
			'siteFonts',
			'siteBlocks',
		]),

		selectedStyle() {
			const isPropertyFontWeight = this.property === PROPERTY_FONT_WEIGHT;
			let styleValue = this.siteStyles[this.element][this.property];

			if (isPropertyFontWeight) {
				styleValue = convertWeightStringToNumber(styleValue);
			}

			if (!styleValue) {
				return {
					title: `Select ${this.label} ...`,
				};
			}

			const selectedOption = this.styleOptions
				.find((styleOption) => styleOption.value === styleValue);

			const defaultSelectedOption = {
				title: styleValue,
				value: styleValue,
			};

			return selectedOption ?? defaultSelectedOption;
		},
	},

	methods: {
		...mapActions(['updateBlockData']),

		handleSearch(value) {
			if (this.allowCustomOptions && this.customOptionsValidator(value)) {
				this.taggable = true;
				this.filterable = false;

				return;
			}

			this.taggable = false;
			this.filterable = true;
		},
		createOption(value) {
			return {
				title: `${value}px`,
				value: `${value}px`,
			};
		},

		updateSelectedStyle(style) {
			const isEditingNavigationFont = this.element === 'nav-link' && [
				'font-weight',
				'font-family',
			].includes(this.property);

			if (isEditingNavigationFont) {
				const headerBlockData = this.siteBlocks.header;
				const {
					fontFamily: _fontFamily,
					fontWeight: _fontWeight,
					...restData
				} = headerBlockData;

				// Reset local header font so it could fallback to global
				this.updateBlockData({
					blockId: 'header',
					blockData: {
						...restData,
					},
				});
			}

			this.$emit('update', style);
			this.siteStore.setSiteStyleProperty({
				element: this.element,
				property: this.property,
				value: style.value,
			});
		},
	},
});
</script>

<style lang="scss" scoped>
.style-select {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	&__label {
		padding: 8px 0;
	}

	:deep(.vs__selected-options) {
		overflow: hidden;
	}
}
</style>
