import { ref } from 'vue';
import { useAiWriterStore } from '@/stores/aiWriterStore';
import { AI_WRITER_DESCRIPTION_NOT_CLEAR_TEXT_RESPONSE } from '@/constants/builderConstants';
import { useNotifications } from '@/use/useNotifications';
import { useI18n } from 'vue-i18n';
import { defineStore } from 'pinia';
import { PINIA_STORES } from '@/constants/stores';

export const useAiWriterDrawerStore = defineStore(PINIA_STORES.AI_WRITER_DRAWER, () => {
	const userPrompt = ref('');
	const generatedText = ref('');
	const isPromptInputTouched = ref(false);
	const isLoading = ref(false);

	const aiWriterStore = useAiWriterStore();
	const { notify } = useNotifications();
	const { t } = useI18n();

	const generateAiText = async () => {
		isPromptInputTouched.value = true;
		isLoading.value = true;

		const { text } = await aiWriterStore.getAiGeneratedText({
			userPrompt: userPrompt.value,
		});

		isLoading.value = false;

		if (!text) {
			return;
		}

		if (text === AI_WRITER_DESCRIPTION_NOT_CLEAR_TEXT_RESPONSE) {
			notify({
				message: t('builder.AITextGenerationDescriptionNotClear'),
			});

			return;
		}

		generatedText.value = text;
	};

	const setUserPrompt = (value: string) => {
		userPrompt.value = value;
	};

	return {
		generateAiText,
		userPrompt,
		setUserPrompt,
		isPromptInputTouched,
		isLoading,
		generatedText,
	};
});
