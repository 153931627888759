<script lang="ts" setup>
import PurchaseDetailsSkeletonLoader from '@/components/sekeleton-loaders/PurchaseDetailsSkeletonLoader.vue';
import currencyService from '@/services/currencyService';
import { BillingEstimate } from '@/types/billingTypes';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
	isLoading: boolean;
	estimate: BillingEstimate;
}

const props = withDefaults(defineProps<Props>(), {
	isLoading: false,
});

const { t } = useI18n();

const purchaseDetails = computed(() => ([
	{
		title: t('common.taxesAndFees'),
		value: props.estimate.total_tax,
	},
	{
		title: t('common.total'),
		value: props.estimate.total,
	},
]));

</script>

<template>
	<PurchaseDetailsSkeletonLoader v-if="isLoading" />
	<ul
		v-else
		class="purchase-details"
	>
		<li
			v-for="detail in purchaseDetails"
			:key="detail.title"
			class="detail-item text-body-2"
		>
			<p class="detail-item__title">
				{{ detail.title }}
			</p>
			<p>{{ currencyService.format(estimate.currency_code, detail.value) }}</p>
		</li>
	</ul>
</template>

<style lang="scss" scoped>
.purchase-details {
	list-style: none;
	padding: 16px;
	border-radius: 4px;
	background-color: var(--color-gray-light);
	margin-top: 16px;
}

.detail-item {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;

	&__title {
		font-weight: 700;
	}

	&:nth-last-child(1) {
		margin-bottom: 0;
	}
}
</style>
