import { ref } from 'vue';

const isHeaderDomainConnectionStatusExpanded = ref<boolean>(false);
const isSavePopupVisible = ref<boolean>(false);
const isPrivateModePopupVisible = ref<boolean>(false);

const MOUSE_LEAVE_TIMEOUT = 1000;

export const useHeaderPopups = () => {
	const mouseLeaveTimeoutId = ref<ReturnType<typeof setTimeout> | undefined>(undefined);

	const setIsHeaderDomainConnectionStatusExpanded = (value: boolean) => {
		isSavePopupVisible.value = false;
		isPrivateModePopupVisible.value = false;

		isHeaderDomainConnectionStatusExpanded.value = value;
	};

	const setIsSavePopupVisible = (value: boolean) => {
		isHeaderDomainConnectionStatusExpanded.value = false;
		isPrivateModePopupVisible.value = false;

		isSavePopupVisible.value = value;
	};

	const setIsPrivateModePopupVisible = ({
		value,
		isTimeoutEnabled,
	}: {value: boolean, isTimeoutEnabled?: boolean}) => {
		isSavePopupVisible.value = false;
		isHeaderDomainConnectionStatusExpanded.value = false;

		if (!value && isTimeoutEnabled) {
			mouseLeaveTimeoutId.value = setTimeout(() => {
				isPrivateModePopupVisible.value = value;
			}, MOUSE_LEAVE_TIMEOUT);

			return;
		}

		clearTimeout(mouseLeaveTimeoutId.value);
		isPrivateModePopupVisible.value = value;
	};

	return {
		setIsSavePopupVisible,
		setIsHeaderDomainConnectionStatusExpanded,
		setIsPrivateModePopupVisible,
		isPrivateModePopupVisible,
		isSavePopupVisible,
		isHeaderDomainConnectionStatusExpanded,
	};
};
