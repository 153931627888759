import { rehype } from 'rehype';
import { visit } from 'unist-util-visit';

import { mergeObjects } from '@/utils/mergeObjects';

import { REHYPE_SETTINGS } from '@zyro-inc/site-modules/constants/rehypeSettings';
import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import {
	AI_BUILDER_FOOTER_BRAND_ELEMENT_ID,
	AI_BUILDER_TEMPLATE_SECTION_TYPE_REGEXES,
} from '@/constants/builderConstants';
import {
	MAX_LOGO_WIDTH,
	MAX_LOGO_WIDTH_MOBILE,
} from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabLogo.vue';

const REGEX_ENDS_WITH_DASH_AND_NUMBER = /-\d+$/;

/**
 * @param {{
 *   template: object,
 * }}
 * @returns {string} - Returns template header block backgroundColor
*/

export const getTemplateNavigationColor = ({ template }) => template.languages.system.blocks.header.navLinkTextColor;

// removes last character from string so it would be singular
export const getSingularContentKey = ({ contentKey }) => (
	contentKey.endsWith('s') ? contentKey.slice(0, -1) : contentKey
);

/**
 * This function takes an HTML string and returns a string without HTML tags
 * @param {string} htmlString - The HTML string
 * @returns {string} - The string without HTML tags
 */
export const getHtmlText = ({ htmlString }) => {
	const { data } = rehype()
		.data('settings', REHYPE_SETTINGS)
		.use(() => (tree, file) => {
			visit(tree, 'element', (node) => {
				if (!node.children[0]) {
					return;
				}

				// eslint-disable-next-line no-param-reassign
				file.data.text = node.children[0].value;
			});
		})
		.processSync(htmlString);

	return data.text;
};

/**
 * Returns all the texts used in all template text elements
 * @param {Array<object>} templateElements - The template elements data
 * @returns {Array<string>} - The array of texts used in text elements
 */
export const getTextElementTexts = ({ templateElements }) => {
	const gridTextBoxElements = templateElements.filter((elementData) => elementData.type === 'GridTextBox');

	return gridTextBoxElements.map((elementData) => getHtmlText({
		htmlString: elementData.content,
	}));
};

/**
 * Returns all the texts used in all template buttons
 * @param {Array<object>} templateElements - The template elements data
 * @returns {Array<string>} - The array of texts used in buttons
 */
export const getButtonTexts = ({ templateElements }) => {
	const buttonElements = templateElements.filter((elementData) => elementData.type === 'GridButton');

	return buttonElements.map((elementData) => elementData.content);
};

/**
 * Returns all the texts used in all template forms
 * @param {Array<object>} templateElements - The template elements data
 * @returns {Array<string>} - The array of texts used in forms
 */
export const getFormTexts = ({ templateElements }) => {
	// Get the GridForm schema data
	const gridFormsData = templateElements.filter((elementData) => elementData.type === 'GridForm');
	const gridFormSchemaData = gridFormsData.flatMap((elementData) => elementData.settings.schema);

	// Get all the texts used in the form
	const formSchemaTexts = gridFormSchemaData.flatMap((schemaItem) => {
		const labelText = schemaItem.inputLabel;
		const placeholderText = schemaItem.placeholder;
		const validationMessages = schemaItem['validation-messages'] ? Object.values(schemaItem['validation-messages']) : [];

		return [
			labelText,
			placeholderText,
			...validationMessages,
		];
	});
	const formSuccessMessageTexts = gridFormsData.map((elementData) => elementData.settings.successMessage);
	const formButtonTexts = gridFormsData.map((elementData) => elementData.submitButtonData.content);

	return [
		...formSchemaTexts,
		...formSuccessMessageTexts,
		...formButtonTexts,
	];
};

/**
 * Finds and returns element entries, that don't have predefined template section ids
 * @param {Array<string,object>} templateElementEntries - all template element entries
 * @returns {Array<string,object>} - The array of element entries that are translatable
 */
export const getTranslatableElementIds = ({ templateElementEntries }) => templateElementEntries.filter(
	([elementId]) => !elementId.startsWith('footer')
		&& !AI_BUILDER_TEMPLATE_SECTION_TYPE_REGEXES.some((regex) => {
			const matchWord = regex.match(/(\w+)/);
			const extractedWordKey = matchWord[1];

			return elementId.startsWith(extractedWordKey);
		}),
);

export const replaceHtmlText = ({
	htmlString,
	newText,
}) => {
	const replaceText = () => (tree) => {
		visit(tree, 'element', (node) => {
			// for cases when tag is empty or it's a self-closing tag
			if (!node.children[0] || !newText) {
				return;
			}

			// eslint-disable-next-line no-param-reassign
			node.children[0].value = newText;
		});
	};

	return rehype()
		.data('settings', REHYPE_SETTINGS)
		.use(replaceText)
		.processSync(htmlString)
		.toString();
};

/**
 * This function replaces all instances of the original text with the AI translation in the template data
 * @param {object} templateData - the template data to modify
 * @param {Map<string, string>} aiTranslationMap - the map of original text to AI translation
 * @returns {object} - the modified template data
 */
export const replaceTextWithAiTranslations = ({
	templateData,
	aiTranslationMap,
}) => {
	if (!aiTranslationMap || !Object.keys(aiTranslationMap).length) {
		return templateData;
	}

	const templateElementEntries = Object.entries(templateData.languages[SYSTEM_LOCALE].elements);
	const translatableElementEntries = getTranslatableElementIds({
		templateElementEntries,
	});

	const elementEntriesWithAiTranslations = translatableElementEntries.map(([elementId, elementData]) => {
		if (elementData.type === 'GridTextBox') {
			const modifiedElementData = mergeObjects(elementData, {
				content: replaceHtmlText({
					htmlString: elementData.content,
					newText: aiTranslationMap[getHtmlText({
						htmlString: elementData.content,
					})],
				}),
			});

			return [
				elementId,
				modifiedElementData,
			];
		}

		if (elementData.type === 'GridButton') {
			const modifiedElementData = mergeObjects(elementData, {
				content: aiTranslationMap[elementData.content],
			});

			return [
				elementId,
				modifiedElementData,
			];
		}

		if (elementData.type === 'GridForm') {
			const modifiedElementData = mergeObjects(elementData, {
				settings: {
					schema: elementData.settings.schema.map((schemaItem) => {
						const modifiedSchemaItem = {
							inputLabel: aiTranslationMap[schemaItem.inputLabel],
							placeholder: aiTranslationMap[schemaItem.placeholder],
							'validation-messages': Object.fromEntries(Object.entries(schemaItem['validation-messages']).map(([key, value]) => [
								key,
								aiTranslationMap[value],
							])),
						};

						return {
							...schemaItem,
							...modifiedSchemaItem,
						};
					}),
					successMessage: aiTranslationMap[elementData.settings.successMessage],
				},
				submitButtonData: {
					content: aiTranslationMap[elementData.submitButtonData.content],
				},
			});

			return [
				elementId,
				modifiedElementData,
			];
		}

		return [
			elementId,
			elementData,
		];
	});

	const pagesWithTranslatedPageNames = Object.fromEntries(Object.entries(templateData.languages[SYSTEM_LOCALE].pages)
		.map(([pageId, pageData]) => (
			[
				pageId,
				{
					...pageData,
					name: aiTranslationMap[pageData.name] || pageData.name,
				},
			]
		)));

	return mergeObjects(templateData, {
		languages: {
			[SYSTEM_LOCALE]: {
				elements: {
					...Object.fromEntries(elementEntriesWithAiTranslations),
				},
				pages: {
					...pagesWithTranslatedPageNames,
				},
			},
		},
	});
};

/**
 * @param {{
 *   templateElement: object,
 *   aiTextData: string,
 * }}
 * @returns {object} Returns updated template element with new AI generated content
*/

export const updateTextElementWithAiContent = ({
	templateElement,
	aiTextData,
}) => mergeObjects(templateElement, {
	content: replaceHtmlText({
		htmlString: templateElement.content,
		newText: aiTextData,
	}),
});

/**
 * @param {{
 *   sectionId: string,
 *   contentKey: string,
 *   content: string | Array,
 * }}
 *  @returns {Array} ['elementId', { ...elementData }] - Returns array of entries with element id and element data.
 *  Were element id is generated based on section id and content key
*/

export const getSectionElementObjectEntries = ({
	sectionId,
	contentKey,
	content,
	elementRepeatCount,
}) => {
	const singularContentKey = getSingularContentKey({
		contentKey,
	});
	const primaryElementId = `${sectionId}-${singularContentKey}`;
	let elementIds = [primaryElementId];

	if (elementRepeatCount) {
		elementIds = [
			...elementIds,
			...Array.from({
				length: elementRepeatCount,
			}, (_, index) => (`${sectionId}-${index + 1}-${singularContentKey}`)),
		];
	}

	return elementIds.flatMap((elementId) => {
		if (typeof content === 'string') {
			const elementDataEntries = [
				elementId,
				content,
			];

			return [elementDataEntries];
		}

		if (contentKey === 'items') {
			return content.flatMap((item, index) => Object.keys(item)
				.map((itemContentKey) => (
					[
						`${elementId}-${itemContentKey}-${index + 1}`,
						item[itemContentKey],
					]
				)));
		}

		return content.map((_, index) => ([
			`${elementId}-${index + 1}`,
			content[index],
		]));
	});
};

/**
 * @param {{
 *   templateData: object,
 *   aiGeneratedElementsContent: array,
 * }}
 * @returns {object} Returns templates elements object with updated elements content
*/

export const updateChangeableTemplateElementsWithAiContent = ({
	templateData,
	aiGeneratedElementsContent,
}) => Object.fromEntries(
	aiGeneratedElementsContent
		.map(([elementId, aiGeneratedElementContent]) => {
			const templateElement = templateData.languages[SYSTEM_LOCALE].elements[elementId];

			if (!templateElement) {
				return [];
			}

			if (elementId.includes('image')) {
				return [
					elementId,
					mergeObjects(templateElement, {
						settings: {
							path: aiGeneratedElementContent.path,
							alt: aiGeneratedElementContent.alt,
							origin: aiGeneratedElementContent.origin,
						},

					}),
				];
			}

			return [
				elementId,
				updateTextElementWithAiContent({
					templateElement,
					aiTextData: aiGeneratedElementContent,
				}),
			];
		})
		.filter((entry) => entry.length),
);

/**
 * @param {{
 *   templateData: object,
 *   aiGeneratedSections: object,
 * }}
 * @returns {array} Returns array of entries with element id and element data.
*/

export const getAiGeneratedElementsContent = ({
	aiGeneratedSections,
	templateData,
}) => Object.entries(aiGeneratedSections)
	.flatMap(([sectionId, aiGeneratedSection]) => Object.entries(aiGeneratedSection)
		.flatMap(([contentKey, aiGeneratedElementContent]) => {
			// Check if element is repeated in the template because some elements need to reuse the same content of the original one
			const elementsThatEndsWithNumber = Object.keys(templateData.languages[SYSTEM_LOCALE].blocks).filter(
				(key) => key.includes(sectionId) && REGEX_ENDS_WITH_DASH_AND_NUMBER.test(key),
			);

			return getSectionElementObjectEntries({
				sectionId,
				contentKey,
				content: aiGeneratedElementContent,
				elementRepeatCount: elementsThatEndsWithNumber?.length,
			});
		}));

/**
 * @param {{
 *   templateData: object,
 *   aiGeneratedSections: object,
 * }}
 * @returns {object} Returns template with updated elements content
*/

export const updateTemplateElementsWithAiGeneratedContent = ({
	aiGeneratedSections,
	templateData,
}) => {
	const aiGeneratedElementsContent = getAiGeneratedElementsContent({
		aiGeneratedSections,
		templateData,
	});
	const updatedChangeableTemplateElements = updateChangeableTemplateElementsWithAiContent({
		templateData,
		aiGeneratedElementsContent,
	});

	return mergeObjects(templateData, {
		languages: {
			[SYSTEM_LOCALE]: {
				elements: {
					...updatedChangeableTemplateElements,
				},
			},
		},
	});
};

/**
 * @param {{
 *   templateData: object,
 *   brandName: string,
 * }}
 * @returns {object} Returns template with updated footer brand name
*/
export const updateTemplateFooterWithBrandName = ({
	brandName,
	templateData,
}) => {
	const templateElement = templateData.languages[SYSTEM_LOCALE].elements[AI_BUILDER_FOOTER_BRAND_ELEMENT_ID];

	if (!templateElement) {
		return templateData;
	}

	return mergeObjects(templateData, {
		languages: {
			[SYSTEM_LOCALE]: {
				elements: {
					[AI_BUILDER_FOOTER_BRAND_ELEMENT_ID]: {
						content: replaceHtmlText({
							htmlString: templateElement.content,
							newText: brandName,
						}),
					},
				},
			},
		},
	});
};

export const updateTemplateWithMetaData = ({
	siteMetaHtmlLanguage,
	siteMetaTitle,
	homePageMetaTitle,
	homePageMetaDescription,
	homePageMetaKeywords,
	templateData,
}) => {
	const templateDataWithUpdatedMeta = mergeObjects(
		templateData,
		{
			meta: {
				metaTitle: siteMetaTitle,
				metaHtmlLanguage: siteMetaHtmlLanguage,
			},
		},
	);

	if (!homePageMetaDescription || !homePageMetaTitle) {
		return templateDataWithUpdatedMeta;
	}

	return mergeObjects(templateDataWithUpdatedMeta, {
		languages: {
			[SYSTEM_LOCALE]: {
				pages: {
					home: {
						meta: {
							title: homePageMetaTitle,
							keywords: homePageMetaKeywords,
							description: homePageMetaDescription,
						},
					},
				},
			},
		},
	});
};

/**
 * @param {{
 *   templateData: object,
 * }}
 * @returns {object} Returns template with removed logo svg
*/
export const removeTemplateLogoSvg = ({ templateData }) => {
	const {
		logoSvg,
		...rest
	} = templateData.languages[SYSTEM_LOCALE].blocks.header.settings;

	return {
		...templateData,
		languages: {
			[SYSTEM_LOCALE]: {
				...templateData.languages[SYSTEM_LOCALE],
				blocks: {
					...templateData.languages[SYSTEM_LOCALE].blocks,
					header: {
						...templateData.languages[SYSTEM_LOCALE].blocks.header,
						settings: {
							...rest,
						},
					},
				},
			},
		},
	};
};

/**
 * @param {{
 *   templateData: object,
 *   logoSvg: object,
 *   color: string,
 * }}
 * @returns {object} Returns template with logo svg
*/
export const updateTemplateWithLogoSvg = ({
	logoSvg,
	color,
	templateData,
}) => {
	const logoSvgWithTemplateColors = logoSvg.svg.replace('currentColor', color);
	const logoWidthDesktop = Math.min(logoSvg.width, MAX_LOGO_WIDTH);
	const logoWidthMobile = Math.min(logoSvg.width, MAX_LOGO_WIDTH_MOBILE);

	return mergeObjects(templateData, {
		languages: {
			[SYSTEM_LOCALE]: {
				blocks: {
					header: {
						desktop: {
							logoHeight: logoSvg.height,
						},
						mobile: {
							logoHeight: logoSvg.height,
						},
						settings: {
							logoSvg: logoSvgWithTemplateColors,
							styles: {
								'logo-width': `${logoWidthDesktop}px`,
								'm-logo-width': `${logoWidthMobile}px`,
							},
						},
					},
				},
			},
		},
	});
};
