<script setup lang="ts">
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';

import EditTextMoreItem from '@/components/builder-controls/edit-text/EditTextMoreItem.vue';
import { SELECTOR_DATA_PORTAL_BUILDER_PREVIEW } from '@/constants/builderConstants';
import {
	computed,
	ref,
	inject,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Popup from '@/components/global/Popup.vue';
import {
	UpdateAnimations,
	AnimationType,
	AnimationKeys,
} from '@/types/animationTypes';

const { t } = useI18n();
const isAnimationSelectOpen = ref(false);
const animationButtonRef = ref(null);
const ANIMATION_TYPES = {
	[AnimationKeys.None]: {
		name: t('builder.animationsSettingsNoAnimation'),
		icon: 'crossed-circle',
		iconDimensions: '48px',
	},
	[AnimationKeys.Fade]: {
		name: t('builder.animationsSettingsFade'),
		icon: 'rectangle-grey',
		iconDimensions: '84px',
	},
	[AnimationKeys.Slide]: {
		name: t('builder.animationsSettingsSlide'),
		icon: 'slide-animation',
		iconDimensions: '84px',
	},
	[AnimationKeys.Scale]: {
		name: t('builder.animationsSettingsScale'),
		icon: 'scale-animation',
		iconDimensions: '84px',
	},
};

const animationType = inject<AnimationType>('animationType');
const selectedAnimationName = computed(() => (
	animationType?.value ? ANIMATION_TYPES[animationType.value].name : ANIMATION_TYPES.none.name
));
const updateAnimations = inject<UpdateAnimations>('updateAnimations');

if (!updateAnimations) {
	console.error('Please provide the updateAnimations function to save the selected animations');
}

const handleAnimationClick = (animationName: string) => {
	isAnimationSelectOpen.value = false;

	updateAnimations?.(animationName);
};
</script>

<template>
	<EditTextMoreItem
		:title="$t('common.animation')"
		direction="row"
	>
		<button
			ref="animationButtonRef"
			class="select-button"
			@click="isAnimationSelectOpen = !isAnimationSelectOpen"
		>
			<p>
				{{ selectedAnimationName }}
			</p>
			<ZyroSvgDeprecated
				class="select-button__icon"
				name="chevron"
				direction="down"
			/>
		</button>
		<Popup
			v-if="isAnimationSelectOpen"
			:target-ref="animationButtonRef"
			placement="bottom-start"
			auto-update
			:offset="4"
			:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
			@click-outside="isAnimationSelectOpen = false"
		>
			<div class="select">
				<button
					v-for="(animation, key) in ANIMATION_TYPES"
					:key="key"
					class="select__button"
					:class="{ 'select__button--active': animationType === key }"
					@click="handleAnimationClick(key)"
				>
					{{ animation.name }}
				</button>
			</div>
		</Popup>
	</EditTextMoreItem>
</template>

<style lang="scss" scoped>
.select-button {
	display: flex;
	align-items: center;
	justify-content: center;
	justify-content: space-between;
	width: 120px;
	height: 30px;
	padding: 8px;
	font-size: 12px;
	cursor: pointer;
	border: 1px solid $color-gray-border;
	border-radius: 6px;

	&__icon {
		color: $color-gray;
	}
}

.select {
	display: flex;
	flex-direction: column;
	width: 120px;
	padding: 8px 0;
	overflow: hidden;
	font-size: 12px;
	background: $color-light;
	border-radius: $border-radius-small;
	box-shadow: $box-shadow;

	&__button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 6px 16px;
		text-align: start;
		cursor: pointer;

		&:hover {
			cursor: pointer;
			background-color: $color-gray-light;
		}

		&--active {
			background-color: $color-gray-light;
		}
	}
}
</style>
