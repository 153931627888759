import axios from '@/services/http/axios';
import { AI_BUILDER_THEME } from '@/constants/builderConstants';
import { SiteData } from '@hostinger/builder-schema-validator';
import { GeneratedColorPalette } from '@/types/aiBuilderTypes';

export const AI_API = `${import.meta.env.VITE_BACKEND_API_URL}/v1/ai`;

export const generateSite = ({
	brandName,
	brandDescription,
	websiteType,
	templateId,
	fonts,
	siteId,
	generateImages = true,
	isHeroImageAiGenerated = false,
	colors,
	domain,
	txtRecord,
	hostingReferenceId,
}: {
  brandName: string
	brandDescription: string
	websiteType: string
	siteId?: string
	templateId?: string
	fonts?: string[]
	generateImages?: boolean
	isHeroImageAiGenerated?: boolean
	colors?: string[]
	domain?: string
	txtRecord?: string
	hostingReferenceId?: string
}) => axios.post<{
	language: string;
	siteData: SiteData;
	ecommerceProducts: any;
	clientTimestamp: number;
	colorPalette: GeneratedColorPalette;
}>(`${AI_API}/generate-site-paid`, {
	brandName,
	websiteDescription: brandDescription,
	websiteType,
	templateId,
	theme: AI_BUILDER_THEME,
	fonts,
	siteName: brandName,
	generateImages,
	isHeroImageAiGenerated,
	...(siteId && {
		siteId,
	}),
	colors,
	domain,
	txtRecord,
	hostingReferenceId,
});
