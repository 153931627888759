<script setup lang="ts">
import {
	computed,
	ref,
	onMounted,
} from 'vue';
import ZyroModal from '@/components/global/ZyroModal.vue';
import { getWordPressExportStatus } from '@/api/WordpressApi';
import {
	WORDPRESS_EXPORT_STATUSES,
	MODAL_WORDPRESS_EXPORT_COMPLETE,
	MODAL_WORDPRESS_EXPORT_FAIL,
	MODAL_WORDPRESS_EXPORTING,
} from '@/constants/builderConstants';
import {
	OPEN_MODAL,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import {
	useRoute,
	useRouter,
} from 'vue-router';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';

const { t } = useI18n();
const {
	dispatch,
	state,
} = useStore();
const router = useRouter();
const route = useRoute();

const websiteId = computed(() => state.websiteId);
const wordpressExportStatusTimer = ref();
const wordpressSiteUrl = route.query.site_url;

const retryBoundary = ref(0);
const RETRY_BOUNDARY = 3;

onMounted(() => {
	const resetExporting = () => {
		clearInterval(wordpressExportStatusTimer.value);

		router.replace({
			query: {
				...route.query,
				user_login: undefined,
				password: undefined,
				site_url: undefined,
			},
		});

		dispatch(`gui/${CLOSE_MODAL}`, {
			name: MODAL_WORDPRESS_EXPORTING,
		});
	};

	wordpressExportStatusTimer.value = setInterval(async () => {
		try {
			const { data } = await getWordPressExportStatus({
				siteId: websiteId.value,
			});

			retryBoundary.value = 0;

			if (data.status === WORDPRESS_EXPORT_STATUSES.DONE) {
				resetExporting();

				const {
					errors,
					success,
				} = data.results;

				if (success.length) {
					dispatch(`gui/${OPEN_MODAL}`, {
						name: MODAL_WORDPRESS_EXPORT_COMPLETE,
						settings: {
							wordpressSiteUrl,
							errorsOccured: errors.length,
						},
					});

					return;
				}

				dispatch(`gui/${OPEN_MODAL}`, {
					name: MODAL_WORDPRESS_EXPORT_FAIL,
				});
			}
		} catch (error) {
			retryBoundary.value += 1;

			if (retryBoundary.value >= RETRY_BOUNDARY) {
				resetExporting();

				dispatch(`gui/${OPEN_MODAL}`, {
					name: MODAL_WORDPRESS_EXPORT_FAIL,
				});
			}
		}
	}, 5000); // 5000ms interval is chosen as a reasonable time for polling exporting status
});
</script>

<template>
	<Teleport to="body">
		<ZyroModal
			:max-width="'376px'"
			:height="'auto'"
			position="center"
			class="wordpress-exporting-modal"
			:show-close-button="false"
			content-padding="32px"
		>
			<div class="wordpress-exporting-modal__content">
				<ZyroLoader
					class="wordpress-exporting-modal__loader"
					size="80px"
					weight="4px"
					color="var(--color-primary)"
				/>
				<h2 class="wordpress-exporting-modal__title text-heading-2">
					{{ t('builder.wordpressExportingModalTitle') }}
				</h2>
				<p class="wordpress-exporting-modal__description text-body-2">
					{{ t('builder.wordpressExportingModalDescriptionV2') }}
				</p>
			</div>
		</ZyroModal>
	</Teleport>
</template>

<style lang="scss" scoped>
.wordpress-exporting-modal{
	text-align: center;

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__title {
		margin: 24px 0 4px;
	}

	&__description {
		color: var(--color-gray)
	}
}
</style>
