<template>
	<div>
		<ZyroFieldInput
			:model-value="currentElement.content"
			qa-selector="linksettingsmodal-input-name"
			maxlength="150"
			:label="$t('builder.editButton.tabActionLabel')"
			:placeholder="$t('builder.editButton.tabActionLabel')"
			@update:model-value="setElementProperty('content', $event)"
		/>
		<LinkSettings
			v-if="!hideLinkSettings"
			:target="currentElement.target"
			:href="currentElement.href"
			:rel="currentElement.rel"
			:type="currentElement.linkType"
			:page-id="currentElement.linkedPageId"
			@update:href="setElementProperty('href', $event)"
			@update:rel="setElementProperty('rel', $event)"
			@update:target="setElementProperty('target', $event)"
			@update:type="setElementProperty('linkType', $event)"
			@update:pageId="setElementProperty('linkedPageId', $event)"
		/>
	</div>
</template>

<script setup lang="ts">
import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';

import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import LinkSettings from '@/components/builder-controls/edit-button/LinkSettings.vue';

type Props = {
	elementId?: string;
}

const props = defineProps<Props>();

const BUTTONS_TO_HIDE_LINK_SETTINGS = [
	'GridStripeButton',
	'GridEcommerceButton',
];

const {
	getters,
	dispatch,
} = useStore();
// elementId here is needed because:
// - mergeElementData uses currentElementId to set element data
// - this component, before being destroyed, catches input blur event from LinkSettings to format href
// - element id is unset too early and thus, data is not saved - currentElementId is undefined.
// So, need to save the elementId explicitly.
// We should fix this by either:
// A. Rework how we unset current element - now it gets unset too early.
//    Same happens in a lot of component where we need to save currentElementId
// B. Rework EditButton components to have Save/Cancel buttons and save on those actions, instead of click outside.
const elementId = ref(props.elementId);
const currentElement = computed(() => (elementId.value ? getters.siteElements[elementId.value] : null));
const hideLinkSettings = computed(() => BUTTONS_TO_HIDE_LINK_SETTINGS.includes(currentElement.value?.type));

const setElementProperty = (property: string, value: string | boolean) => {
	dispatch('mergeElementData', {
		elementId: elementId.value,
		elementData: {
			[property]: value,
		},
	});
};
</script>
