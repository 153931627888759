<script setup lang="ts">
import ZyroFieldTextArea from '@/components/global/ZyroFieldTextArea.vue';
import CheckerList from '@/components/global/CheckerList.vue';

import { SEO_MAX_STRING_LENGTH_DESCRIPTION } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import {
	watch,
	computed,
	ref,
} from 'vue';
import { usePageSeoSettings } from '@/use/usePageSeoSettings';
import {
	getIsSeoDescriptionLengthValid,
	getIsFocusKeywordIncludedInString,
} from '@/utils/seoValidation';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const {
	pageMetaDescription,
	pageMetaFocusKeyword,
	mergePageMeta,
} = usePageSeoSettings();

const descriptionInputValue = ref(pageMetaDescription.value ?? '');
const seoDescriptionLength = computed(() => descriptionInputValue.value.length);
const isSeoDescriptionLengthValid = computed(() => getIsSeoDescriptionLengthValid({
	seoDescription: descriptionInputValue.value,
}));
const isSeoDescriptionEmpty = computed(() => !descriptionInputValue.value);

const isFocusKeywordIncludedInSeoDescription = computed(() => getIsFocusKeywordIncludedInString({
	stringToCheck: descriptionInputValue.value,
	focusKeyword: pageMetaFocusKeyword.value,
}));

const seoDescriptionCheckerList = computed(() => ([
	{
		id: 'seoDescriptionNotEmpty',
		isCompleted: !isSeoDescriptionEmpty.value,
		text: {
			keyPath: 'builder.seoDrawer.seoChecker.seoCheckerDescriptionNotEmpty',
		},
	},
	{
		id: 'seoDescriptionLength',
		isCompleted: isSeoDescriptionLengthValid.value,
		text: {
			keyPath: 'builder.seoDrawer.seoChecker.seoCheckerDescriptionLength',
			value: seoDescriptionLength.value,
		},
	},
	{
		id: 'seoDescriptionFocusKeyword',
		isCompleted: isFocusKeywordIncludedInSeoDescription.value,
		text: {
			keyPath: 'builder.seoDrawer.seoChecker.seoCheckerDescriptionFocusKeyword',
			value: pageMetaFocusKeyword.value ? `"${pageMetaFocusKeyword.value}"` : '',
		},
	},
]));

watch(pageMetaDescription, () => {
	descriptionInputValue.value = pageMetaDescription.value ?? '';
});
</script>

<template>
	<ZyroFieldTextArea
		id="page-description"
		:model-value="descriptionInputValue"
		:label="t('builder.pageSettingsModal.metaDescription')"
		:min-height="120"
		:maxlength="SEO_MAX_STRING_LENGTH_DESCRIPTION"
		@update:model-value="descriptionInputValue = $event"
		@text-area-blur="mergePageMeta({ description: descriptionInputValue })"
	/>

	<CheckerList :checker-items="seoDescriptionCheckerList" />
</template>
