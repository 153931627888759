<script setup lang="ts">
import {
	ref,
	computed,
	onBeforeUnmount,
	ComponentPublicInstance,
} from 'vue';
import EventLogApi from '@/api/EventLogApi';
import Popup from '@/components/global/Popup.vue';
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import { useStore } from 'vuex';
import { useAiTextGeneration } from '@/use/useAiTextGeneration';
import { NPS_TYPE_AI_WRITER } from '@/constants/builderConstants';
import AiTextGeneratorForm from '@/components/builder-controls/ai-text-generator/AiTextGeneratorForm.vue';

const { state } = useStore();

type Props = {
	elementId: string;
}

const props = defineProps<Props>();

const {
	isAiTextGeneratorPopupVisible,
	generateAiText,
	userPrompt,
	isPromptInputTouched,
	setIsAiTextGeneratorPopupVisible,
} = useAiTextGeneration({
	elementId: props.elementId,
});
const aiTextGeneratorFormControlsRef = ref<ComponentPublicInstance | null>(null);
const isMobileScreen = computed(() => state.gui.isMobileScreen);

onBeforeUnmount(() => {
	setIsAiTextGeneratorPopupVisible(false);
});

const handleOpenClick = () => {
	setIsAiTextGeneratorPopupVisible(!isAiTextGeneratorPopupVisible.value);

	EventLogApi.logEvent({
		eventName: 'website_builder.ai_assist.enter',
	});
};
</script>

<template>
	<div>
		<HostingerButton
			ref="aiTextGeneratorFormControlsRef"
			v-qa="'ai-text-generator-open-settings'"
			class="trigger-button"
			button-type="plain"
			theme="highlight"
			@click="handleOpenClick"
		>
			<template #icon-left>
				<Icon
					is-custom
					name="stars"
					dimensions="20px"
				/>
			</template>
			<span v-if="!isMobileScreen">{{ $t('builder.AIWriter.title') }}</span>
		</HostingerButton>
		<Popup
			v-if="isAiTextGeneratorPopupVisible"
			:target-ref="aiTextGeneratorFormControlsRef && aiTextGeneratorFormControlsRef.$el"
			placement="bottom"
			auto-update
			is-mobile-fixed-to-bottom
			@click-outside="isAiTextGeneratorPopupVisible = false"
		>
			<ZyroPopupCard
				:title="$t('builder.AIWriter.title')"
				@close="isAiTextGeneratorPopupVisible = false"
			>
				<p class="ai-text-generator__description">
					{{ $t('builder.AITextWriterPopupDescription') }}
				</p>
				<AiTextGeneratorForm
					:is-prompt-input-touched="isPromptInputTouched"
					:user-prompt="userPrompt"
					@generate="generateAiText({ isTryingAgain: false })"
					@update:user-prompt="userPrompt = $event"
				/>
				<NpsRateFeature
					class="ai-text-generator__nps-rate"
					:feature-name="$t('builder.npsRateAIWriter')"
					:type="NPS_TYPE_AI_WRITER"
				/>
			</ZyroPopupCard>
		</Popup>
	</div>
</template>

<style lang="scss" scoped>
.trigger-button {
	color: $color-primary;
	font-weight: 400;
}

.ai-text-generator {
	&__description {
		color: $color-gray;
		font-size: 14px;
		line-height: 1.4;
		margin: 8px 0 16px;
	}

	&__nps-rate {
		margin-top: 16px;
	}

}
</style>
