<template>
	<div class="notification">
		<div
			v-if="isLoading"
			class="notification__loading"
		>
			<ZyroLoader
				class="notification__loader"
				size="56px"
				weight="3px"
			/>
			<div
				v-qa="'builder-heatmap-generating'"
				class="notification__label text-body-3"
			>
				{{ $t('builder.AIHeatmapGenerating') }}
			</div>
		</div>
		<div
			v-else-if="hasFailed"
			class="notification__error"
		>
			<div class="notification__label text-body-3">
				{{ errorMessage }}
			</div>
			<HostingerButton
				color="plump-purple"
				type="button"
				@click="$emit('retry')"
			>
				{{ $t('builder.AIHeatmapRetry') }}
			</HostingerButton>
		</div>
	</div>
</template>

<script>
import HostingerButton from '@/components/global/HostingerButton.vue';

import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';

import { defineComponent } from 'vue';

export default defineComponent({

	components: {
		HostingerButton,
		ZyroLoader,
	},

	props: {
		isLoading: {
			type: Boolean,
			required: true,
		},
		hasFailed: {
			type: Boolean,
			required: true,
		},
		errorMessage: {
			type: String,
			default: null,
		},
	},
	emits: ['retry'],
});
</script>

<style lang="scss" scoped>
.notification {
	position: fixed;
	top: 30vh;
	left: calc(50% - 110px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 220px;
	padding: 32px;
	text-align: center;
	background-color: $color-light;

	&__loader {
		margin: 0 auto;
	}

	&__label {
		margin: 32px 0;
	}
}
</style>
