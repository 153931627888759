<template>
	<GridInstagramFeed
		:id="elementId"
		ref="instagramRef"
		:key="renderKey"
		:media="media"
		:data-element-ref="elementId"
		class="layout-element__component layout-element__component--GridInstagramFeed"
		@dragstart.stop.prevent
	/>

	<ElementEditControls
		v-if="!isPreviewMode"
		:target-ref="instagramRef?.$el"
		:element-id="elementId"
		:is-element-active="isActive"
		:enter-edit-mode-button-title="$t('builder.editInstagram.title')"
	>
		<template #edit-mode-popup>
			<EditInstagramFeed
				:is-new-instagram-api="isNewInstagramApi"
				@close="closeElementEditPopupHandler"
			/>
		</template>
	</ElementEditControls>
</template>

<script>
import {
	watch,
	computed,
	ref,
	defineComponent,
} from 'vue';
import { useStore } from 'vuex';

import {
	deleteToken,
	getFeed,
	getToken,
} from '@/api/InstagramApi';
import GridInstagramFeed from '@zyro-inc/site-modules/components/elements/instagram-feed/GridInstagramFeed.vue';
import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import EditInstagramFeed from '@/components/builder-controls/edit-instagram-feed/EditInstagramFeed.vue';

import { useElementEditPopup } from '@/use/useElementEditPopup';
import { useGridInstagramFeed } from '@zyro-inc/site-modules/components/elements/instagram-feed/useGridInstagramFeed';
import { isDesktopSafari } from '@/utils/browserIdentifiers';

export default defineComponent({
	components: {
		GridInstagramFeed,
		ElementEditControls,
		EditInstagramFeed,
	},

	props: {
		elementId: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		isActive: {
			type: Boolean,
			default: false,
		},
		isPreviewMode: {
			type: Boolean,
			required: false,
		},
	},

	setup(props) {
		const instagramRef = ref(null);

		const { state } = useStore();
		const { websiteId: siteId } = state;

		const { closeElementEditPopupHandler } = useElementEditPopup({
			elementId: props.elementId,
		});

		const username = computed(() => props.data.settings.username);
		const elementId = computed(() => props.elementId);
		const data = computed(() => props.data);

		const cleanupUser = () => {
			if (username.value) {
				deleteToken({
					siteId: state.websiteId,
					elementId: props.elementId,
				});
			}
		};

		const {
			media,
			init,
			itemGap,
			isNewInstagramApi,
		} = useGridInstagramFeed({
			elementId,
			elementData: data,
			siteId,
			getInstagramTokenHandler: getToken,
			getInstagramFeedHandler: getFeed,
			cleanupCallback: cleanupUser,
		});

		watch(username, init, {
			immediate: true,
		});

		const renderKey = ref(0);

		watch(itemGap, () => {
			if (isDesktopSafari) {
				// Rerender on each change in safari or else it wont rerender changed gaps
				renderKey.value += 1;
			}
		});

		return {
			media,
			itemGap,
			renderKey,
			instagramRef,
			closeElementEditPopupHandler,
			isNewInstagramApi,
		};
	},
});
</script>
