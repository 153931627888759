<template>
	<ZyroDrawer
		class="add-element-drawer"
		:header-title="$t('common.addElements')"
		:header-description="$t('builder.dragAndDropElements')"
		@back="closeInnerSidebar"
	>
		<template #body>
			<AddElementList />
		</template>
	</ZyroDrawer>
</template>

<script setup lang="ts">
import { useSidebar } from '@/use/useSidebar';

import ZyroDrawer from '@/components/global/ZyroDrawer.vue';

import AddElementList from '@/components/builder-controls/add-element/AddElementList.vue';

const { closeInnerSidebar } = useSidebar();
</script>

<style lang="scss" scoped>
.add-element-drawer {
	&__header {
		margin-bottom: 16px;
	}
}
</style>
