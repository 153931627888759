import { ref } from 'vue';

const selectedLayoutBlockId = ref(null);
const copiedLayoutBlockId = ref(null);
const copiedLayoutBlockLocale = ref(null);
const selectedLayoutElementId = ref(null);
const copiedElementId = ref(null);
const copiedElementLocale = ref(null);
const isElementCut = ref(false);

export const useLayoutContextMenu = () => {
	const setSelectedLayoutBlockId = (blockId) => {
		selectedLayoutBlockId.value = blockId;
	};

	const setSelectedLayoutElementId = (elementId) => {
		selectedLayoutElementId.value = elementId;
	};

	return {
		selectedLayoutBlockId,
		copiedLayoutBlockId,
		selectedLayoutElementId,
		copiedElementId,
		copiedElementLocale,
		isElementCut,
		copiedLayoutBlockLocale,
		setSelectedLayoutBlockId,
		setSelectedLayoutElementId,
	};
};
