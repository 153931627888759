import {
	ELEMENT_POSITION_KEY_DESKTOP,
	DESKTOP_BLOCK_WIDTH,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { getElementPositionFromDOM } from '@/utils/getElementPositionFromDom';
import { fitToLayoutXBounds } from '@/utils/fitToLayoutXBounds';
import { fitToLayoutYBounds } from '@/utils/fitToLayoutYBounds';

import {
	MOBILE_BLOCK_PADDING_X,
	MOBILE_BUILDER_WIDTH,
} from '@zyro-inc/site-modules/components/blocks/layout/constants';

/**
 * Get the highest top position from the given elements array
 * @param {Array} elements
 * @param {String} elementPositionKey
 * @returns {Number} - top position
 */
export const getHighestElementsTop = ({
	elements,
	elementPositionKey,
}) => Math.min(
	...elements.map((element) => element[elementPositionKey].top),
);

/**
 * Get the lowest bottom position from the given elements array
 * @param {Array} elements
 * @param {String} elementPositionKey
 * @returns {Number} - bottom position
 */
export const getLowestElementsBottom = ({
	elements,
	elementPositionKey,
}) => Math.max(...elements.map((element) => element[elementPositionKey].bottom));

/**
 * From a given elements array, returns an array of element groups.
 * Element groups are constructed by analyzing the elements' positions.
 * If an element is in the same vertical range (they overlap horizontally) as another element, they belong to the same group.
 * @param {Array} elements - array of layout block elements
 * @param {String} elementPositionKey
 * @returns {Array} - array of element groups
 */
export const getElementGroups = ({
	elements,
	elementPositionKey,
}) => {
	const elementsWithBottomPositions = elements.map((element) => ({
		...element,
		[elementPositionKey]: {
			...element[elementPositionKey],
			bottom: element[elementPositionKey].top + element[elementPositionKey].height,
		},
	}));
	const elementsSortedByTops = [...elementsWithBottomPositions].sort((a, b) => a[elementPositionKey].top - b[elementPositionKey].top);

	const elementGroupArrays = elementsSortedByTops.reduce((groups, element) => {
		// Find to which group the element belongs
		const groupIndex = groups.findIndex((group) => group.some((groupElement) => {
			const {
				top: groupElementTop,
				bottom: groupElementBottom,
			} = groupElement[elementPositionKey];
			const {
				top: elementTop,
				bottom: elementBottom,
			} = element[elementPositionKey];

			const isElementInGroupTopRange = elementTop >= groupElementTop && elementTop < groupElementBottom;
			const isElementInGroupBottomRange = elementBottom > groupElementTop && elementBottom <= groupElementBottom;

			return isElementInGroupTopRange || isElementInGroupBottomRange;
		}));

		// If the element doesn't belong to any group, create a new group
		if (groupIndex === -1) {
			return [
				...groups,
				[element],
			];
		}

		// If the element belongs to a group, add it to the group
		return [
			...groups.slice(0, groupIndex),
			[
				...groups[groupIndex],
				element,
			],
			...groups.slice(groupIndex + 1),
		];
	}, []);

	return elementGroupArrays.map((group) => ({
		groupTop: getHighestElementsTop({
			elements: group,
			elementPositionKey,
		}),
		groupBottom: getLowestElementsBottom({
			elements: group,
			elementPositionKey,
		}),
		elements: group,
	}));
};

const getFallbackGroupData = ({
	groupElements = [],
	elementPositionKey,
}) => groupElements.map((element) => ({
	elementId: element.elementId,
	position: {
		top: element[elementPositionKey].top,
	},
}));

/**
 * From a given higher and lower element groups, returns an array of elements with updated positions.
 * The elements are updated according to the switch between the groups.
 * @param {Object} higherGroup
 * @param {Object} lowerGroup
 * @param {String} elementPositionKey
 * @returns {Array} - array of updated element positions ([{ elementId, position: { top } }])
 */
export const getElementPositionsAfterGroupsSwitch = ({
	higherGroup,
	lowerGroup,
	elementPositionKey,
}) => {
	if (!higherGroup || !lowerGroup) {
		return [
			...getFallbackGroupData({
				groupElements: higherGroup?.elements,
				elementPositionKey,
			}),
			...getFallbackGroupData({
				groupElements: lowerGroup?.elements,
				elementPositionKey,
			}),
		];
	}

	const {
		groupTop: higherGroupTop,
		groupBottom: higherGroupBottom,
		elements: higherGroupElements,
	} = higherGroup;
	const {
		groupTop: lowerGroupTop,
		groupBottom: lowerGroupBottom,
		elements: lowerGroupElements,
	} = lowerGroup;

	const gapBetweenGroups = lowerGroupTop - higherGroupBottom;
	const lowerGroupHeight = lowerGroupBottom - lowerGroupTop;
	const lowerGroupBottomAfterSwitch = higherGroupTop + lowerGroupHeight;
	const higherGroupTopAfterSwitch = lowerGroupBottomAfterSwitch + gapBetweenGroups;
	const higherGroupElementsAfterSwitch = higherGroupElements.map((element) => ({
		elementId: element.elementId,
		position: {
			top: higherGroupTopAfterSwitch + (element[elementPositionKey].top - higherGroupTop),
		},
	}));
	const lowerGroupElementsAfterSwitch = lowerGroupElements.map((element) => ({
		elementId: element.elementId,
		position: {
			top: higherGroupTop + (element[elementPositionKey].top - lowerGroupTop),
		},
	}));

	return [
		...higherGroupElementsAfterSwitch,
		...lowerGroupElementsAfterSwitch,
	];
};

/**
 * Returns the elements group that contains the given element ID
 * @param {Object} elementGroup
 * @param {String} selectedElementId
 * @returns {Object} element group
 */
export const getElementGroupIndexByElementId = ({
	elementGroups,
	elementId,
}) => elementGroups.findIndex(
	(group) => group.elements.some((element) => element.elementId === elementId),
);

/**
 * Calculates the independent space that elements takes up within the group.
 * - If the element is the only element in the group, the space is the element's height.
 * - Otherwise, it's the gap between the group without the deleted element and the element itself (for top, and for bottom)
 * @param {Object} elementToDeleteGroup - element group of the element to delete
 * @param {Object} elementToDelete - element to delete
 * @param {String} elementPositionKey - key of element position object
 * @returns {Number} - shift to top margin
 */
export const getShiftMarginsAfterElementDelete = ({
	elementToDeleteGroup,
	elementToDelete,
	elementPositionKey,
}) => {
	if (elementToDeleteGroup.elements.length === 1) {
		return {
			topMargin: elementToDelete[elementPositionKey].height,
			bottomMargin: 0,
		};
	}

	const groupElementsWithoutElementToDelete = elementToDeleteGroup.elements.filter(
		({ elementId }) => elementId !== elementToDelete.elementId,
	);
	const elementsWithoutElementToDeleteHighestTop = getHighestElementsTop({
		elements: groupElementsWithoutElementToDelete,
		elementPositionKey,
	});
	const elementsWithoutElementToDeleteLowestBottom = getLowestElementsBottom({
		elements: groupElementsWithoutElementToDelete,
		elementPositionKey,
	});
	const topMargin = elementsWithoutElementToDeleteHighestTop - elementToDelete[elementPositionKey].top;
	const bottomMargin = elementToDelete[elementPositionKey].bottom - elementsWithoutElementToDeleteLowestBottom;

	return {
		topMargin: topMargin > 0 ? topMargin : 0,
		bottomMargin: bottomMargin > 0 ? bottomMargin : 0,
	};
};

/**
 * Get all elements below the target element
 * @param {Array} elements
 * @param {Object} verticalTreshold - vertical treshold below which elements should be returned. Default is 0
 * @param {String} elementPositionKey
 * @returns {Array} - array of elements below the target element
 */
export const getElementsBelowVerticalTreshold = ({
	elements,
	elementPositionKey,
	verticalTreshold = 0,
}) => elements.filter((element) => element[elementPositionKey].top > verticalTreshold);

/**
 * Returns an array of elements with updated positions after all elements below the target element are shifted to top.
 * @param {Array} elements - array of layout block elements
 * @param {Object} elementToShiftAfter - element to shift other elements after
 * @param {String} elementPositionKey
 * @param {Number} shiftMargin - margin by which elements should be shifted to top. Positive - shift down, negative - shift up
 * @returns {Array} - array of updated element positions ([{ elementId, position: { top } }]
 */
export const getElementPositionsAfterVerticalShift = ({
	elements,
	elementPositionKey,
	shiftMargin,
}) => elements.map((element) => ({
	elementId: element.elementId,
	position: {
		top: Math.max(0, element[elementPositionKey].top + shiftMargin),
	},
}));

export const getBlockIdByElementId = ({
	elementId,
	siteBlocks,
}) => Object.keys(siteBlocks).find((blockId) => siteBlocks[blockId].components?.includes(elementId));

/**
 * Returns updated group positions (left, right, width) after adding an element to the group
 * @param {{
 *   groups: [],
 *   groupIndex: number,
 *   element: object,
 *   elementPositionKey: string
 * }}
 * @param {Number} groupIndex - index of group to update
 * @param {Object} element - element to add to group
 * @returns {Array} array of groups with updated left and right positions
*/
export const getColumnGroupPositionAfterAddingElement = ({
	groups,
	groupIndex,
	element,
	elementPositionKey = ELEMENT_POSITION_KEY_DESKTOP,
}) => {
	const {
		left: elementLeft,
		width: elementWidth,
	} = element[elementPositionKey];

	const elementRight = elementLeft + elementWidth;

	const isGroupCreated = !!groups[groupIndex];

	// Update group width if group doesn't exist or old width is smaller than new width
	const shouldUpdateGroupWidth = !isGroupCreated || groups[groupIndex].width < elementRight;

	// Update group left if group doesn't exist or old left is bigger than new left
	const shouldUpdateGroupLeft = !isGroupCreated || groups[groupIndex].left > elementLeft;

	const width = shouldUpdateGroupWidth ? elementRight : groups[groupIndex].width;
	const left = shouldUpdateGroupLeft ? elementLeft : groups[groupIndex].left;

	return {
		left,
		right: left + width,
		width,
	};
};

/**
 * Based on element left and right positions checks if element belongs to any group.
 * Element belongs to the group if element is in the same horizontal range as a group
 * @param {{
 *   groups: array
 *   element: object
 *   elementPositionKey: string
 * }}
 * @param {Object} element - element to add to group
 * @returns {Number} index of group to which the element belongs or -1 if the element doesn't belong to any group
*/
export const getElementColumnGroupIndex = ({
	groups,
	element,
	elementPositionKey = ELEMENT_POSITION_KEY_DESKTOP,
}) => groups.findIndex((group) => {
	const {
		left: groupElementLeft,
		right: groupElementRight,
	} = group;

	const {
		left: elementLeft,
		right: elementRight,
	} = element[elementPositionKey];

	const isElementInGroupLeftRange = elementLeft >= groupElementLeft && elementLeft < groupElementRight;
	const isElementInGroupRightRange = elementRight > groupElementLeft && elementRight <= groupElementRight;

	return isElementInGroupLeftRange || isElementInGroupRightRange;
});

/**
 * Creates element groups from an array of block elements.
 * The elements are grouped by their left and right position (if they overlap vertically)
 * @param {{
 *   elements: array
 *   elementPositionKey: string
 * }}
 * @returns {Array} array of element groups
*/
export const getElementsColumnGroups = ({
	elements,
	elementPositionKey = ELEMENT_POSITION_KEY_DESKTOP,
}) => {
	const elementsWithRightPositions = elements.map((element) => {
		const {
			left,
			width,
		} = element[elementPositionKey];

		return {
			...element,
			[elementPositionKey]: {
				...element[elementPositionKey],
				right: left + width,
			},
		};
	});
	const elementsSortedByLeft = [...elementsWithRightPositions]
		.sort((a, b) => a[elementPositionKey].left - b[elementPositionKey].left);

	const elementGroupArrays = elementsSortedByLeft.reduce((groups, element) => {
		// Find to which group the element belongs
		const groupIndex = getElementColumnGroupIndex({
			groups,
			element,
			elementPositionKey,
		});

		const {
			left: groupLeft,
			width: groupWidth,
			right: groupRight,
		} = getColumnGroupPositionAfterAddingElement({
			groups,
			groupIndex,
			element,
			elementPositionKey,
		});

		// If the element doesn't belong to any group, create a new group
		if (groupIndex === -1) {
			return [
				...groups,
				{
					elements: [element.elementId],
					left: groupLeft,
					width: groupWidth,
					right: groupRight,
				},
			];
		}

		// If the element belongs to a group, add it to the group
		return [
			...groups.slice(0, groupIndex),
			{
				...groups[groupIndex],
				elements: [
					...groups[groupIndex].elements,
					element.elementId,
				],
				left: groupLeft,
				width: groupWidth,
				right: groupRight,
			},
			...groups.slice(groupIndex + 1),
		];
	}, []);

	return elementGroupArrays;
};

/**
 * @param {{
 *   elementsColumnGroups: array
 *   blockElements: object
 *   elementPositionKey: string
 * }}
 * @param {Object} blockElements - Object of block elements were key is elementId
 * @returns {Array} Array of elements groups sorted by top position
*/

export const sortColumnGroupsElementsByTop = ({
	elementsColumnGroups,
	blockElements,
	elementPositionKey = ELEMENT_POSITION_KEY_DESKTOP,
}) => elementsColumnGroups.map((elementGroup) => {
	const groupElements = elementGroup.elements.map((elementId) => blockElements[elementId]);

	const groupElementsPositionsSortedByTop = groupElements
		.sort((a, b) => a[elementPositionKey].top - b[elementPositionKey].top);

	return {
		...elementGroup,
		elements: groupElementsPositionsSortedByTop.map((element) => element.elementId),
	};
});

/**
 * @param {{
 *   elementGroups: array,
 *   startIndex: number,
 *   endIndex: number
 * }}
 * @returns {object} Returns element group object with merge elements and updated groupTop, groupBottom positions
*/
export const getMergedElementGroups = ({
	elementGroups,
	startIndex,
	endIndex,
}) => {
	const slicedElementGroup = elementGroups.slice(startIndex, endIndex + 1);

	if (slicedElementGroup.length === 0) {
		return null;
	}

	const mergedGroup = slicedElementGroup.reduce((groups, elementGroup) => {
		const {
			elements: groupElements,
			groupTop,
			groupBottom,
		} = elementGroup;

		const newGroupTop = groups.groupTop ? Math.min(groups.groupTop, groupTop) : groupTop;
		const newGroupBottom = groups.groupBottom ? Math.max(groups.groupBottom, groupBottom) : groupBottom;

		return {
			groupTop: newGroupTop,
			groupBottom: newGroupBottom,
			elements: [
				...(groups?.elements ? groups.elements : []),
				...groupElements,
			],
		};
	}, {});

	return mergedGroup;
};

/**
 * @param {{
 *   elementsIds: array,
 *   blockElements: object
 * }}
 * @returns {string} Return hightest (lowest top) element id
*/
export const getHighestElement = ({
	elementsIds,
	blockElements,
}) => elementsIds.reduce((hightestElementId, currentElementId) => {
	const currentElementTop = blockElements[currentElementId][ELEMENT_POSITION_KEY_DESKTOP].top;
	const hightestElementTop = blockElements[hightestElementId][ELEMENT_POSITION_KEY_DESKTOP].top;

	return currentElementTop > hightestElementTop ? hightestElementId : currentElementId;
}, elementsIds[0]);

/**
 *
 * @param {{
 *  oldElements: array[object],
 *  newElements: array[object],
 * }}
 * @returns {string} Return unique element id from newElements array
*/
export const findUniqueElementIdByComparingElementArrays = ({
	oldElements,
	newElements,
}) => {
	const oldElementsIds = oldElements.map((element) => element.elementId);
	const newElementsIds = newElements.map((element) => element.elementId);

	const uniqueElementId = newElementsIds.find((elementId) => !oldElementsIds.includes(elementId));

	return uniqueElementId;
};

export const getLayoutElementPositionFromDOM = ({
	elementId,
	blockId,
	isMobileMode,
}) => {
	const builderWidth = isMobileMode ? MOBILE_BUILDER_WIDTH : DESKTOP_BLOCK_WIDTH;

	const elementPositionFromDom = getElementPositionFromDOM({
		elementId,
		blockId,
		leftOffset: isMobileMode ? MOBILE_BLOCK_PADDING_X : 0,
	});

	const position = fitToLayoutYBounds(
		fitToLayoutXBounds(
			elementPositionFromDom,
			builderWidth,
		),
	);

	return {
		position,
		blockWidth: elementPositionFromDom.blockWidth,
	};
};

export const getLowerElementsRelativeToActive = ({
	layoutElements,
	activeElementId,
	elementPositionKey,
	isElementWithTheSameTopIncluded = true,
}) => {
	const activeElementData = layoutElements.find(({ elementId }) => elementId === activeElementId);

	return layoutElements
		.filter((element) => {
			const isNotActiveElement = element.elementId !== activeElementId;

			const isBelowActiveElement = isElementWithTheSameTopIncluded
				? activeElementData[elementPositionKey].top <= element[elementPositionKey].top
				: activeElementData[elementPositionKey].top < element[elementPositionKey].top;

			return isNotActiveElement && isBelowActiveElement;
		});
};

export const getElementsBelowActiveElementPositions = ({
	topOffset,
	elementPositionKey,
	lowerElementsRelativeToActive,
}) => Object.fromEntries(Object.entries(lowerElementsRelativeToActive).map(([, element]) => {
	const {
		top: elementTop,
		left: elementLeft,
		height: elementHeight,
		width: elementWidth,
	} = element[elementPositionKey];

	const position = {
		...element[elementPositionKey],
		height: elementHeight,
		width: elementWidth,
		left: elementLeft,
		top: elementTop - topOffset,
	};

	return [
		element.elementId,
		position,
	];
}));

export const getUpdatedElementsPosition = ({
	elementsPositions,
	topOffset = 0,
	elementPositionKey,
}) => Object.fromEntries(Object.entries(elementsPositions)
	.map(([elementId, elementPosition]) => {
		const {
			top: elementTop,
			left: elementLeft,
			width: elementWidth,
			height: elementHeight,
		} = fitToLayoutYBounds(elementPosition);

		return [
			elementId,
			{
				[elementPositionKey]: {
					...elementPosition,
					top: elementTop + topOffset,
					left: elementLeft,
					width: elementWidth,
					height: elementHeight,
				},
			},
		];
	}));
