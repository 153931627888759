import { CURRENCY_MAPPER } from '@/utils/currencyCommon';

export default {
	format(currency: string, price: number) {
		const {
			decimalDigits,
			symbol,
			format,
			isDecimalHidden,
		} = CURRENCY_MAPPER[currency];

		const resolvedPrice = (price / 10 ** decimalDigits).toFixed(isDecimalHidden ? 0 : decimalDigits);

		return format ? format.replace('{price}', resolvedPrice) : `${symbol}${resolvedPrice}`;
	},
};
