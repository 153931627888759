<script setup lang="ts">
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import ZyroModal from '@/components/global/ZyroModal.vue';
import { useEcommerceAdminStore } from '@/stores/ecommerceAdminStore';
import {
	ref,
	computed,
} from 'vue';

const ecommerceAdminStore = useEcommerceAdminStore();

const isUpdatingEcommerceData = ref(false);

const ecommerceAdminIframeSrc = computed(() => ecommerceAdminStore.ecommerceAdminIframeSrc);
const shouldUpdateEcommerceData = computed(() => ecommerceAdminStore.shouldUpdateEcommerceData);

const closeEcommerceAdminIframe = async () => {
	if (shouldUpdateEcommerceData.value) {
		isUpdatingEcommerceData.value = true;

		await ecommerceAdminStore.handleEcommerceDataUpdate();

		isUpdatingEcommerceData.value = false;
	}

	ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
		isOpen: false,
	});
};
</script>

<template>
	<ZyroModal
		max-width="calc(100vw - 48px)"
		max-height="calc(100vh - 48px)"
		:title="$t('builder.storeManager.title')"
		title-class="text-heading-3"
		content-padding="0"
		header-padding="16px 24px"
		:is-button-disabled="isUpdatingEcommerceData"
		class="ecommerce-admin-modal"
		no-overflow
		@close-modal="closeEcommerceAdminIframe"
	>
		<iframe
			:src="ecommerceAdminIframeSrc"
			:title="$t('builder.storeManager.title')"
			class="ecommerce-admin-modal__iframe"
		/>
		<div
			v-if="isUpdatingEcommerceData"
			class="ecommerce-admin-modal__overlay"
		>
			<ZyroLoader
				size="100px"
				color="var(--color-light)"
			/>
		</div>
	</ZyroModal>
</template>

<style scope lang="scss">
.ecommerce-admin-modal {
	position: relative;

	&__iframe {
		border-top: 1px solid $color-gray-border;
		width: 100%;
		height: 100%;
	}

	&__overlay {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: rgb($color-dark, 0.5);
		gap: 8px;
	}
}
</style>
