import { computed } from 'vue';
import { load } from 'opentype.js';
import { useStore } from 'vuex';
import { stripSchemeFromURL } from '@/utils/urlValidators';

export const useTextToSvg = () => {
	const {
		state,
		dispatch,
	} = useStore();
	const googleFonts = computed(() => state.fonts.googleFonts);

	const getSvg = async ({
		text,
		fontFamily,
		fontSize,
	}) => {
		await dispatch('fonts/fetchGoogleFonts');

		const googleFontFamily = googleFonts.value.find(({ family }) => fontFamily.includes(family))
           || googleFonts.value.find(({ family }) => family === 'Roboto');

		if (!googleFontFamily) {
			return {};
		}

		const fontUrl = `//${stripSchemeFromURL({
			url: googleFontFamily.files['700'] || googleFontFamily.files.regular,
		})}`;

		const openTypeFontObject = fontUrl && (await load(fontUrl));
		const textPathToMeasureBoundings = openTypeFontObject.getPath(
			text,
			0,
			0,
			fontSize,
		);
		const {
			x1,
			x2,
			y1,
			y2,
		} = textPathToMeasureBoundings.getBoundingBox();

		const textWidth = x2 - x1;
		const textHeight = y2 - y1;
		const textPath = openTypeFontObject.getPath(text, -x1, -y1, fontSize);
		const logoSvg = textPath.toSVG().replace('/>', ' preserveAspectRatio="none"/>');
		const textSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 ${textWidth} ${textHeight}" fill="currentColor">${logoSvg}</svg>`;

		return {
			svg: textSvg,
			width: textWidth,
			height: textHeight,
		};
	};

	return {
		getSvg,
	};
};
