<template>
	<GridForm
		:id="elementId"
		ref="formRef"
		:data-element-ref="elementId"
		class="layout-element__component layout-element__component--GridForm"
		:form-id="data.formId"
		:settings="data.settings"
		:is-user-site="isPreviewMode"
		:show-success-message="showSuccessMessage"
		:submit-button-data="data.submitButtonData"
		:submit-button-background-color="data.submitButtonBackgroundColor"
		:submit-button-background-color-hover="data.submitButtonBackgroundColorHover"
		:submit-button-font-color="data.submitButtonFontColor"
		:submit-button-font-color-hover="data.submitButtonFontColorHover"
		:submit-button-border-color="data.submitButtonBorderColor"
		:submit-button-border-color-hover="data.submitButtonBorderColorHover"
		:submit-button-font-family="data.submitButtonFontFamily"
		:submit-button-font-weight="data.submitButtonFontWeight"
		:submit-button-font-size-mobile="data.mobile?.submitButtonFontSize"
		:submit-button-font-size-desktop="data.desktop?.submitButtonFontSize"
		:submit-button-border-width="data.submitButtonBorderWidth"
		:submit-button-border-radius="data.submitButtonBorderRadius"
		:theme="data.settings.theme"
		:form-justify="data.settings.styles.justify"
		:form-button-justify-self="data.submitButtonData.settings.styles.align"
		:mobile-form-button-justify-self="data.submitButtonData.settings.styles['m-align']"
		:form-background-color="data.formBackgroundColor"
		:form-border-width="data.formBorderWidth"
		:form-border-color="data.formBorderColor"
		:form-border-radius="data.formBorderRadius"
		:form-padding="data.formPadding"
		:input-fill-color="data.inputFillColor"
		:input-fill-color-hover="data.inputFillColorHover"
		:form-font-family="data.formFontFamily"
		:form-font-weight="data.formFontWeight"
		:label-text-color="data.labelTextColor"
		:label-text-size="data.desktop?.labelTextSize"
		:mobile-label-text-size="data.mobile?.labelTextSize"
		:input-text-color="data.inputTextColor"
		:input-text-color-hover="data.inputTextColorHover"
		:input-text-size="data.desktop?.inputTextSize"
		:mobile-input-text-size="data.mobile?.inputTextSize"
		:input-border-color="data.inputBorderColor"
		:input-border-color-hover="data.inputBorderColorHover"
		:input-border-width="data.inputBorderWidth"
		:input-border-radius="data.inputBorderRadius"
		:elements-vertical-spacing="data.desktop?.formElementsVerticalSpacing"
		:mobile-elements-vertical-spacing="data.mobile?.formElementsVerticalSpacing"
		@on-submit="onSubmit"
	>
		<PostSubmitElement
			v-if="isPreviewMode"
			:success-message="data.settings.successMessage"
			:show-success-message="showSuccessMessage"
			has-border-width
			is-in-preview-mode
		/>
	</GridForm>

	<ElementEditControls
		v-if="!isPreviewMode"
		:target-ref="formRef?.$el"
		:element-id="props.elementId"
		:is-element-active="props.isActive"
		:enter-edit-mode-button-title="$t('builder.editForm.editForm')"
	>
		<template #edit-mode-popup>
			<EditForm @close="closeElementEditPopupHandler" />
		</template>
	</ElementEditControls>
</template>

<script setup>
import {
	ref,
	computed,
	onMounted,
} from 'vue';

import { useStore } from 'vuex';
import { useBuilderMode } from '@/use/useBuilderMode';
import GridForm from '@zyro-inc/site-modules/components/elements/form/GridForm.vue';
import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import EditForm from '@/components/builder-controls/edit-form/EditForm.vue';
import PostSubmitElement from '@zyro-inc/site-modules/components/elements/form/PostSubmitElement.vue';

import { useElementEditPopup } from '@/use/useElementEditPopup';
import { useSiteStore } from '@/stores/siteStore';

const props = defineProps({
	elementId: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		required: true,
	},
	isActive: {
		type: Boolean,
		default: false,
	},
	isPreviewMode: {
		type: Boolean,
		required: false,
	},
});

const {
	dispatch,
	getters,
} = useStore();

const showSuccessMessage = ref(false);

const { isAiBuilderMode } = useBuilderMode();
const { closeElementEditPopupHandler } = useElementEditPopup({
	elementId: props.elementId,
});

const formRef = ref(null);

const siteStore = useSiteStore();

const website = computed(() => siteStore.site);
const siteForms = computed(() => getters.siteForms);

const canGenerateFormToken = computed(() => !siteForms.value[props.data.formId]?.token
		&& website.value
		&& !isAiBuilderMode.value);

const onSubmit = async () => {
	if (!props.isPreviewMode) {
		return;
	}

	showSuccessMessage.value = true;
};

onMounted(() => {
	if (canGenerateFormToken.value) {
		dispatch('forms/generateToken', {
			formId: props.data.formId,
		});
	}
});
</script>
