<script setup lang="ts">
import { useStore } from 'vuex';
import {
	ref,
	computed,
	watch,
	nextTick,
	toRefs,
} from 'vue';
import { useTextEditor } from '@/use/text-editor/useTextEditor';
import {
	GAMIFICATION_TASK_CHANGE_HEADING,
	GAMIFICATION_TASK_CHANGE_PARAGRAPH,
} from '@/constants/builderConstants';
import { useTracking } from '@/use/useTracking';
import { useGamification } from '@/use/useGamification';
import { useDeviceElementHeight } from '@/use/useDeviceElementHeight';
import { EditorContent } from '@tiptap/vue-3';
import { PAGE_TYPE_BLOG } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { useLayoutElements } from '@/use/useLayoutElements';
import GridTextBox from '@zyro-inc/site-modules/components/elements/text-box/GridTextBox.vue';
import { useBlogStore } from '@/stores/blogStore';
import DOMPurify from 'dompurify';

interface Props {
  content: string;
  styles?: { [key: string]: any };
  isTextEditMode: boolean;
  elementId?: string;
  textBoxRef: InstanceType<typeof GridTextBox> | null;
	isPreviewMode?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	isTextEditMode: false,
});

const emit = defineEmits<{
'update:content': [string]
}>();

const {
	state,
	getters,
	dispatch,
} = useStore();
const {
	isHeadingElement,
	isParagraphElement,
	completeAchievement,
} = useGamification();
const { updateElementHeightOnDevices } = useDeviceElementHeight();
const {
	editor,
	initializeEditor,
	removeEditor,
	setCaretPositionToEnd,
	getInlineStyleValue,
} = useTextEditor();
const { trackTextBoxCountChanges } = useTracking();
const { deleteSelectedElement } = useLayoutElements({
	selectedElementId: props.elementId,
});
const blogStore = useBlogStore();

interface SpaceBetweenLinesArea {
	width: number;
	offsetTop: number;
	height: number;
	nodeRef: Node;
	isHidden: boolean;
}

const { isTextEditMode } = toRefs(props);
const spaceBetweenLinesAreas = ref<SpaceBetweenLinesArea[]>([]);
const dragStartPositionY = ref<number | null>(null);
const dragStartMarginBottom = ref<number | null>(null);
const draggedAreaIndex = ref<number | null | undefined>(null);
const itemWidth = ref<number | null>(null);
const itemHeight = ref<number | null>(null);
const initialText = ref<string>('');
const textBoxEditorRef = ref<InstanceType<typeof EditorContent> | null>(null);

const sanitizedContent = computed(() => DOMPurify.sanitize(props.content));

const noSelectAreaStyles = computed(() => ({
	width: `${itemWidth.value}px`,
	height: `${itemHeight.value}px`,
}));
const isEditingSpaceBetweenLines = computed(() => !!dragStartPositionY.value);
const currentlyEditedNodeMarginBottom = computed(() => getInlineStyleValue('marginBottom') || `${dragStartMarginBottom.value}px`);

const getIsEditingSpaceBetweenLinesByIndex = (index: number) => isEditingSpaceBetweenLines.value && draggedAreaIndex.value === index
			&& !spaceBetweenLinesAreas.value[index].isHidden;

const getSpaceBetweenLinesAreas = () => {
	const textEditorElement = textBoxEditorRef?.value?.$el?.firstChild;

	// Prevents code execution on not yet initialized .firstChild
	if (!textEditorElement) return;

	// Prevent bottom margin change for list elements
	const preventedNodeNames = [
		'ul',
		'ol',
	];

	spaceBetweenLinesAreas.value = [...textEditorElement.childNodes].slice(0, -1).map((node, index) => ({
		width: textEditorElement.offsetWidth,
		offsetTop: props.textBoxRef.$el.offsetTop + node.offsetTop + node.offsetHeight,
		height: Number.parseInt(window.getComputedStyle(node).marginBottom, 10),
		nodeRef: [...textEditorElement.childNodes][index],
		isHidden: preventedNodeNames.includes(node?.localName),
	}));
};

const getGridItemWidthAndHeight = () => {
	// Grid item is this element parent, in UI inidentified with blue borders (resize handles).
	const textItemRef = props.textBoxRef?.$el.parentElement;

	itemWidth.value = textItemRef?.offsetWidth;
	itemHeight.value = textItemRef?.offsetHeight;
};

const handleTextBoxContentChange = () => {
	getSpaceBetweenLinesAreas();
	getGridItemWidthAndHeight();
};

const resetSpaceBetweenLinesAreas = () => {
	dragStartPositionY.value = null;
	dragStartMarginBottom.value = null;
	draggedAreaIndex.value = null;
	spaceBetweenLinesAreas.value = [];
};

const handleDragStart = (event: MouseEvent, initial: number, index: number) => {
	const textEditor = editor.value;

	if (!textEditor) return;

	dragStartPositionY.value = event.clientY;
	draggedAreaIndex.value = index;

	const textNodesEndPositions: number[] = [];

	// @ts-ignore
	textEditor.state.doc.content.content.forEach((node: any, nodeIndex: number) => {
		if (nodeIndex === 0) {
			textNodesEndPositions.push(node.nodeSize - 1);

			return;
		}

		textNodesEndPositions.push(textNodesEndPositions[nodeIndex - 1] + node.nodeSize);
	});

	textEditor.commands.setTextSelection(textNodesEndPositions[index]);

	dragStartMarginBottom.value = Number.parseInt(getInlineStyleValue('marginBottom'), 10) || initial;
};

const handleDragFinish = () => {
	if (!isTextEditMode.value) {
		return;
	}

	dragStartPositionY.value = null;
	dragStartMarginBottom.value = null;
	draggedAreaIndex.value = null;

	editor.value?.commands.focus();
};

const updateSpaceBetweenLines = (event: MouseEvent) => {
	if (!isTextEditMode.value || !editor.value) {
		return;
	}

	const MIN_SPACE_VALUE = 8;

	if (!dragStartPositionY.value) {
		return;
	}

	const dragValue = event.clientY - dragStartPositionY.value;
	const marginBottom = (dragStartMarginBottom.value as number) + dragValue;
	const marginBottomValid = marginBottom > MIN_SPACE_VALUE ? marginBottom : MIN_SPACE_VALUE;

	editor.value.chain()
		.updateAttributes('paragraph', {
			marginBottom: `${marginBottomValid}px`,
		})
		.updateAttributes('heading', {
			marginBottom: `${marginBottomValid}px`,
		})
		.run();
};

// to update line height @mousemove on text box parent
defineExpose({
	handleDragFinish,
	updateSpaceBetweenLines,
});

watch(isTextEditMode, async (value) => {
	if (value) {
		initializeEditor(props.content);
		await nextTick(); // Await tiptap editor element to be mounted

		if (!editor.value) return;
		initialText.value = editor.value.getText();

		setCaretPositionToEnd();

		editor.value.on('create', handleTextBoxContentChange);
		editor.value.on('update', () => {
			handleTextBoxContentChange();
		});

		return;
	}

	if (!editor.value) {
		return;
	}

	if (props.elementId) {
		dispatch('mergeElementData', {
			elementId: props.elementId,
			elementData: {
				content: editor.value.getHTML(),
			},
		});
	} else {
		emit('update:content', editor.value.getHTML());
	}

	const currentText = editor.value.getText();

	if (initialText.value !== currentText) {
		trackTextBoxCountChanges();

		if (isHeadingElement(props.content)) {
			completeAchievement(GAMIFICATION_TASK_CHANGE_HEADING);
		}

		if (isParagraphElement(props.content)) {
			completeAchievement(GAMIFICATION_TASK_CHANGE_PARAGRAPH);
		}
	}

	if (props.elementId) {
		await updateElementHeightOnDevices({
			elementId: props.elementId,
		});

		if (editor.value.state.doc.textContent.length === 0) {
			deleteSelectedElement();
		}

		editor.value.off('update', handleTextBoxContentChange);
	}

	removeEditor();
	resetSpaceBetweenLinesAreas();

	if (getters.currentPage.type === PAGE_TYPE_BLOG) {
		blogStore.calculateReadTime(state.currentPageId);
	}

	dispatch('undoRedo/createSnapshot');
});

watch(() => props.styles, () => {
	if (!isTextEditMode.value) {
		return;
	}

	handleTextBoxContentChange();
});

</script>

<template>
	<div>
		<EditorContent
			v-if="isTextEditMode"
			ref="textBoxEditorRef"
			:editor="editor"
			class="text-editor"
		/>
		<div
			v-else
			:class="{ 'pointer-events-none' : !props.isPreviewMode }"
			v-html="sanitizedContent"
		/>
		<!-- Space between lines editor -->
		<div v-if="isTextEditMode">
			<!-- 1. Area that prevents from selecting, while editing space between lines (user-select:none on textbox doesn't work) -->
			<div
				v-show="isEditingSpaceBetweenLines"
				class="space-between-lines-no-select-area"
				:style="noSelectAreaStyles"
				@mouseup="handleDragFinish"
			/>
			<!-- 2. A draggable area for each space between lines in textbox -->
			<div
				v-for="(verticalSpace, index) in spaceBetweenLinesAreas"
				:key="index"
				class="space-between-lines-area"
				:class="{
					'space-between-lines-area--disabled': verticalSpace.isHidden,
					'space-between-lines-area--active': getIsEditingSpaceBetweenLinesByIndex(index),
					'pointer-events-none': isEditingSpaceBetweenLines && !getIsEditingSpaceBetweenLinesByIndex(index)
				}"
				:style="{
					width: `${verticalSpace.width}px`,
					height: `${verticalSpace.height}px`,
					top: `${verticalSpace.offsetTop}px`,
				}"
				@mousedown="handleDragStart($event, verticalSpace.height, index)"
				@mouseup="handleDragFinish"
			>
				<div
					v-show="getIsEditingSpaceBetweenLinesByIndex(index)"
					class="space-between-lines-area__value text-body-2"
				>
					{{ currentlyEditedNodeMarginBottom }}
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.text-editor {
	position: relative;
}

.space-between-lines-no-select-area {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	cursor: row-resize;
	user-select: none;
	background: transparent;
}

.space-between-lines-area {
	position: absolute;
	left: 0;
	z-index: 2;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	padding: 8px;
	pointer-events: all;
	cursor: row-resize;
	background: transparent;

	&__value {
		min-width: 44px;
		padding: 2px;
		margin: 0;
		font-size: 12px;
		font-weight: 500;
		line-height: 1;
		color: $color-light;
		text-align: center;
		user-select: none;
		background-color: $color-azure;
		border-radius: 2px;
	}

	&:hover {
		background-color: rgba($color-azure, 0.2);
	}

	&--active {
		background-color: rgba($color-azure, 0.2);
	}

	&--disabled {
		pointer-events: none;

		&:hover {
			background-color: transparent;
		}
	}
}

.pointer-events-none {
	pointer-events: none;
}
</style>
