import { isObject } from '@zyro-inc/site-modules/utils/object';

export const snakeToCamel = (string: string, pascal?: boolean) => {
	const converter = (matches: string) => matches[1]?.toUpperCase();

	let result = string?.toString().replace(/(_\w)/g, converter);

	if (pascal) {
		result = result.charAt(0).toUpperCase() + result.slice(1);
	}

	return result;
};

export const snakeToCamelObj = (obj: any) => {
	if (isObject(obj) && !Array.isArray(obj)) {
		const n: { [key: string]: string } = {};

		Object.keys(obj).forEach((k) => {
			n[snakeToCamel(k)] = snakeToCamelObj(obj[k as keyof typeof obj]);
		});

		return n;
	}

	if (Array.isArray(obj)) {
		const n: Array<any> = [];

		obj.forEach((k: string) => n.push(snakeToCamelObj(k)));

		return n;
	}

	return obj;
};
