<script setup lang="ts">
import {
	provide,
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import EventLogApi from '@/api/EventLogApi';
import GridTextBox from '@zyro-inc/site-modules/components/elements/text-box/GridTextBox.vue';
import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import VerticalSeparator from '@/components/global/VerticalSeparator.vue';
import AiTextGeneratorFormControls from '@/components/builder-controls/ai-text-generator/AiTextGeneratorFormControls.vue';
import AiTextGeneratorTextControls from '@/components/builder-controls/ai-text-generator/AiTextGeneratorTextControls.vue';
import EditText from '@/components/builder-controls/edit-text/EditText.vue';
import { useBuilderMode } from '@/use/useBuilderMode';
import { useElementEditPopup } from '@/use/useElementEditPopup';
import TextBoxTextEditor from '@/components/text-edit/TextBoxTextEditor.vue';
import type { UpdateAnimations } from '@/types/animationTypes';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';
import { ANIMATION_TYPE_ELEMENT } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { useTextBoxInPreview } from '@/use/useTextBoxInPreview';

const props = defineProps({
	elementId: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		required: true,
	},
	isActive: {
		type: Boolean,
		default: false,
	},
	isPreviewMode: {
		type: Boolean,
		required: false,
	},
});

const {
	state,
	getters,
	dispatch,
} = useStore();

const { textBoxClickHandler } = useTextBoxInPreview(props.isPreviewMode);

const { closeElementEditPopupHandler } = useElementEditPopup({
	elementId: props.elementId,
});
const { isAiBuilderMode } = useBuilderMode();

const textBoxRef = ref<InstanceType<typeof GridTextBox> | null>(null);
const textBoxEditorRef = ref<InstanceType<typeof TextBoxTextEditor> | null>(null);

const { siteLanguages } = getters;
const currentLocale = computed(() => state.currentLocale);
const currentElementId = computed(() => getters.currentElementId);
const isCurrentElement = computed(() => currentElementId.value === props.elementId);
const isTextEditMode = computed(() => props.elementId === getters.currentElementId && state.isElementEditMode);
const animationType = computed(() => siteLanguages[currentLocale.value].elements[currentElementId.value].animation?.name);

const enterElementEditMode = () => dispatch('enterElementEditMode');
const updateSpaceBetweenLines = (event: MouseEvent) => {
	textBoxEditorRef.value?.updateSpaceBetweenLines(event);
};

const handleDragFinish = () => {
	textBoxEditorRef.value?.handleDragFinish();
};

const { displayAnimationInEditor } = useSiteEngineAnimations();

const updateAnimations:UpdateAnimations = (animation) => {
	displayAnimationInEditor({
		animation,
		id: currentElementId.value,
	});

	dispatch('mergeElementData', {
		elementId: currentElementId.value,
		elementData: {
			animation: animation !== 'none' ? {
				name: animation,
				type: ANIMATION_TYPE_ELEMENT,
			} : null,
		},
	});

	EventLogApi.logEvent({
		eventName: 'website_builder.styles_animation_style.selected',
		eventProperties: {
			animation,
			type: ANIMATION_TYPE_ELEMENT,
			location: currentElementId.value,
		},
	});
};

provide('updateAnimations', updateAnimations);
provide('animationType', animationType);
</script>
<template>
	<!-- notranslate class is needed to prevent browser extensions from breaking the textbox -->
	<!-- @dragstart.prevent needed to prevent default link dragging behavior -->
	<GridTextBox
		ref="textBoxRef"
		v-qa="'builder-gridelement-gridtextbox'"
		class="notranslate layout-element__component layout-element__component--GridTextBox"
		:data-element-ref="elementId"
		:text-align="data.settings.styles.text"
		:text-align-mobile="data.settings.styles['m-text']"
		:background-color="data.backgroundColor"
		@dragstart.prevent
		@keyup.enter="enterElementEditMode"
		@mouseleave="handleDragFinish"
		@mousemove="updateSpaceBetweenLines"
		@click="textBoxClickHandler"
	>
		<TextBoxTextEditor
			ref="textBoxEditorRef"
			:is-text-edit-mode="isTextEditMode"
			:element-id="elementId"
			:content="data.content"
			:styles="data.settings.styles"
			:text-box-ref="textBoxRef"
		/>
	</GridTextBox>

	<template v-if="!isPreviewMode">
		<ElementEditControls
			:target-ref="textBoxRef?.$el"
			:element-id="elementId"
			:is-element-active="isActive"
			:enter-edit-mode-button-title="$t('builder.editText.title')"
			:edit-mode-popup-options="{
				placement: 'top-start',
				offset: 22,
				flip: false,
				autoUpdate: true,
			}"
			is-mobile-popup-sticked-to-bottom
		>
			<template
				v-if="!isAiBuilderMode"
				#additional-edit-buttons
			>
				<VerticalSeparator />
				<AiTextGeneratorFormControls :element-id="elementId" />
			</template>
			<template #edit-mode-popup>
				<EditText @close="closeElementEditPopupHandler" />
			</template>
		</ElementEditControls>

		<AiTextGeneratorTextControls
			v-if="isCurrentElement"
			:element-id="elementId"
			:target-ref="textBoxRef?.$el"
		/>
	</template>
</template>
