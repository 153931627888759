<template>
	<div
		ref="slideshowManagerRef"
		class="slideshow-manager"
	>
		<DragDropOverlay
			v-if="isEmpty && !isAiBuilderMode"
			class="slideshow-manager__empty"
		>
			<template #drop-files-text>
				{{ $t('builder.assetManagerSlideshowAddImagesWhenEmpty') }}
			</template>
			<template #placeholder-button>
				<HostingerButton
					v-qa="'chooseimage-btn-browsefiles'"
					@click="$emit('open-asset-manager')"
				>
					<template #icon-left>
						<Icon name="cloud_upload" />
					</template>
					{{ $t('builder.assetManagerSlideshowAddImages') }}
				</HostingerButton>
			</template>
		</DragDropOverlay>
		<!-- Cannot be wrapper in a <div>, because overflow-y doesn't work on masonry anymore -->
		<template v-else>
			<ImagesControlBar @open-asset-manager="$emit('open-asset-manager')" />
			<HorizontalMasonry
				:images="imagesObject"
				@remove-from-gallery="removeSlide"
				@move-image="moveSlide"
			/>
		</template>
	</div>
</template>

<script setup>
import {
	onMounted,
	ref,
	watch,
	computed,
} from 'vue';
import { useStore } from 'vuex';

import { getOptimizedSrc } from '@zyro-inc/site-modules/utils/getSrcsets';

import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import HorizontalMasonry from '@/components/builder-modals/modals/asset-manager/gallery/HorizontalMasonry.vue';
import { useAssets } from '@/use/useAssets';
import DragDropOverlay from '@/components/builder-modals/modals/asset-manager/user/DragDropOverlay.vue';
import ImagesControlBar from '@/components/builder-modals/modals/asset-manager/user/ImagesControlBar.vue';
import { useImageSlideshow } from '@zyro-inc/site-modules/components/blocks/slideshow/useImageSlideshow';
import { useBuilderMode } from '@/use/useBuilderMode';

const props = defineProps({
	slideshowId: {
		type: String,
		required: true,
	},
});

const emit = defineEmits(['open-asset-manager']);

const {
	state,
	getters,
	dispatch,
} = useStore();

const { isAiBuilderMode } = useBuilderMode();
const {
	listenForDragAndDrop,
	isDraggedOver,
} = useAssets(props);

const { slideshowRefs } = useImageSlideshow();
const { slideTo } = slideshowRefs.value[props.slideshowId];

const slideshowManagerRef = ref(null);

onMounted(() => {
	listenForDragAndDrop(slideshowManagerRef.value);
});

// To not handle all overlay actions, on drag, open asset manager.
watch(isDraggedOver, () => emit('open-asset-manager'));

const images = computed(() => getters.currentBlock.slides);

const imagesObject = computed(() => ({
	...images.value.map((image) => ({
		url: getOptimizedSrc(image.origin, image.path, state.websiteId, {
			width: 200,
		}),
	})),
}));

const isEmpty = computed(() => Object.keys(imagesObject.value).length === 0);

const moveSlide = ({
	newIndex,
	oldIndex,
}) => {
	const slides = [...images.value];

	slides.splice(newIndex, 0, slides.splice(oldIndex, 1)[0]);

	dispatch('updateBlockData', {
		blockId: props.slideshowId,
		blockData: {
			slides,
		},
		merge: true,
	});
};

const removeSlide = (slideIndex) => {
	dispatch('updateBlockData', {
		blockId: props.slideshowId,
		blockData: {
			slides: images.value.filter((_, index) => index !== slideIndex),
		},
		merge: true,
	});

	slideTo(0);
};
</script>

<style lang="scss" scoped>
.slideshow-manager {
	height: 100%;
	padding: 0 20px;

	&__empty {
		display: flex;
		justify-content: center;
		height: 100%;
	}
}
</style>
