<script setup lang="ts">
import ZyroTooltip from '@/components/global/ZyroTooltip.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import ZyroProgressBar from '@/components/reusable-components/ZyroProgressBar.vue';
import {
	SIDEBAR_WIDTH,
	HEADER_HEIGHT,
} from '@/constants/builderConstants';
import { useGamification } from '@/use/useGamification';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const {
	completedAchievementsCount,
	achievements,
} = useGamification();

const emit = defineEmits<{'action-click': []}>();
</script>

<template>
	<ZyroTooltip
		content-position="absolute"
		toggle-event="none"
		position="right"
		:forced-position="{
			left: `${SIDEBAR_WIDTH + 10}px`,
			top: `${HEADER_HEIGHT + 8}px`,
			paddingLeft: '8px',
			paddingTop: '8px',
			paddingBottom: '8px',
		}"
		:custom-triangle-style=" {
			top: '14px',
			bottom: 'unset',
		}"
		class="tooltip"
		has-close-icon
	>
		<div class="content">
			<p class="content__title text-bold-2">
				<Icon
					class="content__check"
					name="check_circle"
					color="success"
					is-filled
					dimensions="24px"
				/>
				<span class="content__title-text">
					{{ t('builder.buildEasierWithInteractiveList') }}
				</span>
			</p>
			<div class="content__progress-wrapper">
				<p class="content__steps text-body-2">
					{{ t('builder.gamificationProgressTextSteps', [completedAchievementsCount, achievements.length]) }}
				</p>

				<ZyroProgressBar
					with-border
					class="content__progress-bar"
					:percentage="20"
					color="success"
				/>

				<HostingerButton
					v-qa="'builder-gamificationtooltip-link-opendrawer'"
					button-type="outlined"
					size="sm"
					class="content__progress-button"
					@click="emit('action-click')"
				>
					<p class="content__progress-button-text">
						{{ t('builder.gamificationCompleteSteps') }}
					</p>
					<Icon
						name="keyboard_arrow_right"
						dimensions="16px"
					/>
				</HostingerButton>
			</div>
		</div>
	</ZyroTooltip>
</template>

<style lang="scss" scoped>
.tooltip {
	box-shadow: $box-shadow-base;
}

.content {
	&__title {
		margin-bottom: 8px;
		display: flex;
		gap: 8px;
	}

	&__title-text {
		width: 276px;
		padding-right: 26px;
	}

	&__check {
		flex-shrink: 0;
	}

	&__progress-wrapper {
		margin-left: 28px;
	}

	&__more-steps {
		text-decoration: underline;
		cursor: pointer;
	}

	&__progress-button {
		width: 100%;
	}

	&__progress {
		height: 15px;
		border-radius: 7.5px;
		width: 308px;
		background-color: var(--color-light);
		position: relative;
		margin-bottom: 8px;

		&::before {
			content: "";
			position: absolute;
			border-radius: 7.5px;
			height: 13px;
			width: 28px;
			left: 1px;
			top: 1px;
			background-color: var(--color-success);
		}
	}
}
</style>
