<script setup lang="ts">
import TagMultiselect from '@/components/global/TagMultiselect.vue';
import PageSettingsSeoKeywordsAddButton from '@/components/PageSettingsSeoKeywordsAddButton.vue';
import CheckerList from '@/components/global/CheckerList.vue';
import { usePageSeoSettings } from '@/use/usePageSeoSettings';

import { MultiselectTag } from '@/types/sharedPropTypes';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const MAX_KEYWORDS = 3;
const MAX_FOCUS_KEYWORDS = 1;

const { t } = useI18n();

const {
	pageMetaKeywords,
	pageMetaFocusKeyword,
	mergePageMeta,
} = usePageSeoSettings();

const tagMultiselectPageMetaKeywords = computed(() => pageMetaKeywords.value.map((name: string) => ({
	value: name,
})));
const pageFocusKeywordTag = computed<MultiselectTag | null>(() => {
	if (!pageMetaFocusKeyword.value) {
		return null;
	}

	return {
		value: pageMetaFocusKeyword.value,
	};
});

const pageMetaKeywordsCount = computed<number>(() => pageMetaKeywords.value.length);
const isPageMetaKeywordsLimitReached = computed<boolean>(() => pageMetaKeywordsCount.value >= MAX_KEYWORDS);

const checkerList = computed(() => ([
	{
		id: 'focusKeyword',
		isCompleted: !!pageMetaFocusKeyword.value,
		text: {
			keyPath: 'builder.seoDrawer.seoChecker.seoCheckerFocusKeywordShouldBeAdded',
		},
	},
]));

const addKeyword = (keyword: string) => {
	if (!isPageMetaKeywordsLimitReached.value) {
		mergePageMeta({
			...(!pageMetaKeywords.value.length ? {
				focusKeyword: keyword,
			} : {}),
			keywords: [
				...pageMetaKeywords.value,
				keyword,
			],
		});
	}
};

const removeKeyword = ({ value } : { value: string }) => {
	const updatedKeywords = pageMetaKeywords.value.filter((currentKeyword) => currentKeyword !== value);
	const isFocusKeywordRemoved = pageMetaFocusKeyword.value === value;

	mergePageMeta({
		keywords: updatedKeywords,
		...isFocusKeywordRemoved ? {
			focusKeyword: updatedKeywords[0] || null,
		} : {},
	});
};

const setFocusKeyword = (keywords : Array<MultiselectTag>) => {
	const newFocusKeyword = keywords[0]?.value;

	mergePageMeta({
		focusKeyword: newFocusKeyword,
	});
};

</script>
<template>
	<div class="page-settings-seo-general__keywords-header">
		<p class="text-bold-2">
			{{ t('builder.focusKeyword') }}
		</p>
		<p class="page-settings-seo-general__keywords-header-counter text-body-2">
			{{ `${pageMetaKeywordsCount}/${MAX_KEYWORDS}` }}
		</p>
	</div>

	<p class="page-settings-seo-general__keywords-message">
		{{ t('builder.focusKeywordMessage') }}
	</p>

	<PageSettingsSeoKeywordsAddButton
		:page-meta-keywords="pageMetaKeywords"
		:merge-page-meta="mergePageMeta"
		:disabled="isPageMetaKeywordsLimitReached"
		:is-page-meta-keywords-limit-reached="isPageMetaKeywordsLimitReached"
		@add="addKeyword"
	/>

	<TagMultiselect
		class="page-settings-seo-general__keywords-multiselect"
		:class="{ 'page-settings-seo-general__keywords-multiselect--empty': !tagMultiselectPageMetaKeywords.length }"
		:tags="tagMultiselectPageMetaKeywords"
		:model-value="pageFocusKeywordTag ? [pageFocusKeywordTag] : []"
		:max-selections="MAX_FOCUS_KEYWORDS"
		is-remove-button-visible
		is-single-select
		qa-prefix="settings-seo-keywords-tags"
		@update:model-value="setFocusKeyword"
		@remove-tag="removeKeyword"
	/>

	<CheckerList :checker-items="checkerList" />
</template>

<style lang="scss" scoped>
.page-settings-seo-general {
	&__keywords-header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;
	}

	&__keywords-header-counter {
		color: $color-gray;
	}

	&__keywords-message {
		margin-bottom: 16px;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.71;
		color: $color-gray;
	}

	&__keywords-multiselect {
		margin: 16px 0;

		&--empty {
			margin: 16px 0 0;
		}
	}
}
</style>
