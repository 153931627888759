<template>
	<div class="navigation-style">
		<ZyroSelect
			class="navigation-style__dropdown"
			:options="styleOptions"
			:model-value="selectedStyleOption"
			:filterable="false"
			:searchable="false"
			@update:model-value="updateSelectedStyleOption"
		>
			<template #option="{ option }">
				{{ option.title }}
			</template>

			<template #selected-option="{ option }">
				{{ option.title }}
			</template>
		</ZyroSelect>

		<EditBlockHeaderTabStyleHeader v-if="selectedStyleOption.id === StyleOptionType.HEADER" />
		<EditBlockHeaderTabStyleButton v-if="selectedStyleOption.id === StyleOptionType.BUTTON" />
		<EditBlockHeaderTabStyleSocialIcons v-else-if="selectedStyleOption.id === StyleOptionType.SOCIAL_ICONS" />

		<template v-else>
			<!-- Button element -->
		</template>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import EditBlockHeaderTabStyleHeader from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabStyleHeader.vue';
import EditBlockHeaderTabStyleButton from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabStyleButton.vue';
import EditBlockHeaderTabStyleSocialIcons from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabStyleSocialIcons.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';

const { t } = useI18n();

enum StyleOptionType {
	HEADER = 'header',
	BUTTON = 'button',
	SOCIAL_ICONS = 'socialIcons',
}

const styleOptions = [
	{
		id: StyleOptionType.HEADER,
		title: t('common.header'),
	},
	{
		id: StyleOptionType.BUTTON,
		title: t('common.button'),
	},
	{
		id: StyleOptionType.SOCIAL_ICONS,
		title: t('common.socialIcons'),
	},
] as const;

type StyleOption = typeof styleOptions[number];

const selectedStyleOption = ref<StyleOption>(styleOptions[0]);

const updateSelectedStyleOption = (option: StyleOption) => {
	selectedStyleOption.value = option;
};
</script>

<style lang="scss" scoped>
.navigation-style {
	&__dropdown {
		margin-bottom: 16px;
	}

	:deep(.navigation-style__dropdown) {
		.vs__dropdown-menu {
			min-height: 90px !important;
		}
	}

	&__item {
		padding: 8px 0;
	}

	&__separator {
		margin: 8px 0;
	}

	&__edit-text-styles-button {
		display: block;
		align-self: center;
		margin: 12px auto 0;
	}
}
</style>
