<template>
	<div class="add-block-button">
		<HostingerButton
			ref="addSectionButton"
			v-qa="'builder-btn-addsection'"
			class="add-block-button__button"
			:class="{ 'add-block-button__button--mobile': isMobileMode }"
			:aria-label="$t('common.addSection')"
			theme="add-block"
			@click="addSection"
		>
			<template #icon-left>
				<Icon name="add" />
			</template>
			{{ isMobileMode ? null : $t('common.addSection') }}
		</HostingerButton>
	</div>
</template>

<script>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';

import {
	mapActions,
	mapGetters,
	mapState,
} from 'vuex';

import {
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';
import { MODAL_ADD_BLOCK } from '@/constants/builderConstants';

import { defineComponent } from 'vue';
import EventLogApi from '@/api/EventLogApi';

export default defineComponent({
	components: {
		Icon,
		HostingerButton,
	},

	props: {
		blockId: {
			type: String,
			default: null,
		},
	},

	computed: {
		...mapState(['currentPageId']),
		...mapGetters('gui', ['isMobileMode']),
		addSectionLabel() {
			return this.isMobileMode ? null : this.$t('common.addSection');
		},
	},

	methods: {
		...mapActionsGui({
			openModal: OPEN_MODAL,
		}),
		...mapActions([
			'addBlock',
			'unselectCurrentElement',
		]),
		addSection() {
			this.unselectCurrentElement();
			this.openModal({
				name: MODAL_ADD_BLOCK,
				settings: {
					previousBlockId: this.blockId,
				},
			});
			this.$refs.addSectionButton.$el.blur();

			EventLogApi.logEvent({
				eventName: 'website_builder.add_section.enter',
			});
		},
	},
});
</script>

<style lang="scss" scoped>
.add-block-button {
	&__button {
		&--mobile {
			padding: 2px 0 2px 8px;

			&:focus:hover {
				padding: 2px 0 2px 10px;
			}
		}
	}
}
</style>
