<template>
	<ZyroDrawer
		class="ai-sales-assistant-drawer"
		@back="closeInnerSidebar"
	>
		<template #header>
			<h2 class="ai-sales-assistant-drawer__header-title">
				{{ $t('builder.aiSalesAssistantDrawer.titleV3') }}<BetaBadge />
			</h2>
			<p class="ai-sales-assistant-drawer__header-description">
				{{ $t('builder.aiSalesAssistantDrawer.descriptionV2') }}
				{{ ' ' }}
				<a
					data-qa="ai-sales-assistant-learn-more-link"
					target="_blank"
					class="z-link"
					href="https://support.hostinger.com/en/articles/9858755-website-builder-ai-assistant-integration"
				>
					{{ $t('common.learnMore') }}
				</a>
			</p>
			<i18n-t
				class="ai-sales-assistant-drawer__header-secondary-description"
				tag="p"
				keypath="builder.aiSalesAssistantDrawer.secondaryDescriptionV2"
			>
				{{ chatLimit }}
			</i18n-t>
		</template>
		<template #body>
			<div class="ai-sales-assistant-drawer__toggle-wrapper">
				<ZyroFieldToggle
					id="ai-sales-assistant-toggle"
					v-qa="'ai-sales-assistant-toggle'"
					class="ai-sales-assistant-drawer__toggle"
					:model-value="isAiSalesAssistantEnabled"
					:label="$t('builder.aiSalesAssistantDrawer.settingTitleV2')"
					@update:model-value="($event) => updateIsAiSalesAssistantEnabled($event)"
				/>
			</div>

			<i18n-t
				class="ai-sales-assistant-drawer__status-message"
				tag="p"
				keypath="builder.aiSalesAssistantDrawer.previewDisclaimer"
			>
				<span
					data-qa="ai-sales-assistant-preview-mode-link"
					target="_blank"
					class="z-link"
					@click="openPreview"
				>
					{{ $t('common.previewMode') }}
				</span>
			</i18n-t>

			<HostingerButton
				v-if="isAiSalesAssistantEnabled"
				class="ai-sales-assistant-drawer__preview-button"
				button-type="outlined"
				@click="openPreview"
			>
				{{ $t('builder.aiSalesAssistantDrawer.previewButton') }}
			</HostingerButton>

			<HImage
				class="ai-sales-assistant-drawer__preview-image"
				src="/images/ai-assistant-preview.png"
			/>

			<div v-if="aiSalesAssistantSettingsItems.length">
				<p class="ai-sales-assistant-drawer__settings-title">
					{{ $t('builder.aiSalesAssistantDrawer.settingsTitle') }}
				</p>
				<div class="ai-sales-assistant-drawer__items">
					<div
						v-for="(item, index) in aiSalesAssistantSettingsItems"
						:key="index"
						v-qa="`ai-sales-assistant-item-${item.iconName}`"
						class="ai-sales-assistant-drawer__item"
						@click="item.action"
					>
						<Icon
							class="ai-sales-assistant-drawer__item-icon"
							:name="item.iconName"
							dimensions="20px"
						/>
						<p class="ai-sales-assistant-drawer__item-title text-body-2">
							{{ item.title }}
						</p>

						<Icon
							class="ai-sales-assistant-drawer__item-arrow"
							name="chevron_right"
							dimensions="24px"
						/>
					</div>
				</div>
			</div>
			<div class="ai-sales-assistant-drawer__nps-container">
				<NpsRateFeature
					:feature-name="t('builder.aiSalesAssistantDrawer.titleV2')"
					:type="NPS_TYPE_FEATURE_AI_SALES_ASSISTANT"
				/>
			</div>
		</template>
	</ZyroDrawer>
</template>

<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import ZyroDrawer from '@/components/global/ZyroDrawer.vue';

import { useSidebar } from '@/use/useSidebar';

import { computed } from 'vue';
// import { useStore } from 'vuex';

import { useSiteStore } from '@/stores/siteStore';
import { NPS_TYPE_FEATURE_AI_SALES_ASSISTANT } from '@/constants/builderConstants';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';

import { useI18n } from 'vue-i18n';
import BetaBadge from '@/components/ui/BetaBadge.vue';
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import HImage from '@/components/global/HImage.vue';
import { PREVIEW_ROUTE } from '@/constants/routes';
import EventLogApi from '@/api/EventLogApi';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

// const { dispatch } = useStore();
const { t } = useI18n();

const siteStore = useSiteStore();
const router = useRouter();
const { dispatch } = useStore();

const aiSalesAssistantData = computed(() => siteStore.aiSalesAssistant);
const isAiSalesAssistantEnabled = computed(() => !!siteStore.aiSalesAssistant?.isEnabled);

const { closeInnerSidebar } = useSidebar();

const chatLimit = 100;

const openPreview = () => {
	dispatch('unselectCurrentElement');

	router.push({
		name: PREVIEW_ROUTE,
	});

	EventLogApi.logEvent({
		eventName: 'website_builder.builder.previewed',
	});
};

// TODO uncomment when ready
const aiSalesAssistantSettingsItems: any[] = [];
// const aiSalesAssistantSettingsItems = [
// {
// title: t('builder.aiSalesAssistantDrawer.customizeDesignTitle'),
// iconName: 'shapes',
// action: () => {
// dispatch('gui/TOGGLE_DRAWER', TODO);
// },
// },$c
// ];

const updateIsAiSalesAssistantEnabled = (newValue: boolean) => {
	EventLogApi.logEvent({
		eventName: 'website_builder.website_settings_ai_sales_assistant.toggle',
		eventProperties: {
			is_enabled: newValue,
		},
	});

	siteStore.setSiteMetaData({
		key: 'aiSalesAssistant',
		value: {
			...aiSalesAssistantData.value,
			isEnabled: newValue,
		},
	});
};
</script>

<style lang="scss" scoped>
.ai-sales-assistant-drawer {
	&__header-title {
		display: flex;
		align-items: center;
		gap: 8px;
		color: var(--color-gray-dark);
		font-size: 24px;
		font-weight: 700;
		line-height: 1.3;
		margin-bottom: 8px;
	}

	&__header-description {
		margin-bottom: 8px;
		color: var(--color-gray);
		font-size: 14px;
		line-height: 1.7;
	}

	&__header-secondary-description {
		color: var(--color-gray);
		font-size: 12px;
		line-height: 20px;
	}

	&__toggle-wrapper {
		margin-bottom: 8px;
	}

	&__toggle {
		padding: 0;
	}

	&__status-message {
		margin-bottom: 16px;
		color: var(--color-gray);
		font-size: 14px;
		line-height: 1.7;
	}

	&__limit-message {
		padding: 8px;
		background: var(--color-gray-light);
		border-radius: 8px;
		font-size: 12px;
		line-height: 20px;
		text-align: center;
		color: var(--color-gray);
	}

	&__settings-title {
		margin: 16px 0 8px;
		font-size: 14px;
		line-height: 24px;
		color: var(--color-dark);
		font-weight: 700;
	}

	&__nps-container {
		margin-top: 16px;
	}

	&__preview-button {
		width: 100%;
		margin-bottom: 16px;
	}

	&__preview-image {
		width: 100%;
	}

	&__item {
		display: flex;
		height: 56px;
		align-items: center;
		border-bottom: 1px solid var(--color-gray-border);
		padding: 12px 8px;
		cursor: pointer;

		&:hover,
		&:focus {
			background-color: var(--color-gray-light);
			transition: background-color 0.3s ease 0s;
		}

		&:last-child {
			margin-left: auto;
		}
	}

	&__item-icon {
		margin-right: 8px;
	}

	&__item-title {
		margin-right: 16px;
	}

	&__item-arrow {
		margin-left: auto;
		color: var(--color-gray-dark);
	}
}
</style>
