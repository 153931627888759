<template>
	<div class="button-style-settings">
		<ZyroSegmentControl
			class="button-style-settings__button-state"
			:controls="buttonStates"
			:active-control="currentStateTab"
			@update:active-control="setButtonState"
		/>

		<div class="button-style-settings__group">
			<ZyroFieldColorPicker
				v-if="isEditingHoverState"
				v-qa="'button-style-settings-fill-color-picker-hover'"
				:is-open="isBackgroundColorPickerOpen"
				:label="$t('builder.editButton.customButtonStyles.fillColor')"
				class="button-style-settings__item"
				:color="fillColorHover"
				@click-outside="isBackgroundColorPickerOpen = false"
				@toggle="isBackgroundColorPickerOpen = !isBackgroundColorPickerOpen"
				@update-color="setButtonColor(
					$event,
					{ localStylesProperty: 'backgroundColorHover' }
				)"
			/>
			<ZyroFieldColorPicker
				v-else
				v-qa="'button-style-settings-fill-color-picker-default'"
				:is-open="isBackgroundColorPickerOpen"
				:label="$t('builder.editButton.customButtonStyles.fillColor')"
				class="button-style-settings__item"
				:color="fillColor"
				@click-outside="isBackgroundColorPickerOpen = false"
				@toggle="isBackgroundColorPickerOpen = !isBackgroundColorPickerOpen"
				@update-color="setButtonColor(
					$event,
					{ localStylesProperty: 'backgroundColor' }
				)"
			/>
		</div>

		<div class="button-style-settings__group">
			<ZyroFieldColorPicker
				v-if="isEditingHoverState"
				v-qa="'button-style-settings-font-color-picker-hover'"
				:is-open="isFontColorPickerOpen"
				:label="$t('builder.editButton.customButtonStyles.textColor')"
				class="button-style-settings__item"
				:color="fontColorHover"
				@click-outside="isFontColorPickerOpen = false"
				@toggle="isFontColorPickerOpen = !isFontColorPickerOpen"
				@update-color="setButtonColor(
					$event,
					{ localStylesProperty: 'fontColorHover' }
				)"
			/>
			<ZyroFieldColorPicker
				v-else
				v-qa="'button-style-settings-font-color-picker-default'"
				:is-open="isFontColorPickerOpen"
				:label="$t('builder.editButton.customButtonStyles.textColor')"
				class="button-style-settings__item"
				:color="fontColor"
				@click-outside="isFontColorPickerOpen = false"
				@toggle="isFontColorPickerOpen = !isFontColorPickerOpen"
				@update-color="setButtonColor(
					$event,
					{ localStylesProperty: 'fontColor' }
				)"
			/>

			<div v-if="!isEditingHoverState">
				<ZyroFieldFontSelect
					:current-font-family="currentFontFamily"
					:is-open="isFontSelectOpen"
					@set-font-family="setFontFamily"
					@toggle="isFontSelectOpen = !isFontSelectOpen"
					@close="isFontSelectOpen = false"
				/>
			</div>

			<div
				v-if="!isEditingHoverState"
				class="button-style-settings__group"
			>
				<ZyroLabel class="button-style-settings__label">
					{{ $t('builder.editButton.customButtonStyles.text.size') }}
				</ZyroLabel>
				<ZyroRange
					v-qa="'button-style-text-size-slider'"
					:min="MIN_FONT_SIZE"
					:max="MAX_FONT_SIZE"
					:step="1"
					has-number-input
					:model-value="fontSize"
					@update:model-value="setFontSize"
				/>
			</div>
		</div>

		<div class="button-style-settings__group">
			<ZyroFieldColorPicker
				v-if="isEditingHoverState"
				v-qa="'button-style-settings-border-color-picker-default'"
				:is-open="isBorderColorPickerOpen"
				:label="$t('builder.editButton.customButtonStyles.borderColor')"
				:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
				class="button-style-settings__item"
				:color="borderColorHover"
				@click-outside="isBorderColorPickerOpen = false"
				@toggle="isBorderColorPickerOpen = !isBorderColorPickerOpen"
				@update-color="setButtonColor(
					$event,
					{ localStylesProperty: 'borderColorHover' }
				)"
			/>
			<ZyroFieldColorPicker
				v-else
				v-qa="'button-style-settings-border-color-picker-default'"
				:is-open="isBorderColorPickerOpen"
				:label="$t('builder.editButton.customButtonStyles.borderColor')"
				:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
				class="button-style-settings__item"
				:color="borderColor"
				@click-outside="isBorderColorPickerOpen = false"
				@toggle="isBorderColorPickerOpen = !isBorderColorPickerOpen"
				@update-color="setButtonColor(
					$event,
					{ localStylesProperty: 'borderColor' }
				)"
			/>

			<div
				v-if="!isEditingHoverState"
				class="button-style-settings__group"
			>
				<ZyroLabel class="button-style-settings__label">
					{{ $t('common.borderWidth') }}
				</ZyroLabel>
				<ZyroRange
					v-qa="'button-style-border-width-slider'"
					:min="MIN_BORDER_WIDTH"
					:max="MAX_BORDER_WIDTH"
					:step="1"
					has-number-input
					:model-value="borderWidth"
					@update:model-value="setBorderWidth"
				/>
				<ZyroLabel class="button-style-settings__label">
					{{ $t('common.cornerRadius') }}
				</ZyroLabel>
				<ZyroRange
					v-qa="'button-style-border-radius-slider'"
					:min="MIN_BORDER_RADIUS"
					:max="maxBorderRadius"
					:step="1"
					has-number-input
					:model-value="borderRadius"
					@update:model-value="setBorderRadius"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	ref,
	computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import ZyroFieldColorPicker from '@/components/global/ZyroFieldColorPicker.vue';
import ZyroFieldFontSelect from '@/components/global/ZyroFieldFontSelect.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroRange from '@/components/global/ZyroRange.vue';
import ZyroSegmentControl from '@/components/global/ZyroSegmentControl.vue';
import { getGridItemSize } from '@zyro-inc/site-modules/utils/getGridItemSize';
import { getDefaultFontFamilyName } from '@/utils/getDefaultFontFamilyName';
import { SELECTOR_DATA_PORTAL_BUILDER_PREVIEW } from '@/constants/builderConstants';
import {
	ELEMENT_POSITION_KEY_DESKTOP,
	ELEMENT_POSITION_KEY_MOBILE,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

const MIN_FONT_SIZE = 10;
const MAX_FONT_SIZE = 48;
const MIN_BORDER_RADIUS = 0;
const MIN_BORDER_WIDTH = 0;
const MAX_BORDER_WIDTH = 10;
const FALLBACK_BUTTON_MAX_BORDER_RADIUS = 25;

type Props = {
	elementId?: string;
};

type Emits = {
	'set-button-style': [Record<string, string | number | Record<string, string | number>>],
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const { t } = useI18n();
const { getters } = useStore();
const buttonStates = [
	{
		title: t('builder.editButton.customButtonStyles.normal'),
	},
	{
		title: t('builder.editButton.customButtonStyles.hover'),
	},
];

const currentStateTab = ref(buttonStates[0]);
const isFontSelectOpen = ref(false);
const isBackgroundColorPickerOpen = ref(false);
const isFontColorPickerOpen = ref(false);
const isBorderColorPickerOpen = ref(false);
const isMobileMode = computed(() => getters['gui/isMobileMode']);
const currentBlock = computed(() => getters.currentBlock);
const siteStyles = computed(() => getters.siteStyles);
const currentElement = computed(() => (props.elementId ? getters.siteElements[props.elementId] : null));
const currentElementSettings = computed(() => currentElement.value?.settings);
const currentButtonType = computed(() => currentElementSettings.value.type);
const currentButtonTypeStyles = computed(() => siteStyles.value[`grid-button-${currentButtonType.value}`]);
const fontSize = computed(() => {
	const currentFontSize = currentElement.value[isMobileMode.value ? 'mobile' : 'desktop']?.fontSize;
	const defaultFontSize = Number.parseInt(siteStyles.value[`grid-button-${currentButtonType.value}`]?.['font-size'], 10);

	return Number.parseInt(currentFontSize || defaultFontSize, 10);
});
const gridElementHeight = computed(() => (currentElementSettings.value.styles.position
	? getGridItemSize(currentBlock.value, currentElementSettings.value.styles.position)?.height
	: undefined));
const gridElementWidth = computed(() => (currentElementSettings.value.styles.position
	? getGridItemSize(currentBlock.value, currentElementSettings.value.styles.position)?.width
	: undefined));
const maxBorderRadius = computed(() => {
	const elementHeight = currentElement.value.desktop?.height || gridElementHeight.value;
	const elementWidth = currentElement.value.desktop?.width || gridElementWidth.value;

	const maxBorderRadiusHeightWise = typeof elementHeight === 'number' ? Math.ceil(elementHeight / 2) : FALLBACK_BUTTON_MAX_BORDER_RADIUS;
	const maxBorderRadiusLengthWise = typeof elementWidth === 'number' ? Math.ceil(elementWidth / 2) : FALLBACK_BUTTON_MAX_BORDER_RADIUS;

	return Math.min(maxBorderRadiusHeightWise, maxBorderRadiusLengthWise);
});
const borderRadius = computed(() => {
	const defaultBorderRadius = Number.parseInt(siteStyles.value[`grid-button-${currentButtonType.value}`]?.['border-radius'], 10);

	return currentElement.value.borderRadius ?? Math.min(maxBorderRadius.value, defaultBorderRadius);
});
const currentFontFamily = computed(() => {
	const elementFontFamily = currentElement.value.fontFamily;
	const isPrimaryFont = currentButtonTypeStyles.value['font-family'].includes('primary');

	const defaultFontFamily = getDefaultFontFamilyName(siteStyles.value, isPrimaryFont);

	return elementFontFamily || defaultFontFamily;
});
const isEditingHoverState = computed(() => currentStateTab.value.title === buttonStates[1].title);
const fillColor = computed(() => currentElement.value.backgroundColor || 'rgb(0, 0, 0)');
const fillColorHover = computed(() => currentElement.value.backgroundColorHover || 'rgb(29, 30, 32)');
const fontColor = computed(() => currentElement.value.fontColor || currentButtonTypeStyles.value.color);
const fontColorHover = computed(() => currentElement.value.fontColorHover || currentButtonTypeStyles.value['color-hover']);
const borderColor = computed(() => currentElement.value.borderColor || currentButtonTypeStyles.value['border-color']);
const borderColorHover = computed(() => currentElement.value.borderColorHover || currentButtonTypeStyles.value['border-color-hover']);
const borderWidth = computed(() => currentElement.value.borderWidth || 0);
const setButtonState = (value: typeof buttonStates[number]) => {
	currentStateTab.value = value;
};

const setButtonColor = (value: string, { localStylesProperty }: { localStylesProperty: string }) => {
	emit('set-button-style', {
		[localStylesProperty]: value,
	});
};

const setFontFamily = ({
	fontFamily,
	fontWeight,
	fileType,
}: {
	fontFamily: string,
	fontWeight: string,
	fileType: string,
}) => {
	emit('set-button-style', {
		fontFamily,
		fontWeight: Number.parseInt(fontWeight, 10),
		fileType,
	});
	isFontSelectOpen.value = false;
};

const setFontSize = (value: number) => {
	emit('set-button-style', {
		[isMobileMode.value ? ELEMENT_POSITION_KEY_MOBILE : ELEMENT_POSITION_KEY_DESKTOP]: {
			fontSize: value,
		},
	});
};

const setBorderWidth = (value: number) => {
	emit('set-button-style', {
		borderWidth: value,
		// Sets initial borderColor if value is missing
		...(!currentElement.value.borderColor ? {
			borderColor: 'rgb(26, 26, 26)',
		} : {}),
	});
};

const setBorderRadius = (value: number) => {
	emit('set-button-style', {
		borderRadius: value,
	});
};
</script>

<style lang="scss" scoped>
.button-style-settings {
	&__group {
		padding: 8px 0;

		&:not(:last-child) {
			border-bottom: 1px solid $color-gray-light;
		}
	}

	&__item {
		padding: 8px 0;
	}

	&__item-horizontal {
		display: flex;
		padding: 8px 0;
	}

	&__text-font-button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 6px 8px;
		cursor: pointer;
		background: $color-gray-light;
		border-radius: 5px;
	}

	&__current-font {
		max-width: 117px;
		margin-right: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__separator {
		width: 1px;
		height: 14px;
		background: $color-gray-border;
	}

	&__text {
		margin-left: 12px;
		color: $color-azure;
		white-space: nowrap;
	}
}
</style>
