<script setup lang="ts">
import { addBreadcrumb } from '@sentry/vue';
import {
	computed,
	useSlots,
} from 'vue';

import { hasSlotContent } from '@zyro-inc/site-modules/utils/hasSlotContent';
import { HostingerButtonProps } from '@/types/sharedPropTypes';

const props = withDefaults(defineProps<HostingerButtonProps>(), {
	routeLinkTo: undefined,
	type: '',
	href: '',
	buttonType: 'contained',
	theme: 'primary',
	size: 'md',
	title: '',
	isDisabled: false,
	isLoading: false,
});

const slots = useSlots();

const componentTag = computed(() => {
	if (props.href) {
		return 'a';
	}

	if (props.routeLinkTo) {
		return 'router-link';
	}

	return 'button';
});

// @ts-ignore
const defaultSlotText = computed(() => slots.default?.()?.[0]?.text?.trim());

const handleClick = () => {
	addBreadcrumb({
		category: 'CLICK:HostingerButton',
		data: {
			title: props.title || defaultSlotText.value,
			routeLinkTo: props.routeLinkTo,
			href: props.href,
			type: props.type,
		},
	});
};
</script>

<template>
	<Component
		:is="componentTag"
		class="hostinger-button"
		:class="[
			{
				[`hostinger-button-${size}`]: size,
				[`hostinger-button-${buttonType}`]: buttonType,
				[`hostinger-button-${size}--loading`]: isLoading && !isDisabled,
				[`hostinger-button-${buttonType}--${theme}`]: theme && !isDisabled,
				[`hostinger-button-${buttonType}--disabled`]: isDisabled,
				'hostinger-button--icon-left': $slots['icon-left'],
				'hostinger-button--icon-right': $slots['icon-right']
			},
		]"
		:disabled="isDisabled || isLoading"
		:type="componentTag === 'button' ? type || 'button' : undefined"
		:to="routeLinkTo"
		:href="isDisabled ? undefined : href"
		:title="title || defaultSlotText"
		@click="handleClick"
	>
		<template v-if="isLoading">
			<div class="loader">
				<div class="loader__element" />
				<div class="loader__element" />
				<div class="loader__element" />
				<div class="loader__element" />
				<div class="loader__element" />
			</div>
		</template>
		<template v-else>
			<slot name="icon-left" />
			<slot name="icon" />
			<div
				v-if="hasSlotContent($slots.default)"
				class="hostinger-button__text"
			>
				<slot />
			</div>
			<slot name="icon-right" />
		</template>
	</Component>
</template>

<style lang="scss" scoped>
$default-border-radius: 8px;

// --- Common for all themes ----------------------------------------------------------------
.hostinger-button {
	$this: &;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-family: inherit;
	text-decoration: none;
	cursor: pointer;
	border: 1px solid transparent;
	border-radius: var(--z-border-radius, $default-border-radius);
	transition: all 0.2s $transition-timing-easing-standard;
	transition-property:
		border-color,
		background-color,
		color;

	:deep(svg) {
		flex-shrink: 0;
		transition: inherit;
		transition-property: fill;
	}

	// Space between text and icon
	&__text {
		display: flex;
		align-items: center;
		margin: 0 8px;
		white-space: nowrap;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&--icon-right {
		justify-content: right;
		text-align: right;
	}

	&--icon-left {
		justify-content: left;
		text-align: left;
	}

	&:disabled,
	&.disabled {
		pointer-events: none;
		cursor: default;
	}
}

// ---- Loader styles -------------------------------------------------------------
.loader {
	position: relative;

	&__element {
		position: absolute;
		top: 0;
		left: 0;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	}

	&__element:nth-child(1) {
		animation-delay: -0.45s;
	}

	&__element:nth-child(2) {
		animation-delay: -0.3s;
	}

	&__element:nth-child(3) {
		animation-delay: -0.15s;
	}

	&__element:nth-child(4) {
		opacity: 0.2;
		animation: none;
	}

}

// ---- Button sizes --------------------------------------------------------------
.hostinger-button-sm {
	font-size: 12px;
	min-height: 32px;
	padding: 5px 15px;
	line-height: 20px;
	font-weight: 700;
	font-style: normal;

	&--loading {
		padding: 5px 46px;
	}

	.loader {
		width: 20px;
		height: 20px;

		&__element {
			border: 4px solid;
		}
	}
}

.hostinger-button-md {
	font-size: 14px;
	min-height: 40px;
	padding: 7px 23px;
	line-height: 24px;
	font-weight: 700;
	font-style: normal;

	&--loading {
		padding: 7px 59px;
	}

	.loader {
		width: 24px;
		height: 24px;

		&__element {
			border: 4px solid;
		}
	}
}

.hostinger-button-lg {
	font-size: 14px;
	min-height: 48px;
	padding: 11px 31px;
	line-height: 24px;
	font-weight: 700;
	font-style: normal;

	&--loading {
		padding: 11px 63px;
	}

	.loader {
		width: 32px;
		height: 32px;

		&__element {
			border: 4px solid;
		}
	}
}

// ---- Button Colors ------------------------------------------------------------
// Colors: text, bg, border, hover, active
@mixin set-colors($colors-map) {
	color: map-get($colors-map, 'textColor');
	background-color: map-get($colors-map, 'backgroundColor');
	border: 1px solid  map-get($colors-map, 'borderColor');

	// States
	&:focus-visible {
		background-color:map-get($colors-map, 'backgroundColorHover');
		border: 1px solid map-get($colors-map, 'borderColorHover');

		// Default button focus outline
		outline: 2px solid $color-azure;
	}

	&:hover,
	&:active {
		// if textColorHover passed, use it, otherwise use textColor
		color: map-get($colors-map,'textColorHover');
		background-color: map-get($colors-map, 'backgroundColorHover');
		border: 1px solid map-get($colors-map, 'borderColorHover');
	}

	.loader {
		&__element {
			border-color: map-get($colors-map, 'textColor') transparent transparent transparent;
		}

		&__element:nth-child(4) {
			border-color: map-get($colors-map, 'textColor');
		}
	}
}

.hostinger-button-contained {
	$this: &;

	&--primary {
		@include set-colors((
			selector: $this,
			textColor: $color-light,
			backgroundColor: $color-primary,
			backgroundColorHover: $color-primary-dark,
			borderColor: $color-primary,
			borderColorHover: $color-primary,
		));
	}

	&--danger {
		@include set-colors((
			selector: $this,
			textColor: $color-light,
			backgroundColor: $color-danger,
			backgroundColorHover: $color-danger-dark,
			borderColor: $color-danger,
			borderColorHover: $color-danger,
		));
	}

	&--warning {
		@include set-colors((
			selector: $this,
			textColor: $color-dark,
			backgroundColor: $color-warning,
			backgroundColorHover: $color-warning-dark,
			borderColor: $color-warning,
			borderColorHover: $color-warning,
		));
	}

	&--add-block {
		margin: 0 8px;
		border-radius: 100px;
		pointer-events: all;
		cursor: pointer;

		@include set-colors((
			selector: $this,
			textColor: $color-light,
			backgroundColor:  $color-azure,
			backgroundColorHover: $color-azure-dark,
			borderColor:  $color-azure,
			borderColorHover:  $color-azure-dark,
		));
	}

	&:disabled,
	&--disabled {
		cursor: not-allowed;
		color: $color-light;
		background-color: $color-gray;
		border: 1px solid $color-gray;
	}
}

.hostinger-button-outlined {
	$this: &;

	&--primary {
		@include set-colors((
			selector: $this,
			textColor: $color-primary,
			backgroundColor: transparent,
			backgroundColorHover: $color-primary-light,
			borderColor: $color-gray-border,
			borderColorHover: $color-gray-border,
		));
	}

	&--danger {
		@include set-colors((
			selector: $this,
			textColor: $color-danger,
			backgroundColor: transparent,
			backgroundColorHover: $color-danger-light,
			borderColor: $color-gray-border,
			borderColorHover: $color-gray-border,
		));
	}

	&--warning {
		@include set-colors((
			selector: $this,
			textColor: $color-dark,
			backgroundColor: transparent,
			backgroundColorHover: $color-primary-light,
			borderColor: $color-dark,
			borderColorHover: $color-gray-border,
		));
	}

	&:disabled,
	&--disabled {
		cursor: not-allowed;
		color: $color-gray;
		background-color: transparent;
		border: 1px solid $color-gray-border;
	}
}

.hostinger-button-text {
	$this: &;

	&--primary {
		@include set-colors((
			selector: $this,
			textColor: $color-primary,
			backgroundColor: transparent,
			backgroundColorHover: $color-primary-light,
			borderColor: transparent,
			borderColorHover: transparent,
		));
	}

	&--danger {
		@include set-colors((
			selector: $this,
			textColor: $color-danger,
			backgroundColor: transparent,
			backgroundColorHover: $color-danger-light,
			borderColor: transparent,
			borderColorHover: transparent,
		));
	}

	&:disabled,
	&--disabled {
		cursor: not-allowed;
		color: $color-gray;
		background-color: transparent;
		border: 1px solid transparent;
	}
}

.hostinger-button-plain {
	padding: 8px;
	border-radius: 8px;
	min-height: 32px;

	$this: &;

	&--primary {
		@include set-colors((
			selector: $this,
			textColor: $color-gray-dark,
			backgroundColor: transparent,
			backgroundColorHover: $color-gray-light,
			borderColor: transparent,
			borderColorHover: transparent,
		));
	}

	&--highlight {
		padding: 6px 8px;

		@include set-colors((
			selector: $this,
			textColor: $color-gray-dark,
			backgroundColor: transparent,
			textColorHover: $color-primary,
			backgroundColorHover: $color-primary-light,
			borderColor: transparent,
			borderColorHover: transparent,
		));
	}

	&:disabled,
	&--disabled {
		cursor: not-allowed;
		color: $color-gray-border;
		background-color: transparent;
		border: 1px solid transparent;
	}

	&--danger {
		@include set-colors((
			selector: $this,
			textColor: $color-danger,
			backgroundColor: transparent,
			backgroundColorHover: $color-danger-light,
			borderColor: transparent,
			borderColorHover: transparent,
		));
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}
</style>
