<template>
	<div class="edit-store">
		<img
			:src="getImageSrc(modalConfig.imageName)"
			:alt="modalConfig.title"
			class="edit-store__image"
		>
		<h5 class="text-heading-1 z-font-weight-normal edit-store__title">
			{{ $t(modalConfig.titleTranslationKey) }}
		</h5>
		<div class="edit-store__text">
			{{ $t(modalConfig.textTranslationKey) }}
		</div>
		<HostingerButton
			v-qa="`${qaSelector}-btn-close`"
			size="lg"
			@click="handleButtonClick"
		>
			{{ $t(modalConfig.buttonTextTranslationKey) }}
		</HostingerButton>
	</div>
</template>

<script>
import HostingerButton from '@/components/global/HostingerButton.vue';

import { mapGetters } from 'vuex';

import { defineComponent } from 'vue';

const MANAGE_TYPE = 'manageStore';
const CHOOSE_TYPE = 'choose';

const MODAL_CONFIG = {
	[MANAGE_TYPE]: {
		titleTranslationKey: 'builder.editOnlineStoreModal.manageStore.title',
		textTranslationKey: 'builder.editOnlineStoreModal.manageStore.text',
		buttonTextTranslationKey: 'builder.editOnlineStoreModal.manageStore.btn',
		imageName: 'store-manageStore.png',
	},
	[CHOOSE_TYPE]: {
		titleTranslationKey: 'builder.editOnlineStoreModal.choose.title',
		textTranslationKey: 'builder.editOnlineStoreModal.choose.text',
		buttonTextTranslationKey: 'builder.editOnlineStoreModal.choose.btn',
		imageName: 'store-upgradeSite.png',
	},
};

export default defineComponent({

	components: {
		HostingerButton,
	},

	props: {
		qaSelector: {
			type: String,
			required: true,
		},
	},

	emits: ['button-click'],

	computed: {
		...mapGetters('subscription', ['hasActiveEcommerceSubscription']),
		modalConfig() {
			if (this.hasActiveEcommerceSubscription) {
				return MODAL_CONFIG[MANAGE_TYPE];
			}

			return MODAL_CONFIG[CHOOSE_TYPE];
		},
	},

	methods: {
		handleButtonClick() {
			this.$emit('button-click');
		},
		getImageSrc(imageName) {
			return new URL(`../../../../../assets/images/${imageName}`, import.meta.url).href;
		},
	},
});
</script>

<style lang="scss" scoped>
@import "./style";
</style>
