import { PINIA_STORES } from '@/constants/stores';
import {
	AiBuilderColorPalette,
	WebsiteType,
} from '@/types/aiBuilderTypes';
import {
	EXPERIMENT_IDS,
	getIsExperimentActive,
} from '@/utils/experiments';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAiBuilderPreviewStore = defineStore(PINIA_STORES.AI_BUILDER_PREVIEW, () => {
	const lastBrandName = ref('');
	const lastWebsiteDescription = ref('');
	const lastWebsiteType = ref<WebsiteType | undefined>();
	const lastSelectedColors = ref<AiBuilderColorPalette | undefined>();
	const aiFormVisible = ref(false);
	const currentVersionIndex = ref(0);
	const isLoadingEcommerceData = ref(false);

	const setLastAiGenerationData = ({
		brandName = '',
		websiteDescription = '',
		websiteType,
		selectedColors,
	}: {
		brandName: string;
		websiteDescription: string;
		websiteType: WebsiteType;
		selectedColors?: AiBuilderColorPalette;
	}) => {
		if (!getIsExperimentActive(EXPERIMENT_IDS.AI_BUILDER_PREVIEW_REVAMP_EXPERIMENT)) {
			return;
		}

		lastBrandName.value = brandName;
		lastWebsiteDescription.value = websiteDescription;
		lastWebsiteType.value = websiteType;
		lastSelectedColors.value = selectedColors;
	};

	const getLastAiGenerationData = () => ({
		brandName: lastBrandName.value,
		websiteDescription: lastWebsiteDescription.value,
		websiteType: lastWebsiteType.value,
		selectedColors: lastSelectedColors.value,
	});

	const setAiFormVisibility = (value: boolean) => {
		aiFormVisible.value = value;
	};

	const setCurrentVersionIndex = (value: number) => {
		currentVersionIndex.value = value;
	};

	const setIsLoadingEcommerceData = (value: boolean) => {
		isLoadingEcommerceData.value = value;
	};

	return {
		lastBrandName,
		lastWebsiteDescription,
		lastWebsiteType,
		setLastAiGenerationData,
		getLastAiGenerationData,
		setAiFormVisibility,
		aiFormVisible,
		currentVersionIndex,
		setCurrentVersionIndex,
		setIsLoadingEcommerceData,
		isLoadingEcommerceData,
	};
});
