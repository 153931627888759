<script lang="ts" setup>
import currencyService from '@/services/currencyService';
import { EstimatePriceItem } from '@/types/billingTypes';
import { computed } from 'vue';

interface Props {
	title: string;
	item: EstimatePriceItem;
	currency: string
}

const props = defineProps<Props>();

const discount = computed(() => {
	const percentage = (props.item.total_discount / props.item.total) * 100;

	return Math.round(percentage);
});

</script>

<template>
	<div class="purchase-item">
		<div class="purchase-item__title">
			<p class="purchase-item__title-text text-body-2">
				{{ title }}
			</p>

			<p
				v-if="discount"
				class="purchase-item__discount text-bold-3"
			>
				{{ $t('common.discount', { discount }) }}
			</p>
		</div>

		<div class="purchase-item__price">
			<!-- Maybe will be used in the future, when estimate will be displayed separately from price item -->
			<p
				v-if="false"
				class="purchase-item__price-old text-body-3"
			>
				{{ currencyService.format(currency, item.total + item.total_discount) }}
			</p>
			<p class="purchase-item__price-new text-bold-1">
				{{ currencyService.format(currency, item.total) }}
			</p>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.purchase-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
	border-radius: 4px;
	border: 1px solid var(--color-gray-border);

	&__title {
		display: flex;
		align-items: center;
		gap: 16px;
	}

	&__price {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	&__title-text {
		color: var(--color-dark);
	}

	&__discount {
		border-radius: 4px;
		background: var(--color-primary-light);
		color: var(--color-primary);
		padding: 4px 8px;
		text-transform: uppercase;
	}

	&__price-new {
		padding: 4px;
		border-radius: 4px;
		background: var(--color-gray-light);
	}

	&__price-old {
		color: var(--color-gray);
		text-decoration: line-through;
	}

	@media screen and (max-width: 460px) {
		&__title {
			flex-direction: column;
			align-items: flex-start;
			gap: 8px;
		}

		&__price {
			flex-direction: column;
			gap: 8px;
		}
	}
}
</style>
