<script setup lang="ts">
import ZyroFieldRange from '@/components/global/ZyroFieldRange.vue';
import ZyroIconSelect from '@/components/global/ZyroIconSelect.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroRange from '@/components/global/ZyroRange.vue';
import { ImageRatioOption } from '@zyro-inc/site-modules/types';
import ZyroToggle from '@/components/global/ZyroToggle.vue';
import {
	ELEMENT_POSITION_KEY_MOBILE,
	ELEMENT_POSITION_KEY_DESKTOP,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { useProductImageRatio } from '@/use/useProductImageRatio';

import cloneDeep from 'lodash.clonedeep';
import {
	ref,
	computed,
	onMounted,
	onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import EditBlockPadding from '@/components/builder-controls/reusable-controls/block/EditBlockPadding.vue';

import {
	SPACING_BETWEEN_COLUMNS_MIN,
	SPACING_BETWEEN_COLUMNS_MAX,
} from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import { useEcommerceStore } from '@/stores/ecommerceStore';

const DEFAULT_COLUMN_COUNT_DESKTOP = 3;
const DEFAULT_COLUMN_COUNT_MOBILE = 1;

const {
	state,
	getters,
	dispatch,
} = useStore();
const { t } = useI18n();
const ecommerceStore = useEcommerceStore();
const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
});
const {
	IMAGE_RATIO_OPTIONS,
	updateImageRatio,
	imageRatio,
} = useProductImageRatio({
	blockId: props.blockId,
});

const initialCurrentBlockData = ref(null);
const siteBlocks = computed(() => getters.siteBlocks);
const block = computed(() => siteBlocks.value[props.blockId]);
const textAlignmentOptions = computed(() => ({
	left: {
		name: t('common.left'),
		icon: 'text-layout-left',
		iconDimensions: '40px',
	},
	center: {
		name: t('common.center'),
		icon: 'text-layout-center',
		iconDimensions: '40px',
	},
	outlined: {
		name: t('common.outlined'),
		icon: 'text-layout-outlined',
		iconDimensions: '40px',
	},
}));
const isMobileView = computed(() => state.gui.isMobileView);
const viewport = computed(() => (isMobileView.value ? ELEMENT_POSITION_KEY_MOBILE : ELEMENT_POSITION_KEY_DESKTOP));
const textAlignment = computed(() => block.value.settings?.styles?.textAlign || 'left');
const borderRadiusForImage = computed(() => Number.parseFloat(block.value.settings?.styles?.imageBorderRadius || 0));
const defaultColumnCount = computed(() => (isMobileView.value ? DEFAULT_COLUMN_COUNT_MOBILE : DEFAULT_COLUMN_COUNT_DESKTOP));
const columnCountDesktop = computed(() => block.value[ELEMENT_POSITION_KEY_DESKTOP]?.columnCount || DEFAULT_COLUMN_COUNT_DESKTOP);
const columnCountMobile = computed(() => block.value[ELEMENT_POSITION_KEY_MOBILE]?.columnCount || DEFAULT_COLUMN_COUNT_MOBILE);
const columnGap = computed(() => block.value[viewport.value]?.columnGap ?? 24);
const rowGap = computed(() => block.value[viewport.value]?.rowGap ?? columnGap.value);
const isFullWidth = computed(() => block.value.isFullWidth);
const isListCentered = computed(() => block.value.isListCentered);
const isSortingEnabled = computed(() => block.value.productSorting?.enabled);
const sorting = computed(() => (isSortingEnabled.value
	? block.value.productSorting?.sortingOptions?.find(({ isEnabled }: { isEnabled: boolean }) => isEnabled)?.value || ''
	: ''));
const productCategoryId = computed(() => block.value.productCategoryId);
// TODO: add util to get product count by category id and use it everywhere
const productCount = computed(() => (
	(isSortingEnabled.value || productCategoryId)
		? ecommerceStore.getCountSorted(sorting.value, productCategoryId.value)
		: ecommerceStore.count));
const isCenterToggleShown = computed(() => productCount.value === 1);

const updateTextAlignment = (newValue: string) => {
	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			settings: {
				styles: {
					textAlign: newValue,
				},
			},
		},
		merge: true,
	});
};

const updateBorderRadiusForImage = (newValue: number) => {
	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			settings: {
				styles: {
					imageBorderRadius: `${newValue || 0}%`,
				},
			},
		},
		merge: true,
	});
};

const updateColumnCount = (newValue: string) => {
	// OR needed for input field to display correct value
	let newColumnCount = Number.parseInt(newValue || defaultColumnCount.value.toString(), 10);

	if (!isMobileView.value) {
		if (newColumnCount > 6) {
			newColumnCount = 6;
		} else if (newColumnCount < 2) {
			newColumnCount = 2;
		}
	}

	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			[viewport.value]: {
				columnCount: newColumnCount,
			},
		},
		merge: true,
	});
};

const updateColumnSpacing = (newValue: number) => {
	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			[viewport.value]: {
				columnGap: newValue,
				rowGap: rowGap.value,
			},
		},
		merge: true,
	});
};

const updateRowSpacing = (newValue: number) => {
	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			[viewport.value]: {
				rowGap: newValue,
			},
		},
		merge: true,
	});
};

const setIsFullWidth = (value: boolean) => {
	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			isFullWidth: value,
		},
		merge: true,
	});
};

const setIsListCentered = (value: boolean) => {
	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			isListCentered: value,
		},
		merge: true,
	});
};

onMounted(() => {
	initialCurrentBlockData.value = cloneDeep(block.value);
});

onUnmounted(() => {
	dispatch('undoRedo/createSnapshot');
});
</script>

<template>
	<div class="ecommerce-tab-layout">
		<div
			v-if="!isMobileView"
			class="ecommerce-tab-layout__toggle"
		>
			<ZyroLabel class="ecommerce-tab-layout__label">
				{{ $t('builder.stretchToFullWidth') }}
			</ZyroLabel>
			<ZyroToggle
				id="full-width"
				:model-value="isFullWidth"
				@update:model-value="setIsFullWidth"
			/>
		</div>
		<div
			v-if="isCenterToggleShown"
			class="ecommerce-tab-layout__toggle"
		>
			<ZyroLabel class="ecommerce-tab-layout__label">
				{{ $t('builder.centerProductList') }}
			</ZyroLabel>
			<ZyroToggle
				id="center-products"
				:model-value="isListCentered"
				@update:model-value="setIsListCentered"
			/>
		</div>
		<ZyroLabel class="ecommerce-tab-layout__label">
			{{ $t('builder.ecommerceProductListProductDisplay') }}
		</ZyroLabel>
		<ZyroIconSelect
			:model-value="textAlignment"
			:options="textAlignmentOptions"
			:items-per-row="3"
			options-grid-gap="8px"
			icon-container-padding="12px 15px"
			class="ecommerce-tab-layout__icon-select"
			group-name="text-alignment"
			active-icon-color="var(--color-azure)"
			active-background-color="var(--color-azure-lighter)"
			@update:model-value="updateTextAlignment"
		/>
		<div class="ecommerce-tab-layout__controls">
			<ZyroLabel class="ecommerce-tab-layout__label">
				{{ $t('builder.zyroEcommerceImageRatio') }}
			</ZyroLabel>
			<ZyroIconSelect
				:model-value="imageRatio"
				:options="IMAGE_RATIO_OPTIONS"
				:items-per-row="3"
				options-grid-gap="8px"
				icon-container-padding="19px 20px"
				class="ecommerce-tab-layout__icon-select"
				group-name="text-alignment"
				active-icon-color="var(--color-azure)"
				active-background-color="var(--color-azure-lighter)"
				@update:model-value="updateImageRatio($event)"
			/>
		</div>
		<div
			v-if="imageRatio !== ImageRatioOption.CONTAIN"
			class="ecommerce-tab-layout__controls"
		>
			<ZyroLabel class="ecommerce-tab-layout__label">
				{{ $t('builder.imageCornerRadius') }}
			</ZyroLabel>
			<ZyroRange
				:model-value="borderRadiusForImage"
				min="0"
				units="%"
				has-number-input
				step="1"
				max="50"
				class="border-radius-range"
				@update:model-value="updateBorderRadiusForImage($event)"
			/>
		</div>
		<ZyroFieldRange
			v-if="isMobileView"
			:model-value="columnCountMobile"
			:min="1"
			:max="2"
			:label="$t('common.columns')"
			units=""
			has-number-input
			class="ecommerce-tab-layout__controls"
			@update:model-value="updateColumnCount"
		/>
		<ZyroFieldRange
			v-else
			:model-value="columnCountDesktop"
			:min="2"
			:max="6"
			:label="$t('common.columns')"
			units=""
			has-number-input
			class="ecommerce-tab-layout__controls"
			@update:model-value="updateColumnCount"
		/>
		<ZyroFieldRange
			:model-value="columnGap"
			:min="SPACING_BETWEEN_COLUMNS_MIN"
			:max="SPACING_BETWEEN_COLUMNS_MAX"
			:label="$t('builder.columnSpacing')"
			units="px"
			has-number-input
			class="ecommerce-tab-layout__controls"
			@update:model-value="updateColumnSpacing"
		/>
		<ZyroFieldRange
			:model-value="rowGap"
			:min="SPACING_BETWEEN_COLUMNS_MIN"
			:max="SPACING_BETWEEN_COLUMNS_MAX"
			:label="$t('builder.rowSpacing')"
			units="px"
			has-number-input
			class="ecommerce-tab-layout__controls"
			@update:model-value="updateRowSpacing"
		/>

		<EditBlockPadding />
	</div>
</template>

<style lang="scss" scoped>
.ecommerce-tab-layout {
	--select-background: #{$color-light};

	&__icon-select {
		color: $color-gray;
	}

	&__toggle {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;
	}

	&__controls {
		padding: 0;
		margin: 24px 0 12px;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__label {
		margin-bottom: 8px;
	}
}

:deep() {
	.border-radius-range .zyro-range__input-wrapper::after {
		margin-left: 5px;
		content: "%";
	}
}
</style>
