<template>
	<div class="title">
		<h1 class="text-heading-1">
			<slot />
		</h1>
	</div>
</template>

<style lang="scss" scoped>
.title {
	padding-bottom: 10px;
	margin: 0 0 56px;
	border-bottom: 1px solid $color-gray;

	@media screen and (max-width: $media-mobile) {
		padding-bottom: 0;
		margin: 0 0 24px;
	}
}
</style>
