<template>
	<Teleport :to="portalSelector">
		<div
			:id="popupId"
			ref="popupContentRef"
			:[POPUP_DATA_ATTRIBUTE]="true"
			:style="popupStyle"
			class="popup-content"
			:class="{
				'popup-content--mobile-fixed-to-bottom': isMobileFixedToBottom,
				'popup-content--mobile-sticked-to-bottom': isMobileStickedToBottom,
				'popup-content--zIndex-controls': isControls,
				'popup-content--fixed': isFixed
			}"
			:[DATA_ATTRIBUTE_SELECTOR]="DATA_ATTRIBUTE_SELECTOR_POPUP_CONTENT"
		>
			<slot />
		</div>
	</Teleport>
	<!-- Wrapper div used for nested popup's click outside handling (onClickOutside()) -->
	<div
		class="popup-placeholder"
		:[PORTAL_DATA_ATTRIBUTE]="true"
		:data-popup-id="popupId"
	/>
</template>

<script setup>
import {
	computePosition,
	flip as floatingFlip,
	shift as floatingShift,
	offset as floatingOffset,
	size,
	autoUpdate as floatingAutoUpdate,
	limitShift,
} from '@floating-ui/dom';
import { useStore } from 'vuex';
import {
	ref,
	watch,
	computed,
	onUnmounted,
	nextTick,
	onMounted,
} from 'vue';
import { generateRandomId } from '@/utils/generateRandomId';
import { onClickOutside } from '@vueuse/core';
import {
	DATA_ATTRIBUTE_SELECTOR,
	DATA_ATTRIBUTE_SELECTOR_ASSET_MANAGER,
	DATA_ATTRIBUTE_SELECTOR_FONT_SELECT,
	DATA_ATTRIBUTE_SELECTOR_POPUP_CONTENT,
	DATA_ATTRIBUTE_SELECTOR_LANGUAGE_DELETE,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

const PORTAL_DATA_ATTRIBUTE = 'data-popup-portal';
const POPUP_DATA_ATTRIBUTE = 'data-popup-content';
const HEADER_HEIGHT_PADDING = 50;
const HEADER_HEIGHT_PADDING_IN_DYNAMIC_PRODUCT_PAGE = 85;

const props = defineProps({
	targetRef: {
		type: [
			null,
			Element,
			HTMLElement,
		],
		default: null,
	},
	portalSelector: {
		type: String,
		default: 'body',
	},
	placement: {
		type: String,
		default: 'right',
	},
	offset: {
		type: [
			Number,
			Object,
		],
		default: 8,
	},
	padding: {
		type: Number,
		default: 24,
	},
	borderRadius: {
		type: Number,
		default: null,
	},
	// shift - keep popup in view, while moving it from the edge
	shift: {
		type: Boolean,
		default: true,
	},
	// flip - keep popup in view, while moving it to other side of element
	flip: {
		type: Boolean,
		default: true,
	},
	// autoMaxHeight - prevent popup from overflowing viewport, by applying max height and adding a scroll to it
	autoMaxHeight: {
		type: Boolean,
		default: false,
	},
	// autoupdate - updates popup position on scroll/DOM changes/etc
	autoUpdate: {
		type: Boolean,
		default: false,
	},
	autoUpdateOptions: {
		type: Object,
		default: () => ({
			ancestorScroll: true,
			ancestorResize: true,
			elementResize: true,
			animationFrame: false,
			elementMove: true,
		}),
	},
	isOnlyClickInside: {
		type: Boolean,
		default: false,
	},
	onClickOutsideOptions: {
		type: Object,
		default: () => ({}),
	},
	isMobileFixedToBottom: {
		type: Boolean,
		default: false,
	},
	isMobileStickedToBottom: {
		type: Boolean,
		default: false,
	},
	isControls: {
		type: Boolean,
		default: false,
	},
	ignoreTargetPositionChange: {
		type: Boolean,
		default: false,
	},
	isFixed: {
		type: Boolean,
		default: false,
	},
});

const {
	state,
	getters,
} = useStore();

const emit = defineEmits(['click-outside']);

const popupId = `popup-${generateRandomId()}`;
const popupContentRef = ref();
const popupX = ref();
const popupY = ref();
const popupMaxHeight = ref();
const autoUpdateCleanFunc = ref();
const referenceElementPosition = ref({});
const referenceElementMutationObserver = ref(null);
const isMobileScreen = computed(() => state.gui.isMobileScreen);

const popupStyle = computed(() => ({
	transform: `translate3d(${popupX.value}px, ${popupY.value}px, 0)`,
	borderRadius: `${props.borderRadius}px`,
	...(props.autoMaxHeight && {
		overflowY: 'auto',
		maxHeight: `${popupMaxHeight.value}px`,
	}),
}));
const headerHeightPadding = computed(() => (
	getters.isCurrentPageTypeDynamicProduct ? HEADER_HEIGHT_PADDING_IN_DYNAMIC_PRODUCT_PAGE : HEADER_HEIGHT_PADDING
));

const setPopupPosition = async (referenceEl, popupEl) => {
	const {
		x,
		y,
	} = await computePosition(referenceEl, popupEl, {
		placement: props.placement,
		middleware: [
			floatingOffset(props.offset),
			...(props.flip ? [
				floatingFlip({
					padding: {
						top: headerHeightPadding.value + props.padding,
						bottom: props.padding,
						left: props.padding,
						right: props.padding,
					},
				}),
			] : []),
			...(props.shift ? [
				floatingShift({
					padding: {
						top: headerHeightPadding.value + props.padding,
						bottom: props.padding,
						left: props.padding,
						right: props.padding,
					},
					crossAxis: true,
					limiter: limitShift(),
				}),
			] : []),
			...(props.autoMaxHeight ? [
				size({
					apply({ availableHeight }) {
						popupMaxHeight.value = availableHeight;
					},
				}),
			] : []),

		],
	});

	popupX.value = x;
	popupY.value = y;
};

onMounted(() => {
	if (!props.targetRef || !props.autoUpdateOptions.elementMove || props.ignoreTargetPositionChange) {
		return;
	}

	referenceElementMutationObserver.value = new MutationObserver((mutationsList) => {
		const hasElementPositionChanged = mutationsList.some(() => {
			const currentPosition = props.targetRef.getBoundingClientRect();
			const hasTopPositionChanged = currentPosition.top !== referenceElementPosition.value.top;
			const hasBottomPositionChanged = currentPosition.bottom !== referenceElementPosition.value.bottom;
			const hasLeftPositionChanged = currentPosition.left !== referenceElementPosition.value.left;
			const hasRightPositionChanged = currentPosition.right !== referenceElementPosition.value.right;

			return hasTopPositionChanged || hasBottomPositionChanged || hasLeftPositionChanged || hasRightPositionChanged;
		});

		if (hasElementPositionChanged) {
			referenceElementPosition.value = props.targetRef.getBoundingClientRect();
		}
	});

	referenceElementMutationObserver.value.observe(props.targetRef, {
		attributeFilter: ['style'],
	});
});

watch([
	() => props.targetRef,
	referenceElementPosition,
	isMobileScreen,
], async ([targetRef]) => {
	if (!targetRef || ((props.isMobileFixedToBottom || props.isMobileStickedToBottom) && isMobileScreen.value)) {
		return;
	}

	await nextTick();

	const popupRef = document.getElementById(popupId);

	if (props.autoUpdate) {
		if (autoUpdateCleanFunc.value) {
			autoUpdateCleanFunc.value();
		}

		if (targetRef && popupRef) {
			autoUpdateCleanFunc.value = floatingAutoUpdate(targetRef, popupRef, () => {
				setPopupPosition(targetRef, popupRef);
			}, props.autoUpdateOptions);
		}
	} else {
		setPopupPosition(targetRef, popupRef);
	}
}, {
	immediate: true,
});

onUnmounted(() => {
	if (autoUpdateCleanFunc.value) {
		autoUpdateCleanFunc.value();
	}

	referenceElementMutationObserver.value?.disconnect();
});

onClickOutside(popupContentRef, (event) => {
	try {
		if (
			props.isOnlyClickInside
					|| (props.onClickOutsideOptions.detectIframe && event.target instanceof Window)
					|| !props.targetRef
		) {
			emit('click-outside', event);

			return;
		}

		// If root node is not document, very likely element on which click is registered is no longer relevent/in the DOM
		if (event.target.getRootNode() !== document
				|| event.target?.closest(`[${DATA_ATTRIBUTE_SELECTOR}=${DATA_ATTRIBUTE_SELECTOR_ASSET_MANAGER}]`)) {
			return;
		}

		if (props.targetRef.contains(event.target) || props.targetRef.isSameNode(event.target)) {
			return;
		}

		// This prevents Edit Controls from closing when choosing Font Weight through Font Select.
		if (event.target?.closest(`[${DATA_ATTRIBUTE_SELECTOR}=${DATA_ATTRIBUTE_SELECTOR_FONT_SELECT}]`)) {
			return;
		}

		// This prevents language deletion modal from closing when clicking on confirm button
		if (event.target?.closest(`[${DATA_ATTRIBUTE_SELECTOR}=${DATA_ATTRIBUTE_SELECTOR_LANGUAGE_DELETE}]`)) {
			return;
		}

		// If clicking on child popup, don't close parent popup
		// TODO: now it supports only 1 level deep nesting. With a first need, implement recursive levels support
		const childPopupPortals = popupContentRef.value.querySelectorAll(`[${PORTAL_DATA_ATTRIBUTE}]`);
		const clickedPopup = event.target.closest(`[${POPUP_DATA_ATTRIBUTE}]`);
		const isParent = clickedPopup
					&& childPopupPortals
					&& [...childPopupPortals].some((childPopupPortal) => clickedPopup.id === childPopupPortal.dataset.popupId);

		if (isParent) {
			return;
		}

		// It's probably lib bug, but sometimes event is triggered even when clicking on popup itself:
		if (event.target.closest(`#${popupId}`)) {
			return;
		}

		emit('click-outside', event);
	} catch (error) {
		emit('click-outside', event);
		console.error(error);
	}
}, props.onClickOutsideOptions);
</script>

<style lang="scss" scoped>
.popup-content {
	position: absolute;
	top: 0;
	left: 0;
	will-change: transform;
	z-index: $z-index-popup;

	&--mobile-fixed-to-bottom {
		@media screen and (max-width: $media-mobile) {
			position: fixed;
			top: unset;
			bottom: 0;
			width: 100%;
		}
	}

	&--mobile-sticked-to-bottom {
		@media screen and (max-width: $media-mobile) {
			position: sticky;
			top: unset;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100vw;
		}
	}

	&--zIndex-controls {
		z-index: $z-index-controls-edit-block-line;
	}

	&--fixed {
		position: fixed;
	}
}

.popup-placeholder {
	display: none;
}
</style>
