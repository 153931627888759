<template>
	<HostingerButton
		v-qa="'builder-header-btn-preview'"
		class="preview-button preview-button--text-only"
		button-type="plain"
		@click="openPreview"
	>
		{{ $t('common.preview') }}
	</HostingerButton>
	<HostingerButton
		v-qa="'builder-header-btn-preview-mobile'"
		class="preview-button preview-button--icon-only"
		button-type="plain"
		@click="openPreview"
	>
		<template #icon>
			<Icon name="visibility" />
		</template>
	</HostingerButton>
</template>
<script setup>
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import { PREVIEW_ROUTE } from '@/constants/routes';
import EventLogApi from '@/api/EventLogApi';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const { dispatch } = useStore();
const openPreview = () => {
	dispatch('unselectCurrentElement');

	router.push({
		name: PREVIEW_ROUTE,
	});

	EventLogApi.logEvent({
		eventName: 'website_builder.builder.previewed',
	});
};
</script>
<style lang="scss" scoped>
.preview-button {
	margin: 0 4px;

	&--text-only {
		@media screen and (max-width: $media-mobile) {
			display: none;
		}
	}

	&--icon-only {
		@media screen and (min-width: $media-mobile) {
			display: none;
		}
	}
}
</style>
