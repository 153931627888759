<template>
	<hr class="separator">
</template>

<style lang="scss" scoped>
.separator {
	width: 100%;
	height: 1px;
	background-color: $color-gray-border;
	border: 0;
}
</style>
