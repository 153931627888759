<template>
	<div :class="BUILDER_CLASS">
		<div class="builder__header">
			<BuilderHeaderPrimary />
			<BuilderHeatmapHeader />
		</div>
		<div :class="BUILDER_BOTTOM_CLASS">
			<BuilderSidebar />
			<span class="builder__content">
				<BuilderRoot />
				<PayToPublishBanner
					v-if="isP2PBannerShown"
					@close="handleP2pBannerClose"
				/>
				<ProductSurveyPopup />
				<BuilderSetupPaymentProviderNotification />
			</span>
		</div>
		<Onboarding v-if="isOnboardingVisible" />
		<ZyroModal
			v-if="$router.currentRoute.value.query.restored === 'true'"
			max-width="480px"
			max-height="214px"
			:title="$t('builder.backupSiteRestoredTitle')"
			@close-modal="$router.replace({ query: null })"
		>
			{{ $t('builder.versionHistorySiteRestoredDescription') }}
			<template #footer>
				<HostingerButton
					v-qa="'builder-versionhistory-btn-restore-complete'"
					class="version-restored-button"
					@click="$router.replace({ query: null })"
				>
					{{ $t('common.gotIt') }}
				</HostingerButton>
			</template>
		</ZyroModal>
		<EcommerceAdminModal v-if="isEcommerceAdminIframeOpen" />
		<AssetManager
			v-if="isAssetManagerVisible"
			is-asset-select-button-hidden
			@close="setAssetManagerVisibility(false)"
		/>
		<AiImageGenerationPopup
			v-if="isAiImageGeneratorOpen"
			@close="isAiImageGeneratorOpen = false"
		/>
		<Overlay />
		<DevToolbar v-if="isDevToolbarEnabled" />
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapActions,
	useStore,
} from 'vuex';
import { useStorage } from '@vueuse/core';
import {
	PREVIEW_ROUTE,
	AI_PREVIEW_ROUTE,
} from '@/constants/routes';
import {
	BUILDER_CLASS,
	BUILDER_BOTTOM_CLASS,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import {
	FLOW_AI_PREVIEW,
	MODAL_WORDPRESS_EXPORTING,
	MODAL_WORDPRESS_EXPORT_FAIL,
	QUERY_PARAM_ENABLE_DEV_TOOLBAR,
	LOCAL_STORAGE_KEY_ENABLE_DEV_TOOLBAR,
} from '@/constants/builderConstants';
import { useGamification } from '@/use/useGamification';
import PayToPublishBanner from '@/components/ui/PayToPublishBanner.vue';
import { triggerWebsiteSavedNotification } from '@/components/ai-builder/useAiTemplate';
import { isAiImageGeneratorOpen } from '@/use/useAiImageGenerator';
import AiImageGenerationPopup from '@/components/builder-controls/AiImageGenerationPopup.vue';
import BuilderHeatmapHeader from '@/components/builder-view/BuilderHeatmapHeader.vue';
import BuilderSidebar from '@/components/builder-view/sidebar/BuilderSidebar.vue';
import BuilderHeaderPrimary from '@/components/builder-view/headers/BuilderHeaderPrimary.vue';
import BuilderSetupPaymentProviderNotification from '@/components/builder-view/BuilderSetupPaymentProviderNotification.vue';
import BuilderRoot from '@/components/builder-view/views/BuilderRoot/BuilderRoot.vue';
import Onboarding from '@/components/onboarding/Onboarding.vue';
import Overlay from '@/components/reusable-components/Overlay.vue';
import AssetManager from '@/components/builder-modals/modals/AssetManager.vue';
import DevToolbar from '@/components/DevToolbar.vue';
import { useOnboarding } from '@/components/onboarding/useOnboarding';
import { useUserAuthorizationState } from '@/use/useUserAuthorizationState';
import { useNotifications } from '@/use/useNotifications';
import { REGEX_URL_START } from '@zyro-inc/site-modules/constants/regex';
import {
	mapStateGui,
	mapActionsGui,
	CLOSE_SIDEBAR,
	OPEN_MODAL,
} from '@/store/builder/gui';
import { useEcommerceAdminStore } from '@/stores/ecommerceAdminStore';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import ZyroModal from '@/components/global/ZyroModal.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import EcommerceAdminModal from '@/components/EcommerceAdminModal.vue';
import {
	defineComponent,
	onMounted,
	onUnmounted,
	computed,
	ref,
} from 'vue';
import { exportToWordPress } from '@/api/WordpressApi';
import {
	useRouter,
	useRoute,
} from 'vue-router';
import EventLogApi from '@/api/EventLogApi';
import { useAiBuilderPreview } from '@/use/useAiBuilderPreview';
import ProductSurveyPopup from '@/components/builder-view/ProductSurveyPopup.vue';

export default defineComponent({
	name: 'Builder',

	components: {
		ZyroModal,
		HostingerButton,
		BuilderRoot,
		BuilderHeaderPrimary,
		BuilderHeatmapHeader,
		BuilderSidebar,
		Onboarding,
		Overlay,
		AssetManager,
		AiImageGenerationPopup,
		DevToolbar,
		PayToPublishBanner,
		EcommerceAdminModal,
		BuilderSetupPaymentProviderNotification,
		ProductSurveyPopup,
	},

	/**
	 * Set currentPage to mostRecentBuilderPageId, if it was set. This returns to the same page after
	 * navigating elsewhere in the editor
	 * (currentPageId can get unset after preview/blog mode to other)
	 */
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			const isFromPreview = from.path.includes(PREVIEW_ROUTE) || from.path.includes(AI_PREVIEW_ROUTE);

			if (vm.mostRecentBuilderPageId && !isFromPreview) {
				vm.updateCurrentPageId(vm.mostRecentBuilderPageId);
				vm.updateMostRecentBuilderPageId(null);
			}
		});
	},

	beforeRouteLeave(to, from, next) {
		this.leaveElementEditMode();
		this.updateMostRecentBuilderPageId(this.currentPageId);
		next();
	},

	setup() {
		const {
			state,
			getters,
			dispatch,
		} = useStore();
		const {
			isOnboardingVisible,
			shouldOpenQuickStartGuideOnEnd,
		} = useOnboarding();
		const {
			connectToWebsocket,
			closeWebsocketConnection,
		} = useGamification();
		const ecommerceAdminStore = useEcommerceAdminStore();
		const { notify } = useNotifications();
		const { deleteGeneratedSiteVersionBySiteId } = useAiBuilderPreview();

		const {
			isUserPayToPublish,
			isSiteCapacityReached,
		} = useUserAuthorizationState();
		const router = useRouter();
		const route = useRoute();

		const appHeightMobileVh = ref('1vh');

		const isP2PBannerShown = computed(() => state.gui.isP2PBannerShown);
		const isQATestUser = computed(() => getters.isQATestUser);
		const isMobileScreen = computed(() => state.gui.isMobileScreen);
		const isAssetManagerVisible = computed(() => state.gui.isAssetManagerVisible);
		const setupPaymentProviderNotificationHeight = computed(() => (getters['gui/isSetupPaymentProviderNotificationVisible'] ? `${isMobileScreen.value ? 148 : 58}px` : '0px'));
		const websiteId = computed(() => state.websiteId);
		const isDevToolbarEnabledInStorage = useStorage(LOCAL_STORAGE_KEY_ENABLE_DEV_TOOLBAR);
		const isDevToolbarEnabled = computed(() => import.meta.env.DEV || isDevToolbarEnabledInStorage.value === 'true');
		const isEcommerceAdminIframeOpen = computed(() => ecommerceAdminStore.isEcommerceAdminIframeOpen);

		const setAssetManagerVisibility = (isVisible) => {
			dispatch('gui/setAssetManagerVisibility', isVisible);
		};

		const handleP2pBannerClose = () => {
			dispatch('gui/setIsP2PBannerShown', false);
		};

		const handleQueryParams = async () => {
			const {
				flow,
				...restQuery
			} = route.query;

			if (flow === FLOW_AI_PREVIEW && !isUserPayToPublish.value && !isSiteCapacityReached.value) {
				triggerWebsiteSavedNotification(dispatch);

				router.replace({
					...route,
					query: restQuery,
				});
			}

			const {
				success,
				site_url: siteUrl,
				user_login: username,
				password,
			} = restQuery;

			const shouldShowWordPressExportFailureMessage = success === 'false';
			const shouldStartWordPressExport = siteUrl && username && password;

			if (shouldShowWordPressExportFailureMessage) {
				router.replace({
					query: {
						...restQuery,
						success: undefined,
					},
				});

				dispatch(`gui/${OPEN_MODAL}`, {
					name: MODAL_WORDPRESS_EXPORT_FAIL,
				});
			}

			if (shouldStartWordPressExport) {
				const domain = siteUrl.replace(REGEX_URL_START, '');

				try {
					const res = await exportToWordPress({
						siteId: state.websiteId,
						domain,
						username,
						password,
					});

					if (res.status === 200) {
						dispatch(`gui/${OPEN_MODAL}`, {
							name: MODAL_WORDPRESS_EXPORTING,
						});
					}
				} catch (error) {
					notify({
						messageI18nKeyPath: 'builder.wordpressExportFailureMessage',
					});
				}
			}

			if (route.query[QUERY_PARAM_ENABLE_DEV_TOOLBAR] === 'true') {
				isDevToolbarEnabledInStorage.value = 'true';
			}
		};

		onMounted(() => {
			EventLogApi.logEvent({
				eventName: 'website_builder.builder.enter',
				eventProperties: {
					website_id: state.websiteId,
					screen_size: window.screen.width,
				},
			});

			if (isMobileScreen.value) {
				window.visualViewport.addEventListener('resize', () => {
					// get the viewport height and multiple it by 1% to get a value for a vh unit
					appHeightMobileVh.value = `${window.visualViewport.height * 0.01}px`;
				});
			} else if (isUserPayToPublish.value) {
				dispatch('gui/setIsP2PBannerShown', true);
			}

			handleQueryParams();
		});

		// Handle generated site version deletion
		onMounted(() => {
			deleteGeneratedSiteVersionBySiteId();
		});

		connectToWebsocket(state.websiteId);

		onUnmounted(() => {
			closeWebsocketConnection();

			if (isMobileScreen.value) {
				window.visualViewport?.removeEventListener('resize');
			}
		});

		return {
			isOnboardingVisible,
			shouldOpenQuickStartGuideOnEnd,
			isHostingerBrand,
			isQATestUser,
			websiteId,
			setAssetManagerVisibility,
			isAssetManagerVisible,
			appHeightMobileVh,
			setupPaymentProviderNotificationHeight,
			isMobileScreen,
			isAiImageGeneratorOpen,
			isDevToolbarEnabled,
			isP2PBannerShown,
			handleP2pBannerClose,
			isEcommerceAdminIframeOpen,
		};
	},

	data() {
		return {
			BUILDER_CLASS,
			BUILDER_BOTTOM_CLASS,
		};
	},

	computed: {
		...mapState([
			'currentPageId',
			'mostRecentBuilderPageId',
		]),
		...mapStateGui(['isSidebarOpen']),
		...mapGetters(['sitePages']),
	},

	created() {
		if (this.isMobileScreen && this.isSidebarOpen) {
			this.closeSidebar();
		}
	},

	methods: {
		...mapActions([
			'leaveElementEditMode',
			'updateCurrentPageId',
			'updateMostRecentBuilderPageId',
		]),
		...mapActionsGui({
			closeSidebar: CLOSE_SIDEBAR,
		}),
	},
});
</script>

<style lang="scss">
.builder {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	width: 100vw;
	height: calc(100vh - v-bind(setupPaymentProviderNotificationHeight));
	position: relative;

	@media screen and (max-width: $media-mobile) {
		height: calc((v-bind(appHeightMobileVh) * 100) - v-bind(setupPaymentProviderNotificationHeight));
	}

	&__header {
		z-index: 1;
		width: 100vw;
		position: fixed;
		top: 0;
		left: 0;
	}

	&__bottom {
		margin-top: $header-height-editor;
		z-index: 0;
		display: flex;
		width: 100%;
	}

	&__content {
		margin-left: $sidebar-width-editor;
		display: flex;
		width: 100%;

		@media screen and (max-width: $media-mobile) {
			margin-left: 0;
		}
	}
}

.version-restored-button {
  margin-left: auto;
}
</style>
