<template>
	<a
		v-qa="'article-link'"
		class="article-link text-body-2 z-link"
		target="_blank"
		rel="noopener"
		:href="href"
		@click="logEvent"
	>
		<slot />
	</a>
</template>

<script>
import EventLogApi from '@/api/EventLogApi';
import { mapState } from 'vuex';
import { REGEX_MATCH_FROM_FIRST_DASH } from '@zyro-inc/site-modules/constants/regex';
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		href: {
			type: String,
			required: true,
		},
	},

	computed: {
		...mapState('user', ['user']),
	},

	methods: {
		logEvent() {
			EventLogApi.logEvent({
				eventName: 'website_builder.help_articles.shown',
				eventProperties: {
					articleLink: this.href.match(REGEX_MATCH_FROM_FIRST_DASH)[1],
				},
			});
		},
	},
});
</script>

<style lang="scss" scoped>
.article-link {
	margin-bottom: 8px;
}
</style>
