<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('builder.editBlockNavigation.title')"
		:tabs="tabs"
		:current-tab="currentTab"
		editor-popup-width="400px"
		@update:current-tab="currentTab = $event"
		@close="$emit('close')"
	>
		<Component
			:is="currentTab.component"
			@vue:mounted="saveBlockDataBeforeEdit"
			@vue:beforeUnmount="saveBlockDataToHistory"
		/>
	</ZyroPopupCard>
</template>

<script setup>
import {
	ref,
	computed,
	watch,
	onBeforeUnmount,
} from 'vue';

import { useStore } from 'vuex';

import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';

import cloneDeep from 'lodash.clonedeep';

import EditBlockHeaderTabCart from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabCart.vue';
import EditBlockHeaderTabLayout from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabLayout.vue';
import EditBlockHeaderTabLogo from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabLogo.vue';
import EditBlockHeaderTabStyle from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabStyle.vue';
import EditBlockHeaderTabElements from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabElements.vue';
import { EDIT_BLOCK_NAVIGATION_TAB_ID_LOGO } from '@/constants/builderConstants';

import { useI18n } from 'vue-i18n';

const emit = defineEmits([
	'close',
	'update:current-tab-id',
]);

const {
	state,
	getters,
	dispatch,
} = useStore();

const defaultBlockEditTab = computed(() => state.defaultBlockEditTab);
const { t } = useI18n();

const TAB_ID_CART = 'cart';

const TABS = [
	{
		id: 'layout',
		title: t('common.layout'),
		component: EditBlockHeaderTabLayout,
	},
	{
		id: 'elements',
		title: t('builder.helpAndResourcesElements'),
		component: EditBlockHeaderTabElements,
	},
	{
		id: EDIT_BLOCK_NAVIGATION_TAB_ID_LOGO,
		title: t('common.logo'),
		component: EditBlockHeaderTabLogo,
	},
	{
		id: TAB_ID_CART,
		title: t('common.shoppingBag'),
		component: EditBlockHeaderTabCart,
	},
	{
		id: 'style',
		title: t('common.style'),
		component: EditBlockHeaderTabStyle,
	},
];

const initialTab = computed(() => (defaultBlockEditTab.value
	? TABS.find((tab) => tab.id === defaultBlockEditTab.value)
	: TABS[0]));

const currentTab = ref(initialTab.value);
const initialCurrentBlockData = ref(null);

const isLocaleWithStore = computed(() => getters['ecommerce/isLocaleWithEcommerceItems']);
const currentBlock = computed(() => getters.currentBlock);

const tabs = computed(() => (isLocaleWithStore.value
	? TABS
	: TABS.filter((tab) => tab.id !== TAB_ID_CART)));

const saveBlockDataToHistory = () => {
	dispatch('undoRedo/createSnapshot');
};

const saveBlockDataBeforeEdit = () => {
	initialCurrentBlockData.value = cloneDeep(currentBlock.value);
};

watch(currentTab, (newTab) => emit('update:current-tab-id', newTab?.id), {
	immediate: true,
});

onBeforeUnmount(() => emit('update:current-tab-id', null));
</script>
