const WEBSOCKET_PING_TIME_INTERVAL = 20000;
const WEBSOCKET_REPEAT_MESSAGE_BLOCKING_TIME = 2000;

const initiateWebsocketKeepalive = (websocket: WebSocket): void => {
	const intervalId = setInterval(() => {
		if (websocket.readyState !== 1) {
			clearInterval(intervalId);

			return;
		}

		websocket.send('keep-alive');
	}, WEBSOCKET_PING_TIME_INTERVAL);
};

export const connectWebsocket = async ({
	url,
	onMessage,
	onClose,
	enableRepeatMessageProtection = false,
}: {
	url: string;
	onMessage: (message: MessageEvent<any>) => void;
	onClose: () => void;
	enableRepeatMessageProtection?: boolean
}) => {
	const websocket = new WebSocket(url) as WebSocket & {
			recentlySentMessages: string[],
			originalSend: typeof WebSocket.prototype.send,
			originalClose: typeof WebSocket.prototype.close,
		};

	websocket.recentlySentMessages = [];
	websocket.addEventListener('message', onMessage);
	websocket.addEventListener('close', onClose);

	await new Promise<void>((resolve) => {
		const openListener = () => {
			resolve();
			websocket.removeEventListener('open', openListener);
		};

		websocket.addEventListener('open', openListener);
	});

	websocket.originalClose = websocket.close;
	websocket.close = (...args) => {
		websocket.originalClose(...args);
		websocket.removeEventListener('message', onMessage);
		websocket.removeEventListener('close', onClose);
	};

	websocket.originalSend = websocket.send;
	websocket.send = (data: string | ArrayBufferLike | Blob | ArrayBufferView) => {
		const isSendingBlocked = enableRepeatMessageProtection && typeof data === 'string' && websocket.recentlySentMessages.includes(data);

		if (isSendingBlocked) {
			return;
		}

		const dataString = data as string;

		websocket.originalSend(data);

		websocket.recentlySentMessages.push(dataString);
		window.setTimeout(() => {
			websocket.recentlySentMessages = websocket.recentlySentMessages.filter((message) => message !== dataString);
		}, WEBSOCKET_REPEAT_MESSAGE_BLOCKING_TIME);
	};

	initiateWebsocketKeepalive(websocket);

	return websocket;
};
