import axios from '@/services/http/axios';

export const HPANEL_API = `${import.meta.env.VITE_BACKEND_API_URL}/hpanel`;

export const authorizeUser = async ({ authToken }) => axios.post(`${HPANEL_API}/auth/${authToken}`);

export const getBillingSites = async () => {
	const { data } = await axios.get(`${HPANEL_API}/billing/sites`);

	return data;
};
