import { ComputedRef } from 'vue';

export enum AnimationKeys {
    None = 'none',
    Fade = 'fade',
    Slide = 'slide',
    Scale = 'scale'
}
export type UpdateAnimations = (animations: string) => void
export type AnimationType = ComputedRef<AnimationKeys | null>
