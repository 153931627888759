<template>
	<div
		v-if="info"
		class="grid-ghost"
		:class="{
			'grid-ghost--use-border': useBorder,
			'grid-ghost--hovered': isBlockHovered && !isBlockSelected,
			'grid-ghost--selected': isBlockSelected,
		}"
	>
		<div
			v-if="!isPillDisabled"
			class="grid-ghost__overlay"
			:class="{ 'grid-ghost__overlay--no-overflow': disablePillOverflow }"
		>
			<OverlayPill
				v-if="isHoveredBlockTitleShown"
				:text="info.title"
				:background-color="info.color"
			/>
			<OverlayPill
				v-if="isBlockSelected"
				:text="info.title"
				:background-color=" 'var(--color-azure'"
			>
				<ZyroSvgDeprecated name="check-mark" />
			</OverlayPill>
		</div>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import OverlayPill from '@/components/block-grid/OverlayPill.vue';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';

const props = defineProps({
	info: {
		validator: (prop) => typeof prop === 'object' || prop === null,
		required: true,
	},
	disablePillOverflow: {
		type: Boolean,
		default: false,
	},
	useBorder: {
		type: Boolean,
		default: false,
	},
	blockId: {
		type: String,
		required: true,
	},
	isBlockSelected: {
		type: Boolean,
		default: false,
	},
	isBlockHovered: {
		type: Boolean,
		default: false,
	},
	isPillDisabled: {
		type: Boolean,
		default: false,
	},
});

const overlayPosition = computed(() => {
	const {
		y1,
		x1,
		y2,
		x2,
	} = props.info;

	return `${y1}/${x1}/${y2}/${x2}`;
});

const overlayOutlineColor = computed(() => props.info.color);

const isHoveredBlockTitleShown = computed(() => !props.isBlockSelected && props.isBlockHovered);

</script>

<style lang="scss" scoped>
.grid-ghost {
	position: relative;
	z-index: $z-index-controls-grid-ghost;
	grid-area: v-bind(overlayPosition);
	pointer-events: none;

	$border: 2px solid v-bind(overlayOutlineColor);

	outline: $border;

	&--use-border {
		border: $border;
		outline: none;
	}

	&--selected {
		border: 2px solid $color-azure;
	}

	&--hovered {
		border: $border;
	}

	&__overlay {
		position: absolute;
		top: -14px;
		left: 15px;
		z-index: $z-index-controls-edit-block-line;
		display: flex;
		align-items: center;

		&--no-overflow {
			top: 4px;
		}
	}
}
</style>
