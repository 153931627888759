<template>
	<div
		key="builder"
		class="app"
		:data-portal="DATA_PORTAL_APP"
		:style="(computedStyles as StyleValue)"
	>
		<template v-if="!isAppLoaderVisible">
			<RouterView />
			<BuilderModals :data-portal="DATA_PORTAL_MODAL" />
			<BuilderToasts />
			<SpotlightSearch />
			<BuilderImpersonateToast
				v-if="!!user && user.isStaff"
				:email="!!user && user.email"
			/>
			<MobileObserver @toggle-mobile="updateIsMobileScreen" />
		</template>
		<HostingerLoader v-else />
	</div>
</template>

<script setup lang="ts">
import {
	watch,
	computed,
	StyleValue,
	onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import HostingerLoader from '@zyro-inc/site-modules/components/HostingerLoader.vue';
import MobileObserver from '@zyro-inc/site-modules/components/ui/MobileObserver.vue';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import BuilderModals from '@/components/builder-modals/BuilderModals.vue';
import BuilderImpersonateToast from '@/components/builder-toasts/BuilderImpersonateToast.vue';
import BuilderToasts from '@/components/builder-toasts/BuilderToasts.vue';
import { useEcommerceAdminStore } from '@/stores/ecommerceAdminStore';

import {
	_000_WEBHOST_REF,
	DATA_PORTAL_APP,
	DATA_PORTAL_MODAL,
} from '@/constants/builderConstants';
import SpotlightSearch from '@/components/spotlight/SpotlightSearch.vue';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import { addGtmElements } from '@/utils/injectableDomElements/addGtmElements';
import { addRobotsMeta } from '@/utils/injectableDomElements/addRobotsMeta';
import { useBuilderMode } from '@/use/useBuilderMode';
import { useUserCredits } from '@/use/useUserCredits';
import { useRoute } from 'vue-router';
import {
	TEMPLATES_ROUTE,
	AI_PREVIEW_ROUTE,
} from '@/constants/routes';
import { useUserAuthorizationState } from '@/use/useUserAuthorizationState';
import { useSiteStore } from '@/stores/siteStore';
import { useAuthStore } from '@/stores/authStore';
import { useIntercomStore } from '@/stores/intercomStore';
import { useResourcesStore } from '@/stores/resourcesStore';
import { useAiBuilderPreview } from '@/use/useAiBuilderPreview';

const resourcesStore = useResourcesStore();

(async () => {
	await resourcesStore.fetchResources();
})();

const {
	state,
	getters,
	dispatch,
} = useStore();
const { t } = useI18n();
const siteStore = useSiteStore();
const authStore = useAuthStore();
const ecommerceAdminStore = useEcommerceAdminStore();
const {
	checkCreditsOrder,
	isCreditsOrderCheckLoading,
} = useUserCredits();
const { fetchUserAuthorizationState } = useUserAuthorizationState();
const { isAiBuilderMode } = useBuilderMode();
const { getIsAiBuilderPreviewExperimentActive } = useAiBuilderPreview();
const route = useRoute();
const intercomStore = useIntercomStore();

const computedStyles = computed(() => objectToCssVariables(siteStore.site.styles));
const currentPageData = computed(() => getters.currentPage);
const user = computed(() => state.user);
const canSave = computed(() => getters['saving/canSave']);
const pageName = computed(() => currentPageData.value?.meta?.title || currentPageData.value?.name);
const isAppLoaderVisible = computed(
	() => state.isRouteLoading
	|| state.isAppLoading
	|| isCreditsOrderCheckLoading.value
	|| authStore.isAuthRedirecting,
);
const brandTitle = computed(() => (isHostingerBrand ? t('common.hostingerPageTitle') : t('common.zyroPageTitle')));

const updateIsMobileScreen = (isMobileScreen: boolean) => {
	dispatch('gui/UPDATE_IS_MOBILE_SCREEN', isMobileScreen);
};

const handleTabClose = () => {
	if (import.meta.env.DEV) {
		return;
	}

	window.addEventListener('beforeunload', async (event) => {
		if (canSave.value && !isAiBuilderMode.value) {
			event.preventDefault();

			await dispatch('saving/saveWebsite');

			// Chrome requires returnValue to be set.
			// eslint-disable-next-line no-param-reassign
			event.returnValue = '';
		}
	});
};

const handleQATestUser = () => {
	const urlParam = new URLSearchParams(window.location.search).get('endOnboarding');

	dispatch('setIsQATestUser', urlParam ? JSON.parse(urlParam) : false);
};

const addMessageEvenetListener = () => {
	if (isAiBuilderMode.value) {
		return;
	}

	window.addEventListener('message', (event) => {
		if (event.origin !== import.meta.env.VITE_ECOMMERCE_ADMIN_URL) {
			return;
		}

		ecommerceAdminStore.handleEcommerceAdminMessage(event);
	});
};

const initHotjar = () => {
	// https://help.hotjar.com/hc/en-us/articles/360033640653-Identify-API-Reference#best-practices
	// eslint-disable-next-line func-names, no-undef, prefer-rest-params, brace-style
	window.hj = window.hj || function () {
		// @ts-ignore
		// eslint-disable-next-line no-undef, prefer-rest-params
		(hj.q = hj.q || []).push(arguments);
	};
};

const initApp = () => {
	if (user.value?.isStaff) {
		return;
	}

	addGtmElements();
	addRobotsMeta();
	handleTabClose();

	// We save explicitly within AI Builder, so autosave timer is not needed there.
	setTimeout(() => {
		const isInAiPreviewWithVersions = getIsAiBuilderPreviewExperimentActive() && route.name === AI_PREVIEW_ROUTE;

		if (!isInAiPreviewWithVersions && !isAiBuilderMode.value) {
			dispatch('saving/startSavingTimer');
		}
	}, 2000);

	initHotjar();
	handleQATestUser();
};

watch(
	() => user.value,
	(newUser: { paid: boolean, isStaff: boolean, isAccessManager: boolean }) => {
		if (!newUser) return;

		const isUnpaid000WebhostUser = ([_000_WEBHOST_REF].includes(user.value.ref));

		if (isUnpaid000WebhostUser
			|| getters['user/isGoRobotsUser']
			|| newUser.isStaff
			|| newUser.isAccessManager
		) {
			return;
		}

		setTimeout(() => {
			intercomStore.setIntercomActive(true);
		}, import.meta.env.VITE_INTERCOM_DELAY);
	},
);

// Page title updates based on user's page settings values & current page user has selected for editing.
watch(() => pageName.value, (newPageName) => {
	if (route.name === TEMPLATES_ROUTE) {
		return;
	}

	document.title = newPageName ? `${newPageName} | ${brandTitle.value}` : brandTitle.value;
}, {
	immediate: true,
});

const isPageWithAuthorization = !window.location.pathname.includes(`/${TEMPLATES_ROUTE}`);

if (isPageWithAuthorization) {
	fetchUserAuthorizationState();
}

checkCreditsOrder();
initApp();

onMounted(() => {
	addMessageEvenetListener();
});
</script>

<style lang="scss" scoped>
.app {
	height: 100%;
}

.tooltip-portal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z-index-layout-tooltip-portal;
	width: 100%;
}
</style>
