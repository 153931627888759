<template>
	<BuilderSidebarActionButton
		ref="triggerButtonRef"
		v-qa="'builder-sidebar-btn-feedback'"
		:is-active="isActive"
		:title="title"
		icon="warning"
		is-icon-filled
		custom-icon-color="var(--color-warning-dark)"
		@action-click="emit('action-click')"
	/>
	<Popup
		v-if="isActive"
		is-fixed
		:target-ref="triggerButtonRef?.$el"
		:padding="isMobileScreen ? 0 : 16"
		auto-update
		@click-outside="handleClose"
	>
		<ul class="sidebar-popup">
			<li>
				<p class="text-bold-2">
					{{ $t('builder.yourWebsiteNeedsChanges') }}
				</p>
				<slot name="description" />
				<HostingerButton @click="emit('button-click')">
					{{ $t('builder.changeUrl') }}
				</HostingerButton>
			</li>
		</ul>
	</Popup>
</template>

<script setup lang="ts">
import Popup from '@/components/global/Popup.vue';
import BuilderSidebarActionButton from '@/components/builder-view/sidebar/BuilderSidebarActionButton.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useStore } from 'vuex';
import {
	computed,
	ref,
} from 'vue';

interface Props {
	title: string;
	isActive: boolean;
}

defineProps<Props>();

const emit = defineEmits<{
	toggle: []
	'close-popup': []
	'action-click': []
	'button-click': []
}>();

const { state } = useStore();

const triggerButtonRef = ref<InstanceType<typeof BuilderSidebarActionButton> | null>(null);
const isMobileScreen = computed(() => state.gui.isMobileScreen);

const handleClose = () => {
	emit('close-popup');
};

</script>

<style lang="scss" scoped>
@import "./BuilderSidebarPopup";

.sidebar-popup {
	list-style: none;
	max-width: 280px;
	padding: 16px;

	li {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

&__text {
	margin-bottom: 8px;
}
}
</style>
