<script setup lang="ts">
import HImage from '@/components/global/HImage.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroModal from '@/components/global/ZyroModal.vue';
import {
	ECOMMERCE_REDIRECT_PATHS,
	MODAL_ECOMMERCE_CONNECT_PAYMENT,
} from '@/constants/builderConstants';
import { CLOSE_MODAL } from '@/store/builder/gui';
import { useSiteStore } from '@/stores/siteStore';
import { useRedirects } from '@/use/useRedirects';
import { useSitePublish } from '@/use/useSitePublish';
import { computed } from 'vue';
import { useStore } from 'vuex';

const { dispatch } = useStore();
const { redirectToEcommerce } = useRedirects();
const { publishSite } = useSitePublish();
const siteStore = useSiteStore();

const websiteId = computed(() => siteStore.websiteId);

const handlePublishSite = () => {
	dispatch(`gui/${CLOSE_MODAL}`, {
		name: MODAL_ECOMMERCE_CONNECT_PAYMENT,
	});

	publishSite();
};

const handleGoToPayments = () => {
	redirectToEcommerce({
		siteId: websiteId.value,
		path: ECOMMERCE_REDIRECT_PATHS.PAYMENT_MANAGEMENT,
	});
};
</script>

<template>
	<ZyroModal
		max-width="440px"
		height="fit-content"
		content-padding="0"
		use-flex-for-content
		:show-close-button="false"
		class="payment-modal"
	>
		<HImage
			class="payment-modal__image"
			src="/images/ecommerce-payments.png"
		/>
		<div class="payment-modal__content">
			<div class="payment-modal__pill text-overline">
				{{ $t('builder.recommended') }}
			</div>
			<h2 class="text-heading-2">
				{{ $t('builder.ecommerceModalTitle') }}
			</h2>
			<i18n-t
				tag="p"
				class="payment-modal__text text-body-2"
				keypath="builder.ecommerceModalText"
			>
				<span class="z-font-weight-bold">{{ $t('builder.ecommerceModalSlot') }}</span>
			</i18n-t>
			<div class="payment-modal__buttons">
				<HostingerButton @click="handleGoToPayments">
					{{ $t('builder.ecommerceGoToPayments') }}
				</HostingerButton>
				<HostingerButton
					button-type="text"
					@click="handlePublishSite"
				>
					{{ $t('builder.goLiveNow') }}
				</HostingerButton>
			</div>
		</div>
	</ZyroModal>
</template>

<style lang="scss" scoped>
.payment-modal {
	display: flex;
	flex-direction: column;

	&__image {
		border-top-left-radius: 14px;
		border-top-right-radius: 14px;
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 32px;
	}

	&__pill {
		padding: 4px 8px;
		color: var(--color-primary);
		background-color: var(--color-primary-light);
		width: fit-content;
		border-radius: 8px;
	}

	&__text {
		display: inline-block;
		color: $color-gray;
	}

	&__buttons {
		flex-wrap: wrap;
		margin-top: 24px;
		display: flex;
		gap: 8px;
	}
}
</style>
