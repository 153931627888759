<template>
	<EditTextMoreItem
		:title="$t('builder.editText.backgroundColor')"
		direction="row"
	>
		<div class="input">
			<ColorPicker
				v-if="textBoxBackgroundColor"
				v-qa="'text-backgorund-color-input'"
				class="input__color-picker"
				:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
				:color="textBoxBackgroundColor"
				:is-open="isOpen"
				@click-outside="isOpen = false"
				@toggle="isOpen = !isOpen"
				@update-color="handleColorInput"
			/>
			<ZyroToggle
				id="text-backgorund-color-toggle"
				v-qa="'text-backgorund-color-toggle'"
				:model-value="!!textBoxBackgroundColor"
				@update:model-value="handleToggle"
			/>
		</div>
	</EditTextMoreItem>
</template>

<script setup>
import ColorPicker from '@/components/global/color-picker/ColorPicker.vue';
import ZyroToggle from '@/components/global/ZyroToggle.vue';
import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import EditTextMoreItem from '@/components/builder-controls/edit-text/EditTextMoreItem.vue';
import { SELECTOR_DATA_PORTAL_BUILDER_PREVIEW } from '@/constants/builderConstants';

const DEFAULT_COLOR = 'rgb(221, 221, 221)';

const {
	getters,
	dispatch,
} = useStore();
const isOpen = ref(false);
const currentElement = computed(() => getters.currentElement);
const textBoxBackgroundColor = computed(() => currentElement?.value?.backgroundColor);
const handleToggle = (isChecked) => {
	dispatch('mergeCurrentElementData', {
		elementData: {
			backgroundColor: isChecked ? DEFAULT_COLOR : null,
		},
	});
};

const handleColorInput = (value) => {
	dispatch('mergeCurrentElementData', {
		elementData: {
			backgroundColor: value,
		},
	});
};
</script>

<style lang="scss" scoped>
.input {
	display: flex;
	align-items: center;

	&__color-picker {
		margin-right: 8px;
	}
}
</style>
