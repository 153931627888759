<script setup lang="ts">
import {
	ref,
	computed,
	watch,
} from 'vue';
import { useStore } from 'vuex';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import Icon from '@/components/global/Icon.vue';

interface CheckerItem {
	id: string,
    isCompleted: boolean,
    text: {
		keyPath: string,
		value?: string,
	},
	isHidden?: boolean,
}
type Props = {
    checkerItems: Array<CheckerItem>,
};

const props = defineProps<Props>();

const HIDE_ITEM_TIMEOUT_MS = 2000;

const { state } = useStore();
const ecommerceStore = useEcommerceStore();

const hideItemTimeout = ref<ReturnType<typeof setTimeout> | null>(
	null,
);
const items = computed(() => props.checkerItems);
const currentPageId = computed(() => state.currentPageId);
const isAnimationSkipped = ref<boolean>(true);
const areAllChecksCompleted = ref<boolean>(true);

watch([
	currentPageId,
	() => ecommerceStore.currentDynamicPageProductId,
], () => {
	isAnimationSkipped.value = true;
}, {
	immediate: true,
});

watch(() => props.checkerItems, (newCheckerItems) => {
	if (areAllChecksCompleted.value || isAnimationSkipped.value) {
		areAllChecksCompleted.value = items.value.every((item) => item.isCompleted);
	}

	if (hideItemTimeout.value) {
		clearTimeout(hideItemTimeout.value);
	}

	hideItemTimeout.value = setTimeout(() => {
		areAllChecksCompleted.value = newCheckerItems.every((item) => item.isCompleted);
		isAnimationSkipped.value = false;
	}, HIDE_ITEM_TIMEOUT_MS);
}, {
	immediate: true,
});

</script>

<template>
	<Transition :name="isAnimationSkipped ? '': 'wrapper'">
		<div
			v-if="!areAllChecksCompleted"
			class="checker-list"
		>
			<ul>
				<li
					v-for="item in items"
					:key="item.text.keyPath"
					class="checker-list__item"
				>
					<div class="checker-list__icon-wrapper">
						<Icon
							class="checker-list__icon"
							is-filled
							dimensions="20px"
							:name="item.isCompleted ? 'check_circle' : 'error'"
							:class="{ 'checker-list__icon--completed': item.isCompleted }"
						/>
					</div>

					<i18n-t
						v-if="item.text.keyPath"
						class="text-body-2"
						tag="p"
						:keypath="item.text.keyPath"
					>
						<b>{{ item.text.value }}</b>
					</i18n-t>
				</li>
			</ul>
		</div>
	</Transition>
</template>

<style lang="scss" scoped>
.checker-list {
	padding: 12px 16px;
	border: 1px solid $color-gray-border;
	border-radius: 8px;

	&__item {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 4px;
		}
	}

	&__icon-wrapper {
		display: flex;
	}

	&__icon {
		color: $color-warning-dark;
		margin-right: 8px;

		&--completed {
			color: $color-success;
		}
	}

	ul {
		list-style: none;
	}
}

.wrapper-leave-active {
  transition: all 0.25s ease-out;
}

.wrapper-enter-from,
.wrapper-leave-to {
  opacity: 0;
}

</style>
