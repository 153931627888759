<template>
	<span class="beta-badge">
		{{ $t('common.beta') }}
	</span>
</template>

<style lang="scss" scoped>
.beta-badge {
    display: inline-flex;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: var(--color-gray);
    background: var(--color-gray-light);
    border-radius: 6px;
    text-transform: uppercase;
}
</style>
