<template>
	<ZyroCollapsible
		v-if="isMobileScreen"
		:is-open="isMobilePagesSelectOpen"
		class="seo-drawer__collapsible"
		@toggle="isMobilePagesSelectOpen = !isMobilePagesSelectOpen"
	>
		<template #heading>
			<div class="seo-drawer__collapsible-heading">
				<Icon
					v-if="!isOverviewSelected"
					class="seo-drawer__status-icon"
					:name="currentTabSeoStatuses[currentPageName] ? 'check_circle' : 'error'"
					dimensions="20px"
					is-filled
					:class="{ 'seo-drawer__status-icon--completed': currentTabSeoStatuses[currentPageName] }"
				/>
				<span class="seo-drawer__spanage-name text-bold-2">
					{{ isOverviewSelected ? t('builder.websiteOverview') : currentPageName }}
				</span>
			</div>
		</template>
		<template #content>
			<NavigationContent
				class="seo-drawer__navigation-container-mobile"
				:is-overview-selected="isOverviewSelected"
				@select-page="handlePageSelect"
				@overview-click="handleOverviewClick"
			/>
		</template>
	</ZyroCollapsible>

	<div
		v-else
		class="seo-drawer__navigation-container"
	>
		<NavigationContent
			:is-overview-selected="isOverviewSelected"
			@select-page="handlePageSelect"
			@overview-click="emit('overview-click')"
		/>
	</div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import Icon from '@/components/global/Icon.vue';
import ZyroCollapsible from '@/components/site-settings/components/ZyroCollapsible.vue';
import {
	ref,
	computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useSeo } from '@/use/useSeo';
import NavigationContent from '@/components/builder-drawers/drawers/partials/seoDrawer/NavigationContent.vue';

interface Props {
	isOverviewSelected: boolean;
}

defineProps<Props>();

const emit = defineEmits<{
	'overview-click': [void],
	'select-page': [string],
}>();

const {
	getters,
	state,
} = useStore();
const { currentTabSeoStatuses } = useSeo();

const { t } = useI18n();
const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isMobilePagesSelectOpen = ref(false);

const currentPageName = computed(() => getters.sitePages[state.currentPageId]?.name);

const handleOverviewClick = () => {
	if (isMobileScreen.value) {
		isMobilePagesSelectOpen.value = false;
	}

	emit('overview-click');
};

const handlePageSelect = (pageId: string) => {
	if (isMobileScreen.value) {
		isMobilePagesSelectOpen.value = false;
	}

	emit('select-page', pageId);
};
</script>
<style lang="scss" scoped>
$drawer-header-height: 65px;
$collapsible-height: 56px;

.seo-drawer {
	&__collapsible {
		margin-bottom: 0;
		border-bottom: 1px solid $color-gray-border;

		:deep(.collapsible__content) {
			overflow-y: scroll;
			height: 500px;
		}

		@media screen and (max-width: $media-mobile) {
			:deep() {
				.seo-drawer__navigation-content {
					height: calc(100vh - $header-height-editor - $drawer-header-height - $collapsible-height);
					overflow: auto;
				}
			}
		}
	}

	&__collapsible-heading {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 0 8px;
	}

	&__navigation-container {
		width: 280px;
		padding: 8px;
		flex-shrink: 0;
		border-right: 1px solid $color-gray-border;
		overflow-y: auto;

		@media screen and (max-width: $media-mobile) {
			width: 100%;
			height: auto;
		}
	}

	&__wrapper {
		display: flex;
		height: 100%;

		@media screen and (max-width: $media-mobile) {
			flex-direction: column;
		}
	}

	&__status-icon {
		flex-shrink: 0;
		color: $color-warning-dark;

		&--completed {
			color: $color-success;
		}
	}
}

</style>
