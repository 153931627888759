import { ref } from 'vue';
/**
 * Composable to track scroll amount in page.
 * * @returns {{
 *   scrollYAmount: Ref<number>
 * }} - Returns number of pixels scrolled in current page
*/

const isInitialized = ref(false);

const scrollYAmount = ref(0);

export const useScrollHandler = () => {
	const initializeScrollHandler = (targetElement: HTMLElement) => {
		if (isInitialized.value) {
			return;
		}

		targetElement.addEventListener('scroll', () => {
			scrollYAmount.value = targetElement.scrollTop;
		});
		isInitialized.value = true;
	};

	return {
		initializeScrollHandler,
		scrollYAmount,
	};
};
