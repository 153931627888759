<script setup lang="ts">
import ZyroBackground from '@/components/global/ZyroBackground.vue';
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import { BACKGROUND_IMAGE_ATTACHMENT_OPTIONS } from '@/constants/builderConstants';
import {
	BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST,
	BACKGROUND_TYPES,
	BLOCK_TYPE_ECOMMERCE_PRODUCT,
	BLOCK_TYPE_STICKY_BAR,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

import { computed } from 'vue';
import { useStore } from 'vuex';

type BackgroundAttachment = typeof BACKGROUND_IMAGE_ATTACHMENT_OPTIONS[keyof typeof BACKGROUND_IMAGE_ATTACHMENT_OPTIONS];

type Props = {
	isFixedBackgroundDisabled: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	isFixedBackgroundDisabled: false,
});

const {
	getters,
	state,
	dispatch,
} = useStore();

const currentBlockId = computed(() => state.currentBlockId);
const currentBlock = computed(() => getters.currentBlock);
const currentBackground = computed(() => {
	const defaultBackground = {
		color: 'rgb(255, 255, 255)',
		current: BACKGROUND_TYPES.COLOR,
	};

	return currentBlock.value.background || defaultBackground;
});

const backgroundAttachment = computed(() => currentBlock.value.attachment || BACKGROUND_IMAGE_ATTACHMENT_OPTIONS.unset);
const isCurrentBlockTypeStickyBar = computed(() => currentBlock.value.type === BLOCK_TYPE_STICKY_BAR);
const isImageBackground = computed(() => currentBackground.value.current === BACKGROUND_TYPES.IMAGE);
const isVideoBackground = computed(() => currentBackground.value.current === BACKGROUND_TYPES.VIDEO);
const isBackgroundFixed = computed(() => backgroundAttachment.value === BACKGROUND_IMAGE_ATTACHMENT_OPTIONS.fixed);
const isFixedBackgroundEnabled = computed(() => !props.isFixedBackgroundDisabled && (isImageBackground.value || isVideoBackground.value));
const isCurrentBlockTypeEcommerce = computed(() => [
	BLOCK_TYPE_ECOMMERCE_PRODUCT,
	BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST,
].includes(currentBlock.value.type));

const updateBackgroundAttachment = (attachment: BackgroundAttachment) => {
	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			attachment,
		},
		merge: true,
	});
};

const handleBackgroundAttachmentToggle = (isFixed: boolean) => {
	updateBackgroundAttachment(isFixed ? BACKGROUND_IMAGE_ATTACHMENT_OPTIONS.fixed : BACKGROUND_IMAGE_ATTACHMENT_OPTIONS.unset);
};
</script>

<template>
	<div>
		<slot name="top" />
		<ZyroBackground
			:is-segment-control-background-dark="isCurrentBlockTypeEcommerce"
			:is-opacity-disabled="isCurrentBlockTypeStickyBar"
		/>
		<ZyroFieldToggle
			v-if="isFixedBackgroundEnabled"
			id="backgroundAttachment"
			:label="$t('common.fixedBackground')"
			:model-value="isBackgroundFixed"
			@update:model-value="handleBackgroundAttachmentToggle"
		/>
	</div>
</template>
