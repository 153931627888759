<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroProgressBar from '@/components/reusable-components/ZyroProgressBar.vue';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import Icon from '@/components/global/Icon.vue';
import {
	computed,
	onMounted,
	onUnmounted,
	ref,
	watch,
} from 'vue';
import { useStore } from 'vuex';
import AiImageGenerationRating from '@/components/builder-controls/AiImageGenerationRating.vue';
import { useAiImageGenerator } from '@/use/useAiImageGenerator';
import EventLogApi from '@/api/EventLogApi';

const AI_IMAGE_GENERATION_INTERVAL = 100;

type Props = {
	imageCountToGenerate: number;
	isImageGenerationDisabled: boolean;
	shouldAddImageElement?: boolean;
	generatedImageSrcList: string[];
	isGeneratingImages: boolean
}

const props = withDefaults(defineProps<Props>(), {
	imageCountToGenerate: 1,
	shouldAddImageElement: false,
});

const emit = defineEmits<{
	'generate-again': [];
	'close': [];
	'back': [];
}>();

const {
	updateImage,
	aiImageGenerateGuildArticleLink,
} = useAiImageGenerator();

const { state } = useStore();
const percentage = ref(0);
const percentageTimer = ref();

const imageSrcListString = computed(() => props.generatedImageSrcList.join(';'));
const isImageSquare = computed(() => props.shouldAddImageElement || !!state.currentElementId);
const isImageFailedToGenerate = computed(() => !props.isGeneratingImages
&& props.imageCountToGenerate !== props.generatedImageSrcList.length);

const loadProgressBar = () => {
	if (percentage.value !== 0) {
		percentage.value = 0;
	}

	percentageTimer.value = setInterval(() => {
		if (percentage.value < 100) {
			percentage.value += 1;
		} else {
			clearInterval(percentageTimer.value);
		}
	}, AI_IMAGE_GENERATION_INTERVAL);

	return percentage;
};

const handleUseImage = (selectedImgSrc: string) => {
	updateImage({
		shouldAddImageElement: props.shouldAddImageElement,
		imageSrc: selectedImgSrc,
	});

	EventLogApi.logEvent({
		eventName: 'website_builder.ai_image_generator.use',
	});
};

const handleGenerateAgain = () => {
	emit('generate-again');
	loadProgressBar();
};

onMounted(() => {
	loadProgressBar();
});

onUnmounted(() => {
	if (percentageTimer.value) {
		clearInterval(percentageTimer.value);
	}
});

watch(() => props.generatedImageSrcList, (newGeneratedImageSrcList) => {
	if (!!newGeneratedImageSrcList.length && percentageTimer.value) {
		percentage.value = 100;
		clearInterval(percentageTimer.value);
	}
});
</script>

<template>
	<div class="image-generator-preview">
		<div class="image-generator-preview__image-list">
			<p class="image-generator-preview__hint text-body-3">
				{{ $t('builder.aiImageGenerationAccessibleLibrary') }}
			</p>
			<p
				v-if="isImageFailedToGenerate"
				class="image-generator-preview__hint image-generator-preview__hint--warning text-body-3"
			>
				{{ $t('builder.someImagesFailedToBeGenerated') }}
				<a
					:href="aiImageGenerateGuildArticleLink"
					target="_blank"
				>
					{{ $t('common.learnMore') }}
				</a>
			</p>
			<div
				v-for="index in imageCountToGenerate"
				:key="index"
				class="image-generator-preview__preview"
				:class="[
					{ 'image-generator-preview__preview--square': isImageSquare },
					{ 'image-generator-preview__preview--loading': isGeneratingImages },
					{ 'image-generator-preview__preview--failed': !isGeneratingImages && !generatedImageSrcList[index - 1] }
				]"
				@click="!isGeneratingImages && handleUseImage(generatedImageSrcList[index - 1])"
			>
				<ZyroLoader
					v-if="isGeneratingImages"
					color="var(--color-gray)"
				/>
				<img
					v-else-if="generatedImageSrcList[index - 1]"
					class="image-generator-preview__preview-image"
					:src="generatedImageSrcList[index - 1]"
				>
				<Icon
					v-else
					name="block"
					color="gray"
				/>
			</div>
		</div>
		<div
			v-if="isGeneratingImages"
			class="image-generator-preview__progress-bar"
		>
			<ZyroProgressBar
				:percentage="percentage"
				color="primary"
			/>
			<p class="image-generator-preview__text text-body-3">
				{{ $t('builder.aiImageGenerationProgressText') }}
			</p>
		</div>
		<div
			v-else
			class="image-generator-preview__footer"
		>
			<AiImageGenerationRating :image-src="imageSrcListString" />
			<div class="image-generator-preview__action-buttons">
				<HostingerButton
					class="image-generator-preview__create-again-button"
					:is-disabled="isImageGenerationDisabled"
					@click="handleGenerateAgain"
				>
					<template #icon>
						<Icon
							name="auto_awesome"
							is-filled
						/>
					</template>
					{{ $t('common.createAgain') }}
				</HostingerButton>
				<slot name="credit-info" />
				<HostingerButton
					button-type="outlined"
					class="image-generator-preview__back-button"
					@click="$emit('back')"
				>
					{{ $t('builder.aiImageGenerationBackToForm') }}
				</HostingerButton>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.image-generator-preview {
	display: flex;
	flex-direction: column;
	padding: 0 0 16px;
	flex-grow: 1;

	@media screen and (max-width: $media-mobile) {
		padding-top: 0;

		&__hint {
			width: 100% !important;
		}
	}

	&__image-list {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 8px;
		gap: 4px;
	}

	&__footer, &__progress-bar {
		width: calc(100% - 16px);
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: end;
	}

  &__preview {
		min-height: 90px;
		min-width: 150px;
		flex-grow: 1;
		background-color: $color-gray-light;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition: border-width 0.05s linear;

		&:not(:only-child) {
			max-height: 160px;
			max-width: 160px;
		}

		&--square {
			aspect-ratio: 1 / 1;

			&:not(:only-child) {
				max-width: 155px;
			}
		}

		&--failed {
			pointer-events: none;
		}

		&:focus,
		&:hover {
			border: 2px solid $color-primary;
		}

		&--loading {
			&:focus,
			&:hover {
				border: none;
			}
		}
  }

	&__preview-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 8px;
		border: 2px solid $color-light;

	}

	&__action-buttons{
		display: flex;
		flex-direction: column;
	}

	&__create-again-button,
	&__back-button {
		margin-bottom: 8px;
	}

	&__text {
		color: var(--color-gray);
		text-align: center;

		// Due to appearing scrollbars we cannot use 100% width of container
		// previewImageWidth * previewImageCount + gapBetweenImages
		width: calc(155px * 2 + 4px);
	}

	&__hint {
		text-align: center;
		background-color: var(--color-gray-light);
		margin-bottom: 4px;
		padding: 4px 8px;
		border-radius: 8px;
		color: var(--color-gray);

		// Due to appearing scrollbars we cannot use 100% width of container
		// previewImageWidth * previewImageCount + gapBetweenImages
		width: calc(155px * 2 + 4px);

		&--warning {
			background-color: var(--color-warning-light);
			color: var(--color-warning-dark-2);
			margin-bottom: 4px;
			margin-top: 4px;
		}
	}
}
</style>
