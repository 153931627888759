import { ref } from 'vue';

import {
	TABS,
	TabsType,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

export interface DrawerPageSettingsPopup {
	id: string;
	tab?: TabsType;
}
const activeDrawerPageSettingsPopup = ref<DrawerPageSettingsPopup | null>(null);

export const useDrawerPageSettingsPopup = () => {
	const setActiveDrawerPageSettingsPopup = ({
		pageId,
		tab = TABS.GENERAL,
	}: {
		pageId: string;
		tab?: TabsType;
	}) => {
		activeDrawerPageSettingsPopup.value = {
			id: pageId,
			tab,
		};
	};

	return {
		activeDrawerPageSettingsPopup,
		setActiveDrawerPageSettingsPopup,
	};
};
