import {
	getManualLocaleCookie,
	getGeoIPLocaleCookie,
	getHostingerLanguageCookie,
} from '@/utils/getCookies';
import { setCookie } from '@zyro-inc/site-modules/utils/cookies';

import { COOKIE_MANUAL_LOCALE } from '@/constants/builderConstants';
import { BuilderLocales } from '@/types/localeTypes';

const HOSTINGER_LANG_TO_LOCALE_MAP: Record<BuilderLocales, string> = {
	id_ID: 'id',
	de_DE: 'de',
	en_GB: 'en',
	es_AR: 'es',
	es_CO: 'es',
	es_MX: 'es',
	es_ES: 'es',
	fr_FR: 'fr',
	it_IT: 'it',
	lt_LT: 'lt',
	nl_NL: 'nl',
	pt_PT: 'pt',
	pt_BR: 'br',
	ru_RU: 'ru',
	tr_TR: 'tr',
	uk_UA: 'ua',
	zh_CN: 'zh',
	hi_IN: 'hi',
	ar_AR: 'sa',
	he_IL: 'he',
	pl_PL: 'pl',
	vi_VN: 'vi',
	th_TH: 'th',
	ro_RO: 'ro',
};

export const getLocaleCodeBasedOnHostingerLanguage = (hostingerLanguage: BuilderLocales) => HOSTINGER_LANG_TO_LOCALE_MAP[hostingerLanguage];

export function getLocaleCodeFromCookie() {
	const localeFromCookie = getLocaleCodeBasedOnHostingerLanguage(getHostingerLanguageCookie())
		|| getManualLocaleCookie()
		|| getGeoIPLocaleCookie();

	return localeFromCookie;
}

export function setLocaleCodeToCookie(localeCode: number) {
	setCookie(COOKIE_MANUAL_LOCALE, localeCode, 365, {
		cdomain: import.meta.env.VITE_COOKIE_DOMAIN,
	});
}
