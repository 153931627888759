<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import {
	computed,
	ref,
} from 'vue';

type Props = {
  customOptions: Record<string, string>;
  defaultOptions: Record<string, string>;
  selectedOption: string | undefined;
  hasAiType: boolean;
}

const emit = defineEmits<{
  'select-option': [string];
  'add-new-type': [{name: string, description: string}];
  'edit-type': [{name: string, description: string}];
  'delete-type': [string];
}>();

const props = withDefaults(defineProps<Props>(), {});

const dialogRef = ref<HTMLDialogElement | null>(null);
const newTypeName = ref('');
const newTypeDescription = ref('');
const isDropdownVisible = ref(false);
const hoveredOptionKey = ref<null | string>(null);
const isEditing = ref(false);

const hoveredOptionDescription = computed<string>(() => {
	if (!hoveredOptionKey.value) {
		return '';
	}

	return {
		...props.defaultOptions,
		...props.customOptions,
	}[hoveredOptionKey.value];
});

const handleButtonDropdownButtonClick = () => {
	isDropdownVisible.value = !isDropdownVisible.value;
};

const handleAddNewTypeButtonClick = () => {
	if (!dialogRef.value) {
		return;
	}

	dialogRef.value.showModal();
};

const closeDialog = () => {
	if (!dialogRef.value) {
		return;
	}

	newTypeName.value = '';
	newTypeDescription.value = '';
	isEditing.value = false;
	dialogRef.value.close();
};

const handleConfirmNewTypeButtonClick = () => {
	if (!newTypeName.value || !newTypeDescription.value) {
		return;
	}

	if (isEditing.value) {
		emit('edit-type', {
			name: newTypeName.value,
			description: newTypeDescription.value,
		});

		closeDialog();

		return;
	}

	emit('add-new-type', {
		name: newTypeName.value,
		description: newTypeDescription.value,
	});

	closeDialog();
};

const handleEditButtonClick = (key: string) => {
	if (!dialogRef.value) {
		return;
	}

	isEditing.value = true;
	newTypeDescription.value = props.customOptions[key];
	newTypeName.value = key;

	dialogRef.value.showModal();
};

</script>

<template>
	<div class="ai-type-dropdown">
		<button
			class="ai-type-dropdown__button"
			@click="handleButtonDropdownButtonClick"
		>
			<span>{{ props.selectedOption || 'Select type' }}</span>
			<Icon
				name="chevron_right"
				dimensions="16px"
				:direction="isDropdownVisible ? 'left' : 'right'"
			/>
		</button>
		<div
			v-if="isDropdownVisible"
			class="ai-type-dropdown__content text-body-3"
		>
			<div class="ai-type-dropdown__types">
				<div
					v-if="hasAiType"
					class="ai-type-dropdown__types-item ai-type-dropdown__types-item"
				>
					<button @click="emit('select-option', '')">
						None
					</button>
				</div>
				<p>Custom</p>
				<ul>
					<li class="ai-type-dropdown__types-item ai-type-dropdown__types-item--outline">
						<button @click="handleAddNewTypeButtonClick">
							Add custom type
						</button>
					</li>
					<li
						v-for="(_description, key) in props.customOptions"
						:key="key"
						class="ai-type-dropdown__types-item"
						@mouseover="hoveredOptionKey = key"
						@mouseleave="hoveredOptionKey = null"
					>
						<button @click="$emit('select-option', key)">
							{{ key }}
						</button>
						<button
							class="ai-type-dropdown__action-button"
							@click="handleEditButtonClick(key)"
						>
							<Icon
								name="edit"
								dimensions="16px"
							/>
						</button>
						<button
							class="ai-type-dropdown__action-button ai-type-dropdown__action-button--danger"
							@click="$emit('delete-type', key)"
						>
							<Icon
								name="delete"
								dimensions="16px"
							/>
						</button>
					</li>
				</ul>
			</div>
			<div class="ai-type-dropdown__types">
				<p>Default</p>
				<ul>
					<li
						v-for="(_description, key) in props.defaultOptions"
						:key="key"
						class="ai-type-dropdown__types-item"
						@mouseover="hoveredOptionKey = key"
						@mouseleave="hoveredOptionKey = null"
					>
						<button @click="$emit('select-option', key)">
							{{ key }}
						</button>
					</li>
				</ul>
			</div>
		</div>
		<div
			v-if="hoveredOptionKey"
			class="ai-type-tooltip text-body-3"
		>
			{{ hoveredOptionDescription }}
		</div>
	</div>
	<dialog
		ref="dialogRef"
		class="dialog-content"
		@close="closeDialog"
	>
		<form
			class="dialog-content__form"
			@submit.prevent
		>
			<h2>{{ isEditing ? 'Update Type' : 'Add Type' }}</h2>
			<label>
				<span>Name</span>
				<input
					v-model="newTypeName"
					:disabled="isEditing"
					type="text"
					required
				>
			</label>
			<label>
				<span>Description</span>
				<textarea
					v-model="newTypeDescription"
					required
				/>
				<span class="dialog-content__form-note">description will be used by AI service to generate relevant content</span>
			</label>
			<div class="dialog-content__form-buttons">
				<button
					type="button"
					class="dialog-content__form-button"
					@click="closeDialog"
				>
					Cancel
				</button>
				<button
					type="button"
					class="dialog-content__form-button"
					@click="handleConfirmNewTypeButtonClick"
				>
					{{ isEditing ? 'Update' : 'Add' }}
				</button>
			</div>
		</form>
	</dialog>
</template>

<style scoped lang="scss">
.ai-type-dropdown {
  position: relative;

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 200px;
    height: 100%;
    border: 1px solid $color-gray-border;
    border-radius: 4px;
    padding: 8px;
    color: $color-gray;
    text-align: left;
    cursor: pointer;

    &:hover {
      border-color: $color-gray-border;
      background-color: $color-gray-light;
    }
  }

  &__content {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 200px;
    max-height:300px;
    overflow: hidden;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 4px;
    padding: 8px;
    color: $color-gray;
  }

  &__types {
    margin-bottom: 8px;
    list-style: none;
  }

  &__action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 24px;
    border-radius: 4px;
    cursor: pointer;

    &--danger {
      &:hover{
        color: $color-danger !important;
      }
    }
  }

  &__types-item {
    display: flex;
    margin-bottom: 4px;
    background-color: $color-gray-light;
    border-radius: 4px;

    & button {
      width: 100%;
      border: none;
      text-align: left;
      cursor: pointer;
      padding: 4px;
      background-color: transparent;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 4px;
    }

    &--outline {
      & button {
        background-color: $color-primary-light;

        &:hover {
          background-color: transparent;
        }
      }
    }

    & button:hover {
      color: $color-primary;
      background-color: $color-primary-light;
    }
  }
}

.ai-type-tooltip {
  position: absolute;
  top: calc(100% + 4px);
  left: 100%;
  width: 200px;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  color: $color-gray;
}

.dialog-content {
  overflow: hidden;
  border: none;
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;

  &:active, &:focus {
    outline: none;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;

    & label {
      display: flex;
      flex-direction: column;

      & input, & textarea {
        font-size: 16px;
      }

      & textarea {
        font-family: var(--font-primary);
        height: 100px;
        resize: none;
      }
    }
  }

  &__form-buttons {
    display: flex;
    gap: 8px;
    margin-top: 16px;
  }

  &__form-button {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    background-color: $color-primary;
    cursor: pointer;

    &:hover {
      background-color: $color-primary-light;
    }
  }

  &__form-note {
    font-size: 12px;
    color: $color-gray;
  }
}
</style>
