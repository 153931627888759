<template>
	<div class="circular-progress-bar-wrapper">
		<ZyroLoader
			v-if="isLoading"
			:size="width"
		/>
		<div v-else>
			<div class="circular-progress-bar">
				<span class="circular-progress-bar__content">
					<slot />
				</span>
				<div class="circular-progress-bar__center-mask" />
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import { CIRCULAR_PROGRESS_BAR_SIZES } from '@/constants/builderConstants';

type CircularProgressBarSize = typeof CIRCULAR_PROGRESS_BAR_SIZES[keyof typeof CIRCULAR_PROGRESS_BAR_SIZES];

interface Props {
	percentage: number;
	isLoading: boolean;
	size?: CircularProgressBarSize
}

const props = withDefaults(defineProps<Props>(), {
	percentage: 0,
	isLoading: false,
	size: CIRCULAR_PROGRESS_BAR_SIZES.SMALL,
});

const width = computed(() => (props.size === CIRCULAR_PROGRESS_BAR_SIZES.SMALL ? '24px' : '64px'));
const borderSize = computed(() => (props.size === CIRCULAR_PROGRESS_BAR_SIZES.SMALL ? '4px' : '8px'));
const fontSize = computed(() => (props.size === CIRCULAR_PROGRESS_BAR_SIZES.SMALL ? '8px' : '16px'));
const scale = computed(() => (props.size === CIRCULAR_PROGRESS_BAR_SIZES.SMALL ? 0.7 : 0.75));
</script>

<style lang="scss" scoped>
.circular-progress-bar-wrapper {
	pointer-events: none;
}

.circular-progress-bar {
	position: relative;
	display: inline-grid;
	place-content: center;
	width: v-bind(width);
	aspect-ratio: 1;
	font-size: v-bind(fontSize);
	line-height: 24px;
	font-weight: bold;
	background-color: transparent;

	&::before {
		position: absolute;
		inset: 0;
		content: "";
		background:
			radial-gradient(farthest-side, $color-success 98%, transparent) top/v-bind(borderSize) v-bind(borderSize) no-repeat,
			conic-gradient($color-success calc(v-bind(percentage) * 1%), transparent 0);
		border-radius: 50%;
		background-color: $color-gray-light;
	}

	// adds rounded tips for the bar
	&::after {
		position: absolute;
		inset: calc(50% - v-bind(borderSize) / 2);
		content: "";
		background: $color-success;
		border-radius: 50%;
		transform: rotate(calc(v-bind(percentage) * 3.6deg)) translateY(calc(50% - v-bind(width) / 2));
	}

	&__content {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__center-mask {
		position: absolute;
		inset:0;
		content: "";
		background-color: $color-light;
		border-radius: 50%;
		transform: scale(v-bind(scale));
	}
}
</style>
