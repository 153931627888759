<template>
	<div class="zyro-label">
		<label
			:for="forProp || undefined"
			class="zyro-label__label text-bold-2"
		>
			<slot />
		</label>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		for: {
			type: String,
			default: '',
		},
		theme: {
			type: String,
			default: 'primary',
			validator: (value) => [
				'primary',
				'secondary',
			].includes(value),
		},
	},

	computed: {
		// Using `for` directly in template causes ESLint parsing error, since it confuses it with `for` loop token
		forProp() {
			return this.for;
		},
	},
});
</script>

<style lang="scss" scoped>
.zyro-label {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;

	&__label {
		display: inline-flex;
		align-items: center;
		gap: 8px;
	}
}
</style>
