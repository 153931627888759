import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

export const TICKING_PERCENTAGE_TIMOUT = 100;
export const STEP_TIMEOUT_MS = 2500;
export const STEP_PATIENCE_TIMEOUT_MS = 10000;
export const STEP_WEBSITE_READY_TIMEOUT_MS = 2000;
export const LOADER_STEP_ID_SERVICES_READY = 'SERVICES_READY';
export const LOADER_STEP_ID_WRITING_CONTENT = 'WRITING_CONTNET';
export const LOADER_STEP_ID_WRITING_CONTENT_LONGER = 'WRITING_CONTENT_LONGER';
export const LOADER_STEP_ID_SEARCHING_IMAGES = 'SEARCHING_IMAGES';
export const LOADER_STEP_ID_SEARCHING_IMAGES_LONGER = 'SEARCHING_IMAGES_LONGER';
export const LOADER_STEP_ID_PICKING_COLORS = 'PICKING_COLORS';
export const LOADER_STEP_ID_FONTS = 'FONTS';
export const LOADER_STEP_ID_LAYOUT = 'LAYOUT';
export const LOADER_STEP_ID_ADJUST_ALIGN = 'ADJUST_ALIGN';
export const LOADER_STEP_ID_MOBILE = 'MOBILE';
export const LOADER_STEP_ID_FINISHING_TOUCHES = 'FINISHING_TOUCHES';
export const LOADER_STEP_ID_FINISHING_TOUCHES_PATIENCE = 'FINISHING_TOUCHES_PATIENCE';
export const LOADER_STEP_ID_WEBSITE_READY = 'WEBSITE_READY';

export interface AiBuilderLoaderStep {
	id: string;
	icon: string;
	text: string;
	timeoutMs: number;
	percentage: number;
}

export const useAiBuilderLoader = () => {
	const { t } = useI18n();

	const aiBuilderSteps = computed<AiBuilderLoaderStep[]>(() => ([
		{
			id: LOADER_STEP_ID_SERVICES_READY,
			icon: 'smart-toy',
			text: t('builder.aiBuilderLoaderStepPrepareServices'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 5,
		},
		{
			id: LOADER_STEP_ID_WRITING_CONTENT,
			icon: 'article',
			text: t('builder.aiBuilderLoaderStepWriteUniqueContent'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 16,
		},
		{
			id: LOADER_STEP_ID_SEARCHING_IMAGES,
			icon: 'image-search',
			text: t('builder.aiBuilderLoaderStepLookForPictures'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 28,
		},
		{
			id: LOADER_STEP_ID_PICKING_COLORS,
			icon: 'palette-outlined',
			text: t('builder.aiBuilderLoaderStepPickColors'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 37,
		},
		{
			id: LOADER_STEP_ID_FONTS,
			icon: 'format-size',
			text: t('builder.aiBuilderLoaderStepFonts'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 48,
		},
		{
			id: LOADER_STEP_ID_LAYOUT,
			icon: 'gallery-thumbnail',
			text: t('builder.aiBuilderLoaderStepLayout'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 59,
		},
		{
			id: LOADER_STEP_ID_ADJUST_ALIGN,
			icon: 'format-image-right',
			text: t('builder.aiBuilderLoaderStepAdjustAlign'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 76,
		},
		{
			id: LOADER_STEP_ID_MOBILE,
			icon: 'mobile-friendly',
			text: t('builder.aiBuilderLoaderStepMobile'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 82,
		},
		{
			id: LOADER_STEP_ID_FINISHING_TOUCHES,
			icon: 'wand',
			text: t('builder.aiBuilderLoaderStepFinishingTouches'),
			timeoutMs: STEP_PATIENCE_TIMEOUT_MS,
			percentage: 96,
		},
		{
			// Show same step as previously, but with patience text
			id: LOADER_STEP_ID_FINISHING_TOUCHES_PATIENCE,
			icon: 'wand',
			text: t('builder.aiBuilderLoaderStepFinishingTouches'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 96,
		},
		{
			id: LOADER_STEP_ID_WEBSITE_READY,
			icon: 'check-circled',
			text: t('builder.aiBuilderLoaderStepWebsiteReady'),
			timeoutMs: STEP_WEBSITE_READY_TIMEOUT_MS,
			percentage: 100,
		},
	]));

	const aiPageGenerateSteps = computed<AiBuilderLoaderStep[]>(() => ([
		{
			id: LOADER_STEP_ID_LAYOUT,
			icon: 'gallery-thumbnail',
			text: t('builder.aiBuilderLoaderStepLayout'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 16,
		},
		{
			id: LOADER_STEP_ID_WRITING_CONTENT,
			icon: 'article',
			text: t('builder.aiBuilderLoaderStepWriteUniqueContent'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 37,
		},
		{
			id: LOADER_STEP_ID_SEARCHING_IMAGES,
			icon: 'image-search',
			text: t('builder.aiBuilderLoaderStepLookForPictures'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 59,
		},
		{
			id: LOADER_STEP_ID_MOBILE,
			icon: 'mobile-friendly',
			text: t('builder.aiBuilderLoaderStepMobile'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 82,
		},
		{
			id: LOADER_STEP_ID_FINISHING_TOUCHES,
			icon: 'wand',
			text: t('builder.aiBuilderLoaderStepFinishingTouches'),
			timeoutMs: STEP_PATIENCE_TIMEOUT_MS,
			percentage: 96,
		},
		{
			// Show same step as previously, but with patience text
			id: LOADER_STEP_ID_FINISHING_TOUCHES_PATIENCE,
			icon: 'wand',
			text: t('builder.aiBuilderLoaderStepFinishingTouches'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 96,
		},
		{
			id: LOADER_STEP_ID_WEBSITE_READY,
			icon: 'check-circled',
			text: t('builder.aiBuilderLoaderStepPageReady'),
			timeoutMs: STEP_WEBSITE_READY_TIMEOUT_MS,
			percentage: 100,
		},
	]));

	const aiTemplatesSteps = computed<AiBuilderLoaderStep[]>(() => ([
		{
			id: LOADER_STEP_ID_WRITING_CONTENT,
			icon: 'article',
			text: t('builder.aiBuilderLoaderStepWriteUniqueContent'),
			timeoutMs: STEP_WEBSITE_READY_TIMEOUT_MS,
			percentage: 10,
		},
		{
			id: LOADER_STEP_ID_WRITING_CONTENT_LONGER,
			icon: 'article',
			text: t('builder.aiBuilderLoaderStepWriteUniqueContent'),
			timeoutMs: STEP_PATIENCE_TIMEOUT_MS,
			percentage: 55,
		},
		{
			id: LOADER_STEP_ID_SEARCHING_IMAGES,
			icon: 'image-search',
			text: t('builder.aiBuilderLoaderStepLookForPictures'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 70,
		},
		{
			id: LOADER_STEP_ID_SEARCHING_IMAGES_LONGER,
			icon: 'image-search',
			text: t('builder.aiBuilderLoaderStepLookForPictures'),
			timeoutMs: STEP_PATIENCE_TIMEOUT_MS,
			percentage: 90,
		},
		{
			id: LOADER_STEP_ID_FINISHING_TOUCHES_PATIENCE,
			icon: 'image-search',
			text: t('builder.aiBuilderLoaderStepLookForPictures'),
			timeoutMs: STEP_TIMEOUT_MS,
			percentage: 96,
		},
		{
			id: LOADER_STEP_ID_WEBSITE_READY,
			icon: 'check-circled',
			text: t('builder.aiBuilderLoaderStepTemplateReady'),
			timeoutMs: STEP_WEBSITE_READY_TIMEOUT_MS,
			percentage: 100,
		},
	]));

	const steps = computed <Record<string, AiBuilderLoaderStep[]>>(() => ({
		aiBuilder: aiBuilderSteps.value,
		aiPageGenerator: aiPageGenerateSteps.value,
		aiTemplates: aiTemplatesSteps.value,
	}));

	return {
		steps,
	};
};
