<script setup lang="ts">
import {
	ref,
	computed,
} from 'vue';

import EditTextAlign from '@/components/builder-controls/edit-text/EditTextAlign.vue';
import EditTextColor from '@/components/builder-controls/edit-text/EditTextColor.vue';
import EditTextFont from '@/components/builder-controls/edit-text/EditTextFont.vue';
import EditTextFontStyle from '@/components/builder-controls/edit-text/EditTextFontStyle.vue';
import EditTextLink from '@/components/builder-controls/edit-text/EditTextLink.vue';
import EditTextList from '@/components/builder-controls/edit-text/EditTextList.vue';
import EditTextMore from '@/components/builder-controls/edit-text/EditTextMore.vue';
import EditTextSize from '@/components/builder-controls/edit-text/EditTextSize.vue';
import EditTextType from '@/components/builder-controls/edit-text/EditTextType.vue';
import EditTextClearFormatting from '@/components/builder-controls/edit-text/EditTextClearFormatting.vue';
import { useTextEditor } from '@/use/text-editor/useTextEditor';

const MAX_FONT_SIZE = 240;
const MAX_FONT_SIZE_NON_GRID = 72;

interface Props {
	isNonGridElement?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
	isNonGridElement: false,
});

// Text editor controls need to wait for the editor to be ready and fully loaded.
// Otherwise, it breaks on new text element addition while another editor is open.
const { editor } = useTextEditor();

const editControlsRef = ref(null);

const maxFontSize = computed(() => (props.isNonGridElement ? MAX_FONT_SIZE_NON_GRID : MAX_FONT_SIZE));
</script>

<template>
	<div
		v-if="editor"
		ref="editControlsRef"
		class="toolbar"
	>
		<div class="toolbar__group">
			<EditTextColor />
		</div>
		<div class="toolbar__group">
			<EditTextType />
		</div>
		<div class="toolbar__group">
			<EditTextFont />
		</div>
		<div class="toolbar__group">
			<EditTextSize :max-font-size="maxFontSize" />
		</div>
		<EditTextFontStyle />
		<div
			v-if="!isNonGridElement"
			class="toolbar__group"
		>
			<EditTextList />
			<EditTextLink />
		</div>
		<div
			v-if="!isNonGridElement"
			class="toolbar__group"
		>
			<EditTextAlign />
		</div>
		<div
			v-if="isNonGridElement"
			class="toolbar__group"
		>
			<EditTextLink />
		</div>
		<div class="toolbar__group">
			<EditTextClearFormatting />
		</div>
		<EditTextMore :is-non-grid-element="isNonGridElement" />
	</div>
</template>

<style lang="scss" scoped>
	.toolbar {
		display: flex;
		align-items: center;
		background-color: $color-light;
		border-radius: $border-radius-medium;
		box-shadow: $box-shadow-base;
		padding: 2px;

		&__group {
			position: relative;
			display: flex;
			align-items: center;

			&:not(:last-child)::after {
				width: 1px;
				height: 20px;
				margin: 0 4px;
				content: "";
				background-color: $color-gray-border;
			}
		}

		@media screen and (max-width: $media-mobile) {
			flex-wrap: wrap;
			border-radius: 0;
			margin: 0;
		}
	}
</style>
