import {
	ref,
	watch,
	computed,
} from 'vue';
import { useStore } from 'vuex';

const currentElementRef = ref();

export const useCurrentElementRef = (props) => {
	const { getters } = useStore();
	const elementRef = ref();
	const currentElementId = computed(() => getters.currentElementId);

	watch([currentElementId], ([newCurrentElementId]) => {
		if (!newCurrentElementId) {
			currentElementRef.value = null;
		}

		if (!props) return;

		if (newCurrentElementId === props.elementId) {
			currentElementRef.value = elementRef.value.$el;
		}
	});

	return {
		elementRef,
		currentElementRef,
	};
};
