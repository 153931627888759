<script lang="ts" setup>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { usePublish } from '@/use/usePublish';
import { useI18n } from 'vue-i18n';
import { authRedirectService } from '@/services/http/authRedirectService';
import { REDIRECT_PARAM_KEYS } from '@/constants/builderConstants';

defineEmits(['close']);

const { t } = useI18n();
const { daysLeftUntilExpiration } = usePublish();

const redirectToBuyHosting = () => {
	authRedirectService.redirectToHpanel({
		path: `${import.meta.env.VITE_HPANEL_URL}/buy-website-builder`,
		params: {
			[REDIRECT_PARAM_KEYS.SECTION]: 'buy_website_builder',
		},
	});
};
</script>

<template>
	<div class="p2p-banner">
		<div class="p2p-banner__content">
			<div class="p2p-banner__expiration">
				<Icon
					name="error"
					color="gray"
					is-filled
					dimensions="24px"
					class="p2p-banner__expiration-icon"
				/>
				<i18n-t
					tag="p"
					keypath="builder.payToPublishPopup.expiration"
					class="p2p-banner__expiration-text text-body-2"
				>
					<span class="z-font-weight-bold">
						{{ daysLeftUntilExpiration }} {{ daysLeftUntilExpiration > 1 ? t('common.days') : t('common.day') }}
					</span>
				</i18n-t>
			</div>
			<HostingerButton
				v-qa="'builder-bottombanner-btn-p2pbuyhosting'"
				size="sm"
				class="p2p-banner__expiration-button"
				@click="redirectToBuyHosting"
			>
				{{ t('common.getPlan') }}
			</HostingerButton>
		</div>
		<HostingerButton
			button-type="plain"
			:title="$t('common.close')"
			class="p2p-banner__expiration-button-close"
			@click="$emit('close')"
		>
			<template #icon>
				<Icon name="close" />
			</template>
		</HostingerButton>
	</div>
</template>

<style lang="scss" scoped>
.p2p-banner {
	position: fixed;
	bottom: 0;
	width: 100%;
	border-top: 1px solid $color-gray-border;

	&__content {
		position: relative;
		background-color: $color-light;
		align-items: center;
		justify-content: center;
		display: flex;
		padding: 12px;
	}

	&__expiration {
		display: flex;
		gap: 8px;
	}

	&__expiration-icon {
		flex-shrink: 0;
	}

	&__expiration-button {
		margin-left: 16px;
	}

	&__expiration-button-close {
		position: absolute;
		top: 8px;
		right: 64px;
	}
}
</style>
