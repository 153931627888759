<template>
	<div class="submit">
		<ZyroFieldInput
			:model-value="buttonText"
			:placeholder="$t('builder.editForm.submitButtonLabel')"
			:label="$t('builder.editForm.submitButtonLabel')"
			@update:model-value="updateButtonText"
		/>
		<ZyroLabel>
			{{ $t('builder.editForm.buttonPosition') }}
		</ZyroLabel>
		<ZyroIconControls
			:model-value="buttonAlign"
			:icons="$options.iconsAlign"
			@update:model-value="updateButtonAlign"
		/>
		<ZyroLabel class="submit__label">
			{{ $t('builder.editForm.submitActionLabel') }}
		</ZyroLabel>
		<ZyroSegmentControl
			v-if="!isCurrentPagePrivate"
			class="submit__tabs-control"
			:controls="TABS"
			:active-control="currentTab"
			@update:active-control="changeCurrentTab"
		/>
		<template v-if="currentTab.id === SUBMIT_ACTIONS.SHOW_MESSAGE">
			<ZyroTextArea
				:model-value="successMessage"
				:placeholder="$t('builder.editForm.successMessagePlaceholder')"
				@update:model-value="updateSuccessMessage"
			/>
			<p class="editor-text text-body-2">
				{{ $t('builder.editForm.successMessageExplanation') }}
			</p>
		</template>
		<template v-else>
			<ZyroLabel class="submit__label">
				{{ $t('common.page') }}
			</ZyroLabel>
			<ZyroSelect
				v-qa="'contact-form-success-page-select'"
				label-key="title"
				class="page-dropdown"
				:options="pages"
				:model-value="selectedPageToLink"
				@update:model-value="updateSelectedPageToLink"
			/>
		</template>
		<HostingerButton
			:title="$t('common.changeButtonStyles')"
			class="button-styles"
			@click="$emit('open-button-styles')"
		>
			<template #icon-left>
				<Icon
					name="edit"
					dimensions="20px"
				/>
			</template>
			{{ $t('common.changeButtonStyles') }}
		</HostingerButton>
	</div>
</template>

<script>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import ZyroIconControls from '@/components/global/ZyroIconControls.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroSegmentControl from '@/components/global/ZyroSegmentControl.vue';
import ZyroTextArea from '@/components/global/ZyroTextArea.vue';

import {
	mapState,
	mapGetters,
	mapActions,
	useStore,
} from 'vuex';

import { SUBMIT_ACTIONS } from '@zyro-inc/site-modules/components/elements/form/constants';
import { capitalizeFirstLetter } from '@zyro-inc/site-modules/utils/modifyString';

import { useBuilderStyles } from '@/components/builder-controls/useBuilderStyles';
import { useI18n } from 'vue-i18n';

import {
	defineComponent,
	computed,
} from 'vue';

const iconsAlign = [
	{
		value: 'start',
		icon: 'align',
		direction: 'left',
	},
	{
		value: 'center',
		icon: 'align-middle-h',
	},
	{
		value: 'end',
		icon: 'align',
		direction: 'right',
	},
];

export default defineComponent({
	components: {
		Icon,
		HostingerButton,
		ZyroSelect,
		ZyroFieldInput,
		ZyroIconControls,
		ZyroLabel,
		ZyroSegmentControl,
		ZyroTextArea,
	},
	emits: ['open-button-styles'],
	setup() {
		const { getters } = useStore();
		const {
			getStyleValue,
			getStyleKey,
		} = useBuilderStyles();
		const { t } = useI18n();

		const isCurrentPagePrivate = computed(() => getters.isCurrentPagePrivate);

		const TABS = [
			{
				id: SUBMIT_ACTIONS.SHOW_MESSAGE,
				title: t('builder.editForm.showMessage'),
			},
			...(isCurrentPagePrivate.value ? [] : [
				{
					id: SUBMIT_ACTIONS.LINK_TO_PAGE,
					title: t('builder.editForm.linkToPage'),
				},
			]),
		];

		return {
			getStyleValue,
			getStyleKey,
			TABS,
			SUBMIT_ACTIONS,
			isCurrentPagePrivate,
		};
	},

	iconsAlign,
	emit: ['open-button-styles'],
	data() {
		return {
			selectedPage: null,
		};
	},

	computed: {
		...mapState('gui', ['isMobileView']),
		...mapGetters([
			'currentElement',
			'currentElementSettings',
			'defaultPages',
		]),
		buttonText() {
			return this.currentElement.submitButtonData.content;
		},
		buttonAlign() {
			return this.getStyleValue('align', this.currentElement.submitButtonData.settings.styles);
		},
		successMessage() {
			if (this.currentElementSettings.successMessage) {
				return this.currentElementSettings.successMessage;
			}

			this.mergeCurrentElementData({
				elementData: {
					settings: {
						successMessage: 'Thank you!',
					},
				},
			});

			return '';
		},
		selectedPageToLink() {
			return this.currentElementSettings.submitRedirectPage || this.pages[0];
		},
		pages() {
			// TODO Needs refactoring as there must be a more elegant solution to tackle this.
			return Object.entries(this.defaultPages).map(([pageId, page]) => ({
				pageId,
				title: page.name
					|| capitalizeFirstLetter(page.slug)
					|| 'Home',
			}));
		},
		currentTab: {
			get() {
				const currentTab = this.currentElementSettings.submitAction || this.TABS[0];

				return this.TABS.find((tab) => tab.id === currentTab.id);
			},
			set(value) {
				this.mergeCurrentElementData({
					elementData: {
						settings: {
							submitAction: value,
						},
					},
				});
			},
		},
	},

	methods: {
		...mapActions(['mergeCurrentElementData']),
		changeCurrentTab(newTab) {
			if (newTab.id === SUBMIT_ACTIONS.LINK_TO_PAGE) {
				this.selectedPageToLink = this.currentElementSettings.submitRedirectPage || this.pages[0];

				if (!this.currentElementSettings.submitRedirectPage) {
					this.updateSelectedPageToLink(this.pages[0]);
				}
			}

			this.currentTab = newTab;
		},
		updateButtonText(newValue) {
			this.mergeCurrentElementData({
				elementData: {
					submitButtonData: {
						content: newValue,
					},
				},
			});
		},
		updateButtonAlign(newValue) {
			const key = this.getStyleKey('align');

			this.mergeCurrentElementData({
				elementData: {
					submitButtonData: {
						settings: {
							styles: {
								[key]: newValue,
							},
						},
					},
				},
			});
		},
		updateSuccessMessage(newValue) {
			this.mergeCurrentElementData({
				elementData: {
					settings: {
						successMessage: newValue,
					},
				},
			});
		},
		updateSelectedPageToLink(value) {
			this.mergeCurrentElementData({
				elementData: {
					settings: {
						submitRedirectPage: value,
					},
				},
			});
		},
	},
});
</script>

<style lang="scss" scoped>
.submit {
	&__tabs-control {
		margin-bottom: 18px;
	}

	&__label {
		margin-bottom: 6px;
	}
}

:deep(.submit) {
	.editor-text {
		+ .zyro-field-text {
			margin-top: 20px;
		}
	}

	.separator {
		margin: 16px auto;
	}

	.zyro-field-text + .zyro-field-toggle {
		margin-top: 32px;
	}

	.zyro-field-toggle + .editor-text {
		margin-top: 8px;
	}
}

.text {
	&--gray {
		color: $color-gray;
	}
}

.page-dropdown {
	margin-bottom: 16px;
}

.button-styles {
	display: flex;
	justify-content: center;
	margin: 8px auto 16px;
}
</style>
