<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';

interface Props {
  isSearching: boolean;
}

defineProps<Props>();

const value = defineModel<string>('modelValue');
const handleClick = () => {
	const input = document.getElementById('page-search');

	if (input) {
		input.focus();
	}
};
</script>

<template>
	<div class="page-search">
		<ZyroFieldInput
			id="page-search"
			v-model="value"
			class="page-search__input"
			:placeholder="$t('builder.seoDrawer.searchPages')"
			padding="8px"
			@click="handleClick"
			@wrapper-click="handleClick"
		>
			<template #prefix>
				<ZyroLoader
					v-if="isSearching"
					size="16px"
					weight="2px"
					class="page-search__loader"
				/>
				<Icon
					v-else
					name="search"
					dimensions="20px"
				/>
			</template>
		</ZyroFieldInput>
	</div>
</template>

<style scoped lang="scss">
.page-search {
  display: flex;
  cursor: text;
  gap: 8px;
  background-color: var(--color-gray-light);
  border-radius: 8px;
  height: 40px;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 16px;

  &__loader {
    margin: 2px;
  }

  :deep() {
    .zyro-field-text {
      margin: 0;
    }

    .zyro-field-wrapper__prefix {
      margin-right: 8px;
    }

    .zyro-input__input {
      padding: 0;
      border: none;
    }

    input::placeholder {
      font-size: 14px;
    }
  }
}
</style>
