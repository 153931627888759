<template>
	<ZyroPopupCard
		type="editor"
		:tabs="tabs"
		:current-tab="currentTab"
		:title="$t('builder.editGallery.popupTitle')"
		@update:current-tab="currentTab = $event"
		@close="$emit('close')"
	>
		<Component :is="currentTab.component" />
	</ZyroPopupCard>
</template>

<script setup>
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';

import EditGalleryMain from '@/components/builder-controls/edit-gallery/EditGalleryMain.vue';
import EditTabAnimation from '@/components/builder-controls/reusable-controls/element/EditTabAnimation.vue';

import { useI18n } from 'vue-i18n';

import { ref } from 'vue';

const { t } = useI18n();

defineEmits(['close']);

const tabs = [
	{
		title: t('common.general'),
		component: EditGalleryMain,
	},
	{
		title: t('common.animation'),
		component: EditTabAnimation,
	},
];
const currentTab = ref(tabs[0]);

</script>

<style lang="scss" scoped>
.manage-button {
	margin-right: 8px;
}
</style>
