<template>
	<div class="upload-bar">
		<p
			v-if="!isAiBuilderMode"
			class="upload-bar__drag-drop-text text-body-2"
		>
			<Icon name="upload" />
			{{ $t('builder.assetManagerTabUserDragDropBar') }}
		</p>
		<HostingerButton
			v-qa="'assetmanager-addimage'"
			@click="onAddImagesClick"
		>
			<template #icon-left>
				<Icon name="add" />
			</template>
			{{ $t('builder.assetManagerGalleryAddImages') }}
		</HostingerButton>
	</div>
</template>

<script setup>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useBuilderMode } from '@/use/useBuilderMode';

const emit = defineEmits(['open-asset-manager']);

const { isAiBuilderMode } = useBuilderMode();

const onAddImagesClick = () => {
	emit('open-asset-manager');
};
</script>

<style lang="scss" scoped>
.upload-bar {
	display: flex;
	grid-area: 1/1/2/2;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	// Avoid layout shift
	min-height: 40px;
	margin: 14px 0 22px;

	&__info {
		display: flex;
		align-items: center;
		padding-left: 60px;
	}

	&__separator {
		align-self: stretch;
		width: 1px;
		margin: 0 20px;
		background-color: $color-gray-light;
	}

	&__count {
		display: inline-block;
		min-width: 2ch;
		text-align: right;
	}

	&__drag-drop-text {
		display: flex;
		align-items: center;
		padding: 10px 20px;
		gap: 4px;

		:deep(svg) {
			margin-right: 10px;
		}
	}
}
</style>
