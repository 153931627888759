<script setup lang="ts">
import Popup from '@/components/global/Popup.vue';
import ColorPickerButton from '@/components/global/color-picker/ColorPickerButton.vue';

import {
	ref,
	onBeforeUnmount,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import {
	DEFAULT_COLOR,
	SELECTOR_DATA_PORTAL_APP,
} from '@/constants/builderConstants';
import ColorPickerSolid from '@/components/global/color-picker/ColorPickerSolid.vue';
import ColorPickerGradient from '@/components/global/color-picker/ColorPickerGradient.vue';
import {
	SiteBackgroundGradient,
	SiteBackgroundType,
} from '@hostinger/builder-schema-validator';
import { useI18n } from 'vue-i18n';

type GradientType = 'solid' | 'gradient';

interface PickerTypeAction {
	type: GradientType;
	label: string;
	action: () => void;
	isActive: boolean;
}

interface Props {
	color?: string;
	gradient?: SiteBackgroundGradient;
	current?: SiteBackgroundType;
	hasGradient?: boolean;
	targetRef?: HTMLElement | null;
	isButtonHidden?: boolean;
	isOpen: boolean;
	placement: string;
	offset?: number;
	portalSelector?: string;
	autoUpdate?: boolean;
	flip?: boolean;
	hideWebsiteColors?: boolean;
	isOpacityDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	color: DEFAULT_COLOR,
	gradient: () => ({
		isAnimated: false,
		angle: 0,
		colors: [
			{
				value: DEFAULT_COLOR,
			},
			{
				value: DEFAULT_COLOR,
			},
		],
	}),
	current: 'color',
	targetRef: null,
	placement: 'bottom-start',
	offset: 4,
	portalSelector: SELECTOR_DATA_PORTAL_APP,
	flip: true,
	showWebsiteColors: true,
	isOpacityDisabled: false,
});

const emit = defineEmits<{
	'update-color': [color: string];
	'update-gradient': [gradient: SiteBackgroundGradient];
	'click-outside': [],
	toggle: []
}>();

const { t } = useI18n();

const currentPickerType = ref<GradientType>(props.current === 'gradient' ? 'gradient' : 'solid');
const isPickerActive = (type: GradientType) => currentPickerType.value === type;

const pickerTypeActions = computed<PickerTypeAction[]>(() => ([
	{
		type: 'solid',
		label: t('builder.solid'),
		action: () => {
			currentPickerType.value = 'solid';
		},
		isActive: isPickerActive('solid'),
	},
	{
		type: 'gradient',
		label: t('builder.gradient'),
		action: () => {
			currentPickerType.value = 'gradient';
		},
		isActive: isPickerActive('gradient'),
	},
]));

const { dispatch } = useStore();

const colorPickerButtonRef = ref<HTMLElement | null>(null);

const isEyeDropperActive = ref(false);

onBeforeUnmount(() => {
	dispatch('gui/updateIsColorPickerOpen', false);
	emit('click-outside');
});
</script>

<template>
	<div>
		<div
			v-show="!isButtonHidden"
			ref="colorPickerButtonRef"
			v-qa="'builder-colorpicker-toggle-btn'"
			@click="$emit('toggle')"
		>
			<slot>
				<ColorPickerButton :color="color" />
			</slot>
		</div>

		<Popup
			v-if="isOpen"
			:target-ref="targetRef || colorPickerButtonRef"
			:placement="placement"
			:portal-selector="portalSelector"
			:offset="offset"
			:flip="flip"
			:auto-update="autoUpdate"
			@click-outside="$emit('click-outside')"
		>
			<div
				v-show="!isEyeDropperActive"
				class="color-picker"
			>
				<div
					v-if="hasGradient"
					class="color-picker-header"
				>
					<button
						v-for="button in pickerTypeActions"
						:key="button.type"
						class="color-picker-header__button"
						:class="{ 'color-picker-header__button--active': button.isActive }"
						@click="button.action"
					>
						{{ button.label }}
					</button>
				</div>

				<ColorPickerSolid
					v-if="isPickerActive('solid')"
					:color="color"
					:hide-website-colors="hideWebsiteColors"
					:is-opacity-disabled="isOpacityDisabled"
					@update-color="emit('update-color', $event)"
					@toggle-eye-dropper="isEyeDropperActive = $event"
				/>
				<ColorPickerGradient
					v-else
					:gradient="(gradient as SiteBackgroundGradient)"
					:is-opacity-disabled="isOpacityDisabled"
					@update-gradient="emit('update-gradient', $event)"
					@toggle-eye-dropper="isEyeDropperActive = $event"
				/>
			</div>
		</Popup>
	</div>
</template>

<style lang="scss" scoped>
.color-picker {
	width: 252px;
	background-color: $color-light;
	border-radius: $border-radius-small;
	box-shadow: 0 0 12px 0 rgba(29, 30, 32, 16%);
	overflow: hidden;
}

.color-picker-header {
	display: flex;
	width: 100%;
	margin-bottom: -8px;

	&__button {
		width: 50%;
		cursor: pointer;
		padding: 8px 0;
		font-size: 14px;
		font-weight: 700;
		color: var(--color-gray);
		line-height: 24px;
		border-bottom: 1px solid var(--color-gray-border);

		&--active {
			color: var(--primary);
			padding: 8px 0 7px;
			border-bottom: 2px solid var(--primary);
		}
	}
}
</style>
