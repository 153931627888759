import { getValidPagePath } from '@/utils/urlValidators';
import { getBiggestIncrementedString } from '@zyro-inc/site-modules/utils/modifyString';
import { MAX_LENGTH_PAGE_SLUG } from '@/constants/builderConstants';

// generate defined length duplicated page slug that always ends with `-copy` and a number at the end
export const generatePageSlug = ({
	initialSlug,
	existingSlugs,
	slugSuffix,
}) => {
	let baseSlug = initialSlug;
	let slug = getBiggestIncrementedString({
		stringToMatch: getValidPagePath(`${baseSlug}${slugSuffix}`).path,
		strings: existingSlugs,
	});

	while (slug.length > MAX_LENGTH_PAGE_SLUG) {
		const lengthDifference = slug.length - MAX_LENGTH_PAGE_SLUG;

		baseSlug = baseSlug.substring(0, baseSlug.length - lengthDifference);
		slug = getBiggestIncrementedString({
			stringToMatch: getValidPagePath(`${baseSlug}${slugSuffix}`).path,
			strings: existingSlugs,
		});
	}

	return slug;
};
