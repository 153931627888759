<template>
	<div class="gallery-assets-controls">
		<div class="gallery-assets-controls__left-controls">
			<p class="text-body-2">
				{{ selectedAssetsCount }}
				{{ $t('builder.assetManagerGalleryControlsItemsSelected') }}
			</p>

			<HostingerButton
				class="gallery-assets-controls__button"
				button-type="text"
				icon-left="deselect-all"
				@click="$emit('deselect-all')"
			>
				<template #icon-left>
					<Icon name="deselect" />
				</template>
				{{ $t('builder.assetManagerGalleryControlsDeselectAll') }}
			</HostingerButton>

			<HostingerButton
				class="gallery-assets-controls__button"
				button-type="text"
				@click="$emit('select-all')"
			>
				<template #icon-left>
					<Icon name="select-all" />
				</template>
				{{ $t('builder.assetManagerGalleryControlsSelectAll') }}
			</HostingerButton>
		</div>

		<HostingerButton
			class="gallery-assets-controls__button"
			@click="$emit('add-to-gallery')"
		>
			{{ $t('builder.assetManagerAddToGallery') }}
		</HostingerButton>
	</div>
</template>

<script>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		Icon,
		HostingerButton,
	},

	props: {
		selectedAssetsCount: {
			type: Number,
			required: true,
		},
	},
	emits: [
		'add-to-gallery',
		'deselect-all',
		'select-all',
	],
});
</script>

<style lang="scss" scoped>
	.gallery-assets-controls {
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 16px 24px;
		background-color: $color-light;
		border-top: 1px solid $color-gray-border;
		border-bottom-right-radius: 15px;
		border-bottom-left-radius: 15px;

		&__left-controls {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__button {
			margin-left: 40px;
		}
	}
</style>
