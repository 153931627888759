<template>
	<Teleport to="body">
		<div
			class="toasts"
			:class="{ 'toasts--hidden': !toasts.length }"
		>
			<TransitionGroup
				name="slide-left"
				tag="div"
				mode="out-in"
				appear
			>
				<Toast
					v-for="(toast, index) in toasts"
					:id="toast.id"
					:key="toast.id"
					:theme="toast.theme"
					:is-close-button-shown="toast.isCloseButtonShown"
					:heading="toast.headingI18nKeyPath ? $t(toast.headingI18nKeyPath) : null"
					:message="toast.messageI18nKeyPath ? $t(toast.messageI18nKeyPath) : toast.message"
					:is-discard-button-shown="toast.isDiscardButtonShown"
					:discard-label="toast.discardLabelI18nKeyPath ? $t(toast.discardLabelI18nKeyPath) : null"
					:submit-label="toast.submitLabelI18nKeyPath ? $t(toast.submitLabelI18nKeyPath) : null"
					:submit-button-styles="toast.submitButtonStyles"
					class="toasts__item"
					:class="{ 'toasts__item--hidden': !isFirstVisibleToast(index) }"
					@submit="handleSubmit(toast)"
					@close="handleClose(toast)"
					@mouseenter="handleMouseEnter"
					@mouseleave="handleMouseLeave"
				/>
			</TransitionGroup>
		</div>
	</Teleport>
</template>

<script setup>
import Toast from '@/components/builder-toasts/Toast.vue';

import { computed } from 'vue';
import { useStore } from 'vuex';

const {
	state,
	dispatch,
} = useStore();

const toasts = computed(() => state.notifications.toasts);

const handleClose = (toast) => {
	const { closeCallback } = toast;

	dispatch('notifications/removeAllLastToastOccurrences');

	if (closeCallback) {
		closeCallback();
	}
};

const isFirstVisibleToast = (index) => index === toasts.value.length - 1;

const handleMouseEnter = () => {
	dispatch('notifications/clearAutoClose');
};

const handleMouseLeave = () => {
	dispatch('notifications/startAutoClose');
};

const handleSubmit = (toast) => {
	const {
		closeOnSubmit,
		submitCallback,
	} = toast;

	if (submitCallback) {
		submitCallback();
	}

	if (closeOnSubmit) {
		handleClose(toast);
	}
};
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/transitions/slide";

.toasts {
	&--hidden {
		display: none;
	}

	&__item {
		position: fixed;
		right: 64px;
		bottom: 15px;
		z-index: $z-index-toasts;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding: 16px;

		@media screen and (max-width: $media-mobile) {
			right: 16px;
		}

		&--hidden {
			transition: right 0.5s ease-in-out;
			right: 0;
			visibility:hidden;
		}
	}
}
</style>
