<script setup lang="ts">
import BuilderSidebarAction from '@/components/builder-view/sidebar/BuilderSidebarAction.vue';
import BuilderSidebarActionButton from '@/components/builder-view/sidebar/BuilderSidebarActionButton.vue';
import { useSiteStore } from '@/stores/siteStore';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { INSTAGRAM_API_VERSION } from '@zyro-inc/site-modules/components/elements/instagram-feed/useGridInstagramFeed';
import {
	DATA_ATTRIBUTE_ELEMENT_ID,
	ELEMENT_TYPE_INSTAGRAM_FEED,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { SiteElementGridInstagramFeed } from '@hostinger/builder-schema-validator';

const { t } = useI18n();
const siteStore = useSiteStore();
const { dispatch } = useStore();

interface InstagramElement {
	locale: string;
	elementId: string;
	pageId: string;
}

const firstFoundOldInstagramFeed = computed<InstagramElement>(() => {
	const siteLanguages = {
		...siteStore.site.languages,
	};

	if (Object.values(siteLanguages).length > 1 && siteLanguages.system) {
		delete siteLanguages.system;
	}

	const foundElement = Object.values(siteLanguages)
		.flatMap(({
			pages,
			blocks: languageBlocks,
			elements,
			locale,
		}) => Object.entries(pages)
			.flatMap(([pageId, pageData]) => pageData.blocks
				?.flatMap((blockId) => languageBlocks[blockId].components?.map((elementId) => {
					const element = elements[elementId] as SiteElementGridInstagramFeed;
					const isOldInstagramFeed = !!(element.settings?.username
					&& element.settings?.apiVersion !== INSTAGRAM_API_VERSION.INSTAGRAM_API_WITH_INSTAGRAM_LOGIN);

					if (element.type === ELEMENT_TYPE_INSTAGRAM_FEED && isOldInstagramFeed) {
						return {
							locale,
							elementId,
							pageId,
						};
					}

					return null;
				})))).find(Boolean);

	return foundElement as InstagramElement;
});

const navigateToInstagramElement = async () => {
	await dispatch('updateCurrentLocale', firstFoundOldInstagramFeed.value.locale);
	await dispatch('updateCurrentPageId', firstFoundOldInstagramFeed.value.pageId);

	const element = document.querySelector(`[${DATA_ATTRIBUTE_ELEMENT_ID}="${firstFoundOldInstagramFeed.value.elementId}"]`);

	if (element) {
		element.scrollIntoView({
			behavior: 'smooth',
		});
	}

	dispatch('selectCurrentElement', {
		elementId: firstFoundOldInstagramFeed.value.elementId,
	});

	dispatch('enterElementEditMode');
};
</script>

<template>
	<BuilderSidebarAction
		v-if="firstFoundOldInstagramFeed?.elementId"
		:title="t('builder.reconnectInstagram')"
	>
		<template #trigger>
			<div class="instagram-notice">
				<span class="instagram-notice__bubble" />
				<BuilderSidebarActionButton
					v-qa="'builder-header-btn-reconnect-instagram'"
					:title="t('builder.reconnectInstagram')"
					custom-icon="instagram-brands"
					@action-click="navigateToInstagramElement"
				/>
			</div>
		</template>
	</BuilderSidebarAction>
</template>

<style lang="scss" scoped>
.instagram-notice {
	position: relative;

	&__bubble {
		position: absolute;
		top: 7px;
		left: 7px;
		width: 10px;
		height: 10px;
		z-index: 3;
		background-color: var(--color-danger);
		border-radius: 50%;
	}
}
</style>
