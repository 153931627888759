<script setup lang="ts">
import {
	ref,
	onMounted,
	computed,
} from 'vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import ZyroTooltip from '@/components/global/ZyroTooltip.vue';
import { useStore } from 'vuex';
import { validateSiteJSON } from '@/api/SitesApi';
import { useNotifications } from '@/use/useNotifications';
import { useSiteStore } from '@/stores/siteStore';
import { useUserStore } from '@/stores/userStore';

const { state } = useStore();
const isValidatingSiteData = ref(false);
const siteDataErrors = ref<string[]>([]);
const { notify } = useNotifications();

const siteStore = useSiteStore();
const userStore = useUserStore();

const areFeaturesLocked = computed(() => userStore.areFeaturesLocked);

const validateSiteData = async () => {
	isValidatingSiteData.value = true;

	try {
		const { errors } = await validateSiteJSON({
			siteId: state.websiteId === 'playground' ? import.meta.env.VITE_DEFAULT_SITE_ID : state.websiteId,
			siteData: siteStore.site,
		});

		siteDataErrors.value = errors;

		if (siteDataErrors.value.length) {
			// eslint-disable-next-line no-console
			console.log(siteDataErrors.value);
		}
	} catch (error) {
		notify({
			message: 'Failed to validate site data. Make sure back-end is running and you are authenticated (go to /default route, and back to /playground)',
		});
	}

	isValidatingSiteData.value = false;
};

const copySiteDataToClipboard = () => {
	navigator.clipboard.writeText(JSON.stringify(siteStore.site));

	notify({
		theme: 'success',
		message: 'Data.json copied',
	});
};

const toggleLockedFeatures = () => {
	userStore.setAreFeaturesLocked(!areFeaturesLocked.value);
};

const overwriteSiteDataFromClipboard = async () => {
	const clipboardData = await navigator.clipboard.readText();

	try {
		const parsedData = JSON.parse(clipboardData);

		siteStore.setSiteData(parsedData);

		notify({
			theme: 'success',
			message: 'Site data overwritten',
		});
	} catch (error) {
		notify({
			theme: 'error',
			message: 'Failed to overwrite site data. Make sure you have valid JSON in your clipboard',
		});
	}
};

onMounted(async () => {
	await validateSiteData();
});
</script>

<template>
	<div class="dev-toolbar">
		<ZyroTooltip
			toggle-event="hover"
			position="left"
			size="small"
		>
			<p class="text-body-3">
				Validate data.json
			</p>
			<template #trigger>
				<HostingerButton
					size="sm"
					button-type="plain"
					:disabled="isValidatingSiteData"
					:is-loading="isValidatingSiteData"
					class="dev-toolbar__button"
					@click="validateSiteData"
				>
					<template #icon>
						<Icon
							class="dev-toolbar__icon"
							:class="{ 'dev-toolbar__icon--invalid': siteDataErrors.length }"
							:name="siteDataErrors.length ? 'error' : 'check_circle'"
						/>
					</template>
				</HostingerButton>
			</template>
		</ZyroTooltip>
		<ZyroTooltip
			toggle-event="hover"
			position="left"
			size="small"
		>
			<p class="text-body-3">
				Copy data.json
			</p>
			<template #trigger>
				<HostingerButton
					size="sm"
					button-type="plain"
					class="dev-toolbar__button"
					@click="copySiteDataToClipboard"
				>
					<template #icon>
						<Icon
							class="dev-toolbar__icon"
							name="content_copy"
						/>
					</template>
				</HostingerButton>
			</template>
		</ZyroTooltip>
		<ZyroTooltip
			toggle-event="hover"
			position="left"
			size="small"
		>
			<p class="text-body-3">
				{{ `${areFeaturesLocked ? 'Unlock' : 'Lock'} features` }}
			</p>
			<template #trigger>
				<HostingerButton
					size="sm"
					button-type="plain"
					class="dev-toolbar__button"
					@click="toggleLockedFeatures"
				>
					<template #icon>
						<Icon
							class="dev-toolbar__icon"
							:name="areFeaturesLocked ? 'lock_open' : 'lock'"
						/>
					</template>
				</HostingerButton>
			</template>
		</ZyroTooltip>
		<ZyroTooltip
			toggle-event="hover"
			position="left"
			size="small"
		>
			<p class="text-body-3">
				{{ 'Overwrite site data from clipboard' }}
			</p>
			<template #trigger>
				<HostingerButton
					size="sm"
					button-type="plain"
					class="dev-toolbar__button"
					@click="overwriteSiteDataFromClipboard"
				>
					<template #icon>
						<Icon
							class="dev-toolbar__icon"
							name="edit"
						/>
					</template>
				</HostingerButton>
			</template>
		</ZyroTooltip>
	</div>
</template>

<style lang="scss" scoped>
.dev-toolbar {
	position: fixed;
	right: 0%;
	top: 50%;
	transform: translateY(-50%);
	background-color: $color-light;
	border-top-left-radius: $border-radius-medium;
	border-bottom-left-radius: $border-radius-medium;
	padding: 4px;
	display: flex;
	flex-direction: column;

	&__button {
		padding: 0;
	}

	&__icon {
		color: $color-success;

		&--invalid {
			color: $color-danger;
		}
	}
}
</style>
