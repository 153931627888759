<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import { ref } from 'vue';
import { timeout } from '@/utils/helpers';
import EventLogApi from '@/api/EventLogApi';

interface Props {
	imageSrc: string;
}

const props = defineProps<Props>();

const SUBMIT_TIMEOUT_MS = 2000;
const isRatingVisible = ref(true);
const isRatingSubmitted = ref(false);

const handleRatingSubmit = async (isLiked: boolean) => {
	EventLogApi.logEvent({
		eventName: 'website_builder.ai_image_generator.image_rating',
		eventProperties: {
			rating: +isLiked,
			imageSrc: props.imageSrc,
		},
	});

	isRatingSubmitted.value = true;
	await timeout(SUBMIT_TIMEOUT_MS);
	isRatingVisible.value = false;
};

</script>

<template>
	<Transition name="fade">
		<div
			v-if="isRatingVisible"
			class="image-rating-wrapper"
		>
			<Transition
				name="fade"
				mode="out-in"
			>
				<p
					v-if="isRatingSubmitted"
					v-qa="'ai-image-generator-rating-submitted'"
					class="text-body-2 image-rating-submitted"
					align="center"
				>
					{{ $t('builder.thankYouForYourFeedback') }}
				</p>

				<div
					v-else
					class="image-rating"
				>
					<p class="text-body-2 image-rating__text">
						{{ $t('builder.aiImagesRate') }}
					</p>
					<div class="image-rating__icons">
						<div
							v-qa="'ai-image-generator-rating-thumbs-up'"
							class="image-rating__icon"
							@click="handleRatingSubmit(true)"
						>
							<Icon
								name="thumbs-up"
								is-custom
							/>
						</div>
						<div
							v-qa="'ai-image-generator-rating-thumbs-down'"
							class="image-rating__icon image-rating__icon--flipped"
							@click="handleRatingSubmit(false)"
						>
							<Icon
								name="thumbs-up"
								is-custom
							/>
						</div>
					</div>
				</div>
			</Transition>
		</div>
	</Transition>
</template>

<style scoped lang="scss">
.image-rating-wrapper {
	margin-bottom: 16px;
}

.image-rating-submitted {
	color: var(--color-gray);
	text-align: center;
	line-height: 40px;
}

.image-rating {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__text {
		color: var(--color-gray)
	}

	&__icons {
		display: flex;
		align-items: baseline;
	}

	&__icon {
		display: inline-block;
		vertical-align: top;
		align-self: baseline;
		padding: 8px;
		border-radius: 4px;
		cursor: pointer;

		svg {
			display: block;
		}

		&--flipped {
			transform: rotate(180deg);
		}

		&:hover {
			background-color: var(--color-gray-light);
		}
	}
}
</style>
