<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import { useStore } from 'vuex';

import AssetManager from '@/components/builder-modals/modals/AssetManager.vue';
import { defaultPexelsVideos } from '@/use/defaultPexelsVideo';

import {
	computed,
	ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { ASSET_CATEGORY } from '@/types/fileFormatTypes';

const { getters } = useStore();
const { t } = useI18n();

const showAssetManager = ref(false);

const currentBlock = computed(() => getters.currentBlock);
const selectedVideoThumbnail = computed(() => currentBlock.value.background?.video?.videoThumbnailSrc || defaultPexelsVideos[0]?.image);
const selectorText = computed(() => (selectedVideoThumbnail.value ? t('common.replaceVideo') : t('common.addVideo')));

const handleClick = () => {
	showAssetManager.value = true;
};
</script>

<template>
	<div class="video-selector">
		<button
			v-qa="'builder-sectionsettings-btn-addvideo'"
			class="video-selector__button"
			:title="selectorText"
			@click="handleClick"
		>
			<img
				v-if="selectedVideoThumbnail"
				:src="selectedVideoThumbnail"
				alt=""
				class="video-selector__image"
			>
			<div class="video-selector__description">
				<Icon
					name="video_library"
					class="video-selector__icon"
				/>
				<p class="text-body-2">
					{{ selectorText }}
				</p>
			</div>
		</button>
		<Teleport
			v-if="showAssetManager"
			to="body"
		>
			<AssetManager
				:visible-categories="[ASSET_CATEGORY.VIDEO]"
				@close="showAssetManager = false"
			/>
		</Teleport>
	</div>
</template>

<style lang="scss" scoped>
.video-selector {
	margin-bottom: 16px;

	&__button {
		position: relative;
		display: block;
		width: 100%;
		height: 200px;
		cursor: pointer;
		border-radius: 4px;
		background-color: $color-gray-border;
	}

	// Image is used instead of background image, because background-image flickers
	&__image {
		object-fit: cover;
		width: 100%;
		height: 100%;
		object-position: center center;
		border-radius: 4px;
	}

	&__icon {
		margin-bottom: 4px;
	}

	&__description {
		position: absolute;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 16px;
		color: $color-light;
		background: rgb(0 0 0 / 60%);
		backdrop-filter: blur(2px);
	}
}
</style>
