import {
	getIsExperimentActive,
	EXPERIMENT_IDS,
} from '@/utils/experiments';
import {
	computed,
	ref,
} from 'vue';
import { useStorage } from '@vueuse/core';
import { SiteData } from '@hostinger/builder-schema-validator';
import { useSiteStore } from '@/stores/siteStore';
import { useRedirects } from '@/use/useRedirects';
import { useStore } from 'vuex';
import { addGoogleFontQueryLinks } from '@/utils/injectableDomElements/addGoogleFontQueryLinks';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { createStoreWithProducts } from '@/api/EcommerceApi';
import { useSiteTextElementPreview } from '@/use/useSiteTextElementPreview';
import { useTextToSvg } from '@/use/useTextToSvg';
import { HTML_LANG_VALUES } from '@/data/htmlLangValues';
import {
	LOGO_SVG_DEFAULT,
	AI_BUILDER_PERSONALIZATION_COLOR_PALETTES,
} from '@/data/builderData';
import {
	SITE_HISTORY_KEY,
	SITE_HISTORY_LIMIT,
	AI_BUILDER_LOGO_SIZE,
	AI_BUILDER_DEFAULT_LOGO_HEIGHT,
	AI_BUILDER_DEFAULT_LOGO_WIDTH,
	AI_BUILDER_MAXIMUM_LOGO_CHARACTER_LENGTH,
	AI_BUILDER_CATEGORY_ID_BUSINESS,
} from '@/constants/builderConstants';
import {
	updateTemplateWithLogoSvg,
	getTemplateNavigationColor,
} from '@/utils/aiTemplateEditingHelpers';
import {
	DEFAULT_HTML_LANG_VALUE,
	SITE_META_ECOMMERCE_STORE_ID,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { useAiBuilderPreviewStore } from '@/stores/aiBuilderPreviewStore';
import { useAiBuilderForm } from '@/components/ai-builder/useAiBuilderForm';
import { generateSite } from '@/api/AiApiV2';
import { useNotifications } from '@/use/useNotifications';
import { useI18n } from 'vue-i18n';
import { AxiosError } from 'axios';
import { BUILDER_ROUTE } from '@/constants/routes';
import { usePreviewHeaderNavigation } from '@/use/usePreviewHeaderNavigation';
import { useRoute } from 'vue-router';
import LogEventApi from '@/api/EventLogApi';
import {
	AiBuilderColorPalette,
	WebsiteType,
	GeneratedColorPalette,
} from '@/types/aiBuilderTypes';

const FONT_FALLBACK = "'Roboto', sans-serif";

interface SiteHistoryRecord {
	language: string;
	siteData: SiteData;
	ecommerceProducts: any;
	timestamp?: number;
	siteId?: string;
	colorPalettePicked?: GeneratedColorPalette | AiBuilderColorPalette;
	colorPaletteGenerated?: GeneratedColorPalette;
}

enum AiPreviewGeneratorState {
	IDLE = 'IDLE',
	GENERATING = 'GENERATING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

export const useAiBuilderPreview = () => {
	const { t } = useI18n();
	const { notify } = useNotifications();
	const generatedSites = useStorage<SiteHistoryRecord[]>(SITE_HISTORY_KEY, []);
	const {
		dispatch,
		getters,
	} = useStore();
	const siteStore = useSiteStore();
	const ecommerceStore = useEcommerceStore();
	const { getSvg } = useTextToSvg();
	const aiBuilderPreviewStore = useAiBuilderPreviewStore();
	const route = useRoute();
	const { navigate } = usePreviewHeaderNavigation();
	const { recalculateWebsiteTextHeights } = useSiteTextElementPreview();
	const { redirectToEcommerce } = useRedirects();
	const {
		setDescriptionValue,
		setBusinessTypeId,
		setBrandName,
		setSelectedColors,
		brandDescription,
		selectedColors,
		websiteType: websiteTypeId,
		brandName,
		AI_BUILDER_CATEGORIES,
		isOnlineStoreSuggestionDeclined,
	} = useAiBuilderForm();

	const aiPreviewGeneratorState = ref<AiPreviewGeneratorState>(AiPreviewGeneratorState.IDLE);

	const isAiPreviewLoading = computed(() => aiPreviewGeneratorState.value === AiPreviewGeneratorState.GENERATING);
	const isEditSiteDisabled = computed(() => isAiPreviewLoading.value || aiBuilderPreviewStore.isLoadingEcommerceData);
	const generatedSitesCount = computed(() => generatedSites.value.length);
	const generatedSitesBySiteId = computed(() => generatedSites.value.filter((site) => site.siteId === siteStore.websiteId));
	const shouldGenerateLogo = computed(() => brandName.value.trim().length < AI_BUILDER_MAXIMUM_LOGO_CHARACTER_LENGTH);
	const currentVersionSite = computed(() => generatedSites.value?.[aiBuilderPreviewStore.currentVersionIndex]);
	const colorPalettePicked = computed(() => currentVersionSite.value?.colorPalettePicked || AI_BUILDER_PERSONALIZATION_COLOR_PALETTES[0]);
	const colorPaletteGenerated = computed(() => currentVersionSite.value?.colorPaletteGenerated
		|| AI_BUILDER_PERSONALIZATION_COLOR_PALETTES[0]);

	const getIsAiBuilderPreviewExperimentActive = () => getIsExperimentActive(EXPERIMENT_IDS.AI_BUILDER_PREVIEW_REVAMP_EXPERIMENT);
	const getIsAiBuilderSidebarExperimentActive = () => getIsExperimentActive(EXPERIMENT_IDS.AI_BUILDER_PREVIEW_SIDEBAR);

	const saveGeneratedSite = (site: SiteHistoryRecord) => {
		if (!getIsAiBuilderPreviewExperimentActive()) {
			return;
		}

		if (generatedSitesCount.value >= SITE_HISTORY_LIMIT) {
			const differentSiteIdRecord = generatedSites.value.findIndex((record) => record.siteId !== siteStore.websiteId);

			// Prioritize to remove the oldest & non-current siteId record
			if (differentSiteIdRecord !== -1) {
				generatedSites.value.splice(differentSiteIdRecord, 1);
			} else {
				generatedSites.value.pop();
			}
		}

		generatedSites.value = [
			{
				...site,
				timestamp: Date.now(),
				siteId: siteStore.websiteId,
			},
			...generatedSites.value,
		];
	};

	const getSiteWithReplacedColors = ({
		siteData,
		newColorPalette,
		oldColorPalette,
	}: {
		siteData: SiteData,
		newColorPalette: AiBuilderColorPalette | GeneratedColorPalette,
		oldColorPalette: AiBuilderColorPalette | GeneratedColorPalette,
	}) => JSON.parse(
		JSON.stringify(siteData)
			.replaceAll(oldColorPalette.color1, newColorPalette.color1)
			.replaceAll(oldColorPalette.color2, newColorPalette.color2)
			.replaceAll(oldColorPalette.color3, newColorPalette.color3)
			.replaceAll(oldColorPalette.light, newColorPalette.light)
			.replaceAll(oldColorPalette.grey, newColorPalette.grey)
			.replaceAll(oldColorPalette.dark, newColorPalette.dark),
	);

	const setSiteWithNewColors = (newColorPalette: AiBuilderColorPalette | GeneratedColorPalette) => {
		const { site } = siteStore;

		if (!Object.keys(site).length) {
			return;
		}

		const oldColorPalette = colorPalettePicked.value;

		const updatedSiteData = getSiteWithReplacedColors({
			siteData: site as SiteData,
			newColorPalette,
			oldColorPalette,
		});

		generatedSites.value[aiBuilderPreviewStore.currentVersionIndex].siteData = updatedSiteData;
		generatedSites.value[aiBuilderPreviewStore.currentVersionIndex].colorPalettePicked = newColorPalette;

		siteStore.setSiteData(updatedSiteData);

		dispatch('updateCurrentPageId');
		dispatch('gui/updatePreviewSiteData', {
			...updatedSiteData,
			siteId: siteStore.websiteId,
		});
	};

	const setNewPreviewSite = async ({ siteVersionIndex }: { siteVersionIndex: number }) => {
		const {
			language,
			siteData,
			ecommerceProducts,
		} = generatedSites.value[siteVersionIndex] || {};

		if (!siteData) {
			throw new Error('Site record does not exist');
		}

		aiBuilderPreviewStore.setCurrentVersionIndex(siteVersionIndex);

		siteStore.setSiteData(siteData);
		dispatch('updateCurrentPageId');
		ecommerceStore.resetProductsList();

		const { colorPalettePicked: siteVersionPalette } = generatedSites.value[siteVersionIndex];

		if (siteVersionPalette) {
			setSiteWithNewColors(siteVersionPalette);
		}

		if (ecommerceProducts?.length) {
			const languageCode = HTML_LANG_VALUES.find(
				(lang) => lang.title.toLowerCase() === language?.toLowerCase(),
			)?.value?.toUpperCase();

			try {
				aiBuilderPreviewStore.setIsLoadingEcommerceData(true);

				const { storeId } = await createStoreWithProducts({
					siteId: siteStore.websiteId,
					products: ecommerceProducts,
					language: languageCode || DEFAULT_HTML_LANG_VALUE.value,
				});

				siteStore.setSiteMetaData({
					key: SITE_META_ECOMMERCE_STORE_ID,
					value: storeId,
				});

				await ecommerceStore.fetchInitialEcommerceData({
					shouldAwaitPageCreation: true,
				});
			} catch (error) {
				console.error('Failed to create ecommerce store', error);
			} finally {
				aiBuilderPreviewStore.setIsLoadingEcommerceData(false);
			}
		}

		await dispatch('gui/updatePreviewSiteData', {
			...siteStore.site,
			siteId: siteStore.websiteId,
		});
	};

	const handleCreateAgainClick = () => {
		aiBuilderPreviewStore.setAiFormVisibility(true);

		const {
			websiteDescription: lastWebsiteDescription,
			websiteType: lastWebsiteType,
			brandName: lastBrandName,
			selectedColors: lastSelectedColors,
		} = aiBuilderPreviewStore.getLastAiGenerationData();

		setDescriptionValue(lastWebsiteDescription);
		setBusinessTypeId(lastWebsiteType);
		setBrandName(lastBrandName);
		setSelectedColors((lastSelectedColors || {}) as AiBuilderColorPalette);

		LogEventApi.logEvent({
			eventName: 'website_builder.ai_builder.create_new_enter',
		});
	};

	const handleAiFormSubmit = async () => {
		if (aiPreviewGeneratorState.value !== AiPreviewGeneratorState.IDLE) {
			return;
		}

		if (!websiteTypeId.value) {
			return;
		}

		aiPreviewGeneratorState.value = AiPreviewGeneratorState.GENERATING;

		const websiteType = (isOnlineStoreSuggestionDeclined.value
			? AI_BUILDER_CATEGORIES[AI_BUILDER_CATEGORY_ID_BUSINESS].amplitudeName
			: AI_BUILDER_CATEGORIES[websiteTypeId.value].amplitudeName) as WebsiteType;
		let generatedSiteData;

		aiBuilderPreviewStore.setLastAiGenerationData({
			brandName: brandName.value,
			websiteDescription: brandDescription.value,
			websiteType,
			selectedColors: selectedColors.value,
		});

		LogEventApi.logEvent({
			eventName: 'website_builder.ai_builder.regenerate',
			eventProperties: {
				website_id: siteStore.websiteId,
				business_type: websiteType,
				...(getIsAiBuilderPreviewExperimentActive() && isOnlineStoreSuggestionDeclined.value && {
					has_refused_online_store_suggestion: isOnlineStoreSuggestionDeclined.value,
				}),
			},
		});

		try {
			const { data } = await generateSite({
				brandName: brandName.value,
				websiteType,
				brandDescription: brandDescription.value,
				siteId: siteStore.websiteId,
			});

			dispatch('saving/updateClientTimestamp', data.clientTimestamp);

			generatedSiteData = data;
			aiPreviewGeneratorState.value = AiPreviewGeneratorState.SUCCESS;
		} catch (error) {
			if (error instanceof AxiosError && error.response?.status === 422) {
				notify({
					message: t('builder.aiBuilderInvalidWebsiteDescription'),
				});
			}

			aiPreviewGeneratorState.value = AiPreviewGeneratorState.ERROR;
			throw error;
		}

		let fontFamiliesToAwait;

		if (generatedSiteData.siteData.fonts) {
			fontFamiliesToAwait = generatedSiteData.siteData.fonts.map((font) => font.family);

			if (getters['fonts/getMetaFont']) {
				addGoogleFontQueryLinks(getters['fonts/getMetaFont']);
			}
		}

		const logoSvg = shouldGenerateLogo.value ? await getSvg({
			text: brandName.value,
			fontFamily: generatedSiteData.siteData.fonts?.[0]?.family || FONT_FALLBACK,
			fontSize: AI_BUILDER_LOGO_SIZE,
		}) : {
			svg: LOGO_SVG_DEFAULT,
			height: AI_BUILDER_DEFAULT_LOGO_HEIGHT,
			width: AI_BUILDER_DEFAULT_LOGO_WIDTH,
		};

		generatedSiteData.siteData = updateTemplateWithLogoSvg({
			logoSvg,
			color: getTemplateNavigationColor({
				template: generatedSiteData.siteData,
			}),
			templateData: generatedSiteData.siteData,
		}) as SiteData;

		await siteStore.setSiteData(generatedSiteData.siteData);

		await recalculateWebsiteTextHeights({
			fontFamiliesToAwait,
		});

		await dispatch('updateCurrentPageId');

		await dispatch('gui/updatePreviewSiteData', {
			...generatedSiteData.siteData,
			siteId: siteStore.websiteId,
		});

		saveGeneratedSite({
			language: generatedSiteData.language,
			siteData: siteStore.site as SiteData,
			ecommerceProducts: generatedSiteData.ecommerceProducts,
			colorPalettePicked: generatedSiteData.colorPalette,
			colorPaletteGenerated: generatedSiteData.colorPalette,
		});

		setNewPreviewSite({
			siteVersionIndex: 0,
		});

		aiBuilderPreviewStore.setAiFormVisibility(false);

		LogEventApi.logEvent({
			eventName: 'website_builder.ai_builder.regenerated',
		});

		aiPreviewGeneratorState.value = AiPreviewGeneratorState.IDLE;
	};

	const handleEditClick = ({ isEcommerceRedirectEnabled = false }) => {
		const colorPaletteProperties = getIsAiBuilderSidebarExperimentActive() ? {
			color1: colorPalettePicked.value.color1,
			color2: colorPalettePicked.value.color2,
			color3: colorPalettePicked.value.color3,
		} : {};

		LogEventApi.logEvent({
			eventName: 'website_builder.preview.edit',
			eventProperties: {
				...colorPaletteProperties,
			},
		});

		if (isEcommerceRedirectEnabled) {
			redirectToEcommerce();

			return;
		}

		if (!route.params.siteId) {
			throw new Error('URL is missing site id');
		}

		dispatch('saving/startSavingTimer');

		navigate({
			name: BUILDER_ROUTE,
			params: {
				siteId: route.params.siteId as string,
			},
		});
	};

	/**
	 * Deletes generated site versions that match the current site id.
	 */
	const deleteGeneratedSiteVersionBySiteId = () => {
		if (!getIsAiBuilderPreviewExperimentActive()) {
			return;
		}

		if (generatedSitesBySiteId.value.length) {
			generatedSites.value = generatedSites.value.filter((site) => site.siteId !== siteStore.websiteId);
		}
	};

	return {
		isAiPreviewLoading,
		isEditSiteDisabled,
		getIsAiBuilderPreviewExperimentActive,
		getIsAiBuilderSidebarExperimentActive,
		saveGeneratedSite,
		setNewPreviewSite,
		handleCreateAgainClick,
		handleAiFormSubmit,
		handleEditClick,
		generatedSites,
		currentVersionSite,
		colorPalettePicked,
		colorPaletteGenerated,
		generatedSitesBySiteId,
		deleteGeneratedSiteVersionBySiteId,
		setSiteWithNewColors,
		getSiteWithReplacedColors,
	};
};
