<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

import ZyroSettingsTitle from '@/components/site-settings/components/ZyroSettingsTitle.vue';
import CookieBanner from '@/components/site-settings/pages/general/components/CookieBanner.vue';
import MetaImageUploadCollapsible from '@/components/site-settings/pages/general/components/MetaImageUploadCollapsible.vue';
import OgImagePreview from '@/components/site-settings/pages/general/components/OgImagePreview.vue';
import SettingsBrowserPreview from '@/components/site-settings/pages/general/components/SettingsBrowserPreview.vue';
import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';
import ZyroToggle from '@/components/global/ZyroToggle.vue';
import { useSiteStore } from '@/stores/siteStore';

const { getters } = useStore();
const siteStore = useSiteStore();

const siteMeta = computed(() => getters.siteMeta);
const isWWWPrefixEnabled = computed(() => siteMeta.value.shouldAddWWWPrefixToDomain);

const saveFavicon = ({
	origin,
	path,
}: { origin: string, path: string}) => {
	siteStore.setSiteMetaData({
		key: 'faviconOrigin',
		value: origin,
	});

	siteStore.setSiteMetaData({
		key: 'faviconPath',
		value: path,
	});
};

const saveOgImage = ({
	origin,
	path,
	alt,
}: { origin: string, path: string, alt: string}) => {
	siteStore.setSiteMetaData({
		key: 'ogImageOrigin',
		value: origin,
	});

	siteStore.setSiteMetaData({
		key: 'ogImagePath',
		value: path,
	});

	siteStore.setSiteMetaData({
		key: 'ogImageAlt',
		value: alt,
	});
};

const updateIsWWWPrefixEnabled = (newValue: boolean) => {
	siteStore.setSiteMetaData({
		key: 'shouldAddWWWPrefixToDomain',
		value: newValue,
	});
};

</script>
<template>
	<section>
		<ZyroSettingsTitle>
			{{ $t('siteSettings.nav.general') }}
		</ZyroSettingsTitle>
		<MetaImageUploadCollapsible
			:title="$t('common.favicon')"
			:label="$t('common.favicon')"
			:info-text="$t('siteSettings.general.favicon.infoText')"
			:meta-image-initial-image-origin="siteMeta.faviconOrigin"
			:meta-image-initial-image-path="siteMeta.faviconPath"
			qa="favicon"
			@save="saveFavicon"
		>
			<template #preview="{ src }">
				<SettingsBrowserPreview :src="src" />
			</template>
		</MetaImageUploadCollapsible>
		<MetaImageUploadCollapsible
			class="link-preview-image"
			:title="$t('siteSettings.general.ogImage.heading')"
			:label="$t('siteSettings.general.ogImage.content.label')"
			:info-text="$t('siteSettings.general.ogImage.content.infoText')"
			:meta-image-initial-image-origin="siteMeta.ogImageOrigin"
			:meta-image-initial-image-path="siteMeta.ogImagePath"
			qa="ogimage"
			@save="saveOgImage"
		>
			<template #description>
				<i18n-t
					tag="p"
					class="link-preview-image__disclaimer text-body-2"
					keypath="builder.linkPreviewImageExplainMessage"
				>
					<a
						class="z-link"
						href="https://support.hostinger.com/en/articles/6466412-website-builder-how-to-add-link-preview-images"
					>
						{{ $t('common.learnMore') }}
					</a>
				</i18n-t>
			</template>
			<template #preview="{ src }">
				<OgImagePreview :src="src" />
			</template>
		</MetaImageUploadCollapsible>
		<CookieBanner />
		<ZyroCard class="www-prefix-card">
			<div class="www-prefix-card__toggle-container">
				<label
					for="www-prefix-toggle"
					class="text-bold-1"
				>
					{{ $t('siteSettings.hasDomainWWWPrefixCardTitle') }}
				</label>
				<ZyroToggle
					id="www-prefix-toggle"
					:model-value="isWWWPrefixEnabled"
					@update:model-value="updateIsWWWPrefixEnabled"
				/>
			</div>
			<p>
				{{ $t('siteSettings.hasDomainWWWPrefixCardSubtitle') }}
			</p>
		</ZyroCard>
	</section>
</template>
<style lang="scss" scoped>
.www-prefix-card {
	margin-bottom: 16px;

	&__toggle-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.link-preview-image {
	:deep(.collapsible__main) {
		padding-top: 0;
	}

	&__disclaimer {
		color: $color-gray;
		margin-bottom: 16px;
	}
}
</style>
