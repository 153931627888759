import {
	ref,
	onMounted,
	watchEffect,
	watch,
} from 'vue';

const DEFAULT_MARGIN = '600px';
let observerInstance: IntersectionObserver | null;
const elements = ref<Element[]>([]);

export const useImageIntersectionObserver = (rootMargin = DEFAULT_MARGIN) => {
	const observer = ref<IntersectionObserver | null>(null);

	const unobserveElement = (element: Element) => {
		if (!observer.value) {
			return;
		}

		observer.value.unobserve(element);
		const index = elements.value.indexOf(element);

		if (index !== -1) {
			elements.value.splice(index, 1);
		}
	};

	const callback = (entriesParam: IntersectionObserverEntry[]) => {
		entriesParam.forEach((entry: IntersectionObserverEntry) => {
			if (entry.isIntersecting && observer.value) {
				unobserveElement(entry.target);
			}
		});
	};

	const addElement = (elementReference: any) => {
		// Watch for templateRef change; Start observing and stop watching if there's and $el mounted.
		const stopReferenceWatcher = watchEffect(() => {
			if (elementReference.value && observer.value) {
				// Check if passed templateRef has an $el. If so - get the $el, if not, get the ref itelf
				const templateElement = elementReference.value.$el ?? elementReference.value;

				observer.value.observe(templateElement);
				elements.value.push(templateElement);

				stopReferenceWatcher();
			}
		});
	};

	try {
		onMounted(() => {
			if (!observerInstance) {
				observerInstance = new IntersectionObserver(callback, {
					rootMargin,
				});
			}

			observer.value = observerInstance;
		});

		watch(elements, (newVal) => {
			if (observer.value && newVal.length === 0) {
				observer.value.disconnect();
			}
		});
	} catch (error) {
		console.error(error);
	}

	return {
		addElement,
		elements,
	};
};
