<template>
	<p class="text-body-2 social-icons-disclaimer">
		{{ $t('builder.editSocialIcons.tabLinks.socialIconMesssage') }}
	</p>

	<EditableItemsList
		ref="items"
		:items="editableItems"
		:placeholder="$t('builder.editSocialIcons.tabLinks.placeholder')"
		:item-placeholder-text="$t('builder.editSocialIcons.tabLinks.itemPlaceholder')"
		:validate-value="validator"
		is-editable-by-single-click
		:is-editable-by-double-click="false"
		class="social-icons-editable-list"
		@edit="editUrl"
		@update-items="updateItems"
	>
		<template #header>
			<EditableItemsAddButton
				v-qa="'edit-social-icons-popup-links-button-add'"
				:button-text="$t('builder.editSocialIcons.tabLinks.addNew')"
				:placeholder="$t('builder.editSocialIcons.tabLinks.placeholder')"
				:validate-value="validator"
				@add="addUrl"
			/>
		</template>
		<template #item-button="{ item, index, startEditingItem }">
			<HostingerButton
				v-qa="`edit-social-icons-popup-links-settings-open-${socialLinksElement.links[index].icon || 'link'}`"
				:data-popup-id="`linkEditButton-${index}`"
				button-type="plain"
				:title="$t('common.settings')"
				@click="openPopup(item, index)"
			>
				<template #icon>
					<Icon
						name="settings"
						dimensions="16px"
					/>
				</template>
			</HostingerButton>
			<Popup
				v-if="isSettingsPopupOpen && openItem === `${item.name}-${index}`"
				:target-ref="linkEditButtonPopupRef"
				padding="0"
				border-radius="16"
				:offset="settingsPopupOffset"
				placement="bottom-end"
				@click-outside="isSettingsPopupOpen = false; openItem = null"
			>
				<div class="links__link-settings">
					<HostingerButton
						v-qa="'edit-social-icons-popup-links-link-edit'"
						button-type="plain"
						@click="startEditingItem(index, item)"
					>
						<template #icon-left>
							<Icon
								name="edit"
								dimensions="16px"
							/>
						</template>
						{{ $t('common.edit') }}
					</HostingerButton>

					<HostingerButton
						v-qa="'edit-social-icons-popup-links-link-remove'"
						class="links__link-remove"
						button-type="plain"
						:title="$t('common.delete')"
						@click="removeUrl(index)"
					>
						<template #icon-left>
							<Icon
								name="delete"
								dimensions="16px"
							/>
						</template>
						{{ $t('common.delete') }}
					</HostingerButton>
				</div>
			</Popup>
		</template>
	</EditableItemsList>
</template>

<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Popup from '@/components/global/Popup.vue';

import { useStore } from 'vuex';

import svgImporter from '@/utils/svgImporter';
import {
	EditableItem,
	BrandIconSocialLink,
} from '@/types/utilTypes';
import { availableIcons } from '@/assets/data/brandInfo';
import EditableItemsAddButton from '@/components/reusable-components/editable-items-list/-partials/EditableItemsAddButton.vue';
import EditableItemsList from '@/components/reusable-components/editable-items-list/EditableItemsList.vue';
import {
	getValidEmail,
	getValidPhoneNumber,
	getValidUrl,
} from '@/utils/urlValidators';
import { REGEX_URL_START } from '@zyro-inc/site-modules/constants/regex';
import { useGamification } from '@/use/useGamification';
import { GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS } from '@/constants/builderConstants';
import { useI18n } from 'vue-i18n';
import {
	computed,
	ref,
} from 'vue';
import {
	SiteElementGridSocialIcons,
	SiteElementGridSocialIconLink,
} from '@hostinger/builder-schema-validator';

const MIN_URL_LENGTH = 5;

const settingsPopupOffset = {
	x: 0,
	y: 8,
};

interface SocialLinkEditableItems {
	originalData: SiteElementGridSocialIconLink;
	name: string,
	iconLocation: string,
	icon: string,
}

const props = defineProps<{
	elementId: string;
}>();

const { completeAchievement } = useGamification();
const {
	dispatch,
	getters,
} = useStore();
const { t } = useI18n();

const socialLinksElementId = computed(() => props.elementId);
const socialLinksElement = computed<SiteElementGridSocialIcons>(() => getters.siteElements[socialLinksElementId.value]);

const openItem = ref<string | null>(null);
const isSettingsPopupOpen = ref(false);
const linkEditButtonPopupRef = ref<HTMLElement | null>(null);

const editableItems = computed<SocialLinkEditableItems[]>(() => socialLinksElement.value.links.map((item) => {
	const {
		link,
		icon,
	} = item;

	return {
		originalData: {
			...item,
		},
		name: decodeURI(link ?? ''),
		iconLocation: icon ? 'brands' : '',
		icon: icon ? `${icon}-brands` : 'link',
	};
}));

const openPopup = (item: EditableItem, index: number) => {
	isSettingsPopupOpen.value = true;
	openItem.value = `${item.name}-${index}`;

	linkEditButtonPopupRef.value = document.querySelector(`[data-popup-id="linkEditButton-${index}"]`);
};

const getIconByURL = async (url: string): Promise<BrandIconSocialLink> => {
	const isNonStandardUrlMatch = url.match(/^(mailto|tel):/);

	const preparedUrl = isNonStandardUrlMatch?.[0] ? url : new URL(url).hostname;

	const urlWithoutStart = preparedUrl.replace(REGEX_URL_START, '');

	const icon = availableIcons.findLast(
		({ identifiers }) => identifiers.some((identifierPattern) => new RegExp(`(.*?)${identifierPattern}(.*?)`, 'i').test(urlWithoutStart)),
	) ?? {
		iconName: 'default',
		brandColor: '',
		identifiers: [],
	};

	const iconSvg = await svgImporter.getSvg(icon && icon.iconName, 'brands', 'brands');

	return {
		...icon,
		svg: iconSvg,
	};
};

const parseUrl = (urlToParse: string) => {
	const {
		url,
		isUrlValid,
	} = getValidUrl(urlToParse);

	const {
		url: emailUrl,
		isUrlValid: isEmailUrlValid,
	} = getValidEmail(urlToParse);

	const {
		url: phoneNumberUrl,
		isUrlValid: isPhoneNumberUrlValid,
	} = getValidPhoneNumber(urlToParse);

	const parsedUrl = url.includes('http') ? url : `https:${url}`;

	if (isEmailUrlValid && emailUrl) {
		return {
			url: emailUrl,
			isUrlValid: isEmailUrlValid,
		};
	}

	if (isPhoneNumberUrlValid && phoneNumberUrl) {
		return {
			url: phoneNumberUrl,
			isUrlValid: isPhoneNumberUrlValid,
		};
	}

	return {
		url: parsedUrl,
		isUrlValid,
	};
};

const validator = (urlToValidate: string) => {
	const {
		url,
		isUrlValid,
	} = parseUrl(urlToValidate);

	if (!urlToValidate.trim()) {
		return {
			isValid: false,
			error: t('validate.emptyValue'),
		};
	}

	const isValid = url.length > MIN_URL_LENGTH && isUrlValid;

	return {
		isValid,
		error: !isValid ? t('builder.editSocialIcons.error.invalidUrl') : '',
	};
};

const updateItems = (links: SocialLinkEditableItems[]) => {
	dispatch('mergeElementData', {
		elementId: socialLinksElementId.value,
		elementData: {
			links: links.map(({ originalData }) => ({
				...originalData,
			})),
		},
	});
};

const addUrl = async (newUrl: string) => {
	const { url } = parseUrl(newUrl);

	const icon = await getIconByURL(url);
	const links = [
		...socialLinksElement.value.links,
		{
			link: url,
			icon: icon.iconName || '',
			svg: icon.svg,
		},
	];

	dispatch('mergeElementData', {
		elementId: socialLinksElementId.value,
		elementData: {
			links,
		},
	});

	completeAchievement(GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS);
};

const removeUrl = (index: number) => {
	const links = [...socialLinksElement.value.links];

	links.splice(index, 1);
	dispatch('mergeElementData', {
		elementId: socialLinksElementId.value,
		elementData: {
			links,
		},
	});

	completeAchievement(GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS);
};

const editUrl = async ({
	newValue,
	index,
}: {
	newValue: EditableItem,
	index: number;
}) => {
	const { url } = parseUrl(newValue.name);
	const icon = await getIconByURL(url);
	const links = [...socialLinksElement.value.links];
	const currentItem = {
		...links[index],
	};

	links[index] = {
		...currentItem,
		link: url,
		icon: icon.iconName || '',
		svg: icon.svg,
	};
	dispatch('mergeElementData', {
		elementId: socialLinksElementId.value,
		elementData: {
			links,
		},
	});

	completeAchievement(GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS);
};
</script>

<style lang="scss" scoped>
.social-icons-disclaimer {
	color: $color-gray;
	margin: 16px 0;
}

.social-icons-editable-list {
	margin-bottom: 16px;
}

.links {
	&__link-settings {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background: $color-light;
		border-radius: 16px;
		padding: 8px;
		box-shadow: $box-shadow;
	}
}
</style>
