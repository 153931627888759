/* eslint-disable camelcase */
import {
	getDeviceId,
	init,
	track,
	Identify,
	identify,
} from '@amplitude/analytics-browser';
import eventList from '@/data/analyticEventList';
import { MEDIA_MOBILE_BREAKPOINT } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import { getSkipAmplitudeCookie } from '@/utils/getCookies';

type LogEvent = {
  eventName: string;
  eventProperties?: Record<string, unknown>;
};

// Initializes amplitude with provided userId (hPanel client id)
const initAmplitude = ({ userId }:{
	userId: string;
}) => {
	if (import.meta.env.DEV || !isHostingerBrand || getSkipAmplitudeCookie()) {
		return;
	}

	const deviceId = getDeviceId();

	init(
		import.meta.env.VITE_AMPLITUDE_BROWSER_API_KEY,
		{
			serverUrl: import.meta.env.VITE_AMPLITUDE_ANALYTICS_URL,
			deviceId,
			defaultTracking: false,
			userId,
			transport: 'beacon',
			flushIntervalMillis: 0,
			flushQueueSize: 1,
		},
	);
};

const setUserProperty = async (
	propertyName: string,
	propertyValue: string,
): Promise<void> => {
	const identifyObject = new Identify();

	identifyObject.set(propertyName, propertyValue);

	await identify(identifyObject);
};

const logEvent = async ({
	eventName,
	eventProperties = {},
}: LogEvent): Promise<void> => {
	if (!isHostingerBrand) {
		return;
	}

	const device_mode = window.screen.width > MEDIA_MOBILE_BREAKPOINT ? 'desktop' : 'mobile';
	const verifiedEventName = eventList.getEvent(eventName)?.amplitude;

	if (!verifiedEventName) {
		console.error(`[Amplitude] \nEvent with name - ${eventName} not found in event list or wrong event object structure`);

		return;
	}

	const event = {
		device_mode,
		...eventProperties,
	};

	const isTestEnvironment = import.meta.env.NODE_ENV === 'test';

	if (!isTestEnvironment && (import.meta.env.DEV || getSkipAmplitudeCookie())) {
		// eslint-disable-next-line no-console
		console.info(`%c [Amplitude]: Sending event - ${verifiedEventName}`, 'color: skyblue; font-weight: bold;', event);

		return;
	}

	await track(verifiedEventName, event).promise;
};

export default {
	initAmplitude,
	logEvent,
	setUserProperty,
};
