import {
	useMouse,
	useMousePressed,
	useScroll,
} from '@vueuse/core';
import {
	Ref,
	watch,
} from 'vue';

export const useMouseScroll = (el: Ref<HTMLElement | null>) => {
	const { x: scrollX } = useScroll(el);
	const { pressed } = useMousePressed({
		target: el,
	});
	const { x: mouseX } = useMouse();

	watch([
		() => pressed.value,
		() => mouseX.value,
	], ([newPressed, newMouseX], [, oldMouseX]) => {
		if (newPressed) {
			const scrollTo = oldMouseX - newMouseX;

			scrollX.value += scrollTo;
		}
	});
};
