<template>
	<ZyroPopupCard
		type="editor"
		is-full-height
		max-width="305px"
		:show-close-button="false"
		@close="$emit('close')"
	>
		<ExpandableSettingsCard
			:title="$t('builder.sectionSettings.title')"
			:is-footer-shown="isFooterShown"
			@close="$emit('close')"
		>
			<ExpandableSettingsItem
				v-for="item in items"
				v-show="!item.isHidden"
				:id="item.id"
				:key="`item-${item.id}`"
				:title="item.title"
				:should-open-at-load="item.isOpen"
				:is-open="currentTab === item.id"
				@item-clicked="handleItemClick(item.id)"
			>
				<Component
					:is="item.component"
					:block-id="props.blockId"
					block-type-product
					use-color-picker-v2
				/>
			</ExpandableSettingsItem>
			<NpsRateFeature
				class="product-settings__nps-rate"
				:feature-name="$t('builder.npsRateOnlineStore')"
				:type="NPS_TYPE_FEATURE_SIMPLE_STORE"
				hide-border
			/>
			<template
				v-if="!ecommerceStore.isDynamicPageFlowEnabled"
				#footer
			>
				<div class="footer">
					<div class="footer__title">
						{{ $t('builder.ecommerceTabsApplyChanges') }}
					</div>
					<div class="footer__button-wrapper">
						<HostingerButton
							v-qa="'builder-popupcard-applytoallbtn'"
							:is-disabled="!isCurrentBlockEdited"
							@click="applyBlockSettingsToAll"
						>
							{{ $t('builder.ecommerceApplyToAll') }}
						</HostingerButton>
						<Transition name="fade">
							<div
								v-if="isLoaded"
								class="footer__checkmark"
							>
								<ZyroSvgDeprecated
									name="check-mark"
									class="footer__icon"
								/>
								{{ $t('builder.appliedToAll') }}
							</div>
						</Transition>
					</div>
				</div>
			</template>
		</ExpandableSettingsCard>
	</ZyroPopupCard>
</template>

<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';
import ExpandableSettingsCard from '@/components/reusable-components/expandable-settings/ExpandableSettingsCard.vue';
import ExpandableSettingsItem from '@/components/reusable-components/expandable-settings/ExpandableSettingsItem.vue';
import { useStore } from 'vuex';
import EditBlockTabStyle from '@/components/builder-controls/common-ecommerce/EditBlockTabStyle.vue';
import EditBlockTabButton from '@/components/builder-controls/edit-block-ecommerce-product/EditBlockTabButton.vue';
import EditBlockTabLayout from '@/components/builder-controls/edit-block-ecommerce-product/EditBlockTabLayout.vue';
import EditBlockTabProduct from '@/components/builder-controls/edit-block-ecommerce-product/EditBlockTabProduct.vue';
import EditBlockTabQuantity from '@/components/builder-controls/edit-block-ecommerce-product/EditBlockTabQuantity.vue';
import cloneDeep from 'lodash.clonedeep';
import { NPS_TYPE_FEATURE_SIMPLE_STORE } from '@/constants/builderConstants';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import { useI18n } from 'vue-i18n';
import { useEcommerceTabSettingsPopup } from '@/use/useEcommerceTabSettingsPopup';
// @ts-ignore
import isEqual from 'lodash.isequal';

import {
	ref,
	computed,
	watch,
	onMounted,
} from 'vue';
import { EcommerceProductType } from '@zyro-inc/site-modules/types';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { useSiteStore } from '@/stores/siteStore';

const TAB_ID_ECOMMERCE = 'ecommerce';

defineEmits(['close']);

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	isProductPurchasable: {
		type: Boolean,
		default: true,
	},
});

const {
	getters,
	dispatch,
} = useStore();
const { t } = useI18n();
const {
	currentTab,
	handleItemClick,
} = useEcommerceTabSettingsPopup();
const ecommerceStore = useEcommerceStore();
const siteStore = useSiteStore();

const initialCurrentBlockValue = ref(null);
const isCurrentBlockEdited = ref(false);
const isLoaded = ref(false);

const siteBlocks = computed(() => getters.siteBlocks);
const block = computed(() => siteBlocks.value[props.blockId]);

const isProductBooking = computed(() => {
	const currentProduct = ecommerceStore.products.find((product) => product.id === block.value.product.id);

	return currentProduct?.type.value === EcommerceProductType.BOOKING;
});

const items = computed<Array<{id: string, title: string, component: any, isOpen?: boolean, isHidden?: boolean}>>(() => [
	{
		id: TAB_ID_ECOMMERCE,
		title: t('common.product'),
		component: EditBlockTabProduct,
		isOpen: true,
	},
	{
		id: 'layout',
		title: t('common.layout'),
		component: EditBlockTabLayout,
	},
	{
		id: 'background',
		title: t('common.style'),
		component: EditBlockTabStyle,
	},
	{
		id: 'button',
		title: t('common.button'),
		isHidden: !props.isProductPurchasable,
		component: EditBlockTabButton,
	},
	{
		id: 'quantity',
		title: t('common.productQuantity'),
		component: EditBlockTabQuantity,
		isHidden: isProductBooking.value || !props.isProductPurchasable,
	},
]);

const isFooterShown = computed(() => siteStore.isCurrentPageTypeEcommerceProduct && isCurrentBlockEdited.value);

watch(block, (newBlock) => {
	if (!isEqual(initialCurrentBlockValue.value, newBlock)) {
		isCurrentBlockEdited.value = true;
	}
});

onMounted(() => {
	initialCurrentBlockValue.value = cloneDeep(block.value);
});

const applyBlockSettingsToAll = () => {
	const blockDataCopy = {
		...block.value,
	};

	delete blockDataCopy.product;

	dispatch('updateEcommerceProductPagesBlocks', blockDataCopy);
	initialCurrentBlockValue.value = block.value;
	isCurrentBlockEdited.value = false;
	isLoaded.value = true;

	setTimeout(() => {
		isLoaded.value = false;
	}, 3500);
};

</script>

<style lang="scss" scoped>
.product-settings {
	&__nps-rate {
		justify-content: center;
		padding: 24px;
	}
}

.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	text-align: center;

	&__title {
		margin-bottom: 8px;
		font-size: 14px;
	}

	&__button-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__checkmark {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: $color-gray;
	}

	&__icon {
		margin-right: 4px;
	}
}
</style>
