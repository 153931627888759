<template>
	<div class="edit-store">
		<HImage
			src="/images/store-upgradeSite.png"
			:alt="$t('builder.editStoreModalContainsAdvanced')"
			class="edit-store__image"
		/>
		<h5 class="text-heading-1 z-font-weight-normal edit-store__title">
			{{ $t('builder.editStoreModalContainsAdvanced') }}
		</h5>
		<HostingerButton
			v-qa="`${qaSelector}-btn-choose`"
			size="lg"
			@click="handleButtonClick"
		>
			{{ $t('common.choosePlan') }}
		</HostingerButton>
		<div class="edit-store__text edit-store__text--small">
			{{ $t('common.or') }}
		</div>
		<i18n-t
			class="edit-store__text"
			keypath="builder.editStoreModalDeleteStorePage"
		>
			<span class="edit-store__highlight">
				{{ $t('builder.editStoreModalDeleteStorePageSlot') }}
			</span>
		</i18n-t>
	</div>
</template>

<script>
import HostingerButton from '@/components/global/HostingerButton.vue';
import HImage from '@/components/global/HImage.vue';
import { defineComponent } from 'vue';

export default defineComponent({

	components: {
		HostingerButton,
		HImage,
	},

	props: {
		qaSelector: {
			type: String,
			required: true,
		},
	},
	emits: ['button-click'],

	methods: {
		handleButtonClick() {
			this.$emit('button-click');
		},
	},
});
</script>

<style lang="scss" scoped>
@import "./style";
</style>
