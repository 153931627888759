import {
	ELEMENT_POSITION_KEY_DESKTOP,
	ELEMENT_POSITION_KEY_MOBILE,
	ELEMENT_TYPE_TEXT_BOX,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import {
	DESKTOP_BLOCK_WIDTH,
	MOBILE_BLOCK_WIDTH,
	MOBILE_BLOCK_PADDING_X,
} from '@zyro-inc/site-modules/components/blocks/layout/constants';
import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';

import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';

import {
	Element,
	ElementPositionKey,
} from '@/types/elementTypes';

import {
	getLowerElementsRelativeToActive,
	getElementsBelowActiveElementPositions,
	getUpdatedElementsPosition,
} from '@/utils/layout';
import { useSiteStore } from '@/stores/siteStore';

interface SiteElement extends Element {
  id: string;
}
type SiteElementsHeight = Record<string, { height: number }>;
type SiteElementsHeightsOnDevices = Record<string, {
	desktop?: { height: number, originalHeight?: number, heightDifference?: number },
	mobile?: { height: number, originalHeight?: number, heightDifference?: number },
}>;

const HEIGHT_DIFFERENCE_THRESHOLD = 5;

export async function measureTextElementsHeights({
	elementPositionKey,
	websiteData,
	siteTextElements,
	fontFamiliesToAwait,
}: {
	elementPositionKey: ElementPositionKey,
	websiteData: Record<string, any>,
	siteTextElements: SiteElement[],
	fontFamiliesToAwait: string[]
}): Promise<SiteElementsHeight> {
	// Create a container element
	const styles: Record<string, any> = objectToCssVariables(websiteData?.styles);
	const container = document.createElement('div');

	container.className = 'site-text-elements-preview';

	const isMobile = elementPositionKey === ELEMENT_POSITION_KEY_MOBILE;

	if (isMobile) {
		container.classList.add('zyro-mb-preview');
		container.style.width = `${MOBILE_BLOCK_WIDTH}px`;
		container.style.padding = `0 ${MOBILE_BLOCK_PADDING_X}px`;
	} else {
		container.style.width = `${DESKTOP_BLOCK_WIDTH}px`;
	}

	container.style.position = 'absolute';
	container.style.visibility = 'hidden';

	Object.keys(styles).forEach((style: string) => {
		container.style.setProperty(style, styles[style]);
	});

	document.body.appendChild(container);

	const fragment = document.createDocumentFragment();

	// Loop through text elements
	siteTextElements.forEach((textElement) => {
		// Create a node element
		const node = document.createElement('div');

		node.classList.add('text-box');
		node.dataset.elementId = textElement.id;
		node.style.width = `${textElement[elementPositionKey].width}px`;

		// Apply innerHTML and styles
		node.innerHTML = textElement.content;

		// Apply text styles
		Object.assign(node.style, textElement.settings.styles);

		node.style.setProperty('--white-space-preview', textElement.settings.styles.text === 'justify' ? 'normal' : 'break-spaces');
		node.style.setProperty('--white-space-mobile-preview', textElement.settings.styles?.['m-text'] === 'justify' ? 'normal' : 'break-spaces');

		// Append the node to the fragment
		fragment.appendChild(node);
	});

	// Append the fragment to the container
	container.appendChild(fragment);

	if (fontFamiliesToAwait.length) {
		await Promise.allSettled(fontFamiliesToAwait.map((fontFamily) => document.fonts.load(`1em "${fontFamily}"`)));
	}

	const measuredTextElementsHeights = Object.fromEntries(siteTextElements.map((textElement, index) => {
		const node = container.children[index];
		const { height } = node.getBoundingClientRect();

		return [
			textElement.id,
			{
				height: Math.round(height),
			},
		];
	}));

	// Remove the container from the document
	document.body.removeChild(container);

	return measuredTextElementsHeights;
}

export const getElementsWithWrongHeight = ({
	originalTextElementsHeights,
	measuredTextElementsHeights,
	elementPositionKey,
}: {
	originalTextElementsHeights: SiteElementsHeightsOnDevices,
	measuredTextElementsHeights: SiteElementsHeight,
	elementPositionKey: ElementPositionKey
}) => Object.fromEntries(Object.keys(measuredTextElementsHeights).flatMap((elementId) => {
	const originalHeight = originalTextElementsHeights[elementId]?.[elementPositionKey]?.height || 0;
	const measuredHeight = measuredTextElementsHeights[elementId].height;

	if ((measuredHeight - originalHeight) > HEIGHT_DIFFERENCE_THRESHOLD) {
		return [
			[
				elementId,
				{
					[elementPositionKey]: {
						height: measuredHeight,
						originalHeight,
						heightDifference: originalHeight - measuredHeight,
					},
				},
			],
		];
	}

	return [];
}));

export const getBlocksWithElementsToUpdate = ({
	siteBlocks,
	elementsToUpdate,
}: {
	siteBlocks: any,
	elementsToUpdate: SiteElementsHeightsOnDevices
}) => Object.fromEntries(Object.keys(siteBlocks).flatMap((blockId) => {
	const block = siteBlocks[blockId];

	if (!block || !block.components) {
		return [];
	}

	const shouldUpdateBlock = block.components.some((elementId: string) => Object.keys(elementsToUpdate).includes(elementId));

	if (shouldUpdateBlock) {
		return [
			[
				blockId,
				{
					...block,
				},
			],
		];
	}

	return [];
}));

export function useSiteTextElementPreview() {
	const siteStore = useSiteStore();

	const {
		getters,
		dispatch,
	} = useStore();
	const websiteDataClone = ref<Record<string, any>>({});

	const siteElements = computed(() => getters.siteElements);
	const siteBlocks = computed(() => getters.siteBlocks);
	const websiteData = computed(() => siteStore.site);
	const siteElementArray = computed<SiteElement[]>(() => Object.keys(siteElements.value).map((elementId) => ({
		...siteElements.value[elementId],
		id: elementId,
	})));
	const siteTextElements = computed<SiteElement[]>(
		() => siteElementArray.value.filter((element) => element.type === ELEMENT_TYPE_TEXT_BOX),
	);

	const originalTextElementsHeights = computed(() => Object.fromEntries(siteTextElements.value
		.map((textElement) => [
			textElement.id,
			{
				[ELEMENT_POSITION_KEY_DESKTOP]: {
					height: textElement[ELEMENT_POSITION_KEY_DESKTOP].height,
				},
				[ELEMENT_POSITION_KEY_MOBILE]: {
					height: textElement[ELEMENT_POSITION_KEY_MOBILE].height,
				},
			},
		])));

	async function createAndMeasureNodes({
		elementPositionKey,
		fontFamiliesToAwait,
	}:{
		elementPositionKey: ElementPositionKey,
		fontFamiliesToAwait: string[]
	}): Promise<void> {
		const measuredTextElementsHeights = await measureTextElementsHeights({
			elementPositionKey,
			websiteData: websiteData.value,
			siteTextElements: siteTextElements.value,
			fontFamiliesToAwait,
		});

		const elementsToUpdate = getElementsWithWrongHeight({
			originalTextElementsHeights: originalTextElementsHeights.value,
			measuredTextElementsHeights,
			elementPositionKey,
		});

		const blocksToUpdate = getBlocksWithElementsToUpdate({
			siteBlocks: siteBlocks.value,
			elementsToUpdate,
		});

		Object.keys(blocksToUpdate).forEach((blockId) => {
			const elementIdsToUpdateHeight = blocksToUpdate[blockId].components
				.filter((blockElementId: string) => Object.keys(elementsToUpdate).includes(blockElementId));

			elementIdsToUpdateHeight.forEach((elementId: string) => {
				const activeElementPosition = elementsToUpdate[elementId];
				const blockElements = blocksToUpdate[blockId].components
					.map((blockElementId: string) => ({
						...websiteDataClone.value.languages.system.elements[blockElementId],
						elementId: blockElementId,
					}));

				const lowerElements = getLowerElementsRelativeToActive({
					layoutElements: blockElements,
					activeElementId: elementId,
					elementPositionKey,
					isElementWithTheSameTopIncluded: false,
				});

				const elementsBelowActiveElementPositions = getElementsBelowActiveElementPositions({
					topOffset: activeElementPosition[elementPositionKey].heightDifference,
					elementPositionKey,
					lowerElementsRelativeToActive: lowerElements,
				});

				const blockElementsWithUpdatedPositions = getUpdatedElementsPosition({
					elementsPositions: {
						[elementId]: {
							...websiteDataClone.value.languages.system.elements[elementId][elementPositionKey],
							height: activeElementPosition[elementPositionKey].height,
						},
						...elementsBelowActiveElementPositions,
					},
					elementPositionKey,
				});

				const updatedBlockElements = Object.fromEntries(Object.keys(blockElementsWithUpdatedPositions).map((elementToUpdateId) => [
					elementToUpdateId,
					{
						...websiteDataClone.value.languages.system.elements[elementToUpdateId],
						...blockElementsWithUpdatedPositions[elementToUpdateId],
					},
				]));

				websiteDataClone.value.languages.system.elements = {
					...websiteDataClone.value.languages.system.elements,
					...updatedBlockElements,
				};

				const block = websiteDataClone.value.languages.system.blocks[blockId];
				const updatedBlockHeight = block[elementPositionKey].minHeight
					+ Math.abs(activeElementPosition[elementPositionKey].heightDifference);

				websiteDataClone.value.languages.system.blocks[blockId] = {
					...block,
					[elementPositionKey]: {
						...block[elementPositionKey],
						minHeight: updatedBlockHeight,
					},
				};
			});
		});
	}

	async function recalculateWebsiteTextHeights({ fontFamiliesToAwait = [] }:{fontFamiliesToAwait?: string[]} = {}) {
		websiteDataClone.value = JSON.parse(JSON.stringify(websiteData.value));

		await createAndMeasureNodes({
			elementPositionKey: ELEMENT_POSITION_KEY_DESKTOP,
			fontFamiliesToAwait,
		});
		await createAndMeasureNodes({
			elementPositionKey: ELEMENT_POSITION_KEY_MOBILE,
			fontFamiliesToAwait,
		});

		dispatch('overwriteWebsiteData', {
			websiteData: websiteDataClone.value,
		});
	}

	return {
		recalculateWebsiteTextHeights,
	};
}
