<template>
	<div
		ref="headerContainerRef"
		class="block-header-provider-builder"
		:class="{ 'block-header-provider-builder--is-preview-mode': isPreviewMode }"
	>
		<BlockHeader
			id="header"
			ref="headerRef"
			v-bind="headerProps"
			@toggle-visibility="isNavOpen = !isNavOpen"
			@click="handleHeaderClick"
			@mouseover="handleBlockHover({ blockId: 'header' })"
			@logo-click="handleLogoClick"
			@cart-click="handleShoppingCartOpen"
		/>

		<template v-if="!isPreviewMode">
			<BlockControls
				v-qa="'builder-section-navigation'"
				block-id="header"
				:target-ref="headerContainerRef"
				:is-first-block="true"
				:section-title="$t('common.header')"
			/>

			<BlockEditControls
				block-id="header"
				:target-ref="headerContainerRef"
				:is-context-menu-button-visible="false"
				:enter-edit-mode-button-title="t('builder.editBlockButton.editHeader')"
				:controls-bar-popup-options="{
					placement: 'bottom-end',
					autoUpdate: true,
					flip: false,
				}"
				:edit-popup-options="{
					placement: 'bottom-end',
					autoUpdate: true,
					flip: false,
				}"
			>
				<template #additional-edit-buttons>
					<VerticalSeparator />
					<ControlsTooltip
						v-if="!isAiBuilderMode"
						:title="$t('builder.editBlockButton.editNavigation')"
					>
						<HostingerButton
							v-if="!isAiBuilderMode"
							v-qa="'builder-section-btn-editnavigation'"
							button-type="plain"
							theme="highlight"
							@click="handleOpenDrawer"
						>
							<template #icon>
								<Icon name="layers" />
							</template>
						</HostingerButton>
					</ControlsTooltip>

					<ControlsTooltip
						v-if="isSiteWithStore"
						:title="$t('builder.editBlockButton.editShoppingCart')"
					>
						<HostingerButton
							v-qa="'builder-section-btn-editcart'"
							button-type="plain"
							theme="highlight"
							:title="$t('builder.editBlockButton.editShoppingCart')"
							@click="handleBlockEditButtonClick({ tabId: 'cart' })"
						>
							<template #icon>
								<Icon name="shopping_bag" />
							</template>
						</HostingerButton>
					</ControlsTooltip>
					<ControlsTooltip :title="$t('builder.editBlockButton.editStyles')">
						<HostingerButton
							v-qa="'builder-section-btn-editstyles'"
							button-type="plain"
							theme="highlight"
							:title="$t('builder.editBlockButton.editStyles')"
							@click="handleBlockEditButtonClick({ tabId: 'style' })"
						>
							<template #icon>
								<Icon name="palette" />
							</template>
						</HostingerButton>
					</ControlsTooltip>
				</template>

				<template #edit-mode-popup>
					<EditBlockHeaderTabs
						class="block-header-provider-builder__edit-tabs"
						:style="{ '--header-height-on-popup-open': `${headerHeightOnPopupOpen}px` }"
						@update:current-tab-id="currentTabId = $event"
						@close="closeBlockEditPopupHandler"
						@vue:mounted="setHeaderHeightOnPopupOpen"
					/>
				</template>
			</BlockEditControls>
		</template>
	</div>
</template>

<script setup>
import {
	computed,
	ref,
	onMounted,
	onBeforeUnmount,
	watch,
	nextTick,
} from 'vue';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import Icon from '@/components/global/Icon.vue';
import VerticalSeparator from '@/components/global/VerticalSeparator.vue';
import BlockControls from '@/components/block/BlockControls.vue';
import BlockEditControls from '@/components/builder-controls/BlockEditControls.vue';
import BlockHeader from '@zyro-inc/site-modules/components/BlockHeader.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import EditBlockHeaderTabs from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabs.vue';
import { getHeaderProps } from '@zyro-inc/site-modules/components/blocks/header/getHeaderProps';
import { getPagePathFromId } from '@zyro-inc/site-modules/utils/page/getPagePathFromId';
import { getPageIdFromPath } from '@zyro-inc/site-modules/utils/page/getPageIdFromPath';
import { getPathParams } from '@zyro-inc/site-modules/utils/page/getPathParams';
import { getLanguageSwitcherLanguages } from '@zyro-inc/site-modules/utils/getLanguageSwitcherLanguages';
import {
	EDIT_BLOCK_NAVIGATION_TAB_ID_LOGO,
	DRAWER_MULTI_PAGE,
} from '@/constants/builderConstants';
import { useBuilderMode } from '@/use/useBuilderMode';
import { useHoveredBlock } from '@/use/useHoveredBlock';
import { OPEN_DRAWER } from '@/store/builder/gui';
import { useSiteStore } from '@/stores/siteStore';
import {
	ELEMENT_POSITION_KEY_DESKTOP,
	ELEMENT_POSITION_KEY_MOBILE,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { useNotifications } from '@/use/useNotifications';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { getIsDynamicProductPageEnabled } from '@zyro-inc/site-modules/utils/ecommerce/siteModulesUtils';
import DOMPurify from 'dompurify';

const {
	state,
	getters,
	dispatch,
} = useStore();

const { t } = useI18n();

const props = defineProps({
	allBlocks: {
		type: Object,
		required: true,
	},
	isPreviewMode: {
		type: Boolean,
		required: false,
	},
});

const ecommerceStore = useEcommerceStore();

const { notify } = useNotifications();
const siteStore = useSiteStore();
const { isAiBuilderMode } = useBuilderMode();
const { handleBlockHover } = useHoveredBlock();

const headerRef = ref(null);
const headerContainerRef = ref(null);
const isNavOpen = ref(false);
const currentTabId = ref(null);
const headerHeightOnPopupOpen = ref(0);

const handleShoppingCartOpen = () => {
	if (!props.isPreviewMode) {
		return;
	}

	notify({
		messageI18nKeyPath: 'onlineStore.checkoutModalDisabledPreview',
		theme: 'info',
	});
};

const website = computed(() => siteStore.site);
const websiteId = computed(() => state.websiteId);
const siteBlocks = computed(() => getters.siteBlocks);
const currentLocale = computed(() => state.currentLocale);
const currentPageId = computed(() => state.currentPageId);
const isMobileMode = computed(() => getters['gui/isMobileMode']);
const isHeaderVisible = computed(() => !getters.isNavHidden);
const isSiteWithStore = computed(() => getters['ecommerce/isSiteWithEcommerceItems']);
const isFirstPageBlockHidden = computed(() => {
	const firstPageBlock = siteBlocks.value[props.allBlocks[0]];

	if (!firstPageBlock) {
		return false;
	}

	const isDesktopFirstBlockHidden = !isMobileMode.value && firstPageBlock.desktop?.isHidden;
	const isMobileFirstBlockHidden = isMobileMode.value && firstPageBlock.mobile?.isHidden;

	return isDesktopFirstBlockHidden || isMobileFirstBlockHidden;
});

const headerProps = computed(() => {
	const {
		blocks,
		nav,
		pages,
		elements,
		metaTitle,
	} = website.value.languages[currentLocale.value];

	const headerPropsRaw = getHeaderProps({
		siteId: websiteId.value,
		meta: website.value.meta,
		blocks,
		nav,
		pages,
		elements,
		languageMetaTitle: metaTitle,
		languageSwitcherLanguages: getLanguageSwitcherLanguages({
			languages: website.value.languages,
			defaultLocale: website.value.meta.defaultLocale,
		}),
		currentLocale: currentLocale.value,
		currentPageId: currentPageId.value,
		shoppingCartItemCount: 0,
		getPagePathFromId: ({ pageId }) => getPagePathFromId({
			siteData: website.value,
			pageId,
			locale: currentLocale.value,
		}),
		isOpen: isNavOpen.value || (isMobileMode.value && !!currentTabId.value),
	});

	const sanitizedSocialIconsLinks = headerPropsRaw.socialIconsLinks?.map((link) => ({
		...link,
		svg: DOMPurify.sanitize(link.svg),
	}));

	return {
		...headerPropsRaw,
		socialIconsLinks: sanitizedSocialIconsLinks,
		// Disable header transparency in editor if first block is hidden - it won't work.
		isTransparent: !isFirstPageBlockHidden.value && headerPropsRaw.isTransparent,
		// Reset logo href in editor since on click, we open edit controls for it
		logoHref: props.isPreviewMode ? headerPropsRaw.logoHref : null,
	};
});
const headerHeight = computed(() => headerProps.value[isMobileMode.value ? 'heightMobile' : 'height']);

const handleOpenDrawer = () => {
	dispatch(`gui/${OPEN_DRAWER}`, {
		id: DRAWER_MULTI_PAGE,
	});
};

const handleBlockEditButtonClick = ({ tabId }) => {
	dispatch('enterBlockEditMode');
	dispatch('leaveElementEditMode');
	dispatch('setDefaultBlockEditTab', tabId || '');
};

const handleHeaderClick = async (event) => {
	dispatch('updateCurrentBlockId', 'header');
	dispatch('unselectCurrentElement');

	const closestAnchor = event.target.closest('a');

	if (!closestAnchor) {
		return;
	}

	if (props.isPreviewMode && closestAnchor.classList.contains('social-icons__link')) {
		isNavOpen.value = false;

		return;
	}

	event.preventDefault();

	const href = closestAnchor.getAttribute('href');

	if (props.isPreviewMode && (href.includes('http://') || href.includes('https://'))) {
		window.open(href, '_blank');
	}

	if (!href) {
		return;
	}

	// Removing parameters that are added to the link;
	const path = href.split('?')[0];
	const [pageLink, anchor] = path.split('#');

	const isDynamicProductPageEnabled = getIsDynamicProductPageEnabled({
		defaultLocalePages: website.value?.languages[website.value.meta.defaultLocale]?.pages,
	});

	const pageId = getPageIdFromPath({
		siteData: website.value,
		path: pageLink,
		products: ecommerceStore.productsSeo,
		isDynamicProductPageEnabled,
	});

	const {
		locale,
		slug,
	} = getPathParams({
		path: pageLink,
		languageKeys: Object.keys(website.value.languages),
		defaultLocale: website.value.meta.defaultLocale,
	});

	if (anchor) {
		dispatch('updateCurrentPageId', pageId);

		const blockId = Object.entries(siteBlocks.value).find(([, { htmlId }]) => htmlId === anchor)?.[0];

		await nextTick();

		const element = document.querySelector(`[data-block-id="${blockId}"]`);

		if (!element) {
			return;
		}

		window.requestAnimationFrame(() => {
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		});

		return;
	}

	if (locale !== currentLocale.value) {
		dispatch('updateCurrentLocale', locale);
	}

	if (pageId) {
		dispatch('updateCurrentPageSlug', slug);
		dispatch('updateCurrentPageId', pageId);
	}

	if (props.isPreviewMode) {
		isNavOpen.value = false;
	}
};

const setHeaderHeightOnPopupOpen = () => {
	headerHeightOnPopupOpen.value = headerHeight.value + (document.querySelector('.block-sticky-bar')?.clientHeight ?? 0);
};

const resizeObserver = new ResizeObserver(([{ contentRect }]) => {
	requestAnimationFrame(() => {
		const { height } = contentRect;

		if (!headerHeight.value || headerHeight.value !== height) {
			dispatch('updateBlockData', {
				blockId: 'header',
				blockData: {
					[isMobileMode.value ? ELEMENT_POSITION_KEY_MOBILE : ELEMENT_POSITION_KEY_DESKTOP]: {
						height: Math.ceil(height),
					},
				},
				merge: true,
			});
		}

		// If header height was not set before, save the website so you can close the tab
		if (!headerHeight.value && !isAiBuilderMode.value) {
			dispatch('saving/saveWebsite');
		}
	});
});

const closeBlockEditPopupHandler = () => {
	dispatch('leaveBlockEditMode');
	currentTabId.value = null;
};

const handleLogoClick = () => {
	dispatch('updateCurrentBlockId', 'header');
	dispatch('setDefaultBlockEditTab', EDIT_BLOCK_NAVIGATION_TAB_ID_LOGO);
	dispatch('enterBlockEditMode');
};

onMounted(() => {
	if (headerRef.value?.$el) {
		resizeObserver.observe(headerRef.value.$el);
	}
});

onBeforeUnmount(() => {
	resizeObserver.disconnect();
});

watch(isHeaderVisible, async (newValue) => {
	if (newValue) {
		await nextTick();

		resizeObserver.observe(headerRef.value.$el);
	} else {
		resizeObserver.disconnect();
	}
});

watch(currentTabId, (newValue) => {
	if (isMobileMode.value) {
		isNavOpen.value = !!newValue;
	}
});
</script>

<style lang="scss" scoped>
@mixin highlight-outline {
    outline: 2px solid $color-azure;
}

.block-header-provider-builder {
	$this: &;

	position: relative;
	height: 100%;

	&:not(#{$this}--is-preview-mode) {
		:deep(.block-header-logo__image) {
			&:hover {
				@include highlight-outline;
			}
		}
	}

	&__edit-tabs {
		--popup-top-height: 95px;

		:deep(.popup-card__content) {
			max-height: calc(100vh - $header-height-editor - var(--header-height-on-popup-open) - var(--popup-top-height));
			min-height: 150px;
			overflow-y: auto;
			overflow-x: hidden;
			scrollbar-width: thin;
		}
	}
}
</style>
