<template>
	<hr class="vertical-separator">
</template>

<style lang="scss" scoped>
.vertical-separator {
	margin: 0 4px;
	width: 1px;
	height: 20px;
	background-color: $color-gray-border;
	border: 0;
}
</style>
