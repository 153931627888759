<script lang="ts" setup>
import SkeletonLoader from '@/components/sekeleton-loaders/base/SkeletonLoader.vue';

interface Props {
	itemCount: number;
}

withDefaults(defineProps<Props>(), {
	itemCount: 2,
});

const getRandomWidth = (currentLength: number, index: number) => {
	if (Math.random() >= 0.5) {
		return `${currentLength + 15 * index}px`;
	}

	return `${currentLength - 15 * index}px`;
};
</script>

<template>
	<ul class="loader">
		<li
			v-for="index in itemCount"
			:key="index"
			class="loader__item"
		>
			<SkeletonLoader
				height="24px"
				:width="getRandomWidth(100, index)"
			/>
			<SkeletonLoader
				height="24px"
				:width="getRandomWidth(60, index)"
			/>
		</li>
	</ul>
</template>

<style lang="scss" scoped>
.loader {
	$this: &;

	list-style: none;
	padding: 15px 16px;
	border-radius: 4px;
	border: 1px solid var(--color-gray-border);
	margin-top: 16px;

	&__item {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;

		&:nth-last-child(1) {
			margin-bottom: 0;
		}
	}
}
</style>
