export const setObjectValue = (object: any, path: string, value: any) => {
	const parts = path.split('.');
	const { length } = parts;

	parts.reduce(
		// eslint-disable-next-line no-return-assign, no-param-reassign
		(previousVal, currentVal, index) => previousVal[currentVal] = index === length - 1 ? value : previousVal[currentVal] || {},
		object,
	);

	return object;
};
