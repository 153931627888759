<script setup lang="ts">
import {
	ref,
	ComponentPublicInstance,
} from 'vue';

import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroToggle from '@/components/global/ZyroToggle.vue';
import Popup from '@/components/global/Popup.vue';

type Props = {
	isHiddenDesktop: boolean;
	isHiddenMobile: boolean;
};

defineProps<Props>();

defineEmits([
	'set-desktop-visibility',
	'set-mobile-visibility',
]);

const visibilityControls = ref<ComponentPublicInstance | null>(null);
const showVisibilitySettings = ref(false);
</script>

<template>
	<div class="visibility">
		<HostingerButton
			ref="visibilityControls"
			v-qa="'visibility-controls-open-settings'"
			button-type="plain"
			theme="highlight"
			:title="$t('common.visibility')"
			@click="showVisibilitySettings = !showVisibilitySettings"
		>
			<template #icon>
				<Icon name="visibility" />
			</template>
		</HostingerButton>
		<Popup
			v-if="showVisibilitySettings"
			:target-ref="visibilityControls && visibilityControls?.$el"
			placement="bottom"
			auto-update
			@click-outside="showVisibilitySettings = false"
		>
			<div class="visibility__controls">
				<p class="visibility__title">
					{{ $t('common.visibility') }}:
				</p>
				<div class="visibility__control">
					<div class="visibility__control-text-wrapper">
						<p class="visibility__control-title text-bold-2">
							{{ $t('common.mobile') }}
						</p>
						<p class="visibility__control-status text-body-2">
							{{ isHiddenMobile ? $t('common.hidden') : $t('common.visible') }}
						</p>
					</div>
					<ZyroToggle
						id="visibility-settings-mobile"
						v-qa="'visibility-controls-setmobilevisibility'"
						:model-value="!isHiddenMobile"
						@update:model-value="$emit('set-mobile-visibility', !isHiddenMobile)"
					/>
				</div>
				<div class="visibility__control">
					<div class="visibility__control-text-wrapper">
						<p class="visibility__control-title text-bold-2">
							{{ $t('common.desktop') }}
						</p>
						<p class="visibility__control-status text-body-2">
							{{ isHiddenDesktop ? $t('common.hidden') : $t('common.visible') }}
						</p>
					</div>
					<ZyroToggle
						id="visibility-settings-desktop"
						v-qa="'visibility-controls-setdesktopvisibility'"
						:model-value="!isHiddenDesktop"
						@update:model-value="$emit('set-desktop-visibility', !isHiddenDesktop)"
					/>
				</div>
			</div>
		</Popup>
	</div>
</template>

<style lang="scss" scoped>
.visibility {
	&__controls {
		width: 220px;
		padding: 16px;
		background-color: $color-light;
		border-radius: $border-radius-small;
		box-shadow: 0 6px 14px rgba(0, 0, 0, 10%);
	}

	&__title {
		margin-bottom: 16px;
		font-size: 11px;
		line-height: 1.27;
		color: $color-gray;
		text-transform: uppercase;
	}

	&__control {
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	&__control-text-wrapper {
		display: flex;
	}

	&__control-title {
		margin-right: 4px;
	}

	&__control-status {
		color: $color-gray;
	}
}
</style>
