<template>
	<!-- This modal's code is copied from EditOnlineStoreModal.vue (reused, because another one was too big) -->
	<ZyroModal
		max-width="458px"
		max-height="auto"
		height="auto"
		width="auto"
		@close-modal="handleModalClose"
	>
		<div class="edit-store">
			<HImage
				src="/images/store-upgradeSite.png"
				:alt="$t('builder.editOnlineStoreModal.upgradeSite.title')"
				class="edit-store__image"
			/>
			<h5 class="text-heading-1 z-font-weight-normal edit-store__title">
				{{ $t('builder.editOnlineStoreModal.upgradeSite.title') }}
			</h5>
			<div class="edit-store__text">
				{{ $t('builder.ecommerceUpgradeSite') }}
			</div>
			<HostingerButton
				v-qa="`builder-ecommercestoremanager-modal-btn-upgrade`"
				size="lg"
				@click="redirectToWWWPayments"
			>
				{{ $t('builder.editOnlineStoreModal.upgradeSite.btn') }}
			</HostingerButton>
		</div>
	</ZyroModal>
</template>

<script>
import HostingerButton from '@/components/global/HostingerButton.vue';
import HImage from '@/components/global/HImage.vue';
import ZyroModal from '@/components/global/ZyroModal.vue';

import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { useRedirects } from '@/use/useRedirects';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		HostingerButton,
		ZyroModal,
		HImage,
	},

	setup() {
		const { redirectToWWWPayments } = useRedirects();

		return {
			redirectToWWWPayments,
		};
	},

	methods: {
		...mapActionsGui({
			closeModal: CLOSE_MODAL,
		}),
		handleModalClose() {
			this.closeModal();
		},
	},
});
</script>

<style lang="scss" scoped>
.edit-store {
	padding: 62px 4px 32px;
	text-align: center;

	&__image {
		display: inline-block;
		width: 100%;
		margin: 28px 0;
	}

	&__text {
		padding: 4px 0 24px;
		letter-spacing: 0.032em;
	}
}
</style>
