import { useStore } from 'vuex';

export const useShape = () => {
	const { dispatch } = useStore();

	const updateShapeColor = (color: string) => {
		dispatch('mergeCurrentElementData', {
			elementData: {
				color,
			},
		});
	};

	return {
		updateShapeColor,
	};
};
