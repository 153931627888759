<script setup lang="ts">
import ZyroIconSelect from '@/components/global/ZyroIconSelect.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroRange from '@/components/global/ZyroRange.vue';
import { useProductImageRatio } from '@/use/useProductImageRatio';
import cloneDeep from 'lodash.clonedeep';
import { useStore } from 'vuex';
import EditBlockPadding from '@/components/builder-controls/reusable-controls/block/EditBlockPadding.vue';
import { useI18n } from 'vue-i18n';
import { ImageRatioOption } from '@zyro-inc/site-modules/types';

import {
	computed,
	onBeforeMount,
	onMounted,
	ref,
} from 'vue';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';

interface Props {
	blockId: string;
}

const props = defineProps<Props>();

const { t } = useI18n();
const {
	getters,
	dispatch,
} = useStore();
const {
	IMAGE_RATIO_OPTIONS,
	updateImageRatio,
	imageRatio,
} = useProductImageRatio({
	blockId: props.blockId,
});

const currentBlock = computed(() => getters.currentBlock);
const currentBlockStyles = computed(() => getters.currentBlockStyles);
const textAlignment = computed(() => currentBlockStyles.value?.textAlign || 'left');
const galleryPlacement = computed(() => currentBlock.value.galleryPlacement || 'bottom');
const borderRadiusForImage = computed(() => Number.parseFloat(currentBlock.value.imageBorderRadius || 0));

const initialCurrentBlockData = ref(null);

const TEXT_ALIGNMENT_OPTIONS = {
	left: {
		name: t('common.left'),
		icon: 'layout-left',
		iconDimensions: '56px',
	},
	center: {
		name: t('common.center'),
		icon: 'layout-center',
		iconDimensions: '56px',
	},
};
const GALLERY_PLACEMENT_OPTIONS = {
	bottom: {
		name: t('common.bottom'),
		icon: 'gallery-bottom',
		iconDimensions: '32px',
	},
	left: {
		name: t('common.left'),
		icon: 'gallery-left',
		iconDimensions: '32px',
	},
};

const updateTextAlignment = (newValue: string) => {
	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			settings: {
				styles: {
					textAlign: newValue,
				},
			},
		},
		merge: true,
	});
};

const updateBlockDataByKey = (key: string, value: string) => {
	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			[key]: value,
		},
		merge: true,
	});
};

const updateBorderRadiusForImage = (newValue: number) => {
	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			imageBorderRadius: `${newValue || 0}%`, // OR needed for input field to display correct value
		},
		merge: true,
	});
};

onMounted(() => {
	initialCurrentBlockData.value = cloneDeep(currentBlock.value);
});

onBeforeMount(() => {
	dispatch('undoRedo/createSnapshot');
});
</script>

<template>
	<div class="ecommerce-tab-layout">
		<ZyroLabel>{{ $t('builder.ecommerceSectionTextAlignment') }}</ZyroLabel>
		<ZyroIconSelect
			:model-value="textAlignment"
			:options="TEXT_ALIGNMENT_OPTIONS"
			:items-per-row="3"
			icon-container-padding="4px 12px"
			class="ecommerce-tab-layout__controls ecommerce-tab-layout__controls--grey"
			group-name="text-alignment"
			active-icon-color="var(--color-azure)"
			active-background-color="var(--color-azure-lighter)"
			@update:model-value="updateTextAlignment"
		/>
		<ZyroLabel>{{ $t('builder.ecommerceSectionGalleryPlacement') }}</ZyroLabel>
		<ZyroIconSelect
			:model-value="galleryPlacement"
			:options="GALLERY_PLACEMENT_OPTIONS"
			:items-per-row="3"
			icon-container-padding="15px 24px"
			class="ecommerce-tab-layout__controls ecommerce-tab-layout__controls--grey"
			group-name="text-alignment"
			active-icon-color="var(--color-azure)"
			active-background-color="var(--color-azure-lighter)"
			@update:model-value="updateBlockDataByKey('galleryPlacement', $event)"
		/>
		<ZyroLabel>
			{{ $t('builder.zyroEcommerceImageRatio') }}
		</ZyroLabel>
		<ZyroIconSelect
			:model-value="imageRatio"
			:options="IMAGE_RATIO_OPTIONS"
			:items-per-row="3"
			options-grid-gap="8px"
			icon-container-padding="19px 20px"
			class="ecommerce-tab-layout__controls"
			group-name="image-ratio"
			active-icon-color="var(--color-azure)"
			active-background-color="var(--color-azure-lighter)"
			@update:model-value="updateImageRatio($event)"
		/>
		<ZyroSeparator />
		<div
			v-if="imageRatio !== ImageRatioOption.CONTAIN"
			class="ecommerce-tab-layout__image-border"
		>
			<ZyroLabel>
				{{ $t('builder.imageCornerRadius') }}
			</ZyroLabel>
			<ZyroRange
				:model-value="borderRadiusForImage"
				class="ecommerce-tab-layout__controls ecommerce-tab-layout__controls--range"
				min="0"
				units="%"
				has-number-input
				step="1"
				max="50"
				@update:model-value="updateBorderRadiusForImage"
			/>
		</div>
		<EditBlockPadding :class="{ 'ecommerce-tab-layout__block-padding': imageRatio !== ImageRatioOption.CONTAIN }" />
	</div>
</template>

<style lang="scss" scoped>
.ecommerce-tab-layout {
	--select-background: #{$color-light};

	&__controls {
		margin: 8px 0 24px;

		&:last-child {
			margin-bottom: 0;
		}

		&--grey {
			color: $color-gray;
		}
	}

	&__image-border {
		margin-top: 24px;
	}

	&__block-padding {
		margin-top: 8px;
	}
}

:deep() {
	.zyro-range {
		&__input-wrapper::after {
			top: 0;
			margin-left: 5px;
			content: "%";
		}
	}
}
</style>
