import {
	computed,
	onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { addBreadcrumb } from '@sentry/vue';

export const useElementEditPopup = (props) => {
	const {
		dispatch,
		getters,
	} = useStore();
	const siteElements = computed(() => getters.siteElements);
	const elementData = computed(() => siteElements.value[props.elementId]);
	const elementType = computed(() => elementData.value?.type);

	const closeElementEditPopupHandler = () => {
		addBreadcrumb({
			category: 'CONTROLS:ELEMENT',
			message: 'Close',
			data: {
				currentElementId: props.elementId,
				type: elementType.value,
			},
			level: 'debug',
			type: 'debug',
		});

		dispatch('leaveElementEditMode');
		dispatch('undoRedo/createSnapshot');
	};

	onMounted(() => {
		addBreadcrumb({
			category: 'CONTROLS:ELEMENT',
			message: 'Created',
			data: {
				currentElementId: props.elementId,
				type: elementType.value,
			},
			level: 'debug',
			type: 'debug',
		});
	});

	return {
		closeElementEditPopupHandler,
	};
};
