import { BEARER_TOKEN } from '@/constants/builderConstants';
import { IJwtPayload } from '@/types/authTypes';

export const AUTH_TOKEN_PREFIX = 'Bearer ' as const;

export const getAuthToken = () => localStorage.getItem(BEARER_TOKEN);
export const getBearerToken = () => `${AUTH_TOKEN_PREFIX}${getAuthToken()}`;
export const setAuthToken = (token: string) => localStorage.setItem(BEARER_TOKEN, token);

export const getParsedToken = () => {
	const token = getAuthToken();

	if (!token) {
		return {} as IJwtPayload;
	}

	const base64Url = token.split('.')[1];

	if (!base64Url) {
		return {} as IJwtPayload;
	}

	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		[
			...window
				.atob(base64),
		]
			.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
			.join(''),
	);

	return JSON.parse(jsonPayload) as IJwtPayload;
};
