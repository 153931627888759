<template>
	<ZyroFieldToggle
		id="header-social-icons-toggle"
		:model-value="isSocialIconsEnabled"
		:label="$t('common.socialIcons')"
		@update:model-value="updateIsSocialIconsEnabled"
	/>

	<template v-if="isLinksSettingsVisible">
		<EditSocialIconsTabLinks
			class="header-social-icons__links"
			:element-id="headerSocialIconsComponentId"
		/>

		<ZyroSegmentControl
			class="header-social-icons__segment-control"
			:controls="socialIconsStates"
			:active-control="currentSocialIconsState"
			@update:active-control="updateCurrentSocialIconsState"
		/>

		<ZyroFieldRange
			v-if="!isHoverState"
			class="header-social-icons__icon-size"
			:model-value="iconSize"
			type="number"
			min="10"
			step="1"
			max="40"
			:label="$t('builder.editSocialIcons.tabIcon.size')"
			@update:model-value="updateIconSize"
		/>

		<ZyroFieldColorPicker
			:is-open="isColorPickerOpen"
			:color="iconColor"
			:label="$t('builder.editSocialIcons.tabIcon.color')"
			@toggle="isColorPickerOpen = !isColorPickerOpen"
			@click-outside="isColorPickerOpen = false"
			@update-color="updateIconColor"
		/>
	</template>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import ZyroSegmentControl from '@/components/global/ZyroSegmentControl.vue';
import { useStore } from 'vuex';
import {
	computed,
	ref,
} from 'vue';
import EditSocialIconsTabLinks from '@/components/builder-controls/edit-social-icons/EditSocialIconsTabLinks.vue';
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import { useAddElement } from '@/use/useAddElement';
import ZyroFieldRange from '@/components/global/ZyroFieldRange.vue';
import ZyroFieldColorPicker from '@/components/global/ZyroFieldColorPicker.vue';
import { ELEMENT_TYPE_SOCIAL_ICONS } from '@zyro-inc/site-modules/constants/siteModulesConstants';

const {
	getters,
	dispatch,
} = useStore();

const { t } = useI18n();
const { defaultElements } = useAddElement();

const socialIconsStates = [
	{
		id: 'normal',
		title: t('builder.editButton.customButtonStyles.normal'),
	},
	{
		id: 'hover',
		title: t('builder.editButton.customButtonStyles.hover'),
	},
];

const headerComponentsIds = computed(() => getters.headerBlock.components);
const headerSocialIconsComponentId = computed(
	() => headerComponentsIds.value.find((componentId) => {
		const component = getters.siteElements[componentId];

		return component.type === ELEMENT_TYPE_SOCIAL_ICONS;
	}),
);

const currentSocialIconsState = ref(socialIconsStates[0]);
const doesHeaderSocialIconsComponentExist = computed(() => !!headerSocialIconsComponentId.value);
const isSocialIconsEnabled = ref(doesHeaderSocialIconsComponentExist.value);
const isLinksSettingsVisible = computed(() => doesHeaderSocialIconsComponentExist.value && isSocialIconsEnabled.value);
const headerSocialIconsComponentData = computed(
	() => getters.siteElements[headerSocialIconsComponentId.value],
);
const iconSize = computed(
	() => (headerSocialIconsComponentId.value
		? Number.parseInt(headerSocialIconsComponentData.value.settings.styles['icon-size'], 10)
		: 20),
);
const isColorPickerOpen = ref(false);
const isHoverState = computed(() => currentSocialIconsState.value.id === 'hover');
const iconColor = computed(
	() => (isHoverState.value
		? headerSocialIconsComponentData.value.settings.styles['icon-color-hover']
		: headerSocialIconsComponentData.value.settings.styles['icon-color']),
);

const addSocialIconsElementToHeader = () => {
	if (doesHeaderSocialIconsComponentExist.value) {
		return;
	}

	// To prevent triggering position check at getIsLayoutPositionValid()
	const placeholderPositions = {
		mobile: {
			left: 0,
			top: 0,
			width: 0,
			height: 0,
		},
		desktop: {
			left: 0,
			top: 0,
			width: 0,
			height: 0,
		},
	};

	dispatch('addElement', {
		blockId: 'header',
		elementData: {
			...defaultElements.value.socialIcons.content,
			...placeholderPositions,
		},
	});
};

const updateCurrentSocialIconsState = (newState) => {
	currentSocialIconsState.value = newState;
};

const removeSocialIconsElementFromHeader = () => {
	if (!doesHeaderSocialIconsComponentExist.value) {
		return;
	}

	dispatch('removeElement', {
		blockId: 'header',
		elementId: headerSocialIconsComponentId.value,
	});
};

const updateIsSocialIconsEnabled = (newState) => {
	isSocialIconsEnabled.value = newState;

	if (newState) {
		addSocialIconsElementToHeader();

		return;
	}

	removeSocialIconsElementFromHeader();
};

const updateIconSize = (newSize) => {
	dispatch('mergeElementData', {
		elementId: headerSocialIconsComponentId.value,
		elementData: {
			settings: {
				styles: {
					'icon-size': `${newSize}px`,
				},
			},
		},
	});
};

const updateIconColor = (value) => {
	dispatch('mergeElementData', {
		elementId: headerSocialIconsComponentId.value,
		elementData: {
			settings: {
				styles: {
					[`${isHoverState.value ? 'icon-color-hover' : 'icon-color'}`]: value,
				},
			},
		},
	});
};
</script>

<style lang="scss" scoped>
.header-social-icons {
	&__links {
		margin-bottom: 18px;
	}

	&__segment-control {
		margin-bottom: 18px;
	}

	&__icon-size {
		margin-top: 0;
	}
}
</style>
