<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('common.instagramFeed')"
		:tabs="tabs"
		:current-tab="currentTab"
		@update:current-tab="currentTab = $event"
		@close="$emit('close')"
	>
		<Component
			:is="currentTab.component"
			:is-new-instagram-api="isNewInstagramApi"
		/>
		<NpsRateFeature
			:feature-name="$t('builder.npsRateInstagram')"
			:type="NPS_TYPE_FEATURE_INSTAGRAM_FEED"
		/>
	</ZyroPopupCard>
</template>

<script>
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';

import EditInstagramFeedTabGeneral from '@/components/builder-controls/edit-instagram-feed/EditInstagramFeedTabGeneral.vue';
import EditInstagramFeedTabLayout from '@/components/builder-controls/edit-instagram-feed/EditInstagramFeedTabLayout.vue';
import EditTabAnimation from '@/components/builder-controls/reusable-controls/element/EditTabAnimation.vue';

import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import { NPS_TYPE_FEATURE_INSTAGRAM_FEED } from '@/constants/builderConstants';

import {
	defineComponent,
	ref,
} from 'vue';

import { useI18n } from 'vue-i18n';

export default defineComponent({
	components: {
		ZyroPopupCard,
		EditInstagramFeedTabGeneral,
		EditInstagramFeedTabLayout,
		NpsRateFeature,
		EditTabAnimation,
	},
	props: {
		isNewInstagramApi: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['close'],
	setup() {
		const { t } = useI18n();
		const tabs = [
			{
				title: t('common.general'),
				component: 'EditInstagramFeedTabGeneral',
			},
			{
				title: t('common.layout'),
				component: 'EditInstagramFeedTabLayout',
			},
			{
				title: t('common.animation'),
				component: 'EditTabAnimation',
			},
		];

		const currentTab = ref(tabs[0]);

		return {
			tabs,
			currentTab,
			NPS_TYPE_FEATURE_INSTAGRAM_FEED,
		};
	},
});
</script>
