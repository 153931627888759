<template>
	<ZyroDrawer
		class="ecommerce-store-drawer"
		:header-title="$t('builder.addEcommerceStoreDrawer.title')"
		@back="closeInnerSidebar"
	>
		<template #body>
			<HImage
				class="ecommerce-store-drawer__store-image"
				src="/images/ecommerce-store.png"
			/>
			<ul class="ecommerce-store-drawer__list">
				<li class="ecommerce-store-drawer__list-item ecommerce-store-drawer__list-item--checkmark text-body-2">
					{{ $t('builder.storeKeyPointProducts') }}
				</li>
				<li class="ecommerce-store-drawer__list-item ecommerce-store-drawer__list-item--checkmark text-body-2">
					{{ $t('builder.storeKeyPointSetup') }}
				</li>
				<li class="ecommerce-store-drawer__list-item ecommerce-store-drawer__list-item--checkmark text-body-2">
					{{ $t('builder.storeKeyPointFast') }}
				</li>
				<li class="ecommerce-store-drawer__list-item ecommerce-store-drawer__list-item--checkmark text-body-2">
					{{ $t('builder.storeKeyPointSeo') }}
				</li>
			</ul>
			<div class="ecommerce-store-drawer__button">
				<HostingerButton
					v-qa="'drawer-addecommerce-addecommercelitestore-btn'"
					size="lg"
					@click="handleAddPage(true)"
				>
					{{ $t('builder.addStore') }}
				</HostingerButton>
			</div>
		</template>
	</ZyroDrawer>
</template>

<script setup>
import { useSidebar } from '@/use/useSidebar';

import ZyroDrawer from '@/components/global/ZyroDrawer.vue';
import HImage from '@/components/global/HImage.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useStore } from 'vuex';
import EventLogApi from '@/api/EventLogApi';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import { MODAL_EDIT_ONLINE_STORE } from '@/constants/builderConstants';

import { computed } from 'vue';

const {
	dispatch,
	getters,
	state,
} = useStore();
const { closeInnerSidebar } = useSidebar();

const hasActiveEcommerceSubscription = computed(() => getters['subscription/hasActiveEcommerceSubscription']);

const closeDrawer = () => dispatch('gui/CLOSE_DRAWER');

const handleAddPage = () => {
	if (!isHostingerBrand && !hasActiveEcommerceSubscription.value) {
		dispatch('gui/OPEN_MODAL', {
			name: MODAL_EDIT_ONLINE_STORE,
		});

		return;
	}

	closeDrawer();
	dispatch('addStorePage');

	EventLogApi.logEvent({
		eventName: 'website_builder.ecomm.store_added',
		eventProperties: {
			location: 'side_menu',
			website_id: state.websiteId,
		},
	});
};
</script>

<style lang="scss" scoped>
.ecommerce-store-drawer {
	&__button {
		margin-top: 32px;
		text-align: center;
	}

	&__list-item {
		&::before {
			font-size: 2em;
			line-height: 0.8;
			vertical-align: middle;
			content: "· ";
		}

		&--checkmark {
			margin-bottom: 8px;

			&::before {
				margin-right: 8px;
				font-size: unset;
				line-height: unset;
				content: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 1.83333L5.50002 11.8333L0.916687 7.24999L2.09169 6.07499L5.50002 9.47499L14.325 0.658325L15.5 1.83333Z' fill='black'/%3E%3C/svg%3E%0A");
			}
		}
	}

	&__store-image {
		width: 100%;
		margin-bottom: 32px;
		object-fit: cover;
	}

	&__list {
		list-style: none;
	}
}
</style>
