<template>
	<div class="website-share">
		<p class="website-share__title text-bold-2">
			{{ $t('builder.shareYourWebsite') }}
		</p>
		<div class="website-share__icons-list">
			<div
				v-for="link in shareLinks"
				:key="link.name"
			>
				<a
					:href="link.url"
					:title="link.name"
					target="_blank"
					class="website-share__icon-wrap"
					@click="trackShareClick(link.name)"
				>
					<ZyroSvgDeprecated
						:name="link.icon"
						class="website-share__icon"
					/>
				</a>
			</div>
			<div class="website-share__copy-link-wrap">
				<div
					class="website-share__icon-wrap"
					@click="copyLink"
				>
					<Icon
						name="link"
						dimensions="24px"
						class="website-share__copy-icon"
					/>
				</div>
				<p
					v-if="isLinkCopied"
					class="text-body-2 website-share__link-copied"
				>
					{{ $t('builder.shareLinkCopied') }}
				</p>
			</div>
			<button
				class="website-share__icon-wrap website-share__icon-wrap--with-text"
				@click="handleQrCreateButtonCLick"
			>
				<Icon
					name="qr_code_2"
					dimensions="24px"
					class="website-share__qr-icon"
				/>
				<p class="text-bold-2">
					{{ $t('builder.publishedModalButtonQrCode') }}
				</p>
			</button>
		</div>
	</div>
</template>
<script>
import {
	ref,
	computed,
	defineComponent,
} from 'vue';

import { useStore } from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';
import Icon from '@/components/global/Icon.vue';
import { OPEN_MODAL } from '@/store/builder/gui';
import { useQrModal } from '@/use/useQrModal';
import {
	MODAL_CREATE_QR,
	MODAL_QR_NOT_AVAILABLE,
} from '@/constants/builderConstants';
import { QR_CODE_LOCATION_TYPES } from '@zyro-inc/site-modules/constants/siteModulesConstants';

export default defineComponent({
	components: {
		ZyroSvgDeprecated,
		Icon,
	},
	props: {
		domainToShare: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const {
			state,
			dispatch,
		} = useStore();
		const { isQrCodeAvailable } = useQrModal();

		const isLinkCopied = ref(false);

		const shareLinks = computed(() => [
			{
				name: 'facebook',
				icon: 'facebook',
				url: `https://www.facebook.com/sharer.php?display=page&u=https%3A%2F%2F${props.domainToShare}`,
			},
			{
				name: 'twitter',
				icon: 'twitter',
				url: `https://twitter.com/intent/tweet?url=https%3A%2F%2F${props.domainToShare}`,
			},
			{
				name: 'linkedin',
				icon: 'linkedin',
				url: `https://www.linkedin.com/shareArticle?url=https%3A%2F%2F${props.domainToShare}`,
			},
		]);

		const copyLink = () => {
			navigator.clipboard.writeText(`https://${props.domainToShare}`);

			isLinkCopied.value = true;

			setTimeout(() => {
				isLinkCopied.value = false;
			}, 1500);

			EventLogApi.logEvent({
				eventName: 'website_builder.social_link.copied',
				eventProperties: {
					website_id: state.websiteId,
					domain_name: props.domainToShare,
				},
			});
		};

		const trackShareClick = (name) => {
			EventLogApi.logEvent({
				eventName: 'website_builder.social_link.created',
				eventProperties: {
					social_media_type: name,
					website_id: state.websiteId,
					domain_name: props.domainToShare,
				},
			});
		};

		const handleQrCreateButtonCLick = () => {
			dispatch(`gui/${OPEN_MODAL}`, {
				name: isQrCodeAvailable.value ? MODAL_CREATE_QR : MODAL_QR_NOT_AVAILABLE,
				settings: {
					location: QR_CODE_LOCATION_TYPES.PUBLISH,
				},
			});
		};

		return {
			copyLink,
			shareLinks,
			isLinkCopied,
			trackShareClick,
			handleQrCreateButtonCLick,
		};
	},
});
</script>
<style lang="scss" scoped>
.website-share {
	text-align: center;

	&__title {
		margin-bottom: 16px;
	}

	&__icons-list {
		display: flex;
		gap: 8px;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		padding: 0 16px;
	}

	&__qr-icon {
		color: $color-primary
	}

	&__icon-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		cursor: pointer;
		border: 1px solid $color-gray-border;
		border-radius: 8px;
		transition: all 0.3s ease;

		&:hover {
			background: $color-gray-light;
		}

		&--with-text {
			width: unset;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 24px;
			color: $color-primary;
			gap: 8px;
		}
	}

	&__icon {
		width: 16px;
		height: 16px;
	}

	&website-share__qr-code-wrap,
	&__copy-link-wrap {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.3s ease;
	}

	&__link-copied {
		position: absolute;
		left: 56px;
		white-space: nowrap;
		animation: 1.5s alternate appear;
	}
}

@keyframes appear {
	0% {
		opacity: 0;
	}

	30% {
		opacity: 1;
	}

	80% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
</style>
