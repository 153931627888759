export interface RedirectState {
  routeName: string;
  prevRouteName: string;
  params: {
    [key: string]: any;
  };
}

export const REDIRECT_MUTATIONS = {
	SET_REDIRECT: 'SET_REDIRECT',
	UNSET_REDIRECT: 'UNSET_REDIRECT',
} as const;

export const HPANEL_REDIRECT_ACTIONS = {
	CONNECT_DOMAIN: 'connect-domain',
	RENEW_DOMAIN: 'renew-domain',
	RESTORE_DOMAIN: 'restore-domain',
	BUY_DOMAIN: 'buy-domain',
} as const;

export const HPANEL_REDIRECT_SECTIONS = {
	WEBSITES: 'websites',
} as const;

export const HPANEL_REDIRECTS_PATHS = {
	DOMAINS: 'domains',
	ADD_DOMAIN: 'add-domain',
	DOMAIN: 'domain',
	DOMAIN_OVERVIEW: 'domain-overview',
	DOMAIN_DNS: 'dns',
	WEBSITES: 'websites',
	UPGRADE: 'order/upgrade',
	REGISTER_DOMAIN_RESOURCE: 'register-domain-resource',
} as const;
