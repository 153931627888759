<template>
	<div
		class="popup-card"
		:class="{
			'popup-card--editor': type === 'editor',
			'popup-card--full-height': isFullHeight,
			'popup-card--select-allowed': isSelectAllowed,
		}"
	>
		<div
			v-if="showCloseButton || title || tabs.length"
			ref="popupHead"
			class="popup-card__head"
			:draggable="isMobileScreen"
			@touchmove="onTouchDrag"
			@dragstart="onDragStart"
			@drag="onDrag"
		>
			<h4
				v-if="title"
				class="popup-card__title text-heading-1"
			>
				{{ title }}
				<slot name="title-suffix" />
			</h4>
			<div class="popup-card__close">
				<span
					v-if="label"
					class="popup-card__label text-overline"
				>
					{{ label }}
				</span>
				<HostingerButton
					v-if="showCloseButton"
					v-qa="'builder-popupcard-btn-close'"
					button-type="plain"
					:title="$t('common.close')"
					@click="$emit('close')"
				>
					<template #icon>
						<Icon name="close" />
					</template>
				</HostingerButton>
			</div>
		</div>
		<ZyroTabs
			v-if="tabs.length"
			:tabs="tabs"
			:current-tab="currentTab"
			@update:current-tab="$emit('update:current-tab', $event)"
		/>
		<div class="popup-card__content">
			<slot />
		</div>
		<div
			v-if="showFooter"
			ref="popupFooter"
			class="popup-card__footer"
		>
			<slot
				v-if="!!hasSlotContent($slots.footer)"
				name="footer"
			/>
			<template v-else>
				<HostingerButton
					v-qa="'builder-popupcard-cancelbtn'"
					button-type="text"
					@click="$emit('close')"
				>
					{{ $t('common.cancel') }}
				</HostingerButton>
				<HostingerButton
					v-qa="'builder-popupcard-savebtn'"
					@click="$emit('save')"
				>
					{{ saveBtnTitle ?? $t('common.save') }}
				</HostingerButton>
			</template>
		</div>
	</div>
</template>

<script setup>
import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/global/Icon.vue';
import ZyroTabs from '@/components/global/ZyroTabs.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';

import { hasSlotContent } from '@zyro-inc/site-modules/utils/hasSlotContent';

defineProps({
	title: {
		type: String,
		default: '',
	},
	type: {
		type: String,
		default: '',
		validator: (type) => [
			'editor',
			'',
		].includes(type),
	},
	showCloseButton: {
		type: Boolean,
		default: true,
	},
	showFooter: {
		type: Boolean,
		default: false,
	},
	tabs: {
		type: Array,
		default: () => [],
	},
	currentTab: {
		type: Object,
		default: () => {},
	},
	maxWidth: {
		type: String,
		default: '400px',
	},
	editorPopupWidth: {
		type: String,
		default: '360px',
	},
	saveBtnTitle: {
		type: String,
		default: null,
	},
	isFullHeight: {
		type: Boolean,
		default: false,
	},
	isSelectAllowed: {
		type: Boolean,
		default: false,
	},
	label: {
		type: String,
		default: '',
	},
});

defineEmits([
	'close',
	'save',
	'update:current-tab',
]);

const { state } = useStore();

const popupHead = ref(null);
const popupHeight = ref(50);
const popupHeightVh = computed(() => `${popupHeight.value}vh`);
const isMobileScreen = computed(() => state.gui.isMobileScreen);

const onDragStart = (event) => {
	// Empty image is set to avoid default drag image
	event.dataTransfer.setDragImage(new Image(), 0, 0);
};

const setPopupHeight = (movedHeight) => {
	const { top } = popupHead.value.getBoundingClientRect();
	const newHeight = popupHeight.value + Math.round(((top - movedHeight) / window.innerHeight) * 100);

	if (newHeight > 0 && newHeight < 100) {
		popupHeight.value = newHeight;
	}
};

const onDrag = (event) => {
	const { clientY } = event;

	setPopupHeight(clientY);
};

const onTouchDrag = (event) => {
	event.preventDefault();

	const { clientY } = event.touches[0];

	setPopupHeight(clientY);
};

</script>

<style lang="scss" scoped>
$popper-padding: 16px;

.popup-card {
	$this: &;

	position: relative;
	padding: 16px;
	margin-top: 0;
	z-index: $z-index-controls-popup-card;
	max-width: v-bind(maxWidth);
	user-select: none;
	background: $color-light;
	border-radius: 16px;
	box-shadow: $box-shadow;

	&--select-allowed {
		user-select: auto;
	}

	&--full-height {
		height: calc(100vh - $header-height-editor - $popper-padding);
		overflow-y: hidden;

		@media screen and (max-width: $media-mobile) {
			margin: 0 $popper-padding 0 auto;
		}

		&#{&} {
			#{$this}__content {
				height: 100%;
				padding: 0;
			}
		}
	}

	&--editor {
		width: v-bind(editorPopupWidth);
		padding: 0;
		border: 1px solid $color-gray-light;
		border-radius: $border-radius-large;

		#{$this}__head {
			padding: 16px 16px 4px;

			@media screen and (max-width: $media-mobile) {
				padding: 12px 16px;
			}
		}

		#{$this}__content {
			padding: 16px;
		}
	}

	&__label {
		padding: 4px 8px;
		background-color: var(--color-gray-light);
		border-radius: 4px;
		color: var(--color-gray);
		cursor: default;
	}

	&__title {
		margin-right: 1em;
		margin-bottom: 0;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		padding: 12px 24px 14px;
		border-top: 1px solid $color-gray-border;
	}

	&__close {
		position: absolute;
		top: 8px;
		right: 8px;
		box-sizing: content-box;
		cursor: pointer;
		background-color: transparent;
		border: 0;
		display: flex;
		align-items: center;
		gap: 2px;
	}

	@media screen and (max-width: $media-mobile) {
		width: 100%;
		max-width: 100%;
		box-shadow: $box-shadow-base;
		border-radius: 16px 16px 0 0;
		max-height: v-bind(popupHeightVh);
		overflow-y: hidden;

		&__content {
			overflow-y: auto;
			max-height: calc(v-bind(popupHeightVh) - 100px);
		}

		&__close {
			min-height: 24px;
			min-width: 24px;
			position: inherit;
		}

		&__head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 12px 16px;
			cursor: grab;
		}

		&__title {
			margin-bottom: 0;
			margin-right: 0;
			font-size: 18px;
			line-height: 24px;
		}
	}
}
</style>
