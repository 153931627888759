<script setup lang="ts">
import { useWebsiteColors } from '@/use/useWebsiteColors';
import ColorPickerSelection from '@/components/global/color-picker/ColorPickerSelection.vue';
import { onMounted } from 'vue';

interface Props {
	color: string;
	hideWebsiteColors?: boolean;
	isOpacityDisabled?: boolean;
}

const emit = defineEmits<{
	'update-color': [color: string];
	'toggle-eye-dropper': [value: boolean];
}>();

const props = withDefaults(defineProps<Props>(), {
	isOpacityDisabled: false,
});

const {
	mostUsedColors,
	getColorValue,
} = useWebsiteColors();

const top16UsedColorValues = [
	...new Set(
		Object.keys(mostUsedColors.value)
			.slice(0, 16)
			.map((color) => getColorValue(color)),
	),
];

onMounted(() => {
	emit('update-color', props.color);
});
</script>

<template>
	<div class="color-picker-solid">
		<div
			v-if="top16UsedColorValues?.length && !hideWebsiteColors"
			class="color-picker-solid__website-colors"
		>
			<p class="text-bold-2">
				{{ $t('common.colorStyles') }}
			</p>

			<div class="color-picker-solid__colors">
				<button
					v-for="siteColor in top16UsedColorValues"
					:key="siteColor"
					v-qa="'builder-colorpicker-websitecolor-btn'"
					class="color-picker-solid__color"
					:class="{ 'active': siteColor === color }"
					:style="{ backgroundColor: siteColor }"
					@click="emit('update-color', siteColor)"
				/>
			</div>
		</div>

		<ColorPickerSelection
			:color="color"
			:is-opacity-disabled="isOpacityDisabled"
			@update-color="emit('update-color', $event)"
			@toggle-eye-dropper="emit('toggle-eye-dropper', $event)"
		/>
	</div>
</template>

<style lang="scss" scoped>
.color-picker-solid {
	&__pick-color-button {
		display: flex;
		margin-top: 8px;
		width: 100%;

		:deep(.hostinger-button__text) {
			gap: 4px;
		}
	}

	&__website-colors {
		padding: 16px 16px 0;
	}

	&__colors {
		display: grid;
		grid-template-columns: repeat(8, 24px);
		grid-column-gap: 4px;
	}

	&__color {
		margin-top: 8px;
		width: 24px;
		height: 24px;
		margin-bottom: 4px;
		cursor: pointer;
		border: 1px solid rgba(221, 225, 230, 80%);
		border-radius: 50%;

		&:hover {
			border: none;
			border: 2px solid $color-primary;
		}

		&.active {
			border: 2px solid $color-light;
			outline: 2px solid $color-primary;
		}
	}
}
</style>
