<template>
	<div class="edit-block-header-tab-cart">
		<template v-if="!state.gui.isMobileView">
			<ZyroFieldInput
				v-qa="`editblocknavigation-tabcart-carticontext-${cartIconText}`"
				:model-value="cartIconText"
				:maxlength="50"
				:label="$t('builder.editBlockNavigation.tabCart.cartIconText')"
				@update:model-value="updateCartIconText"
			/>
		</template>
		<ZyroSeparator />
		<ZyroIconSelect
			v-qa="`editblocknavigation-tabcart-carticonsize-${cartIconSize}`"
			class="edit-block-header-tab-cart__icon-select"
			:model-value="cartIconSize"
			:options="ICON_SIZE_OPTIONS"
			:label="$t('builder.editBlockNavigation.tabCart.iconSize')"
			:items-per-row="3"
			group-name="icon-size"
			@update:model-value="updateCartIconSize"
		/>
	</div>
</template>

<script setup lang="ts">
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import ZyroIconSelect from '@/components/global/ZyroIconSelect.vue';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';

import { useStore } from 'vuex';

import { useI18n } from 'vue-i18n';

import { computed } from 'vue';

const {
	state,
	getters,
	dispatch,
} = useStore();
const { t } = useI18n();

const ICON_SIZE_OPTIONS = {
	'24px': {
		name: t('common.small'),
		icon: 'shopping-bag',
		iconDimensions: '24px',
	},
	'32px': {
		name: t('common.medium'),
		icon: 'shopping-bag',
		iconDimensions: '32px',
	},
	'40px': {
		name: t('common.large'),
		icon: 'shopping-bag',
		iconDimensions: '40px',
	},
};

const cartIconText = computed(() => getters.currentBlockSettings.cartText);
const cartIconSize = computed(() => getters.currentBlockStyles?.cartIconSize);

const updateCartIconText = (newValue: string) => {
	dispatch('updateBlockData', {
		blockId: state.currentBlockId,
		blockData: {
			settings: {
				cartText: newValue,
			},
		},
		merge: true,
	});
};

const updateCartIconSize = (newValue: string) => {
	dispatch('updateBlockData', {
		blockId: state.currentBlockId,
		blockData: {
			settings: {
				styles: {
					cartIconSize: newValue,
				},
			},
		},
		merge: true,
	});
};
</script>

<style lang="scss" scoped>
.edit-block-header-tab-cart {
	&__icon-select {
		margin-top: 16px;
	}
}
</style>
