<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';
import { getHighestQualityPexelsVideo } from '@/stores/pexelsStore';
import { Video } from '@/types/pexelsTypes';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';

type Emits = {
	'select-video': [number];
}

type Props = {
  video: Video;
}

const emit = defineEmits<Emits>();
const props = defineProps<Props>();

const { t } = useI18n();

const highestQualityVideo = getHighestQualityPexelsVideo(props.video.videoFiles);

const isVideoLoaded = ref(false);
</script>

<template>
	<div class="video-preview">
		<div class="video-preview__video-container">
			<ZyroLoader
				v-if="!isVideoLoaded"
				size="32px"
				color="var(--color-light)"
				class="video-preview__loader"
			/>
			<video
				:src="highestQualityVideo.link"
				autoplay
				muted
				loop
				class="video-preview__video"
				:poster="props.video.image"
				@loadeddata="isVideoLoaded = true"
			/>
		</div>
		<div class="video-preview__author-container">
			<div class="video-preview__author text-body-2">
				<p>
					<i18n-t
						class="video-preview__author text-body-2"
						keypath="builder.assetManagerTabPexelsFootageAuthor"
					>
						<a
							class="z-link"
							:href="props.video.user.url"
							target="_blank"
							rel="noopener noreferrer"
						>
							{{ props.video.user.name }}.
						</a>
					</i18n-t>
				</p>
				<p>
					<i18n-t
						class="video-preview__author-name"
						keypath="builder.assetManagerTabPexelsAccreditation"
					>
						<a
							class="z-link"
							href="https://www.pexels.com/terms-of-service/"
							target="_blank"
							rel="noopener noreferrer"
						>
							{{ $t('builder.assetManagerTabPexelsAccreditationLink') }}
						</a>
					</i18n-t>
				</p>
				<HostingerButton
					:title="t('builder.assetManagerMediaLayoutSelectItem')"
					@click="emit('select-video', props.video.id)"
				>
					{{ t('builder.assetManagerMediaLayoutSelectItem') }}
				</HostingerButton>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
$author-container-height-desktop: 60px;
$author-container-height-mobile: 100px;

.video-preview {
	height: calc(100% - $author-container-height-desktop);
	display: flex;
	flex-direction: column;

  &__video-container {
		position: relative;
		left: 24px;
		display: flex;
		justify-content: center;
		height: calc(100% - $author-container-height-desktop);
		background-color: $color-gray-light;
		width: calc(100% - 48px);
		overflow: hidden;

		@media screen and (max-width: $media-mobile) {
			left: 0;
			width: 100%;
		}
  }

  &__video {
		height: 100%;
  }

  &__author-container {
		display: flex;
		border-top: 1px solid $color-gray-border;
		position: relative;
		height: $author-container-height-desktop;
		width: 100%;

		@media screen and (max-width: $media-mobile) {
			height: unset;
		}
  }

	&__author {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		margin: 0 auto;
		width: 100%;
		padding: 0 24px;

		@media screen and (max-width: $media-mobile) {
			text-align: center;
			flex-direction: column;
			gap: 16px;
			padding: 16px;
		}
	}

	&__loader {
		position: absolute !important;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
</style>
