<!-- Source: https://www.cssscript.com/confetti-animation/ -->
<template>
	<div class="confetti">
		<div
			v-for="n in 10"
			:key="`${n}-item`"
			class="confetti__item"
		/>
	</div>
</template>

<style lang="scss" scoped>
.confetti {
	overflow: hidden;
	position: absolute;
	inset: 0;

	&__item {
		left: 50%;
		width: 16px;
		height: 16px;
		position: absolute;
		transform-origin: left top;
		animation: fall 4s ease-in-out -1s infinite;

		&:nth-child(1) {
			left: 10%;
			animation-delay: 0;
			background-color: $color-danger;
		}

		&:nth-child(2) {
			left: 20%;
			animation-delay: -5s;
			background-color: $color-meteorite;
		}

		&:nth-child(3) {
			left: 30%;
			animation-delay: -3s;
			background-color: $color-warning;
		}

		&:nth-child(4) {
			left: 40%;
			animation-delay: -2.5s;
			background-color: $color-danger;
		}

		&:nth-child(5) {
			left: 50%;
			animation-delay: -4s;
			background-color: $color-success;
		}

		&:nth-child(6) {
			left: 60%;
			animation-delay: -6s;
			background-color: $color-warning;
		}

		&:nth-child(7) {
			left: 70%;
			animation-delay: -1.5s;
			background-color: $color-danger-light;
		}

		&:nth-child(8) {
			left: 80%;
			animation-delay: -2s;
			background-color: $color-primary-dark;
		}

		&:nth-child(9) {
			left: 90%;
			animation-delay: -3.5s;
			background-color: $color-meteorite;
		}

		&:nth-child(10) {
			left: 100%;
			animation-delay: -2.5s;
			background-color: $color-meteorite-light;
		}
	}
}

@keyframes fall {
	0% {
		transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
	}

	25% {
		transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
	}

	50% {
		transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
	}

	75% {
		transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
	}

	100% {
		transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
	}
}
</style>
