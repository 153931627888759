import { defineStore } from 'pinia';
import { PINIA_STORES } from '@/constants/stores';
import { ref } from 'vue';
import { useSiteStore } from '@/stores/siteStore';
import { useStore } from 'vuex';
import {
	IFRAME_EVENT_ECOMMERCE_ADMIN_UPDATED,
	IFRAME_EVENT_ECOMMERCE_ADMIN_UNAVAILABLE,
	ECOMMERCE_REDIRECT_PATHS,
} from '@/constants/builderConstants';
import { EcommerceAdminPath } from '@/types/ecommerceTypes';
import { getRedirectUrlToEcommerce } from '@/use/useRedirects';
import { useAuthStore } from '@/stores/authStore';
import { CATEGORY_ID_PREFIX } from '@zyro-inc/site-modules/constants/ecommerce';
import { getStoreProducts } from '@/api/StoreApi';
import { getStoreId } from '@zyro-inc/site-modules/utils/getters/getStoreId';
import { timeout } from '@/utils/helpers';
import { useNotifications } from '@/use/useNotifications';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { SiteEcommerceSortingValue } from '@hostinger/builder-schema-validator';

export const useEcommerceAdminStore = defineStore(PINIA_STORES.ECOMMERCE_ADMIN, () => {
	const {
		getters,
		dispatch,
	} = useStore();
	const siteStore = useSiteStore();
	const authStore = useAuthStore();
	const ecommerceStore = useEcommerceStore();

	const isEcommerceAdminIframeOpen = ref(false);
	const ecommerceAdminIframeSrc = ref('');
	const shouldUpdateEcommerceData = ref(false);

	const handleSaveChanges = async () => {
		if (!getters['saving/canSave']) {
			return;
		}

		await dispatch('saving/saveWebsite');
	};

	const setIsEcommerceAdminIframeOpen = async ({
		isOpen,
		path,
		params,
	}: {isOpen: boolean, path?: EcommerceAdminPath, params?: Record<string, string | number>, location?: string}) => {
		if (!isOpen) {
			isEcommerceAdminIframeOpen.value = false;
			ecommerceAdminIframeSrc.value = '';

			return;
		}

		await handleSaveChanges();

		const url = getRedirectUrlToEcommerce({
			path: path as string,
			siteId: siteStore.websiteId,
			params: {
				...params,
				jwt: authStore.getAuthToken(),
			},
		});

		isEcommerceAdminIframeOpen.value = isOpen;
		ecommerceAdminIframeSrc.value = url;
	};

	const getSortingParams = () => {
		const productsSortedKeys = Object.keys(ecommerceStore.productsSorted || {});

		return productsSortedKeys.reduce((acc: Array<{sort: SiteEcommerceSortingValue, collectionId?: string}>, key: string) => {
			const regex = new RegExp(`(${CATEGORY_ID_PREFIX}[A-Z0-9]+)`);
			const categoryIdMatch = key.match(regex);

			if (categoryIdMatch) {
				const collectionId = categoryIdMatch[0];
				const sort = key.replace(collectionId, '') as SiteEcommerceSortingValue;

				acc.push({
					collectionId,
					sort,
				});
			} else {
				acc.push({
					sort: key as SiteEcommerceSortingValue,
				});
			}

			return acc;
		}, []);
	};

	const handleEcommerceAdminMessage = (event: MessageEvent) => {
		if (event.data === IFRAME_EVENT_ECOMMERCE_ADMIN_UNAVAILABLE) {
			const { notify } = useNotifications();

			setIsEcommerceAdminIframeOpen({
				isOpen: false,
			});

			notify({
				messageI18nKeyPath: 'builder.storeManagerUnavailable',
			});

			return;
		}

		if (event.data !== IFRAME_EVENT_ECOMMERCE_ADMIN_UPDATED || shouldUpdateEcommerceData.value) {
			return;
		}

		shouldUpdateEcommerceData.value = true;
	};

	const handleEcommerceDataUpdate = async () => {
		const ecommerceBackendUpdateDelay = 2000;
		const sortingFetchVariants: { sort: SiteEcommerceSortingValue, collectionId?: string }[] = getSortingParams();
		const storeId = getStoreId(getters.siteMeta);

		await timeout(ecommerceBackendUpdateDelay);

		const productData = await Promise.all(sortingFetchVariants.map(({
			sort,
			collectionId,
		}: {sort: SiteEcommerceSortingValue, collectionId?: string}) => getStoreProducts(storeId, {
			sort,
			collectionId,
		})));

		sortingFetchVariants.forEach((data, index) => {
			ecommerceStore.setStoreProducts({
				products: productData[index].products,
				sort: data.sort,
				collectionId: data.collectionId,
				count: productData[index].count,
			});
		});
		await ecommerceStore.initEcommerce({
			refetch: true,
		});

		const isDynamicPageProductSelected = !!ecommerceStore.currentDynamicPageProductId
			&& ecommerceStore.currentDynamicPageProductId !== -1;

		if (isDynamicPageProductSelected) {
			const isDynamicPageProductPresent = ecommerceStore.productsSeo.some(
				(product) => product.id === ecommerceStore.currentDynamicPageProductId,
			);

			if (!isDynamicPageProductPresent) {
				ecommerceStore.setCurrentDynamicPageProductId(ecommerceStore.productsSeo[0]?.id || -1);
			}
		}

		shouldUpdateEcommerceData.value = false;
	};

	const openProductsAddInIframe = async () => {
		setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS_ADD,
		});
	};

	const openPaymentsInIframe = async () => {
		setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.PAYMENT_MANAGEMENT,
		});
	};

	const openCompanyInformationInIframe = async () => {
		setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.COMPANY_INFORMATION,
		});
	};

	const openShippingEditInIframe = async ({ shouldEditFirst }: {
		shouldEditFirst: number,
	}) => {
		setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.SHIPPING_EDIT,
			params: {
				shouldEditFirst,
			},
		});
	};

	return {
		shouldUpdateEcommerceData,
		isEcommerceAdminIframeOpen,
		ecommerceAdminIframeSrc,
		setIsEcommerceAdminIframeOpen,
		handleEcommerceAdminMessage,
		handleEcommerceDataUpdate,
		openProductsAddInIframe,
		openPaymentsInIframe,
		openCompanyInformationInIframe,
		openShippingEditInIframe,
	};
});
