// eslint-disable-next-line import/no-cycle
import axios from '@/services/http/axios';
import {
	HResource,
	HResourceState,
	HResourceType,
} from '@/types/resourceTypes';
import { snakeToCamelObj } from '@/utils/snakeToCamel';

const HRESOURCES_API = `${import.meta.env.VITE_HRESOURCES_API_URL}/api`;

type ResourcesResponse = {
	data: HResource[];
	status: number,
	success: boolean,
};

export default {
	getUserResources: async ({
		types,
		states,
	}: { types?: HResourceType[], states?: HResourceState[] } = {
		types: [],
		states: [],
	}): Promise<HResource[]> => {
		const { data } = await axios.get<ResourcesResponse>(`${HRESOURCES_API}/v2/resources`, {
			params: {
				...(types && {
					type: types,
				}),
				...(states && {
					state: states,
				}),
			},
		});

		return snakeToCamelObj(data.data);
	},
};
