import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';

const isHoveredBlockLocked = ref(false);
const hoveredBlock = ref(null);

export const useHoveredBlock = () => {
	const { getters } = useStore();

	const siteBlocks = computed(() => getters.siteBlocks);
	const hoveredBlockId = computed(() => hoveredBlock.value?.id);

	const handleBlockHover = ({ blockId }) => {
		if (isHoveredBlockLocked.value) {
			return;
		}

		hoveredBlock.value = {
			id: blockId,
			type: siteBlocks.value[blockId].type,
			slot: siteBlocks.value[blockId].slot,
		};
	};

	return {
		hoveredBlock,
		hoveredBlockId,
		isHoveredBlockLocked,
		handleBlockHover,
	};
};
