<template>
	<div class="shortcut-container">
		<p class="text-body-2">
			<slot name="title" />
		</p>
		<p class="text-body-2 shortcut-container__shortcut-text">
			<slot name="shortcut" />
		</p>
	</div>
</template>

<style lang="scss" scoped>
.shortcut-container {
	display: flex;
	justify-content: space-between;

	&__shortcut-text {
		color: $color-gray;
	}
}
</style>
