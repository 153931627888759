import {
	computed,
	ref,
} from 'vue';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import { captureException } from '@sentry/vue';
import { HOSTING_PLANS } from '@/constants/resources';

import { useStore } from 'vuex';
import { getUser } from '@/api/UsersApi';
import { checkHasHostingPlan } from '@/api/HostingApi';
import {
	HRESOURCE_STATE,
	HRESOURCE_TYPE,
} from '@/types/resourceTypes';
import { useResourcesStore } from '@/stores/resourcesStore';

const isUserAuthenticated = ref(false);
const isUserWithActivePlan = ref(false);
const isUserPayToPublish = ref(false);
const availablePlans = ref([]);

const isSiteCapacityReached = computed(() => availablePlans.value.length
	&& availablePlans.value.every((plan) => plan.limits.addons.limit <= plan.limits.addons.count));
const isOnlyOneStarterPlanUser = computed(() => availablePlans.value.length === 1
	&& availablePlans.value[0].plan === HOSTING_PLANS.STARTER);

export const useUserAuthorizationState = () => {
	const { dispatch } = useStore();
	const resourcesStore = useResourcesStore();

	const getIsPayToPublishUser = () => new Promise((resolve, reject) => {
		try {
			if (resourcesStore.isLoaded) {
				const hostingResources = resourcesStore.getResourcesByTypes([HRESOURCE_TYPE.HOSTING]);

				resolve(!hostingResources.length);
			}

			const unsubscribe = resourcesStore.$subscribe((_, state) => {
				if (!state.isLoaded) {
					return;
				}

				const hostingResources = resourcesStore.getResourcesByTypes([HRESOURCE_TYPE.HOSTING]);

				resolve(!hostingResources.length);
				unsubscribe();
			});
		} catch (error) {
			captureException(error);
			reject(new Error(`Error while getting users information. ${error}`));
		}
	});

	const fetchUserAuthorizationState = async () => {
		try {
			const { hPanelId } = await getUser();

			if (!hPanelId) {
				return;
			}

			if (hPanelId.startsWith('test-')) {
				isUserAuthenticated.value = true;
				isUserWithActivePlan.value = true;

				return;
			}

			isUserPayToPublish.value = await getIsPayToPublishUser();

			if (isUserPayToPublish.value) {
				isUserAuthenticated.value = true;
				isUserWithActivePlan.value = false;

				return;
			}

			try {
				isUserAuthenticated.value = true;

				const { data } = await checkHasHostingPlan();

				isUserWithActivePlan.value = true;

				availablePlans.value = data.filter((plan) => [
					HRESOURCE_STATE.PENDING_SETUP,
					HRESOURCE_STATE.ACTIVE,
				].includes(plan.status));
			} catch (error) {
				if (error.response?.status === 401) {
					isUserWithActivePlan.value = false;
				} else if (isHostingerBrand && error.response.status === 403) {
					isUserWithActivePlan.value = false;
				}
			}
		} catch (error) {
			if (isHostingerBrand && error.response?.status === 401) {
				isUserAuthenticated.value = false;
				isUserWithActivePlan.value = false;
			} else {
				dispatch('notifications/notify', {
					message: 'An error occurred while fetching user information.',
				});

				isUserAuthenticated.value = false;
				isUserWithActivePlan.value = false;
			}
		}
	};

	const getPlanData = (hostingReferenceId) => availablePlans.value.find(
		(plan) => Number.parseInt(hostingReferenceId, 10) === plan.order_id,
	);

	const isHostingPlanCapacityReached = (hostingReferenceId) => {
		const planData = getPlanData(hostingReferenceId);

		return !planData || planData.limits.addons.limit <= planData.limits.addons.count;
	};

	const getFirstAvailablePlanId = () => {
		const firstAvailablePlan = availablePlans.value.find((plan) => plan.limits.addons.limit > plan.limits.addons.count);

		return firstAvailablePlan?.order_id.toString();
	};

	// If passed hosting reference ID plan capacity is reached, get first available plan ID
	const getHostingReferenceIdForSiteCreation = (hostingReferenceId) => (
		isHostingPlanCapacityReached(hostingReferenceId) ? getFirstAvailablePlanId() : hostingReferenceId
	);

	return {
		isUserAuthenticated,
		isUserWithActivePlan,
		fetchUserAuthorizationState,
		isSiteCapacityReached,
		isUserPayToPublish,
		isOnlyOneStarterPlanUser,
		isHostingPlanCapacityReached,
		getFirstAvailablePlanId,
		getHostingReferenceIdForSiteCreation,
	};
};
