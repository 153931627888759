<template>
	<ResizeHandle
		:type="resizeHandleType"
		:resize-direction="resizeHandleDirection"
		@mousedown.left.stop="startSectionResizing"
		@touchstart.prevent="startSectionResizingOnTouchStart"
	/>
</template>

<script>
import ResizeHandle from '@/components/builder-controls/control-line/ResizeHandle.vue';
import { useSectionResizing } from '@/use/useSectionResizing';
import { useTouchPosition } from '@/use/useTouchPosition';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		ResizeHandle,
	},

	props: {
		blockId: {
			type: String,
			required: true,
		},
	},

	setup(props, context) {
		const {
			startSectionResizing,
			resizeHandleType,
			resizeHandleDirection,
		} = useSectionResizing(props, context);

		const { handleTouchEvent } = useTouchPosition();

		const startSectionResizingOnTouchStart = (event) => {
			// Set initial touch position
			handleTouchEvent(event);

			startSectionResizing();
		};

		return {
			startSectionResizingOnTouchStart,
			startSectionResizing,
			resizeHandleType,
			resizeHandleDirection,
			handleTouchEvent,
		};
	},
});
</script>
