import {
	ref,
	computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
	AI_BUILDER_CATEGORY_ID_STORE,
	AI_BUILDER_CATEGORY_ID_BLOG,
	AI_BUILDER_CATEGORY_ID_BUSINESS,
	AI_BUILDER_CATEGORY_ID_PORTFOLIO,
	AI_BUILDER_CATEGORY_ID_OTHER,
	AI_BUILDER_CATEGORY_ID_WEDDINGS,
	AI_BUILDER_CATEGORY_ID_LANDING_PAGE,
	AI_BUILDER_CATEGORY_ID_RESTAURANT,
	TYPE_SUGGESTION_DEBOUNCE_TIME,
} from '@/constants/builderConstants';
import {
	ColorsTag,
	AiBuilderColorPalette,
	WebsiteType,
	AiBuilderCategory,
} from '@/types/aiBuilderTypes';

import { fetchWebsiteType } from '@/api/AiApi';
import { debounce } from 'lodash';

import { useAiField } from '@/use/useAiField';

const brandName = ref('');
const brandDescription = ref('');
const websiteType = ref<WebsiteType>();
const currentStep = ref(0);
const isDescriptionUpdatedByVoice = ref(false);
const isDescriptionUpdatedManually = ref(false);
const isCollapsibleContainerOpen = ref(false);
const isPaletteListPopupOpen = ref(false);
const selectedColorsTag = ref<ColorsTag>('random');
const selectedHostingReferenceId = ref<string | undefined>(undefined);
const selectedColors = ref<AiBuilderColorPalette>({
	name: '',
	color1: '',
	color2: '',
	color3: '',
	light: '',
	dark: '',
	grey: '',
});
const shouldAddOnlineStore = ref(true);
const isOnlineStoreSuggestionDeclined = ref(false);

const setSelectedColors = (colors: AiBuilderColorPalette) => {
	selectedColors.value = colors;
};

export const validateFormInput = (input: string) => !!input.trim();

export const useAiBuilderForm = () => {
	const { t } = useI18n();

	const {
		fieldProgress: descriptionProgress,
		fieldError: descriptionError,
		setFieldValue,
		validateField: validateDescription,
		onFieldBlur: onDescriptionBlur,
	} = useAiField({
		initialValue: brandDescription.value,
	});

	const setDescriptionValue = (value: string) => {
		setFieldValue(value);
		brandDescription.value = value;
	};

	const setBusinessTypeId = (value: WebsiteType | undefined) => {
		websiteType.value = value;
	};

	const setBrandName = (value: string) => {
		brandName.value = value;
	};

	const AI_BUILDER_CATEGORIES: Record<WebsiteType, AiBuilderCategory> = {
		[AI_BUILDER_CATEGORY_ID_BUSINESS]: {
			label: t('builder.aiBuilderBusinessShowcase'),
			id: AI_BUILDER_CATEGORY_ID_BUSINESS,
			amplitudeName: 'Business',
			onboardingRedirectParamValue: 'business',
		},
		[AI_BUILDER_CATEGORY_ID_STORE]: {
			label: t('builder.addEcommerceStoreDrawer.title'),
			id: AI_BUILDER_CATEGORY_ID_STORE,
			amplitudeName: 'Online store',
			onboardingRedirectParamValue: 'online-store',
		},
		[AI_BUILDER_CATEGORY_ID_LANDING_PAGE]: {
			label: t('builder.landingPage'),
			id: AI_BUILDER_CATEGORY_ID_LANDING_PAGE,
			amplitudeName: 'Landing page',
			onboardingRedirectParamValue: 'landing-page',
		},
		[AI_BUILDER_CATEGORY_ID_BLOG]: {
			label: t('builder.blog.blogDrawer.title'),
			id: AI_BUILDER_CATEGORY_ID_BLOG,
			amplitudeName: 'Blog',
			onboardingRedirectParamValue: 'blog',
		},
		[AI_BUILDER_CATEGORY_ID_PORTFOLIO]: {
			label: t('common.portfolio'),
			id: AI_BUILDER_CATEGORY_ID_PORTFOLIO,
			amplitudeName: 'Portfolio',
			onboardingRedirectParamValue: 'portfolio',
		},
		[AI_BUILDER_CATEGORY_ID_RESTAURANT]: {
			label: t('common.restaurant'),
			id: AI_BUILDER_CATEGORY_ID_RESTAURANT,
			amplitudeName: 'Restaurant',
			onboardingRedirectParamValue: 'restaurant',
		},
		[AI_BUILDER_CATEGORY_ID_WEDDINGS]: {
			label: t('builder.weddings'),
			id: AI_BUILDER_CATEGORY_ID_WEDDINGS,
			amplitudeName: 'Weddings invitation',
			onboardingRedirectParamValue: 'weddings',
		},
		[AI_BUILDER_CATEGORY_ID_OTHER]: {
			label: t('siteSettings.domain.domainConnectionModal.otherProvider'),
			id: AI_BUILDER_CATEGORY_ID_OTHER,
			amplitudeName: 'Other',
			onboardingRedirectParamValue: 'other',
		},
	};

	const isFetchingWebsiteType = ref(false);

	const isValidBrandName = computed(() => validateFormInput(brandName.value));
	const isValidBrandDescription = computed(() => validateDescription());
	const isValidBusinessType = computed(() => !!websiteType.value && validateFormInput(websiteType.value));
	const isFormValid = computed(() => isValidBrandName.value && isValidBrandDescription.value && isValidBusinessType.value);
	const isCreateWebsiteButtonDisabled = computed(() => !isFormValid.value || isFetchingWebsiteType.value);

	const isWebsiteTypeSuggestionVisible = computed(
		() => websiteType.value === AI_BUILDER_CATEGORY_ID_STORE,
	);

	const setWebsiteType = (id?: WebsiteType) => {
		websiteType.value = id;
	};

	const resetFormInputs = () => {
		brandName.value = '';
		websiteType.value = undefined;
		setDescriptionValue('');
		setWebsiteType();
	};

	const debouncedFetchSuggestedWebsiteType = debounce(async () => {
		if (!isValidBrandDescription.value) {
			return;
		}

		isFetchingWebsiteType.value = true;
		try {
			const { data } = await fetchWebsiteType({
				description: brandDescription.value,
				brandName: isValidBrandName.value ? brandName.value : undefined,
			});

			const capitalizedWebsiteType = data.websiteType.charAt(0).toUpperCase() + data.websiteType.slice(1);

			setWebsiteType(capitalizedWebsiteType);
		} catch (error) {
			console.error('Failed to fetch suggested website type', error);

			setWebsiteType(AI_BUILDER_CATEGORY_ID_OTHER);
		} finally {
			isFetchingWebsiteType.value = false;
		}
	}, TYPE_SUGGESTION_DEBOUNCE_TIME);

	const handleOnlineStoreCheckboxChange = (value: boolean) => {
		isOnlineStoreSuggestionDeclined.value = !value;
	};

	return {
		AI_BUILDER_CATEGORIES,
		brandName,
		websiteType,
		isFormValid,
		isValidBrandName,
		isValidBusinessType,
		currentStep,
		isDescriptionUpdatedByVoice,
		isDescriptionUpdatedManually,
		selectedColors,
		setSelectedColors,
		isCollapsibleContainerOpen,
		isPaletteListPopupOpen,
		selectedColorsTag,
		resetFormInputs,
		selectedHostingReferenceId,
		isWebsiteTypeSuggestionVisible,
		debouncedFetchSuggestedWebsiteType,
		shouldAddOnlineStore,
		setWebsiteType,
		isFetchingWebsiteType,
		brandDescription,
		descriptionProgress,
		descriptionError,
		setDescriptionValue,
		setBusinessTypeId,
		setBrandName,
		validateDescription,
		onDescriptionBlur,
		isCreateWebsiteButtonDisabled,
		handleOnlineStoreCheckboxChange,
		isOnlineStoreSuggestionDeclined,
	};
};
