<template>
	<div
		ref="userLayoutRef"
		class="assets-layout"
		:class="{
			'assets-layout--content-centered': isNoAssetsFound,
			'assets-layout--content-shifted': selectedAssetsCount > 0
		}"
	>
		<ZyroLoader v-if="!hasServerAssetsLoaded" />
		<template v-else-if="!isNoAssetsFound">
			<AssetsGrid
				:assets="validAssetList"
				:is-gallery="isGallery"
				:current-tab="currentTab"
				:visible-categories="(visibleCategories as any)"
				:is-asset-select-button-hidden="isAssetSelectButtonHidden"
				@open-image-details="emit('open-image-details', $event)"
				@select-image="emit('select-image', $event)"
				@move-asset-to="emit('move-asset-to', $event)"
				@rename-asset="emit('rename-asset', $event)"
				@delete-asset="emit('delete-asset', $event)"
			>
				<template
					v-if="currentTab.id === ASSETS_TAB_ID.MY_LIBRARY"
					#breadcrumbs
				>
					<AssetFolderBreadcrumbs />
				</template>
				<template
					v-if="currentTab.id === ASSETS_TAB_ID.MY_LIBRARY && currentDirectoryFolders.length"
					#folders
				>
					<FolderAssetsGrid
						:folders="currentDirectoryFolders"
						@open-directory="emit('open-directory', $event)"
						@rename-asset="emit('rename-asset', $event)"
						@delete-asset="emit('delete-asset', $event)"
					/>
				</template>
			</AssetsGrid>
		</template>
		<GalleryAssetsControls
			v-if="selectedAssetsCount > 0"
			:selected-assets-count="selectedAssetsCount"
			class="assets-layout__gallery-assets-control"
			@select-all="selectAllGalleryImages"
			@deselect-all="deselectSelectedGalleryImages"
			@add-to-gallery="emit('add-to-gallery')"
		/>
		<DragDropOverlay
			v-if="(isNoAssetsFound || isDraggedOver) && hasServerAssetsLoaded"
			class="assets-layout__drag-and-drop-overlay"
			:is-dragged-over="isDraggedOver"
			@open-file-dialog="emit('open-file-dialog')"
		/>
		<!-- Dialogs -->
		<SystemDialogModal
			v-if="invalidAssetsBeingUploaded.length"
			:title="assetTooLargeModalConfig.title"
			:primary-button-text="$t('builder.assetManagerDialogTooCancel')"
			:secondary-button-text="$t('builder.assetManagerDialogSkipUnsupported')"
			@close="removeInvalidAssetsBeingUploaded"
			@click-primary="removeInvalidAssetsBeingUploaded"
			@click-secondary="continueAssetUpload"
		>
			{{ assetTooLargeModalConfig.descriptionOne }}
			<br>
			{{ assetTooLargeModalConfig.descriptionTwo }}
		</SystemDialogModal>
		<SystemDialogModal
			v-else-if="hasSomeAssetsFailedUploading"
			:title="errorMessage.title"
			:primary-button-text="$t('builder.assetManagerDialogRetry')"
			:secondary-button-text="$t('builder.assetManagerDialogSkipUnsupported')"
			@close="deleteFailedAssets"
			@click-primary="retryFailedAssets"
			@click-secondary="deleteFailedAssets"
		>
			{{ errorMessage.description }}
		</SystemDialogModal>
		<AssetDeleteDialog />
	</div>
</template>

<script setup lang="ts">
import {
	ref,
	onMounted,
	computed,
	Ref,
} from 'vue';
import { useI18n } from 'vue-i18n';

import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';

import AssetsGrid from '@/components/builder-modals/modals/asset-manager/user/AssetsGrid.vue';
import FolderAssetsGrid from '@/components/builder-modals/modals/asset-manager/user/FolderAssetsGrid.vue';
import DragDropOverlay from '@/components/builder-modals/modals/asset-manager/user/DragDropOverlay.vue';
import GalleryAssetsControls from '@/components/builder-modals/modals/asset-manager/user/GalleryAssetsControls.vue';
import AssetDeleteDialog from '@/components/builder-modals/modals/asset-manager/user/AssetDeleteDialog.vue';
import AssetFolderBreadcrumbs from '@/components/builder-modals/modals/asset-manager/user/AssetFolderBreadcrumbs.vue';
import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import {
	ASSETS_IMAGE_SIZE_LIMIT_MB,
	ASSETS_DOCUMENT_SIZE_LIMIT_MB,
} from '@/constants/builderConstants';
import { useAssets } from '@/use/useAssets';
import { useAssetManagerFolders } from '@/use/useAssetManagerFolders';
import { isAiGeneratedImageUrl } from '@/utils/urlValidators';
import {
	ASSET_CATEGORY,
	AssetCategories,
	ASSETS_CATEGORIES,
	ASSETS_TAB_ID,
	AssetsTabId,
	FileAsset,
	SUPPORTED_FILE_TYPES,
} from '@/types/fileFormatTypes';

const props = withDefaults(defineProps<{
	isGallery: boolean;
	validAssets: FileAsset[];
	currentTab: { title: string, id: AssetsTabId };
	visibleCategories: AssetCategories;
	isAssetSelectButtonHidden: boolean;
}>(), {
	isGallery: true,
	validAssets: () => [],
	visibleCategories: () => ASSETS_CATEGORIES,
});

const emit = defineEmits<{
	'open-image-details': [any],
	'select-image': [FileAsset],
	'open-directory': [FileAsset],
	'add-to-gallery': [],
	'open-file-dialog': [],
	'rename-asset': [FileAsset],
	'delete-asset': [FileAsset],
	'move-asset-to': [FileAsset],
}>();

const {
	isDraggedOver,
	listenForDragAndDrop,
	deleteFailedAssets,
	invalidAssetsBeingUploaded,
	uploadAssets,
	removeInvalidAssetsBeingUploaded,
	retryFailedAssets,
	hasServerAssetsLoaded,
	selectAllGalleryImages,
	deselectSelectedGalleryImages,
	assets,
}: {
	isDraggedOver: Ref<boolean>;
	listenForDragAndDrop: (element: HTMLElement) => void;
	deleteFailedAssets: () => void;
	invalidAssetsBeingUploaded: Ref<FileAsset[]>;
	uploadAssets: (assets: FileAsset[]) => void;
	removeInvalidAssetsBeingUploaded: () => void;
	retryFailedAssets: () => void;
	hasServerAssetsLoaded: Ref<boolean>;
	selectAllGalleryImages: () => void;
	deselectSelectedGalleryImages: () => void;
	assets: Ref<FileAsset[]>;
} = useAssets(props, {
	emit,
});

const {
	currentDirectoryValidAssets,
	currentDirectoryFolders,
	validAssetsWithPaths,
	folders,
} = useAssetManagerFolders({
	assets: computed(() => props.validAssets),
});

const aiGeneratedImageAssets = computed(() => props.validAssets.filter((asset) => isAiGeneratedImageUrl(asset.url)));

const userLayoutRef = ref<HTMLElement | null>(null);
const { t } = useI18n();

const assetTooLargeFileConfig = {
	title: t('builder.assetManagerDialogAssetTooLargeTitleMixed'),
	descriptionOne: t('builder.assetManagerDialogAssetTooLargeLineOneDescriptionMixed'),
	descriptionTwo: t('builder.assetManagerDialogAssetTooLargeLineTwoDescriptionMixed', [
		ASSETS_IMAGE_SIZE_LIMIT_MB,
		ASSETS_DOCUMENT_SIZE_LIMIT_MB,
	]),
};

const assetTooLargeImageConfig = {
	title: t('builder.assetManagerDialogAssetTooLargeTitleImage'),
	descriptionOne: t('builder.assetManagerDialogAssetTooLargeOneDescriptionImage'),
	descriptionTwo: t('builder.assetManagerDialogAssetTooLargeDescriptionImage', [ASSETS_IMAGE_SIZE_LIMIT_MB]),
};

const assetTooLargeDocumentConfig = {
	title: t('builder.assetManagerDialogAssetTooLargeTitleDocument'),
	descriptionOne: t('builder.assetManagerDialogAssetTooLargeOneDescriptionDocument'),
	descriptionTwo: t('builder.assetManagerDialogAssetTooLargeDescriptionDocument', [ASSETS_DOCUMENT_SIZE_LIMIT_MB]),
};

const validAssetList = computed(() => {
	if (props.currentTab.id === ASSETS_TAB_ID.MY_LIBRARY) {
		return currentDirectoryValidAssets.value;
	}

	if (props.currentTab.id === ASSETS_TAB_ID.AI_IMAGES) {
		return aiGeneratedImageAssets.value;
	}

	return validAssetsWithPaths.value;
});

const errorMessage = computed(() => {
	const unsupportedFormatAssets = assets.value.filter((asset) => asset.hasFailed && asset.isUnsupportedFormat);

	if (unsupportedFormatAssets.length) {
		return {
			title: t('builder.assetManagerDialogAssetUnsupportedFormat'),
			description: t('builder.assetManagerNotSupportedFileTypes', {
				types: SUPPORTED_FILE_TYPES.map((type) => `.${type.toUpperCase()}`).join(', '),
			}),
		};
	}

	const isDimensionsTooLarge = assets.value.some((asset) => asset.hasFailed && asset.isDimensionsTooLarge);

	if (isDimensionsTooLarge) {
		return {
			title: t('builder.assetManagerDialogAssetTooLargeTitleImage'),
			description: t('builder.assetManagerDialogAssetTooLargeDimension'),
		};
	}

	return {
		title: t('builder.assetManagerDialogSomethingWentWrong'),
		description: t('builder.assetManagerDialogSomeFailed'),
	};
});

onMounted(() => {
	listenForDragAndDrop(userLayoutRef.value as HTMLElement);
});

const selectedAssetsCount = computed(() => assets.value.filter((asset) => asset.isGalleryImageSelected).length);
const isNoAssetsFound = computed(() => !assets.value.length && !folders.value.length);
const hasSomeAssetsFailedUploading = computed(() => {
	const failedToUploadAssets = assets.value.filter((asset) => asset.hasFailed);

	return failedToUploadAssets.length > 0;
});
const assetTooLargeModalConfig = computed(() => {
	const invalidAssetCategories = invalidAssetsBeingUploaded.value.map(({ category }) => category);
	const invalidAssetCategoriesUnique = new Set(invalidAssetCategories);

	if (invalidAssetCategoriesUnique.size > 1) {
		return assetTooLargeFileConfig;
	}

	switch (invalidAssetCategories[0]) {
	case ASSET_CATEGORY.DOCUMENT: return assetTooLargeDocumentConfig;
	case ASSET_CATEGORY.IMAGE: return assetTooLargeImageConfig;
	default: return assetTooLargeFileConfig;
	}
});

const continueAssetUpload = () => {
	removeInvalidAssetsBeingUploaded();
	uploadAssets(invalidAssetsBeingUploaded.value);
};
</script>

<style lang="scss" scoped>
.assets-layout {
	$spacing: 22px;

	padding-bottom: 16px;

	// Account for navigation height
	height: calc(100% - 34px);
	overflow: auto;

	&--content-centered {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&--content-shifted {
		padding-bottom: 64px;
	}

	&__gallery-assets-control {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	&__drag-and-drop-overlay {
		// Fix zindexing in relation to other elements that have position: relative
		position: relative;
		grid-area: 1 / 1 / -1 / -1;
		margin: 0 $spacing $spacing;
	}
}
</style>
