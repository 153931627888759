import {
	ref,
	onUnmounted,
} from 'vue';

export const useMousePosition = () => {
	const mouseX = ref<number | null>(null);
	const mouseY = ref<number | null>(null);

	const handleMoveMouse = (e: MouseEvent) => {
		mouseX.value = e.clientX;
		mouseY.value = e.clientY;
	};

	document.addEventListener('mousemove', handleMoveMouse);

	onUnmounted(() => {
		document.removeEventListener('mousemove', handleMoveMouse);
	});

	return {
		mouseX,
		mouseY,
	};
};
