<template>
	<div
		class="action"
		:class="{ 'action--disabled-action-cursor': isDisabled }"
	>
		<ZyroTooltip
			position="right"
			size="small"
			toggle-event="hover"
			content-position="absolute"
			:forced-position="forcedPosition"
			:use-portal="false"
			:hide-tooltip="isActive || isMobileScreen"
			:mode="isDisabled ? 'dark' : 'light'"
		>
			<template #trigger>
				<slot name="beforeTrigger" />
				<slot
					v-if="!!$slots.trigger"
					name="trigger"
				/>
				<BuilderSidebarActionButton
					v-else
					:is-active="isActive"
					:is-disabled="isDisabled"
					:title="title"
					:icon="icon"
					:custom-icon="customIcon"
					:is-icon-filled="isIconFilled"
					@click="$emit('action-click')"
				>
					<template #beforeTitle>
						<slot name="beforeTitle" />
					</template>
				</BuilderSidebarActionButton>
			</template>
			<slot name="tooltip-content">
				{{ title }}
			</slot>
		</ZyroTooltip>
	</div>
</template>

<script setup>
import { useStore } from 'vuex';
import BuilderSidebarActionButton from '@/components/builder-view/sidebar/BuilderSidebarActionButton.vue';
import ZyroTooltip from '@/components/global/ZyroTooltip.vue';
import { computed } from 'vue';

defineProps({
	isActive: {
		type: Boolean,
		default: false,
	},
	isDisabled: {
		type: Boolean,
		default: false,
	},
	title: {
		type: String,
		default: null,
	},
	icon: {
		type: String,
		default: null,
	},
	customIcon: {
		type: String,
		default: null,
	},
	isIconFilled: {
		type: Boolean,
		default: false,
	},
	forcedPosition: {
		type: Object,
		default: () => ({
			left: '64px',
			top: '6px',
			'white-space': 'nowrap',
		}),
	},
});

defineEmits(['action-click']);

const { state } = useStore();
const isMobileScreen = computed(() => state.gui.isMobileScreen);
</script>
