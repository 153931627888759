<template>
	<section class="export-section">
		<h2 class="text-heading-1 export-card__title">
			{{ $t('builder.siteSettingsWebsiteExportWordpress') }}
		</h2>
		<ZyroCard class="export-card">
			<div class="export-card__content">
				<p class="text-body-2 export-card__description">
					{{ $t('builder.siteSettingsWebsiteExportWordpressDescriptionV2') }}
					<a
						data-qa="wordpress-export-learn-more-link"
						target="_blank"
						class="z-link export-card__learn-more-link"
						href="https://support.hostinger.com/en/articles/6572573-website-builder-how-to-export-content-to-wordpress"
					>
						{{ $t('common.learnMore') }}
					</a>
				</p>
				<ZyroFieldInput
					id="wp-domain-input"
					v-model="domainInput"
					:label="$t('builder.siteSettingsWebsiteExportWordpressInputLabel')"
					:placeholder="$t('builder.siteSettingsWebsiteExportWordpressInputPlaceholderV2')"
					:maxlength="WORDPRESS_DOMAIN_MAX_LENGTH"
					class="export-card__input"
					:error="inputError"
				/>
				<HostingerButton
					:is-disabled="!domainInput || isLoading"
					button-type="contained"
					target="_blank"
					class="export-card__export-button"
					@click="handleExport"
				>
					<ZyroLoader
						v-if="isLoading"
						size="24px"
						color="var(--color-light)"
					/>
					<span v-else>
						{{ $t('builder.siteSettingsExportStartExportButton') }}
					</span>
				</HostingerButton>
				<InfoBanner class="export-card__info-banner">
					<template #title>
						<div class="text-body-2">
							{{ $t('builder.siteSettingsWebsiteExportWordpressInfo') }}
							<a
								data-qa="export-card-wp-info-link"
								target="_blank"
								class="z-link"
								@click="redirectToHPanel({ path: 'websites' })"
							>
								{{ $t('builder.siteSettingsWebsiteExportWordpressInfoLink') }}
							</a>
						</div>
					</template>
				</InfoBanner>
			</div>
		</ZyroCard>

		<ZyroCard class="export-card">
			<div class="export-card__export-info">
				<div class="export-card__export-info-section">
					<p class="text-bold-1 export-card__info-title">
						{{ $t('builder.siteSettingsExportWhatCanBeExported') }}
					</p>
					<ul>
						<li
							v-for="item in exportedInfoTitlesAndSubtitles"
							:key="item.title"
							class="text-body-2 export-card__exported-data-item"
						>
							<Icon
								name="check"
								class="export-card__check-icon"
								dimensions="24px"
							/>
							<div>
								<p>
									{{ item.title }}
									<span v-if="item.link">
										(<a
											:data-qa="`export-card-info-${item.link.id}`"
											target="_blank"
											class="z-link export-card__info-link"
											@click="item.link.action"
										>
											{{ item.link.text }}
										</a>)
									</span>
								</p>
							</div>
						</li>
					</ul>
				</div>
				<div class="export-card__export-info-section">
					<p class="text-bold-1 export-card__info-title">
						{{ $t('builder.siteSettingsExportWhatCantBeExported') }}
					</p>
					<ul>
						<li
							v-for="item in notExportedInfoTitlesAndSubtitles"
							:key="item.title"
							class="text-body-2 export-card__exported-data-item"
						>
							<Icon
								name="close"
								class="export-card__close-icon"
							/>

							<div>
								<p class="export-card__item-name">
									{{ item.title }}
								</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</ZyroCard>
	</section>
</template>

<script setup lang="ts">

import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import Icon from '@/components/global/Icon.vue';
import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';
import InfoBanner from '@/components/ui/InfoBanner.vue';
import { useRedirects } from '@/use/useRedirects';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useI18n } from 'vue-i18n';
import { getIdentify } from '@/api/WordpressApi';
import { debounce } from '@zyro-inc/site-modules/utils/debounce';
import { REGEX_URL_START } from '@zyro-inc/site-modules/constants/regex';
import { type AxiosError } from 'axios';

const { t } = useI18n();
const { state } = useStore();
const { redirectToHPanel } = useRedirects();

const websiteId = computed(() => state.websiteId);
const isLoading = ref(false);
const inputError = ref<string | null>(null);

const exportedInfoTitlesAndSubtitles = [
	{
		title: t('builder.siteSettingsExportTexts'),
	},
	{
		title: t('builder.siteSettingsExportImages'),
	},
	{
		title: t('builder.siteSettingsExportPages'),
	},
	{
		title: t('builder.siteSettingsExportBlogPosts'),
	},
	{
		title: t('builder.siteSettingsExportFileMediaLibrary'),
		link: {
			id: 'media-library-link',
			text: t('builder.siteSettingsExportFileMediaLibraryLink'),
			action: () => {
				window.open('https://codex.wordpress.org/Uploading_Files', '_blank');
			},
		},
	},
];

const notExportedInfoTitlesAndSubtitles = [
	{
		title: t('builder.siteSettingsExportSiteStyling'),
	},
	{
		title: t('builder.siteSettingsExportOnlineStore'),
	},

	{
		title: t('builder.siteSettingsExportIntegrations'),
	},
	{
		title: t('builder.siteSettingsExportFormsSubmissions'),
	},
	{
		title: t('builder.siteSettingsExportSEOSettings'),
	},
];

const WORDPRESS_DOMAIN_MAX_LENGTH = 255;

const domainInput = ref('');

const handleIdentify: (domain: string) => void = debounce(async (domain: string) => {
	try {
		const authorizationUrl = await getIdentify({
			siteId: websiteId.value,
			domain,
		});

		window.open(authorizationUrl, '_self');
	} catch (error) {
		let message = 'builder.wordpressIdentifyServerError';
		const status = (error as AxiosError).response?.status;

		if (status === 401) {
			message = 'builder.wordpressIdentifyAuthError';
		}

		if (status === 403) {
			message = 'builder.wordpressIdentifyPassNotSupportError';
		}

		if (status === 422) {
			message = 'builder.wordpressIdentifyInvalidDomainError';
		}

		if (status === 404) {
			message = 'builder.wordpressIdentifyNotFoundError';
		}

		inputError.value = t(message);
	} finally {
		isLoading.value = false;
	}
}, 1500);

const handleExport = async () => {
	isLoading.value = true;
	inputError.value = null;

	const preparedDomain = domainInput.value.replace(REGEX_URL_START, '').replace(/\/+$/, '');

	handleIdentify(preparedDomain);
};
</script>

<style lang="scss" scoped>
	.export-card {
		padding: 24px;
		border: 1px solid var(--color-gray-border);
		border-radius: 16px;

		:deep(.zyro-input__input) {
			background: var(--color-light);
			border-color: var(--color-gray-border);
		}

		&__title {
			font-weight: 500;
			margin-bottom: 32px;
		}

		&__description {
			margin-bottom: 20px;
		}

		&__input {
			margin: 4px 0 16px;
		}

		&__info-title {
			font-size: 14px;
		}

		&__export-button {
			min-width: 130px;
		}

		&__info-link {
			display: inline;
		}

		&__check-icon {
			color: $color-success;
		}

		&__close-icon {
			color: $color-danger;
		}

		&__info-banner {
			margin-top: 32px;
		}

		&__learn-more-link {
			text-decoration: underline
		}

		&__export-info {
			display: flex;

			@media screen and (max-width: $media-mobile) {
				flex-direction: column;
				gap: 16px;
			}
		}

		&__export-info-section {
			width: 50%;

			& > p {
				margin-bottom: 16px;
			}

			& ul {
				display: flex;
				flex-direction: column;
				gap: 8px;
				list-style: none;
			}

			@media screen and (max-width: $media-mobile) {
				width: 100%;
			}
		}

		&__exported-data-item {
			display: flex;
			gap: 8px;
		}
	}
</style>
