<template>
	<form
		class="stripe-set-up"
		@submit.prevent="$emit('save-key', tempApiKey)"
	>
		<div class="stripe-set-up__text">
			<p class="text-bold-1 stripe-set-up__title">
				{{ $t('builder.editButton.stripe.setup') }}
			</p>
			<i18n-t
				tag="p"
				class="text-body-1 text-body-2"
				keypath="builder.editButton.stripe.activateKey"
			>
				<a
					class="z-link"
					:href="$t('siteSettings.integrationStripeLinkHref')"
					target="_blank"
					v-text="$t('builder.editButton.stripe.activateKeyHow')"
				/>
			</i18n-t>
		</div>
		<ZyroFieldInput
			v-model="tempApiKey"
			class="stripe-set-up__field"
			qa-selector="stripe-settings-setup-input-apikey"
			:placeholder="$t('builder.editButton.stripe.publicApiKey')"
			:label="$t('builder.editButton.stripe.publicApiKey')"
			:error="isApiKeyValid ? null : 'The publishable API key doesn\'t seem to be right. Please try again.'"
		/>
		<HostingerButton
			v-qa="'stripe-settings-setup-button-save'"
			class="stripe-set-up__button"
			type="submit"
		>
			{{ $t('common.save') }}
		</HostingerButton>
	</form>
</template>

<script>
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		HostingerButton,
		ZyroFieldInput,
	},

	props: {
		isApiKeyValid: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['save-key'],
	data() {
		return {
			tempApiKey: null,
		};
	},
});
</script>

<style lang="scss" scoped>
.stripe-set-up {
	text-align: center;

	&__title {
		margin-bottom: 8px;
	}

	&__text {
		margin: 24px 0;
	}

	&__field,
	&__button {
		margin-bottom: 16px;
	}
}
</style>
