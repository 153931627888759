import { useStore } from 'vuex';
import { captureException } from '@sentry/vue';
import {
	computed,
	watch,
} from 'vue';
import { checkIfHtmlHasGivenTags } from '@/utils/rehypeUtils';
import { connectWebsocket } from '@/utils/websocketWrapper';
import { useI18n } from 'vue-i18n';
import EventLogApi from '@/api/EventLogApi';
import {
	GAMIFICATION_TASK_START,
	GAMIFICATION_TASK_CHANGE_HEADING,
	GAMIFICATION_TASK_UPDATE_IMAGE,
	GAMIFICATION_TASK_CHANGE_PARAGRAPH,
	GAMIFICATION_TASK_CHANGE_LOGO,
	GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS,
	GAMIFICATION_TASK_CHECK_MOBILE_VIEW,
	GAMIFICATION_TASK_CHANGE_SEO,
	GAMIFICATION_TASK_CONNECT_DOMAIN,
	GAMIFICATION_TASK_GO_LIVE,
	GAMIFICATION_EVENT_NAMES,
	DRAWER_SEO,
	DRAWER_ADD_ELEMENT,
	DRAWER_MULTI_PAGE,
	EDIT_BLOCK_NAVIGATION_TAB_ID_LOGO,
	GAMIFICATION_ID_TO_TRANSLATED_NAME_MAP,
	GAMIFICATION_TASK_ADD_PRODUCT,
	GAMIFICATION_TASK_ADD_PAYMENT_METHOD,
	GAMIFICATION_TASK_UPDATE_COMPANY_DETAILS,
	GAMIFICATION_TASK_UPDATE_SHIPPING_OPTIONS,
	GAMIFICATION_PRODUCT_TYPES,
} from '@/constants/builderConstants';
import {
	Achievement,
	GamificationTaskKey,
	WSAchievement,
	WSAchievementKeys,
	AchievementsMessageData,
	MessageData,
} from '@/types/gamification';
import {
	SiteElementType,
	SiteElement,
	SiteElementGridTextBox,
} from '@hostinger/builder-schema-validator';
import {
	BLOCK_SLOT_FOOTER,
	ELEMENT_TYPE_TEXT_BOX,
	BLOCK_TYPE_NAVIGATION,
	ELEMENT_TYPE_SOCIAL_ICONS,
	ELEMENT_TYPE_IMAGE,
	BLOCK_TYPE_LAYOUT,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { OPEN_DRAWER } from '@/store/builder/gui';
import { useOnboarding } from '@/components/onboarding/useOnboarding';
import {
	MOBILE_SWITCH_BUTTON_SELECTOR,
	PUBLISH_BUTTON_SELECTOR,
} from '@/components/onboarding/onboardingSelectors';
import { useOverlay } from '@/use/useOverlay';
import { useRedirects } from '@/use/useRedirects';
import { useDomainStore } from '@/stores/domainStore';
import {
	getIsExperimentActive,
	EXPERIMENT_IDS,
	EXPERIMENT_VARIANTS,
} from '@/utils/experiments';
import { getAuthToken } from '@/utils/auth';
import { useAuthStore } from '@/stores/authStore';
import { useEcommerceAdminStore } from '@/stores/ecommerceAdminStore';
import { useGamificationStore } from '@/stores/gamificationStore';

const GET_ACHIEVEMENTS = 'getAchievements';
const COMPLETE_ACHIEVEMENT = 'completeAchievement';
const WEBSOCKET_RECONNECT_TIMEOUT = 1000;
const HEADER_TAGS = [
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
];

const achievementsAsTips = [
	GAMIFICATION_TASK_CHANGE_SEO,
	GAMIFICATION_TASK_CHANGE_LOGO,
];

export const useGamification = () => {
	const IsGamificationDomainExperimentEnabled: boolean = getIsExperimentActive(
		EXPERIMENT_IDS.GAMIFICATION_DOMAINS,
		EXPERIMENT_VARIANTS[EXPERIMENT_IDS.GAMIFICATION_DOMAINS].VAR_1,
	);

	const authStore = useAuthStore();

	const {
		dispatch,
		state,
		getters,
	} = useStore();
	const domainStore = useDomainStore();
	const {
		setHighlightedElement,
		showOverlay,
	} = useOverlay();
	const { endOnboarding } = useOnboarding();
	const {
		redirectToHPanelAddDomain,
		redirectToHPanelDomainSettings,
	} = useRedirects();

	const { t } = useI18n();
	const {
		openProductsAddInIframe,
		openPaymentsInIframe,
		openCompanyInformationInIframe,
		openShippingEditInIframe,
	} = useEcommerceAdminStore();
	const gamificationStore = useGamificationStore();

	const isWebsiteWithEcommerce = computed<boolean>(() => getters['ecommerce/isSiteWithEcommerceItems']);
	const otherTips = computed(() => gamificationStore.achievements.filter(({ id }) => achievementsAsTips.includes(id)));

	const achievements = computed<Achievement[]>(() => gamificationStore.achievements.filter(({
		id,
		type,
	}) => {
		const excludedAchievements = [
			GAMIFICATION_TASK_CHANGE_SEO,
			GAMIFICATION_TASK_CHANGE_LOGO,
			...[!IsGamificationDomainExperimentEnabled && GAMIFICATION_TASK_CONNECT_DOMAIN],
		];

		return !excludedAchievements.includes(id) && type !== GAMIFICATION_PRODUCT_TYPES.ECOMMERCE;
	}));
	const ecommerceTips = computed(() => {
		if (!isWebsiteWithEcommerce.value) {
			return [];
		}

		return gamificationStore.achievements?.filter(({ type }) => type === GAMIFICATION_PRODUCT_TYPES.ECOMMERCE);
	});
	const completedEcommerceTips = computed<Achievement[]>(() => ecommerceTips.value.filter(({ isCompleted }) => isCompleted));
	const completedEcommerceTipsCount = computed<number>(() => completedEcommerceTips.value.length);
	const isConnectDomainAchievementAvailableInBackend = computed<boolean>(
		() => gamificationStore.achievements.some(({ id }) => id === GAMIFICATION_TASK_CONNECT_DOMAIN),
	);
	const completedAchievements = computed<Achievement[]>(() => achievements.value.filter(({ isCompleted }) => isCompleted));
	const lastCompletedAchievement = computed<Achievement | undefined>(() => gamificationStore.lastCompletedAchievement);
	const completedAchievementsCount = computed<number>(() => completedAchievements.value.length);
	const areAllAchievementsCompleted = computed<boolean>(
		() => achievements.value?.length > 0 && achievements.value.length === completedAchievementsCount.value,
	);
	const isGamificationLoaded = computed<boolean>(() => gamificationStore.isGamificationLoaded);
	const footerBlockId = computed<string | undefined>(() => Object.keys(getters.siteBlocks)
		.find((key) => getters.siteBlocks[key].slot === BLOCK_SLOT_FOOTER));
	const websiteId = computed<string>(() => state.websiteId);

	const getElementPopperReference = (selector: string) => `[data-popper-reference="${selector}"]`;

	const isParagraphElement = (html: string | undefined) => checkIfHtmlHasGivenTags({
		html,
		tagsToMatch: ['p'],
	});
	const isHeadingElement = (html: string | undefined) => checkIfHtmlHasGivenTags({
		html,
		tagsToMatch: HEADER_TAGS,
	});

	const getAchievementById = (id: string) => gamificationStore.achievements?.find((item) => id === item.id);

	const isCustomDomainAchievementCompleted = computed<boolean>(() => !!getAchievementById(GAMIFICATION_TASK_CONNECT_DOMAIN)?.isCompleted);

	const isGamificationVisible = computed<boolean>(() => {
		const isLoadingFailed = isGamificationLoaded.value && !achievements.value.length;

		return gamificationStore.isGamificationAvailableForSite && !isLoadingFailed;
	});

	const getCurrentPageElementsByType = ({ elementType }: { elementType: SiteElementType }): (SiteElement & { id: string })[] => {
		const currentPageBlockIds: string[] = [
			...getters.currentPage.blocks,
			...(footerBlockId.value ? [footerBlockId.value] : []),
		];

		return currentPageBlockIds
			.flatMap((blockId) => {
				const blockElements: string[] = getters.siteBlocks[blockId].components || [];

				return blockElements.map(
					(elementId) => ({
						id: elementId,
						...getters.siteElements[elementId],
					}),
				);
			})
			.filter((item) => getters.siteElements[item.id].type === elementType);
	};

	const scrollElementIntoView = (selector: string) => {
		const domElement = document.querySelector(selector);

		domElement?.scrollIntoView({
			block: 'center',
		});
	};

	const selectCurrentElement = (elementId: string | undefined) => {
		dispatch('updateCurrentBlockId', null);
		dispatch('selectCurrentElement', {
			elementId,
		});
		dispatch('enterElementEditMode');
	};

	const achievementData = computed<Partial<Achievement>[]>(() => [
		{
			id: GAMIFICATION_TASK_START,
		},
		{
			id: GAMIFICATION_TASK_CHANGE_HEADING,
			tooltipText: t('builder.gamificationHeadingEdited'),
			clickAction: () => {
				const elements = getCurrentPageElementsByType({
					elementType: ELEMENT_TYPE_TEXT_BOX,
				}) as (SiteElementGridTextBox & { id: string })[];

				const element = elements.find((item) => isHeadingElement(item.content));

				if (!element) {
					dispatch(`gui/${OPEN_DRAWER}`, {
						id: DRAWER_ADD_ELEMENT,
					});

					return;
				}

				scrollElementIntoView(`[data-element-ref="${element.id}"]`);
				selectCurrentElement(element.id);
			},
		},
		{
			id: GAMIFICATION_TASK_UPDATE_IMAGE,
			tooltipText: t('builder.gamificationImageUpdated'),
			clickAction: () => {
				const [element] = getCurrentPageElementsByType({
					elementType: ELEMENT_TYPE_IMAGE,
				});

				if (element) {
					scrollElementIntoView(`[data-element-ref="${element.id}"]`);
					selectCurrentElement(element.id);

					return;
				}

				const pageBlocks: string[] = getters.currentPage.blocks;

				const blockWithBackground = pageBlocks
					.map((blockId) => ({
						id: blockId,
						...getters.siteBlocks[blockId],
					}))
					.find((item) => item.type === BLOCK_TYPE_LAYOUT && item.background.current === 'image');

				if (!blockWithBackground) {
					dispatch(`gui/${OPEN_DRAWER}`, {
						id: DRAWER_ADD_ELEMENT,
					});

					return;
				}

				scrollElementIntoView(`[data-block-ref="${blockWithBackground.id}"]`);

				dispatch('updateCurrentBlockId', blockWithBackground.id);

				dispatch('setDefaultBlockEditTab', 'background');
				dispatch('enterBlockEditMode');
			},
		},
		{
			id: GAMIFICATION_TASK_CHANGE_PARAGRAPH,
			tooltipText: t('builder.gamificationParagraphEdited'),
			clickAction: () => {
				const elements = getCurrentPageElementsByType({
					elementType: ELEMENT_TYPE_TEXT_BOX,
				}) as (SiteElementGridTextBox & { id: string })[];

				const element = elements.find((item) => isParagraphElement(item.content));

				if (!element) {
					dispatch(`gui/${OPEN_DRAWER}`, {
						id: DRAWER_ADD_ELEMENT,
					});

					return;
				}

				scrollElementIntoView(`[data-element-ref="${element.id}"]`);
				selectCurrentElement(element.id);
			},
		},
		{
			id: GAMIFICATION_TASK_CHANGE_LOGO,
			tooltipText: t('builder.gamificationLogoUpdated'),
			clickAction: () => {
				const hasHeader = getters.siteBlocks.header?.type === BLOCK_TYPE_NAVIGATION;

				if (!hasHeader || getters.isNavHidden) {
					dispatch(`gui/${OPEN_DRAWER}`, {
						id: DRAWER_MULTI_PAGE,
					});

					return;
				}

				scrollElementIntoView('#header');

				dispatch('updateCurrentBlockId', 'header');
				dispatch('setDefaultBlockEditTab', EDIT_BLOCK_NAVIGATION_TAB_ID_LOGO);
				dispatch('enterBlockEditMode');
			},
		},
		{
			id: GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS,
			tooltipText: t('builder.gamificationSocialIconsChanged'),
			clickAction: () => {
				const [element] = getCurrentPageElementsByType({
					elementType: ELEMENT_TYPE_SOCIAL_ICONS,
				});

				if (!element) {
					dispatch(`gui/${OPEN_DRAWER}`, {
						id: DRAWER_ADD_ELEMENT,
					});

					return;
				}

				scrollElementIntoView(`[data-element-ref="${element.id}"]`);
				selectCurrentElement(element.id);
			},
		},
		{
			id: GAMIFICATION_TASK_CHECK_MOBILE_VIEW,
			tooltipText: t('builder.gamificationMobileChecked'),
			clickAction: () => {
				const elementPopperReference = getElementPopperReference(MOBILE_SWITCH_BUTTON_SELECTOR);
				const mobileViewButton = document.querySelectorAll(elementPopperReference)[1];

				setHighlightedElement({
					element: mobileViewButton,
				});

				showOverlay(true);
			},
		},
		{
			id: GAMIFICATION_TASK_CHANGE_SEO,
			tooltipText: t('builder.gamificationSeoUpdated'),
			clickAction: () => {
				dispatch('gui/OPEN_DRAWER', {
					id: DRAWER_SEO,
				});
			},
		},
		{
			id: GAMIFICATION_TASK_CONNECT_DOMAIN,
			tooltipText: t('builder.gamificationDomainConnected'),
			clickAction: () => {
				if (isCustomDomainAchievementCompleted.value) {
					redirectToHPanelDomainSettings({
						// @ts-ignore
						currentDomain: domainStore.customDomain,
						siteId: state.websiteId,
					});

					return;
				}

				redirectToHPanelAddDomain({
					// @ts-ignore
					currentDomain: domainStore.customDomain,
					siteId: state.websiteId,
				});
			},
		},
		{
			id: GAMIFICATION_TASK_GO_LIVE,
			tooltipText: t('builder.gamficationPublished'),
			clickAction: () => {
				const elementPopperReference = getElementPopperReference(PUBLISH_BUTTON_SELECTOR);

				setHighlightedElement({
					element: document.querySelector(elementPopperReference),
				});

				showOverlay(true);
			},
		},
		{
			id: GAMIFICATION_TASK_ADD_PRODUCT,
			clickAction: () => openProductsAddInIframe(),
		},
		{
			id: GAMIFICATION_TASK_ADD_PAYMENT_METHOD,
			clickAction: () => openPaymentsInIframe(),
		},
		{
			id: GAMIFICATION_TASK_UPDATE_COMPANY_DETAILS,
			clickAction: () => openCompanyInformationInIframe(),
		},
		{
			id: GAMIFICATION_TASK_UPDATE_SHIPPING_OPTIONS,
			clickAction: () => openShippingEditInIframe({
				shouldEditFirst: 1,
			}),
		},
	]);

	const getAchievements = (): void => {
		if (!gamificationStore.ws || gamificationStore.ws.readyState !== 1) {
			throw new Error("Websocket doesn't exist or is not connected");
		}

		const jsonData = JSON.stringify({
			method: GET_ACHIEVEMENTS,
		});

		gamificationStore.ws.send(jsonData);
	};

	const completeAchievement = async (taskKey: GamificationTaskKey) => {
		const isCompleted = !!getAchievementById(taskKey)?.isCompleted;
		const isUnavailableConnectDomainAchievement = (
			!IsGamificationDomainExperimentEnabled || !isConnectDomainAchievementAvailableInBackend.value
		) && taskKey === GAMIFICATION_TASK_CONNECT_DOMAIN;

		if (
			!isGamificationVisible.value
			|| isCompleted
			|| isUnavailableConnectDomainAchievement
		) {
			return;
		}

		if (!gamificationStore.ws) {
			// eslint-disable-next-line no-use-before-define
			await connectToWebsocket(websiteId.value);
		}

		if (!gamificationStore.ws || gamificationStore.ws.readyState !== 1) {
			return;
		}

		const jsonData = JSON.stringify({
			method: COMPLETE_ACHIEVEMENT,
			slug: taskKey,
		});

		try {
			gamificationStore.ws.send(jsonData);

			EventLogApi.logEvent({
				eventName: GAMIFICATION_EVENT_NAMES.TASK_COMPLETED[taskKey],
			});

			const isLastAchievement = achievements.value
				.every((achievement) => (achievement.id === taskKey || achievement?.isCompleted));

			if (isLastAchievement) {
				EventLogApi.logEvent({
					eventName: GAMIFICATION_EVENT_NAMES.COMPLETED,
				});
			}
		} catch (error) {
			dispatch('notifications/notify', {
				messageI18nKeyPath: 'builder.gamificatioFailedError',
			});

			captureException(error);
		}
	};

	const handleCloseEvent = async () => {
		if (areAllAchievementsCompleted.value) {
			return;
		}

		gamificationStore.setWebsocketObject();

		// delay reconnect to avoid potentially spamming the server
		await new Promise((resolve) => {
			window.setTimeout(resolve, WEBSOCKET_RECONNECT_TIMEOUT);
		});

		// eslint-disable-next-line no-use-before-define
		connectToWebsocket(websiteId.value);
	};

	const handleMessageEvent = async (messageEvent: MessageEvent<MessageData>) => {
		if (!messageEvent.data || messageEvent.data === 'JWT verification failed') {
			gamificationStore.setIsGamificationLoaded(true);

			// eslint-disable-next-line no-use-before-define
			closeWebsocketConnection();

			return;
		}

		try {
			const parsedData: AchievementsMessageData = JSON.parse(messageEvent.data.toString());

			if (!parsedData?.achievements) {
			// eslint-disable-next-line no-use-before-define
				closeWebsocketConnection();

				return;
			}

			const oldAchievements = [...achievements.value];
			const createdAt = new Date(parsedData.created_at).getTime();

			const achievementEntries = Object.entries(parsedData.achievements) as [WSAchievementKeys, WSAchievement][];

			const tasks: Achievement[] = achievementEntries
				.map(([key, value]) => {
					const found = achievementData.value.find(((action) => action.id === key));

					return {
						id: key,
						drawerNote: found?.drawerNote ? t(found?.drawerNote) : '',
						isCompleted: !!value.achieved_at,
						achievedAt: value.achieved_at,
						name: t(GAMIFICATION_ID_TO_TRANSLATED_NAME_MAP[key]),
						clickAction: found?.clickAction,
						tooltipText: found?.tooltipText,
						type: value.product,
					};
				});

			gamificationStore.setAchievements(tasks);
			gamificationStore.setIsGamificationLoaded(true);
			gamificationStore.setCreatedAt(createdAt);

			const isAchievementNewlyCompleted = (achievement: Achievement) => oldAchievements.some(
				(oldAchievement) => achievement.id === oldAchievement.id
			&& achievement?.isCompleted !== oldAchievement?.isCompleted,
			);

			const completedAchievement = achievements.value.find((achievement) => isAchievementNewlyCompleted(achievement));

			if (completedAchievement) {
				gamificationStore.setLastCompletedAchievement(completedAchievement);
			}

			const isGamificationTaskStartCompleted = !!getAchievementById(GAMIFICATION_TASK_START)?.isCompleted;

			if (!isGamificationTaskStartCompleted && isGamificationVisible.value) {
				endOnboarding();

				completeAchievement(GAMIFICATION_TASK_START);
			}
		} catch (error) {
			const isRefreshNeeded = String(error).includes('jwt expired');

			if (isRefreshNeeded) {
				await authStore.refreshToken('');

				// eslint-disable-next-line no-use-before-define
				closeWebsocketConnection();
				// eslint-disable-next-line no-use-before-define
				connectToWebsocket(websiteId.value);
			}
		}
	};

	const closeWebsocketConnection = () => {
		if (!gamificationStore.ws) {
			return;
		}

		try {
			gamificationStore.ws.close();

			gamificationStore.setWebsocketObject();
		} catch (error) {
			dispatch('notifications/notify', {
				messageI18nKeyPath: 'builder.gamificatioFailedError',
			});

			captureException(error);
		}
	};

	const connectToWebsocket = async (siteId: string) => {
		if (!gamificationStore.isGamificationAvailableForSite) {
			gamificationStore.setIsGamificationLoaded(true);

			return;
		}

		if (!siteId || gamificationStore.ws) {
			return;
		}

		try {
			const websocket = await connectWebsocket({
				url: `${import.meta.env.VITE_GAMIFICATION_WEBSOCKET_URL}/gamification?sso_token=${getAuthToken()}&site_id=${siteId}`,
				onMessage: handleMessageEvent,
				onClose: handleCloseEvent,
				enableRepeatMessageProtection: true,
			});

			gamificationStore.setWebsocketObject(websocket);

			if (!achievements.value.length) {
				getAchievements();
			}
		} catch (error) {
			captureException(error);

			gamificationStore.setIsGamificationLoaded(true);

			dispatch('notifications/notify', {
				messageI18nKeyPath: 'builder.gamificationFailedErrorV2',
			});
		}
	};

	watch(areAllAchievementsCompleted, (value) => {
		if (value) {
			closeWebsocketConnection();
		}
	});

	return {
		achievements,
		otherTips,
		completedAchievementsCount,
		areAllAchievementsCompleted,
		connectToWebsocket,
		closeWebsocketConnection,
		completeAchievement,
		achievementData,
		isParagraphElement,
		isHeadingElement,
		handleMessageEvent,
		isGamificationVisible,
		lastCompletedAchievement,
		getAchievementById,
		ecommerceTips,
		completedEcommerceTipsCount,
	};
};
