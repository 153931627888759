<script lang="ts" setup>
import { SUPPORT_ARTICLE_LINK_BACKUPS } from '@/constants/builderConstants';
import ZyroModal from '@/components/global/ZyroModal.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useStore } from 'vuex';

const { dispatch } = useStore();

const closeModal = () => {
	dispatch('gui/CLOSE_MODAL');
};
</script>
<template>
	<Teleport to="body">
		<ZyroModal
			max-width="600px"
			height="auto"
			class="no-backups-modal"
			:title="$t('builder.versionHistoryNoBackups')"
			@close-modal="closeModal"
		>
			<template #subtitle>
				<i18n-t
					tag="p"
					class="no-backups-modal__subtitle text-body-2"
					keypath="builder.versionHistoryNoBackupsDescription"
				>
					<a
						class="z-link text-body-2"
						target="_blank"
						:href="SUPPORT_ARTICLE_LINK_BACKUPS"
					>
						{{ $t('builder.siteBackupsLink') }}
					</a>
				</i18n-t>
			</template>
			<div class="no-backups-modal__footer">
				<HostingerButton @click="closeModal">
					{{ $t('common.close') }}
				</HostingerButton>
			</div>
		</ZyroModal>
	</Teleport>
</template>
<style lang="scss" scoped>
.no-backups-modal {
	:deep(.modal) {
		padding: 16px 16px 32px;
	}

	&__subtitle {
		color: $color-gray-dark;
	}

	&__footer {
		display: flex;
		justify-content: flex-end;
		margin-top: 16px;
	}
}
</style>
