<template>
	<div class="button-group">
		<HostingerButton
			v-qa="'builderheader-menuitem-undo'"
			:disabled="isUndoDisabled"
			button-type="plain"
			class="margin-x-4"
			:title="$t('common.undo')"
			@click="executeUndo"
		>
			<template #icon>
				<Icon name="undo" />
			</template>
		</HostingerButton>

		<HostingerButton
			v-qa="'builderheader-menuitem-redo'"
			:disabled="isRedoDisabled"
			button-type="plain"
			class="margin-x-4"
			:title="$t('common.redo')"
			@click="executeRedo"
		>
			<template #icon>
				<Icon name="redo" />
			</template>
		</HostingerButton>
	</div>
</template>
<script setup>
import Icon from '@/components/global/Icon.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import HostingerButton from '@/components/global/HostingerButton.vue';

const {
	getters,
	dispatch,
} = useStore();

const isUndoDisabled = computed(() => getters['undoRedo/isUndoDisabled']);
const isRedoDisabled = computed(() => getters['undoRedo/isRedoDisabled']);

const executeUndo = () => {
	dispatch('undoRedo/executeUndo');
};

const executeRedo = () => {
	dispatch('undoRedo/executeRedo');
};
</script>
<style lang="scss" scoped>
.margin-x-4 {
	margin: 0 4px;
}
</style>
