import {
	ref,
	computed,
	watch,
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useNotifications } from '@/use/useNotifications';
import { useAiWriterStore } from '@/stores/aiWriterStore';
import {
	TEXT_EDITOR_TAG_PARAGRAPH,
	TEXT_EDITOR_CLASS_BODY,
	AI_WRITER_DESCRIPTION_NOT_CLEAR_TEXT_RESPONSE,
} from '@/constants/builderConstants';
import { DATA_ATTRIBUTE_ELEMENT_ID } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { useDeviceElementHeight } from '@/use/useDeviceElementHeight';
import { getColorsFromHTML } from '@/use/useWebsiteColors';
import { storeToRefs } from 'pinia';

const isAiTextGeneratorPopupVisible = ref(false);
const elementPreviousTextContent = ref('');
const aiGeneratedText = ref('');

const userPrompt = ref('');
const isPromptInputTouched = ref(false);

export const useAiTextGeneration = (props: {elementId: string}) => {
	const {
		dispatch,
		getters,
	} = useStore();
	const aiWriterStore = useAiWriterStore();
	const { t } = useI18n();
	const { notify } = useNotifications();
	const { updateElementHeightOnDevices } = useDeviceElementHeight();
	const { isTextBeingGenerated } = storeToRefs(aiWriterStore);

	const currentElementId = computed(() => getters.currentElementId);
	const currentElement = computed(() => getters.currentElement);
	const isEditingTextBoxElement = computed(() => getters.isEditingTextBoxElement);

	const textElementColor = computed(() => {
		const textContentColors = getColorsFromHTML(elementPreviousTextContent.value);

		if (textContentColors.length) {
			return textContentColors[textContentColors.length - 1];
		}

		return '';
	});

	const aiGeneratedTextHtmlContent = computed(() => {
		const style = textElementColor.value ? `style="color: ${textElementColor.value}"` : '';

		return `<${TEXT_EDITOR_TAG_PARAGRAPH} ${style} class="${TEXT_EDITOR_CLASS_BODY}"
			>${aiGeneratedText.value}</${TEXT_EDITOR_TAG_PARAGRAPH}>`;
	});

	const updateElementWithAiGeneratedText = ({ pushToHistory = false }) => {
		dispatch('mergeElementData', {
			elementId: props.elementId,
			elementData: {
				content: `${elementPreviousTextContent.value}${aiGeneratedTextHtmlContent.value}`,
			},
		});

		updateElementHeightOnDevices({
			elementId: props.elementId,
		});

		if (pushToHistory) {
			dispatch('undoRedo/createSnapshot');
		}

		// Scroll to the bottom of the page to show the generated text
		// setTimeout is needed to wait for the element to be updated with new text
		setTimeout(() => {
			const element = document.querySelector(`[${DATA_ATTRIBUTE_ELEMENT_ID}="${props.elementId}"]`);

			if (!element) {
				return;
			}

			element.scrollIntoView({
				block: 'end',
				behavior: 'smooth',
			});
		});
	};

	const generateAiText = async ({ isTryingAgain }:{isTryingAgain: boolean}) => {
		isPromptInputTouched.value = true;

		isAiTextGeneratorPopupVisible.value = false;
		aiGeneratedText.value = '';

		if (!isTryingAgain) {
			elementPreviousTextContent.value = currentElement.value.content;
		}

		const { text } = await aiWriterStore.getAiGeneratedText({
			userPrompt: userPrompt.value,
		});

		if (!text || props.elementId !== currentElementId.value) {
			return;
		}

		if (text === AI_WRITER_DESCRIPTION_NOT_CLEAR_TEXT_RESPONSE) {
			notify({
				message: t('builder.AITextGenerationDescriptionNotClear'),
			});

			isAiTextGeneratorPopupVisible.value = true;

			return;
		}

		aiGeneratedText.value = text;

		updateElementWithAiGeneratedText({
			pushToHistory: false,
		});
	};

	const discardAiGeneratedText = () => {
		dispatch('mergeElementData', {
			elementId: props.elementId,
			elementData: {
				content: elementPreviousTextContent.value,
			},
		});

		aiGeneratedText.value = '';
	};

	const keepAiGeneratedText = () => {
		updateElementWithAiGeneratedText({
			pushToHistory: true,
		});

		aiGeneratedText.value = '';
	};

	const setIsAiTextGeneratorPopupVisible = (value: boolean) => {
		isAiTextGeneratorPopupVisible.value = value;
	};

	watch(currentElementId, (newCurrentElementId) => {
		// Reset AI text generation state if user switched to another element
		if (props && newCurrentElementId !== props.elementId) {
			if (isTextBeingGenerated.value) {
				notify({
					message: t('builder.AITextGenerationStopped'),
				});

				aiWriterStore.setIsTextBeingGenerated(false);
			}

			aiGeneratedText.value = '';
			userPrompt.value = '';
			isPromptInputTouched.value = false;
		}
	});

	watch(isEditingTextBoxElement, () => {
		// Reset AI text generation state if user started editing text box element
		if (
			aiGeneratedText.value.length
			&& isEditingTextBoxElement.value
		) {
			aiGeneratedText.value = '';
			userPrompt.value = '';
		}
	});

	return {
		userPrompt,
		aiGeneratedText,
		isAiTextGeneratorPopupVisible,
		generateAiText,
		discardAiGeneratedText,
		keepAiGeneratedText,
		isPromptInputTouched,
		setIsAiTextGeneratorPopupVisible,
	};
};
