// eslint-disable-next-line import/no-cycle
import { authAxios } from '@/services/http/authAxios';
// eslint-disable-next-line import/no-cycle
import axios from '@/services/http/axios';

const URL = `${import.meta.env.VITE_API_H_SSO}/v1/auth`;

export const refreshAuthToken = () => authAxios.post<{ token: string }>(`${URL}/refresh`, {}, {
	whitelistedStatusCodes: [401],
});

export const generateToken = (app: string) => axios.post<{ data: { token: string } }>(`${URL}/generate-token`, {
	target_application: app,
});
