import { useIsWindowBeingResized } from '@/use/useIsWindowResizing';
import { ref } from 'vue';
import { DATA_ATTRIBUTE_ELEMENT_ID } from '@zyro-inc/site-modules/constants/siteModulesConstants';

const triggerCallbacks: Record<string, () => void> = {};
const isTriggeredByCallback: Record<string, boolean> = {};

// Active element is the one that is being resized or dragged by user but in this context
// we are only concerned with elements that are being resized.
const isParentElementActive = (elementId: string) => !!document.querySelector(
	`[${DATA_ATTRIBUTE_ELEMENT_ID}="${elementId}"].layout-element--is-active`,
);

const resizeObserverInstance = ref(new ResizeObserver((entries) => {
	const { isWindowResizing } = useIsWindowBeingResized();

	if (isWindowResizing.value) {
		return;
	}

	entries.forEach((entry) => {
		const elementId = entry.target.getAttribute('data-element-ref');

		if (!elementId) {
			return;
		}

		// This helps to reduce scope to only elements that resize based on their content.
		if (isParentElementActive(elementId)) {
			return;
		}

		// If resize was caused by resize observer return immediately
		if (isTriggeredByCallback[elementId]) {
			return;
		}

		if (triggerCallbacks[elementId]) triggerCallbacks[elementId]();
	});
}));

export const useLayoutElementResizeObserver = () => {
	const observeLayoutElement = ({
		htmlElement,
		elementId,
		triggerCallback,
	}:{
		htmlElement: HTMLElement,
		elementId: string,
		triggerCallback: () => void
	}) => {
		if (!htmlElement) {
			return;
		}

		// LayoutElement is just a wrapper around the actual component thus we need to observe
		// the subject of resize - component itself.
		const layoutElementComponent = htmlElement.querySelector('.layout-element__component');

		if (triggerCallbacks[elementId] || !layoutElementComponent) {
			return;
		}

		triggerCallbacks[elementId] = () => {
			isTriggeredByCallback[elementId] = true;
			triggerCallback();
			isTriggeredByCallback[elementId] = false;
		};

		resizeObserverInstance.value.observe(layoutElementComponent);
	};

	const unobserveLayoutElement = ({
		htmlElement,
		elementId,
	}:{
		htmlElement: HTMLElement,
		elementId: string,
	}) => {
		if (!triggerCallbacks[elementId] || !htmlElement) {
			return;
		}

		resizeObserverInstance.value.unobserve(htmlElement);

		delete triggerCallbacks[elementId];
		delete isTriggeredByCallback[elementId];
	};

	return {
		observeLayoutElement,
		unobserveLayoutElement,
	};
};
