import {
	InternalAxiosRequestConfig,
	AxiosResponse,
} from 'axios';

export const getNormalisedRequestConfigProperties: (config: InternalAxiosRequestConfig<any> | undefined) => {
    serviceName: string | undefined;
    backendPathname: string | undefined;
} = (config) => {
	if (!config || !config.url) {
		return {
			serviceName: undefined,
			backendPathname: undefined,
		};
	}

	let serviceName: string| undefined;
	let backendPathname: string | undefined;

	try {
		const urlObject = new URL(config.url);

		serviceName = urlObject.origin;
		backendPathname = urlObject.pathname;
	} catch (error) {
		serviceName = config.baseURL;
		backendPathname = config.url;
	}

	return {
		serviceName,
		backendPathname,
	};
};

export const getNormalisedErrorResponseProperties = (response : AxiosResponse<unknown, any> | undefined) => {
	if (!response) {
		return {
			message: undefined,
			status: undefined,
			code: undefined,
		};
	}

	const { data } = response as AxiosResponse<
    {
			message?: string;
			code?: number;
			error?: {
				message?: string;
				code?: number;
			}
		}
  >;

	return {
		status: response.status,
		message: data?.message || data?.error?.message,
		code: data?.code || data?.error?.code,
	};
};
