<template>
	<ZyroFieldToggle
		id="header-button-toggle"
		:model-value="isButtonEnabled"
		:label="t('common.button')"
		@update:model-value="updateIsButtonEnabled"
	/>
	<template v-if="isButtonEnabled">
		<EditButtonTabAction :element-id="headerButtonComponentId" />
		<EditButtonTabStyle :element-id="headerButtonComponentId" />
	</template>
</template>

<script setup lang="ts">
import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import { useAddElement } from '@/use/useAddElement';
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import {
	ELEMENT_TYPE_BUTTON,
	PLACEHOLDER_POSITION,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import EditButtonTabAction from '@/components/builder-controls/edit-button/EditButtonTabAction.vue';
import EditButtonTabStyle from '@/components/builder-controls/edit-button/EditButtonTabStyle.vue';

const { t } = useI18n();
const {
	getters,
	dispatch,
} = useStore();
const { defaultElements } = useAddElement();
const headerComponentsIds = computed<string[]>(() => getters.headerBlock.components);
const headerButtonComponentId = computed(
	() => headerComponentsIds.value.find((componentId) => getters.siteElements[componentId].type === ELEMENT_TYPE_BUTTON),
);
const isButtonEnabled = ref(!!headerButtonComponentId.value);

const addButtonElementToHeader = () => {
	if (headerButtonComponentId.value) {
		return;
	}

	dispatch('addElement', {
		blockId: 'header',
		elementData: {
			...defaultElements.value.button.content,
			...PLACEHOLDER_POSITION,
		},
	});
};

const removeButtonElementFromHeader = () => {
	if (!headerButtonComponentId.value) {
		return;
	}

	dispatch('removeElement', {
		blockId: 'header',
		elementId: headerButtonComponentId.value,
	});
};

const updateIsButtonEnabled = (newValue: boolean) => {
	isButtonEnabled.value = newValue;

	if (newValue) {
		addButtonElementToHeader();
	} else {
		removeButtonElementFromHeader();
	}
};
</script>
