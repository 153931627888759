import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { ImageRatioOption } from '@zyro-inc/site-modules/types';

export const useProductImageRatio = ({ blockId }: {blockId: string}) => {
	const { t } = useI18n();
	const {
		getters,
		dispatch,
	} = useStore();

	const IMAGE_RATIO_OPTIONS = {
		[ImageRatioOption.CONTAIN]: {
			name: t('common.original'),
			icon: 'image-ratio-original',
			iconDimensions: '24px',
		},
		[ImageRatioOption.COVER]: {
			name: t('common.square'),
			icon: 'image-ratio-square',
			iconDimensions: '24px',
		},
		[ImageRatioOption.LANDSCAPE]: {
			name: t('common.landscape'),
			icon: 'image-ratio-landscape',
			iconDimensions: '24px',
		},
		[ImageRatioOption.PORTRAIT]: {
			name: t('common.portrait'),
			icon: 'image-ratio-portrait',
			iconDimensions: '24px',
		},
	} as const;

	const siteBlocks = computed(() => getters.siteBlocks);
	const block = computed(() => siteBlocks.value[blockId]);
	const imageRatio = computed(() => block.value?.imageRatio || 'cover');

	const updateImageRatio = (newImageRatio: string) => {
		dispatch('updateBlockData', {
			blockId,
			blockData: {
				imageRatio: newImageRatio,
			},
			merge: true,
		});
	};

	return {
		imageRatio,
		updateImageRatio,
		IMAGE_RATIO_OPTIONS,
	};
};
