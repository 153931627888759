<template>
	<ZyroDrawer
		class="multilingual-drawer"
		:header-title="$t('builder.multilingualDrawerTitle')"
		:header-description="$t('builder.multilingualDrawerDescription')"
		@back="closeInnerSidebar"
	>
		<template #body>
			<div
				v-qa="'builder-sidemenu-websitemenu'"
				class="multilingual-drawer__wrapper"
			>
				<MultilingualAddLanguage class="multilingual-drawer__add-language" />
				<div class="multilingual-drawer__content">
					<EditableItemsList
						v-if="currentLanguageList.length > 0"
						v-qa="'buildersidebar-multilingual-visiblepages'"
						class="multilingual-drawer__language-list"
						:items="currentLanguageList"
						:is-editable-by-double-click="false"
						@item-click="handleLanguageClick"
						@draggable-end="reorderLanguages"
					>
						<template #item-button="{ item }">
							<MultilingualListItemPopup :item="item" />
						</template>
					</EditableItemsList>
					<div
						v-if="currentLanguageList.length > 0"
						class="multilingual-drawer__description text-body-2"
					>
						<p>
							{{ $t('builder.multilingualDrawerDefaultLangDescription') }}
						</p>
					</div>
					<ol
						v-if="currentLanguageList.length < 2"
						class="multilingual-drawer__tutorial"
					>
						<li class="multilingual-drawer__tutorial-step">
							{{ $t('builder.multilingualDrawerHelpSteps.selectStartingLanguage') }}
						</li>
						<li class="multilingual-drawer__tutorial-step">
							{{ $t('builder.multilingualDrawerHelpSteps.setupOriginal') }}
						</li>
						<li class="multilingual-drawer__tutorial-step">
							{{ $t('builder.multilingualDrawerHelpSteps.fullControl') }}
						</li>
					</ol>

					<InfoBanner
						theme="info"
						class="multilingual-drawer__disclaimer"
					>
						<template #title>
							{{ $t('builder.multilingualDrawerTranslationsDisclaimer') }}
						</template>
					</InfoBanner>
					<NpsRateFeature
						:feature-name="$t('builder.npsRateMultilingual')"
						:type="NPS_TYPE_FEATURE_MULTILINGUAL"
					/>
				</div>
			</div>
		</template>
	</ZyroDrawer>
</template>

<script setup lang="ts">
import ZyroDrawer from '@/components/global/ZyroDrawer.vue';
import InfoBanner from '@/components/ui/InfoBanner.vue';
import { useI18n } from 'vue-i18n';

import { useStore } from 'vuex';
import { useSidebar } from '@/use/useSidebar';

import MultilingualAddLanguage from '@/components/multilingual/MultilingualAddLanguage.vue';
import MultilingualListItemPopup from '@/components/multilingual/MultilingualListItemPopup.vue';
import EditableItemsList from '@/components/reusable-components/editable-items-list/EditableItemsList.vue';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import { NPS_TYPE_FEATURE_MULTILINGUAL } from '@/constants/builderConstants';
import { SiteLanguages } from '@hostinger/builder-schema-validator';

import { computed } from 'vue';

const { t } = useI18n();

const {
	getters,
	dispatch,
} = useStore();

const { closeInnerSidebar } = useSidebar();

const siteLanguagesArray = computed(() => getters.siteLanguagesSortedArray);
const siteLanguages = computed<SiteLanguages>(() => getters.siteLanguages);
const defaultLocale = computed(() => getters.defaultLocale);

const currentLanguageList = computed(() => siteLanguagesArray.value.map((language: { locale: string, isHidden: boolean}) => ({
	...language,
	image: {
		src: `i18n/${language.locale}.png`,
		alt: `${language.locale} Flag`,
	},
	pill: language.locale === defaultLocale.value ? t('builder.multilingualDrawerDefault') : false,
	type: 'Multilingual',
	isDisabled: language.isHidden,
})));

const reorderLanguages = ({
	newIndex,
	oldIndex,
} : {newIndex: number, oldIndex: number}) => {
	const {
		system,
		...restLanguages
	} = siteLanguages.value;
	const LanguageEntries = Object.entries(restLanguages);

	const language = LanguageEntries[oldIndex];

	LanguageEntries.splice(oldIndex, 1);
	LanguageEntries.splice(newIndex, 0, language);

	const orderedLanguageEntries = LanguageEntries.map(([locale, lang], index) => [
		locale,
		{
			...lang,
			orderPosition: index,
		},
	]);

	const reorderedLanguages = Object.fromEntries(orderedLanguageEntries);

	dispatch('updateLanguages', {
		system,
		...reorderedLanguages,
	});
};

const handleLanguageClick = (language: { locale: string}) => {
	dispatch('updateCurrentLocale', language.locale);
};
</script>

<style lang="scss" scoped>
.multilingual-drawer {
	&__language-list {
		margin-top: 0;
	}

	&__description {
		margin: 16px 0;
		color: $color-gray;

		& p:nth-child(2) {
			margin-top: 8px;
			font-weight: bold;
		}
	}

	&__tutorial {
		position: relative;
		margin: 16px;
		color: $color-gray;
	}

	&__tutorial-step {
		margin-bottom: 16px;
		font-size: 14px;
		line-height: 1.71;
	}

	&__help-icon {
		position: absolute;
		top: 0;
		left: -45px;
		color: $color-gray;
	}

	&__dropdown {
		width: 88%;
		margin-top: 29px;
		margin-bottom: 0;
		border-bottom: 1px solid $color-gray-light;
	}

	&__add-language {
		position: relative;
		z-index: 2;
	}

	&__disclaimer {
		font-size: 14px;
		line-height: 1.71;
		margin-bottom: 16px;
	}

	:deep() {
		.item {
			&--disabled {
				.tooltip,
				.flag-container,
				.trigger,
				.item {
					&__content {
						opacity: 0.4;
					}
				}
			}
		}
	}
}

</style>
