import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { CLOSE_DRAWER } from '@/store/builder/gui';

import { BUILDER_ROUTE } from '@/constants/routes';

import { useDrawerPageSettingsPopup } from '@/use/useDrawerPageSettingsPopup';
import { useBuilderMode } from '@/use/useBuilderMode';

const isSidebarOpen = ref(false);
const isSidebarBackdropVisible = ref(false);
const isInnerSidebarOpen = ref(false);

export const useSidebar = () => {
	const { dispatch } = useStore();
	const { isAiBuilderMode } = useBuilderMode();
	const router = useRouter();
	const { activeDrawerPageSettingsPopup } = useDrawerPageSettingsPopup();

	const toggleSidebar = () => {
		isSidebarOpen.value = !isSidebarOpen.value;
	};

	const openInnerSidebar = () => {
		isInnerSidebarOpen.value = true;
	};

	const closeInnerSidebar = () => {
		isInnerSidebarOpen.value = false;

		dispatch(`gui/${CLOSE_DRAWER}`);
		if (router.currentRoute.value.name !== BUILDER_ROUTE && !isAiBuilderMode.value) {
			router.push({
				name: BUILDER_ROUTE,
			});
		}
	};

	const closeSidebar = () => {
		isSidebarOpen.value = false;
		isSidebarBackdropVisible.value = false;
		activeDrawerPageSettingsPopup.value = null;

		closeInnerSidebar();
	};

	return {
		isSidebarOpen,
		isInnerSidebarOpen,
		isSidebarBackdropVisible,
		toggleSidebar,
		closeSidebar,
		openInnerSidebar,
		closeInnerSidebar,
	};
};
