import { REDIRECT_MUTATIONS } from '@/types/redirectModuleTypes';
import {
	RouteLocationRaw,
	useRouter,
} from 'vue-router';
import { useStore } from 'vuex';

export const usePreviewHeaderNavigation = () => {
	const router = useRouter();
	const { commit } = useStore();

	const navigate = (to: RouteLocationRaw) => {
		commit(REDIRECT_MUTATIONS.SET_REDIRECT, {
			isFromPreviewHeader: true,
		});

		router.push(to);
	};

	return {
		navigate,
	};
};
