<template>
	<div class="ecommerce-tab-pagination">
		<div class="ecommerce-tab-pagination__controls">
			<ZyroLabel class="ecommerce-tab-pagination__label">
				{{ $t('builder.viewMoreProductsWith') }}
			</ZyroLabel>
			<ZyroRadioList
				v-qa="`editproductlist-tabpagination-controls`"
				:model-value="scrollBehaviour"
				:options="scrollbehaviourOptions"
				class="ecommerce-tab-pagination__controls"
				@update:model-value="updateScrollBehaviour"
			/>
		</div>
		<ZyroFieldRange
			:model-value="productsPerPage"
			min="1"
			has-number-input
			class="ecommerce-tab-pagination__controls"
			:max="MAX_PRODUCTS_PER_PAGE"
			:label="scrollBehaviour === EcommerceProductListScrollBehaviour.PAGINATION
				? $t('builder.ecommerceProductsPerPage')
				: $t('builder.ecommerceProductsPerLoad')"
			units=""
			@update:model-value="updateProductsPerPage"
		/>
	</div>
</template>

<script lang="ts" setup>
import ZyroFieldRange from '@/components/global/ZyroFieldRange.vue';
import ZyroRadioList from '@/components/global/ZyroRadioList.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';

import cloneDeep from 'lodash.clonedeep';
import {
	ref,
	computed,
	onMounted,
	onBeforeUnmount,
} from 'vue';
import { SiteBlock } from '@hostinger/builder-schema-validator';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { productsPerPageByColumnCount } from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import { EcommerceProductListScrollBehaviour } from '@zyro-inc/site-modules/types';

const MAX_PRODUCTS_PER_PAGE = 50;

const {
	dispatch,
	getters,
	state,
} = useStore();
const { t } = useI18n();

const scrollbehaviourOptions = {
	[EcommerceProductListScrollBehaviour.PAGINATION]: {
		name: t('common.pagination'),
	},
	[EcommerceProductListScrollBehaviour.SCROLL]: {
		name: t('builder.infiniteScroll'),
	},
};
const blockId = ref('');
const initialCurrentBlockData = ref<SiteBlock | null>(null);

const currentBlockId = computed<string>(() => state.currentBlockId);
const currentBlock = computed<SiteBlock>(() => getters.currentBlock);
const defaultProductsCount = computed(() => (productsPerPageByColumnCount as Record<number, number>)[currentBlock.value.columnCount || 2]);
const productsPerPage = computed(() => currentBlock.value.productsPerPage || defaultProductsCount.value);
const scrollBehaviour = computed(() => currentBlock.value.scrollBehaviour || EcommerceProductListScrollBehaviour.PAGINATION);

const updateProductsPerPage = (newValue: string) => {
	const newValueInt = Number.parseInt(newValue, 10);
	const realValue = newValueInt > MAX_PRODUCTS_PER_PAGE ? MAX_PRODUCTS_PER_PAGE : newValueInt;

	dispatch('updateBlockData', {
		blockId: blockId.value,
		blockData: {
			productsPerPage: realValue,
		},
		merge: true,
	});
};

const updateScrollBehaviour = (newValue: EcommerceProductListScrollBehaviour) => {
	dispatch('updateBlockData', {
		blockId: blockId.value,
		blockData: {
			scrollBehaviour: newValue,
		},
		merge: true,
	});
};

onMounted(() => {
	blockId.value = currentBlockId.value;
	initialCurrentBlockData.value = cloneDeep(currentBlock.value);
});

onBeforeUnmount(() => {
	dispatch('undoRedo/createSnapshot');
});
</script>

<style lang="scss" scoped>
.ecommerce-tab-pagination {
	&__controls {
		padding: 0;
		margin-top: 0;
		margin-bottom: 24px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__label {
		margin-bottom: 8px;
	}

	:deep(.zyro-radio-list) {
		flex-direction: column;
		gap: 8px;
	}
}
</style>
