import {
	ref,
	computed,
} from 'vue';

const croppedImageId = ref<string | null>(null);

export const useCropImage = () => {
	const exitCropMode = () => {
		croppedImageId.value = null;
	};

	const enterCropMode = (id: string) => {
		croppedImageId.value = id;
	};

	return {
		croppedImageId: computed(() => croppedImageId.value),
		exitCropMode,
		enterCropMode,
	};
};
