export const HOSTING_PLANS = {
	STARTER: 'hostinger_starter',
	PREMIUM: 'hostinger_premium',
	BUSINESS: 'hostinger_business',
	CLOUD_ECONOMY: 'cloud_economy',
	CLOUD_PROFESSIONAL: 'cloud_professional',
	CLOUD_ENTERPRISE: 'cloud_enterprise',
	WORDPRESS_SINGLE: 'wp_hostinger_single',
	WORDPRESS_SINGLE_SPECIAL: 'wp_hostinger_single_special',
	WORDPRESS_PREMIUM: 'wp_hostinger_premium',
	WORDPRESS_PREMIUM_SPECIAL: 'wp_hostinger_premium_special',
	WORDPRESS_BUSINESS: 'wp_hostinger_business',
	WORDPRESS_BUSINESS_SPECIAL: 'wp_hostinger_business_special',
	WORDPRESS_CLOUD_ECONOMY: 'wp_cloud_economy',
	WORDPRESS_CLOUD_PROFESSIONAL: 'wp_cloud_professional',
	WOO_STARTER: 'woo_hostinger_premium',
	WOO_PREMIUM: 'woo_cloud_economy',
	WOO_BUSINESS: 'woo_cloud_professional',
	WOO_HOSTINGER_BUSINESS: 'woo_hostinger_business',
	WEBLINK_BRONZE: 'weblink_bronze',
	WEBLINK_PREMIUM: 'weblink_premium',
	WEBLINK_BUSINESS: 'weblink_business',
	BUSINESS_TOP: 'gcp_business_8',
};

export const HRESOURCE_STATE = {
	ACTIVE: 'active',
	PENDING: 'pending_setup',
	UPDATING: 'updating',
	FAILED: 'failed',
	SUSPENDED: 'suspended',
	SUSPENDING: 'suspending',
	ACTIVATING: 'activating',
	COMPLETED: 'completed',
	CREATED: 'created',
	UNSUSPENDING: 'unsuspending',
	DELETED: 'deleted',
	DELETING: 'deleting',
	DESTROYED: 'destroyed',
	CANCELED: 'canceled',
	EXPIRED: 'expired',
	LOADING: 'loading',
} as const;

export const HRESOURCE_TYPE = {
	VIRTUAL_MACHINE: 'virtual_machine',
	EMAIL: 'email',
	GOOGLE_WORKSPACE: 'google_workspace',
	TITAN_MAIL: 'titan_mail',
	DOMAIN: 'domain',
	DOMAIN_TRANSFER: 'domain_transfer',
	FREE_DOMAIN: 'free_domain',
	FREE_DOMAIN_TRANSFER: 'free_domain_transfer',
	CPANEL_RESELLER_HOSTING: 'cpanel_reseller_hosting',
	DOMAIN_PRIVACY_PROTECTION: 'domain_privacy_protection',
	HOSTING: 'hosting',
	CPANEL_HOSTING: 'cpanel_hosting',
	PRIORITY_SUPPORT: 'priority_support',
	SSL: 'ssl',
	CLOUDFLARE: 'cloudflare',
	RANKING_COACH: 'ranking_coach',
	WEBSITE_BUILDER: 'website_builder',
	DAILY_BACKUP: 'daily_backup',
} as const;

export const HRESOURCE_ADDON_TYPE = {
	CLOUDFLARE: 'cloudflare',
	DAILY_BACKUP: 'daily_backup',
	DOMAIN_PRIVACY_PROTECTION: 'domain_privacy_protection',
	TITAN_MAIL: 'titan_mail',
	SSL_INSTALL: 'ssl_install',
	MAIL: 'mail',
	VPS_LICENSE: 'vps_license',
} as const;

export const HRESOURCE_ADDON_STATE = {
	PENDING_SETUP: 'pending_setup',
	ACTIVE: 'active',
	CANCELED: 'canceled',
	SUSPENDED: 'suspended',
} as const;

export const HRESOURCE_ITEM_STATE = {
	ACTIVE: 'active',
	DELETED: 'deleted',
} as const;

export const HRESOURCE_ITEM_TYPE = {
	WEBSITE: 'website',
	SUBDOMAIN: 'subdomain',
	PARKED: 'parked',
	ADDON: 'addon',
} as const;

export const CHARGEBEE_CANCEL_REASON = {
	REASON_ABUSE: 'abuse',
	REASON_FRAUD: 'fraud',
	REASON_REFUND: 'refund',
	REASON_INCORRECT_ORDER: 'incorrect_order',
	REASON_STAGING_DELETED: 'staging_deleted',
	REASON_WRONG_PRODUCT: 'wrong_product',
	REASON_NOT_PAID: 'Not Paid',
};

export type ChargebeeCancelReason =
	(typeof CHARGEBEE_CANCEL_REASON)[keyof typeof CHARGEBEE_CANCEL_REASON];

export type HResourceType =
	| (typeof HRESOURCE_TYPE)[keyof typeof HRESOURCE_TYPE];

export type HResourceTypeArray = ReadonlyArray<HResourceType>;

export type HResourceState =
	(typeof HRESOURCE_STATE)[keyof typeof HRESOURCE_STATE];

export type HResourceItemState =
	(typeof HRESOURCE_ITEM_STATE)[keyof typeof HRESOURCE_ITEM_STATE];

export type HResourceItemType =
	(typeof HRESOURCE_ITEM_TYPE)[keyof typeof HRESOURCE_ITEM_TYPE];

export type HResourceAddonType =
	(typeof HRESOURCE_ADDON_TYPE)[keyof typeof HRESOURCE_ADDON_TYPE];

export type HResourceAddonState =
	(typeof HRESOURCE_ADDON_STATE)[keyof typeof HRESOURCE_ADDON_STATE];

export type HostingPLan =
	(typeof HOSTING_PLANS)[keyof typeof HOSTING_PLANS];

export type BillingPeriodUnit = 'month' | 'year';

export type BillingPeriod =
	| '1'
	| '2'
	| '3'
	| '4'
	| '5'
	| '6'
	| '12'
	| '24'
	| '36'
	| '48'
	| '60';

export const SUBSCRIPTION_STATUS = {
	CANCELLED: 'cancelled',
	ACTIVE: 'active',
	DOMAIN: 'domain',
	SUSPENDED: 'suspended',
	EXPIRED: 'expired',
	DOMAIN_TRANSFER: 'DOMAIN_TRANSFER',
	PENDING_SETUP: 'pending_setup',
	PENDING_DATA_ENTRY: 'pending_data_entry',
	AWAITING_PAYMENT: 'awaiting_payment',
	PAYMENT_INITIATED: 'payment_initiated',
	NON_RENEWING: 'non_renewing',
	IN_TRIAL: 'in_trial',
	PAID: 'paid',
	ABUSED: 'abused',
	PAUSED: 'paused',
	VOIDED: 'voided',
} as const;

export type SubscriptionStatus =
	(typeof SUBSCRIPTION_STATUS)[keyof typeof SUBSCRIPTION_STATUS];

export const SUBSCRIPTION_CANCEL_REASON = {
	FRAUD: 'fraud',
	REFUND: 'refund',
	STAGING_DELETED: 'staging_deleted',
	ABUSE: 'abuse',
};

export type SubscriptionCancelReason = (typeof SUBSCRIPTION_CANCEL_REASON)[keyof typeof SUBSCRIPTION_CANCEL_REASON];

export interface SubscriptionParams {
	plan?: HostingPLan;
	domain?: string;
	emailPlan?: string;
	resourceId?: number;
}

export const SUBSCRIPTION_ITEM_TYPE = {
	PLAN: 'plan',
	ADDON: 'addon',
	CHARGE: 'charge',
};

export type SubscriptionItemType =
	(typeof SUBSCRIPTION_ITEM_TYPE)[keyof typeof SUBSCRIPTION_ITEM_TYPE];

export interface BillingItem {
	name?: string;
	domain?: string;
	resourceType: string;
	itemPriceId: string;
	itemType?: SubscriptionItemType;
	itemId?: string;
	itemPriceMetadata?: {
		upgradesTo: string[];
	};
}
export interface BillingMetaData {
	name?: string;
	domain?: string;
	resourceId?: string;
	planTitle?: string;
}

interface BillingLineItem {
	amount: number;
	dateFrom: number;
	dateTo: number;
	description: string;
	discountAmount: number;
	entityId: string;
	entityType: string;
	id: string;
	isTaxed: boolean;
	quantity: number;
	subscriptionId: string;
	taxAmount: number;
	unitAmount: number;
	externalName: string;
}

export const HBILLING_INVOICE_STATUS = {
	PAYMENT_DUE: 'payment_due',
	NOT_PAID: 'not_paid',
	PAID: 'paid',
	POSTED: 'posted',
	VOIDED: 'voided',
	PENDING: 'pending',
} as const;

export type HBillingInvoiceStatus = (typeof HBILLING_INVOICE_STATUS)[keyof typeof HBILLING_INVOICE_STATUS];

interface BillingDiscount {
	amount: number;
	description: string;
	discountType: string;
	entityId: string;
}

interface BillingLineItemDiscount {
	amount: number;
	lineItemId: string;
	discountType: string;
	entityId: string;
}

interface BillingTax {
	name: string;
	amount: number;
	description: string;
}

interface BillingLineItemTax {
	lineItemId: string;
	taxName: string;
	taxRate: number;
	isPartialTaxApplied: false;
	isNonComplianceTax: false;
	taxableAmount: number;
	taxAmount: number;
	taxJurisType: string;
	taxJurisName: string;
	taxJurisCode: string;
	taxAmountInLocalCurrency: null;
	localCurrencyCode: null;
}

export interface BillingInvoice {
	amountAdjusted: number;
	amountDue: number;
	amountPaid: number;
	amountToCollect: number;
	baseCurrencyNode: string;
	currencyNode: string;
	customerId: string;
	date: Date;
	deleted: false;
	dueDate: number;
	exchangeRate: 0;
	firstInvoice: boolean;
	id: string;
	issuedInvoiceId?: string;
	referenceInvoiceId?: string;
	lineItems: BillingLineItem[];
	linkedPayments: {
		appliedAmount: number;
		appliedAt: number;
		txnAmount: number;
		txnDate: 1694589315;
		txnId: string;
		txnStatus: string;
	}[];
	paidAt: Date;
	currencyCode: string;
	recurring: boolean;
	status: HBillingInvoiceStatus;
	subTotal: number;
	subscriptionId: string;
	tax: number;
	total: number;
	discounts: BillingDiscount[];
	lineItemDiscounts: BillingLineItemDiscount[];
	appliedCredits: [];
	issuedCreditNotes: [];
	taxes: BillingTax[];
	lineItemTaxes: BillingLineItemTax[];
}

interface RenewPrice {
	itemPriceId: string;
	enabled: boolean;
	price?: number;
}

interface BillingSubscriptionMetadata {
	domain?: string;
}
export interface BillingSubscription {
	id: string;
	billingPeriod: BillingPeriod;
	billingPeriodUnit: BillingPeriodUnit;
	canRenew: boolean;
	canReactivate: boolean;
	canEnableAutoRenew: boolean;
	chargedImmediatelyOnAutoRenew: boolean;
	status: SubscriptionStatus;
	cfSubscriptionParams?: SubscriptionParams;
	dueInvoicesCount: number;
	currentTermStart: number;
	currentTermEnd: number;
	nextBillingAt: Date;
	resourceType: HResourceType;
	cancelReasonCode: SubscriptionCancelReason;
	canceledAt: number;
	items: BillingItem[];
	metadata?: BillingMetaData;
	// eslint-disable-next-line no-use-before-define
	resource?: HResource;
	expiresAt?: string;
	invoices?: BillingInvoice[];
	renewPrices: RenewPrice[];
	renewalPrice: number;
	scheduledChanges: {
		renewalPrice: number;
		nextBillingAt: number;
		remainingBillingCycles: number | null;
	};
	defaultRenewalPrice: string;
	metaData: BillingSubscriptionMetadata;
	hasScheduledChanges: boolean;
}

export interface HResourceItem {
	id: number;
	resourceId: number;
	type: HResourceItemType;
	state: HResourceItemState;
	domain: string;
	config: [] | null;
	createdAt: string;
	updatedAt: string;
	// eslint-disable-next-line no-use-before-define
	resource?: HResource;
	features: unknown[];
}

export interface IHResourceAddon {
	id: number;
	resourceId?: number;
	type: HResourceAddonType;
	config: [] | null;
	state: HResourceAddonState;
	createdAt?: string;
	updatedAt?: string;
}

export interface IHResourceMetadata {
	country?: string;
	orderId?: string;
	expiresAt?: string;
	invoiceNr?: string;
	domain?: string;
	planCode?: string;
	planTitle?: string;
	planName?: string;
	isChargebee?: 1 | 0;
	currencyCode?: string;
	customerName?: string;
	billingPeriod?: string;
	customerEmail?: string;
	resourceVersion?: string;
}

export interface HResourceConfig {
	plan?: HostingPLan;
	emailPlan?: string;
	domain?: string;
	username?: string;
	tlds?: string[];
	h5g?: boolean;
	freeMail?: {
		planCode: string,
		numberOfSeats: number,
	},
	freeDomain?: {
		tlds: string[],
	}
}

export interface HResource {
	id: number;
	customerCustomId: string;
	userId?: number;
	referenceId: string | null;
	relatedResourceId: number | null;
	idempotencyKey: string | null;
	chargebeeSubscriptionId: string | null;
	type: HResourceType;
	state: HResourceState;
	reason: ChargebeeCancelReason | null;
	title: string | null;
	config?: HResourceConfig;
	metadata?: IHResourceMetadata;
	subscription?: BillingSubscription;
	expiresAt: string | null;
	activatedAt: string | null;
	suspendedAt: string | null;
	createdAt: string;
	updatedAt: string;
	items: HResourceItem[];
	addons: IHResourceAddon[];
	parent?: HResource | null;
	relatedServices?: HResource[];
	planName?: string;
}
