<template>
	<div class="element-edit-popup-search-bar-tab-style">
		<ZyroSegmentControl
			:controls="controls"
			:active-control="activeControl"
			@update:active-control="activeControl = $event"
		/>

		<ZyroFieldColorPicker
			:is-open="isFillColorPickerOpen"
			:label="$t('builder.editButton.customButtonStyles.fillColor')"
			:color="isEditingNormalState ? currentElement.fillColor : currentElement.fillColorHover"
			@click-outside="isFillColorPickerOpen = false"
			@toggle="isFillColorPickerOpen = !isFillColorPickerOpen"
			@update-color="updateFillColor"
		/>

		<ZyroSeparator />

		<ZyroFieldColorPicker
			:is-open="isTextAndIconColorPickerOpen"
			:label="$t('builder.searchBarTextAndIconColorLabel')"
			:color="isEditingNormalState ? currentElement.textAndIconColor : currentElement.textAndIconColorHover"
			@click-outside="isTextAndIconColorPickerOpen = false"
			@toggle="isTextAndIconColorPickerOpen = !isTextAndIconColorPickerOpen"
			@update-color="dispatch('mergeElementData', {
				elementId: currentElementId,
				elementData: { [isEditingNormalState ? 'textAndIconColor' : 'textAndIconColorHover']: $event }
			})"
		/>

		<ZyroFieldFontSelect
			v-if="isEditingNormalState"
			:current-font-family="currentElement.fontFamily"
			:is-open="isFontSelectOpen"
			:is-font-weight-selection-enabled="false"
			@set-font-family="dispatch('mergeElementData', {
				elementId: currentElementId,
				elementData: { fontFamily: $event.fontFamily }
			})"
			@toggle="isFontSelectOpen = !isFontSelectOpen"
			@close="isFontSelectOpen = false"
		/>

		<ZyroSeparator />

		<ZyroFieldColorPicker
			:is-open="isBorderColorPickerOpen"
			:label="$t('builder.editButton.customButtonStyles.borderColor')"
			:color="isEditingNormalState ? currentElement.borderColor : currentElement.borderColorHover"
			@click-outside="isBorderColorPickerOpen = false"
			@toggle="isBorderColorPickerOpen = !isBorderColorPickerOpen"
			@update-color="dispatch('mergeElementData', {
				elementId: currentElementId,
				elementData: { [isEditingNormalState ? 'borderColor' : 'borderColorHover']: $event }
			})"
		/>

		<ZyroFieldRange
			v-if="isEditingNormalState"
			class="element-edit-popup-search-bar-tab-style__input-border-radius"
			:model-value="currentElement.borderRadius"
			min="0"
			max="25"
			units="px"
			has-number-input
			:label="$t('common.cornerRadius')"
			@update:model-value="dispatch('mergeElementData', {
				elementId: currentElementId,
				elementData: { borderRadius: $event }
			})"
		/>
	</div>
</template>

<script setup lang="ts">
import {
	ref,
	computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
// @ts-ignore
import tinycolor from 'tinycolor2';
import {
	ELEMENT_EDIT_POPUP_STYLE_STATE_ID_NORMAL,
	ELEMENT_EDIT_POPUP_STYLE_STATE_ID_HOVER,
} from '@/constants/builderConstants';
import ZyroFieldColorPicker from '@/components/global/ZyroFieldColorPicker.vue';
import ZyroFieldFontSelect from '@/components/global/ZyroFieldFontSelect.vue';
import ZyroSegmentControl from '@/components/global/ZyroSegmentControl.vue';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';
import ZyroFieldRange from '@/components/global/ZyroFieldRange.vue';

const { t } = useI18n();
const {
	getters,
	dispatch,
} = useStore();

const controls = [
	{
		id: ELEMENT_EDIT_POPUP_STYLE_STATE_ID_NORMAL,
		title: t('builder.editButton.customButtonStyles.normal'),
	},
	{
		id: ELEMENT_EDIT_POPUP_STYLE_STATE_ID_HOVER,
		title: t('builder.editButton.customButtonStyles.hover'),
	},
];
const activeControl = ref(controls[0]);
const isFillColorPickerOpen = ref(false);
const isTextAndIconColorPickerOpen = ref(false);
const isFontSelectOpen = ref(false);
const isBorderColorPickerOpen = ref(false);
const isEditingNormalState = computed(() => activeControl.value.id === ELEMENT_EDIT_POPUP_STYLE_STATE_ID_NORMAL);
const currentElementId = computed(() => getters.currentElementId);
const currentElement = computed(() => getters.currentElement);

const updateFillColor = (color: string) => {
	if (isEditingNormalState.value) {
		dispatch('mergeElementData', {
			elementId: currentElementId.value,
			elementData: {
				fillColor: color,
			},
		});

		return;
	}

	const resultItemHoverColor = tinycolor(color).getBrightness() <= 50
		? 'rgba(255, 255, 255, 0.06)'
		: 'rgba(0, 0, 0, 0.06)';

	dispatch('mergeElementData', {
		elementId: currentElementId.value,
		elementData: {
			fillColorHover: color,
			resultItemHoverColor,
		},
	});
};
</script>

<style lang="scss" scoped>
.element-edit-popup-search-bar-tab-style {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-bottom: 24px;

	&__input-border-radius {
		margin: 0;
	}
}
</style>
