<template>
	<ZyroModal
		max-width="600px"
		max-height="700px"
		height="auto"
		class="before-publish-modal"
		@close-modal="closeModal"
	>
		<div class="before-publish-modal__content">
			<h2 class="text-title-2 before-publish-modal__title">
				{{ $t('builder.expiredSubscriptionModalTitle') }}
			</h2>
			<p class="text-body-1 before-publish-modal__subtitle">
				{{ $t('builder.expiredSubscriptionModalSubtitle') }}
			</p>

			<HostingerButton
				v-qa="'builder-modal-beforepublish-btn'"
				size="lg"
				@click="redirectToSubscriptions({
					subscriptionId: subscription.id,
					websiteId,
				})"
			>
				{{ $t('builder.expiredSubscriptionModalButtonText') }}
			</HostingerButton>
		</div>
	</ZyroModal>
</template>

<script>
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroModal from '@/components/global/ZyroModal.vue';

import { mapState } from 'vuex';

import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { useRedirects } from '@/use/useRedirects';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		HostingerButton,
		ZyroModal,
	},

	setup() {
		const { redirectToSubscriptions } = useRedirects();

		return {
			redirectToSubscriptions,
		};
	},

	computed: {
		...mapState(['websiteId']),
		...mapState('subscription', ['subscription']),
	},

	methods: {
		...mapActionsGui({
			closeModal: CLOSE_MODAL,
		}),
	},
});
</script>

<style lang="scss" scoped>
.before-publish-modal {
	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 48px;
		text-align: center;
	}

	&__title {
		margin-bottom: 16px;
	}

	&__subtitle {
		margin-bottom: 48px;
	}
}
</style>
