<script lang="ts" setup>
import { CLOSE_MODAL } from '@/store/builder/gui';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useAiPageGenerator } from '@/use/useAiPageGenerator';

import GenerateContentModal from '@/components/builder-modals/modals/-partials/GenerateContentModal.vue';

const { dispatch } = useStore();
const { t } = useI18n();

const {
	isGeneratingPage,
	pageDescription,
	generatePage,
} = useAiPageGenerator();

const exampleDescriptions = [
	{
		title: t('builder.generatePageDescriptionExampleTitle1'),
		text: t('builder.generatePageDescriptionExampleText1'),
	},
	{
		title: t('builder.generatePageDescriptionExampleTitle2'),
		text: t('builder.generatePageDescriptionExampleText2'),
	},
];

const handleCloseModal = () => {
	dispatch(`gui/${CLOSE_MODAL}`);
};

const handlePageGeneration = async (description: string) => {
	pageDescription.value = description;

	try {
		await generatePage();

		dispatch('gui/CLOSE_DRAWER');
		handleCloseModal();
	} finally {
		isGeneratingPage.value = false;
	}
};

</script>

<template>
	<GenerateContentModal
		:is-generating="isGeneratingPage"
		:example-descriptions="exampleDescriptions"
		:text-area-placeholder="t('builder.generatePagePlaceholder')"
		:text-area-label="t('builder.pageSettingsModal.textFields.pageDescription.label')"
		:generate-modal-title="t('builder.createPageWithAi')"
		:generate-button-text="t('builder.createPage')"
		button-data-qa="generate-page-button"
		@generate-content="handlePageGeneration"
	/>
</template>

<style lang="scss" scoped>
.action-button {
	margin-top: 24px;
	text-align: center;
}

.content {
	margin-top: 24px;

	&__textarea {
		margin: 8px 0;

		:deep(.zyro-textarea) {
			border-radius: 8px;
		}
	}

	&__examples {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin-top: -28px;

		@media screen and (max-width: 460px) {
			margin-top: 0;
			align-items: flex-start;
		}
	}

	&__examples-button {
		color: var(--color-primary);
		font-size: 12px;

		&:hover, &:active, &:focus {
			background-color: transparent;
		}
	}

	&__examples-list {
		margin-top: 4px;
		border-radius: 4px;
		border: 1px solid var(--color-gray-border);
		padding: 12px 16px;
		list-style: none;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
		display: flex;
	}
}

.generate-page-modal {
	@media screen and (max-width: 460px) {
		&__title {
			margin-top: 24px;
		}

		:deep(.modal__content) {
			padding: 24px;
		}
	}
}
</style>
