<script setup lang="ts">
import { addBreadcrumb } from '@sentry/vue';
import { computed } from 'vue';

const ICON_ROTATION_MAP = {
	up: '0deg',
	right: '90deg',
	down: '180deg',
	left: '270deg',
};

interface Props {
	name: string;
	direction?: 'up' | 'right' | 'down' | 'left';
	dimensions?: string;
	viewBox?: string;
	isFilled?: boolean;
	color?: string;
	isCustom?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	direction: 'up',
	dimensions: '24px',
	viewBox: '0 0 60 55',
	isFilled: false,
});

const size = computed(() => props.dimensions);
const fill = computed(() => (props.isFilled ? '1' : '0'));
const rotationAngle = computed(() => ICON_ROTATION_MAP[props.direction]);
const iconHref = computed(() => `/__spritemap#icon-${props.name}`);
const opticalSize = computed(() => (props.dimensions ? props.dimensions.replace('px', '') : '24'));
const currentColor = computed(() => `var(--color-${props.color})`);

const handleClick = () => {
	addBreadcrumb({
		category: 'CLICK:Icon',
		data: {
			name: props.name,
		},
	});
};
</script>

<template>
	<svg
		v-if="isCustom"
		v-qa="`icon-${name}`"
		class="icon"
		:viewBox="viewBox"
		:class="[{ 'icon__dimensions': isCustom }, { 'icon--color': color }]"
		@click="handleClick"
	>
		<use :href="iconHref" />
	</svg>
	<span
		v-else
		v-qa="`icon-${name}`"
		class="icon material-symbols-rounded"
		:class="{ 'icon--color': color }"
		@click="handleClick"
	>
		{{ name }}
	</span>
</template>

<style lang="scss" scoped>
.icon {
	transition: 0.3s ease transform;
	fill: currentColor;
	font-size: v-bind(size);
	transform: rotate(v-bind(rotationAngle));
	font-variation-settings: "FILL" v-bind(fill), "opsz" v-bind(opticalSize);
	width: v-bind(size);
	height: v-bind(size);
	overflow: hidden;

	&--color {
		color: v-bind(currentColor);
	}

	&__dimensions {
		width: v-bind(size);
		height: v-bind(size);
	}
}
</style>
