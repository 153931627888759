// eslint-disable-next-line no-restricted-imports
import axios from 'axios';
import defaultAxiosInstance from '@/services/http/axios';
import { snakeToCamelObj } from '@/utils/snakeToCamel';

const axiosInstance = axios.create({
	timeout: 150000,
	headers: {
		common: {
			Accept: 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
		},
	},
});

// @ts-ignore
defaultAxiosInstance.interceptors.request.handlers
	.forEach((interceptor: any) => axiosInstance.interceptors.request.use(interceptor.fulfilled, interceptor.rejected));

// @ts-ignore
defaultAxiosInstance.interceptors.response.handlers
	.forEach((interceptor: any) => axiosInstance.interceptors.response.use(interceptor.fulfilled, interceptor.rejected));

// This is needed because the hDomainsStatus expect axios instance with unwrapped data response
axiosInstance.interceptors.response.use((res) => (snakeToCamelObj({
	...res,
	data: typeof res.data.data !== 'undefined' ? res.data.data : res.data,
})));

export const hDomainAxiosInstance = axiosInstance;
