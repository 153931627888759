import { ElementPositions } from '@/types/elementTypes';

export const fitToLayoutYBounds = (position: ElementPositions): ElementPositions => {
	const {
		left,
		width,
		top,
		height,
	} = position;

	return {
		// Don't allow the block to go outside the layout bounds
		top: Math.max(0, top),
		left,
		height,
		width,
	};
};
