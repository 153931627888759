<template>
	<div class="screen-toggle button-group">
		<HostingerButton
			v-qa="`builder-header-btn-switchview${isMobileView ? '-mobile' : ''}`"
			:data-popper-reference="MOBILE_SWITCH_BUTTON_SELECTOR"
			button-type="plain"
			:title="$t('common.toggleMobile')"
			class="screen-toggle__button"
			:class="{ 'screen-toggle__button--active': !isMobileView }"
			@click="handleViewToggle"
		>
			<template #icon>
				<Icon name="laptop_windows" />
			</template>
		</HostingerButton>
		<HostingerButton
			:data-popper-reference="MOBILE_SWITCH_BUTTON_SELECTOR"
			button-type="plain"
			class="screen-toggle__button"
			:class="{ 'screen-toggle__button--active': isMobileView }"
			@click="handleViewToggle"
		>
			<template #icon>
				<Icon name="smartphone" />
			</template>
		</HostingerButton>
	</div>
</template>

<script setup>
import HostingerButton from '@/components/global/HostingerButton.vue';

import Icon from '@/components/global/Icon.vue';
import { GAMIFICATION_TASK_CHECK_MOBILE_VIEW } from '@/constants/builderConstants';
import { useStore } from 'vuex';
import { MOBILE_SWITCH_BUTTON_SELECTOR } from '@/components/onboarding/onboardingSelectors';
import { useGamification } from '@/use/useGamification';
import { computed } from 'vue';

const {
	state,
	dispatch,
} = useStore();
const { completeAchievement } = useGamification();

const isMobileView = computed(() => state.gui.isMobileView);

const handleViewToggle = () => {
	dispatch('gui/toggleMobileView');

	if (isMobileView.value) {
		completeAchievement(GAMIFICATION_TASK_CHECK_MOBILE_VIEW);
	}
};
</script>
<style lang="scss" scoped>
.screen-toggle {
	@media screen and (max-width: $media-mobile) {
		&#{&} {
			display: none;
		}
	}

	&__button {
		margin: 0 4px;

		&:first-child {
			margin-right: 0;
		}

		&--active {
			background-color: $color-primary-light;
			color: $color-primary;
		}
	}
}
</style>
