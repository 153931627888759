<template>
	<div class="element-edit-popup-search-bar-tab-general">
		<ZyroFieldInput
			:model-value="currentElement.placeholderText"
			class="element-edit-popup-search-bar-tab-general__placeholder-input"
			:placeholder="t('builder.searchBarDefaultPlaceholder')"
			:maxlength="ELEMENT_EDIT_POPUP_GENERAL_MAX_LENGTH_INPUT"
			:label="t('builder.searchBarPlaceholderInputLabel')"
			@update:model-value="dispatch('mergeElementData', {
				elementId: currentElementId,
				elementData: { placeholderText: $event },
			})"
		/>
		<ZyroFieldInput
			:model-value="currentElement.noResultsText"
			:placeholder="t('builder.searchBarDefaultNoResultsText')"
			:label="t('builder.searchBarNoResultsInputLabel')"
			:maxlength="ELEMENT_EDIT_POPUP_GENERAL_MAX_LENGTH_INPUT"
			@update:model-value="dispatch('mergeElementData', {
				elementId: currentElementId,
				elementData: { noResultsText: $event },
			})"
		/>
		<InfoBanner
			class="element-edit-popup-search-bar-tab-general__info-banner"
			:subtitle="$t('builder.searchBarEditDisclaimer')"
		/>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ELEMENT_EDIT_POPUP_GENERAL_MAX_LENGTH_INPUT } from '@/constants/builderConstants';
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import InfoBanner from '@/components/ui/InfoBanner.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const { t } = useI18n();
const {
	getters,
	dispatch,
} = useStore();

const currentElementId = computed(() => getters.currentElementId);
const currentElement = computed(() => getters.currentElement);
</script>

<style lang="scss" scoped>
.element-edit-popup-search-bar-tab-general {
	&__info-banner {
		margin-bottom: 24px;
	}
}
</style>
