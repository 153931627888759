<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';
import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';

import { useEditableItemInput } from '@/components/reusable-components/editable-items-list/useEditableItemInput';

import { useI18n } from 'vue-i18n';

const SEO_KEYWORD_MAX_STRING_LENGTH = 100;

interface Props {
	pageMetaKeywords: string[];
	mergePageMeta: (dataToMerge: Record<string, unknown>) => void;
	disabled?: boolean;
	isPageMetaKeywordsLimitReached: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	disabled: false,
});

const emit = defineEmits<{
	add: [keyword: string ];
}>();

const { t } = useI18n();

const getIsKeywordValid = (keyword: string) => {
	if (!keyword) {
		return {
			isValid: false,
			error: t('validate.emptyValue'),
		};
	}

	if (keyword.length > SEO_KEYWORD_MAX_STRING_LENGTH) {
		return {
			isValid: false,
			error: t('builder.maxKeywordLength', [SEO_KEYWORD_MAX_STRING_LENGTH]),
		};
	}

	if (props.pageMetaKeywords.includes(keyword)) {
		return {
			isValid: false,
			error: t('builder.keywordAlreadyExists'),
		};
	}

	return {
		isValid: true,
	};
};

const {
	handleInput,
	showInput,
	hideInput,
	submitValue,
	isInputShown,
	inputValue,
	error,
} = useEditableItemInput({
	validateValue: getIsKeywordValid,
	emit,
});

</script>

<template>
	<HostingerButton
		v-qa="'edititems-list-btn-additem'"
		class="add-button"
		theme="primary"
		:disabled="disabled"
		button-type="outlined"
		@click="!isPageMetaKeywordsLimitReached && showInput()"
	>
		{{ t('builder.pageSettingsAddNewKeyword') }}
	</HostingerButton>

	<Teleport
		v-if="isInputShown"
		to="body"
	>
		<SystemDialogModal
			:title="t('builder.seoDrawer.addNewKeywordModalTitle')"
			:primary-button-text="t('common.cancel')"
			:secondary-button-text="t('common.add')"
			secondary-button-color="primary"
			width="400px"
			@close="hideInput"
			@click-primary="hideInput"
			@click-secondary="submitValue"
		>
			<ZyroFieldInput
				color="light"
				class="add-button-modal__input"
				:label="t('builder.keyword')"
				:error="error"
				:placeholder="t('builder.addNewKeywordPlaceholder')"
				:model-value="inputValue"
				focus-on-mount
				qa-selector="edititems-item-input-newitem"
				@update:model-value="handleInput"
			/>
		</SystemDialogModal>
	</Teleport>
</template>

<style lang="scss" scoped>
.add-button {
	&:disabled {
		pointer-events: all;
		cursor: not-allowed;
	}
}
</style>
