<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('builder.blog.blogPostHeader.postHeader')"
		:tabs="tabs"
		:current-tab="currentTab"
		@update:current-tab="currentTab = $event"
		@close="$emit('close')"
	>
		<EditBlockBlogHeaderTabStyle
			v-if="currentTab.id === TAB_STYLE"
			@close="$emit('close')"
		/>

		<EditBlockBlogHeaderTabLayout
			v-else-if="currentTab.id === TAB_LAYOUT"
			@close="$emit('close')"
		/>

		<EditBlockTabBackground
			v-else-if="currentTab.id === TAB_BACKGROUND"
			@close="$emit('close')"
		/>
	</ZyroPopupCard>
</template>

<script setup lang="ts">
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';
import EditBlockBlogHeaderTabLayout from '@/components/builder-controls/edit-block-blog-header/EditBlockBlogHeaderTabLayout.vue';
import EditBlockBlogHeaderTabStyle from '@/components/builder-controls/edit-block-blog-header/EditBlockBlogHeaderTabStyle.vue';
import EditBlockTabBackground from '@/components/builder-controls/edit-block/EditBlockTabBackground.vue';

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

defineEmits(['close']);

const TAB_STYLE = 'style';
const TAB_LAYOUT = 'layout';
const TAB_BACKGROUND = 'background';

const { t } = useI18n();
const tabs = [
	{
		id: TAB_STYLE,
		title: t('common.style'),
		component: 'EditBlockBlogHeaderTabStyle',
	},
	{
		id: TAB_LAYOUT,
		title: t('common.layout'),
		component: 'EditBlockBlogHeaderTabLayout',
	},
	{
		id: TAB_BACKGROUND,
		title: t('common.background'),
		component: 'EditBlockTabBackground',
	},
];

const currentTab = ref(tabs[0]);
</script>
