<template>
	<a
		v-qa="`blogpostsettings-useaititlegenerator-click`"
		target="_blank"
		class="blog-post-settings-tab-general-ai-link__link z-link"
		href="https://zyro.com/ai/blog-title-generator?ref=builder"
	>
		{{ $t('builder.blog.blogPostSettings.AIRecommendationSlotTitleGenerator') }}
	</a>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style scoped lang="scss">
.blog-post-settings-tab-general-ai-link {
	&__link {
		color: $color-dark;
	}
}
</style>
