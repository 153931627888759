<script setup>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';

import { useStore } from 'vuex';

import Divider from '@/components/builder-controls/Divider.vue';
import HideItemButton from '@/components/builder-controls/multipage-drawer/popup-buttons/-partials/HideItemButton.vue';

import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';

import { ref } from 'vue';

const props = defineProps({
	item: {
		type: Object,
		required: true,
	},
	toggle: {
		type: Function,
		default: () => ({}),
	},
});

defineEmits(['start-renaming']);

const { dispatch } = useStore();

const isDeleteModalShown = ref(false);

const remove = () => {
	dispatch('navigation/removeItem', {
		itemId: props.item.navItemId,
	});

	isDeleteModalShown.value = false;

	props.toggle();
};
</script>

<template>
	<div>
		<HostingerButton
			v-qa="'sitemenusettingspopup-btn-rename'"
			button-type="plain"
			class="popup-button"
			@click="$emit('start-renaming')"
		>
			<template #icon-left>
				<Icon
					name="edit"
					dimensions="20px"
				/>
			</template>
			<span class="text-body-2">
				{{ $t('common.rename') }}
			</span>
		</HostingerButton>
		<HideItemButton
			:item="item"
			:toggle="toggle"
		/>
		<Divider />
		<HostingerButton
			v-qa="'sitemenusettingspopup-btn-delete'"
			button-type="plain"
			class="popup-button"
			@click="isDeleteModalShown = true"
		>
			<template #icon-left>
				<Icon
					name="delete"
					dimensions="20px"
				/>
			</template>
			<span class="text-body-2">
				{{ $t('common.delete') }}
			</span>
		</HostingerButton>
		<Teleport
			v-if="isDeleteModalShown"
			to="body"
		>
			<SystemDialogModal
				:title="$t('builder.deletePageModal.dropdownTitle')"
				:primary-button-text="$t('common.cancel')"
				:secondary-button-text="$t('common.delete')"
				secondary-button-color="danger"
				class="delete-modal"
				@click-primary="isDeleteModalShown = false, toggle()"
				@click-secondary="remove"
				@close="isDeleteModalShown = false, toggle()"
			>
				<p class="text-body-1">
					{{ $t('builder.deletePageModal.text') }}
					<span class="text-bold-1">{{ item.name }}</span>?
				</p>
			</SystemDialogModal>
		</Teleport>
	</div>
</template>

<style lang="scss" scoped>
.delete-modal {
	z-index: $z-index-popup;
}
</style>
