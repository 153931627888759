<template>
	<div class="blog-post-settings-tab-cover-image">
		<ZyroImageSelector
			:origin="currentBlogPage.coverImageOrigin"
			:path="currentBlogPage.coverImagePath"
			@update="setCurrentBlogPageImages"
		/>
	</div>
</template>

<script>
import ZyroImageSelector from '@/components/global/ZyroImageSelector.vue';

import {
	mapState,
	mapActions,
	mapGetters,
} from 'vuex';

import { defineComponent } from 'vue';
import { updateAiGeneratedImagePath } from '@/utils/urlValidators';

export default defineComponent({
	components: {
		ZyroImageSelector,
	},

	computed: {
		...mapState('gui', ['activeModalSettings']),
		...mapGetters(['blogPages']),

		currentBlogPage() {
			return this.blogPages[this.activeModalSettings.blogPostId];
		},
	},

	methods: {
		...mapActions(['mergePageData']),

		setCurrentBlogPageImages({
			path,
			origin,
			alt,
			url,
		}) {
			const validPath = updateAiGeneratedImagePath(url, path);

			this.mergePageData({
				pageId: this.activeModalSettings.blogPostId,
				pageData: {
					meta: {
						ogImagePath: validPath,
						ogImageOrigin: origin,
						ogImageAlt: alt,
					},
					coverImagePath: validPath,
					coverImageOrigin: origin,
					coverImageAlt: alt,
				},
			});
		},
	},
});
</script>

<style lang="scss" scoped>
.blog-post-settings-tab-cover-image {
	padding-top: 8px;
}
</style>
