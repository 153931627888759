<script setup lang="ts">
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';

import { computed } from 'vue';

const HANDLE_ANIMATION_THRESHOLD = 5;

const props = defineProps({
	type: {
		type: String,
		default: 'default',
	},
	resizeDirection: {
		type: Number,
		default: 0,
	},

});

const hasError = computed(() => props.type === 'error');
const resizeMovementLength = computed(() => props.resizeDirection * HANDLE_ANIMATION_THRESHOLD);
</script>

<template>
	<button
		ref="resizeButton"
		class="resize-handle"
		:class="`resize-handle--${type}`"
		:style="`--resize-movement-length: ${resizeMovementLength}px`"
	>
		<ZyroSvgDeprecated
			name="chevron"
			direction="up"
			class="resize-handle__icon"
			:class="{ 'resize-handle__icon--disabled': hasError }"
		/>
		<ZyroSvgDeprecated
			name="chevron"
			direction="down"
		/>
	</button>
</template>

<style lang="scss" scoped>
.resize-handle {
	display: flex;
	flex-direction: column;
	padding: 4px 20px;
	color: $color-light;
	cursor: row-resize;
	background: $color-azure; // default color
	border-radius: 6px;
	transition: background 0.2s $transition-timing-easing-standard, transform 0.1s ease-out;
	transform: translateY(var(--resize-movement-length));

	// Resize handle types
	&--primary {
		background: $color-primary;
	}

	&--error {
		background: $color-primary;
	}

	&__icon {
		margin-bottom: 4px;
		transition: opacity 0.2s linear;

		&--disabled {
			opacity: 0.4;
		}
	}
}
</style>
