<script lang="ts" setup>
import { computed } from 'vue';

import getAssetLink from '@/utils/getAssetLink';

interface Props {
  src: string;
  alt?: string;
  isExternal?: boolean;
}

const props = defineProps<Props>();

const imageSrc = computed(() => (props.isExternal ? props.src : getAssetLink(`/assets${props.src}`)));
</script>

<template>
	<img
		:src="imageSrc"
		:alt="alt"
	>
</template>
