<template>
	<div class="input-types">
		<ZyroLabel class="input-types__label">
			{{ $t('builder.editForm.fieldType') }}
		</ZyroLabel>
		<ZyroSelect
			v-qa="'contact-form-field-select'"
			label-key="title"
			:filterable="false"
			:searchable="false"
			class="field-dropdown"
			:options="typeValidations"
			:model-value="currentType"
			@update:model-value="$emit('change-type', $event.field)"
		>
			<template #selected-option="{ option }">
				<div class="field-dropdown__selected">
					<ZyroSvgDeprecated
						:name="option.svg"
						class="field-dropdown__option-icon"
					/>
					{{ option.title }}
				</div>
			</template>
			<template #option="{ option }">
				<div v-qa="`dropdown-option-${option.title}`">
					<ZyroSvgDeprecated
						v-if="option.svg"
						:name="option.svg"
						class="field-dropdown__option-icon"
					/>
					{{ option.title }}
				</div>
			</template>
		</ZyroSelect>
	</div>
</template>
<script>
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';

import {
	defineComponent,
	computed,
} from 'vue';

import { useI18n } from 'vue-i18n';

export default defineComponent({
	components: {
		ZyroSvgDeprecated,
		ZyroLabel,
		ZyroSelect,
	},

	props: {
		validation: {
			type: Array,
			required: true,
		},
	},

	emits: ['change-type'],

	setup(props) {
		const { t } = useI18n();
		const typeText = {
			title: t('builder.editForm.fieldTypes.fieldTypeText'),
			field: {
				validation: [['optional']],
				messages: {
					required: 'This field is required',
				},
				validationType: 'text',
			},
			svg: 'text-sm',
		};
		const typeEmail = {
			title: t('builder.editForm.fieldTypes.fieldTypeEmail'),
			field: {
				validation: [
					['bail'],
					['email'],
					['required'],
				],
				messages: {
					required: 'This field is required',
					email: 'Please enter a valid email address',
				},
				validationType: 'email',
			},
			svg: 'mail',
		};
		const typePhone = {
			title: t('builder.editForm.fieldTypes.fieldTypePhone'),
			field: {
				validation: [
					['bail'],
					['required'],
					[
						'phone',
						// eslint-disable-next-line no-useless-escape
						'/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/',
					],
				],
				messages: {
					required: 'This field is required',
					phone: 'Please enter a valid phone number',
				},
				validationType: 'phone',
			},
			svg: 'smartphone',
		};

		const isBailRulePresent = computed(() => props.validation.some(([rule]) => rule === 'bail'));

		const isMailRulePresent = computed(() => props.validation.some(([rule]) => rule === 'email'));

		const currentType = computed(() => {
			if (!isBailRulePresent.value) {
				return typeText;
			}

			if (isMailRulePresent.value) {
				return typeEmail;
			}

			return typePhone;
		});

		return {
			typeValidations: [
				typeText,
				typeEmail,
				typePhone,
			],
			currentType,
		};
	},
});
</script>
<style lang="scss" scoped>
.input-types {
	&__label {
		margin-bottom: 8px;
	}
}

.field-dropdown {
	margin-bottom: 16px;

	&__option-icon {
		margin-right: 10px;
	}

	&__selected {
		display: flex;
		align-items: center;
	}

	:deep(.vs__dropdown-menu) {
		max-height: 120px;
	}
}
</style>
