<template>
	<div class="layout">
		<ZyroFieldRange
			:model-value="height"
			units="px"
			:label="$t('common.height')"
			color="light"
			has-number-input
			:max="BLOCK_STICKY_BAR_MAX_HEIGHT"
			:min="minRange"
			@update:model-value="updateHeight"
		/>
	</div>
</template>

<script setup lang="ts">
import {
	onBeforeUnmount,
	computed,
	onMounted,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import ZyroFieldRange from '@/components/global/ZyroFieldRange.vue';
import {
	BLOCK_STICKY_BAR_MAX_HEIGHT,
	BLOCK_STICKY_BAR_MIN_HEIGHT,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

const {
	dispatch,
	state,
	getters,
} = useStore();

const minRange = ref(BLOCK_STICKY_BAR_MIN_HEIGHT);

const currentBlockId = computed(() => state.currentBlockId);
const currentBlock = computed(() => getters.currentBlock);
const viewport = computed(() => (getters['gui/isMobileMode'] ? 'mobile' : 'desktop'));
const height = computed(() => currentBlock.value[viewport.value]?.minHeight);

const updateBlockData = (value: number) => {
	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			[viewport.value]: {
				minHeight: value,
			},
		},
		merge: true,
	});
};

const updateHeight = (value: number) => {
	dispatch('leaveElementEditMode');
	updateBlockData(value);
};

onMounted(() => {
	const currentBlockRef = document.querySelector(`[data-block-id="${currentBlockId.value}"]`) as HTMLElement;
	const currentBlockHeight = currentBlockRef?.offsetHeight;

	// update minHeight range display if text has changed
	if (currentBlockHeight && currentBlockHeight !== height.value) {
		minRange.value = currentBlockHeight;

		updateBlockData(currentBlockHeight);
	}
});

onBeforeUnmount(() => {
	dispatch('undoRedo/createSnapshot');
});
</script>
