/* Injected dependencies */
/* eslint-disable import/no-cycle */
import {
	addBreadcrumb,
	captureException,
} from '@sentry/vue';
import {
	createRouter,
	createWebHistory,
} from 'vue-router';
import { getCookie } from '@zyro-inc/site-modules/utils/cookies';
import {
	activatePlan,
	getSites,
} from '@/api/BillingApi';
import {
	getRedirectUrlToHostingerLogin,
	getRedirectUrlToWWW,
	getRedirectUrlToDashboard,
	getRedirectUrlToEcommerce,
} from '@/use/useRedirects';
import EventLogApi from '@/api/EventLogApi';
import { getLocaleCodeBasedOnHostingerLanguage } from '@/utils/i18n/cookieLocale';
import {
	loadStartingLocaleMessages,
	loadLocaleMessagesAsync,
} from '@/i18n/setup';
import {
	WWW_REDIRECT_PATHS,
	REDIRECT_PARAM_VALUES,
	REDIRECT_PARAM_KEYS,
	COOKIE_SITE_ID,
	DRAWER_ANALYTICS,
	DRAWER_FORMS,
	DRAWER_GAMIFICATION,
	DRAWER_EXPORT,
	DRAWER_MULTI_PAGE,
	LOCAL_STORAGE_KEY_AI_PREVIEW_ENABLED,
	QUERY_PARAM_OPEN_AI_TEMPLATE_FLOW_FORM,
	JWT,
	AUTH_TOKEN,
	LOCAL_STORAGE_KEY_IS_FORCE_RELOAD_REQUIRED,
	LOCAL_STORAGE_KEY_CURRENT_ROUTE_PATH,
} from '@/constants/builderConstants';

import { SITE_PREVIEW_CLASS } from '@zyro-inc/site-modules/constants/siteModulesConstants';

import {
	triggerWebsiteSavedNotification,
	resetIsGoingToBuilder,
} from '@/components/ai-builder/useAiTemplate';
import {
	BUILDER_ROUTE,
	PREVIEW_ROUTE,
	VERSION_HISTORY_ROUTE,
	STORE_MANAGER_REDIRECT_ROUTE,
	SETTINGS_ROUTE,
	FORM_EMAIL_VERIFICATION_ROUTE,
	AI_BUILDER_ROUTE,
	AI_PREVIEW_ROUTE,
	TEMPLATES_ROUTE,
} from '@/constants/routes';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import { store } from '@/store/builder/builderStore';
import { usePublish } from '@/use/usePublish';
import { useUserAuthorizationState } from '@/use/useUserAuthorizationState';

import RouterView from '@/pages/RouterView.vue';
import Builder from '@/pages/Builder.vue';
import { REDIRECT_MUTATIONS } from '@/types/redirectModuleTypes';
import { authRedirectService } from '@/services/http/authRedirectService';
import { scrollToSection } from '@zyro-inc/site-modules/utils/scrollToSection';
import { forceReload } from '@/services/forceReloadService';
import { useIntercomStore } from '@/stores/intercomStore';

const {
	SIGN_UP,
	SIGN_IN,
	SITES,
} = WWW_REDIRECT_PATHS;
const { RETURN } = REDIRECT_PARAM_KEYS;
const { RETURN_BUILDER } = REDIRECT_PARAM_VALUES;

/* Builder pages */
const Preview = () => import('@/pages/Preview.vue');
const AiBuilderPreviewWrapper = () => import('@/pages/AiBuilderPreviewWrapper.vue');
const VersionHistory = () => import('@/pages/VersionHistory.vue');
const InstagramAuth = () => import('@/pages/instagram/Auth.vue');
const SiteNotFound = () => import('@/pages/SiteNotFound.vue');
const FormEmailVerification = () => import('@/pages/FormEmailVerification.vue');
const AiBuilder = () => import('@/pages/AiBuilder.vue');
const Templates = () => import('@/pages/Templates.vue');

const routeTransition = {
	transitionName: 'fade',
};

const getCurrentSiteIdFromCookie = async () => {
	const siteId = getCookie(COOKIE_SITE_ID);

	if (siteId) {
		return siteId;
	}

	// Temporary workaround for dPreviews to load the first available site when there is no site ID.
	if (window.location.hostname.includes('.hostinger.dev')) {
		const userSitesList = await getSites();

		return userSitesList.length > 0 ? userSitesList[0].id : null;
	}

	return null;
};

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/site-not-found',
			component: SiteNotFound,
		},
		{
			path: `/${AI_BUILDER_ROUTE}`,
			name: AI_BUILDER_ROUTE,
			component: AiBuilder,
			meta: routeTransition,
			beforeEnter: async (to, from, next) => {
				store.commit('setIsRouteLoading', true);

				const {
					fetchUserAuthorizationState,
					isUserPayToPublish,
					isUserAuthenticated,
					isUserWithActivePlan,
				} = useUserAuthorizationState();

				await fetchUserAuthorizationState();

				if (!isUserAuthenticated.value) {
					const hostingerLoginUrl = getRedirectUrlToHostingerLogin();

					window.location.assign(hostingerLoginUrl);
				}

				if (isUserPayToPublish.value && !to.query.siteId) {
					try {
						const usersSiteList = await getSites();

						const siteId = usersSiteList.length > 0 ? usersSiteList[0].id : '';

						if (siteId) {
							next({
								name: BUILDER_ROUTE,
								params: {
									siteId,
								},
							});
						}
					} catch (error) {
						// p2p user without an already generated site will get 401, so we need to let them through
						if (error.response?.status !== 401) {
							captureException(error);

							throw new Error('Error while fetching user sites', error);
						}
					}
				}

				// Redirect to HPanel to upgrade if user has no active plan and is not p2p
				if (!isUserPayToPublish.value && !isUserWithActivePlan.value && isUserAuthenticated.value) {
					authRedirectService.redirectToHpanel();

					return;
				}

				if (!isHostingerBrand && !to.query.siteId) {
					next('/site-not-found');
				} else {
					const { dispatch } = store;

					await loadStartingLocaleMessages();

					if (to.query.lang) {
						const localeCode = getLocaleCodeBasedOnHostingerLanguage(to.query.lang);

						if (localeCode) {
							await loadLocaleMessagesAsync(localeCode);
						}
					}

					await dispatch('initializeExperiments');

					next();
				}

				const locationPropertyValue = to.query.ref || to.query.location;

				// resetting for when user comes back from preview with browser back button and its state stays persistent
				resetIsGoingToBuilder();

				await EventLogApi.logEvent({
					eventName: 'website_builder.ai_builder.enter',
					eventProperties: {
						...(locationPropertyValue && {
							location: locationPropertyValue,
						}),
						screen_size: window.screen.width,
						is_p2p_user: isUserPayToPublish.value,
					},
				});
			},
		},
		{
			path: `/${TEMPLATES_ROUTE}/:category?/:page?`,
			name: TEMPLATES_ROUTE,
			component: Templates,
			meta: routeTransition,
			beforeEnter: async (to, from, next) => {
				const {
					isUserPayToPublish,
					fetchUserAuthorizationState,
				} = useUserAuthorizationState();

				await fetchUserAuthorizationState();

				// Remove used domain from query params to avoid site creation errors
				if (from.name === BUILDER_ROUTE && to.query.domain) {
					const {
						domain,
						...otherQueries
					} = to.query;

					next({
						...to,
						query: otherQueries,
					});

					return;
				}

				if (isUserPayToPublish.value && !to.query.siteId) {
					try {
						const usersSiteList = await getSites();

						const siteId = usersSiteList.length > 0 ? usersSiteList[0].id : '';

						if (siteId) {
							next({
								name: BUILDER_ROUTE,
								params: {
									siteId,
								},
							});
						}
					} catch (error) {
						// p2p user without an already generated site will get 401, so we need to let them through
						if (error.response?.status !== 401) {
							captureException(error);

							throw new Error('Error while fetching user sites', error);
						}
					}
				}

				if (!isHostingerBrand) {
					next('/site-not-found');
				} else {
					await loadStartingLocaleMessages();

					if (to.query.lang) {
						const localeCode = getLocaleCodeBasedOnHostingerLanguage(to.query.lang);

						if (localeCode) {
							await loadLocaleMessagesAsync(localeCode);
						}
					}

					next();
				}
			},
			scrollBehavior(to, from) {
				if (from.query?.preview) {
					return {};
				}

				return {
					top: 0,
				};
			},
		},
		{
			// Temporary support legacy entrance: (cached www/crm, missed code places)
			path: '/',
			component: RouterView,
			beforeEnter: async (to, from, next) => {
				try {
					const currentSiteId = await getCurrentSiteIdFromCookie();

					if (!currentSiteId) {
						if (isHostingerBrand) {
							authRedirectService.redirectToHpanel();

							return;
						}

						const dashboardUrl = getRedirectUrlToDashboard({
							path: SITES,
						});

						window.location.assign(dashboardUrl);

						return;
					}

					next(`/${currentSiteId}`);
				} catch (error) {
					if (error?.response?.status === 401) {
						window.location.assign(getRedirectUrlToHostingerLogin());

						return;
					}

					if (isHostingerBrand) {
						authRedirectService.redirectToHpanel();

						return;
					}

					const wwwUrl = getRedirectUrlToWWW({
						path: SIGN_IN,
						params: {
							[RETURN]: RETURN_BUILDER,
						},
					});

					window.location.assign(wwwUrl);
				}
			},
		},
		{
			// Use this route as parent, for all routes that require specific site.
			path: '/:siteId',
			component: RouterView,
			async beforeEnter(to, from, next) {
				const {
					state,
					dispatch,
				} = store;

				const { handlePublish } = usePublish();
				const { isUserWithActivePlan } = useUserAuthorizationState();
				const intercomStore = useIntercomStore();

				if (to.params.siteId === 'demo') {
					window.location.assign(AI_BUILDER_ROUTE);

					return;
				}

				if (!state.hasBuilderInitialized) {
					const [isInitSuccessful] = await Promise.all([
						dispatch('initBuilder', {
							siteId: to.params.siteId,
						}),
						loadStartingLocaleMessages(),
					]);

					if (!isInitSuccessful) {
						return;
					}
				}

				// If editor opened with query ?drawer=analytics, open analytics drawer
				if (to.query[REDIRECT_PARAM_KEYS.DRAWER] === REDIRECT_PARAM_VALUES.ANALYTICS) {
					dispatch('gui/OPEN_DRAWER', {
						id: DRAWER_ANALYTICS,
					});
				}

				// If editor opened with query ?drawer=forms, open forms drawer
				if (to.query[REDIRECT_PARAM_KEYS.DRAWER] === REDIRECT_PARAM_VALUES.FORMS) {
					dispatch('gui/OPEN_DRAWER', {
						id: DRAWER_FORMS,
					});
				}

				// If editor opened with query ?drawer=gamification, open gamification drawer
				if (to.query[REDIRECT_PARAM_KEYS.DRAWER] === REDIRECT_PARAM_VALUES.GAMIFICATION) {
					dispatch('gui/OPEN_DRAWER', {
						id: DRAWER_GAMIFICATION,
					});
				}

				// If editor opened with query ?drawer=export, open export to wordpress drawer
				if (to.query[REDIRECT_PARAM_KEYS.DRAWER] === REDIRECT_PARAM_VALUES.EXPORT) {
					dispatch('gui/OPEN_DRAWER', {
						id: DRAWER_EXPORT,
					});
				}

				if (to.query[REDIRECT_PARAM_KEYS.DRAWER] === REDIRECT_PARAM_VALUES.PAGES) {
					dispatch('gui/OPEN_DRAWER', {
						id: DRAWER_MULTI_PAGE,
					});
				}

				const shouldActivatePlan = to.query[REDIRECT_PARAM_KEYS.ACTIVATE_PLAN];

				if (shouldActivatePlan) {
					await activatePlan(to.params.siteId);
					await dispatch('subscription/getSubscription');
				}

				// Handle scenarios after user has completed a payment
				const returnParam = to.query[REDIRECT_PARAM_KEYS.RETURN];

				const shouldAutoPublish = returnParam === REDIRECT_PARAM_VALUES.RETURN_PUBLISH
					&& isUserWithActivePlan.value;

				if (shouldAutoPublish) {
					await handlePublish();
				}

				// Handle email redirects, that open editor with Intercom open
				const intercomParam = to.query[REDIRECT_PARAM_KEYS.OPEN_INTERCOM];

				if (intercomParam) {
					intercomStore.handleIntercomOpen();
				}

				next();
			},
			children: [
				{
					path: '',
					name: BUILDER_ROUTE,
					component: Builder,
					meta: routeTransition,
				},
				{
					path: PREVIEW_ROUTE,
					name: PREVIEW_ROUTE,
					component: Preview,
					meta: routeTransition,
					beforeEnter(to, from, next) {
						const { dispatch } = store;

						dispatch('updateFonts');

						next();
					},
				},
				{
					path: AI_PREVIEW_ROUTE,
					name: AI_PREVIEW_ROUTE,
					component: AiBuilderPreviewWrapper,
					meta: routeTransition,
					beforeEnter(to, from, next) {
						const {
							dispatch,
							state,
						} = store;
						const isAiPreviewEnabled = !!localStorage.getItem(LOCAL_STORAGE_KEY_AI_PREVIEW_ENABLED);
						const {
							isUserPayToPublish,
							isSiteCapacityReached,
						} = useUserAuthorizationState();

						if (!isAiPreviewEnabled) {
							const { isFromPreviewPage } = from.query;

							if (isFromPreviewPage) {
								next({
									name: BUILDER_ROUTE,
									params: {
										siteId: state.websiteId,
									},
								});

								return;
							}

							next({
								name: AI_BUILDER_ROUTE,
								query: {
									isFromPreviewPage: true,
								},
							});

							if (!isUserPayToPublish.value && !isSiteCapacityReached.value) {
								triggerWebsiteSavedNotification(dispatch);
							}

							return;
						}

						dispatch('updateFonts');

						next();
					},
				},
				{
					path: VERSION_HISTORY_ROUTE,
					name: VERSION_HISTORY_ROUTE,
					component: VersionHistory,
					meta: routeTransition,
				},
				{
					path: STORE_MANAGER_REDIRECT_ROUTE,
					meta: routeTransition,
					component: RouterView,
					beforeEnter(to, from, next) {
						if (store.getters['subscriptions/hasActiveBusinessSubscription'] || store.getters['ecommerce/isStoreTypeZyro']) {
							const ecommerceUrl = getRedirectUrlToEcommerce({
								siteId: to.params.siteId,
							});

							window.location.assign(ecommerceUrl);

							return;
						}

						next({
							query: {
								...to.query,
							},
							path: `/${to.params.siteId}`,
						});
					},
				},
				{
					path: SETTINGS_ROUTE,
					component: RouterView,
					meta: routeTransition,
					beforeEnter(to, from, next) {
						const { user } = store.state.user ?? {};

						if (import.meta.env.DEV || user) {
							next();
						} else if (isHostingerBrand) {
							authRedirectService.redirectToHpanel();
						} else {
							const wwwUrl = getRedirectUrlToWWW({
								path: SIGN_UP,
								params: {
									[RETURN]: RETURN_BUILDER,
								},
							});

							window.location.assign(wwwUrl);
						}
					},
				},
			],
		},
		{
			path: '/instagram/auth',
			component: InstagramAuth,
			async beforeEnter(to, from, next) {
				const { dispatch } = store;

				const decodedSate = decodeURIComponent(decodeURI(to.query.state));

				await dispatch('initBuilder', {
					siteId: JSON.parse(decodedSate).siteId,
				});

				next();
			},
		},
		...(isHostingerBrand ? [
			{
				path: `/${FORM_EMAIL_VERIFICATION_ROUTE}`,
				name: FORM_EMAIL_VERIFICATION_ROUTE,
				component: FormEmailVerification,
			},
		] : []),
		// TEST ROUTES:

		/**
		 * Opens plain editor without any API calls.
		 * Used for editor loading speed testing purposes
		 */
		{
			path: '/test/editor-plain',
			component: Builder,
			meta: routeTransition,
			async beforeEnter(to, from, next) {
				const { dispatch } = store;
				const localTemplate = (await import('@/assets/templates/editor-plain-template.json')).default;

				dispatch('overwriteWebsiteData', {
					websiteData: localTemplate,
				});
				dispatch('updateCurrentPageId');
				next();
			},
		},
	],
	scrollBehavior: (to, from) => {
		// disable scrolling when in ai templates flow
		if ((from.name === TEMPLATES_ROUTE && from.query?.[QUERY_PARAM_OPEN_AI_TEMPLATE_FLOW_FORM])
			|| (to.name === TEMPLATES_ROUTE && to.query?.[QUERY_PARAM_OPEN_AI_TEMPLATE_FLOW_FORM])) {
			return;
		}

		if (to.matched.some((m) => m.meta.disableScroll)) return;

		// eslint-disable-next-line consistent-return
		return {
			top: 0,
			left: 0,
		};
	},
});

const manageRedirects = (to, from) => {
	const notSameNames = to.name !== from.name;

	if (notSameNames && from.name !== store.state.redirect.routeName) {
		store.commit(REDIRECT_MUTATIONS.UNSET_REDIRECT);
	}
};

const removeJWTQuery = (to, next) => {
	if (!to.query[JWT] && !to.query[AUTH_TOKEN]) {
		return next();
	}

	const {
		[JWT]: jwt,
		[AUTH_TOKEN]: authToken,
		...otherQueries
	} = to.query;

	return next({
		...to,
		query: otherQueries,
	});
};

router.afterEach(async () => {
	store.commit('setIsRouteLoading', false);
});

router.beforeEach((to, from, next) => {
	removeJWTQuery(to, next);
});

// This global guard works together with initForceReloadCheck function.
// Route navigation won't be finished if chunk load error occurs. (User stays in the same page)
// This guard sets correct route that should be active if there is no chunk load error.
router.beforeEach(async (to, _, next) => {
	localStorage.setItem(LOCAL_STORAGE_KEY_CURRENT_ROUTE_PATH, to.fullPath);

	const isForceReloadRequired = !!Number(localStorage.getItem(LOCAL_STORAGE_KEY_IS_FORCE_RELOAD_REQUIRED));

	if (!isForceReloadRequired) {
		next();

		return;
	}

	await forceReload(to.fullPath);

	next();
});

router.beforeEach(async (to, from, next) => {
	manageRedirects(to, from);

	addBreadcrumb({
		category: 'NAVIGATION',
		data: {
			from,
			to,
		},
		level: 'debug',
		type: 'debug',
	});

	next();
});

router.beforeEach(async (to, from, next) => {
	const {
		state,
		dispatch,
	} = store;

	const {
		isUserPayToPublish,
		isSiteCapacityReached,
	} = useUserAuthorizationState();

	if (
		![
			TEMPLATES_ROUTE,
			AI_PREVIEW_ROUTE,
			PREVIEW_ROUTE,
			VERSION_HISTORY_ROUTE,
		].includes(from.name)
	) {
		next();
	} else {
		if (from.name === TEMPLATES_ROUTE && to.name !== BUILDER_ROUTE) {
			next();

			return;
		}

		const { isFromPreviewHeader } = state.redirect.params;

		if (to.name === AI_BUILDER_ROUTE) {
			next();

			if (!isUserPayToPublish.value && !isSiteCapacityReached.value) {
				triggerWebsiteSavedNotification(dispatch);
			}

			return;
		}

		if (isFromPreviewHeader) {
			next();

			return;
		}

		next(false);

		await dispatch('gui/updatePreviewPageData', {
			path: to.path,
		}, {
			root: true,
		});

		// Check if the hash exists
		if (to.hash) {
			const sectionWithHash = document.querySelector(to.hash);

			if (sectionWithHash) {
				scrollToSection({
					linkToSection: to.hash,
					isPreviewMode: true,
				});
			}
		} else {
			const preview = document.querySelector(`.${SITE_PREVIEW_CLASS}`);

			preview.scrollTo({
				top: 0,
			});
		}
	}
});

router.onError(async (error) => {
	store.commit('setIsRouteLoading', false);

	if (error.message.includes('Failed to fetch dynamically imported module')) {
		const { dispatch } = store;

		// save website before reload
		await dispatch('saving/saveWebsite');

		// reload page if it failed to fetch imported module
		window.location.reload();

		return;
	}

	captureException(error);
});

export default router;
