import {
	computed,
	watch,
} from 'vue';
import { useStore } from 'vuex';

import {
	getElementGroups,
	getElementPositionsAfterGroupsSwitch,
	getElementsColumnGroups,
	sortColumnGroupsElementsByTop,
	getMergedElementGroups,
	getHighestElement,
	findUniqueElementIdByComparingElementArrays,
} from '@/utils/layout';

import { checkIfTwoArraysHaveTheSameOrder } from '@/utils/array';

import {
	BLOCK_TYPE_LAYOUT,
	ELEMENT_POSITION_KEY_MOBILE,
	DEFAULT_SECTION_ROW_GAP,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

import {
	LAYOUT_MOBILE_AUTO_POSITIONING_KEY,
	LAYOUT_MOBILE_AUTO_POSITIONING_MIN_ELEMENTS_COUNT,
	LAYOUT_ELEMENT_MAX_WIDTH_MOBILE,
} from '@/constants/builderConstants';

export const updateMobileElementsPositions = ({
	elements,
	mobileElementsOrder,
	spacingY,
}) => mobileElementsOrder.reduce((fixedElementsPositions, elementId) => {
	const element = elements[elementId];
	const elementMobilePosition = element[ELEMENT_POSITION_KEY_MOBILE];
	const elementIndex = mobileElementsOrder.indexOf(elementId);

	const previousElementId = mobileElementsOrder[elementIndex - 1];
	const previousElement = fixedElementsPositions.find(({ elementId: _elementId }) => _elementId === previousElementId);
	const previousElementMobilePosition = previousElement?.[ELEMENT_POSITION_KEY_MOBILE];
	const previousElementBottomPosition = previousElementMobilePosition
			&& previousElementMobilePosition.top + previousElementMobilePosition.height;

	// If previous element doesn't exist it means current element is a first element
	// So offset it only by spacingY
	const topOffset = previousElement ? previousElementBottomPosition + spacingY : spacingY;

	// Center elements inside mobile block
	const blockCenter = LAYOUT_ELEMENT_MAX_WIDTH_MOBILE / 2;
	const elementCenterLeftPosition = blockCenter - (elementMobilePosition.width / 2);

	const fixedElementPosition = {
		...element,
		[ELEMENT_POSITION_KEY_MOBILE]: {
			...elementMobilePosition,
			top: topOffset,
			left: elementCenterLeftPosition,
		},
	};

	return [
		...fixedElementsPositions,
		fixedElementPosition,
	];
}, []);

const getUpdatedMobileTopPositions = ({
	selectedElementId,
	mobileElementsGroups,
	currentMobileElementsOrder,
	newMobileElementsOrder,
	multiSelectLength = 0,
}) => {
	const multiSelectOffset = multiSelectLength ? multiSelectLength - 1 : 0;
	const isSelectedElementMovedUp = newMobileElementsOrder.indexOf(selectedElementId)
		< currentMobileElementsOrder.indexOf(selectedElementId);

	const isSelectedElementInTheSamePosition = newMobileElementsOrder.indexOf(selectedElementId)
		=== currentMobileElementsOrder.indexOf(selectedElementId);

	const higherGroupStartIndex = isSelectedElementMovedUp
		? newMobileElementsOrder.indexOf(selectedElementId)
		: currentMobileElementsOrder.indexOf(selectedElementId);

	const higherGroupEndIndex = isSelectedElementMovedUp
		? currentMobileElementsOrder.indexOf(selectedElementId) - 1
		: currentMobileElementsOrder.indexOf(selectedElementId) + multiSelectOffset;

	const higherElementGroup = getMergedElementGroups({
		elementGroups: mobileElementsGroups,
		startIndex: higherGroupStartIndex,
		endIndex: higherGroupEndIndex,
	});

	const lowerGroupStartIndex = isSelectedElementMovedUp
		? currentMobileElementsOrder.indexOf(selectedElementId)
		: currentMobileElementsOrder.indexOf(selectedElementId) + multiSelectOffset + 1;

	const lowerGroupEndIndex = isSelectedElementMovedUp
		? currentMobileElementsOrder.indexOf(selectedElementId) + multiSelectOffset
		: newMobileElementsOrder.indexOf(selectedElementId) + multiSelectOffset;

	const lowerElementGroup = getMergedElementGroups({
		elementGroups: mobileElementsGroups,
		startIndex: lowerGroupStartIndex,
		endIndex: lowerGroupEndIndex,
	});

	const elementPositionsAfterSwitch = getElementPositionsAfterGroupsSwitch({
		higherGroup: higherElementGroup,
		lowerGroup: isSelectedElementInTheSamePosition || !lowerElementGroup ? higherElementGroup : lowerElementGroup,
		elementPositionKey: ELEMENT_POSITION_KEY_MOBILE,
	});

	return elementPositionsAfterSwitch;
};

export const useLayoutMobilePositioning = (props) => {
	const {
		dispatch,
		getters,
	} = useStore();

	const siteElements = computed(() => getters.siteElements);
	const siteBlocks = computed(() => getters.siteBlocks);
	const isMobileMode = computed(() => getters['gui/isMobileMode']);

	const block = computed(() => siteBlocks.value[props.blockId.value]);

	const blockElements = computed(() => {
		if (!block.value?.components) {
			return [];
		}

		return block.value.components.map((elementId) => {
			const element = siteElements.value[elementId];

			return {
				...element,
				elementId,
			};
		});
	});
	const blockElementsObject = computed(() => Object.fromEntries(blockElements.value.map((element) => [
		element.elementId,
		element,
	])));

	const mobileElementsGroups = computed(() => getElementGroups({
		elements: blockElements.value,
		elementPositionKey: ELEMENT_POSITION_KEY_MOBILE,
	}));

	const currentMobileElementsOrder = computed(() => blockElements.value
		.sort((a, b) => a[ELEMENT_POSITION_KEY_MOBILE].top - b[ELEMENT_POSITION_KEY_MOBILE].top)
		.map((element) => element.elementId));

	const columnGroupsElementsSortedByTop = computed(() => {
		const elementsColumnGroups = getElementsColumnGroups({
			elements: blockElements.value,
		});

		return sortColumnGroupsElementsByTop({
			elementsColumnGroups,
			blockElements: blockElementsObject.value,
		});
	});

	const newMobileElementsOrder = computed(() => columnGroupsElementsSortedByTop.value.flatMap((group) => group.elements));

	const isMobileAutoPositioningEnabled = computed(() => block.value && !!block.value[LAYOUT_MOBILE_AUTO_POSITIONING_KEY]);

	// Check if the order of the elements in the mobile view is the same as in the desktop view
	// this is used to correctly disable auto-positioning then elements order has changed
	const isMobileElementsOrderTheSameAsDesktop = computed(() => checkIfTwoArraysHaveTheSameOrder({
		array1: currentMobileElementsOrder.value,
		array2: newMobileElementsOrder.value,
	}));

	const isMobileAutoPositioningToggleVisible = computed(() => !isMobileAutoPositioningEnabled.value
			&& isMobileMode.value
			&& block.value.type === BLOCK_TYPE_LAYOUT
			&& block.value.components.length >= LAYOUT_MOBILE_AUTO_POSITIONING_MIN_ELEMENTS_COUNT);

	const updateGroupMobileOrder = ({
		selectedElementId,
		multiSelectLength,
	}) => {
		const elementPositionsAfterSwitch = getUpdatedMobileTopPositions({
			selectedElementId,
			currentMobileElementsOrder: currentMobileElementsOrder.value,
			newMobileElementsOrder: newMobileElementsOrder.value,
			mobileElementsGroups: mobileElementsGroups.value,
			multiSelectLength,
		});

		elementPositionsAfterSwitch.forEach(({
			elementId,
			position,
		}) => {
			dispatch('mergeElementData', {
				elementId,
				elementData: {
					[ELEMENT_POSITION_KEY_MOBILE]: {
						...position,
					},
				},
			});
		});
	};

	const updateSectionElementsMobileOrder = ({ selectedElementsIds }) => {
		// Check if all selected elements are in the block
		// When elements are moved to other block, selectedElementsIds will contain elements that are not in the block
		const isEveryElementIsInBlock = selectedElementsIds.every((id) => blockElementsObject.value[id]);

		if (
			!isMobileAutoPositioningEnabled.value
			|| !isEveryElementIsInBlock
		) {
			return;
		}

		const isMultiSelect = selectedElementsIds.length > 1;
		const selectedElementId = getHighestElement({
			elementsIds: selectedElementsIds,
			blockElements: blockElementsObject.value,
		});

		const currentSelectedElementMobileOrderIndex = currentMobileElementsOrder.value
			.findIndex((elementId) => elementId === selectedElementId);
		const newSelectedElementMobileOrderIndex = newMobileElementsOrder.value
			.findIndex((elementId) => elementId === selectedElementId);

		// Check if the selected element has changed its position in the mobile view
		const hasSelectedElementChangedMobilePosition = currentSelectedElementMobileOrderIndex
			!== newSelectedElementMobileOrderIndex;

		// If selected element hasn't changed its position, don't update the groups
		if (!hasSelectedElementChangedMobilePosition) {
			return;
		}

		if (isMultiSelect) {
			updateGroupMobileOrder({
				selectedElementId: selectedElementsIds[0],
				multiSelectLength: selectedElementsIds.length,
			});
		} else {
			updateGroupMobileOrder({
				selectedElementId,
			});
		}
	};

	const updateMobileAutoPositioning = ({ isMobileAutoPositioningEnabled: _isMobileAutoPositioningEnabled }) => {
		dispatch('updateBlockData', {
			blockId: props.blockId.value,
			blockData: {
				[LAYOUT_MOBILE_AUTO_POSITIONING_KEY]: _isMobileAutoPositioningEnabled ? true : null,
			},
			merge: true,
		});

		if (!_isMobileAutoPositioningEnabled) {
			return;
		}

		// If auto positioning is enabled, update the elements positions and section height

		const spacingY = block.value.snapRowGap || DEFAULT_SECTION_ROW_GAP;
		const updatedMobileElementsPositions = updateMobileElementsPositions({
			elements: blockElementsObject.value,
			mobileElementsOrder: newMobileElementsOrder.value,
			spacingY,
		});

		const lastElement = updatedMobileElementsPositions[updatedMobileElementsPositions.length - 1];
		const lastElementBottomPosition = lastElement[ELEMENT_POSITION_KEY_MOBILE].top
				+ lastElement[ELEMENT_POSITION_KEY_MOBILE].height;
		const updatedSectionHeight = lastElementBottomPosition + spacingY;

		updatedMobileElementsPositions.forEach(({
			elementId,
			mobile: position,
		}) => {
			dispatch('mergeElementData', {
				elementId,
				elementData: {
					[ELEMENT_POSITION_KEY_MOBILE]: {
						...position,
					},
				},
			});
		});

		dispatch('updateBlockData', {
			blockId: props.blockId.value,
			blockData: {
				[ELEMENT_POSITION_KEY_MOBILE]: {
					minHeight: updatedSectionHeight,
				},
			},
			merge: true,
		});

		dispatch('undoRedo/createSnapshot');
	};

	watch([
		blockElements,
		props.blockId,
	], ([newBlockElements, newBlockId], [oldsBlockElements, oldBlockId]) => {
		const hasElementsCountChanged = newBlockElements.length !== oldsBlockElements.length;

		// BlockId is changed because EditBlockButton is not discarded when switching between blocks
		const hasBlockIdChanged = newBlockId !== oldBlockId;

		if (!hasElementsCountChanged || hasBlockIdChanged || !blockElements.value.length) {
			return;
		}

		// If elements count has changed and changes were done on mobile disable auto positioning
		if (isMobileMode.value) {
			updateMobileAutoPositioning({
				isMobileAutoPositioningEnabled: false,
			});
			// Check if new element was added to the block
			// If it did update mobile order of the elements
		} else if (newBlockElements.length > oldsBlockElements.length) {
			const addedElementId = findUniqueElementIdByComparingElementArrays({
				oldElements: oldsBlockElements,
				newElements: newBlockElements,
			});

			updateSectionElementsMobileOrder({
				selectedElementsIds: [addedElementId],
			});
		}
	});

	// Disable mobile auto positioning if element has been moved on mobile
	// and elements order is not the same as on desktop
	watch(isMobileElementsOrderTheSameAsDesktop, (newIsMobileElementsOrderTheSameAsDesktop) => {
		if (isMobileMode.value && isMobileAutoPositioningEnabled.value && !newIsMobileElementsOrderTheSameAsDesktop) {
			updateMobileAutoPositioning({
				isMobileAutoPositioningEnabled: false,
			});
		}
	});

	return {
		updateSectionElementsMobileOrder,
		isMobileAutoPositioningEnabled,
		isMobileElementsOrderTheSameAsDesktop,
		isMobileAutoPositioningToggleVisible,
		updateMobileAutoPositioning,
	};
};
