<template>
	<ZyroDrawer
		class="drawer-styles"
		:is-back-button-visible-on-desktop="currentPage.component !== 'UserStyles'"
		:header-title="$t('builder.userStyles.drawerLabel')"
		:header-description="$t('builder.userStyles.drawerSubtitle')"
		@back="handleBackClick()"
	>
		<template #header>
			<p
				v-if="currentPage.subtext18nPath"
				class="text-body-2 style-list__subtext"
			>
				{{ $t(currentPage.subtext18nPath) }}
			</p>
		</template>

		<template #body>
			<div
				v-if="isLoadingUserStylesDrawer"
				class="drawer-styles-loader"
			>
				<ZyroLoader />
			</div>

			<div
				v-else
				class="drawer-styles-tabs"
			>
				<UserStyles v-if="currentPage.component === USER_STYLES_DRAWER_STYLES_COMPONENT" />
				<TypographyCategorySelect v-else-if="currentPage.component === USER_STYLES_DRAWER_TYPOGRAPHY_COMPONENT" />
				<TypographyTypeSelect v-else-if="currentPage.component === USER_STYLES_DRAWER_TYPOGRAPHY_TYPE_SELECT_COMPONENT" />
			</div>
		</template>
	</ZyroDrawer>
</template>

<script setup lang="ts">
import ZyroDrawer from '@/components/global/ZyroDrawer.vue';

import {
	onBeforeMount,
	computed,
	onMounted,
} from 'vue';
import { useStore } from 'vuex';

import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';

import UserStyles from '@/components/builder-drawers/drawers/partials/stylesDrawer/UserStyles.vue';
import TypographyCategorySelect from '@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/TypographyCategorySelect.vue';
import TypographyTypeSelect from '@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/TypographyTypeSelect.vue';
import { useTypographyStylesLibrary } from '@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/use/useTypographyStylesLibrary';
import { useUserStyles } from '@/components/builder-drawers/drawers/partials/stylesDrawer/use/useUserStyles';
import {
	DRAWER_USER_STYLES,
	USER_STYLES_DRAWER_STYLES_COMPONENT,
	USER_STYLES_DRAWER_TYPOGRAPHY_COMPONENT,
	USER_STYLES_DRAWER_TYPOGRAPHY_TYPE_SELECT_COMPONENT,
} from '@/constants/builderConstants';
import { UPDATE_CURRENT_FONT_STYLES } from '@/store/builder/fonts';
import { CHANGE_PREVIOUS_DRAWER_PAGE } from '@/store/builder/gui';
import { useSidebar } from '@/use/useSidebar';

const {
	state,
	dispatch,
} = useStore();

const {
	getCurrentTemplateStyles,
	hasLoadedCurrentTemplateStyles,
} = useUserStyles();
const { addTemplateTypographyStylesToList } = useTypographyStylesLibrary();

const { closeInnerSidebar } = useSidebar();
const currentPage = computed(() => state.gui.drawerPage[DRAWER_USER_STYLES]);

const isLoadingFonts = computed(() => state.fonts.isLoadingFonts);
const isLoadingUserStylesDrawer = computed(() => isLoadingFonts.value || !hasLoadedCurrentTemplateStyles.value);

const handleBackClick = () => {
	if (currentPage.value.component === USER_STYLES_DRAWER_STYLES_COMPONENT) {
		closeInnerSidebar();

		return;
	}

	dispatch(`gui/${CHANGE_PREVIOUS_DRAWER_PAGE}`, DRAWER_USER_STYLES);
};

onBeforeMount(async () => {
	if (!hasLoadedCurrentTemplateStyles.value) {
		await getCurrentTemplateStyles();
		addTemplateTypographyStylesToList();
	}
});

onMounted(() => {
	dispatch(`fonts/${UPDATE_CURRENT_FONT_STYLES}`);
});
</script>

<style lang="scss" scoped>

@import "@/components/builder-drawers/drawers/partials/forms";

.style-list {
	&__back-button {
		margin-right: 8px;
		transform: rotate(180deg);
	}
}

.drawer-styles {
	&__subtext {
		margin-bottom: 24px;
		color: $color-gray;
	}

	&__nps-rate {
		margin-top: 0;
		margin-bottom: 24px;
	}

	&__tabs {
		margin-bottom: 24px;
	}
}

.drawer-styles-loader {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.drawer-styles-tabs {
	:deep() {
		.zyro-tabs {
			margin-top: 0;
			margin-left: -16px;
			margin-right: -16px;
			width: auto;
		}
	}
}
</style>
