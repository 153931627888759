<template>
	<EditableItemsList
		ref="items"
		:items="editableItems"
		:placeholder="$t('builder.blog.blogPostSettings.writeCategories')"
		:item-placeholder-text="$t('builder.blog.blogPostSettings.itemPlaceholder')"
		:validate-value="validator"
		@edit="editItem"
		@update-items="updateItems"
	>
		<template #header>
			<EditableItemsAddButton
				:button-text="$t('builder.blog.blogPostSettings.addCategory')"
				:placeholder="$t('builder.blog.blogPostSettings.writeCategories')"
				:validate-value="validator"
				@add="addItem"
			/>
		</template>
		<template #item-button="{ item, startEditingItem }">
			<BlogCategoryPopup
				@duplicate="duplicateItem(item.name)"
				@delete="handleCategoryDelete(item.name)"
				@edit="startEditingItem"
			/>
		</template>
	</EditableItemsList>
</template>

<script setup lang="ts">
import BlogCategoryPopup from '@/components/builder-controls/blog-drawer/BlogCategoryPopup.vue';

import EditableItemsAddButton from '@/components/reusable-components/editable-items-list/-partials/EditableItemsAddButton.vue';
import EditableItemsList from '@/components/reusable-components/editable-items-list/EditableItemsList.vue';

import { computed } from 'vue';
import { useBlogStore } from '@/stores/blogStore';
import { generateRandomId } from '@/utils/generateRandomId';
import { useI18n } from 'vue-i18n';
import { SiteBlogCategory } from '@hostinger/builder-schema-validator';

const blogStore = useBlogStore();

const { t } = useI18n();

const handleCategoryDelete = (categoryName: string) => {
	const categoryId = blogStore.getCategoryIdByName(categoryName);

	blogStore.removeCategory(categoryId as string);
};

const editableItems = computed(() => blogStore.categoriesNames.map((name) => ({
	name,
})));

const validator = (categoryName: string) => {
	if (!categoryName) {
		return {
			isValid: false,
			error: t('validate.emptyValue'),
		};
	}

	if (blogStore.categoriesNames.includes(categoryName)) {
		return {
			isValid: false,
			error: t('builder.blog.blogPostSettings.error'),
		};
	}

	return {
		isValid: true,
		error: '',
	};
};

const updateItems = (newCategoryNames: SiteBlogCategory[]) => {
	const newCategories = newCategoryNames.reduce(
		(newCategoriesArray, { name }) => ({
			...newCategoriesArray,
			[String(blogStore.getCategoryIdByName(name))]: {
				name,
			},
		}),
		{},
	);

	blogStore.setCategories(newCategories);
};

const editItem = ({
	oldValue,
	newValue,
}: { oldValue: SiteBlogCategory, newValue: SiteBlogCategory }) => {
	blogStore.editCategory({
		oldName: oldValue.name,
		newName: newValue.name,
	});
};

const addItem = (newValue: string) => {
	blogStore.addCategory({
		categoryName: newValue,
	});
};

const duplicateItem = (newValue: string) => {
	blogStore.addCategory({
		categoryName: `${newValue}-${generateRandomId()}`,
	});
};

</script>
