export const fitToLayoutXBounds = (position, blockWidth, shouldCenter = false) => {
	const {
		left,
		width,
		top,
		height,
	} = position;

	// Override left lesser than 0 and greater than block width - element width
	const leftPosition = shouldCenter
		? Math.max(0, (blockWidth - width) / 2)
		: Math.min(Math.max(0, left), Math.max(0, blockWidth - width));

	return {
		top,
		left: leftPosition,
		height,
		width: Math.min(width, blockWidth),
	};
};
