import { getCookie } from '@zyro-inc/site-modules/utils/cookies';

import {
	COOKIE_MANUAL_LOCALE,
	COOKIE_GEOIP_LOCALE,
	COOKIE_HOSTINGER_LANGUAGE,
	COOKIE_E2E_SKIP_AMPLITUDE,
} from '@/constants/builderConstants';

import { BuilderLocales } from '@/types/localeTypes';

export const getManualLocaleCookie = () => getCookie(COOKIE_MANUAL_LOCALE);

export const getGeoIPLocaleCookie = () => getCookie(COOKIE_GEOIP_LOCALE);

export const getHostingerLanguageCookie = (): BuilderLocales => getCookie(COOKIE_HOSTINGER_LANGUAGE) as BuilderLocales;

export const getSkipAmplitudeCookie = () => getCookie(COOKIE_E2E_SKIP_AMPLITUDE) === 'true';
