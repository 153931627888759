<template>
	<div class="edit-store">
		<HImage
			src="/images/store-upgradeSite.png"
			:alt="$t('builder.editStoreModalContainsAdvanced')"
			class="edit-store__image"
		/>
		<h5 class="text-heading-1 z-font-weight-normal edit-store__title">
			{{ $t('builder.editStoreModalContainsAdvanced') }}
		</h5>
		<i18n-t
			class="edit-store__text"
			keypath="builder.editStoreModalSwitchToOnline"
		>
			<a
				href="//support.zyro.com/en/articles/6000617-how-to-switch-to-the-lite-store"
				target="_blank"
				class="edit-store__link"
			>
				{{ $t('builder.editStoreModalSwitchToOnlineSlot') }}
			</a>
		</i18n-t>
	</div>
</template>

<script>
import HImage from '@/components/global/HImage.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		HImage,
	},
	props: {
		qaSelector: {
			type: String,
			required: true,
		},
	},
	emits: ['button-click'],

	methods: {
		handleButtonClick() {
			this.$emit('button-click');
		},
	},
});
</script>

<style lang="scss" scoped>
@import "./style";
</style>
