<script setup lang="ts">
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';
import EditBlockTabBackground from '@/components/builder-controls/edit-block/EditBlockTabBackground.vue';
import EditBlockStickyBarLayout from '@/components/builder-controls/edit-block-sticky-bar/EditBlockStickyBarLayout.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import { NPS_TYPE_FEATURE_BLOCK_STICKY_BAR } from '@/constants/builderConstants';

const props = defineProps({
	startTabId: {
		type: String,
		default: null,
	},
});

defineEmits(['close']);

const { t } = useI18n();
const tabs = [
	{
		id: 'background',
		title: t('common.background'),
		component: EditBlockTabBackground,
	},
	{
		id: 'layout',
		title: t('common.layout'),
		component: EditBlockStickyBarLayout,
	},
];
const initialTab = tabs.find((tab) => tab.id === props.startTabId) ?? tabs[0];
const currentTab = ref(initialTab);
</script>

<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('builder.sectionSettings.title')"
		:tabs="tabs"
		:current-tab="currentTab"
		@update:current-tab="currentTab = $event"
		@close="$emit('close')"
	>
		<Component
			:is="currentTab.component"
			is-fixed-background-disabled
		/>
		<NpsRateFeature
			:feature-name="t('builder.npsRateStickyBar')"
			:type="NPS_TYPE_FEATURE_BLOCK_STICKY_BAR"
		/>
	</ZyroPopupCard>
</template>
