<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import { useSeoStepOnboarding } from '@/use/useSeoStepOnboarding';
import SeoOnboardingModal from '@/components/builder-modals/modals/SeoOnboardingModal.vue';

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

type Props = {
    pageName: string;
    pageId: string;
}

defineProps<Props>();

const {
	isOnboardingStarted,
	endOnboarding,
	restartOnboarding,
} = useSeoStepOnboarding();

</script>

<template>
	<div class="ai-seo-card">
		<div class="ai-seo-card__content-container">
			<div>
				<Icon
					class="ai-seo-card__icon"
					name="auto_awesome"
					dimensions="24px"
				/>
			</div>
			<div>
				<p class="ai-seo-card__title text-bold-2">
					{{ t('builder.seoCheckerAssistant') }}
				</p>
				<i18n-t
					data-qa="seo-checker-assistant-description"
					class="ai-seo-card__subtitle text-body-2"
					tag="p"
					keypath="builder.seoCheckerGetSuggestionsDescription"
				>
					<b>{{ pageName }}</b>
				</i18n-t>
				<HostingerButton
					data-qa="seo-checker-assistant-scan-button"
					theme="primary"
					button-type="outlined"
					@click="restartOnboarding"
				>
					{{ t('builder.seoCheckerAiSeoAssistantGenerate') }}
				</HostingerButton>
			</div>
		</div>
	</div>

	<SeoOnboardingModal
		v-if="isOnboardingStarted"
		:page-id="pageId"
		@close="endOnboarding"
	/>
</template>

<style lang="scss" scoped>

.ai-seo-card {
	background-color: $color-light;
	border-radius: 8px;
	border: 1px solid $color-gray-border;
	margin-bottom: 32px;

	&__content-container {
		display: flex;
		padding: 16px;
		gap: 16px;

		@media screen and (max-width: $media-mobile) {
			flex-direction: column;
			gap: 16px;
		}
	}

	&__subtitle {
		margin-bottom: 16px;
		color: $color-gray-dark;
	}

	&__icon {
		color: $color-primary;
	}

	&__title {
		margin-bottom: 8px;
	}
}
</style>
