<script setup lang="ts">
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';

import { useStore } from 'vuex';
import { computed } from 'vue';

import {
	addressToGoogleMapUrl,
	googleMapUrlToAddress,
} from '@/utils/urlValidators';

const {
	getters,
	dispatch,
} = useStore();

const currentElementId = computed(() => getters.currentElementId);
const src = computed(() => googleMapUrlToAddress(getters.currentElementSettings.src));

const updateSrc = (newValue: string) => {
	dispatch('mergeCurrentElementData', {
		elementData: {
			settings: {
				src: addressToGoogleMapUrl(newValue),
			},
		},
	});
};
</script>

<template>
	<div>
		<ZyroFieldInput
			:id="`${currentElementId}-text`"
			:model-value="src"
			:label="$t('builder.editMap.tabActionLabel')"
			@update:model-value="updateSrc"
		/>
	</div>
</template>
