export type QrImageFormatType = 'PNG' | 'SVG';

export const SUPPORTED_FILE_TYPES = [
	'jpg',
	'jpeg',
	'png',
	'gif',
	'svg',
	'webp',
	'wav',
	'mp3',
	'flac',
	'ogg',
	'mp4',
	'avi',
	'mov',
	'wmv',
	'flv',
	'doc',
	'docx',
	'xls',
	'xlsx',
	'ppt',
	'pptx',
	'odt',
	'odp',
	'pdf',
	'epub',
	'txt',
	'rtf',
] as const;

export type SupportedFileType = typeof SUPPORTED_FILE_TYPES[number];

export interface FileAsset {
	category: string;
	hasFailed: boolean;
	id: string;
	isBeingUploaded: boolean;
	isFetchedFromServer: boolean;
	isGalleryImageSelected: string;
	isUnsupportedFormat: boolean;
	isDimensionsTooLarge: boolean;
	isValid: boolean;
	lastModified: string;
	transferProgress: number;
	url: string;
	file?: any
	initialDirectory?: string;
}

export const ASSET_CATEGORY = {
	IMAGE: 'image',
	VIDEO: 'video',
	AI_IMAGE: 'aiImage',
	DOCUMENT: 'document',
} as const;

export type AssetCategory = typeof ASSET_CATEGORY[keyof typeof ASSET_CATEGORY];

export const ASSETS_CATEGORIES = [
	ASSET_CATEGORY.IMAGE,
	ASSET_CATEGORY.DOCUMENT,
] as const;

export type AssetCategories = Partial<typeof ASSETS_CATEGORIES>;

export const ASSETS_TAB_ID = {
	MY_LIBRARY: 'myLibrary',
	MY_LIBRARY_PAYWALL: 'myLibraryPaywall',
	IMAGES: 'images',
	DOCUMENTS: 'documents',
	UNSPLASH: 'unsplash',
	PEXELS: 'pexels',
	AI_IMAGES: 'aiImages',
} as const;

export type AssetsTabId = typeof ASSETS_TAB_ID[keyof typeof ASSETS_TAB_ID];
