<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('builder.editForm.formSettings')"
		:tabs="tabs"
		:current-tab="currentTab"
		editor-popup-width="420px"
		@update:current-tab="changeTab"
		@close="$emit('close')"
	>
		<EditFormTabGeneral v-if="currentTab.component === 'EditFormTabGeneral'" />
		<EditFormTabFields v-if="currentTab.component === 'EditFormTabFields'" />
		<EditFormTabButton
			v-if="currentTab.component === 'EditFormTabButton'"
			@open-button-styles="openButtonStyles"
		/>
		<EditFormTabStyle
			v-if="currentTab.component === 'EditFormTabStyle'"
			:tab-to-open="stylesTabToOpen"
		/>
		<EditTabAnimation v-if="currentTab.component === 'EditTabAnimation'" />
		<NpsRateFeature
			:feature-name="$t('builder.npsRateForm')"
			:type="NPS_TYPE_FEATURE_FORM"
		/>
	</ZyroPopupCard>
</template>

<script setup>
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';

import EditFormTabButton from '@/components/builder-controls/edit-form/EditFormTabButton.vue';
import EditFormTabFields from '@/components/builder-controls/edit-form/EditFormTabFields/EditFormTabFields.vue';
import EditFormTabGeneral from '@/components/builder-controls/edit-form/EditFormTabGeneral.vue';
import EditFormTabStyle from '@/components/builder-controls/edit-form/EditFormTabStyle/EditFormTabStyle.vue';
import EditTabAnimation from '@/components/builder-controls/reusable-controls/element/EditTabAnimation.vue';

import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import { NPS_TYPE_FEATURE_FORM } from '@/constants/builderConstants';
import { useI18n } from 'vue-i18n';
import { useBuilderMode } from '@/use/useBuilderMode';

import { ref } from 'vue';

defineEmits(['close']);

const { t } = useI18n();
const { isAiBuilderMode } = useBuilderMode();
const tabs = [
	...(isAiBuilderMode.value ? [] : [
		{
			title: t('common.general'),
			component: 'EditFormTabGeneral',
		},
	]),
	{
		title: t('common.fields'),
		component: 'EditFormTabFields',
	},
	{
		title: t('common.button'),
		component: 'EditFormTabButton',
	},
	{
		title: t('common.style'),
		component: 'EditFormTabStyle',
	},
	{
		title: t('common.animation'),
		component: 'EditTabAnimation',
	},
];

const stylesTabToOpen = ref('');
const currentTab = ref(tabs[0]);

const openButtonStyles = () => {
	stylesTabToOpen.value = 'button';
	currentTab.value = tabs.find((tab) => tab.component === 'EditFormTabStyle');
};

const changeTab = (tab) => {
	stylesTabToOpen.value = '';
	currentTab.value = tab;
};
</script>
