<template>
	<p class="text-bold-2 styles-dropdown-label">
		{{ $t('builder.editForm.customizeElements') }}
	</p>
	<ZyroSelect
		v-qa="'form-styles-tab-select'"
		label-key="title"
		:filterable="false"
		:searchable="false"
		class="styles-dropdown"
		:options="styleOptions"
		:model-value="selectedStyleOption"
		@update:model-value="updateSelectedStyleOption"
	/>
	<EditFormButtonStyle v-if="selectedStyleOption.id === 'button'" />
	<EditFormFieldsStyle v-if="selectedStyleOption.id === 'formFields'" />
	<EditFormBackgroundStyle v-if="selectedStyleOption.id === 'background'" />
</template>

<script setup>
import ZyroSelect from '@/components/global/ZyroSelect.vue';

import EditFormButtonStyle from '@/components/builder-controls/edit-form/EditFormTabStyle/EditFormButtonStyle.vue';
import EditFormFieldsStyle from '@/components/builder-controls/edit-form/EditFormTabStyle/EditFormFieldsStyle.vue';
import EditFormBackgroundStyle from '@/components/builder-controls/edit-form/EditFormTabStyle/EditFormBackgroundStyle.vue';

import { useI18n } from 'vue-i18n';

import { ref } from 'vue';

const { t } = useI18n();

const props = defineProps({
	tabToOpen: {
		type: String,
		default: '',
	},
});

const styleOptions = [
	{
		id: 'button',
		title: t('common.submitButton'),
	},
	{
		id: 'formFields',
		title: t('common.formFields'),
	},
	{
		id: 'background',
		title: t('common.background'),
	},
];
const selectedStyleOption = ref(styleOptions[1]);

if (props.tabToOpen) {
	selectedStyleOption.value = styleOptions.find((option) => option.id === props.tabToOpen);
}

const updateSelectedStyleOption = (option) => {
	selectedStyleOption.value = option;
};
</script>
<style lang="scss" scoped>
.styles-dropdown-label {
	margin-bottom: 8px;
}

.styles-dropdown {
	margin-bottom: 16px;
}
</style>
