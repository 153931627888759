<template>
	<ContextMenu
		:is-enabled="!!selectedLayoutBlockId && isEnabled"
		:trigger-ref="triggerRef"
		@close-context-menu="handleCloseMenu"
	>
		<div class="block-context-menu">
			<div
				v-if="isMobileScreen"
				class="block-context-menu__heading"
			>
				<p class="text-bold-2">
					{{ $t('builder.editText.moreOptions') }}
				</p>
				<HostingerButton
					v-qa="'mobile-context-menu-btn-close'"
					button-type="plain"
					:title="$t('common.close')"
					@click="handleCloseMenu"
				>
					<template #icon>
						<Icon name="close" />
					</template>
				</HostingerButton>
			</div>
			<span v-if="!isMobileScreen || isMobileCopyPasteAllowed">
				<ContextMenuButton
					icon="place_item"
					:title="$t('builder.contextMenu.gridBlock.paste')"
					:disabled="!doesCopiedElementExist"
					@click="handlePasteElement($event)"
				/>
				<ContextMenuButton
					v-if="userStore.isJsonClipboardEnabled"
					icon="place_item"
					:title="$t('builder.contextMenu.gridBlock.pasteSectionFromJson')"
					@click="handlePasteBlockFromJson"
				/>
				<template v-if="!siteStore.isCurrentPageTypeLegacyEcommerceProduct">
					<ZyroSeparator />
					<ContextMenuButton
						icon="content_copy"
						:title="$t('builder.contextMenu.gridBlock.copySection')"
						:disabled="isFooter"
						@click="handleCopyBlock"
					/>
					<ContextMenuButton
						v-if="userStore.isJsonClipboardEnabled"
						icon="content_copy"
						:title="$t('builder.contextMenu.gridBlock.copySectionAsJson')"
						:disabled="isFooter"
						@click="handleCopyBlockJson"
					/>
					<ContextMenuButton
						icon="place_item"
						:title="$t('builder.contextMenu.gridBlock.pasteSection')"
						:disabled="isPasteBlockDisabled"
						@click="handlePasteBlock"
					/>
				</template>
			</span>
			<ContextMenuButton
				v-if="isMobileScreen"
				icon="delete_outline"
				:title="$t('common.delete')"
				@click="emit('delete-block')"
			/>
		</div>
	</ContextMenu>
</template>

<script setup>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ContextMenu from '@/components/context-menu/ContextMenu.vue';
import ContextMenuButton from '@/components/context-menu/ContextMenuButton.vue';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';
import { useContextMenu } from '@/components/context-menu/useContextMenu';
import { cloneBlock } from '@/utils/siteDataUtils';
import { useStore } from 'vuex';
import { useAddElement } from '@/use/useAddElement';
import cloneDeep from 'lodash.clonedeep';
import { generateRandomId } from '@/utils/generateRandomId';
import { useLayoutContextMenu } from '@/components/context-menu/useLayoutContextMenu';

import { NEW_ELEMENT_RELATIVE_POSITION } from '@/constants/builderConstants';
import {
	SYSTEM_LOCALE,
	BLOCK_TYPE_ECOMMERCE_PRODUCT,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

import { computed } from 'vue';

import { addBreadcrumb } from '@sentry/vue';
import { useSiteStore } from '@/stores/siteStore';
import { useUserStore } from '@/stores/userStore';
import { useClipboard } from '@/use/useClipboard';

const props = defineProps({
	isEnabled: {
		type: Boolean,
		default: true,
	},
	blockElements: {
		type: Array,
		required: true,
	},
	blockId: {
		type: String,
		required: true,
	},
	triggerRef: {
		type: Element,
		default: null,
	},
	isMobileCopyPasteAllowed: {
		type: Boolean,
		default: false,
	},
});
const emit = defineEmits([
	'close-context-menu',
	'delete-block',
]);

const {
	selectedLayoutBlockId,
	copiedLayoutBlockId,
	copiedLayoutBlockLocale,
	copiedElementId,
	copiedElementLocale,
	isElementCut,
} = useLayoutContextMenu();

const { closeContextMenu } = useContextMenu();

const {
	state,
	getters,
	dispatch,
} = useStore();
const { addLayoutElement } = useAddElement();
const {
	copyEntireBlockDataToClipboard,
	pasteEntireBlockDataFromClipboard,
} = useClipboard();

const siteStore = useSiteStore();
const userStore = useUserStore();

const website = computed(() => siteStore.site);
const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isCurrentPagePrivate = computed(() => getters.isCurrentPagePrivate);
const currentLocale = computed(() => state.currentLocale);
const currentPageId = computed(() => state.currentPageId);
const siteLanguages = computed(() => getters.siteLanguages);
const siteBlocks = computed(() => getters.siteBlocks);
const isMobileMode = computed(() => getters['gui/isMobileMode']);
const doesCopiedElementExist = computed(() => !!siteLanguages.value[copiedElementLocale.value]?.elements[copiedElementId.value]);
const isFooter = computed(() => siteBlocks.value[selectedLayoutBlockId.value]?.slot === 'footer');
const doesCopiedBlockExist = computed(() => Object.values(siteLanguages.value)
	.some((language) => language.blocks[copiedLayoutBlockId.value]));
const isPasteBlockDisabled = computed(() => {
	if (siteStore.isCurrentPageTypeLegacyEcommerceProduct) {
		return siteBlocks.value[copiedLayoutBlockId.value]?.type === BLOCK_TYPE_ECOMMERCE_PRODUCT;
	}

	return !doesCopiedBlockExist.value || isFooter.value;
});

const copyBlock = () => {
	copiedLayoutBlockId.value = selectedLayoutBlockId.value;
	copiedLayoutBlockLocale.value = isCurrentPagePrivate.value ? SYSTEM_LOCALE : currentLocale.value;
};

const pasteBlock = () => {
	const {
		newBlock,
		newElements,
		newBlocks,
	} = cloneBlock({
		siteData: website.value,
		blockId: copiedLayoutBlockId.value,
		fromLocale: copiedLayoutBlockLocale.value,
	});

	addBreadcrumb({
		category: 'CLICK:Pasting section',
		data: {
			blockType: newBlock.type,
			parentBlockId: selectedLayoutBlockId.value,
		},
	});

	dispatch('addBlock', {
		pageId: currentPageId.value,
		blockData: newBlock,
		blocks: newBlocks,
		elements: newElements,
		previousBlockId: selectedLayoutBlockId.value,
	});
};

const pasteElement = async (element) => {
	const copiedElementData = siteLanguages.value[copiedElementLocale.value]?.elements[copiedElementId.value];

	if (!copiedElementData) {
		return;
	}

	const newElementData = cloneDeep(copiedElementData);
	const blockToAddRef = document.querySelector(`[data-block-ref='${props.blockId}']`);
	const newElementRawPosition = {
		newElementRawLeft: element.clientX,
		newElementRawTop: element.clientY,
		newElementRawWidth: isMobileMode.value ? newElementData.mobile.width : newElementData.desktop.width,
		newElementRawHeight: isMobileMode.value ? newElementData.mobile.height : newElementData.desktop.height,
	};

	addBreadcrumb({
		category: isElementCut.value ? 'CLICK:Cut element' : 'CLICK:Paste element',
		data: {
			elementType: newElementData.type,
			parentElementId: copiedElementId.value,
		},
	});

	await addLayoutElement({
		blockElements: props.blockElements,
		blockId: props.blockId,
		blockToAddRef,
		elementId: generateRandomId(),
		newElementData,
		newElementRawPosition,
		newElementRelativePosition: NEW_ELEMENT_RELATIVE_POSITION.EXACT,
	});

	if (isElementCut.value) {
		dispatch('removeElement', {
			elementId: copiedElementId.value,
			locale: copiedElementLocale.value,
		});

		isElementCut.value = null;
		copiedElementId.value = null;
		copiedElementLocale.value = null;

		dispatch('undoRedo/createSnapshot');
	}
};

const handleCloseMenu = () => {
	selectedLayoutBlockId.value = null;

	if (props.triggerRef) {
		emit('close-context-menu');
	} else {
		closeContextMenu();
	}
};

const handleCopyBlock = () => {
	copyBlock();
	handleCloseMenu();
};

const handleCopyBlockJson = () => {
	copyEntireBlockDataToClipboard({
		blockId: selectedLayoutBlockId.value,
	});

	handleCloseMenu();
};

const handlePasteBlock = () => {
	pasteBlock();
	handleCloseMenu();
};

const handlePasteBlockFromJson = () => {
	pasteEntireBlockDataFromClipboard({
		selectedBlockId: selectedLayoutBlockId.value,
	});

	handleCloseMenu();
};

const handlePasteElement = (element) => {
	pasteElement(element);
	handleCloseMenu();
};
</script>
<style lang="scss" scoped>
.block-context-menu {
	&__heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px 16px;
		margin-bottom: 1px;
	}
}
</style>
