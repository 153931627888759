<template>
	<div class="zyro-field-toggle">
		<div class="zyro-field-toggle__group">
			<ZyroLabel
				v-qa="'toggle-label'"
				:for="props.id"
				class="zyro-field-toggle__label"
				:class="{ 'zyro-field-toggle__label--disabled': props.disabled }"
			>
				<slot name="icon-left" />

				{{ label }}
			</ZyroLabel>
			<ZyroToggle
				:id="props.id"
				v-qa="props.qaSelector"
				:model-value="props.modelValue"
				class="zyro-field-toggle__switch"
				:disabled="props.disabled"
				@update:model-value="$emit('update:model-value', $event)"
			/>
		</div>
		<div
			v-if="message"
			class="zyro-field-toggle__message"
		>
			{{ message }}
		</div>
	</div>
</template>

<script setup lang="ts">
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroToggle from '@/components/global/ZyroToggle.vue';

interface Props {
	id: string;
	label?: string;
	message?: string;
	modelValue: boolean;
	disabled?: boolean;
	qaSelector?: string;
}

const props = withDefaults(defineProps<Props>(), {
	label: '',
	message: '',
	disabled: false,
	qaSelector: 'toggle',
});

defineEmits<{
	'update:model-value': [boolean],
}>();

</script>

<style lang="scss" scoped>
.zyro-field-toggle {
	width: 100%;
	padding: 16px 0;

	&__group {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 24px;
	}

	&__message {
		margin-top: 8px;
		font-size: 13px;
		color: $color-gray;
	}

	&__label {
		line-height: 16px;
		cursor: pointer;

		&--disabled {
			color: $color-gray;
		}
	}

	&__switch {
		margin-left: auto;
	}
}
</style>
