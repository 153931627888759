import { computed } from 'vue';

import {
	ELEMENT_POSITION_KEY_MOBILE,
	ELEMENT_POSITION_KEY_DESKTOP,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

import { useStore } from 'vuex';
import {
	getLayoutElementPositionFromDOM,
	getLowerElementsRelativeToActive,
	getElementsBelowActiveElementPositions,
	getUpdatedElementsPosition,
} from '@/utils/layout';

import { fitToLayoutYBounds } from '@/utils/fitToLayoutYBounds';

import { useSectionResizing } from '@/use/useSectionResizing';

import {
	Element,
	ElementPositionKey,
	ElementPositions,
} from '@/types/elementTypes';

export const getUpdatedElementsPositionsRelativeToActiveElement = ({
	element,
	elementTopOffset,
	newElementPosition,
	layoutElements,
	elementPositionKey,
}: {
	element: Element;
	elementTopOffset: number;
	newElementPosition: ElementPositions;
	layoutElements: Array<Element>;
	elementPositionKey: ElementPositionKey;
}) => {
	const { elementId } = element;

	const lowerElements = getLowerElementsRelativeToActive({
		layoutElements,
		activeElementId: elementId,
		elementPositionKey,
	});

	const topOffset = element[elementPositionKey].top - fitToLayoutYBounds(newElementPosition).top;

	const elementsBelowActiveElementPositions = getElementsBelowActiveElementPositions({
		topOffset,
		elementPositionKey,
		lowerElementsRelativeToActive: lowerElements,
	});

	const elementsData = getUpdatedElementsPosition({
		elementsPositions: {
			...elementsBelowActiveElementPositions,
		},
		topOffset: elementTopOffset,
		elementPositionKey,
	});

	if (!elementId) {
		return {
			...elementsData,
		};
	}

	return {
		...elementsData,
		[elementId]: {
			[elementPositionKey]: newElementPosition,
		},
	};
};

export const getValidElementsForGrouping = ({
	blockElements,
	activeElement,
	elementPositionKey,
}: {
	blockElements: Array<Element>;
	activeElement: Element;
	elementPositionKey: ElementPositionKey;
}): Array<Element> => {
	const {
		left: activeElementLeft,
		top: activeElementTop,
		width: activeElementWidth,
		height: activeElementHeight,
	} = activeElement[elementPositionKey];
	const activeElementRight = activeElementLeft + activeElementWidth;
	const activeElementBottom = activeElementTop + activeElementHeight;

	return blockElements.filter((blockElement: Element) => {
		const {
			left: elementLeft,
			top: elementTop,
			width: elementWidth,
		} = blockElement[elementPositionKey];
		const elementRight = elementLeft + elementWidth;

		const isElementBelowActiveElement = elementTop >= activeElementBottom;

		const isElementRightIntercepted = activeElementLeft >= elementLeft && activeElementLeft < elementRight;
		const isElementRightAndLeftIntercepted = activeElementLeft <= elementLeft && activeElementRight >= elementRight;
		const isElementLeftIntercepted = activeElementLeft <= elementLeft && activeElementRight > elementLeft;

		const isElementInHorizontalColumn = isElementRightIntercepted
			|| isElementRightAndLeftIntercepted
			|| isElementLeftIntercepted;

		return (isElementBelowActiveElement && isElementInHorizontalColumn) || blockElement.elementId === activeElement.elementId;
	});
};

export const useLayoutElementsSpacing = ({ blockId }: {blockId: string}) => {
	const {
		getters,
		dispatch,
	} = useStore();
	const { saveBlockMinHeight } = useSectionResizing({
		blockId,
	});

	const siteBlocks = computed(() => getters.siteBlocks);
	const siteElements = computed(() => getters.siteElements);
	const block = computed(() => siteBlocks.value[blockId]);

	const blockElements = computed<Array<Element> | null>(() => {
		if (!block.value?.components) {
			return null;
		}

		return block.value.components.map((elementId: string) => ({
			...siteElements.value[elementId],
			elementId,
		}));
	});

	const isMobileMode = computed(() => getters['gui/isMobileMode']);
	const elementPositionKey = computed<ElementPositionKey>(() => (isMobileMode.value
		? ELEMENT_POSITION_KEY_MOBILE
		: ELEMENT_POSITION_KEY_DESKTOP));

	const updatePositionsForElementsBelow = ({ elementId } : {elementId: string}) => {
		if (!blockElements.value) {
			return;
		}

		const activeElement = {
			elementId,
			...siteElements.value[elementId],
		};

		const validElementsForGrouping = getValidElementsForGrouping({
			blockElements: blockElements.value,
			activeElement,
			elementPositionKey: elementPositionKey.value,
		});

		const { position: newActiveElementPosition } = getLayoutElementPositionFromDOM({
			elementId,
			blockId,
			isMobileMode: elementPositionKey.value === ELEMENT_POSITION_KEY_MOBILE,
		});

		const elementTopOffset = newActiveElementPosition.height - activeElement[elementPositionKey.value].height;

		const elementsData = getUpdatedElementsPositionsRelativeToActiveElement({
			element: activeElement,
			elementTopOffset,
			newElementPosition: newActiveElementPosition,
			layoutElements: validElementsForGrouping,
			elementPositionKey: elementPositionKey.value,
		});

		dispatch('mergeBulkElementsData', {
			elementsData,
		});

		const newBlockMinHeight = block.value[elementPositionKey.value].minHeight + elementTopOffset;

		saveBlockMinHeight({
			minHeight: newBlockMinHeight,
			saveToHistory: false,
		});
	};

	return {
		updatePositionsForElementsBelow,
	};
};
