<script setup lang="ts">
import Popup from '@/components/global/Popup.vue';
import Icon from '@/components/global/Icon.vue';

import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import EditTextButton from '@/components/builder-controls/edit-text/EditTextButton.vue';
import { SELECTOR_DATA_PORTAL_BUILDER_PREVIEW } from '@/constants/builderConstants';
import EditTextMoreAlignment from '@/components/builder-controls/edit-text/EditTextMoreAlignment.vue';
import EditTextMoreBackground from '@/components/builder-controls/edit-text/EditTextMoreBackground.vue';
import EditTextMoreTextGenerator from '@/components/builder-controls/edit-text/EditTextMoreTextGenerator.vue';
import EditTextMoreFeedback from '@/components/builder-controls/edit-text/EditTextMoreFeedback.vue';
import EditTextMoreLineHeight from '@/components/builder-controls/edit-text/EditTextMoreLineHeight.vue';
import EditTextMoreAnimation from '@/components/builder-controls/edit-text/EditTextMoreAnimation.vue';
import { useBuilderMode } from '@/use/useBuilderMode';

interface Props {
	isNonGridElement?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	isNonGridElement: false,
});

const { getters } = useStore();
const { isAiBuilderMode } = useBuilderMode();
const isOpen = ref(false);
const editTextMoreButton = ref<InstanceType<typeof EditTextButton> | null>(null);

const currentBlockType = computed(() => getters.currentBlockType);
const showEditTextJustifyOptions = computed(() => currentBlockType.value !== 'BlockLayout' && !props.isNonGridElement);

const closePopup = () => {
	isOpen.value = false;
};
</script>

<template>
	<div>
		<EditTextButton
			ref="editTextMoreButton"
			qa-selector="textsettings-more-button"
			is-rounded-right
			:is-active="isOpen"
			:tooltip-text="$t('builder.editText.moreOptions')"
			@click="isOpen = !isOpen"
		>
			<Icon
				name="more_vert"
				dimensions="20px"
			/>
		</EditTextButton>
		<Popup
			v-if="isOpen"
			:target-ref="editTextMoreButton?.$el"
			placement="bottom-start"
			auto-update
			:offset="4"
			:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
			@click-outside="closePopup"
		>
			<div class="more">
				<EditTextMoreLineHeight
					v-if="!isNonGridElement"
					class="border-bottom"
				/>
				<EditTextMoreBackground
					v-if="!isNonGridElement"
					class="border-bottom"
				/>
				<EditTextMoreAnimation />
				<EditTextMoreAlignment
					v-if="showEditTextJustifyOptions"
					class="border-bottom"
				/>
				<EditTextMoreTextGenerator
					v-if="!isAiBuilderMode && !isNonGridElement"
					class="border-bottom"
				/>
				<EditTextMoreFeedback v-if="!isAiBuilderMode && !isNonGridElement" />
			</div>
		</Popup>
	</div>
</template>

<style lang="scss" scoped>
.more {
	width: 252px;
	background: $color-light;
	border-radius: 5px;
	box-shadow: $box-shadow;
}

.border-bottom {
	border-bottom: 1px solid $color-gray-light;
}
</style>
