<template>
	<HostingerButton
		v-if="item.locale !== defaultLocale || siteLanguagesArray.length === 1"
		ref="multilanguageSettingsButtonRef"
		v-qa="`multilingual-button-settings-${item.locale}`"
		:title="$t('common.settings')"
		button-type="plain"
		@click.stop="isMultilanguagePopupOpen = !isMultilanguagePopupOpen"
	>
		<template #icon>
			<Icon
				name="settings"
				dimensions="20px"
			/>
		</template>
	</HostingerButton>

	<Popup
		v-if="isMultilanguagePopupOpen"
		:target-ref="multilanguageSettingsButtonRef.$el"
		placement="bottom-end"
		:offset="{
			x: 0,
			y: 12,
		}"
		@click-outside="isMultilanguagePopupOpen = false"
	>
		<div
			class="multilanguage-popup-content"
			@click.stop
		>
			<MultilingualPopupButtons
				:item="item"
				@toggle-language-settings="toggle"
			/>
		</div>
	</Popup>
</template>

<script setup>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Popup from '@/components/global/Popup.vue';

import { useStore } from 'vuex';

import MultilingualPopupButtons from '@/components/multilingual/MultilingualPopupButtons.vue';

import {
	ref,
	computed,
} from 'vue';

defineProps({
	item: {
		type: Object,
		required: true,
	},
});

const { getters } = useStore();

const defaultLocale = computed(() => getters.defaultLocale);
const siteLanguagesArray = computed(() => getters.siteLanguagesArray);

const isMultilanguagePopupOpen = ref(false);
const multilanguageSettingsButtonRef = ref(null);

</script>

<style lang="scss" scoped>
.multilanguage-popup-content {
	background: $color-light;
	border-radius: 8px;
	box-shadow: $box-shadow;
}
</style>
