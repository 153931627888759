<template>
	<div class="expandable-item">
		<button
			v-qa="`expandable-settings-${title}-tab`"
			class="expandable-item__button text-bold-2"
			@click="handleToggle"
		>
			<div class="expandable-item__button-content">
				<ZyroSvgDeprecated
					name="chevron"
					dimensions="10px"
					class="expandable-item__arrow"
					:direction="isOpen ? 'down' : 'right'"
				/>
				<p class="expandable-item__title">
					{{ title }}
				</p>
			</div>
		</button>
		<Transition name="slide-top-to-bottom">
			<div
				v-if="isOpen"
				class="expandable-item__content"
			>
				<slot />
			</div>
		</Transition>
	</div>
</template>

<script setup lang="ts">
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';

type Props = {
	title: string;
	shouldOpenAtLoad?: boolean;
	isOpen: boolean;
}

defineProps<Props>();

const emit = defineEmits<{
	'item-clicked': [],
}>();

const handleToggle = () => {
	emit('item-clicked');
};
</script>

<style lang="scss" scoped>
.expandable-item {
	$this: &;

	display: flex;
	flex-direction: column;

	&:not(:last-child) {
		#{$this}__button {
			border-bottom: 1px solid $color-gray-light;
		}
	}

	&__button {
		width: 100%;
		cursor: pointer;
	}

	&__button-content {
		display: flex;
		align-items: center;
		padding: 16px;
		margin: 0 8px;
	}

	&__arrow {
		margin-right: 12px;
	}

	&__content {
		padding: 24px;
		background-color: $color-gray-light;
	}

	&__title {
		text-align: left;
	}
}
</style>
