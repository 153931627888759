<template>
	<div class="feedback">
		<ZyroSvgDeprecated
			class="feedback__icon"
			name="smiley-face"
		/>
		<p class="feedback__text">
			<i18n-t
				tag="p"
				keypath="builder.editImage.tabSeo.NpsImage"
			>
				<p
					data-qa="feature-nps-modal-link"
					class="feedback__link z-link"
					@click="openNps"
				>
					{{ $t('builder.editImage.tabSeo.NpsImageRateThisFeature') }}
				</p>
			</i18n-t>
		</p>
	</div>
</template>

<script setup lang='ts'>
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';

import { NPS_TYPE_FEATURE_TEXT } from '@/constants/builderConstants';

import { useNpsStore } from '@/stores/npsStore';
import { useI18n } from 'vue-i18n';

const npsStore = useNpsStore();
const { t } = useI18n();

const openNps = () => {
	npsStore.setNpsData({
		newQuestion: `${t('builder.npsRateQuestion')} ${t('builder.npsRateText')}`,
		isVisible: true,
		formType: NPS_TYPE_FEATURE_TEXT,
	});
};
</script>

<style lang="scss" scoped>
.feedback {
	display: flex;
	align-items: center;
	padding: 16px;
	font-size: 13px;

	&__icon {
		flex-shrink: 0;
		margin-right: 4px;
	}

	&__link {
		color: $color-dark;
	}
}
</style>
