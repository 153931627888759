<script setup lang="ts">
import { ref } from 'vue';
import ZyroDrawer from '@/components/global/ZyroDrawer.vue';
import AiTextGeneratorForm from '@/components/builder-controls/ai-text-generator/AiTextGeneratorForm.vue';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import Icon from '@/components/global/Icon.vue';
import { useAiWriterDrawerStore } from '@/stores/aiWriterDrawerStore';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useSidebar } from '@/use/useSidebar';
import { NPS_TYPE_AI_WRITER } from '@/constants/builderConstants';
import { timeout } from '@/utils/helpers';
import EventLogApi from '@/api/EventLogApi';
import { storeToRefs } from 'pinia';

const COPY_ICON_RESET_TIME = 1500;

const { closeInnerSidebar } = useSidebar();
const aiWriterDrawerStore = useAiWriterDrawerStore();
const {
	userPrompt,
	isPromptInputTouched,
	isLoading,
	generatedText,
} = storeToRefs(aiWriterDrawerStore);
const {
	generateAiText,
	setUserPrompt,
} = aiWriterDrawerStore;

const isCopied = ref(false);

const copyToClipboard = async () => {
	navigator.clipboard.writeText(generatedText.value);
	isCopied.value = true;
	EventLogApi.logEvent({
		eventName: 'website_builder.ai_assist.copy_text',
	});

	await timeout(COPY_ICON_RESET_TIME);

	isCopied.value = false;
};
</script>
<template>
	<ZyroDrawer
		v-qa="'builder-sidebar-aiwriter'"
		class="ai-writer-drawer"
		:header-title="$t('builder.AIWriter.title')"
		:header-description="$t('builder.AIWriter.description')"
		@back="closeInnerSidebar"
	>
		<template #body>
			<div
				ref="aiWriterRef"
				class="ai-writer"
			>
				<AiTextGeneratorForm
					location="drawer"
					:user-prompt="userPrompt"
					:is-prompt-input-touched="isPromptInputTouched"
					:is-loading="isLoading"
					@generate="generateAiText"
					@update:user-prompt="setUserPrompt($event)"
				/>
				<Transition
					name="fade"
					mode="out-in"
				>
					<div
						v-if="generatedText"
						class="generated-text"
					>
						<div class="generated-text__header">
							<span class="text-bold-2">{{ $t('builder.generatedText') }}</span>
							<HostingerButton
								:title="$t('common.copy')"
								button-type="plain"
								class="generated-text__button"
								size="md"
								@click="copyToClipboard"
							>
								<template #icon-left>
									<Transition
										name="bounce"
										mode="out-in"
									>
										<Icon
											:key="isCopied ? 'copied' : 'copy'"
											:name="isCopied ? 'check' : 'content_copy'"
											dimensions="20px"
										/>
									</Transition>
								</template>
								<span>{{ $t('common.copy') }}</span>
							</HostingerButton>
						</div>
						<div class="generated-text__body">
							{{ generatedText }}
						</div>
					</div>
				</Transition>
			</div>
		</template>
		<template #footer>
			<NpsRateFeature
				class="ai-writer__nps"
				:type="NPS_TYPE_AI_WRITER"
				hide-border
			/>
		</template>
	</ZyroDrawer>
</template>

<style lang="scss" scoped>
.ai-writer-drawer {
	:deep(.drawer-body__content) {
		padding: 0;
	}

	:deep(.drawer-footer) {
		padding: 8px 16px;
	}
}

.ai-writer {
	padding: 16px;

	&__nps {
		:deep(.rate-feature__container) {
			padding: 0;
		}
	}
}

.generated-text {
	display: flex;
	flex-direction: column;
	padding-top: 16px;
	margin-top: 16px;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
	}

	&__button {
		color: $color-primary;
		border: none;
		padding: 0;

		:deep(.hostinger-button__text) {
			margin-left: 4px;
		}

		&:focus, &:hover {
			color: $color-primary;
			background-color: $color-light;
			border: none;
		}
	}
}

.bounce-enter-active {
  animation: bounce-in 0.3s;
}

.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}
</style>
