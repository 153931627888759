<script setup lang="ts">
import ZyroIconSelect from '@/components/global/ZyroIconSelect.vue';

import {
	computed,
	onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { ImageHoverEffect } from '@zyro-inc/site-modules/types';

const {
	getters,
	dispatch,
} = useStore();
const { t } = useI18n();

type Props = {
	blockId: string;
}

const props = defineProps<Props>();

const currentBlock = computed(() => getters.currentBlock);
const imageHoverEffect = computed(() => currentBlock.value.settings.imageHoverEffect || ImageHoverEffect.NO_EFFECT);

const IMAGE_HOVER_EFFECT_OPTIONS = {
	[ImageHoverEffect.NO_EFFECT]: {
		name: t('common.noEffect'),
		icon: 'hover-no-effect',
		iconDimensions: '40px',
	},
	[ImageHoverEffect.ZOOM]: {
		name: t('common.zoom'),
		icon: 'hover-zoom',
		iconDimensions: '40px',
	},
	[ImageHoverEffect.SWAP_IMAGE]: {
		name: t('common.swapImage'),
		icon: 'hover-swap-image',
		iconDimensions: '40px',
	},
} as const;

const updateImageHoverEffect = (newValue: ImageHoverEffect) => {
	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			settings: {
				imageHoverEffect: newValue,
			},
		},
		merge: true,
	});
};

onUnmounted(() => {
	dispatch('undoRedo/createSnapshot');
});
</script>

<template>
	<div class="image-hover-effect">
		<ZyroIconSelect
			:model-value="imageHoverEffect"
			:options="IMAGE_HOVER_EFFECT_OPTIONS"
			:items-per-row="3"
			options-grid-gap="8px"
			icon-container-padding="11px 19px"
			class="image-hover-effect__select"
			active-icon-color="var(--color-azure)"
			active-background-color="var(--color-azure-lighter)"
			@update:model-value="updateImageHoverEffect"
		/>
		<hr class="image-hover-effect__hr">
		<p class="image-hover-effect__note">
			{{ t('common.effectsApplyToDesktop') }}
		</p>
	</div>
</template>

<style lang="scss" scoped>
.image-hover-effect {
	--select-background: #{$color-light};

	&__select {
		color: $color-gray;
	}

	&__hr {
		margin: 16px auto;
		border: none;
		height: 1px;
		background-color: $color-gray-border;
	}

	&__note {
		color: $color-gray;
		font-size: 14px;
		line-height: 1.5rem;
	}

	.zyro-icon-select__icon-container {
		height: auto;
	}
}
</style>
