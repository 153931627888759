<template>
	<SiteElementSearchBar
		ref="elementRef"
		class="site-element-search-bar-provider-builder layout-element__component"
		:data-element-ref="props.elementId"
		:placeholder-text="props.data.placeholderText"
		:no-results-text="props.data.noResultsText"
		:fill-color="props.data.fillColor"
		:fill-color-hover="props.data.fillColorHover"
		:text-and-icon-color="props.data.textAndIconColor"
		:text-and-icon-color-hover="props.data.textAndIconColorHover"
		:font-family="props.data.fontFamily"
		:border-color="props.data.borderColor"
		:border-color-hover="props.data.borderColorHover"
		:border-radius="props.data.borderRadius"
		:result-item-hover-color="props.data.resultItemHoverColor"
		:translations="ecommerceTranslations"
		:site-id="siteId"
	/>
	<ElementEditControls
		v-if="!isPreviewMode"
		:target-ref="elementRef?.$el"
		:element-id="props.elementId"
		:is-element-active="props.isActive"
		:enter-edit-mode-button-title="$t('builder.elementEditPopupSearchBarEditButtonTitle')"
	>
		<template #edit-mode-popup>
			<ElementEditPopupSearchBar @close="closeElementEditPopupHandler" />
		</template>
	</ElementEditControls>
</template>

<script setup lang="ts">
import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import SiteElementSearchBar from '@zyro-inc/site-modules/components/SiteElementSearchBar.vue';
import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import ElementEditPopupSearchBar from '@/components/ElementEditPopupSearchBar.vue';
import { useElementEditPopup } from '@/use/useElementEditPopup';

interface Data {
	placeholderText: string
	noResultsText: string
	fillColor:string
	fillColorHover: string
	textAndIconColor: string
	textAndIconColorHover: string
	resultItemHoverColor: string
	fontFamily: string
	borderColor: string
	borderColorHover: string
	borderRadius: number
}
interface Props {
	data: Data,
	elementId: string,
	isActive: boolean,
	isPreviewMode?: boolean,
}

const props = defineProps<Props>();

const {
	state,
	getters,
} = useStore();

const siteId = computed(() => state.websiteId);
const ecommerceTranslations = computed(() => getters.ecommerceShoppingCart?.translations ?? {});

const elementRef = ref<InstanceType<typeof SiteElementSearchBar>>();
const { closeElementEditPopupHandler } = useElementEditPopup({
	elementId: props.elementId,
});
</script>

<style lang="scss" scoped>
.site-element-search-bar-provider-builder {
	pointer-events: none;
	user-select: none;
}
</style>
