<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

import ZyroDrawer from '@/components/global/ZyroDrawer.vue';
import Integrations from '@/components/site-settings/pages/integrations/Integrations.vue';

const {
	state,
	dispatch,
} = useStore();

const isMobileScreen = computed(() => state.gui.isMobileScreen);

const closeDrawer = () => {
	dispatch('gui/CLOSE_DRAWER');
};
</script>

<template>
	<ZyroDrawer
		:is-back-button-visible-on-mobile="false"
		is-close-button-visible
		:width="isMobileScreen ? '100%' : '75%'"
		class="settings-drawer"
		@close="closeDrawer"
	>
		<template #body>
			<Integrations />
		</template>
	</ZyroDrawer>
</template>

<style lang="scss" scoped>
.settings-drawer {
	:deep(.drawer) {
		background: $color-gray-light;
	}

	@media screen and (max-width: $media-mobile) {
		padding-top: $header-height-editor;
	}
}
</style>
