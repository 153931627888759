<template>
	<div>
		<ColorPicker
			:has-gradient="hasGradient"
			:current="current"
			:is-open="isOpen"
			:color="color"
			:gradient="gradient"
			:placement="placement"
			:flip="false"
			:portal-selector="portalSelector"
			:is-opacity-disabled="isOpacityDisabled"
			@update-color="emit('update-color', $event)"
			@update-gradient="emit('update-gradient', $event)"
			@toggle="emit('toggle')"
			@click-outside="emit('click-outside')"
		>
			<div class="color-picker-toggle">
				<p class="text-bold-2">
					{{ label }}
				</p>
				<ColorPickerButton
					:is-gradient="isGradientSelected"
					:color="color"
					:gradient="gradient"
				/>
			</div>
		</ColorPicker>

		<div
			v-if="isGradientSelected"
			class="gradient-animation-toggle"
			@click="emit('update-gradient', {
				...gradient,
				isAnimated: !gradient.isAnimated
			})"
		>
			<p class="text-bold-2">
				{{ $t('builder.animateGradient') }}
			</p>

			<ZyroToggle
				v-qa="'gradient-animation-toggle-button'"
				:model-value="gradient.isAnimated"
				class="zyro-field-toggle__switch"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import ColorPickerButton from '@/components/global/color-picker/ColorPickerButton.vue';
import ColorPicker from '@/components/global/color-picker/ColorPicker.vue';
import {
	DEFAULT_COLOR,
	SELECTOR_DATA_PORTAL_BUILDER_PREVIEW,
} from '@/constants/builderConstants';
import {
	SiteBackgroundGradient,
	SiteBackgroundType,
} from '@hostinger/builder-schema-validator';
import ZyroToggle from '@/components/global/ZyroToggle.vue';
import { computed } from 'vue';

interface Props {
	color?: string;
	gradient?: SiteBackgroundGradient;
	current?: SiteBackgroundType;
	hasGradient?: boolean;
	isOpen: boolean;
	label?: string;
	placement?: string;
	portalSelector?: string;
	isOpacityDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	color: DEFAULT_COLOR,
	gradient: () => ({
		angle: 0,
		isAnimated: false,
		colors: [
			{
				value: DEFAULT_COLOR,
			},
			{
				value: DEFAULT_COLOR,
			},
		],
	}),
	placement: 'right-start',
	portalSelector: SELECTOR_DATA_PORTAL_BUILDER_PREVIEW,
	isOpacityDisabled: false,
});

const emit = defineEmits<{
	'toggle': [],
	'click-outside': [],
	'update-color': [color: string],
	'update-gradient': [gradient: SiteBackgroundGradient],
}>();

const isGradientSelected = computed(() => props.current === 'gradient');
</script>

<style lang="scss" scoped>
.gradient-animation-toggle {
	margin-top: 16px;
}

.color-picker-toggle, .gradient-animation-toggle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
}
</style>
