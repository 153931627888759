<template>
	<ZyroModal
		:title="$t('common.blogSettings')"
		width="360px"
		height="auto"
		no-overflow
		class="settings-modal"
		@close-modal="closeModal"
	>
		<ZyroTabs
			:tabs="tabs"
			:current-tab="currentTab"
			@update:current-tab="currentTab = $event"
		/>
		<BlogSettingsTabCategories v-if="currentTab.component = 'BlogSettingsTabCategories'" />
	</ZyroModal>
</template>

<script setup>

import { ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import ZyroModal from '@/components/global/ZyroModal.vue';
import ZyroTabs from '@/components/global/ZyroTabs.vue';

import BlogSettingsTabCategories from '@/components/builder-controls/blog-drawer/BlogSettingsTabCategories.vue';

const { t } = useI18n();
const { dispatch } = useStore();
const tabs = [
	{
		id: 'categories',
		title: t('common.categories'),
		component: 'BlogSettingsTabCategories',
	},
];
const currentTab = ref(tabs[0]);

const closeModal = () => {
	dispatch('gui/CLOSE_MODAL');
};
</script>

<style lang="scss" scoped>
:deep(.settings-modal) {
	.modal {
		&__content {
			min-height: 450px;
		}
	}
}
</style>
