<template>
	<Popup
		:target-ref="props.targetRef"
		placement="bottom"
		auto-update
		@click-outside="emit('click-outside')"
	>
		<div
			class="publish-popup-content"
			:style="{ '--content-width': popupWidthInPx } "
		>
			<p class="publish-popup-content__title text-bold-2">
				<slot name="title" />
			</p>

			<p
				v-if="$slots.subtitle"
				class="publish-popup-content__subtitle text-body-2"
			>
				<slot name="subtitle" />
			</p>

			<slot name="content" />

			<ZyroSeparator />

			<div class="publish-popup-content__footer">
				<slot name="footer" />
			</div>
		</div>
	</Popup>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Popup from '@/components/global/Popup.vue';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';

type Props = {
	targetRef: HTMLElement | null;
	width?: number
};

const props = withDefaults(defineProps<Props>(), {
	width: 280,
});

const emit = defineEmits<{
	'click-outside': [];
}>();

const popupWidthInPx = computed(() => `${props.width}px`);

</script>

<style lang="scss" scoped>
.publish-popup-content {
	display: flex;
	flex-direction: column;
	width: var(--content-width);
	padding: 16px;
	background: $color-light;
	border: 1px solid $color-gray-light;
	border-radius: $border-radius-medium;
	box-shadow: 0 2px 14px rgba(0, 0, 0, 10%);

	&__title {
		margin-bottom: 8px;
	}

	&__subtitle {
		margin-bottom: 8px;
		color: $color-gray;
	}

	&__footer {
		display: flex;
		padding-top: 16px;
	}
}
</style>
