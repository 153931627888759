<script lang="ts" setup>
import { useStore } from 'vuex';
import ZyroModal from '@/components/global/ZyroModal.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import { computed } from 'vue';
import {
	CLOSE_MODAL,
	GUI_NAMESPACE,
} from '@/store/builder/gui';
import { DRAWER_SEO } from '@/constants/builderConstants';
import { useI18n } from 'vue-i18n';

interface Props {
	settings: Record<string, any>;
}

const props = withDefaults(defineProps<Props>(), {
	settings: () => ({}),
});

const {
	dispatch,
	state,
} = useStore();
const { t } = useI18n();
const isSiteBeingPublished = computed(() => state.gui.isSiteBeingPublished);
const closeModal = () => dispatch(`${GUI_NAMESPACE}/${CLOSE_MODAL}`);

const handleChangeClick = () => {
	dispatch('gui/OPEN_DRAWER', {
		id: DRAWER_SEO,
		settings: {
			pageId: props.settings.pageId,
			isCheckoutSlugError: true,
		},
	});

	closeModal();
};

</script>

<template>
	<Teleport to="body">
		<ZyroModal
			is-for-payments
			max-width="400px"
			class="checkout-slug-error-modal"
			height="auto"
			:show-close-button="false"
			:content-padding="'24px'"
		>
			<Icon
				name="warning"
				is-filled
				size="24px"
				class="checkout-slug-error-modal__icon"
			/>

			<div class="checkout-slug-error-modal__content">
				<h2 class="text-heading-2">
					{{ isSiteBeingPublished ? t('builder.siteCannotBePublished') : t('builder.siteCannotBeUpdated') }}
				</h2>
				<i18n-t
					tag="p"
					class="checkout-slug-error-modal__content-description text-body-2"
					keypath="builder.checkoutSlugIsReservedDescription"
				>
					<span>
						{{ isSiteBeingPublished ? t("builder.changeToPublishWebsite") : t('builder.changeToUpdateWebsite') }}
					</span>
				</i18n-t>
			</div>

			<div class="checkout-slug-error-modal__action-buttons">
				<HostingerButton @click="handleChangeClick">
					{{ t('builder.changeUrl') }}
				</HostingerButton>
			</div>
		</ZyroModal>
	</Teleport>
</template>

<style lang="scss" scoped>
.checkout-slug-error-modal {
	z-index: $z-index-popup;

	:deep() {
		.modal__content {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	&__icon {
		color: $color-warning-dark;
	}

	&__content {
		text-align: center;
		margin: 24px 0;
	}

	&__content-description {
		margin-top: 8px;
	}

	&__action-buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		:deep() {
			.hostinger-button {
				width: 100%;
			}
		}
	}}
</style>
