<template>
	<div
		ref="blogHeaderRef"
		class="blog-header-container"
	>
		<BlockBlogHeader
			:data="data"
			:class="customAnimationClass"
			:current-blog-page="currentPage"
			:blog-categories="blogCategories"
			:is-first-block="isFirstBlock"
			:blog-reading-time-text="blogReadingTimeText"
			:[DATA_ATTRIBUTE_ANIMATION_STATE]="isPreviewMode ? previewAnimationState : animationAttributeStateValue"
		/>
	</div>

	<template v-if="!isPreviewMode">
		<BlockControls
			:block-id="blockId"
			:target-ref="blogHeaderRef"
			:is-first-block="isFirstBlock"
			:section-title="$t('common.postHeader')"
			:transparent-header-height="transparentHeaderHeight"
		/>
		<BlockEditControls
			:block-id="blockId"
			:target-ref="blogHeaderRef"
			is-visibility-button-visible
			:is-context-menu-button-visible="false"
			:enter-edit-mode-button-title="t('builder.editBlockButton.editBlogHeader')"
		>
			<template #additional-edit-buttons>
				<VerticalSeparator />
				<div class="blog-header-controls">
					<ControlsTooltip :title=" $t('builder.editBlockButton.changePostTitle')">
						<HostingerButton
							v-qa="'builder-section-btn-changeposttitle'"
							button-type="plain"
							theme="highlight"
							@click="openBlogPostSettings"
						>
							<template #icon>
								<Icon name="mode_edit" />
							</template>
						</HostingerButton>
					</ControlsTooltip>
				</div>
			</template>
			<template #edit-mode-popup>
				<EditBlockBlogHeaderTabs @close="closeBlockEditPopupHandler" />
			</template>
		</BlockEditControls>
	</template>
</template>

<script setup>
import {
	computed,
	onMounted,
	ref,
} from 'vue';

import { useStore } from 'vuex';

import { useI18n } from 'vue-i18n';

import VerticalSeparator from '@/components/global/VerticalSeparator.vue';
import Icon from '@/components/global/Icon.vue';
import BlockEditControls from '@/components/builder-controls/BlockEditControls.vue';
import BlockControls from '@/components/block/BlockControls.vue';
import BlockBlogHeader from '@zyro-inc/site-modules/components/blocks/blog/BlockBlogHeader.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import EditBlockBlogHeaderTabs from '@/components/builder-controls/edit-block-blog-header/EditBlockBlogHeaderTabs.vue';

import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';

import {
	DATA_ATTRIBUTE_ANIMATION_STATE,
	DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

import {
	GUI_NAMESPACE,
	OPEN_MODAL,
} from '@/store/builder/gui';

import { MODAL_BLOG_POST_SETTINGS } from '@/constants/builderConstants';
import { useBlogStore } from '@/stores/blogStore';

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		default: () => ({}),
	},
	isFirstBlock: {
		type: Boolean,
		default: false,
	},
	transparentHeaderHeight: {
		type: Number,
		default: 0,
	},
	isPreviewMode: {
		type: Boolean,
		required: false,
	},
});

const {
	state,
	getters,
	dispatch,
} = useStore();

const { t } = useI18n();
const blogStore = useBlogStore();

const previewAnimationState = ref('');

const {
	customAnimationClass,
	animationAttributeStateValue,
} = useSiteEngineAnimations({
	data: props.data,
});

const blogHeaderRef = ref(null);

const currentPage = computed(() => getters.currentPage);
const currentPageId = computed(() => state.currentPageId);
const blogCategories = computed(() => blogStore.blogCategories);
const blogReadingTimeText = computed(() => blogStore.blogReadingTimeText);

const openBlogPostSettings = () => {
	dispatch(`${GUI_NAMESPACE}/${OPEN_MODAL}`, {
		name: MODAL_BLOG_POST_SETTINGS,
		settings: {
			blogPostId: currentPageId.value,
		},
	});
};

const closeBlockEditPopupHandler = () => {
	dispatch('leaveBlockEditMode');
};

onMounted(() => {
	if (!props.isPreviewMode) {
		return;
	}

	setTimeout(() => {
		previewAnimationState.value = DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE;
	}, 100);
});
</script>

<style lang="scss" scoped>
.blog-header-container {
	display: flex;
}

.blog-header-controls {
	display: flex;
	flex-grow: 1;
}
</style>
