<template>
	<ControlsTooltip
		class="placement-switch-top"
		:title="$t('builder.editBlockButton.moveUp')"
	>
		<HostingerButton
			v-qa="'elementplaceswitch-btn-up'"
			button-type="plain"
			theme="highlight"
			:disabled="!isSwitchToTopEnabled"
			@click="handleElementPositionSwitch({
				higherGroup: elementGroups[selectedElementGroupIndex - 1],
				lowerGroup: selectedElementGroup,
			})"
		>
			<template #icon>
				<Icon name="arrow_upward" />
			</template>
		</HostingerButton>
	</ControlsTooltip>
	<ControlsTooltip
		class="placement-switch-bottom"
		:title="$t('builder.editBlockButton.moveDown')"
	>
		<HostingerButton
			v-qa="'elementplaceswitch-btn-down'"
			button-type="plain"
			theme="highlight"
			:disabled="!isSwitchToBottomEnabled"
			@click="handleElementPositionSwitch({
				higherGroup: selectedElementGroup,
				lowerGroup: elementGroups[selectedElementGroupIndex + 1],
			})"
		>
			<template #icon>
				<Icon name="arrow_downward" />
			</template>
		</HostingerButton>
	</ControlsTooltip>
</template>

<script setup>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useLayoutElements } from '@/use/useLayoutElements';
import ControlsTooltip from '@/components/ControlsTooltip.vue';

const props = defineProps({
	elementId: {
		type: String,
		required: true,
	},
});
const { state } = useStore();
const builderPreviewContainerRef = computed(() => state.gui.builderPreviewContainerRef);
const {
	elementGroups,
	currentElementPositionKey,
	switchElementGroupsPositions,
	selectedElement,
	selectedElementGroupIndex,
	selectedElementGroup,
} = useLayoutElements({
	selectedElementId: props.elementId,
});

const isSelectedElementGroupFirst = computed(() => selectedElementGroupIndex.value === 0);
const isSelectedElementGroupLast = computed(() => selectedElementGroupIndex.value === (elementGroups.value?.length || 0) - 1);
const isSwitchToTopEnabled = computed(() => !isSelectedElementGroupFirst.value);
const isSwitchToBottomEnabled = computed(() => !isSelectedElementGroupLast.value);

const handleElementPositionSwitch = ({
	higherGroup,
	lowerGroup,
}) => {
	const focusedElementTopBeforeSwitch = selectedElement.value[currentElementPositionKey.value].top;

	switchElementGroupsPositions({
		higherGroup,
		lowerGroup,
	});

	const scrollByAmount = selectedElement.value[currentElementPositionKey.value].top - focusedElementTopBeforeSwitch;

	builderPreviewContainerRef.value.scrollBy({
		top: scrollByAmount,
		behavior: 'smooth',
	});
};
</script>
<style lang="scss" scoped>

@media screen and (min-width: $media-mobile) {
	.placement-switch-top {
		margin-bottom: 8px;
	}
}

.placement-switch-top,
.placement-switch-bottom {
	background-color: $color-light;
	border-radius: 8px;
}
</style>
