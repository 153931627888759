import {
	computed,
	ref,
} from 'vue';

import { generateImages as generateAiImages } from '@/api/AiApi';
import { useStore } from 'vuex';
import {
	AI_IMAGE_GENERATE_DEFAULT_COUNT,
	GAMIFICATION_TASK_UPDATE_IMAGE,
	AI_GENERATED_IMAGE_SIZE,
	NEW_ELEMENT_TOP_OFFSET,
} from '@/constants/builderConstants';
import { BLOCK_TYPE_LAYOUT } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { captureException } from '@sentry/vue';
import { useNotifications } from '@/use/useNotifications';
import { useGamification } from '@/use/useGamification';
import { useAssets } from '@/use/useAssets';
import { useI18n } from 'vue-i18n';
import { AxiosError } from 'axios';
import { useAddElement } from '@/use/useAddElement';

import { generateRandomId } from '@/utils/generateRandomId';

import { useUserCredits } from '@/use/useUserCredits';
import { useHoveredBlock } from '@/use/useHoveredBlock';
import { useSidebar } from '@/use/useSidebar';
import { useBlockLayout } from '@zyro-inc/site-modules/components/blocks/layout/useBlockLayout';
import { getImageBlock } from '@/components/block/blocks';

export type StylePresetType = 'enhance' | 'anime' | 'digital-art' | 'comic-book' | 'fantasy-art' | 'analog-film' | 'neon-punk' | 'isometric' | 'low-poly' | 'origami' | 'line-art' | 'modeling-compound' | 'cinematic' | '3d-model' | 'pixel-art';
export type AspectRatioType = 'square' | 'landscape';
export type AiGeneratedImageElementDataSettingsType = {
	path: string | null;
	origin: string;
}
export type AiGeneratedImageElementDataType = {
	fullResolutionWidth: number;
	fullResolutionHeight: number;
	settings: AiGeneratedImageElementDataSettingsType;
};

type GenerateImageType = {
	description: string;
	stylePreset: StylePresetType;
	shouldAddImageElement?: boolean;
	imageCount?: number;
}

type UploadedAssetType = {
	name: string;
	path: string;
	url: string;
	size: number;
	lastModified: string;
	context: string;
};

const IMAGE_SQUARE_ASPECT_RATIO = 'square';
const IMAGE_SQUARE_WIDTH = 1024;
const IMAGE_SQUARE_HEIGHT = 1024;
const IMAGE_LANDSCAPE_ASPECT_RATIO = 'landscape';
const IMAGE_LANDSCAPE_WIDTH = 1344;
const IMAGE_LANDSCAPE_HEIGHT = 768;

export const isAiImageGeneratorOpen = ref(false);

export const useAiImageGenerator = () => {
	const { decrementUserCredits } = useUserCredits();
	const { completeAchievement } = useGamification();
	const { notify } = useNotifications();
	const { t } = useI18n();
	const { upsertAsset } = useAssets();
	const { closeSidebar } = useSidebar();
	const {
		state,
		dispatch,
		getters,
	} = useStore();
	const {
		defaultElements,
		addLayoutElement,
	} = useAddElement();

	const { hoveredBlockId } = useHoveredBlock();

	const siteElements = computed(() => getters.siteElements);
	const siteBlocks = computed(() => getters.siteBlocks);
	const isMobileMode = computed(() => getters['gui/isMobileMode']);
	const currentBlockId = computed(() => state.currentBlockId || hoveredBlockId.value);
	const currentBlock = computed(() => siteBlocks.value[currentBlockId.value]);

	// @ts-ignore
	const { blockElements } = useBlockLayout({
		blockData: currentBlock,
		siteElements,
		shouldBuildResponsive: false,
	} as any);

	const aiImageGenerateGuildArticleLink = 'https://support.hostinger.com/en/articles/8670801-website-builder-guidelines-for-ai-image-generation';

	const generatedImageSrcList = ref<string[]>([]);
	const isGeneratingImages = ref(false);

	const IMAGE_GENERATOR_STYLES = [
		// TODO: enable once we fix the dreamstudio generation
		// {
		// name: t('builder.aiImageGenerationStylePhotographic'),
		// file: IMAGE_STYLE_PHOTOGRAPHIC,
		// value: 'photographic',
		// },
		{
			name: t('builder.aiImageGenerationStyleEnhance'),
			file: '/images/image-generator-styles/enhance.png',
			value: 'enhance',
		},
		{
			name: t('builder.aiImageGenerationStyleAnime'),
			file: '/images/image-generator-styles/anime.png',
			value: 'anime',
		},
		{
			name: t('builder.aiImageGenerationStyleDigitalArt'),
			file: '/images/image-generator-styles/digital-art.png',
			value: 'digital-art',
		},
		{
			name: t('builder.aiImageGenerationStyleComicBook'),
			file: '/images/image-generator-styles/comic-book.png',
			value: 'comic-book',
		},
		{
			name: t('builder.aiImageGenerationStyleFantasyArt'),
			file: '/images/image-generator-styles/fantasy-art.png',
			value: 'fantasy-art',
		},
		{
			name: t('builder.aiImageGenerationStyleAnalogFilm'),
			file: '/images/image-generator-styles/analog-film.png',
			value: 'analog-film',
		},
		{
			name: t('builder.aiImageGenerationStyleNeonPunk'),
			file: '/images/image-generator-styles/neon-punk.png',
			value: 'neon-punk',
		},
		{
			name: t('builder.aiImageGenerationStyleIsometric'),
			file: '/images/image-generator-styles/isometric.png',
			value: 'isometric',
		},
		{
			name: t('builder.aiImageGenerationStyleLowPoly'),
			file: '/images/image-generator-styles/low-poly.png',
			value: 'low-poly',
		},
		{
			name: t('builder.aiImageGenerationStyleOrigami'),
			file: '/images/image-generator-styles/origami.png',
			value: 'origami',
		},
		{
			name: t('builder.aiImageGenerationStyleLineArt'),
			file: '/images/image-generator-styles/line-art.png',
			value: 'line-art',
		},
		{
			name: t('builder.aiImageGenerationStyleCraftClay'),
			file: '/images/image-generator-styles/craft-clay.png',
			value: 'modeling-compound',
		},
		{
			name: t('builder.aiImageGenerationStyleCinematic'),
			file: '/images/image-generator-styles/cinematic.png',
			value: 'cinematic',
		},
		{
			name: t('builder.aiImageGenerationStyle3DModel'),
			file: '/images/image-generator-styles/3d-model.png',
			value: '3d-model',
		},
		{
			name: t('builder.aiImageGenerationStylePixelArt'),
			file: '/images/image-generator-styles/pixel-art.png',
			value: 'pixel-art',
		},
	];

	const imageAspectRatio = ref<AspectRatioType>(IMAGE_SQUARE_ASPECT_RATIO);

	const resetImageGenerator = () => {
		generatedImageSrcList.value = [];
		isGeneratingImages.value = false;
	};

	const generateImages = async ({
		description,
		stylePreset,
		shouldAddImageElement = false,
		imageCount = AI_IMAGE_GENERATE_DEFAULT_COUNT,
	}: GenerateImageType) => {
		isGeneratingImages.value = true;
		imageAspectRatio.value = state.currentElementId || shouldAddImageElement ? IMAGE_SQUARE_ASPECT_RATIO : IMAGE_LANDSCAPE_ASPECT_RATIO;

		const width = imageAspectRatio.value === IMAGE_SQUARE_ASPECT_RATIO ? IMAGE_SQUARE_WIDTH : IMAGE_LANDSCAPE_WIDTH;
		const height = imageAspectRatio.value === IMAGE_SQUARE_ASPECT_RATIO ? IMAGE_SQUARE_HEIGHT : IMAGE_LANDSCAPE_HEIGHT;

		const invalidDescriptionNotification = () => notify({
			headingI18nKeyPath: 'builder.invalidAiImageDescription',
			messageI18nKeyPath: 'builder.aiImageDescriptionIncludesBadWords',
			isDiscardButtonShown: false,
			submitLabelI18nKeyPath: 'common.learnMore',
			submitCallback: () => {
				window.open(aiImageGenerateGuildArticleLink, '_blank');
			},
		});

		// Generate multiple images here
		try {
			const { data } = await generateAiImages({
				description,
				stylePreset,
				width,
				height,
				siteId: state.websiteId,
				count: imageCount,
			});

			if (!data.images.length || data.error) {
				invalidDescriptionNotification();
				resetImageGenerator();

				return;
			}

			const generatedImages = data.images;

			generatedImages.forEach((generatedImage: UploadedAssetType) => {
				const assetPath = `/${generatedImage.name}`;

				dispatch('assets/setAssets', [
					...state.assets.assets,
					{
						...generatedImage,
						name: generatedImage.name,
						path: assetPath,
					},
				]);

				upsertAsset(null, {
					...generatedImage,
					url: decodeURI(generatedImage.url),
					isFetchedFromServer: true,
					name: generatedImage.name,
					path: assetPath,
				});

				generatedImageSrcList.value.push(generatedImage.url);
			});

			dispatch('assets/addAssetsFolderData', {
				assets: state.assets.assets,
			});

			decrementUserCredits({
				generateImageCount: data.images.length,
			});
		} catch (error) {
			console.error(error);
			isGeneratingImages.value = false;

			if ((error as AxiosError).response?.status === 451) {
				invalidDescriptionNotification();

				return;
			}

			if ((error as AxiosError).response?.status === 429) {
				notify({
					message: t('builder.aiImageGenerationDailyLimit'),
				});

				return;
			}

			notify({
				message: t('builder.aiImageGenerationFailedGeneration'),
			});

			captureException(error);
		}

		isGeneratingImages.value = false;
	};

	const getAiGeneratedElementData = (imageSrc: string): AiGeneratedImageElementDataType => {
		const { origin } = new URL(imageSrc);

		return {
			fullResolutionWidth: imageAspectRatio.value === IMAGE_SQUARE_ASPECT_RATIO ? IMAGE_SQUARE_WIDTH : IMAGE_LANDSCAPE_WIDTH,
			fullResolutionHeight: imageAspectRatio.value === IMAGE_SQUARE_ASPECT_RATIO ? IMAGE_SQUARE_HEIGHT : IMAGE_LANDSCAPE_HEIGHT,
			settings: {
				path: imageSrc,
				origin,
			},
		};
	};

	const createImageElement = async (imageSrc: string) => {
		const defaultImageElement = defaultElements.value.image;

		const aiGeneratedElementData = getAiGeneratedElementData(imageSrc);
		const newElementData = {
			...defaultImageElement.content,
			...aiGeneratedElementData,
			settings: {
				...defaultImageElement.content.settings,
				...aiGeneratedElementData.settings,
				styles: {
					...(defaultImageElement.content.settings?.styles || {}),
				},
			},
		};

		const currentBlockSelected = document.querySelector(`[data-block-ref='${currentBlockId.value}'`) as Element;

		const newElementRawPosition = {
			newElementRawTop: NEW_ELEMENT_TOP_OFFSET,
			newElementRawWidth: isMobileMode.value ? AI_GENERATED_IMAGE_SIZE.MOBILE : AI_GENERATED_IMAGE_SIZE.DESKTOP,
			newElementRawHeight: isMobileMode.value ? AI_GENERATED_IMAGE_SIZE.MOBILE : AI_GENERATED_IMAGE_SIZE.DESKTOP,
		};

		await addLayoutElement({
			blockElements: blockElements.value,
			blockId: currentBlockId.value,
			blockToAddRef: currentBlockSelected,
			elementId: generateRandomId(),
			newElementData,
			newElementRawPosition,
		});
	};

	const updateElementImage = (imageSrc: string) => {
		dispatch('mergeCurrentElementData', {
			elementData: getAiGeneratedElementData(imageSrc),
		});

		dispatch('undoRedo/createSnapshot');

		completeAchievement(GAMIFICATION_TASK_UPDATE_IMAGE);
	};

	const updateBlockImage = (imageSrc: string) => {
		const { origin } = new URL(imageSrc);

		const generatedImageData = {
			image: imageSrc,
			origin,
			path: imageSrc,
			current: 'image',
			color: null,
			alt: null,
		};

		dispatch('updateBlockData', {
			blockId: state.currentBlockId,
			blockData: {
				background: generatedImageData,
			},
			merge: true,
		});

		dispatch('undoRedo/createSnapshot');

		completeAchievement(GAMIFICATION_TASK_UPDATE_IMAGE);
	};

	const updateImage = async ({
		shouldAddImageElement = false,
		imageSrc,
	}: {
		shouldAddImageElement?: boolean;
		imageSrc: string;
	}) => {
		if (!imageSrc) {
			return;
		}

		if (shouldAddImageElement) {
			if (!currentBlock.value) {
				notify({
					messageI18nKeyPath: 'builder.aiImageCannotBeAddedToCurrentSectionError',
				});

				closeSidebar();

				return;
			}

			if (currentBlock.value.type === BLOCK_TYPE_LAYOUT) {
				createImageElement(imageSrc);

				closeSidebar();

				return;
			}

			const imageBlock = getImageBlock({
				imageData: getAiGeneratedElementData(imageSrc),
			});

			dispatch('addBlock', {
				pageId: state.currentPageId,
				blockData: imageBlock.blockData,
				elements: imageBlock.elements,
				previousBlockId: currentBlockId.value,
			});
			closeSidebar();

			return;
		}

		if (!state.currentElementId && !state.currentBlockId) {
			return;
		}

		if (state.currentElementId) {
			updateElementImage(imageSrc);

			return;
		}

		if (state.currentBlockId) {
			updateBlockImage(imageSrc);
		}
	};

	return {
		isGeneratingImages,
		generateImages,
		updateImage,
		generatedImageSrcList,
		IMAGE_GENERATOR_STYLES,
		resetImageGenerator,
		aiImageGenerateGuildArticleLink,
	};
};
