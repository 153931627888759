<template>
	<div class="edit-image-tab-styles">
		<div v-if="showImageFitOptions">
			<ZyroLabel class="edit-image-tab-styles__image-fit-label">
				{{ $t('builder.editImage.tabImage.objectFit') }}
			</ZyroLabel>
			<ZyroRadioList
				:model-value="objectFit"
				:options="FIT_OPTIONS"
				@update:model-value="updateObjectFit"
			/>
		</div>
		<template v-if="isImageInLayoutBlock">
			<HostingerButton
				button-type="outlined"
				class="reset-aspect-ratio"
				@click="resetProportions"
			>
				<template #icon-left>
					<Icon
						name="reset_image"
						dimensions="24px"
					/>
				</template>
				{{ $t('builder.editImage.tabImage.resetProportions') }}
			</HostingerButton>
			<ZyroSeparator />
		</template>
		<template v-if="!isImageMaskUsed">
			<ZyroLabel class="edit-image-tab-styles__border-radius-units-label">
				{{ $t('common.cornerRadiusUnits') }}
			</ZyroLabel>
			<ZyroIconControls
				:model-value="currentBorderRadiusValueMode"
				class="edit-image-tab-styles__mode-toggle"
				:icons="toggleModeValues"
				:toggleable="false"
				@update:model-value="currentBorderRadiusValueMode = $event"
			/>
			<ZyroSeparator />
			<ZyroFieldRange
				v-if="currentBorderRadiusValueMode === BORDER_RADIUS_VALUE_MODE_PERCENT"
				:model-value="borderRadiusInPercent * 2"
				min="0"
				max="100"
				step="1"
				units="%"
				:label="$t('common.cornerRadius')"
				@update:model-value="updateBorderRadiusInPercent"
			/>
			<ZyroFieldRange
				v-else
				:model-value="borderRadiusInNumber"
				min="0"
				:max="pixelBorderRadiusUpperLimit"
				units="px"
				:label="$t('common.cornerRadius')"
				@update:model-value="updatePixelBorderRadius"
			/>
		</template>
		<ZyroFieldRange
			:model-value="overlayOpacityInPercent"
			min="0"
			max="100"
			step="1"
			units="%"
			:label="$t('common.overlayOpacity')"
			@update:model-value="updateOverlayOpacityInPercent"
		/>
		<p
			v-if="isImageMaskUsed"
			class="edit-image-tab-styles__border-radius-disclaimer"
		>
			{{ $t('builder.editImage.tabStyles.borderRadiusDisclaimer') }}
		</p>
	</div>
</template>

<script setup>
import Icon from '@/components/global/Icon.vue';
import ZyroFieldRange from '@/components/global/ZyroFieldRange.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroRadioList from '@/components/global/ZyroRadioList.vue';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';

import { useI18n } from 'vue-i18n';
import { useLayoutHelpers } from '@/use/useLayoutHelpers';
import { useCurrentGridImage } from '@/use/useCurrentGridImage';
import { useStore } from 'vuex';
import {
	computed,
	onMounted,
	ref,
	watch,
} from 'vue';
import ZyroIconControls from '@/components/global/ZyroIconControls.vue';

const BORDER_RADIUS_VALUE_MODE_PIXEL = 'px';
const BORDER_RADIUS_VALUE_MODE_PERCENT = '%';
const toggleModeValues = [
	{
		value: BORDER_RADIUS_VALUE_MODE_PIXEL,
	},
	{
		value: BORDER_RADIUS_VALUE_MODE_PERCENT,
	},
];

const {
	getters,
	dispatch,
} = useStore();
const { t } = useI18n();
const { resetProportions } = useCurrentGridImage();
const {
	isMobileMode,
	elementPositionKey,
	elementPositionKeyReversed,
} = useLayoutHelpers();

const currentElement = computed(() => getters.currentElement);
const currentElementStyles = computed(() => getters.currentElementStyles);
const currentElementBlockType = computed(() => getters.currentElementBlockType);
const currentElementPositionKeyData = computed(() => currentElement.value[elementPositionKey.value]);
const currentElementReversedPositionKeyData = computed(() => currentElement.value[elementPositionKeyReversed.value]);
const currentElementBorderRadius = computed(() => {
	if (currentElementPositionKeyData.value?.borderRadius) {
		return currentElementPositionKeyData.value?.borderRadius;
	}

	if (currentElementReversedPositionKeyData.value?.borderRadius) {
		return currentElementReversedPositionKeyData.value?.borderRadius;
	}

	return null;
});

const currentElementOverlayOpacity = computed(() => currentElement.value?.overlayOpacity || null);

const isImageMaskUsed = computed(() => !!currentElement.value?.shapeMaskSource);
const objectFit = computed(() => currentElementStyles.value['object-fit'] || 'cover');

const currentBorderRadiusValueMode = ref(
	currentElementBorderRadius.value?.endsWith(BORDER_RADIUS_VALUE_MODE_PERCENT)
		? BORDER_RADIUS_VALUE_MODE_PERCENT
		: BORDER_RADIUS_VALUE_MODE_PIXEL,
);

const borderRadiusInNumber = computed(() => Number.parseInt(currentElementBorderRadius.value || 0, 10));
const borderRadiusInPercent = computed(() => Math.min(100, Number.parseInt(currentElementBorderRadius.value || 0, 10)));
const overlayOpacityInPercent = computed(() => Math.min(100, Number.parseInt(currentElementOverlayOpacity.value || 0, 10)));
const isImageInLayoutBlock = computed(() => currentElementBlockType.value === 'BlockLayout');
const showImageFitOptions = computed(() => !isMobileMode.value && !isImageInLayoutBlock.value);

const pixelBorderRadiusUpperLimit = computed(() => {
	const {
		width,
		height,
	} = currentElementPositionKeyData.value;

	return Math.ceil(Math.min(
		width / 2,
		height / 2,
	));
});

const FIT_OPTIONS = {
	contain: {
		name: t('builder.editImage.tabImage.fit'),
	},
	cover: {
		name: t('builder.editImage.tabImage.fill'),
	},
};

const updateObjectFit = (newValue) => {
	dispatch('mergeCurrentElementData', {
		elementData: {
			settings: {
				styles: {
					'object-fit': newValue,
				},
			},
		},
	});
};

const updatePixelBorderRadius = (radius) => {
	dispatch('mergeCurrentElementData', {
		elementData: {
			[elementPositionKey.value]: {
				borderRadius: `${radius}px`,
			},
		},
	});
};

const updateBorderRadiusInPercent = (radius) => {
	dispatch('mergeCurrentElementData', {
		elementData: {
			[elementPositionKey.value]: {
				borderRadius: `${50 * (radius / 100)}%`,
			},
		},
	});
};

const updateOverlayOpacityInPercent = (opacity) => {
	dispatch('mergeCurrentElementData', {
		elementData: {
			overlayOpacity: opacity,
		},
	});
};

watch(currentBorderRadiusValueMode, (newMode) => {
	if (newMode === BORDER_RADIUS_VALUE_MODE_PERCENT) {
		updateBorderRadiusInPercent(Math.min(100, borderRadiusInPercent.value));
	} else {
		updatePixelBorderRadius(Math.min(pixelBorderRadiusUpperLimit.value, borderRadiusInNumber.value * 2));
	}
});

onMounted(() => {
	if (currentBorderRadiusValueMode.value === BORDER_RADIUS_VALUE_MODE_PIXEL) {
		updatePixelBorderRadius(Math.min(pixelBorderRadiusUpperLimit.value, borderRadiusInNumber.value));
	}
});
</script>

<style lang="scss" scoped>
.edit-image-tab-styles {
	position: realtive;

	&__image-fit-label {
		margin: 8px 0;
	}

	&__mode-toggle {
		position: relative;
	}

	&__border-radius-units-label {
		margin-top: 16px;
	}

	&__border-radius-disclaimer {
		color: $color-gray;
		font-size: 12px;
		line-height: 1.6;
		margin-bottom: 16px;
	}
}

.reset-aspect-ratio {
	margin-bottom: 16px;
}
</style>
