<script setup>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useStore } from 'vuex';

const props = defineProps({
	item: {
		type: Object,
		required: true,
	},
	toggle: {
		type: Function,
		default: () => ({}),
	},
});

const { dispatch } = useStore();

const hideNavigationItem = () => {
	const {
		navItemId,
		isHidden = false,
	} = props.item;

	dispatch('navigation/changeItemVisibility', {
		itemId: navItemId,
		isHidden: !isHidden,
	});

	props.toggle();
};
</script>
<template>
	<!-- You need to wrap the button in a div if you want to test click event :') -->
	<div class="popup-button">
		<HostingerButton
			v-qa="`sitemenusettingspopup-btn-${item.isHidden ? 'show' : 'hide'}`"
			button-type="plain"
			@click="hideNavigationItem"
		>
			<template #icon-left>
				<Icon
					dimensions="20px"
					:name="item.isHidden ? 'visibility' : 'visibility_off'"
				/>
			</template>
			<span class="text-body-2">
				{{ $t(`builder.siteMenu.${item.isHidden ? 'showInNavigation' : 'hideFromNavigation'}`) }}
			</span>
		</HostingerButton>
	</div>
</template>

<style lang="scss" scoped>
.popup-button .hostinger-button {
	width: 100%;
}
</style>
