import { PINIA_STORES } from '@/constants/stores';
import { useSiteStore } from '@/stores/siteStore';
import { mergeObjects } from '@/utils/mergeObjects';
import {
	SiteEcommerceSortingValue,
	SiteData,
	SitePages,
	SitePage,
} from '@hostinger/builder-schema-validator';
import {
	EcommerceProduct,
	EcommerceProductSeoSettingsData,
	EcommerceUpdateProductsSeoPayload,
	SiteEcommerceSeoChange,
	SiteEcommerceSeoChangesSchema,
} from '@zyro-inc/site-modules/types';
import { defineStore } from 'pinia';
import { captureException } from '@sentry/vue';
import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import {
	META_ECOMMERCE_TYPE,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import {
	removePage,
	removeBlock,
	removeElement,
} from '@/utils/siteDataUtils';
import { BLOCKS_ECOMMERCE } from '@zyro-inc/site-modules/constants/ecommerce';
import { getSortedProductsKey } from '@/utils/ecommerce';
import {
	getStoreProducts,
	getStoreProductsSeo,
} from '@/api/StoreApi';
import { getStoreId } from '@zyro-inc/site-modules/utils/getters/getStoreId';
import {
	getIsExperimentActive,
	FEATURE_FLAG_IDS,
} from '@/utils/experiments';
import { QUERY_PARAM_GOROBOTS_ENABLE_LEGACY_PRODUCT_PAGES } from '@/constants/builderConstants';
import { patcher } from '@/utils/jsondiffpatch';
import { useRoute } from 'vue-router';
import {
	getStoreSettings,
	updateProductsSeo,
} from '@/api/EcommerceAdminApi';
import { generatePageSlug } from '@/utils/generatePageSlug';
import { extractText } from '@/utils/extractText';
import { getBatchedArrays } from '@zyro-inc/site-modules/utils/getBatchedArrays';

export const useEcommerceStore = defineStore(PINIA_STORES.ECOMMERCE, () => {
	const {
		state,
		getters,
		dispatch,
	} = useStore();
	const siteStore = useSiteStore();
	const route = useRoute();

	const arePaymentsEnabled = ref(true);
	const isLoadingProducts = ref<Record<string, boolean>>({
		global: false,
	});
	const productsSorted = ref<Record<SiteEcommerceSortingValue, EcommerceProduct[]>>(
		{} as Record<SiteEcommerceSortingValue, EcommerceProduct[]>,
	);
	const countSorted = ref<Record<SiteEcommerceSortingValue, number>>({} as Record<SiteEcommerceSortingValue, number>);
	const productsNonSorted = ref<EcommerceProduct[]>([]);
	const countNonSorted = ref(0);
	const hiddenProducts = ref<EcommerceProduct[]>([]);
	const isEditingGlobalPage = ref(false);
	const currentDynamicPageProductId = ref<string | number>('');
	// rawProductsSeo holds SEO with no changes in data.json
	// its needed in preview to be able to find product by its slug
	const rawProductsSeo = ref<EcommerceProductSeoSettingsData[]>([]);
	const productsSeo = ref<EcommerceProductSeoSettingsData[]>([]);

	const allProducts = computed(() => [
		...productsNonSorted.value,
		...hiddenProducts.value,
	]);
	const productSeoUpdates = computed(() => Object.entries(state.ecommerce.productMetaUpdates as Record<string, Record<string, string>>)
		.map(([productId, meta]) => {
			const product = productsSeo.value.find(({ id }) => id === productId);
			const productMeta = product?.seo_settings || {};
			const oldMeta = meta.seo_settings || {};

			return {
				id: productId,
				seo_settings: {
					...productMeta,
					...oldMeta,
				},
			};
		}));
	const productsSeoWithUpdates = computed(() => productsSeo.value
		.reduce((acc: EcommerceProductSeoSettingsData[], seoData: EcommerceProductSeoSettingsData) => {
			const updatedProductSeo = productSeoUpdates.value
				.find((updatedData) => updatedData.id === seoData.id) as EcommerceProductSeoSettingsData;

			return [
				...acc,
				updatedProductSeo || seoData,
			];
		}, []));
	const ecommerceSeoChanges = computed<SiteEcommerceSeoChangesSchema>(() => siteStore.site.ecommerceSeoChanges || []);
	const storeId = computed(() => getStoreId(getters.siteMeta));
	const defaultLocale = computed(() => getters.defaultLocale);
	const productSlugs = computed(() => productsSeo.value.map((product) => {
		const productSlug = product.seo_settings?.slug;
		const productSlugUpdated = state.ecommerce.productMetaUpdates[product.id]?.slug;

		return productSlugUpdated || productSlug;
	}));
	const isDynamicPageFlowEnabled = computed(() => {
		const gorobotsQuery = route.query[QUERY_PARAM_GOROBOTS_ENABLE_LEGACY_PRODUCT_PAGES];

		if (siteStore.isLegacyProductPagesAdded) {
			return false;
		}

		if (gorobotsQuery) {
			const isLegacyProductPagesEnabled = gorobotsQuery === '1';

			return !isLegacyProductPagesEnabled;
		}

		const isFeatureFlagEnabled = getIsExperimentActive(FEATURE_FLAG_IDS.ECOMMERCE_DYNAMIC_PAGES_FLOW);

		return getters['user/isZyroUser'] || isFeatureFlagEnabled || !!Object.keys(siteStore.ecommerceDynamicProductPageTemplates).length;
	});

	const setCurrentDynamicPageProductId = (productId: string | number) => {
		currentDynamicPageProductId.value = productId;
	};

	const setNonSortedProducts = (newProducts: EcommerceProduct[]) => {
		if (!productsNonSorted.value.length || newProducts.length > productsNonSorted.value.length) {
			productsNonSorted.value = newProducts;
		}

		const updatedProducts = productsNonSorted.value.map((oldProduct) => {
			const newProduct = newProducts.find((p) => p.id === oldProduct.id);

			return newProduct ? {
				...oldProduct,
				...newProduct,
			} : oldProduct;
		});

		// in dynamic page flow, products are being fetched in batches, not all at once
		if (isDynamicPageFlowEnabled.value) {
			const nonExistingProducts = newProducts.filter(
				(newProduct) => !updatedProducts?.some(
					(existingProduct) => existingProduct.id === newProduct.id,
				),
			);

			productsNonSorted.value = [
				...updatedProducts,
				...nonExistingProducts,
			];

			return;
		}

		productsNonSorted.value = updatedProducts;
	};

	const setSortedProducts = (newProducts: EcommerceProduct[], sortedProductsKey: SiteEcommerceSortingValue) => {
		const nonExistingProducts = newProducts.filter(
			(newProduct) => !productsSorted.value[sortedProductsKey]?.some(
				(existingProduct) => existingProduct.id === newProduct.id,
			),
		);

		productsSorted.value[sortedProductsKey] = [
			...(productsSorted.value[sortedProductsKey] || []),
			...nonExistingProducts,
		];
	};

	const setStoreProducts = ({
		products,
		sort,
		collectionId,
		count,
	}: {
		products: EcommerceProduct[],
		sort?: SiteEcommerceSortingValue,
		collectionId?: string,
		count?: number,
	}) => {
		setNonSortedProducts(products);

		if (collectionId || sort) {
			const sortedProductsKey = getSortedProductsKey(sort, collectionId);

			setSortedProducts(products, sortedProductsKey);
			countSorted.value[sortedProductsKey] = count || products.length;
		} else {
			countNonSorted.value = count || products.length;
		}
	};

	const fetchProductById = async ({
		productId,
		blockId,
		isHidden,
	}: {productId: string, blockId: string, isHidden?: boolean}): Promise<EcommerceProduct | null> => {
		if (!storeId.value) {
			return null;
		}

		isLoadingProducts.value[blockId] = true;

		try {
			const productData = await getStoreProducts(storeId.value, {
				productId,
				isHidden,
			});

			setNonSortedProducts(productData.products);

			return productData.products[0];
		} catch (error) {
			dispatch('notifications/notify', {
				message: `Error while getting store product: ${productId}.`,
			});

			captureException(error);

			return null;
		} finally {
			isLoadingProducts.value[blockId] = false;
		}
	};

	const fetchProducts = async ({
		limit,
		resetUndoForPageCreation = true,
		pickStylesFromTheme = false,
		shouldAwaitPageCreation = false,
	}: {
		limit?: number,
		resetUndoForPageCreation?: boolean,
		pickStylesFromTheme?: boolean,
		shouldAwaitPageCreation?: boolean,
	} = {}) => {
		if (!storeId.value) {
			return;
		}

		isLoadingProducts.value.global = true;

		try {
			if (isDynamicPageFlowEnabled.value) {
				const productData = await getStoreProducts(storeId.value, {
					limit: limit || 1,
				});

				setStoreProducts({
					products: productData.products,
					count: productData.count,
				});

				dispatch('addEcommerceProductPages', {
					pickStylesFromTheme,
					resetUndo: resetUndoForPageCreation,
				});
			} else {
				const productData = await getStoreProducts(storeId.value);

				setStoreProducts({
					products: productData.products,
					count: productData.count,
				});

				if (!getters['ecommerce/isStoreTypeZyro']) {
					return;
				}

				if (shouldAwaitPageCreation) {
					await dispatch('addEcommerceProductPages', {
						pickStylesFromTheme,
						resetUndo: resetUndoForPageCreation,
					});
				} else {
					dispatch('addEcommerceProductPages', {
						pickStylesFromTheme,
						resetUndo: resetUndoForPageCreation,
					});
				}
			}
		} catch (error) {
			dispatch('notifications/notify', {
				message: 'Error while getting store products.',
			});

			captureException(error);
		} finally {
			isLoadingProducts.value.global = false;
		}
	};

	const fetchListProducts = async ({
		sortType,
		collectionId,
		blockId,
		limit,
		offset,
	}: {
		sortType: SiteEcommerceSortingValue,
		collectionId: string,
		blockId: string,
		limit: number,
		offset: number
	}) => {
		isLoadingProducts.value[blockId] = true;

		try {
			const sort = (!sortType && collectionId) ? 'order=ASC&sort_by=collection_order' as SiteEcommerceSortingValue : sortType;
			const productData = await getStoreProducts(storeId.value, {
				sort,
				collectionId,
				limit,
				offset,
			});

			setStoreProducts({
				products: productData.products,
				sort,
				collectionId,
				count: productData.count,
			});
		} catch (error) {
			dispatch('notifications/notify', {
				message: 'Error while getting store products.',
			});

			captureException(error);
		} finally {
			isLoadingProducts.value[blockId] = false;
		}
	};

	const fetchProductsSeo = async () => {
		try {
			const { products } = await getStoreProductsSeo(storeId.value);

			const productsSeoWithPendingChanges = ecommerceSeoChanges.value.length ? products.map((product) => {
				const pendingChanges = ecommerceSeoChanges.value.find(
					(change) => product.id === change.id,
				);

				if (pendingChanges) {
					return {
						...product,
						seo_settings: {
							...pendingChanges.seo_settings,
						},
					};
				}

				return product;
			}) : products;

			rawProductsSeo.value = products;
			productsSeo.value = productsSeoWithPendingChanges;
		} catch (error) {
			dispatch('notifications/notify', {
				message: 'Error while getting store products seo data.',
			});

			captureException(error);
		}
	};

	const updateStoreProductsSeo = (updatedProductsSeo: SiteEcommerceSeoChangesSchema) => {
		const mergedProducts = productsSeo.value.map((product) => {
			const matchingProduct = updatedProductsSeo.find((_updatedProduct) => _updatedProduct.id === product.id);

			if (matchingProduct) {
				return mergeObjects(product, matchingProduct);
			}

			return product;
		});

		productsSeo.value = mergedProducts;
	};

	const updateRawStoreProductsSeo = (updatedProductsSeo: SiteEcommerceSeoChangesSchema) => {
		const mergedProducts = rawProductsSeo.value.map((product) => {
			const matchingProduct = updatedProductsSeo.find((_updatedProduct) => _updatedProduct.id === product.id);

			if (matchingProduct) {
				return mergeObjects(product, matchingProduct);
			}

			return product;
		});

		rawProductsSeo.value = mergedProducts;
	};

	const fetchInitialEcommerceData = async ({
		shouldAwaitPageCreation = false,
		pickStylesFromTheme = false,
		resetUndoForPageCreation = true,
		limit,
	}: {
		shouldAwaitPageCreation?: boolean,
		pickStylesFromTheme?: boolean,
		resetUndoForPageCreation?: boolean
		limit?: number
	} = {}) => {
		const promises = [
			fetchProducts({
				pickStylesFromTheme,
				shouldAwaitPageCreation,
				resetUndoForPageCreation,
				...(limit ? {
					limit,
				} : {}),
			}),
			fetchProductsSeo(),
		];

		await Promise.all(promises);
	};

	const getProductsSorted = (sort: SiteEcommerceSortingValue, collectionId: string) => {
		const sortValue = (!sort && collectionId) ? 'order=ASC&sort_by=collection_order' : sort;

		return productsSorted.value[getSortedProductsKey(sortValue, collectionId)] || [];
	};

	const getCountSorted = (sort: SiteEcommerceSortingValue, collectionId: string) => {
		const sortValue = (!sort && collectionId) ? 'order=ASC&sort_by=collection_order' : sort;

		return countSorted.value[getSortedProductsKey(sortValue, collectionId)] || 0;
	};

	const setHiddenProducts = (products: EcommerceProduct[]) => {
		hiddenProducts.value = products;
	};

	const deleteEcommerceItemsFromSite = (siteData: SiteData) => {
		let siteDataClone = patcher.clone(siteData) as SiteData;

		// get ecommerce block and element info from the remaining pages
		Object.entries(siteDataClone.languages).forEach(([
			locale,
			{
				blocks,
				elements,
			},
		]) => {
			const languageEcommerceBlockIds = Object.keys(blocks).filter(
				(key) => BLOCKS_ECOMMERCE.includes(blocks[key]?.type),
			);
			const languageEcommerceElementIds = Object.keys(elements).filter(
				(key) => elements[key]?.type === 'GridEcommerceButton',
			);

			languageEcommerceBlockIds.forEach((blockId) => {
				siteDataClone = removeBlock({
					siteData: siteDataClone,
					blockId,
					locale,
				}) as SiteData;
			});

			languageEcommerceElementIds.forEach((elementId) => {
				siteDataClone = removeElement({
					siteData: siteDataClone,
					elementId,
					locale,
				}) as SiteData;
			});
		});

		delete siteDataClone.meta[META_ECOMMERCE_TYPE];

		// remove ecommerce shopping cart
		const {
			ecommerceShoppingCart,
			...restData
		} = siteDataClone;

		return restData;
	};

	const deleteLegacyEcommercePagesFromSite = () => {
		let siteDataClone = patcher.clone(siteStore.site) as SiteData;

		dispatch('unselectCurrentElement');
		dispatch('updateCurrentBlockId', null);

		// then delete all the product pages together with their product blocks from default lang
		const productPageKeys = Object.keys(siteStore.ecommerceLegacyProductPages);

		if (productPageKeys.length) {
			productPageKeys.forEach((pageId) => {
				siteDataClone = removePage({
					siteData: siteDataClone,
					pageId,
					locale: defaultLocale.value,
				}) as SiteData;
			});
		}

		const siteDataWithDeletedItems = deleteEcommerceItemsFromSite(siteDataClone);

		dispatch('overwriteWebsiteData', {
			websiteData: {
				...siteDataWithDeletedItems,
			},
		});
		dispatch('undoRedo/resetUndoRedo', null);
		dispatch('saving/saveWebsite', {});
	};

	const deleteDynamicEcommercePageFromSite = () => {
		let siteDataClone = patcher.clone(siteStore.site) as SiteData;

		dispatch('unselectCurrentElement');
		dispatch('updateCurrentBlockId', null);
		dispatch('updateCurrentPageId');

		const dynamicProductPageTemplateKeys = Object.keys(siteStore.ecommerceDynamicProductPageTemplates);

		if (dynamicProductPageTemplateKeys.length) {
			dynamicProductPageTemplateKeys.forEach((pageId) => {
				siteDataClone = removePage({
					siteData: siteDataClone,
					pageId,
					locale: defaultLocale.value,
				}) as SiteData;
			});
		}

		const siteDataWithDeletedItems = deleteEcommerceItemsFromSite(siteDataClone);

		dispatch('overwriteWebsiteData', {
			websiteData: {
				...siteDataWithDeletedItems,
			},
		});
		dispatch('saving/saveWebsite', {});
	};

	const deleteEcommerceFromSite = () => {
		if (Object.keys(siteStore.ecommerceDynamicProductPageTemplates).length) {
			deleteDynamicEcommercePageFromSite();

			return;
		}

		deleteLegacyEcommercePagesFromSite();
	};

	// Getter to check for both - legacy ecommerce pages and new dynamic ecommerce pages
	const getIsBlockInEcommerceProductPage = (blockId: string) => Object.values(
		siteStore.ecommerceProductPages as SitePages,
	).some(({ blocks }) => blocks?.includes(blockId));

	const resetProductsList = () => {
		productsNonSorted.value = [];
		productsSorted.value = {} as Record<SiteEcommerceSortingValue, EcommerceProduct[]>;
		countNonSorted.value = 0;
		countSorted.value = {} as Record<SiteEcommerceSortingValue, number>;
	};

	const getIsSlugNotUsedForProducts = ({ slug } : {slug: string}): boolean => !productSlugs.value.includes(slug);

	// To handle edge cases for websites, that for some reason had their store created, new products added in admin,
	// but never came back to editor - thus dont have seo in products saved nor ecommerce
	// pages created so they would be handled by mapper
	const getProductsWithoutSlugsChanges = () => {
		const websitePageList = Object.values(siteStore.site.languages).reduce((acc: SitePage[], lang) => {
			const pages = lang.pages || {};

			return [
				...acc,
				...Object.values(pages),
			];
		}, []);
		const existingPageSlugs = websitePageList.map(({ slug }) => slug).filter(Boolean);

		const constructProductSlug = (
			product: EcommerceProduct | EcommerceProductSeoSettingsData,
			existingProductSlugs: string[],
		) => {
			const productSlug = generatePageSlug({
				initialSlug: product.title,
				existingSlugs: [
					...existingProductSlugs,
					...existingPageSlugs,
				],
				slugSuffix: '-product',
			});

			const {
				slug,
				...rest
			} = product.seo_settings || {};

			return {
				id: product.id,
				seo_settings: {
					slug: productSlug,
					title: product.title,
					...rest,
				},
			};
		};

		if (isDynamicPageFlowEnabled.value) {
			const existingProductSlugs = productsSeo.value.map(
				(product) => product.seo_settings?.slug,
			).filter(Boolean) as string[];
			const productsWithoutSlugs = productsSeo.value.filter((product) => !product.seo_settings?.slug);

			return productsWithoutSlugs.map((product) => constructProductSlug(product, existingProductSlugs));
		}

		const storeProducts = [
			...productsNonSorted.value,
			...hiddenProducts.value,
		];

		const productPagesWithoutSlugs = websitePageList.filter((page) => {
			const isPageTypeEcommerceProduct = page.type === PAGE_TYPE_ECOMMERCE_PRODUCT;

			if (!isPageTypeEcommerceProduct) {
				return false;
			}

			const productSeoData = storeProducts.find((product) => product.id === page.productId)?.seo_settings || {};

			return !productSeoData.slug;
		});

		const existingProductSlugs = storeProducts.map((product) => product.seo_settings?.slug).filter(Boolean) as string[];

		return productPagesWithoutSlugs.map(
			(page) => {
				const product = storeProducts.find(({ id }) => id === page.productId);

				if (!product) {
					return null;
				}

				const parsedDescriptionHtml = new DOMParser().parseFromString(product.description, 'text/html');
				const description = parsedDescriptionHtml ? extractText(parsedDescriptionHtml) : '';
				const seoDataWithSlug = constructProductSlug(product, existingProductSlugs);

				return {
					...seoDataWithSlug,
					seo_settings: {
						...seoDataWithSlug.seo_settings,
						...(product.thumbnail ? {
							ogImagePath: product.thumbnail,
						} : {}),
						ogImageOrigin: 'other',
						ogImageAlt: product.title,
						description,
					},
				};
			},
		).filter(Boolean);
	};

	const updateProductPagesWithoutSlugsSeo = async () => {
		const productsWithoutSlugsChanges = getProductsWithoutSlugsChanges();

		if (!productsWithoutSlugsChanges?.length) {
			return;
		}

		try {
			const updatePromises = getBatchedArrays(productsWithoutSlugsChanges, 100)
				.map((batch) => updateProductsSeo(storeId.value, batch as EcommerceUpdateProductsSeoPayload[]));

			const updatedProducts = await Promise.all(updatePromises);
			const updatedProductsSeo: SiteEcommerceSeoChange[] = updatedProducts.flat().map((product) => ({
				id: product.id as string,
				seo_settings: product.seo_settings,
			}));

			if (!isDynamicPageFlowEnabled.value) {
				dispatch('ecommerce/updateStoreProducts', updatedProducts.flat());
			}

			updateStoreProductsSeo(updatedProductsSeo);
			updateRawStoreProductsSeo(updatedProductsSeo);
		} catch (error) {
			dispatch('notifications/notify', {
				messageI18nKeyPath: 'builder.productPageDataSavingNotification',
				submitLabelI18nKeyPath: 'common.reload',
				isDiscardButtonShown: false,
				submitCallback: () => window.location.reload(),
			}, {
				root: true,
			});

			console.error(error);
			captureException(error);

			throw error;
		}
	};

	const updateProductsSeoData = () => {
		if (!storeId.value) {
			return;
		}

		const productSeoChanges = Object.entries(state.ecommerce.productMetaUpdates)
			.map(([productId, meta]) => {
				const product = productsSeo.value.find(({ id }) => id === productId);
				const productMeta = product?.seo_settings || {};

				return {
					id: productId,
					seo_settings: {
						...productMeta,
						...(meta || {}),
					},
				};
			});

		if (!productSeoChanges.length) {
			return;
		}

		siteStore.setEcommerceSeoChanges(productSeoChanges);

		dispatch('ecommerce/setProductMetaUpdates', null, {
			root: true,
		});

		updateStoreProductsSeo(productSeoChanges);
	};

	const saveEcommerceSeoChanges = async () => {
		updateProductsSeoData();

		const pendingEcommerceSeoChanges = Object.values(ecommerceSeoChanges.value);

		if (!pendingEcommerceSeoChanges.length) {
			return;
		}

		const updatePromises = getBatchedArrays(pendingEcommerceSeoChanges, 100)
			.map((batch) => updateProductsSeo(storeId.value, batch));

		await Promise.all(updatePromises);

		rawProductsSeo.value = rawProductsSeo.value.map((productSeo) => {
			const productSeoChange = pendingEcommerceSeoChanges.find((change) => change.id === productSeo.id);
			const newSeo = productSeoChange?.seo_settings || {};

			return {
				...productSeo,
				seo_settings: {
					...productSeo.seo_settings,
					...newSeo,
				},
			};
		});

		siteStore.resetEcommerceSeoChanges();
	};

	const handleEditDynamicProductPageClick = () => {
		if (getters.isCurrentPageTypeDynamicProduct) {
			return;
		}

		const [dynamicProductPageId] = Object.keys(siteStore.ecommerceDynamicProductPageTemplates);
		const { currentLocale } = state;

		if (currentLocale !== defaultLocale.value) {
			dispatch('updateCurrentLocale', defaultLocale.value);
		}

		dispatch('updateCurrentPageId', dynamicProductPageId);
		setCurrentDynamicPageProductId(productsSeo.value[0]?.id || -1);
	};

	const getPaymentsSettings = async () => {
		const { ecommerceStoreId } = getters.siteMeta;

		if (!ecommerceStoreId) {
			return;
		}

		try {
			const data = await getStoreSettings(ecommerceStoreId);

			arePaymentsEnabled.value = data.status.paymentsEnabled;
		} catch (error) {
			dispatch('notifications/notify', {
				message: `Error while getting store settings: ${ecommerceStoreId}.`,
			});

			captureException(error);
		}
	};

	const initEcommerce = async ({
		shouldPickStylesFromTheme = false,
		refetch = false,
	} = {}) => {
		dispatch('ecommerce/getVariantsQuantity');

		// needed for addEcommerceProductPages store action
		await fetchInitialEcommerceData({
			pickStylesFromTheme: shouldPickStylesFromTheme,
			// update only existing products for dynamic page flow
			...(isDynamicPageFlowEnabled.value ? {
				limit: productsNonSorted.value.length,
			} : {}),
		});

		if (!refetch) {
			// To handle edge cases, more info in ecommerceStore.ts
			await updateProductPagesWithoutSlugsSeo();
		}

		await Promise.all([
			dispatch('ecommerce/getSettings'),
			dispatch('ecommerce/getCategories'),
			getPaymentsSettings(),
		]);
	};

	return {
		isEditingGlobalPage,
		deleteEcommerceFromSite,
		currentDynamicPageProductId,
		setCurrentDynamicPageProductId,
		getProductsSorted,
		getCountSorted,
		products: productsNonSorted,
		productSlugs,
		count: countNonSorted,
		productsSorted,
		productsNonSorted,
		countSorted,
		hiddenProducts,
		allProducts,
		fetchProducts,
		fetchListProducts,
		isLoadingProducts,
		setStoreProducts,
		fetchProductById,
		setHiddenProducts,
		isDynamicPageFlowEnabled,
		getIsBlockInEcommerceProductPage,
		resetProductsList,
		productsSeo,
		rawProductsSeo,
		fetchInitialEcommerceData,
		getIsSlugNotUsedForProducts,
		updateProductsSeoData,
		updateProductPagesWithoutSlugsSeo,
		fetchProductsSeo,
		updateStoreProductsSeo,
		productsSeoWithUpdates,
		saveEcommerceSeoChanges,
		initEcommerce,
		handleEditDynamicProductPageClick,
		getPaymentsSettings,
		arePaymentsEnabled,
	};
});
