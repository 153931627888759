import axios from '@/services/http/axios';
import {
	ChatbotConversationHistory,
	ChatbotRespondResponse,
} from '@hostinger/hcomponents';

const ROOT_URL = `${import.meta.env.VITE_BACKEND_API_URL}/chatbot`;

export const initializeChatbot = () => axios.post<ChatbotConversationHistory>(`${ROOT_URL}/initialize`);

export const respondChatbot = ({ content }: { content: string }) => axios.post<ChatbotRespondResponse>(`${ROOT_URL}/respond`, {
	content,
});

export const restartChatbot = () => axios.post<ChatbotConversationHistory>(`${ROOT_URL}/restart`);

export const rateChatbotConversation = (payload: { rating: number, message?: string }) => (
	axios.post<{ message: string}>(`${ROOT_URL}/conversation/rate`, payload)
);
