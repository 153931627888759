export const PINIA_STORES = {
	AI_WRITER: 'aiWriter',
	AI_WRITER_DRAWER: 'aiWriterDrawer',
	AI_TOOLS: 'aiTools',
	SITE: 'site',
	SEO: 'seo',
	USER: 'user',
	DOMAIN: 'domain',
	ECOMMERCE_ADMIN: 'ecommerceAdmin',
	ECOMMERCE: 'ecommerce',
	CLIPBOARD: 'clipboard',
	GAMIFICATION: 'gamification',
	NPS: 'nps',
	INTERCOM: 'intercom',
	BLOG: 'blog',
	GUI: 'gui',
	AI_BUILDER_PREVIEW: 'aiBuilderPreview',
	RESOURCES: 'resources',
} as const;
