<template>
	<div>
		<ZyroFieldRange
			:label="$t('builder.editCommon.itemsPerRow')"
			class="edit-instagram-feed__style-range"
			units=""
			min="1"
			max="16"
			:model-value="itemsPerRow"
			@update:model-value="setElementStyle(itemsPerRowKey, $event)"
		/>
		<ZyroFieldRange
			:label="$t('builder.editCommon.gapBetweenPhotos')"
			class="edit-instagram-feed__style-range"
			min="0"
			max="72"
			step="8"
			:model-value="itemGap"
			@update:model-value="setItemGap($event)"
		/>
	</div>
</template>

<script setup>
import ZyroFieldRange from '@/components/global/ZyroFieldRange.vue';

import { useStore } from 'vuex';
import { computed } from 'vue';

const {
	getters,
	dispatch,
} = useStore();

const isMobileView = computed(() => getters['gui/isMobileMode']);
const itemsPerRowKey = computed(() => (isMobileView.value ? 'm-items-per-row' : 'items-per-row'));
const itemsPerRow = computed(() => getters.currentElementStyles[itemsPerRowKey.value]);

const itemGapKey = computed(() => (isMobileView.value ? 'm-item-gap' : 'item-gap'));
const itemGap = computed(() => getters.currentElementStyles[itemGapKey.value]?.replace('px', '') || '0');

const setItemGap = (value) => {
	dispatch('mergeCurrentElementData', {
		elementData: {
			settings: {
				styles: {
					[itemGapKey.value]: value ? `${value}px` : '0px',
				},
			},
		},
	});
};

const setElementStyle = (styleKey, value) => {
	dispatch('mergeCurrentElementData', {
		elementData: {
			settings: {
				styles: {
					[styleKey]: value,
				},
			},
		},
	});
};
</script>

<style lang="scss" scoped>
.edit-instagram-feed {
	&__style-range:not(:last-of-type) {
		padding-bottom: 24px;
		border-bottom: 0.5px solid $color-gray-border;
	}
}
</style>
