import { generateRandomId } from '@/utils/generateRandomId';
import { addBreadcrumb } from '@sentry/vue';

const DEFAULT_TOAST_TIMEOUT = 5000;

export default {
	namespaced: true,
	state: {
		toasts: [],
		autoCloseInterval: null,
	},
	mutations: {
		addToast: (state, notification) => {
			state.toasts = [
				...state.toasts,
				notification,
			];
		},
		clearAutoClose: (state) => {
			clearInterval(state.autoCloseInterval);
		},
		removeAllLastToastOccurrences: (state) => {
			const lastToast = state.toasts[state.toasts.length - 1];

			state.toasts = state.toasts.filter((item) => item.message !== lastToast.message
				|| item.messageI18nKeyPath !== lastToast.messageI18nKeyPath);
		},
		setAutoCloseInterval: (state, interval) => {
			state.autoCloseInterval = interval;
		},
		removeToastById: (state, id) => {
			state.toasts = state.toasts.filter((item) => item.id !== id);
		},
	},
	actions: {
		notify: ({ dispatch }, payload = {}) => {
			const notification = {
				isDiscardButtonShown: true,
				isCloseButtonShown: true,
				closeOnSubmit: true,
				isAutoCloseDisabled: payload.submitCallback || payload.submitLabelI18nKeyPath,
				...payload,
				id: payload.id || generateRandomId(), // generate ID to help finding item in log
				timeStamp: Date.now(), // generate timeStamp for filtering, sorting etc
			};

			addBreadcrumb({
				data: notification,
				category: 'notification',
				level: 'info',
			});

			dispatch('createToast', notification);
			dispatch('clearAutoClose');
			dispatch('startAutoClose');
		},
		createToast: ({ commit }, notification) => {
			commit('addToast', notification);
		},
		startAutoClose: ({
			commit,
			state,
		}) => {
			const autoCloseInterval = setInterval(() => {
				const lastToast = state.toasts[state.toasts.length - 1];

				// if toast has a CTA button it should not be auto closed
				if (lastToast?.isAutoCloseDisabled) {
					clearInterval(state.autoCloseInterval);

					return;
				}

				commit('removeAllLastToastOccurrences');
			}, DEFAULT_TOAST_TIMEOUT);

			commit('setAutoCloseInterval', autoCloseInterval);
		},
		clearAutoClose: ({ commit }) => {
			commit('clearAutoClose');
		},
		removeAllLastToastOccurrences: ({ commit }) => {
			commit('removeAllLastToastOccurrences');
		},
	},
};
