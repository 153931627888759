<template>
	<button
		v-qa="`context-menu-${title}-btn`"
		class="context-menu-button text-body-2"
		:disabled="isDisabled"
	>
		<span
			v-if="icon"
			class="context-menu-button__icon"
		>
			<Icon :name="icon" />
		</span>
		{{ title }}
		<span
			v-if="shortcut"
			class="context-menu-button__shortcut"
		>
			{{ isMac ? shortcut.mac : shortcut.windows }}
		</span>
	</button>
</template>

<script setup>
import Icon from '@/components/global/Icon.vue';
import { getIsUserUsingMac } from '@/utils/getIsUserUsingMac';

defineProps({
	icon: {
		type: String,
		default: null,
	},
	title: {
		type: String,
		required: true,
	},
	shortcut: {
		type: Object,
		default: null,
		validator: (shortcut) => 'mac' in shortcut && 'windows' in shortcut,
	},
	isDisabled: {
		type: Boolean,
		default: false,
	},
});

const isMac = getIsUserUsingMac();
</script>

<style lang="scss" scoped>
.context-menu-button {
	display: flex;
	width: 100%;
	padding: 10px 16px;
	align-items: center;

	&:disabled {
		color: #6D7081;
	}

	&__icon {
		height: 24px;
		width: 24px;
		margin-right: 16px;
	}

	&:hover,
	&:focus-visible {
		&:not(:disabled) {
			cursor: pointer;
			background: $color-gray-light;
		}
	}

	&__shortcut {
		margin-left: auto;
		color: $color-gray;
		letter-spacing: 0.15em;

		@media screen and (max-width: $media-mobile) {
			display: none;
		}
	}
}
</style>
