<script setup lang="ts">
import HImage from '@/components/global/HImage.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useRedirects } from '@/use/useRedirects';

const { redirectToEcommerce } = useRedirects();

</script>

<template>
	<div class="page-settings-seo-drawer">
		<HImage
			class="page-settings-seo-drawer__store-image"
			src="/images/activate-seo.png"
		/>
		<h4 class="text-heading-3">
			{{ $t('builder.pageSettingsSeoDrawerAddStoreTitle') }}
		</h4>
		<p class="text-body-2 page-settings-seo-drawer__description">
			{{ $t('builder.pageSettingsSeoDrawerAddStoreDescription') }}
		</p>
		<ul class="page-settings-seo-drawer__list">
			<li class="page-settings-seo-drawer__list-item page-settings-seo-drawer__list-item--checkmark text-body-2">
				{{ $t('builder.storeKeyPointProducts') }}
			</li>
			<li class="page-settings-seo-drawer__list-item page-settings-seo-drawer__list-item--checkmark text-body-2">
				{{ $t('builder.storeKeyPointSetup') }}
			</li>
			<li class="page-settings-seo-drawer__list-item page-settings-seo-drawer__list-item--checkmark text-body-2">
				{{ $t('builder.storeKeyPointFast') }}
			</li>
			<li class="page-settings-seo-drawer__list-item page-settings-seo-drawer__list-item--checkmark text-body-2">
				{{ $t('builder.storeKeyPointSeo') }}
			</li>
		</ul>
		<div class="page-settings-seo-drawer__button">
			<HostingerButton
				v-qa="'drawer-addecommerce-addecommercelitestore-btn'"
				size="lg"
				@click="redirectToEcommerce"
			>
				{{ $t('builder.addStore') }}
			</HostingerButton>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.page-settings-seo-drawer {
	position: relative;
	background-color: $color-gray-light;
	border-radius: 16px;
	padding: 32px;
	border: 1px solid $color-gray-border;

	&__description {
		padding: 4px 0;
	}

	&__button {
		margin-top: 32px;
		text-align: right;
	}

	&__list-item {
		&::before {
			font-size: 2em;
			line-height: 0.8;
			vertical-align: middle;
			content: "· ";
		}

		&--checkmark {
			margin-bottom: 8px;

			&::before {
				margin-right: 8px;
				content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M5.99997 12.0001L10.2878 16.2879C10.405 16.4051 10.5949 16.4051 10.7121 16.2879L20.25 6.75006' stroke='%23673DE6' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
			}
		}
	}

	&__store-image {
		width: 100%;
		margin-bottom: 24px;
		object-fit: cover;
	}

	&__list {
		list-style: none;
	}
}
</style>
