<script lang="ts" setup>
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useEcommerceAdminStore } from '@/stores/ecommerceAdminStore';
import { useI18n } from 'vue-i18n';

import { ECOMMERCE_REDIRECT_PATHS } from '@/constants/builderConstants';

const ecommerceAdminStore = useEcommerceAdminStore();

const { t } = useI18n();

const handleEcommerceManageButtonClick = () => {
	ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
		isOpen: true,
		path: ECOMMERCE_REDIRECT_PATHS.STORE_LANGUAGE,
	});
};

</script>

<template>
	<div class="ecommerce-tab-language">
		<HostingerButton
			v-qa="'editproductlist-tabstorelanguage-language-button'"
			@click="handleEcommerceManageButtonClick"
		>
			{{ t('common.change') }}
		</HostingerButton>
		<hr class="ecommerce-tab-language__hr">
		<p class="ecommerce-tab-language__note">
			{{ t('builder.storeLanguageEffectNote') }}
		</p>
	</div>
</template>

<style lang="scss" scoped>
.ecommerce-tab-language {
    display: flex;
	flex-direction: column;
    align-items: center;

	&__hr {
		margin: 16px auto;
		border: none;
		height: 1px;
		width: 100%;
		background-color: $color-gray-border;
	}

	&__note {
		color: $color-gray;
		font-size: 14px;
		line-height: 1.5rem;
		text-align: justify;
	}
}
</style>
