<script setup lang="ts">
import ColorPickerSelection from '@/components/global/color-picker/ColorPickerSelection.vue';
import { useWebsiteColors } from '@/use/useWebsiteColors';
import { SiteBackgroundGradient } from '@hostinger/builder-schema-validator';
import { onMounted } from 'vue';
import { getGradientCss } from '@zyro-inc/site-modules/utils/cssUtils';
import { isEqual } from 'lodash';

interface Props {
	gradient: SiteBackgroundGradient;
	isOpacityDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	isOpacityDisabled: false,
});

const emit = defineEmits<{
	'update-gradient': [gradient: SiteBackgroundGradient];
	'toggle-eye-dropper': [value: boolean];
}>();

const { mostUsedGradients } = useWebsiteColors();

const top16UsedGradients = [
	...new Set(
		Object.keys(mostUsedGradients.value).map((gradient) => JSON.parse(gradient))
			.slice(0, 16),
	),
];

const isGradientActive = (
	usedGradient: SiteBackgroundGradient,
) => isEqual({
	...usedGradient,
	isAnimated: false,
}, {
	...props.gradient,
	isAnimated: false,
});

onMounted(() => {
	emit('update-gradient', props.gradient);
});
</script>

<template>
	<div class="color-picker-gradient">
		<div
			v-if="top16UsedGradients.length"
			class="color-picker-gradient__website-colors"
		>
			<p class="text-bold-2">
				{{ $t('builder.websiteGradients') }}
			</p>

			<div class="color-picker-gradient__gradients">
				<button
					v-for="usedGradient in top16UsedGradients"
					:key="JSON.stringify(usedGradient)"
					class="color-picker-gradient__gradient"
					:class="{ 'active': isGradientActive(usedGradient) }"
					:style="{ background: getGradientCss(usedGradient) }"
					@click="emit('update-gradient', {
						...usedGradient,
						isAnimated: gradient.isAnimated,
					})"
				/>
			</div>
		</div>

		<ColorPickerSelection
			is-gradient
			:gradient="gradient"
			:is-opacity-disabled="isOpacityDisabled"
			@update-gradient="emit('update-gradient', $event)"
			@toggle-eye-dropper="emit('toggle-eye-dropper', $event)"
		/>
	</div>
</template>

<style lang="scss" scoped>
.color-picker-gradient {
	&__pick-color-button {
		display: flex;
		margin-top: 8px;
		width: 100%;

		:deep(.hostinger-button__text) {
			gap: 4px;
		}
	}

	&__website-colors {
		padding: 16px 16px 0;
	}

	&__gradients {
		display: grid;
		grid-template-columns: repeat(8, 24px);
		grid-column-gap: 4px;
	}

	&__gradient {
		margin-top: 8px;
		width: 24px;
		height: 24px;
		margin-bottom: 4px;
		cursor: pointer;
		border: 1px solid rgba(221, 225, 230, 80%);
		border-radius: 50%;

		&:hover {
			border: none;
			border: 2px solid $color-primary;
		}

		&.active {
			border: 2px solid $color-light;
			outline: 2px solid $color-primary;
		}
	}
}
</style>
