<script lang="ts" setup>
import {
	computed,
	onMounted,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import ZyroModal from '@/components/global/ZyroModal.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useRedirects } from '@/use/useRedirects';
import {
	CLOSE_MODAL,
	GUI_NAMESPACE,
} from '@/store/builder/gui';
import Icon from '@/components/global/Icon.vue';
import { useI18n } from 'vue-i18n';
import EventLogApi from '@/api/EventLogApi';
import { useDomainStore } from '@/stores/domainStore';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';

const {
	dispatch,
	state,
} = useStore();

const { t } = useI18n();
const { redirectToHPanelUpgradePage } = useRedirects();

const domainStore = useDomainStore();

const location = computed(() => state.gui.activeModalSettings?.location);
const customDomain = computed(() => domainStore.customDomain);
const isLoading = ref(false);

const closeModal = () => dispatch(`${GUI_NAMESPACE}/${CLOSE_MODAL}`);

const handleRedirectToUpgradePage = () => {
	isLoading.value = true;

	EventLogApi.logEvent({
		eventName: 'website_builder.feature_lock.upgrade',
		eventProperties: {
			location: 'modal',
		},
	});

	redirectToHPanelUpgradePage({
		currentDomain: customDomain.value,
	});
};

onMounted(() => {
	EventLogApi.logEvent({
		eventName: 'website_builder.feature_lock.shown',
		eventProperties: {
			location: location.value,
		},
	});
});
</script>

<template>
	<Teleport to="body">
		<ZyroModal
			is-for-payments
			max-width="400px"
			class="upgrade-plan-modal"
			height="auto"
			:show-close-button="false"
			:content-padding="'24px'"
			@close-modal="closeModal"
		>
			<Icon
				class="upgrade-plan-modal__icon"
				name="lock"
				is-filled
				size="24px"
			/>

			<div class="upgrade-plan-modal__content">
				<h2 class="text-heading-2">
					{{ t('builder.featureLock.upgradeModalTitle') }}
				</h2>

				<p class="upgrade-plan-modal__content-description text-body-2">
					{{ t('builder.featureLock.upgradeModalDescription') }}
				</p>
			</div>

			<div class="upgrade-plan-modal__action-buttons">
				<HostingerButton
					:is-disabled="isLoading"
					@click="handleRedirectToUpgradePage"
				>
					{{ t('common.upgradeNow') }}
					<ZyroLoader
						v-if="isLoading"
						class="upgrade-plan-modal__loader"
						size="20px"
						weight="2px"
						color="var(--color-light)"
					/>
				</HostingerButton>
				<HostingerButton
					button-type="text"
					@click="closeModal"
				>
					{{ t('common.cancel') }}
				</HostingerButton>
			</div>
		</ZyroModal>
	</Teleport>
</template>

<style lang="scss" scoped>
.upgrade-plan-modal {
	z-index: $z-index-popup;

	:deep() {
		.modal__content {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	&__icon {
		color: $color-primary;
	}

	&__content {
		text-align: center;
		margin: 24px 0;
	}

	&__content-description {
		margin-top: 8px;
	}

	&__loader {
		margin-left: 8px;
	}

	&__action-buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		width: 100%;

		:deep() {
			.hostinger-button {
				width: 100%;
			}
		}
	}}
</style>
