import { updateElementPositionFromDOM } from '@/components/block-layout/useLayout';
import {
	getBlogListBlock,
	getPageTitleBlock,
} from '@/components/block/blocks';
import {
	PAGE_TYPE_BLOG,
	PAGE_TYPE_BLOG_LIST_SLUG,
} from '@/constants/builderConstants';
import { generateRandomId } from '@/utils/generateRandomId';
import {
	addBlock,
	addBlogPostTemplate,
	addPage,
} from '@/utils/siteDataUtils';
import {
	NAVIGATION_TYPE,
	SiteData,
	SitePage,
} from '@hostinger/builder-schema-validator';
import { ELEMENT_TYPE_TEXT_BOX } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { getBiggestIncrementedString } from '@zyro-inc/site-modules/utils/modifyString';

export const createBlogListBlock = ({
	postColumnCount,
	postsPerPage,
}: {
	postColumnCount?: number,
	postsPerPage?: number,
}): {
	id: string,
	data: any,
} => ({
	id: generateRandomId(),
	data: getBlogListBlock({
		mockCategories: false,
		postColumnCount,
		postsPerPage,
	}),
});

export const addBlogPostsIfNeeded = ({
	siteData,
	locale,
	blogPostsData,
}:{
	siteData: SiteData,
	locale: string,
	blogPostsData: any[],
}): SiteData => {
	const hasBlogPosts = Object.values(siteData.languages[locale].pages)
		.some((page) => page.type === PAGE_TYPE_BLOG);

	if (!hasBlogPosts) {
		return blogPostsData.reduce((acc, blogPostData) => {
			const { siteDataWithBlogPostPage } = addBlogPostTemplate({
				locale,
				siteData: acc,
				isDraft: false,
				...blogPostData,
			});

			return siteDataWithBlogPostPage;
		}, siteData);
	}

	return siteData;
};

export const addBlogListToExistingPage = ({
	siteData,
	locale,
	pageId,
	blockId,
	blockData,
	previousBlockId,
}: {
	siteData: SiteData,
	locale: string,
	pageId: string,
	blockId: string,
	blockData: any,
	previousBlockId?: string,
}): SiteData => addBlock({
	locale,
	siteData,
	pageId,
	blockId,
	blockData,
	previousBlockId,
}) as SiteData;

export const createNewBlogListPage = ({
	siteData,
	locale,
	pageId,
	pageTitle,
	blogListBlock,
	pages,
}:{
	siteData: SiteData,
	locale: string,
	pageId: string,
	pageTitle: string,
	blogListBlock: any,
	pages: Record<string, SitePage>,
}): SiteData => {
	const blogTitleBlockId = generateRandomId();
	const blogTitleBlock = getPageTitleBlock({
		title: pageTitle,
	});

	return addPage({
		locale,
		siteData,
		pageId,
		pageData: {
			name: pageTitle,
			slug: getBiggestIncrementedString({
				stringToMatch: PAGE_TYPE_BLOG_LIST_SLUG,
				strings: Object.values(pages).map(({ slug }) => slug),
			}),
			blocks: [
				blogTitleBlockId,
				blogListBlock.id,
			],
			type: 'default',
		},
		blocks: {
			[blogListBlock.id]: blogListBlock.data,
			[blogTitleBlockId]: blogTitleBlock.blockData,
		},
		elements: {
			...blogTitleBlock.elements,
		},
		navigationItem: {
			linkType: NAVIGATION_TYPE.PAGE,
			subItems: [],
		},
	}) as SiteData;
};

export const updateBlogTextElementDOMPositions = ({
	siteData,
	locale,
	pageId,
	dispatch,
}: {
	siteData: SiteData,
	locale: string,
	pageId: string,
	dispatch: any,
}) => {
	const {
		pages,
		blocks,
		elements,
	} = siteData.languages[locale];
	const pageBlocks = Object.fromEntries(
		Object.entries(blocks)
			.filter(([blockId]) => pages[pageId].blocks?.includes(blockId)),
	);

	Object.entries(pageBlocks).forEach(([blockId, { components }]) => {
		if (!components) return;

		components.forEach((elementId) => {
			const elementType = elements[elementId].type;

			if (elementType === ELEMENT_TYPE_TEXT_BOX) {
				updateElementPositionFromDOM({
					elementId,
					blockId,
					dispatch,
					isMobileMode: false,
				});
			}
		});
	});
};
