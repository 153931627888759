import { useStore } from 'vuex';
import { computed } from 'vue';
import { getZyrositePreviewDomain } from '@/api/PublishApi';
import { useDomainStore } from '@/stores/domainStore';

export const usePreviewDomain = () => {
	const { commit } = useStore();
	const domainStore = useDomainStore();
	const customDomain = computed<string>(() => domainStore.customDomain);

	const setPreviewDomain = async () => {
		const {
			zyrositePreviewDomain,
			previewDomain: domain,
		} = await getZyrositePreviewDomain({
			domain: customDomain.value,
		});

		commit('setZyroDomain', zyrositePreviewDomain);
		commit('setPreviewDomain', domain);
	};

	return {
		setPreviewDomain,
	};
};
