<script setup lang="ts">
// eslint-disable-next-line import/extensions
import 'vanilla-colorful/rgba-string-color-picker.js';

import tinycolor from 'tinycolor2';
import { useWebsiteColors } from '@/use/useWebsiteColors';
import { debounce } from '@zyro-inc/site-modules/utils/debounce';
import { useEyeDropper } from '@vueuse/core';

import {
	computed,
	ref,
	watch,
} from 'vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import { SiteBackgroundGradient } from '@hostinger/builder-schema-validator';
import ZyroRange from '@/components/global/ZyroRange.vue';
import { DEFAULT_COLOR } from '@/constants/builderConstants';

interface GradientColors {
	isActive: boolean;
	value: string;
}

interface Props {
	color?: string;
	gradient?: SiteBackgroundGradient;
	isGradient?: boolean;
	isOpacityDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	color: DEFAULT_COLOR,
	gradient: () => ({
		isAnimated: false,
		angle: 0,
		colors: [
			{
				value: DEFAULT_COLOR,
			},
			{
				value: DEFAULT_COLOR,
			},
		],
	}),
	isOpacityDisabled: false,
});

const emit = defineEmits<{
	'update-color': [color: string],
	'update-gradient': [gradient: SiteBackgroundGradient],
	'toggle-eye-dropper': [value: boolean],
}>();

const { getColorValue } = useWebsiteColors();

const {
	isSupported: isEyeDropperSupported,
	open: openEyeDropper,
} = useEyeDropper();

const gradientColors = ref<GradientColors[]>([
	{
		isActive: true,
		value: props.gradient.colors[0].value || DEFAULT_COLOR,
	},
	{
		isActive: false,
		value: props.gradient.colors[1].value || DEFAULT_COLOR,
	},
]);

const activeGradientColorIndex = computed(() => gradientColors.value.findIndex((color) => color.isActive));
const currentColor = computed(() => {
	const color = props.isGradient ? gradientColors.value[activeGradientColorIndex.value].value : props.color;

	return tinycolor(color).toRgbString();
});

const gradientAngle = ref(props.gradient.angle);

watch(() => gradientAngle.value, (value) => {
	emit('update-gradient', {
		...props.gradient,
		angle: value,
	});
});

watch(() => props.gradient, (gradient) => {
	gradientColors.value = gradient.colors.map(({ value }, index) => ({
		isActive: gradientColors.value[index].isActive,
		value,
	}));
}, {
	deep: true,
});

const toggleGradientColor = (index: number) => {
	gradientColors.value = gradientColors.value.map((color, colorIndex) => ({
		...color,
		isActive: colorIndex === index,
	}));
};

const textInputValue = computed(() => tinycolor(getColorValue(currentColor.value)).toHexString());

const gradientChange = (value: string) => {
	if (props.isGradient) {
		gradientColors.value[activeGradientColorIndex.value].value = value;

		const colors = gradientColors.value.map((color) => ({
			value: getColorValue(color.value),
		}));

		emit('update-gradient', {
			...props.gradient,
			colors,
		});

		return;
	}

	emit('update-color', getColorValue(value));
};

const handleTextInput = (value: string) => {
	const color = tinycolor(value);

	if (!color.isValid()) {
		return;
	}

	gradientChange(value);
};

const handleEyeDropper = async () => {
	emit('toggle-eye-dropper', true);

	try {
		const selectedColor = await openEyeDropper();

		if (!selectedColor) {
			return;
		}

		gradientChange(selectedColor.sRGBHex);
	} catch {
		emit('toggle-eye-dropper', false);
	}

	emit('toggle-eye-dropper', false);
};

const debounceInputChange = debounce((event: InputEvent) => {
	handleTextInput((event.target as HTMLInputElement).value);
}, 1000);
</script>

<template>
	<div class="picker-selection">
		<div v-if="isGradient">
			<p class="text-bold-2">
				{{ $t('builder.gradientColors') }}
			</p>

			<div class="picker-gradient">
				<button
					v-for="(gradientColor, index) in gradientColors"
					:key="index"
					class="picker-gradient__button"
					:class="{ 'picker-gradient__button--active': gradientColor.isActive }"
					@click="toggleGradientColor(index)"
				>
					<span
						class="picker-gradient__color"
						:style="{ backgroundColor: gradientColor.value }"
					/>
					<Icon
						name="edit"
						dimensions="20px"
					/>
				</button>
			</div>
		</div>

		<rgba-string-color-picker
			:color="currentColor"
			@color-changed.stop="gradientChange($event.detail.value)"
		/>

		<input
			ref="textInput"
			v-qa="'color-picker_text-input'"
			:value="textInputValue"
			class="picker-selection__text-input"
			type="text"
			@keydown.enter="handleTextInput(($event.target as HTMLInputElement).value)"
			@blur="handleTextInput(($event.target as HTMLInputElement).value)"
			@input="debounceInputChange"
			@focus="($refs.textInput as HTMLInputElement).select()"
		>

		<HostingerButton
			v-if="isEyeDropperSupported"
			button-type="outlined"
			class="picker-selection__pick-color-button"
			@click="handleEyeDropper"
		>
			<Icon name="colorize" />
			{{ $t('common.pickColor') }}
		</HostingerButton>

		<div
			v-if="isGradient"
			v-qa="'color-picker_gradient-slider'"
			class="gradient-slider"
		>
			<p class="text-bold-2">
				{{ $t('builder.gradientAngle') }}
			</p>

			<ZyroRange
				v-model="gradientAngle"
				v-qa="'color-picker_gradient-slider'"
				has-number-input
				:min="0"
				:max="360"
				:step="1"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.gradient-slider {
	margin-top: 12px;
	padding-top: 12px;
	border-top: 1px solid var(--color-gray-border);
}

.picker-selection {
	width: 252px;
	background-color: $color-light;
	border-radius: $border-radius-small;
	box-shadow: $box-shadow;
	padding: 16px;

	&__pick-color-button {
		display: flex;
		margin-top: 8px;
		width: 100%;

		:deep(.hostinger-button__text) {
			gap: 4px;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px;
	}

	&__edit {
		font-size: 14px;
		font-weight: 700;
		line-height: 1;
		color: $color-azure;
		cursor: pointer;
	}

	&__colors {
		display: grid;
		grid-template-columns: repeat(8, 24px);
		grid-column-gap: 4px;
		margin-bottom: 16px;
	}

	&__color {
		width: 24px;
		height: 24px;
		margin-bottom: 4px;
		cursor: pointer;
		border: 1px solid rgba(221, 225, 230, 80%);
		border-radius: 50%;

		&:hover {
			border: none;
			border: 2px solid $color-azure;
		}

		&.active {
			border: 2px solid $color-light;
			outline: 2px solid $color-azure;
		}
	}

	&__text-input {
		width: 100%;
		padding: 8px 0;
		margin-top: 12px;
		font-family: Roboto, sans-serif;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		letter-spacing: 0.25px;
		border: 1px solid $color-gray-border;
		border-radius: 8px;
		outline: none;

		&:hover,
		&:focus {
			border: 1px solid var(--color-primary);
		}
	}

	&__eye-dropper-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 6px 12px;
		margin-bottom: 8px;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.43;
		color: $color-azure;
		cursor: pointer;
		border: 1px solid $color-gray-border;
		border-radius: 6px;
		transition: border 0.2s;

		&:hover {
			border: 1px solid $color-azure;
		}
	}

	&__eye-dropper-icon {
		width: 16px;
		height: 16px;
		margin-right: 4px;
	}

	&__apply-button {
		width: 100%;
		padding: 6px;
		margin-top: 16px;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.43;
		color: $color-light;
		cursor: pointer;
		background-color: $color-azure;
		border-radius: 6px;
	}
}

.picker-gradient {
	display: flex;
	gap: 4px;
	margin: 8px 0 12px;

	&__button {
		width: 50%;
		border: 1px solid var(--color-gray-border);
		padding: 4px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		gap: 4px;
		cursor: pointer;

		&--active {
			border: 2px solid var(--color-primary);
			padding: 3px;
		}
	}

	&__color {
		border-radius: 4px;
		flex-grow: 1;
		height: 24px;
		border: 1px solid var(--color-gray-border);
	}
}

rgba-string-color-picker {
	width: 100%;
	border-radius: 6px;

	&::part(saturation) {
		margin-bottom: 12px;
		border: none;
		border-radius: 6px;
		box-shadow: none;
	}

	&::part(hue) {
		flex: 0 0 14px;
		margin-bottom: 12px;
		border-radius: 6px;
	}

	&::part(alpha) {
		flex: 0 0 14px;
		border-radius: 6px;
	}

	&::part(hue-pointer),
	&::part(saturation-pointer),
	&::part(alpha-pointer) {
		width: 20px;
		height: 20px;
		cursor: pointer;
		border: 3px solid rgb(255, 255, 255);
		box-shadow: 0 0 0 1px $color-gray-border;

		&::after {
			height: auto;
		}
	}
}
</style>
