<script setup lang="ts">
import ZyroModal from '@/components/global/ZyroModal.vue';
import { CLOSE_MODAL } from '@/store/builder/gui';
import { MODAL_PRODUCT_PAGE_TEMPLATE } from '@/constants/builderConstants';
import { useStore } from 'vuex';
import HImage from '@/components/global/HImage.vue';
import { computed } from 'vue';

const {
	state,
	dispatch,
} = useStore();

const isMobileScreen = computed(() => state.gui.isMobileScreen);

const closeModal = () => {
	dispatch(`gui/${CLOSE_MODAL}`, {
		name: MODAL_PRODUCT_PAGE_TEMPLATE,
	});
};
</script>

<template>
	<ZyroModal
		class="product-page-modal"
		:max-width="isMobileScreen ? '360px' : '540px'"
		height="auto"
		:title="$t('builder.productPageTemplateModalTitle')"
		:header-padding="isMobileScreen ? '24px 24px 8px' : '32px 32px 16px'"
		:content-padding="isMobileScreen ? '0 24px 24px' : '0 32px 32px'"
		title-class="text-heading-2"
		@close-modal="closeModal"
	>
		<p class="text-body-2">
			{{ $t('builder.productPageTemplateModalText') }}
		</p>
		<HImage
			class="product-page-modal__image"
			src="/images/product-template-example.png"
		/>
	</ZyroModal>
</template>

<style scoped lang="scss">
.product-page-modal {
	:deep(.modal__title) {
		@media screen and (max-width: $media-mobile) {
			max-width: 285px;
		}
	}

	&__image {
		margin-top: 16px;
		width: 100%;
	}
}
</style>
