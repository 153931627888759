import {
	ref,
	onUnmounted,
} from 'vue';

const touchX = ref<number | null>(null);
const touchY = ref<number | null>(null);

export const useTouchPosition = () => {
	const handleTouchEvent = (event: TouchEvent) => {
		const firstTouch = event.touches[0];

		touchX.value = firstTouch.clientX;
		touchY.value = firstTouch.clientY;
	};

	document.addEventListener('touchmove', handleTouchEvent);

	onUnmounted(() => {
		document.removeEventListener('touchmove', handleTouchEvent);
	});

	return {
		touchX,
		touchY,
		handleTouchEvent,
	};
};
