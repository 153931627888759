<template>
	<div class="code-editor">
		<ZyroTextArea
			ref="codeEditorInput"
			autofocus
			class="code-editor__text-area"
			:min-height="262"
			:model-value="props.modelValue"
			@update:model-value="emit('update:model-value', $event)"
		/>
	</div>
</template>

<script setup>
import {
	ref,
	onMounted,
} from 'vue';
import ZyroTextArea from '@/components/global/ZyroTextArea.vue';

const props = defineProps({
	modelValue: {
		type: String,
		default: '',
	},
});

const emit = defineEmits(['update:model-value']);
const codeEditorInput = ref(null);

onMounted(() => {
	const element = codeEditorInput.value.$el;

	element.focus(({
		preventScroll: true,
	}));

	if (element.setSelectionRange) {
		element.setSelectionRange(element.value.length, element.value.length);
	}
});
</script>

<style lang="scss">
.code-editor {
	user-select: auto;
	overflow: auto;
	margin-bottom: 8px;

	&__text-area {
		max-height: 460px;
		resize: vertical;
	}
}
</style>
