<template>
	<HostingerButton
		class="toggle-control"
		:class="{ 'toggle-control--enabled': isStyleEnabled }"
		button-type="plain"
		theme="highlight"
		@click="toggleStyle"
	>
		<template #icon>
			<Icon
				:name="toggleValue"
				dimensions="20px"
				is-custom
			/>
		</template>
	</HostingerButton>
</template>

<script>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';

import { mapGetters } from 'vuex';

import { defineComponent } from 'vue';
import { useSiteStore } from '@/stores/siteStore';

const DEFAULT_TOGGLE_VALUE = 'none';

export default defineComponent({
	name: 'StyleToggleControl',

	components: {
		Icon,
		HostingerButton,
	},

	props: {
		element: {
			type: String,
			required: true,
		},
		property: {
			type: String,
			required: true,
		},
		toggleValue: {
			type: String,
			required: true,
		},
	},

	setup() {
		const siteStore = useSiteStore();

		return {
			siteStore,
		};
	},

	computed: {
		...mapGetters(['siteStyles']),
		isStyleEnabled() {
			return Boolean(this.siteStyles[this.element][this.property] !== DEFAULT_TOGGLE_VALUE);
		},
	},

	methods: {
		toggleStyle() {
			if (!this.isStyleEnabled || this.siteStyles[this.element][this.property] === undefined) {
				this.setStylePropertyValue(this.toggleValue);

				return;
			}

			this.setStylePropertyValue(DEFAULT_TOGGLE_VALUE);
		},
		setStylePropertyValue(value) {
			this.siteStore.setSiteStyleProperty({
				element: this.element,
				property: this.property,
				value,
			});
		},
	},
});
</script>

<style lang="scss" scoped>
.toggle-control {
	width: 36px;
	height: 36px;
	cursor: pointer;

	&--enabled {
		background-color: $color-gray-light;
	}
}
</style>
