<template>
	<div ref="connectionStatusContainerRef">
		<HostingerButton
			button-type="plain"
			class="connection-status__trigger"
			:class="{ 'connection-status__trigger--disabled': !isSitePublished || isCustomDomainPreview, }"
			@mouseenter="setIsHeaderDomainConnectionStatusExpanded(true)"
			@mouseleave="setIsHeaderDomainConnectionStatusExpanded(false)"
		>
			<ZyroLoader
				v-if="domainStore.isStatusLoading"
				size="14px"
			/>
			<div
				v-else
				class="connection-status__status"
			>
				<div
					v-if="!isCustomDomainPreview"
					class="connection-status__status-dot"
					:class="`connection-status__status-dot--${statusTheme}`"
				/>
				<a
					v-qa="'header-connected-domain'"
					class="connection-status__domain-text text-bold-2"
					:class="{
						'connection-status__domain-text--disabled': !isSitePublished || isCustomDomainPreview,
						'text-bold-2': isCustomDomainPreview,
						'text-body-2': !isCustomDomainPreview,
					}"
					:href="`https://${customDomain}`"
					target="_blank"
				>
					{{ isCustomDomainPreview ? $t('builder.connectDomainMessage') : customDomain }}
				</a>
			</div>
		</HostingerButton>

		<Popup
			v-if="!isMobileScreen && isHeaderDomainConnectionStatusExpanded"
			placement="bottom"
			:offset="0"
			:target-ref="(connectionStatusContainerRef as HTMLElement)"
		>
			<div
				class="connection-status__hover-area"
				@mouseenter="setIsHeaderDomainConnectionStatusExpanded(true)"
				@mouseleave="setIsHeaderDomainConnectionStatusExpanded(false)"
			>
				<div
					v-if="domainStatusConfig"
					class="connection-status-content"
				>
					<div
						v-if="domainStore.isStatusLoading"
						class="connection-status-content__loader"
					>
						<ZyroLoader
							color="var(--color-primary)"
							secondary-color="var(--color-primary-light)"
							size="40px"
						/>
					</div>
					<template v-if="isCustomDomainPreview">
						<div class="connection-status-content__container">
							<div class="text-bold-2 connection-status-content__title">
								{{ $t('common.connectDomainV2') }}
							</div>
							<div class="text-body-2">
								{{ $t('builder.improveDiscoverabilityV2') }}
							</div>
						</div>
					</template>
					<template v-else>
						<a
							v-qa="'domain-card-connected-domain'"
							class="connection-status-content__domain-text domain-text text-bold-2"
							:class="{
								'connection-status__domain-text--disabled': !isSitePublished,
								'connection-status-content__title': isCustomDomainPreview,
							}"
							:href="`https://${customDomain}`"
							target="_blank"
						>
							<span class="connection-status-content__domain-text-name">
								{{ customDomain }}
							</span>
							<Icon
								v-if="isSitePublished"
								class="connection-status-content__domain-text-icon"
								name="open_in_new"
								dimensions="16px"
							/>
						</a>
						<ZyroPill
							v-if="!isCustomDomainPreview"
							v-qa="`domain-card-status-${domainStatusConfig.statusText}`"
							class="connection-status-content__status-pill"
							:text="domainStatusConfig.statusText"
							:theme="statusTheme"
							text-class="text-bold-3"
						/>
						<ZyroSeparator class="connection-status-content__separator" />
					</template>

					<template v-if="isDomainPropagating">
						<div class="connection-status-content__container">
							<div class="text-body-2">
								{{ $t('builder.domainPropagationMessage') }}
							</div>
						</div>
					</template>

					<HostingerButton
						class="connection-status-content__action-button"
						:button-type="domainStatusConfig.actionButtonType"
						@click="domainStatusConfig.action"
					>
						{{ domainStatusConfig.actionButtonText }}
					</HostingerButton>

					<template v-if="isCustomDomainPreview">
						<ZyroSeparator class="connection-status-content__separator" />
						<div class="connection-status-content__temporary-domain-box">
							<h3 class="text-bold-2">
								{{ $t('builder.temporaryDomainMessage') }}
							</h3>
							<p class="connection-status-content__temporary-domain-text text-body-2">
								<a
									class="connection-status-content__domain-text-temporary"
									:class="{ 'connection-status__domain-text--disabled': !isSitePublished }"
									:href="`https://${customDomain}`"
									target="_blank"
								>
									<span class="connection-status-content__domain-text-name">
										{{ customDomain }}
									</span>
									<Icon
										v-if="isSitePublished"
										class="connection-status-content__domain-text-icon"
										name="open_in_new"
										dimensions="16px"
									/>
								</a>
							</p>
						</div>
					</template>
				</div>
			</div>
		</Popup>
	</div>
</template>

<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';

import {
	onBeforeMount,
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import { useDomainStore } from '@/stores/domainStore';

import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';

import { useHeaderPopups } from '@/use/useHeaderPopups';
import { useDomainStatus } from '@/use/useDomainStatus';
import ZyroPill from '@/components/global/ZyroPill.vue';
import { STATUS_THEMES } from '@/types/sharedPropTypes';
import type { StatusThemes } from '@/types/sharedPropTypes';
import Icon from '@/components/global/Icon.vue';

import Popup from '@/components/global/Popup.vue';

const {
	getters,
	state,
} = useStore();
const domainStore = useDomainStore();

const customDomain = computed(() => domainStore.customDomain);
const isDomainPropagating = computed(() => domainStore.isDomainPropagating);
const isSitePublished = computed(() => getters.isSitePublished);
const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isCustomDomainPreview = computed(() => getters.isCustomDomainPreview);
const connectionStatusContainerRef = ref<HTMLElement | null>(null);

const {
	isHeaderDomainConnectionStatusExpanded,
	setIsHeaderDomainConnectionStatusExpanded,
} = useHeaderPopups();

const { domainStatusConfig } = useDomainStatus();

const statusTheme = computed<StatusThemes>(() => domainStatusConfig.value?.statusTheme || STATUS_THEMES.WARNING);

onBeforeMount(() => {
	domainStore.fetchDomainStatus();
});

</script>

<style lang="scss" scoped>
$popup-width: 360px;

@mixin hidden-text-overflow($max-width) {
	max-width: $max-width;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.connection-status {
	position: relative;
	display: flex;
	align-items: center;
	color: $color-gray;
	height: $header-height-editor;

	&__trigger {
		margin: 0 2px;

		&--disabled {
			cursor: default;
		}
	}

	&__status {
		display: flex;
		gap: 8px;
		align-items: center;
		user-select: none;
	}

	&__status-dot {
		width: 16px;
		margin: 4px;
		height: 16px;
		border-radius: 50%;

		&--success {
			background-color: $color-success;
		}

		&--warning {
			background-color: $color-warning;
		}

		&--danger {
			background-color: $color-danger;
		}

		&--inactive {
			background-color: $color-danger;
		}
	}

	&__domain-text {
		@include hidden-text-overflow(200px);

		text-decoration: none;

		&--disabled {
			cursor: default;
			pointer-events: none;
		}
	}

	&__hover-area {
		// center content popup under the trigger
		width: $popup-width;
		padding-top: 8px;
	}
}

.connection-status-content {
	$content-padding: 16px;

	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	padding: $content-padding;
	overflow: hidden;
	background: $color-light;
	border: 1px solid $color-gray-border;
	border-radius: 16px;
	box-shadow: $box-shadow-base;

	&__container {
		margin-bottom: 16px;
	}

	&__status-pill {
		margin-top: 8px;
	}

	&__banner {
		width: 100%;
		margin-top: 16px;
	}

	&__temporary-domain-box {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 4px;
		padding: 12px $content-padding;
		max-width: 100%;
		background: var(--color-gray-light);
		border-radius: 8px;
	}

	&__temporary-domain-text {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&__title {
		margin-bottom: 4px
	}

	&__separator {
		margin: 16px 0;
	}

	&__domain-text {
		@include hidden-text-overflow($popup-width - 2 * $content-padding);

		text-decoration: none;
		display: inline-flex;
		align-items: center;
		gap: 8px;
	}

	&__domain-text-temporary {
		@include hidden-text-overflow($popup-width - 2 * ($content-padding * 2));

		text-decoration: none;
		display: inline-flex;
		align-items: center;
		gap: 8px;
	}

	&__domain-text-name {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__domain-text-icon {
		flex-shrink: 0;
		color: var(--color-gray)
	}

	&__loader {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 40%);
	}

	&__notification {
		margin: 16px 0 0;
	}

	&__notification-text {
		margin-bottom: 8px;
	}

	&__refresh-text,
	&__refresh-preview,
	&__refresh-button {
		color: $color-gray;
	}

	&__refresh-preview {
		margin-top: 8px;
	}

	&__refresh-button {
		margin-top: 8px;
		text-decoration: underline;
		cursor: pointer;

		&--disabled {
			color: $color-gray;
			pointer-events: none;
		}
	}
}
</style>
