import {
	COOKIE_SETUP_PAYMENT_NOTIFICATION_CLOSED_AT,
	LOCAL_STORAGE_KEY_IS_PRODUCT_TEMPLATE_NOTIFICATION_SHOWN,
} from '@/constants/builderConstants';
import {
	SitePages,
	SiteEcommerceSortingValue,
} from '@hostinger/builder-schema-validator';
import { extractText } from '@/utils/extractText';
import { generatePageSlug } from '@/utils/generatePageSlug';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';

export const getSetupPaymentNotificationClosedAtCookieName = (storeId: string) => `${
	COOKIE_SETUP_PAYMENT_NOTIFICATION_CLOSED_AT}/${storeId}`;

export const getSortedProductsKey = (sort = '', collectionId = '') => `${sort}${collectionId}` as SiteEcommerceSortingValue;

export const setIsProductTemplateNotificationShownLocalStorage = (websiteId: string) => {
	localStorage.setItem(`${LOCAL_STORAGE_KEY_IS_PRODUCT_TEMPLATE_NOTIFICATION_SHOWN}-${websiteId}`, '1');
};

export const getIsProductTemplateNotificationShowLocalStorage = (websiteId: string) => localStorage
	.getItem(`${LOCAL_STORAGE_KEY_IS_PRODUCT_TEMPLATE_NOTIFICATION_SHOWN}-${websiteId}`);

export const getIsProductTemplateNotificationShown = (isDynamicPageFlowEnabled: boolean, websiteId: string) => {
	const isProductNotificationsShown = getIsProductTemplateNotificationShowLocalStorage(websiteId);

	return !isProductNotificationsShown && isDynamicPageFlowEnabled;
};

export const getShouldSkipProductsFetch = ({
	sortType,
	collectionId,
	sortedProductsFromStore,
	allProducts,
	totalProductCount,
}: {
	sortType?: SiteEcommerceSortingValue,
	collectionId?: string,
	sortedProductsFromStore?: EcommerceProduct[],
	allProducts: EcommerceProduct[],
	totalProductCount: number,
}) => {
	if (sortType || collectionId) {
		return sortedProductsFromStore?.length && sortedProductsFromStore?.length === totalProductCount;
	}

	return totalProductCount !== 0 && allProducts?.length === totalProductCount;
};

export const fillSeoSettingsForProducts = ({
	products,
	sitePages,
}: {products: Array<EcommerceProduct>, sitePages:SitePages}): Array<EcommerceProduct> => {
	const existingPageSlugs: Array<string> = Object.values(sitePages)
		.flatMap((page) => (page.slug ? [page.slug] : []));
	const existingProductSlugs: Array<string> = [];

	const ecommerceProductsWithUpdatedSeo = products.map((product: EcommerceProduct) => {
		const parsedDescriptionHtml = new DOMParser().parseFromString(product.description, 'text/html');
		const description = parsedDescriptionHtml ? extractText(parsedDescriptionHtml) : '';

		const productSlug = generatePageSlug({
			initialSlug: product.title,
			existingSlugs: [
				...existingProductSlugs,
				...existingPageSlugs,
			],
			slugSuffix: '-product',
		});

		existingProductSlugs.push(productSlug);

		const updatedProduct = {
			...product,
			seo_settings: {
				...(product.thumbnail ? {
					ogImagePath: product.thumbnail,
				} : {}),
				ogImageOrigin: 'other',
				ogImageAlt: product.title,
				description,
				slug: productSlug,
				title: product.title,
			},
		};

		return updatedProduct;
	});

	return ecommerceProductsWithUpdatedSeo;
};
