// TODO: once this file is converted to TypeScript delete `ExtendedUseAiTemplateType` from AiBuilder.vue
/* eslint-disable import/no-cycle */
import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import {
	captureException,
	addBreadcrumb,
} from '@sentry/vue';
import { useNotifications } from '@/use/useNotifications';
import { useI18n } from 'vue-i18n';
import { useAiBuilderForm } from '@/components/ai-builder/useAiBuilderForm';
import EventLogApi from '@/api/EventLogApi';
import {
	THEME_SUCCESS,
	SYSTEM_LOCALE,
	THEME_LOGO,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { useRedirects } from '@/use/useRedirects';
import {
	AI_BUILDER_MAXIMUM_LOGO_CHARACTER_LENGTH,
	AI_BUILDER_LOGO_SIZE,
	AI_BUILDER_DEFAULT_LOGO_WIDTH,
	AI_BUILDER_DEFAULT_LOGO_HEIGHT,
	LOCAL_STORAGE_KEY_AI_BUILDER_FREE_TRY_WEBSITES,
	AI_BUILDER_FAILED_GENERATION_BEFORE_REDIRECT_COUNT,
	LOCAL_STORAGE_KEY_AI_PREVIEW_ENABLED,
	TYPE_BLOCK_BACKGROUND,
	HPANEL_REDIRECTS_PATHS,
	AI_BUILDER_CATEGORY_ID_STORE,
	FLOW_QUERY_CART,
} from '@/constants/builderConstants';
import { useSiteTextElementPreview } from '@/use/useSiteTextElementPreview';
import { generateSite } from '@/api/AiApi';
import {
	useRoute,
	useRouter,
} from 'vue-router';
import { useTextToSvg } from '@/use/useTextToSvg';
import { useAssets } from '@/use/useAssets';
import { useAiTemplateGenerator } from '@/use/useAiTemplateGenerator';
import { useUserAuthorizationState } from '@/use/useUserAuthorizationState';
import { useUserStore } from '@/stores/userStore';
import { useResourcesStore } from '@/stores/resourcesStore';

import { removeTemplateLogoSvg } from '@/utils/aiTemplateEditingHelpers';

import { LOGO_SVG_DEFAULT } from '@/data/builderData';
import {
	AI_PREVIEW_ROUTE,
	TEMPLATES_ROUTE,
} from '@/constants/routes';
import googleFonts from '@zyro-inc/site-modules/data/mostPopularGoogleFonts.json';
import { useSiteStore } from '@/stores/siteStore';
import { addGoogleFontQueryLinks } from '@/utils/injectableDomElements/addGoogleFontQueryLinks';
import { useRouteQuery } from '@vueuse/router';
import { useAiBuilderPreview } from '@/use/useAiBuilderPreview';
import { useAiBuilderPreviewStore } from '@/stores/aiBuilderPreviewStore';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import {
	FEATURE_FLAG_IDS,
	getIsExperimentActive,
} from '@/utils/experiments';

const FONT_FALLBACK = "'Roboto', sans-serif";

const currentTemplateIndex = ref(0);
const isSiteBeingGenerated = ref(false);
const aiGeneratedTemplates = ref([]);
const isGoingToBuilder = ref(false);
const currentAiGeneratedTemplate = computed(() => aiGeneratedTemplates.value[currentTemplateIndex.value]);
const isLoading = computed(() => isSiteBeingGenerated.value || isGoingToBuilder.value);
const hasGenerationFailed = ref(false);
const templateBrandName = ref('');
const aiTemplateData = ref(null);
const aiTranslations = ref(null);
const sectionBasedGenerationSiteData = ref(null);
const isCustomColorPickerVisible = ref(false);
const customColors = ref({
	color1: '',
	color2: '',
	color3: '',
});

export const triggerWebsiteSavedNotification = (dispatch) => {
	const { redirectToHPanel } = useRedirects();

	dispatch('notifications/notify', {
		theme: THEME_SUCCESS,
		headingI18nKeyPath: 'builder.aiBuilderWebsiteSaved',
		messageI18nKeyPath: 'builder.aiBuilderAccessWebsiteList',
		submitLabelI18nKeyPath: 'builder.goToWebsiteList',
		isDiscardButtonShown: false,
		submitButtonStyles: {
			buttonType: 'text',
			theme: 'primary',
		},
		submitCallback: () => {
			redirectToHPanel({
				path: 'websites',
			});
		},
		isAutoCloseDisabled: false,
	});
};

export const resetIsGoingToBuilder = () => {
	isGoingToBuilder.value = false;
};

export const useAiTemplate = () => {
	const {
		state,
		dispatch,
		getters,
	} = useStore();
	const { recalculateWebsiteTextHeights } = useSiteTextElementPreview();
	const { redirectToHPanel } = useRedirects();
	const {
		isUserWithActivePlan,
		isUserPayToPublish,
		isOnlyOneStarterPlanUser,
		getHostingReferenceIdForSiteCreation,
	} = useUserAuthorizationState();
	const route = useRoute();
	const router = useRouter();
	const {
		brandName: formBrandName,
		brandDescription: formBrandDescription,
		websiteType,
		AI_BUILDER_CATEGORIES,
		selectedColors,
		selectedHostingReferenceId,
		setWebsiteType,
	} = useAiBuilderForm();

	const { t } = useI18n();
	const { notify } = useNotifications();
	const { getSvg } = useTextToSvg();
	const {
		uploadSvgAsset,
		assets,
		assetsToDelete,
		deleteMediaAsset,
	} = useAssets();
	const { getPredefinedTemplatesWithAiData } = useAiTemplateGenerator();
	const { saveGeneratedSite } = useAiBuilderPreview();
	const aiBuilderPreviewStore = useAiBuilderPreviewStore();
	const userStore = useUserStore();
	const siteStore = useSiteStore();
	const resourcesStore = useResourcesStore();
	const domainQuery = useRouteQuery('domain');
	const ecommerceStore = useEcommerceStore();

	const siteId = ref(route.query.siteId);
	const failedSiteGenerationsBeforeRedirect = ref(0);

	const website = computed(() => siteStore.site);
	const areFeaturesLocked = computed(() => userStore.areFeaturesLocked);
	const isChangingTemplate = ref(route.query.isChangingTemplate);
	const oldLogoAssetData = computed(() => assets.value.find((asset) => asset.url?.includes?.('logo')));
	const sitePages = computed(() => getters.sitePages);
	const siteBlocks = computed(() => getters.siteBlocks);
	const siteElements = computed(() => getters.siteElements);
	const siteNav = computed(() => getters.siteNav);
	const sitePrimaryFont = computed(() => getters.siteFonts?.primary || FONT_FALLBACK);
	const shouldGenerateLogo = computed(() => templateBrandName.value.trim().length < AI_BUILDER_MAXIMUM_LOGO_CHARACTER_LENGTH);

	const setCustomColors = (colors) => {
		customColors.value = colors;
	};

	const deleteGeneratedTemplates = () => {
		// isGoingToBuilder is used to indicate loading state. Setting to false to prevent empty screen
		// when backing from AI generated site preview
		isGoingToBuilder.value = false;

		aiGeneratedTemplates.value = [];
		dispatch('updateCurrentPageId');
		window.localStorage.removeItem(LOCAL_STORAGE_KEY_AI_BUILDER_FREE_TRY_WEBSITES);
	};

	const updateCurrentTemplateDataWithChangesFromVuex = () => {
		aiGeneratedTemplates.value[currentTemplateIndex.value] = website.value;
	};

	const saveGeneratedTemplates = () => {
		if (!aiGeneratedTemplates.value.length) {
			return;
		}

		updateCurrentTemplateDataWithChangesFromVuex();

		const generatedSitesData = {
			aiGeneratedTemplates: aiGeneratedTemplates.value,
			ecommerceProducts: sectionBasedGenerationSiteData.value.ecommerceProducts,
			brandName: formBrandName.value,
			brandDescription: formBrandDescription.value,
			websiteType: websiteType.value,
			currentTemplateIndex: currentTemplateIndex.value,
		};

		window.localStorage.setItem(LOCAL_STORAGE_KEY_AI_BUILDER_FREE_TRY_WEBSITES, JSON.stringify(generatedSitesData));
	};

	const getGeneratedSite = async ({
		brandName,
		brandDescription,
		fonts,
	}) => {
		templateBrandName.value = brandName;
		isSiteBeingGenerated.value = true;
		hasGenerationFailed.value = false;

		try {
			const {
				txtRecord,
				hostingReferenceId,
				flow,
			} = route.query;

			const isDirectCartRedirect = flow === FLOW_QUERY_CART;
			const referenceId = isDirectCartRedirect ? hostingReferenceId : getHostingReferenceIdForSiteCreation(hostingReferenceId);

			aiBuilderPreviewStore.setLastAiGenerationData({
				brandName,
				websiteDescription: brandDescription,
				websiteType: AI_BUILDER_CATEGORIES[websiteType.value].amplitudeName,
				...(selectedColors.value.color1 && {
					selectedColors: selectedColors.value,
				}),
			});

			const { data } = await generateSite({
				brandName,
				brandDescription,
				fonts,
				domain: domainQuery.value,
				txtRecord,
				...(siteId.value && {
					siteId: siteId.value,
				}),
				...(selectedColors.value.color1 && {
					colors: selectedColors.value,
				}),
				...(websiteType.value && {
					websiteType: AI_BUILDER_CATEGORIES[websiteType.value].amplitudeName,
				}),
				...(!siteId.value && {
					hostingReferenceId: referenceId,
				}),
				...(getIsExperimentActive(FEATURE_FLAG_IDS.AI_BUILDER_NEW_IMAGE_SELECTION) && {
					isAiImageExperimentActive: true,
				}),
			});

			if (data.siteId) {
				siteId.value = data.siteId;

				await dispatch('assets/fetchAssets', data.siteId);
			}

			return data;
		} catch (error) {
			captureException(error, {
				tags: {
					errorType: 'AI Builder generation error',
				},
			});

			if (failedSiteGenerationsBeforeRedirect.value >= AI_BUILDER_FAILED_GENERATION_BEFORE_REDIRECT_COUNT) {
				notify({
					messageI18nKeyPath: 'builder.aiFailedGenerationRedirectToastMessage',
					headingI18nKeyPath: 'builder.aiFailedGenerationRedirectToastHeading',
					submitLabelI18nKeyPath: 'builder.aiFailedGenerationRedirectCTA',
					theme: THEME_LOGO,
					isDiscardButtonShown: false,
					submitCallback: () => {
						router.push({
							name: TEMPLATES_ROUTE,
							query: {
								...route.query,
								...(siteId.value ? {
									siteId: siteId.value,
								} : {}),
							},
						});
					},
				});
			} else if (error.response?.status === 422) {
				notify({
					message: t('builder.aiBuilderInvalidWebsiteDescription'),
				});
			} else if (error.response?.data?.message === 'Website limit reached' && error.response?.data?.error === 400) {
				notify({
					message: t('builder.aiBuilderWebsiteLimitReached'),
					submitLabelI18nKeyPath: 'siteSettings.myWebsites',
					submitCallback: () => redirectToHPanel({
						path: HPANEL_REDIRECTS_PATHS.WEBSITES,
					}),
					isDiscardButtonShown: false,
				});
			} else {
				notify({
					message: t('builder.aiBuilderGenerationFailed'),
				});
			}

			isSiteBeingGenerated.value = false;
			hasGenerationFailed.value = true;

			deleteGeneratedTemplates();

			if (isUserWithActivePlan.value) {
				failedSiteGenerationsBeforeRedirect.value += 1;
			}

			return null;
		} finally {
			// Remove domain query param from URL
			// Prevents error that occurs when user presses back button after going to builder
			// and then tries to generate another site under same domain
			const currentUrl = new URL(window.location.href);

			domainQuery.value = null;

			currentUrl.searchParams.delete('domain');
			window.history.replaceState({}, '', currentUrl);
		}
	};

	const uploadTemplateLogo = async ({ websiteData }) => {
		const { logoSvg } = websiteData.languages[SYSTEM_LOCALE].blocks.header.settings;

		const logoFile = new File([logoSvg], 'ai-logo.svg', {
			type: 'image/svg+xml',
		});

		try {
			const uploadedSvgAsset = await uploadSvgAsset({
				file: logoFile,
			});

			await dispatch('updateBlockData', {
				blockId: 'header',
				blockData: {
					settings: {
						logoImagePath: uploadedSvgAsset.path,
						logoImageOrigin: 'assets',
					},
				},
				merge: true,
			});
		} catch (error) {
			captureException(error);
			notify({
				message: t('builder.templateLogoUploadFailed'),
			});
		}

		const websiteDataWithoutSvgLogo = removeTemplateLogoSvg({
			templateData: website.value,
		});

		await dispatch('overwriteWebsiteData', {
			websiteData: websiteDataWithoutSvgLogo,
			websiteId: siteId.value,
		});
	};

	const removeOldLogoAsset = async () => {
		if (!oldLogoAssetData.value) {
			return;
		}

		assetsToDelete.value = [
			{
				...oldLogoAssetData.value,
			},
		];

		await deleteMediaAsset();

		assetsToDelete.value = [];
	};

	const createWebsite = async ({
		clientTimestamp,
		hasOnlineStore,
	} = {}) => {
		const parallelPromises = [
			removeOldLogoAsset(),
			dispatch('saving/updateClientTimestamp', clientTimestamp),
			uploadTemplateLogo({
				websiteData: website.value,
			}),
		];

		await Promise.all(parallelPromises);

		if (hasOnlineStore) {
			await ecommerceStore.fetchInitialEcommerceData({
				shouldAwaitPageCreation: true,
			});
		}

		await dispatch('saving/saveWebsite', {
			saveWhenImpersonating: !isChangingTemplate.value,
			isTimerStarted: false,
		});
	};

	const continueToBuilder = async ({
		hasOnlineStore,
		clientTimestamp,
	} = {}) => {
		// isGoingToBuilder triggers isLoading state. Loading state controls form visibility.
		// Setting to true to prevent AI Builder's form from showing right after site generation
		isGoingToBuilder.value = true;

		await createWebsite({
			hasOnlineStore,
			clientTimestamp,
		});

		dispatch('updateCurrentPageId');

		if (!state.user?.user?.id) {
			await dispatch('user/getUser', {
				force: true,
			});
			await resourcesStore.fetchResources();
			await dispatch('initHResourcesData');
		}

		localStorage.setItem(LOCAL_STORAGE_KEY_AI_PREVIEW_ENABLED, true);

		router.push({
			name: AI_PREVIEW_ROUTE,
			params: {
				siteId: route.query.siteId || siteId.value,
				...(route.query.location ? {
					location: route.query.location,
				} : {}),
			},
		});
		aiGeneratedTemplates.value = [];
	};

	const generateTemplates = async ({
		brandName,
		brandDescription,
	}) => {
		const fonts = googleFonts.items.map((font) => font.family);

		const generatedSiteData = await getGeneratedSite({
			brandName,
			brandDescription,
			fonts,
		});

		addBreadcrumb({
			category: 'SITE_GENERATE',
			message: 'right after AI site generation',
			data: {
				googleFonts: fonts,
				brandName,
				brandDescription,
				generatedFonts: generatedSiteData?.siteData?.fonts,
				generatedStylesFont: generatedSiteData?.siteData?.styles?.font,
			},
		});

		if (!generatedSiteData) {
			isSiteBeingGenerated.value = false;

			return;
		}

		sectionBasedGenerationSiteData.value = generatedSiteData;

		const logoSvg = shouldGenerateLogo.value
			? await getSvg({
				text: brandName,
				fontFamily: generatedSiteData.siteData.fonts[0].family || sitePrimaryFont.value,
				fontSize: AI_BUILDER_LOGO_SIZE,
			})
			: {
				svg: LOGO_SVG_DEFAULT,
				height: AI_BUILDER_DEFAULT_LOGO_HEIGHT,
				width: AI_BUILDER_DEFAULT_LOGO_WIDTH,
			};

		aiGeneratedTemplates.value = getPredefinedTemplatesWithAiData({
			sectionsWithAiData: aiTemplateData.value,
			logoSvg,
			sectionBasedGenerationSiteData: sectionBasedGenerationSiteData.value,
		});

		dispatch('overwriteWebsiteData', {
			websiteData: aiGeneratedTemplates.value[currentTemplateIndex.value],
			websiteId: siteId.value,
		});

		const fontFamiliesToAwait = generatedSiteData.siteData.fonts.map((font) => font.family);

		if (getters['fonts/getMetaFont']) {
			addGoogleFontQueryLinks(getters['fonts/getMetaFont']);
		}

		await recalculateWebsiteTextHeights({
			fontFamiliesToAwait,
		});

		await dispatch('gui/updatePreviewSiteData', {
			...website.value,
			siteId: siteStore.websiteId,
		});

		if (isUserPayToPublish.value) {
			await EventLogApi.setUserProperty('is_builder_p2p', 'true');
			await EventLogApi.setUserProperty('is_builder_paid', 'false');
		}

		const isGradientUsed = Object.values(generatedSiteData.siteData.languages[SYSTEM_LOCALE].blocks)
			.some((block) => block.background?.current === TYPE_BLOCK_BACKGROUND.Gradient);

		const generatedSiteTypeId = Object.keys(AI_BUILDER_CATEGORIES)
			.find((categoryId) => AI_BUILDER_CATEGORIES[categoryId].amplitudeName === generatedSiteData.websiteType);

		if (generatedSiteTypeId) {
			setWebsiteType(generatedSiteTypeId);
		}

		await EventLogApi.logEvent({
			eventName: 'website_builder.ai_builder.created',
			eventProperties: {
				...(route.query.ref && {
					location: route.query.ref,
				}),
				...(generatedSiteData.usage && {
					color_mentioned: generatedSiteData.usage.isColorMentioned,
					font_mentioned: generatedSiteData.usage.isFontMentioned,
					is_non_online_store_with_product_list: generatedSiteData.usage.isNonOnlineStoreWithProductList,
					has_video_background: generatedSiteData.usage.hasVideoBackground,
				}),
				builder_type: 'v2',
				theme: generatedSiteData.theme,
				gradient_used: isGradientUsed,
				website_id: siteId.value,
				business_type: AI_BUILDER_CATEGORIES[websiteType.value].amplitudeName,
				is_p2p_user: isUserPayToPublish.value,
				is_features_lock_enabled: areFeaturesLocked.value,
			},
		});

		selectedHostingReferenceId.value = route.query.hostingReferenceId;

		await router.replace({
			query: {
				hostingReferenceId: selectedHostingReferenceId.value,
				// if user is p2p or starter plan user - add siteId so that when they go back they overwrite the site
				...((isUserPayToPublish.value || isOnlyOneStarterPlanUser.value) && {
					siteId: siteId.value,
					isChangingTemplate: true,
				}),
			},
		});

		saveGeneratedSite({
			language: generatedSiteData.descriptionLanguage,
			siteData: website.value,
			ecommerceProducts: generatedSiteData.ecommerceProducts,
			colorPalettePicked: generatedSiteData.colorPalette,
			colorPaletteGenerated: generatedSiteData.colorPalette,
		});

		await continueToBuilder({
			hasOnlineStore: generatedSiteTypeId === AI_BUILDER_CATEGORIES[AI_BUILDER_CATEGORY_ID_STORE].amplitudeName,
			clientTimestamp: generatedSiteData.clientTimestamp,
		});

		isSiteBeingGenerated.value = false;
	};

	const updateTemplate = ({ index } = {}) => {
		updateCurrentTemplateDataWithChangesFromVuex();
		// Switch to new template
		currentTemplateIndex.value = index;
		dispatch('overwriteWebsiteData', {
			websiteData: {
				...currentAiGeneratedTemplate.value,
				languages: {
					[SYSTEM_LOCALE]: {
						...currentAiGeneratedTemplate.value.languages[SYSTEM_LOCALE],
						blocks: {
							...siteBlocks.value,
							...currentAiGeneratedTemplate.value.languages[SYSTEM_LOCALE].blocks,
						},
						elements: {
							...siteElements.value,
							...currentAiGeneratedTemplate.value.languages[SYSTEM_LOCALE].elements,
						},
						pages: {
							...sitePages.value,
							...currentAiGeneratedTemplate.value.languages[SYSTEM_LOCALE].pages,
						},
						nav: [...siteNav.value],
					},
				},
			},
			websiteId: siteId.value,
		});
	};

	return {
		aiGeneratedTemplates,
		generateTemplates,
		currentTemplateIndex,
		isSiteBeingGenerated,
		deleteGeneratedTemplates,
		continueToBuilder,
		updateTemplate,
		hasGenerationFailed,
		currentAiGeneratedTemplate,
		aiTemplateData,
		aiTranslations,
		isGoingToBuilder,
		isLoading,
		saveGeneratedTemplates,
		removeOldLogoAsset,
		uploadTemplateLogo,
		sectionBasedGenerationSiteData,
		createWebsite,
		customColors,
		setCustomColors,
		isCustomColorPickerVisible,
	};
};
