import { computed } from 'vue';
import { useAiBuilderForm } from '@/components/ai-builder/useAiBuilderForm';
import {
	updateTemplateWithLogoSvg,
	getTemplateNavigationColor,
} from '@/utils/aiTemplateEditingHelpers';
import {
	AI_BUILDER_TEMPLATE_TYPE_DEFAULT,
	AI_BUILDER_TEMPLATE_TYPE_ONLINE_STORE,
	AI_BUILDER_TEMPLATE_TYPE_PORTFOLIO,
	AI_BUILDER_CATEGORY_ID_PORTFOLIO,
	AI_BUILDER_CATEGORY_ID_STORE,
	AI_BUILDER_CATEGORY_ID_BLOG,
	AI_BUILDER_TEMPLATE_TYPE_BLOG,
} from '@/constants/builderConstants';

export const useAiTemplateGenerator = () => {
	const { websiteType } = useAiBuilderForm();
	const templateType = computed(() => {
		if (websiteType.value === AI_BUILDER_CATEGORY_ID_PORTFOLIO) {
			return AI_BUILDER_TEMPLATE_TYPE_PORTFOLIO;
		}

		if (websiteType.value === AI_BUILDER_CATEGORY_ID_STORE) {
			return AI_BUILDER_TEMPLATE_TYPE_ONLINE_STORE;
		}

		if (websiteType.value === AI_BUILDER_CATEGORY_ID_BLOG) {
			return AI_BUILDER_TEMPLATE_TYPE_BLOG;
		}

		return AI_BUILDER_TEMPLATE_TYPE_DEFAULT;
	});

	const getPredefinedTemplatesWithAiData = ({
		logoSvg,
		sectionBasedGenerationSiteData,
	}) => {
		if (logoSvg?.svg) {
			const siteDataWithUpdatedLogo = updateTemplateWithLogoSvg({
				logoSvg,
				color: getTemplateNavigationColor({
					template: sectionBasedGenerationSiteData.siteData,
				}),
				templateData: sectionBasedGenerationSiteData.siteData,
			});

			return [siteDataWithUpdatedLogo];
		}

		return [sectionBasedGenerationSiteData.siteData];
	};

	return {
		getPredefinedTemplatesWithAiData,
		templateType,
	};
};
