<template>
	<div
		ref="mobileFrame"
		class="mobile-frame"
		:class="{ 'zyro-mb-preview': isMobileView }"
	>
		<slot />
	</div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';

import { computed } from 'vue';
import { MOBILE_BLOCK_WIDTH } from '@zyro-inc/site-modules/components/blocks/layout/constants';

const { state } = useStore();
const isMobileView = computed(() => state.gui.isMobileView);
const mobileWidth = `${MOBILE_BLOCK_WIDTH}px`;
</script>

<style lang="scss" scoped>
.mobile-frame {
	height: 100%;

	&.zyro-mb-preview {
		margin: auto;
		width: v-bind('mobileWidth');
	}
}
</style>
