import { PINIA_STORES } from '@/constants/stores';
import { captureException } from '@sentry/vue';
import {
	HResource,
	HRESOURCE_STATE,
	HRESOURCE_TYPE,
	HResourceType,
} from '@/types/resourceTypes';
import { defineStore } from 'pinia';
import {
	computed,
	ref,
} from 'vue';
// eslint-disable-next-line import/no-cycle
import HResourcesApi from '@/api/HResourcesApi';
import { useNotifications } from '@/use/useNotifications';
import { getIsLocalDevelopmentPlaygroundMode } from '@/utils/getIsLocalDevelopmentPlaygroundMode';

export const useResourcesStore = defineStore(PINIA_STORES.RESOURCES, () => {
	const { notify } = useNotifications();

	const isLoaded = ref(false);
	const resources = ref<HResource[]>([]);
	const siteHResourceId = ref<string | null>(null);

	const setSiteHResourceId = (id: string) => {
		siteHResourceId.value = id;
	};

	const fetchResources = async () => {
		if (getIsLocalDevelopmentPlaygroundMode()) {
			isLoaded.value = true;

			return;
		}

		try {
			resources.value = await HResourcesApi.getUserResources();
			isLoaded.value = true;
		} catch (error) {
			notify({
				messageI18nKeyPath: 'builder.siteResourcesFailedToLoad',
				theme: 'error',
				submitCallback: () => {},
			});

			captureException(error);

			isLoaded.value = false;
		}
	};

	const getResourcesByTypes = (types: HResourceType[]) => resources.value.filter((resource) => types.includes(resource.type));

	const pendingHostingResources = computed(() => resources.value
		.filter((resource) => resource.type === HRESOURCE_TYPE.HOSTING && resource.state === HRESOURCE_STATE.PENDING));

	const mostRecentPendingResource = computed(() => pendingHostingResources.value
		.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))?.[0]);

	const siteResource = computed(() => resources.value.find((resource) => String(resource.id) === siteHResourceId.value));

	return {
		fetchResources,
		getResourcesByTypes,
		setSiteHResourceId,
		siteHResourceId,
		pendingHostingResources,
		mostRecentPendingResource,
		siteResource,
		resources,
		isLoaded,
	};
});
