export const alignText = [
	{
		value: 'left',
		icon: 'align-left',
	},
	{
		value: 'center',
		icon: 'align-center',
	},
	{
		value: 'right',
		icon: 'align-right',
	},
];
