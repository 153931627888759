<template>
	<div class="banner">
		<Icon
			class="banner__icon"
			name="lock"
			dimensions="24px"
			is-filled
		/>
		<p class="banner__text">
			{{ $t('builder.featureLock.bannerMessage') }}
			<a
				class="banner__button"
				:href="redirectLinkToUpgradePage"
				@click.prevent="handleRedirectToUpgradePage"
			>
				{{ $t('common.upgradeNow') }}
			</a>
		</p>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Icon from '@/components/global/Icon.vue';
import { useRedirects } from '@/use/useRedirects';
import EventLogApi from '@/api/EventLogApi';
import { useDomainStore } from '@/stores/domainStore';

const domainStore = useDomainStore();

const {
	redirectToHPanelUpgradePage,
	getRedirectLinkToUpgradePage,
} = useRedirects();

const customDomain = computed(() => domainStore.customDomain);

const redirectLinkToUpgradePage = computed(() => getRedirectLinkToUpgradePage({
	currentDomain: customDomain.value,
}));

const handleRedirectToUpgradePage = () => {
	EventLogApi.logEvent({
		eventName: 'website_builder.feature_lock.upgrade',
		eventProperties: {
			location: 'banner',
		},
	});

	redirectToHPanelUpgradePage({
		currentDomain: customDomain.value,
	});
};

</script>

<style lang="scss" scoped>
.banner {
    display: flex;
    gap: 8px;
    padding: 16px;
    border-radius: 12px;
    color: $color-meteorite-dark;
	background-color: $color-meteorite-light;

	&__icon {
		min-width: 24px;
	}

	&__text {
		font-size: 14px;
		line-height: 24px;
	}

	&__button {
		cursor: pointer;
		font-weight: 700;
		color: $color-primary;
		background: none;
		text-decoration: none;
	}
}

</style>
