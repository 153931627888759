<template>
	<div class="text-container">
		<h2 class="text-bold-2">
			<slot name="title" />
		</h2>
		<p class="text-body-2">
			<slot />
		</p>
	</div>
</template>

<style lang="scss" scoped>
.text-container {
	margin-bottom: 40px;

	@media screen and (max-width: $media-mobile) {
		margin-bottom: 24px;
	}
}

</style>
