<template>
	<div class="ecommerce-tab-product-count">
		<div class="ecommerce-tab-product-count__toggle">
			<ZyroLabel>
				{{ $t('builder.showTotalProductCount') }}
			</ZyroLabel>
			<ZyroToggle
				id="total-product-count"
				:model-value="isTotalProductCountShown"
				@update:model-value="setIsProductCountShown"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
import cloneDeep from 'lodash.clonedeep';
import ZyroToggle from '@/components/global/ZyroToggle.vue';
import { useStore } from 'vuex';
import {
	ref,
	computed,
	onMounted,
	onBeforeUnmount,
} from 'vue';
import { SiteBlock } from '@hostinger/builder-schema-validator';
import ZyroLabel from '@/components/global/ZyroLabel.vue';

const {
	dispatch,
	getters,
	state,
} = useStore();

const blockId = ref('');
const initialCurrentBlockData = ref<SiteBlock | null>(null);
const currentBlockId = computed<string>(() => state.currentBlockId);
const currentBlock = computed<SiteBlock>(() => getters.currentBlock);
const isTotalProductCountShown = computed<boolean>(() => currentBlock.value.isTotalProductCountShown || false);

const setIsProductCountShown = (value: boolean) => {
	dispatch('updateBlockData', {
		blockId: blockId.value,
		blockData: {
			isTotalProductCountShown: value,
		},
		merge: true,
	});
};

onMounted(() => {
	blockId.value = currentBlockId.value;
	initialCurrentBlockData.value = cloneDeep(currentBlock.value);
});

onBeforeUnmount(() => {
	dispatch('undoRedo/createSnapshot');
});

</script>

<style lang="scss" scoped>
.ecommerce-tab-product-count {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__toggle {
		display: flex;
		justify-content: space-between;
	}

}
</style>
