<template>
	<div
		v-qa="`builder-sidebar`"
		class="sidebar"
	>
		<div
			v-if="isMobileScreen"
			class="sidebar__mobile-wrapper"
		>
			<BuilderSidebarProductPageToggle
				v-if="isProductPageTemplateButtonVisible && isSidebarToggleButtonsVisible"
				:is-sidebar-open="isSidebarOpen"
				@menu-button-click="toggleSidebar"
			/>
			<BuilderSidebarMenuToggle
				v-if="isSidebarToggleButtonsVisible"
				:is-sidebar-open="isSidebarOpen"
				@menu-button-click="toggleSidebar"
			/>
			<Transition
				name="drawer-slide-left"
				mode="out-in"
				appear
			>
				<div
					v-if="isSidebarOpen"
					ref="sidebarMobileRef"
					class="sidebar__mobile"
				>
					<HostingerButton
						class="sidebar__mobile-close-button"
						button-type="plain"
						@click="toggleSidebar"
					>
						<template #icon>
							<Icon name="close" />
						</template>
					</HostingerButton>
					<BuilderSidebarMenuControls />
				</div>
			</Transition>
			<Transition
				name="fade"
				mode="out-in"
			>
				<div
					v-if="isSidebarOpen || isSidebarBackdropVisible"
					class="backdrop"
					@click="closeSidebar"
				/>
			</Transition>
		</div>
		<div
			v-else
			class="sidebar__desktop"
		>
			<BuilderSidebarMenuControls />
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import { useSidebar } from '@/use/useSidebar';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import BuilderSidebarMenuControls from '@/components/builder-view/sidebar/BuilderSidebarMenuControls.vue';
import BuilderSidebarMenuToggle from '@/components/builder-view/sidebar/BuilderSidebarMenuToggle.vue';

import { resizedSectionId } from '@/use/useSectionResizing';

import {
	draggedElementId,
	resizedElementId,
} from '@/components/block-layout/useLayout';
import BuilderSidebarProductPageToggle from '@/components/builder-view/sidebar/BuilderSidebarProductPageToggle.vue';

const {
	state,
	getters,
} = useStore();

const {
	isSidebarOpen,
	isSidebarBackdropVisible,
	toggleSidebar,
	closeSidebar,
} = useSidebar();

const sidebarMobileRef = ref(null);
const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isProductPageTemplateButtonVisible = computed(() => getters.isCurrentPageTypeDynamicProduct);

// Hide mobile sidebar toggle when resizing section or dragging element
const isSidebarToggleButtonsVisible = computed(() => !resizedSectionId.value && !draggedElementId.value && !resizedElementId.value);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/backdrop';

.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	margin-top: $header-height-editor;
	height: calc(100vh - $header-height-editor);
	z-index: $z-index-layout-sidebar;

	&__mobile-wrapper {
		@media screen and (max-width: $media-mobile) {
			position: fixed;
			display: flex;
			flex-direction: column;
			width: 100%;
		}
	}

	&__desktop {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		background-color: $color-light;
		transition: 0.2s ease-in-out;
		z-index: $z-index-layout-sidebar;
		position: relative;
		min-width: $sidebar-width-editor;
		border-right: solid 1px $color-gray-border;
	}

	&__mobile {
		z-index: $z-index-layout-sidebar;
		background-color: $color-light;
		position: fixed;
		overflow: auto;
		padding-bottom: 16px;
		top: 0;
		left: 0;
		width: calc(100% - $sidebar-min-gap-mobile);
		border: none;
		max-width: 320px;
		border-radius: 0;
		height: 100vh;
		display: flex;
		flex-direction: column;
		min-width: unset;
	}

	&__mobile-close-button {
		align-self: flex-end;
		margin: 8px;
		padding: 8px;
	}
}

.backdrop {
	@include backdrop;
}
</style>
