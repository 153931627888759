<template>
	<div
		v-if="currentTab"
		class="zyro-tabs"
	>
		<div
			ref="el"
			class="zyro-tabs__list"
		>
			<button
				v-for="tab in tabs"
				:key="tab.title"
				v-qa="`builder-tab-${tab.title}`"
				:class="{
					'zyro-tabs__tab--active': currentTab.title === tab.title,
					'zyro-tabs__tab--disabled': tab.isDisabled,
					'zyro-tabs__tab--indicator': tab.hasIndicator,
				}"
				:title="tab.title"
				:disabled="tab.isDisabled"
				class="zyro-tabs__tab text-bold-2"
				@click="$emit('update:current-tab', tab)"
			>
				{{ tab.title }}
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useMouseScroll } from '@/use/useMouseScroll';
import { ref } from 'vue';

const el = ref<HTMLElement | null>(null);

useMouseScroll(el);

interface Tab {
	id: any;
	title: string;
	isDisabled?: boolean;
	hasIndicator?: boolean;
}

interface Props {
	tabs: Tab[];
	currentTab?: {
		title: string;
	} | null;
}

defineProps<Props>();
defineEmits<{
	'update:current-tab': [tab: Tab]
}>();
</script>

<style lang="scss" scoped>
.zyro-tabs {
	display: flex;
	width: 100%;
	border-bottom: 0.5px solid $color-gray-border;

	&__list {
		display: flex;
		overflow-x: auto;
		padding: 12px 16px 0;

		&::-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;
		scrollbar-width: none;
	}

	&__tab {
		position: relative;
		padding: 2px 0 5px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
		color: $color-gray;
		cursor: pointer;
		background-color: transparent;
		transition: color 0.2s ease;
		white-space: nowrap;

		&:not(:last-child) {
			margin-right: 16px;
		}

		&--active {
			color: var(--color-primary);

			&::before {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				content: "";
				background-color: var(--color-primary);
			}
		}

		&--disabled {
			cursor: not-allowed;
			opacity: 0.6;
		}

		&--indicator {
			&::after {
				position: absolute;
				top: 0;
				right: -10px;
				width: 10px;
				height: 10px;
				content: "";
				background-color: $color-warning-dark;
				border-radius: 50%;
			}
		}
	}
}
</style>
