<template>
	<SystemDialogModal
		:title="$t('builder.userStyles.typography.modal.title')"
		:primary-button-text="$t('common.cancel')"
		:secondary-button-text="$t('common.reset')"
		secondary-button-color="danger"
		@close="closeModal"
		@click-primary="closeModal"
		@click-secondary="handleReset"
	>
		<p>
			{{ $t('builder.userStyles.modal.subtitle') }}
		</p>
	</SystemDialogModal>
</template>

<script>
import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';

import { useTypographyStylesLibrary } from '@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/use/useTypographyStylesLibrary';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		SystemDialogModal,
	},

	setup() {
		const { resetSelectedTypographyStyles } = useTypographyStylesLibrary();

		return {
			resetSelectedTypographyStyles,
		};
	},

	methods: {
		...mapActionsGui({
			closeModal: CLOSE_MODAL,
		}),
		handleReset() {
			this.resetSelectedTypographyStyles();
			this.closeModal();
		},
	},
});
</script>
