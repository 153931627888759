import axios from '@/services/http/axios';
import {
	SearchVideos,
	SearchVideosResponse,
} from '@/types/pexelsTypes';

const PEXELS_API = `${import.meta.env.VITE_BACKEND_API_URL}/pexels`;

const searchVideos = async ({
	query,
	page,
	perPage,
}:SearchVideos) => {
	const { data } = await axios.post(`${PEXELS_API}/search`, {
		query,
		page,
		perPage,
	});

	return data as SearchVideosResponse;
};

export default {
	searchVideos,
};
