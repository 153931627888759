<script setup lang="ts">
import ZyroInput from '@/components/global/ZyroInput.vue';
import { usePexelsStore } from '@/stores/pexelsStore';
import {
	onMounted,
	ref,
	toRefs,
	watch,
} from 'vue';
import debounce from 'lodash/debounce';
import { useScroll } from '@vueuse/core';
import PexelsVideoPreview from '@/components/builder-modals/modals/asset-manager/pexels/PexelsVideoPreview.vue';
import { Video } from '@/types/pexelsTypes';
import HImage from '@/components/global/HImage.vue';
import { PEXELS_SEARCH_DEBOUNCE_TIME } from '@/constants/builderConstants';
import { useI18n } from 'vue-i18n';

type Emits = {
	'toggle-preview': [boolean];
	'close': [];
}

type Props = {
	isPreviewOpen: boolean;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const { t } = useI18n();

const pexelsStore = usePexelsStore();

const searchQuery = defineModel('searchQuery', {
	type: String,
	default: '',
});

const freeVideosRef = ref();
const { arrivedState } = useScroll(freeVideosRef);
const { bottom: hasReachedBottom } = toRefs(arrivedState);

const videoData = ref();

const handleVideoPreviewClick = (video: Video) => {
	videoData.value = video;
	emit('toggle-preview', true);
};

const handleVideoPreviewClose = () => {
	videoData.value = undefined;
	emit('toggle-preview', false);
};

const handleVideoSelect = (videoId: number) => {
	pexelsStore.selectVideo(videoId);
	emit('close');
};

watch(hasReachedBottom, (value) => {
	if (value) {
		pexelsStore.searchMoreVideos();
	}
});

watch(searchQuery, debounce(() => pexelsStore.searchVideo({
	query: searchQuery.value,
}), PEXELS_SEARCH_DEBOUNCE_TIME));

onMounted(() => {
	if (pexelsStore.showEmptyDisclaimer) {
		pexelsStore.setDefaultPexelsVideos();
	}
});
</script>

<template>
	<PexelsVideoPreview
		v-if="isPreviewOpen"
		:video="videoData"
		@close-preview="handleVideoPreviewClose"
		@select-video="handleVideoSelect"
	/>
	<template v-else>
		<ZyroInput
			v-model="searchQuery"
			class="free-videos__search-input"
			placeholder="Search for free videos"
		/>
		<div
			v-if="pexelsStore.showEmptyDisclaimer"
			class="free-videos__disclaimer-container"
		>
			<span class="text-heading-1">
				{{ t('builder.assetManagerTabPexelsNoResults') }}
			</span>
			<span class="text-body-2">
				{{ t('builder.assetManagerTabPexelsCheckSpelling') }}
			</span>
		</div>
		<div
			v-else
			ref="freeVideosRef"
			class="free-videos"
		>
			<div>
				<ul class="free-videos__list">
					<li
						v-for="video in pexelsStore.videos"
						:key="video.id"
						class="free-videos__item"
					>
						<img
							class="free-videos__image"
							:src="video.image"
							alt="video"
							@click="handleVideoPreviewClick(video)"
						>
					</li>
				</ul>
			</div>
		</div>
	</template>
	<div
		v-if="!isPreviewOpen"
		class="free-videos__pexels-attribution"
	>
		<HImage
			class="free-videos__pexels-logo"
			src="/images/pexels-logo.png"
		/>
	</div>
</template>

<style scoped lang="scss">
.free-videos {
  padding: 20px;
	overflow-y: auto;
	height: 100%;

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    list-style: none;

		@media screen and (max-width: $media-mobile) {
			grid-template-columns: repeat(2, 1fr);
		}
  }

  &__image {
    width: 100%;
		aspect-ratio: 16 / 9;
    border-radius: 5px;
    cursor: pointer;
    object-fit: cover
  }

  &__search-input {
		padding: 0 20px;
  }

	&__pexels-attribution {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 60px;
		padding-right: 16px;;
	}

	&__pexels-logo {
		height: 75%;
	}

	&__disclaimer-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
}
</style>
