import { HOSTINGER_URL_HELP } from '@/constants/builderConstants';
import { PINIA_STORES } from '@/constants/stores';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useIntercomStore = defineStore(PINIA_STORES.INTERCOM, () => {
	const isIntercomActive = ref(false);

	const setIntercomActive = (isActive: boolean, callback?: () => void) => {
		isIntercomActive.value = isActive;

		if (callback) {
			callback();
		}
	};

	const handleIntercomOpen = () => {
		window.open(HOSTINGER_URL_HELP, '_blank');
	};

	return {
		isIntercomActive,
		setIntercomActive,
		handleIntercomOpen,
	};
});
