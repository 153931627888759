<script setup lang="ts">
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';

import EditBlockTabStyle from '@/components/builder-controls/common-ecommerce/EditBlockTabStyle.vue';
import EditBlockTabLayout from '@/components/builder-controls/edit-block-ecommerce-product-list/EditBlockTabLayout.vue';
import EditBlockTabRibbons from '@/components/builder-controls/edit-block-ecommerce-product-list/EditBlockTabRibbons.vue';
import EditBlockTabPagination from '@/components/builder-controls/edit-block-ecommerce-product-list/EditBlockTabPagination.vue';
import EditBlockTabBuyButton from '@/components/builder-controls/edit-block-ecommerce-product-list/EditBlockTabBuyButton.vue';
import EditBlockTabFilters from '@/components/builder-controls/edit-block-ecommerce-product-list/EditBlockTabFilters.vue';
import EditBlockTabImageHoverEffect from '@/components/builder-controls/edit-block-ecommerce-product-list/EditBlockTabImageHoverEffect.vue';
import EditBlockTabProductOrder from '@/components/builder-controls/edit-block-ecommerce-product-list/EditBlockTabProductOrder.vue';
import EditBlockTabSorting from '@/components/builder-controls/edit-block-ecommerce-product-list/EditBlockTabSorting.vue';
import EditBlockTabProductCount from '@/components/builder-controls/edit-block-ecommerce-product-list/EditBlockTabProductCount.vue';
import EditBlockTabStoreLanguage from '@/components/builder-controls/edit-block-ecommerce-product-list/EditBlockTabStoreLanguage.vue';
import ExpandableSettingsCard from '@/components/reusable-components/expandable-settings/ExpandableSettingsCard.vue';
import ExpandableSettingsItem from '@/components/reusable-components/expandable-settings/ExpandableSettingsItem.vue';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import { NPS_TYPE_FEATURE_SIMPLE_STORE } from '@/constants/builderConstants';
import { useI18n } from 'vue-i18n';
import { useEcommerceTabSettingsPopup } from '@/use/useEcommerceTabSettingsPopup';
import { computed } from 'vue';

const { t } = useI18n();
const {
	currentTab,
	handleItemClick,
} = useEcommerceTabSettingsPopup();

defineEmits(['close']);

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
});

const items = computed<Record<string, any>[]>(() => [
	{
		id: 'store-language',
		title: t('common.storeLanguage'),
		component: EditBlockTabStoreLanguage,
	},
	{
		id: 'layout',
		title: t('common.layout'),
		component: EditBlockTabLayout,
	},
	{
		id: 'style',
		title: t('common.style'),
		component: EditBlockTabStyle,
	},
	{
		id: 'filters',
		title: t('common.filters'),
		component: EditBlockTabFilters,
	},
	{
		id: 'sorting',
		title: t('common.sorting'),
		component: EditBlockTabSorting,
	},
	{
		id: 'product-count',
		title: t('builder.totalProductCount'),
		component: EditBlockTabProductCount,
	},
	{
		id: 'scroll-behaviour',
		title: t('builder.scrollBehaviour'),
		component: EditBlockTabPagination,
	},
	{
		id: 'buy-button',
		title: t('builder.addToBagButton'),
		component: EditBlockTabBuyButton,
	},
	{
		id: 'ribbons',
		title: t('common.ribbons'),
		component: EditBlockTabRibbons,
	},
	{
		id: 'image-hover-effect',
		title: t('common.imageHoverEffect'),
		component: EditBlockTabImageHoverEffect,
	},
	{
		id: 'product-order',
		title: t('builder.orderOfProducts'),
		component: EditBlockTabProductOrder,
	},
]);
</script>

<template>
	<ZyroPopupCard
		type="editor"
		is-full-height
		max-width="305px"
		:show-close-button="false"
	>
		<ExpandableSettingsCard
			:title="$t('builder.sectionSettings.title')"
			@close="$emit('close')"
		>
			<ExpandableSettingsItem
				v-for="item in items"
				:id="item.id"
				:key="`item-${item.id}`"
				:title="item.title"
				:should-open-at-load="item.isOpen"
				:is-open="currentTab === item.id"
				@item-clicked="handleItemClick(item.id)"
			>
				<Component
					:is="item.component"
					:block-id="props.blockId"
				/>
			</ExpandableSettingsItem>
			<NpsRateFeature
				class="product-list-settings__nps-rate"
				:feature-name="$t('builder.npsRateOnlineStore')"
				:type="NPS_TYPE_FEATURE_SIMPLE_STORE"
				hide-border
			/>
		</ExpandableSettingsCard>
	</ZyroPopupCard>
</template>

<style lang="scss" scoped>
.product-list-settings {
	&__nps-rate {
		justify-content: center;
		padding: 24px;
	}
}
</style>
