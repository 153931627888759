import { useStore } from 'vuex';

import { PAGE_ID_PRIVATE } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { SITE_META_PRIVATE_MODE_KEY } from '@/constants/builderConstants';
import { useSiteStore } from '@/stores/siteStore';
import EventLogApi from '@/api/EventLogApi';

export const usePrivatePage = () => {
	const { dispatch } = useStore();

	const siteStore = useSiteStore();

	const openPrivatePage = () => {
		dispatch('updateCurrentPageId', PAGE_ID_PRIVATE);
	};

	const updateIsPrivateModeActive = ({
		isPrivateModeActive,
		location,
	}: { isPrivateModeActive: boolean, location: string }) => {
		siteStore.setSiteMetaData({
			key: SITE_META_PRIVATE_MODE_KEY,
			value: isPrivateModeActive,
		});

		EventLogApi.logEvent({
			eventName: 'website_builder.private_mode.switch',
			eventProperties: {
				value: isPrivateModeActive ? 'on' : 'off',
				location,
			},
		});
	};

	return {
		openPrivatePage,
		updateIsPrivateModeActive,
	};
};
