<template>
	<div class="edit-image-tab-shape">
		<ZyroIconSelect
			:model-value="currentElementShapeMaskName"
			:options="SHAPE_TYPES"
			:items-per-row="4"
			options-grid-gap="8px"
			active-background-color="var(--color-azure-lighter)"
			class="text-body-2"
			@update:model-value="($event: string) => setShape({ shapeMaskName: $event })"
		/>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

import ZyroIconSelect from '@/components/global/ZyroIconSelect.vue';

const {
	getters,
	dispatch,
} = useStore();

interface ShapeType {
	icon: string;
	iconDimensions: string;
	maskSource?: string;
}

const SHAPE_TYPES: { [index: string]: ShapeType} = {
	none: {
		icon: 'crossed-circle',
		iconDimensions: '34px',
	},
	maskShape16: {
		icon: 'mask-shape-16',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><circle cx="100" cy="100" r="100" fill="%23727586"/></svg>',
	},
	maskShape1: {
		icon: 'mask-shape-1',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path d="M0 100L100 0L200 100L100 200L0 100Z" fill="%23727586"/></svg>',
	},
	maskShape2: {
		icon: 'mask-shape-2',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path d="M100 0L120.706 22.7259L150 13.3975L156.569 43.4315L186.603 50L177.274 79.2945L200 100L177.274 120.706L186.603 150L156.569 156.569L150 186.603L120.706 177.274L100 200L79.2945 177.274L50 186.603L43.4315 156.569L13.3975 150L22.7259 120.706L0 100L22.7259 79.2945L13.3975 50L43.4315 43.4315L50 13.3975L79.2945 22.7259L100 0Z" fill="%23727586"/></svg>',
	},
	maskShape3: {
		icon: 'mask-shape-3',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path d="M100 200C84.306 200 71.3985 188.07 69.8522 172.783C57.9498 182.499 40.3867 181.808 29.2893 170.711C18.192 159.613 17.5011 142.05 27.2168 130.148C11.9305 128.602 -6.86007e-07 115.694 0 100C1.24213e-06 84.3059 11.9305 71.3984 27.2168 69.8522C17.5012 57.9498 18.192 40.3867 29.2894 29.2893C40.3867 18.192 57.9498 17.5011 69.8522 27.2167C71.3985 11.9305 84.306 1.24213e-06 100 0C115.694 -6.86007e-07 128.602 11.9305 130.148 27.2167C142.05 17.5011 159.613 18.1919 170.711 29.2893C181.808 40.3867 182.499 57.9498 172.783 69.8522C188.07 71.3984 200 84.306 200 100C200 115.694 188.07 128.602 172.783 130.148C182.499 142.05 181.808 159.613 170.711 170.711C159.613 181.808 142.05 182.499 130.148 172.783C128.602 188.07 115.694 200 100 200Z" fill="%23727586"/></svg>',
	},
	maskShape4: {
		icon: 'mask-shape-4',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path d="M174.537 100C190.372 89.3849 200 75.3671 200 60C200 26.8629 155.228 0 100 0C44.7715 0 0 26.8629 0 60C0 75.3671 9.62845 89.3849 25.4631 100C9.62845 110.615 0 124.633 0 140C0 173.137 44.7715 200 100 200C155.228 200 200 173.137 200 140C200 124.633 190.372 110.615 174.537 100Z" fill="%23727586"/></svg>',
	},
	maskShape5: {
		icon: 'mask-shape-5',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path d="M200 46.6667C200 56.5817 193.374 65.7744 182.076 73.3333C193.374 80.8922 200 90.085 200 100C200 109.915 193.374 119.108 182.076 126.667C193.374 134.226 200 143.418 200 153.333C200 179.107 155.228 200 100 200C44.7715 200 0 179.107 0 153.333C0 143.418 6.62593 134.226 17.9241 126.667C6.62593 119.108 0 109.915 0 100C0 90.085 6.62593 80.8922 17.9241 73.3333C6.62593 65.7744 0 56.5817 0 46.6667C0 20.8934 44.7715 0 100 0C155.228 0 200 20.8934 200 46.6667Z" fill="%23727586"/></svg>',
	},
	maskShape6: {
		icon: 'mask-shape-6',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path d="M34.7326 50C14.4491 42.4512 0 22.913 0 0H200C200 22.913 185.551 42.4512 165.267 50C185.551 57.5488 200 77.087 200 100C200 122.913 185.551 142.451 165.267 150C185.551 157.549 200 177.087 200 200H0C0 177.087 14.4491 157.549 34.7326 150C14.4491 142.451 0 122.913 0 100C0 77.087 14.4491 57.5488 34.7326 50Z" fill="%23727586"/></svg>',
	},
	maskShape7: {
		icon: 'mask-shape-7',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path d="M100.001 173.738C24.3647 236.944 -36.9443 175.636 26.2624 99.9999C-36.9443 24.3644 24.3647 -36.9438 100.001 26.2621C175.623 -36.9438 236.947 24.3644 173.74 99.9999C236.947 175.578 175.623 236.944 100.001 173.738Z" fill="%23727586"/></svg>',
	},
	maskShape8: {
		icon: 'mask-shape-8',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path d="M0 100C0 44.7715 44.7715 0 100 0C155.228 0 200 44.7715 200 100V200H0V100Z" fill="%23727586"/></svg>',
	},
	maskShape9: {
		icon: 'mask-shape-9',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path d="M0 0H113.333C161.198 0 200 38.802 200 86.6667V200H86.6667C38.802 200 0 161.198 0 113.333V0Z" fill="%23727586"/></svg>',
	},
	maskShape10: {
		icon: 'mask-shape-10',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M200 50V4.37114e-06L100 0V33.9803C99.9893 6.3751 77.6077 4.37114e-06 50 4.37114e-06H2.18557e-06V100H34C6.3858 100 -1.20706e-06 122.386 0 150L2.18557e-06 200H100V166C100 193.614 122.386 200 150 200H200V100H166.02C193.625 99.9893 200 77.6077 200 50Z" fill="%23727586"/></svg>',
	},
	maskShape11: {
		icon: 'mask-shape-11',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.4046 100.422C8.31777 102.425 0.714259 111.325 0.714259 122L0.714256 178C0.714256 190.15 10.564 200 22.7143 200H78.7143C89.6402 200 98.7058 192.035 100.422 181.595C102.425 191.682 111.325 199.286 122 199.286H178C190.15 199.286 200 189.436 200 177.286V121.286C200 110.36 192.035 101.294 181.596 99.578C191.682 97.5746 199.286 88.6752 199.286 78V22C199.286 9.84974 189.436 2.97894e-06 177.286 2.44784e-06L121.286 0C110.36 -4.77585e-07 101.294 7.96461 99.5781 18.4046C97.5747 8.31778 88.6753 0.71428 78 0.71428H22C9.84974 0.71428 0 10.564 0 22.7143V78.7143C0 89.6401 7.96462 98.7058 18.4046 100.422Z" fill="%23727586"/></svg>',
	},
	maskShape12: {
		icon: 'mask-shape-12',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M148 0H0L52 200H200L148 0Z" fill="%23727586"/></svg>',
	},
	maskShape13: {
		icon: 'mask-shape-13',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M121.089 0C174.339 0 200 65.9669 200 126.582C200 171.887 160.89 200 121.089 200C67.5113 200 0 187.57 0 126.582C0 53.4983 56.886 0 121.089 0Z" fill="%23727586"/></svg>',
	},
	maskShape14: {
		icon: 'mask-shape-14',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path d="M168.562 42.5672C184.281 55.141 200 76.1523 200 99.8108C200 123.635 184.127 150.271 157.312 171.283C130.497 192.294 92.8952 207.68 62.9983 195.934C33.1014 184.187 11.064 145.473 3.2045 104.278C-4.655 63.2477 1.81753 19.9014 24.009 5.83869C46.2005 -8.38948 84.111 6.66591 110.772 16.2617C137.432 25.8574 152.997 29.9935 168.562 42.5672Z" fill="%23727586"/></svg>',
	},
	maskShape15: {
		icon: 'mask-shape-15',
		iconDimensions: '48px',
		maskSource: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path d="M1.97387 169.13C-5.79459 148.805 11.296 120.102 19.8413 98.9532C28.542 77.8041 28.3866 64.2082 36.1551 46.2178C43.7682 28.09 59.1497 5.56755 79.8138 0.898269C100.323 -3.63368 126.269 9.55016 150.662 27.678C175.21 45.6684 198.205 68.6028 199.914 92.9106C201.468 117.218 181.736 142.899 157.343 163.224C132.795 183.55 103.741 198.381 72.8222 199.892C42.0591 201.265 9.58696 189.455 1.97387 169.13Z" fill="%23727586"/></svg>',
	},
};

const currentElement = computed(() => getters.currentElement);
const currentElementShapeMaskName = computed(() => {
	const currentElementShapeMaskSource = currentElement.value?.shapeMaskSource;

	if (!currentElementShapeMaskSource) {
		return 'none';
	}

	const shapeMaskName = Object.keys(SHAPE_TYPES)
		.find((maskName) => SHAPE_TYPES[maskName].maskSource === currentElementShapeMaskSource);

	return shapeMaskName;
});

const setShape = ({ shapeMaskName }: {shapeMaskName: string}) => {
	if (shapeMaskName === 'none') {
		dispatch('mergeCurrentElementData', {
			elementData: {
				shapeMaskSource: null,
			},
		});

		return;
	}

	const shapeMaskSource = SHAPE_TYPES[shapeMaskName].maskSource;

	dispatch('mergeCurrentElementData', {
		elementData: {
			shapeMaskSource,
		},
	});
};

</script>
