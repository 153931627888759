<template>
	<div
		v-qa="`builder-header-text-publishsite`"
		class="publish-button-content"
	>
		<ZyroLoader
			v-if="isSiteBeingPublished"
			class="publish-button-content__loader"
			size="20px"
			color="var(--color-azure-light)"
		/>
		<span v-else>
			{{ buttonText }}
		</span>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import { useI18n } from 'vue-i18n';

import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';

const {
	state,
	getters,
} = useStore();
const { t } = useI18n();

const zyroDomain = computed(() => state.zyroDomain);
const isSiteBeingPublished = computed(() => state.gui.isSiteBeingPublished);
const websiteStatus = computed(() => getters.websiteStatus);
const isMobileScreen = computed(() => state.gui.isMobileScreen);
const buttonText = computed(() => {
	if (isSiteBeingPublished.value) {
		return t('builder.header.publishing');
	}

	if (websiteStatus.value === 'SUSPENDED') {
		return t('builder.header.websiteSuspended');
	}

	if (zyroDomain.value) {
		return isMobileScreen.value ? t('common.update') : t('builder.header.updateWebsite');
	}

	return t('builder.header.goLive');
});
</script>

<style lang="scss" scoped>
.publish-button-content {
	display: flex;
	align-items: center;
	white-space: nowrap;
	justify-content: center;

	&__loader {
		&#{&} {
			margin: 0 13px;
		}
	}
}

</style>
