import { TEXT_EDITOR_DATA_ATTRIBUTE_LINKED_PAGE_ID } from '@/constants/builderConstants';
import { useSiteStore } from '@/stores/siteStore';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useStore } from 'vuex';

export const useTextBoxInPreview = (isPreviewMode: boolean) => {
	const {
		getters,
		state,
		dispatch,
	} = useStore();

	const siteStore = useSiteStore();
	const {
		sitePages,
		siteBlocks,
	} = storeToRefs(siteStore);

	const defaultLocale = computed(() => getters.defaultLocale);
	const currentLocale = computed(() => state.currentLocale);
	const isCurrentLocaleDefault = computed(() => currentLocale.value === defaultLocale.value);

	const textBoxClickHandler = async (e: MouseEvent) => {
		if (!isPreviewMode) {
			return;
		}

		e.preventDefault();

		if (!e.target || !(e.target as Element).matches('a')) {
			return;
		}

		const { href } = (e.target as HTMLAnchorElement);
		const builderUrl = window.location.origin;
		const isBuilderLink = href.includes(builderUrl);

		if (!isBuilderLink) {
			window.open(href, '_blank');

			return;
		}

		const builderLink = href.replace(builderUrl, '');

		const pageId = (e.target as Element).getAttribute(TEXT_EDITOR_DATA_ATTRIBUTE_LINKED_PAGE_ID);

		const [pageLink, anchor] = builderLink.split('#');

		const pageSlug = !isCurrentLocaleDefault.value
			? pageLink.replace(`/${currentLocale.value}`, '').replace('/', '')
			: pageLink.replace('/', '');

		if (anchor) {
			const [anchorPageId, pageValue] = Object.entries(sitePages.value).find(([, { slug }]) => slug === pageSlug) || [];

			await dispatch('updateCurrentPageId', anchorPageId);

			const blockId = Object.entries(siteBlocks.value)
				.find(([
					currentBlockId,
					{
						htmlId,
						slot,
					},
				]) => htmlId === anchor
				&& ((pageValue?.blocks as any || []).includes(currentBlockId) || slot === 'footer'))
				?.[0];

			const element = document.querySelector(`[data-block-id="${blockId}"]`) as HTMLElement;

			if (!element) {
				return;
			}

			window.requestAnimationFrame(() => {
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			});

			return;
		}

		await dispatch('updateCurrentPageSlug', pageSlug);
		await dispatch('updateCurrentPageId', pageId);
	};

	return {
		textBoxClickHandler,
	};
};
