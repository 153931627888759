<script setup>
import { captureException } from '@sentry/vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroFieldTextArea from '@/components/global/ZyroFieldTextArea.vue';
import ZyroInput from '@/components/global/ZyroInput.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroModal from '@/components/global/ZyroModal.vue';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';
import TagMultiselect from '@/components/global/TagMultiselect.vue';
import SegmentedProgressBar from '@/components/global/SegmentedProgressBar.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import {
	ONBOARDING_STEPS_SEO,
	ONBOARDING_STEPS_SEO_INTRO,
	ONBOARDING_STEPS_SEO_BRAND_NAME,
	ONBOARDING_STEPS_SEO_DESCRIPTION,
	ONBOARDING_STEPS_SEO_BRAND_KEYWORDS,
	ONBOARDING_STEPS_SEO_BRAND_REVIEW,
	ONBOARDING_STEPS_SEO_LANGUAGE,
	MODAL_UPGRADE_TO_BUSINESS_PLAN,
	ECOMMERCE_FUNNEL_LOCATIONS,
	AI_GENERATE_MAX_LENGTH,
} from '@/constants/builderConstants';
import { OPEN_MODAL } from '@/store/builder/gui';
import { SEO_MAX_STRING_LENGTH_DESCRIPTION } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import {
	computed,
	ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import PageSearchPreview from '@/components/builder-controls/page-settings/PageSearchPreview.vue';
import { getPagePathFromId } from '@zyro-inc/site-modules/utils/page/getPagePathFromId';
import EventLogApi from '@/api/EventLogApi';
import { useNotifications } from '@/use/useNotifications';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import { HTML_LANG_VALUES } from '@/data/htmlLangValues';
import { useSeoStepOnboarding } from '@/use/useSeoStepOnboarding';
import { usePageSeoSettings } from '@/use/usePageSeoSettings';
import { createSeo } from '@/api/AiApi';
import { useUserStore } from '@/stores/userStore';
import { useSiteStore } from '@/stores/siteStore';
import FeatureLockBanner from '@/components/FeatureLockBanner.vue';
import { useAiField } from '@/use/useAiField';

const props = defineProps({
	pageId: {
		type: String,
		required: true,
	},
});

const emit = defineEmits(['close']);

const { notify } = useNotifications();
const { t } = useI18n();
const {
	getters,
	state,
	dispatch,
} = useStore();
const siteStore = useSiteStore();
const userStore = useUserStore();
const { pageData } = usePageSeoSettings();

const isLoading = ref(false);

const isMobileScreen = computed(() => state.gui.isMobileScreen);
const modalMaxHeight = computed(() => (isMobileScreen.value ? '100%' : '80vh'));
const siteMetaTitle = computed(() => getters.currentLanguageData.metaTitle ?? getters.siteMeta.metaTitle);

const onboardingSteps = computed(() => ONBOARDING_STEPS_SEO.filter((stepName) => {
	// Skipping intro step
	if (stepName === ONBOARDING_STEPS_SEO_INTRO) return false;

	// If Website Title is present skip this step - allow empty string title
	if (stepName === ONBOARDING_STEPS_SEO_BRAND_NAME) {
		return [
			null,
			undefined,
		].includes(siteMetaTitle.value);
	}

	// if user has set metaHtmlLanguage property
	// or if Website Title is present
	// or if website has more than 1 language (multilanguage) skip this step
	if (stepName === ONBOARDING_STEPS_SEO_LANGUAGE) {
		if (getters.siteLanguagesArray.length > 1) return false;

		return !getters.siteMeta.metaHtmlLanguage;
	}

	return true;
}));

const {
	currentOnboardingStep,
	isOnboardingRestarted,
	goToNextStep,
	onboardingStepCount,
	goToPreviousStep,
	endOnboarding,
} = useSeoStepOnboarding({
	steps: onboardingSteps,
});
const pageMeta = computed(() => pageData.value.meta ?? {});
const pageMetaDescription = computed(() => pageMeta.value.description ?? '');
const providedDescription = computed(() => (isOnboardingRestarted.value ? pageMetaDescription.value : ''));
const pageName = ref(getters.sitePages[props.pageId].name);
const currentStepName = computed(() => onboardingSteps.value[currentOnboardingStep.value]);
const isPreviousStepArrowVisible = computed(() => currentOnboardingStep.value !== 0);
const isCurrentPageHomePage = computed(() => props.pageId === getters.homePageId);
const websiteMetaHtmlLanguage = ref(
	HTML_LANG_VALUES.find(({ value }) => (!getters.siteMeta.metaHtmlLanguage ? value === 'en' : value === getters.siteMeta.metaHtmlLanguage)),
);
const businessBrandName = ref('');
const selectedKeywords = ref([]);
const keywords = ref([]);
const generatedDescription = ref('');
const generatedTitle = ref('');
const hasModifiedManually = ref(false);
const selectedKeywordsTags = computed(() => selectedKeywords.value.map((keyword) => ({
	value: keyword,
})));
const isCurrentPageTypeEcommerceProduct = computed(() => getters.isCurrentPageTypeEcommerceProduct);
const contentHeading = isCurrentPageHomePage.value ? t('builder.seoOnboardingPageDescriptionHeading') : t('builder.seoOnboardingPageDescriptionHeadingPage');
const contentSubtitle = isCurrentPageHomePage.value ? t('builder.seoOnboardingPageDescriptionSubtitle') : t('builder.seoOnboardingPageDescriptionSubtitlePage');
const keywordsHeading = isCurrentPageHomePage.value ? t('builder.seoOnboardingKeywordsHeading') : t('builder.seoOnboardingKeywordsHeadingPage');

const {
	fieldValue,
	fieldProgress,
	fieldError,
	setFieldValue,
	onFieldBlur,
	validateField,
} = useAiField({
	initialValue: providedDescription.value,
});

const saveOnboardingPageMeta = () => {
	const dataToMerge = {
		title: generatedTitle.value,
		description: generatedDescription.value,
		keywords: selectedKeywords.value,
		focusKeyword: selectedKeywords.value[0],
	};

	if (isCurrentPageTypeEcommerceProduct.value) {
		dispatch('ecommerce/setProductMetaUpdates', {
			productId: pageData.value.productId,
			productMeta: dataToMerge,
		});
	} else {
		dispatch('mergePageData', {
			pageId: props.pageId,
			pageData: {
				meta: dataToMerge,
			},
		});
	}

	if (businessBrandName.value) {
		if (getters.isCurrentSystemLocale) {
			siteStore.setSiteMetaData({
				key: 'metaTitle',
				value: businessBrandName.value,
			});
		} else {
			dispatch('setLocaleMeta', {
				metaTitle: businessBrandName.value,
			});
		}
	}

	if (onboardingSteps.value.includes(ONBOARDING_STEPS_SEO_LANGUAGE)) {
		siteStore.setSiteMetaData({
			key: 'metaHtmlLanguage',
			value: websiteMetaHtmlLanguage.value.value,
		});
	}
};

const nextStepButtonText = computed(() => {
	if (currentStepName.value === ONBOARDING_STEPS_SEO_INTRO) return t('builder.quickStartGuideStartButton');
	if (currentOnboardingStep.value === onboardingStepCount.value - 1) return t('builder.onboardingFinish');

	return t('builder.seoOnboardingNextStep');
});

const isNextStepButtonDisabled = computed(() => {
	if (isLoading.value) return true;

	if (currentStepName.value === ONBOARDING_STEPS_SEO_BRAND_NAME) return !businessBrandName.value;

	if (currentStepName.value === ONBOARDING_STEPS_SEO_BRAND_KEYWORDS) return selectedKeywords.value.length < 3;

	return false;
});

const handleNextStepClick = async () => {
	if (userStore.areFeaturesLocked) {
		dispatch(`gui/${OPEN_MODAL}`, {
			name: MODAL_UPGRADE_TO_BUSINESS_PLAN,
			settings: {
				location: ECOMMERCE_FUNNEL_LOCATIONS.SEO_ONBOARDING_MODAL,
			},
		});

		return;
	}

	if (currentStepName.value === ONBOARDING_STEPS_SEO_BRAND_NAME && businessBrandName.value) {
		EventLogApi.logEvent({
			eventName: 'website_builder.seo_brand_name.typed',
			eventProperties: {
				location: 'onboarding',
				name_provided: businessBrandName.value,
			},
		});
	}

	if (currentStepName.value === ONBOARDING_STEPS_SEO_LANGUAGE && websiteMetaHtmlLanguage.value.value) {
		EventLogApi.logEvent({
			eventName: 'website_builder.seo_language.selected',
			eventProperties: {
				location: 'onboarding',
				language_selected: websiteMetaHtmlLanguage.value.value,
			},
		});
	}

	if (currentStepName.value === ONBOARDING_STEPS_SEO_DESCRIPTION) {
		// TODO: is_modification property - if page onboarding is opened manually, set it as false
		EventLogApi.logEvent({
			eventName: 'website_builder.seo_website_description.typed',
			eventProperties: {
				location: 'onboarding',
				is_main: isCurrentPageHomePage.value,
				is_modification: false,
				description: fieldValue.value,
				quality: fieldProgress.value.progress,
			},
		});

		if (!validateField()) {
			return;
		}

		isLoading.value = true;

		try {
			const { data } = await createSeo({
				brandDescription: fieldValue.value,
			});

			keywords.value = data.keywords.map((keyword) => ({
				value: keyword,
			}));
			selectedKeywords.value = data.keywords.slice(0, 2);

			goToNextStep();
		} catch (error) {
			captureException(error);
			notify({
				message: t('builder.seoOnboardingFailed'),
			});
			emit('close');
		} finally {
			isLoading.value = false;
		}

		return;
	}

	if (currentStepName.value === ONBOARDING_STEPS_SEO_BRAND_KEYWORDS) {
		// TODO: is_modification property - if page onboarding is opened manually, set it as false
		EventLogApi.logEvent({
			eventName: 'website_builder.seo_keywords.chosen',
			eventProperties: {
				location: 'onboarding',
				is_main: isCurrentPageHomePage.value,
				is_modification: false,
				keyword_chosen: selectedKeywords.value,
			},
		});

		isLoading.value = true;

		try {
			const { data } = await createSeo({
				brandDescription: fieldValue.value,
				keywords: selectedKeywords.value,
			});

			const aggregatedGeneratedDescription = data.meta.length > SEO_MAX_STRING_LENGTH_DESCRIPTION
				? `${data.meta.slice(0, 197)}...`
				: data.meta;

			generatedDescription.value = aggregatedGeneratedDescription;
			generatedTitle.value = data.title;

			goToNextStep();
		} catch (error) {
			captureException(error);
			notify({
				message: t('builder.seoOnboardingFailed'),
			});
			emit('close');
		} finally {
			isLoading.value = false;
		}

		return;
	}

	if (currentStepName.value === ONBOARDING_STEPS_SEO_BRAND_REVIEW) {
		// TODO: is_modification property - if page onboarding is opened manually, set it as false
		EventLogApi.logEvent({
			eventName: 'website_builder.seo_summary.finished',
			eventProperties: {
				location: 'onboarding',
				is_main: isCurrentPageHomePage.value,
				is_modification: false,
				manual_changes: hasModifiedManually.value,
			},
		});

		saveOnboardingPageMeta();
		endOnboarding();

		return;
	}

	goToNextStep();
};

const updateSelectedKeywordFromTags = (tags) => {
	selectedKeywords.value = tags.map((tag) => tag.value);
};

const currentPageUrl = computed(() => {
	const url = getPagePathFromId({
		siteData: getters.website,
		pageId: props.pageId,
		locale: state.currentLocale,
	});

	return url ? `${getters.siteUrl}${url}` : getters.siteUrl;
});
</script>

<template>
	<Teleport to="body">
		<ZyroModal
			:max-width="'730px'"
			:height="'auto'"
			:max-height="modalMaxHeight"
			position="center"
			class="seo-onboarding"
			:class="{ 'loading': isLoading }"
			show-close-button
			content-padding="0 40px 16px 40px"
			@close-modal="$emit('close')"
		>
			<div class="seo-onboarding__content">
				<div
					v-if="currentStepName !== ONBOARDING_STEPS_SEO_INTRO"
					class="page-reminder"
				>
					<span class="page-reminder__seo">{{ $t('common.seo') }}:</span>
					<ZyroSvgDeprecated
						class="page-icon"
						:name="isCurrentPageHomePage ? 'home' : 'page'"
					/>
					<span class="page-reminder__page-name">{{ pageName }}</span>
				</div>
				<div
					v-if="currentStepName === ONBOARDING_STEPS_SEO_INTRO"
					class="onboarding-intro"
				>
					<ZyroSvgDeprecated
						name="search"
						dimensions="16px"
						class="search-icon"
					/>
					<p class="content-heading">
						{{ $t('builder.optimizeForGoogle') }}
					</p>
					<p class="content-subtitle">
						{{ $t('builder.seoOnboardingIntroduction') }}
					</p>
				</div>
				<div
					v-if="currentStepName === ONBOARDING_STEPS_SEO_BRAND_NAME"
					class="brand-name"
				>
					<p class="content-heading">
						{{ $t('builder.seoOnboardingBusinessNameHeading') }}
					</p>
					<p class="content-subtitle">
						{{ $t('builder.seoOnboardingBusinessNameSubtitle') }}
					</p>
					<ZyroLabel
						for="brand-name-input"
						class="input-label"
					>
						{{ $t('builder.seoOnboardingBusinessOrBrandName') }}
					</ZyroLabel>
					<ZyroInput
						id="brand-name-input"
						v-model="businessBrandName"
						class="input"
						:placeholder="$t('builder.seoOnboardingInputPlaceholder')"
						focus-on-mount
					/>
					<p class="input-example">
						{{ $t('builder.seoOnboardingInputExample') }}
					</p>
				</div>
				<div
					v-if="currentStepName === ONBOARDING_STEPS_SEO_LANGUAGE"
					class="website-language"
				>
					<p class="content-heading">
						{{ $t("builder.seoOnboardingLanguageHeading") }}
					</p>
					<p class="content-subtitle">
						{{ $t("builder.seoOnboardingLanguageSubtitle") }}
					</p>
					<p class="text-bold-2 label">
						{{ $t('siteSettings.seoGeneralInputLangDropdownTitle') }}
					</p>
					<ZyroSelect
						v-qa="'html-language-select'"
						label-key="title"
						class="language-select"
						:options="HTML_LANG_VALUES"
						:model-value="websiteMetaHtmlLanguage"
						@update:model-value="websiteMetaHtmlLanguage = $event"
					/>
				</div>
				<div
					v-if="currentStepName === ONBOARDING_STEPS_SEO_DESCRIPTION"
					class="page-description"
				>
					<p class="content-heading">
						{{ contentHeading }}
					</p>
					<p class="content-subtitle">
						{{ contentSubtitle }}
					</p>
					<ZyroLabel
						for="page-description-textarea"
						class="input-label"
					>
						{{ $t('builder.seoOnboardingPageDescription') }}
					</ZyroLabel>
					<ZyroFieldTextArea
						id="page-description-textarea"
						v-model="fieldValue"
						qa-selector="seo-onboarding-description-input"
						theme="hostinger"
						:error="fieldError"
						:placeholder="$t('builder.seoOnboardingPageDescriptionPlaceholder')"
						:min-height="120"
						:maxlength="AI_GENERATE_MAX_LENGTH"
						is-resizable
						autofocus
						@text-area-blur="onFieldBlur"
						@update:model-value="setFieldValue($event)"
					/>
					<SegmentedProgressBar
						:progress="fieldProgress.progress"
						:colors="fieldProgress.colors"
					/>
					<div class="description-hint-text">
						<p class="text-body-3">
							{{ fieldProgress.label }}
						</p>
						<span class="text-body-3">{{ fieldValue.length }}/{{ AI_GENERATE_MAX_LENGTH }}</span>
					</div>
				</div>
				<div
					v-if="currentStepName === ONBOARDING_STEPS_SEO_BRAND_KEYWORDS"
					class="brand-keywords"
				>
					<p class="content-heading">
						{{ keywordsHeading }}
					</p>
					<p class="content-subtitle">
						{{ $t('builder.seoOnboardingKeywordsSubtitle') }}
					</p>
					<TagMultiselect
						v-model="selectedKeywordsTags"
						:tags="keywords"
						:max-selections="3"
						qa-prefix="onboarding-seo-keywords-tags"
						@update:model-value="updateSelectedKeywordFromTags"
					/>
				</div>
				<div
					v-if="currentStepName === ONBOARDING_STEPS_SEO_BRAND_REVIEW"
					class="flow-review"
				>
					<p class="content-heading">
						{{ $t('builder.seoOnboardingReviewHeading') }}
					</p>
					<p class="content-subtitle">
						{{ $t('builder.seoOnboardingReviewSubtitle') }}
					</p>
					<PageSearchPreview
						class="preview"
						:title="generatedTitle"
						:description="generatedDescription"
						:url="currentPageUrl"
					/>
					<p class="preview-description">
						{{ $t('builder.seoOnboardingReviewExampleDescription') }}
					</p>
					<ZyroLabel
						for="seo-title-input"
						class="input-label"
					>
						{{ $t('builder.pageSettingsModal.seoTitle') }}
					</ZyroLabel>
					<ZyroInput
						id="seo-title-input"
						v-model="generatedTitle"
						class="input"
						@update:modelValue="generatedTitle = $event, hasModifiedManually = true"
					/>
					<ZyroLabel
						for="seo-description-input"
						class="input-label"
					>
						{{ $t('builder.pageSettingsModal.metaDescription') }}
					</ZyroLabel>
					<ZyroInput
						id="seo-description-input"
						v-model="generatedDescription"
						class="input"
						@update:modelValue="generatedDescription = $event, hasModifiedManually = true"
					/>
				</div>
				<FeatureLockBanner
					v-if="userStore.areFeaturesLocked"
					class="seo-onboarding__feature-lock-banner"
				/>
			</div>
			<template #footer>
				<div class="footer__right-side">
					<span
						v-if="currentStepName !== ONBOARDING_STEPS_SEO_INTRO"
						class="step-count"
					>
						{{ currentOnboardingStep + 1 }} / {{ onboardingStepCount }}
					</span>
					<HostingerButton
						v-qa="'next-button'"
						class="next-button"
						:disabled="isNextStepButtonDisabled"
						@click="handleNextStepClick"
					>
						{{ nextStepButtonText }}
						<ZyroLoader
							v-if="isLoading"
							class="next-button__loader"
							size="20px"
							weight="2px"
						/>
					</HostingerButton>
				</div>
				<ZyroSvgDeprecated
					v-if="isPreviousStepArrowVisible"
					name="arrow-down"
					class="back-arrow"
					:class="{ 'disabled': isLoading }"
					dimensions="20px"
					direction="right"
					@click="goToPreviousStep(), hasModifiedManually = false"
				/>
			</template>
		</ZyroModal>
	</Teleport>
</template>

<style lang="scss" scoped>
.description-hint-text {
	display: flex;
	justify-content: space-between;
	margin-top: 4px;
	color: var(--color-gray);
}

.seo-onboarding {
	&.loading {
		:deep(.modal) {
			.modal__content > * {
				pointer-events: none;
				opacity: 0.6;
			}
		}
	}

	&__content {
		.content-heading {
			margin-bottom: 8px;
			font-size: 24px;
			font-weight: 500;
			line-height: 1.33;
		}

		.content-subtitle {
			margin-bottom: 24px;
			font-size: 14px;
			font-weight: 400;
			line-height: 1.42;
			color: $color-gray;
			letter-spacing: 0.25px;
		}

		.label {
			margin-bottom: 8px;
		}

		.language-select {
			margin-bottom: 16px;
		}

		.page-reminder {
			display: flex;
			align-items: center;
			margin-bottom: 16px;
			color: $color-gray;

			&__seo,
			&__page-name {
				font-size: 14px;
				line-height: 1.42;
				letter-spacing: 0.25px;
			}

			&__seo:first-child {
				margin-right: 8px;
				font-weight: 700;
				color: rgb(0, 0, 0);
			}

			.page-icon {
				margin-right: 8px;
			}
		}

		.onboarding-intro {
			text-align: center;

			.search-icon {
				margin-bottom: 29px;
				color: $color-gray;
			}
		}

		.input-label,
		.input {
			margin-bottom: 8px;
		}

		.input-label {
			line-height: 1.42;
		}

		.brand-keywords .brand-keywords,
		.brand-name .input-example {
			margin-bottom: 40px;
		}

		.brand-name .input-example {
			font-size: 13px;
			line-height: 1.23;
			color: $color-gray;
		}

		.flow-review .preview-description {
			margin-bottom: 20px;
			font-size: 13px;
			line-height: 1.23;
			color: $color-gray;
		}

		.preview {
			margin-bottom: 8px;
		}
	}

	&__feature-lock-banner {
		margin: 24px 0;
	}
}

:deep(.modal) {
	.modal__content {
		margin-top: 40px;
	}

	.modal__footer {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		height: 69px;
		border-top: 1px solid rgb(218, 220, 224);

		.back-arrow {
			&:not(.disabled):hover {
				cursor: pointer;
			}

			&.disabled {
				pointer-events: none;
				opacity: 0.5;
			}
		}

		.footer__right-side {
			display: flex;
			align-items: center;

			.next-button {
				position: relative;

				&__loader {
					margin-left: 8px;
				}
			}

			.step-count {
				margin-right: 16px;
				font-size: 14px;
				line-height: 1.42;
				color: $color-gray;
				letter-spacing: 0.25px;
			}
		}
	}
}
</style>
