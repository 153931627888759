<template>
	<div
		class="edit-style-container"
		:class="{ 'edit-style-container--active': isActive }"
	>
		<ZyroSeparator class="separator" />
		<div class="edit-style-container__content text-body-2">
			<Icon name="edit" />
			<span class="margin-left text-body-2">{{ title }}</span>
		</div>
	</div>
</template>

<script>
import Icon from '@/components/global/Icon.vue';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		Icon,
		ZyroSeparator,
	},

	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
	},
});
</script>

<style lang="scss" scoped>
@import "@/components/builder-drawers/drawers/partials/stylesDrawer/UserStyles";

.separator {
	margin: 16px 0;
}

.margin-left {
	margin-left: 8px;
}

.edit-style-container__content {
	color: $color-primary;

	&.hover {
		color: $color-primary-dark;
	}
}
</style>
