<template>
	<div class="og-preview">
		<p class="og-preview__title text-body-2">
			{{ $t('siteSettings.general.ogImage.content.label') }}
		</p>
		<Component
			:is="defaultIconComponent"
			:src="src"
			:name="defaultIcon"
			alt="OG Image preview"
			class="og-preview__image"
			dimensions="147px"
			view-box="0 0 405 123"
			is-custom
		/>
		<p class="og-preview__meta-text text-body-2">
			{{ pageMetaTitle }}
		</p>
		<p class="og-preview__meta-text text-body-2">
			{{ pageMetaDescription }}
		</p>
		<p class="og-preview__url text-body-2">
			{{ siteUrl || 'https://www.yourwebsitestitle.zyrosite.com' }}
		</p>
	</div>
</template>

<script setup>
import Icon from '@/components/global/Icon.vue';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import { useStore } from 'vuex';
import { computed } from 'vue';

const { getters } = useStore();

const props = defineProps({
	src: {
		type: String,
		default: '',
	},
});

const pageMetaTitle = computed(() => getters.currentPage.meta?.title ?? '');
const pageMetaDescription = computed(() => getters.currentPage.meta?.description ?? '');
const siteUrl = computed(() => getters.siteUrl);

const defaultIconComponent = computed(() => (props.src ? 'img' : Icon));
const defaultIcon = isHostingerBrand ? 'hostinger' : 'preview-ogimage';
</script>

<style lang="scss" scoped>
.og-preview {
	padding: 13px 10px 10px;
	background-color: $color-gray-light;

	&__title {
		margin-bottom: 14px;
	}

	&__image {
		width: 100%;
		max-height: 123px;
		object-fit: cover;
	}

	&__meta-text {
		margin-bottom: 6px;
	}
}
</style>
