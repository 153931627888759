<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import { useI18n } from 'vue-i18n';
import { useDomainStore } from '@/stores/domainStore';

defineEmits(['connect-domain']);

const domainStore = useDomainStore();

const { t } = useI18n();

</script>

<template>
	<div class="premium">
		<Icon
			:name="domainStore.isEligibleForFreeDomain ? 'featured-seasonal-and-gifts' : 'captive_portal'"
			:is-custom="domainStore.isEligibleForFreeDomain"
			view-box="0 0 24 24"
			class="premium__claim-icon"
		/>
		<div>
			<p class="premium__title text-bold-1">
				{{ domainStore.isEligibleForFreeDomain
					? t('builder.publishedModalClaimDomainTitle')
					: t('builder.publishedModalRoot.premium.title')
				}}
			</p>
			<p class="premium__text text-body-2">
				{{ domainStore.isEligibleForFreeDomain
					? t('builder.publishedModalClaimDomainDescription')
					: t('builder.improveDiscoverability')
				}}
			</p>
		</div>
		<HostingerButton @click="$emit('connect-domain')">
			{{ $t('builder.publishedModalRoot.premium.btn') }}
		</HostingerButton>
	</div>
</template>

<style lang="scss" scoped>
.premium {
	text-align: left;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: $media-mobile) {
		flex-direction: column;
		align-items: flex-start;
	}

	&__title {
		margin-bottom: 4px;
		color: $color-dark;
	}

	&__text {
		color: $color-dark;
		margin-right: 16px;

		@media screen and (max-width: $media-mobile) {
			margin-bottom: 16px;
			margin-right: 0;
		}
	}

	&__claim-icon {
		color: $color-primary;
		margin-right: 14px;
		min-width: 24px;

		@media screen and (max-width: $media-mobile) {
			margin-bottom: 16px;
		}
	}

}
</style>
