<template>
	<ButtonStyleSettingsV2
		:element-id="elementId"
		@set-button-style="setButtonStyle"
	/>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';

import ButtonStyleSettingsV2 from '@/components/builder-controls/edit-button/-partials/button-style-tab/ButtonStyleSettingsV2.vue';

type Props = {
	elementId?: string;
};

const props = defineProps<Props>();
const { dispatch } = useStore();
const setButtonStyle = (stylesToMerge: Record<string, string | number | Record<string, string | number>>) => {
	dispatch('mergeElementData', {
		elementId: props.elementId,
		elementData: {
			...stylesToMerge,
		},
	});
};
</script>
