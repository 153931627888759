<script setup lang="ts">
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';
import Icon from '@/components/global/Icon.vue';
import { onBeforeMount } from 'vue';

interface ListDataItem {
	name?: string;
	type?: string;
	icon?: string;
	prependIcon?: string;
}

type ListData = Record<string, ListDataItem>;

interface Props {
	listData: ListData,
	current: string,
}

const props = withDefaults(defineProps<Props>(), {
	current: '',
});

type Emits = {
	'update:current': [id: string];
}

const emit = defineEmits<Emits>();

const setCurrent = (id: string) => {
	emit('update:current', id);
};

onBeforeMount(() => {
	if (Object.keys(props.listData).length && !props.listData[props.current]) {
		setCurrent(Object.keys(props.listData)[0]);
	}
});
</script>

<template>
	<div class="list-selector">
		<template
			v-for="(listItem, id) in listData"
			:key="id"
		>
			<ZyroSeparator
				v-if="listItem.type === 'separator'"
				class="list-selector__separator"
			/>
			<button
				v-else
				v-qa="`builder-addnewsectionmodal-btn-${listItem.name}`"
				class="list-selector__item text-body-2"
				:class="{ 'is-current': current === id }"
				@click="setCurrent(id)"
			>
				<div class="list-selector__top-item">
					<Icon
						v-if="listItem.prependIcon"
						class="list-selector__icon"
						:name="listItem.prependIcon"
					/>
					{{ listItem.name }}
				</div>
				<Icon
					v-if="listItem.icon"
					class="list-selector__icon"
					:name="listItem.icon"
				/>
			</button>
		</template>
	</div>
</template>

<style lang="scss" scoped>
.list-selector {
	&__icon {
		margin-right: 8px;
	}

	&__top-item {
		display: flex;
		align-items: center;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 12px 16px;
		margin: 0;
		text-align: left;
		text-decoration: none;
		user-select: none;
		background-color: transparent;
		border-radius: $border-radius-medium;

		&.is-current,
		&:hover,
		&:active,
		&:focus {
			background-color: $color-gray-light;
		}

		&:not(.is-current) {
			cursor: pointer;
		}
	}

	&__separator {
		margin: 8px 0;
	}
}
</style>
