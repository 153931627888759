import { computed } from 'vue';
import { useStore } from 'vuex';

export const useGuiGetters = () => {
	const { state } = useStore();

	const isMobileScreen = computed(() => state.gui.isMobileScreen);

	return {
		isMobileScreen,
	};
};
