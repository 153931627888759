<template>
	<div
		v-if="!isAiBuilderMode"
		class="rate-feature"
		:class="{ 'rate-feature--description-disabled': isDescriptionDisabled }"
	>
		<div
			class="rate-feature__container"
			:class="{ 'rate-feature__container--with-border': !hideBorder }"
		>
			<a
				target="_blank"
				class="rate-feature__link rate-feature__link--icon z-link"
				@click="openNps"
			>
				<Icon
					name="add_reaction"
					color="gray"
					dimensions="20px"
				/>
			</a>
			<p
				v-if="!isDescriptionDisabled"
				class="rate-feature__text"
			>
				<i18n-t
					tag="p"
					keypath="builder.editImage.tabSeo.NpsImage"
				>
					<a
						data-qa="feature-nps-modal-link"
						target="_blank"
						class="rate-feature__link z-link"
						@click="openNps"
					>
						{{ $t('builder.editImage.tabSeo.NpsImageRateThisFeature') }}
					</a>
				</i18n-t>
			</p>
		</div>
	</div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useBuilderMode } from '@/use/useBuilderMode';
import Icon from '@/components/global/Icon.vue';
import { useNpsStore } from '@/stores/npsStore';

const props = defineProps({
	featureName: {
		type: String,
		required: true,
	},
	type: {
		type: String,
		required: true,
	},
	isDescriptionDisabled: {
		type: Boolean,
		default: false,
	},
	hideBorder: {
		type: Boolean,
		default: false,
	},
});

const { t } = useI18n();
const { isAiBuilderMode } = useBuilderMode();
const npsStore = useNpsStore();

const openNps = () => {
	npsStore.setNpsData({
		newQuestion: `${t('builder.npsRateQuestion')} ${props.featureName}`,
		isVisible: true,
		formType: props.type,
	});
};
</script>

<style lang="scss" scoped>
.rate-feature {
	$this: &;

	display: flex;
	align-items: flex-start;
	justify-items: center;
	margin-bottom: 0;
	font-size: 12px;

	&__container {
		display: flex;
		width: 100%;
		align-items: center;
		gap: 8px;
		padding-top: 8px;

		&--with-border {
			border-top: 1px solid $color-gray-border;

			#{$this} {
				margin-top: 0;
			}
		}
	}

	&__link {
		color: var(--color-primary);

		&--icon {
			height: 20px;
		}
	}

	&--description-disabled {
		padding-left: 7px;
		margin-top: 0;

		#{$this}__container {
			padding-top: 6px;
			border-top: 0;
		}

		#{$this}__icon {
			width: 22px;
			height: 22px;
		}
	}

	&__text {
		line-height: 1.25;
		color: $color-gray;
	}
}

</style>
