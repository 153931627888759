<template>
	<div class="options">
		<div
			v-for="option in options"
			:key="option.id"
			class="options__row"
		>
			<Icon
				class="options__row-icon"
				:name="`${tag}-empty`"
				dimensions="16px"
				is-custom
			/>
			<ZyroFieldInput
				class="options__input"
				:model-value="option.value"
				@update:model-value="updateOptionName({
					value: $event,
					id: option.id
				})"
			/>
			<HostingerButton
				class="options__trash"
				button-type="plain"
				@click="removeOption(option.id)"
			>
				<template #icon>
					<Icon
						name="delete"
						dimensions="20px"
					/>
				</template>
			</HostingerButton>
		</div>
		<div class="options__row">
			<HostingerButton
				button-type="outlined"
				@click="addNewOption"
			>
				<template #icon-left>
					<Icon name="add" />
				</template>
				{{ $t('builder.editForm.addOption') }}
			</HostingerButton>
		</div>
	</div>
</template>
<script>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';

import { generateRandomId } from '@/utils/generateRandomId';

import { defineComponent } from 'vue';

export default defineComponent({

	components: {
		Icon,
		HostingerButton,
		ZyroFieldInput,
	},

	props: {
		options: {
			type: Array,
			default: () => ([]),
		},
		tag: {
			type: String,
			default: 'radio',
		},
	},
	emits: ['update'],

	methods: {
		updateOptionName({
			value,
			id,
		}) {
			const newOptions = this.options.map((option) => (option.id === id ? {
				id,
				value,
			} : option));

			this.saveUpdatedOptions(newOptions);
		},
		addNewOption() {
			const newOptions = [
				...this.options,
				{
					id: generateRandomId(),
					value: `${this.$t('builder.editForm.option')} ${this.options.length + 1}`,
				},
			];

			this.saveUpdatedOptions(newOptions);
		},
		removeOption(optionId) {
			const newOptions = this.options.filter(({ id }) => (id !== optionId));

			this.saveUpdatedOptions(newOptions);
		},
		saveUpdatedOptions(value) {
			this.$emit('update', value);
		},
	},
});
</script>
<style lang="scss" scoped>
.options {
	&__row {
		display: flex;
		flex-direction: row;
	}

	&__row-icon {
		align-self: center;
		margin-right: 8px;
		margin-bottom: 16px;
	}

	&__input {
		width: 100%;
	}

	&__trash {
		margin-left: 4px;
		margin-bottom: 16px;

		&:hover {
			color: $color-danger;
		}
	}
}
</style>
