<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';

import { useEditGridGallery } from '@/components/builder-controls/edit-gallery/useEditGridGallery';

const { showGalleryManager } = useEditGridGallery();
</script>
<template>
	<div class="empty-gallery">
		<p class="empty-gallery__title text-bold-1">
			{{ $t('builder.editGallery.emptyBlockTitle') }}
		</p>
		<p class="empty-gallery__explanation text-body-1">
			{{ $t('builder.editGallery.emptyBlockExplanation') }}
		</p>
		<HostingerButton
			size="sm"
			class="drag-and-drop-overlay__button"
			@click="showGalleryManager = true"
		>
			{{ $t('builder.editGallery.manageButton') }}
		</HostingerButton>
	</div>
</template>
<style lang="scss" scoped>
.empty-gallery {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 8px;
	text-align: center;
	background: $color-gray-light;

	&__title {
		margin-bottom: 8px;
	}

	&__explanation {
		margin-bottom: 24px;
		color: $color-gray;
	}
}
</style>
