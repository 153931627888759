<script setup lang="ts">
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroModal from '@/components/global/ZyroModal.vue';
import { CLOSE_MODAL } from '@/store/builder/gui';
import { MODAL_QR_NOT_AVAILABLE } from '@/constants/builderConstants';

const { t } = useI18n();
const { dispatch } = useStore();

const handleCloseClick = () => {
	dispatch(`gui/${CLOSE_MODAL}`, {
		name: MODAL_QR_NOT_AVAILABLE,
	});
};
</script>

<template>
	<ZyroModal
		max-width="600px"
		height="auto"
		content-padding="8px 40px 0"
		header-padding="32px 40px 0"
		footer-padding="24px 40px 40px"
		no-footer-border
		:title="t('builder.pageSettingsQrCodeNotAvailableTitle')"
		class="qr-not-available-modal"
		@close-modal="handleCloseClick"
	>
		<div>
			<i18n-t
				tag="p"
				keypath="builder.pageSettingsQrCodeNotAvailableParagraph"
				class="text-body-2"
			>
				<a
					v-qa="'editor-qrmodalnotavailable-link-moreaboutqrcode'"
					class="qr-not-available-modal__link z-link"
					href="//support.hostinger.com/en/articles/8908627-website-builder-how-to-generate-a-qr-code-of-a-page"
					target="_blank"
					rel="noopener noreferrer"
					v-text="t('builder.pageSettingsQrCodeNotAvailableLink')"
				/>
			</i18n-t>
		</div>

		<template #footer>
			<div class="qr-not-available-modal__footer">
				<HostingerButton
					v-qa="'legalcontentgeneratormodal-btn-submit'"
					class="qr-not-available-modal__close"
					@click="handleCloseClick"
				>
					{{ t('common.close') }}
				</HostingerButton>
			</div>
		</template>
	</ZyroModal>
</template>

<style scoped lang="scss">
.qr-not-available-modal {
	&__footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
}
</style>
