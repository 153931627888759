<template>
	<button
		class="menu-button"
		:class="{
			'menu-button--controls-open': isControlBarVisible,
			'menu-button--active': isSidebarOpen
		}"
		@click="$emit('menu-button-click')"
	>
		<Icon
			name="dashboard_customize"
			is-filled
		/>
	</button>
</template>
<script setup>
import Icon from '@/components/global/Icon.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

defineEmits(['menu-button-click']);

defineProps({
	isSidebarOpen: {
		type: Boolean,
		default: false,
	},
});

const {
	state,
	getters,
} = useStore();

const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isControlBarVisible = computed(() => !!state.currentElementId || !!state.currentBlockId);
const positionBottom = computed(() => (getters['gui/isSetupPaymentProviderNotificationVisible'] ? `${isMobileScreen.value ? '100' : '52'}px` : '16px'));
</script>
<style scoped lang="scss">
$position-right: 16px;
$position-bottom: v-bind(positionBottom);

.menu-button {
  background-color: $color-light;
  box-shadow: 0 0 12px 0 rgba(29, 30, 32, 16%);
  padding: 16px;
  color: $color-primary;
  position: fixed;
  right: $position-right;
  bottom: $position-bottom;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  &--controls-open {
    bottom: calc(#{$position-bottom} + 45px);
  }

  &--active {
    scale: 0.85;
  }
}
</style>
