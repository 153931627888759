import { useStore } from 'vuex';
import { computed } from 'vue';
import {
	ELEMENT_POSITION_KEY_MOBILE,
	ELEMENT_POSITION_KEY_DESKTOP,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

export const useLayoutHelpers = () => {
	const { getters } = useStore();

	const isMobileMode = computed(() => getters['gui/isMobileMode']);
	const elementPositionKey = computed(() => (isMobileMode.value ? ELEMENT_POSITION_KEY_MOBILE : ELEMENT_POSITION_KEY_DESKTOP));
	const elementPositionKeyReversed = computed(() => (isMobileMode.value ? ELEMENT_POSITION_KEY_DESKTOP : ELEMENT_POSITION_KEY_MOBILE));

	return {
		isMobileMode,
		elementPositionKey,
		elementPositionKeyReversed,
	};
};
