/**
 * Validate layout position
 * @param {Object} position
 * @returns {boolean}
*/
export const getIsLayoutPositionValid = ({ position }) => {
	if (!position) {
		return false;
	}

	const propertiesToValidate = [
		'top',
		'left',
		'width',
		'height',
	];

	return propertiesToValidate.every((property) => {
		const hasProperty = property in position;
		const isCorrectPosition = typeof position[property] === 'number' && position[property] >= 0;

		return hasProperty && isCorrectPosition;
	});
};
